import React, { useEffect, useState } from "react";
import { useTable } from "react-table";
import PT from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { X, PlusCircle, Trash } from "react-feather";
import { constants } from "../../config";
import SelectSearch, { fuzzySearch } from "react-select-search";

import { getClassBySchoolId } from "../../store/class/actions";
import { getShifts } from "../../store/shift/actions";
import { getShiftTimes } from "../../store/shiftTime/actions";
import { getSchoolById } from "../../store/school/actions";
import { getInstructors } from "../../store/instructor/actions";
import { getScheduleGroupsByClassId } from "../../store/scheduleGroup/actions";
import {
  addSchedule,
  getSchedules,
  deleteSchedule,
  updateSchedule,
} from "../../store/schedule/actions";
import { getCourses } from "../../store/course/actions";

ScheduleClass.propTypes = {
  shiftId: PT.number,
  classTitle: PT.number
};

export default function ScheduleClass(props) {
  const { t, i18n } = useTranslation();
  const { shiftId } = props;
  const { classTitle } = props;
  const dispatch = useDispatch();
  const classStore = useSelector((store) =>
    store.class.class
      .sort((a, b) => a.classLabel.localeCompare(b.classLabel))
      .sort((a, b) => a.classLevel - b.classLevel)
  );
  const shiftsStore = useSelector((store) => store.shifts.shifts);
  const shiftTimesStore = useSelector((store) =>
    store.shiftTimes.shiftTimes.sort((a, b) =>
      a.startTime < b.startTime ? -1 : a.startTime > b.startTime ? 1 : 0
    )
  );
  const schedulesStore = useSelector((store) => store.schedules.schedules);
  const courseStore = useSelector((store) => store.courses.courses);
  const scheduleGroupsStore = useSelector(
    (store) => store.scheduleGroups.scheduleGroups
  );
  const instructorsStore = useSelector(
    (store) => store.instructors.instructors
  );
  const [isGroup, setIsGroup] = useState(false);
  const [as, setAs] = useState("");
  const [isDisabledButton, setIsDisabledButton] = useState(true);
  const [newSchedule, setNewSchedule] = useState([
    {
      instructorId: "",
      isGroup: false,
      courseId: "",
      groupTitle: "",
      scheduleGroupId: "",
    },
  ]);
  const [editSchedule, setEditSchedule] = useState({});
  const [modal, setModal] = useState({
    createSchedule: false,
    editSchedule: false,
  });
  
  useEffect(async () => {
    await dispatch(getSchedules());
    await dispatch(getCourses());
    await dispatch(getClassBySchoolId());
    await dispatch(getShifts());
    await dispatch(getShiftTimes());
    await dispatch(
      getSchoolById(JSON.parse(localStorage.getItem("school"))?.id)
    );
  }, []);

  const das = constants.days;
  const onInputChange = (e, index) => {
    console.log(e, "gooooooooo");
    if ((e.key || e.target.name) === "instructorId") {
      let sa = e.value.split(",");
      console.log(sa, "23232332");
      let a = newSchedule;
      let b = newSchedule?.[index];
      let s = das.filter(a =>  a.ru == newSchedule[0].weekDay);
      newSchedule[0].weekDay = s[0].en;
      b.instructorId = Number(sa[0]);
      b.courseId =  Number(sa[1]);
      b.isGroup = isGroup;
      a.splice(index, 1);
      a.splice(index, 0, b);
      setNewSchedule(a);
    } else if ((e.key || e.target.name) === "group") {
      let a = newSchedule;
      let b = newSchedule?.[index];
      b.groupTitle = e.value.shift();
      b.scheduleGroupId = e.value.pop();
      b.isGroup = isGroup;
      a.splice(index, 1);
      a.splice(index, 0, b);
      setNewSchedule(a);
    } else {
      setNewSchedule({
        ...newSchedule,
        [e.key || e.target.name]: e.value || e.target.value,
      });
    }
  };
  const onEditInputChange = (e, index) => {
    if ((e.key || e.target.name) === "instructorId") {
      let sa = e.value.split(",");
      let a = editSchedule;
      let b = editSchedule?.[index];
      b.instructorId = Number(sa[0]);
      b.courseId =  Number(sa[1]);
      b.isGroup = isGroup;
      a.splice(index, 1);
      a.splice(index, 0, b);
      setEditSchedule(a);
    } else if ((e.key || e.target.name) === "group") {
      let a = editSchedule;
      let b = editSchedule?.[index];
      b.groupTitle = e.value.shift();
      b.scheduleGroupId = e.value.pop();
      b.isGroup = isGroup;
      a.splice(index, 1);
      a.splice(index, 0, b);
      setEditSchedule(a);
    } else {
      setEditSchedule({
        ...editSchedule,
        [e.key || e.target.name]: e.value || e.target.value,
      });
    }
  };

  let arr = [];
  constants.days.slice(0, JSON.parse(localStorage.getItem("school"))?.studyDay)
    .map((day) => {
      let str = day?.[`${String(i18n.language).toLowerCase()}`];
      let strEn = day?.en;
      arr.push({ Header: str, accessor: strEn,});
    });

  function tableData() {
    let ar = [];
    const f = shiftTimesStore.filter(ite => ite.shiftId == shiftId);
    for(let i = 0; i < 5; i++){
      ar.push({
        id: "",
        MONDAY: " ",
        TUESDAY: " ",
        WEDNESDAY: " ",
        THURSDAY: " ",
        FRIDAY: " ",
        shiftTimeId: f[i]?.id,
        shiftTime: f[i]?.title,
        courseId: "",
        instructorId: ""
      });
    };
    const classLevel = classStore.filter(obj => obj.id == classTitle);
    const s = schedulesStore.filter(el => el.classId == classTitle);
    const monday = s.filter(el => el.weekDay == "MONDAY");
    const tuesday = s.filter(el => el.weekDay == "TUESDAY");
    const wednesday = s.filter(el => el.weekDay == "WEDNESDAY");
    const thursday = s.filter(el => el.weekDay == "THURSDAY");
    const friday = s.filter(el => el.weekDay == "FRIDAY");
    ar[2].classTitle = classLevel[0]?.classLevel+classLevel[0]?.classLabel;
    monday.map(el => {
      const l = ar.filter(obj => obj.shiftTimeId === el.shiftTimeId );
      if(l.length) {
        l[0].MONDAY = el.courseTitleRU;
        l[0].id = el.id;
        l[0].courseId = el.courseId;
        l[0].instructorId = el.instructorId
        ;
      };
    });
    tuesday.map(el => {
      const l = ar.filter(obj => obj.shiftTimeId === el.shiftTimeId );
      if(l.length) {
        l[0].TUESDAY = el.courseTitleRU;
        l[0].id = el.id;
        l[0].courseId = el.courseId;
        l[0].instructorId = el.instructorId;
      }
    });
    wednesday.map(el => {
      const l = ar.filter(obj => obj.shiftTimeId === el.shiftTimeId );
      if(l.length) {
        l[0].WEDNESDAY = el.courseTitleRU;
        l[0].id = el.id;
        l[0].courseId = el.courseId; 
        l[0].instructorId = el.instructorId;
      }
    });
    thursday.map(el => {
      const l = ar.filter(obj => obj.shiftTimeId === el.shiftTimeId );
      if(l.length) {
        l[0].THURSDAY = el.courseTitleRU;
        l[0].id = el.id;
        l[0].courseId = el.courseId; 
        l[0].instructorId = el.instructorId;
      }
    });
    friday.map(el => {
      const l = ar.filter(obj => obj.shiftTimeId === el.shiftTimeId );
      if(l.length) {
        l[0].FRIDAY = el.courseTitleRU; 
        l[0].id = el.id;
        l[0].courseId = el.courseId; 
        l[0].instructorId = el.instructorId;
      }
    });
    return ar;
  }

  const columns = React.useMemo(
    () => [
      {
        Header: "",
        accessor: "classTitle",
        width: 40,
      },
      {
        Header: "",
        accessor: "shiftTime",
        width: 40,
      },
      ...arr
    ],
    [shiftId, schedulesStore, String(i18n.language)]
  );
  const data = tableData();

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  let opt = [];
  instructorsStore.map((instructor) => {
    return instructor.instructorCourseTitleDtoList.map((course) => {
      opt.push({
        name: `${instructor.instructorTitle} - ${
          courseStore.find((item) => item.id === course.courseId)?.[
            String(i18n.language).toLowerCase() === "en" ||
            String(i18n.language).toLowerCase() === "tr"
              ? `title`
              : `title${String(i18n.language).toUpperCase()}`
          ]
        }`,
        value: `${instructor.instructorId}, ${course.courseId}`,
        key: "instructorId",
      });
    });
  });
  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, idx1) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={idx1}>
              {headerGroup.headers.map((column, idx2) => (
                <th {...column.getHeaderProps()} key={idx2}>
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()} className="class-schedule-table">
          {rows.map((row, idx3) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={idx3}>
                {row.cells.map((cell, idx4) => {
                  return (
                    <td
                      // className={
                      //   cell.row.original.dayKey
                      //     ? cell.row.original?.[`${cell.column.id}`]?.[0]
                      //       ?.isGroup
                      //       ? "isGroup day"
                      //       : "day"
                      //     : cell.row.original.days === "-" &&
                      //       cell.row.original.shiftTime === " "
                      //       ? "empty"
                      //       : ""
                      // }
                      title={`${
                        shiftTimesStore.find(
                          (item) => item.id === cell.row.original.shiftTimeId
                        )?.startTime
                      }-${
                        shiftTimesStore.find(
                          (item) => item.id === cell.row.original.shiftTimeId
                        )?.endTime
                      }`}
                      {...row.getRowProps({
                        style: {
                          width: cell.column.width,
                          cursor: "pointer",
                        },
                        onClick: () => {
                          console.log(cell.row.original?.[`${cell.column.id}`].length < 2, "goooooo5555555");
                          if (cell.row.original?.[`${cell.column.id}`].length < 2) {
                            // setAs(`${cell.row.original.instructorId}, ${cell.row.original.courseId}`);
                            // setIsGroup(
                            //   cell.row.original
                            // );
                            // setEditSchedule(
                            //   cell.row.original
                            // );
                            // setNewSchedule([
                            //   {
                            //     ...newSchedule,
                            //     weekDay: cell.column.Header,
                            //     classId: classTitle,
                            //     shift: shiftsStore.find(
                            //       (item) => item.id == shiftId
                            //     )?.title,
                            //     shiftId: shiftId,
                            //     shiftTimeId: cell.row.original.shiftTimeId,
                            //     shiftTime: cell.row.original.shiftTime,
                            //     courseId: cell.row.original.courseId,
                            //     intructorId: cell.row.original.intructorId,
                            //   },
                            // ]);
                            setEditSchedule([{
                              ...cell.row.original,
                              weekDay: cell.column.Header,
                              shiftTime: cell.row.original.shiftTime,
                            }]
                            );
                            // setModal({ ...modal, editSchedule: true });
                            setNewSchedule([
                              {
                                ...newSchedule,
                                class: data[2]?.classTitle,
                                weekDay: cell.column.Header,
                                classId: classTitle,
                                shift: shiftsStore.find(
                                  (item) => item.id == shiftId
                                )?.title,
                                shiftId: shiftId,
                                shiftTimeId: cell.row.original.shiftTimeId,
                                shiftTime: cell.row.original.shiftTime,
                              },
                            ]);
                            setModal({ ...modal, createSchedule: true });
                          }else {
                            setAs(`${cell.row.original.instructorId}, ${cell.row.original.courseId}`);
                            setIsGroup(
                              cell.row.original?.[`${cell.column.id}`].length < 2
                            );
                            setEditSchedule(
                              cell.row.original
                            );
                            // setNewSchedule([
                            //   {
                            //     ...newSchedule,
                            //     weekDay: cell.column.Header,
                            //     classId: classTitle,
                            //     shift: shiftsStore.find(
                            //       (item) => item.id == shiftId
                            //     )?.title,
                            //     shiftId: shiftId,
                            //     shiftTimeId: cell.row.original.shiftTimeId,
                            //     shiftTime: cell.row.original.shiftTime,
                            //     courseId: cell.row.original.courseId,
                            //     intructorId: cell.row.original.intructorId,
                            //   },
                            // ]);
                            setEditSchedule([{
                              ...cell.row.original,
                              weekDay: cell.column.Header,
                              shiftTime: cell.row.original.shiftTime,
                            }]
                            );
                            setModal({ ...modal, editSchedule: true });
                            // setNewSchedule([
                            //   {
                            //     ...newSchedule,
                            //     weekDay: cell.column.Header,
                            //     classId: classTitle,
                            //     shift: shiftsStore.find(
                            //       (item) => item.id == shiftId
                            //     )?.title,
                            //     shiftId: shiftId,
                            //     shiftTimeId: cell.row.original.shiftTimeId,
                            //     shiftTime: cell.row.original.shiftTime,
                            //   },
                            // ]);
                            // setModal({ ...modal, createSchedule: true });
                          }
                        },
                      })}
                      key={idx4}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <Modal
        isOpen={modal.createSchedule}
        onRequestClose={() => setModal({ ...modal, createSchedule: false }, setAs(""))}
        overlayClassName="modal-overlay"
        onAfterOpen={async () => {
          await dispatch(getScheduleGroupsByClassId(classTitle));
          await dispatch(getInstructors());
        }}
      >
        <div className="modal">
          <div className="modal-close">
            <button
              onClick={() => setModal({ ...modal, createSchedule: false })}
            >
              <X size={15} color="#9A9A9A" />
            </button>
          </div>
          <div className="modal-header">
            <div className="modal-header-title">
              <h2 className="roboto">
                {data[2].classTitle} {t("schoolAdmin:lower case class")}
              </h2>
            </div>
            <div className="modal-header-description">
              <p className="body-2">
                {editSchedule?.[0]?.weekDay}
                , {newSchedule?.[0]?.shiftTime}{" "}
                {t("schoolAdmin:lesson lower case")}
              </p>
            </div>
          </div>
          <div className="modal-body">
            <div className="modal-input">
              <div className="gender">
                <div>
                  <input
                    type="radio"
                    name="isGroup"
                    value={false}
                    defaultChecked={isGroup == false}
                    onChange={() => {
                      setIsGroup(false);
                      setIsDisabledButton(true);
                    }}
                  />
                  <label htmlFor="gender">{t("schoolAdmin:all class")}</label>
                </div>
                <div>
                  <input
                    type="radio"
                    name="isGroup"
                    value={true}
                    defaultChecked={isGroup == true}
                    onChange={() => {
                      setIsGroup(true);
                      setIsDisabledButton(true);
                    }}
                  />
                  <label htmlFor="gender">{t("schoolAdmin:a group")}</label>
                </div>
              </div>
            </div>
            {newSchedule?.map((item, idx) => (
              <div
                className="modal-inputs-list"
                style={{ marginTop: "24px" }}
                key={idx}
              >
                <div className="modal-input">
                  {/* <select
                    name="intructorId"
                    onChange={(e) => onInputChange(e, idx)}
                    required
                  >
                    <option value="" selected hidden disabled>
                      {t("schoolAdmin:teacher")} -{" "}
                      {t("schoolAdmin:lesson upper case")}
                    </option>
                    {instructorsStore.map((instructor) => {
                      return instructor?.instructorCourseTitleDtoList?.map(
                        (course, idx) => (
                          <option
                            value={[instructor.instructorId, course.courseId]}
                            key={idx}
                          >
                            {instructor.instructorTitle} -{" "}
                            {
                              courseStore.find(
                                (item) => item.id === course.courseId
                              )?.[
                                String(i18n.language).toLowerCase() === "en" ||
                                String(i18n.language).toLowerCase() === "tr"
                                  ? `title`
                                  : `title${String(
                                      i18n.language
                                    ).toUpperCase()}`
                              ]
                            }
                          </option>
                        )
                      );
                    })}
                  </select> */}
                  {instructorsStore.length > 0 && courseStore.length > 0 && (
                    <SelectSearch
                      options={opt}
                      search
                      className="select-search drop-up small"
                      filterOptions={fuzzySearch}
                      name="intructorId"
                      value={as}
                      onChange={(_, k) => {
                        // onFilterHandler(k.value);
                        onInputChange(k, idx);
                        newSchedule?.[0]?.isGroup
                          ? setIsDisabledButton(true)
                          : setIsDisabledButton(false);
                      }}
                      required
                      placeholder={`${t("schoolAdmin:teacher")} - ${t(
                        "schoolAdmin:lesson upper case"
                      )}`}
                    />
                  )}
                </div>

                {isGroup && (
                  <>
                    <div className="modal-input">
                      <SelectSearch
                        options={scheduleGroupsStore.map((item) => ({
                          name: item.groupTitle,
                          value: [item.groupTitle, item.groupId],
                          key: "group",
                        }))}
                        search
                        className="select-search"
                        filterOptions={fuzzySearch}
                        name="group"
                        value={`${[
                          newSchedule.groupTitle,
                          newSchedule.scheduleGroupId,
                        ]}`}
                        onChange={(_, k) => {
                          // onFilterHandler(k.value);
                          onInputChange(k, idx);
                          newSchedule?.[0]?.isGroup
                            ? setIsDisabledButton(false)
                            : setIsDisabledButton(true);
                        }}
                        required
                        placeholder={`${t("schoolAdmin:a group")}`}
                      />
                    </div>
                    <div className="divider"></div>
                    {idx + 1 > newSchedule.length - 1 ? (
                      <div className="add-other-group">
                        <button
                          onClick={() =>
                            setNewSchedule([
                              ...newSchedule,
                              {
                                ...newSchedule?.[0],
                                scheduleGroupId: null,
                                groupTitle: "",
                              },
                            ])
                          }
                        >
                          <PlusCircle size={24} color="#F0783A" />
                          <span>{t("schoolAdmin:add group")}</span>
                        </button>
                      </div>
                    ) : (
                      <div className="remove-other-group">
                        <button
                          onClick={() => {
                            let schedule = newSchedule;
                            schedule.splice(idx, 1);
                            setNewSchedule([...schedule]);
                          }}
                        >
                          <Trash size={24} color="#FF7171" />
                          <span>{t("schoolAdmin:delete group")}</span>
                        </button>
                      </div>
                    )}
                  </>
                )}
              </div>
            ))}
          </div>
          <div className="modal-footer">
            <button
              className="large secondary"
              onClick={() => {
                setModal({ ...modal, createSchedule: false });
                setIsGroup(false);
                setNewSchedule([]);
              }}
            >
              {t("presetting:consel presetting")}
            </button>
            <button
              className="large primary"
              disabled={isDisabledButton}
              onClick={async () => {
                await newSchedule.map((item) => dispatch(addSchedule(item)));
                await setModal({ ...modal, createSchedule: false });
                await setIsGroup(false);
                await setNewSchedule([]);
              }}
            >
              {t("common:ready")}
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modal.editSchedule}
        onRequestClose={() => setModal({ ...modal, editSchedule: false }, setAs(""))}
        overlayClassName="modal-overlay"
        onAfterOpen={async () => {
          await setNewSchedule([]);
          await dispatch(
            getScheduleGroupsByClassId(classTitle)
          );
          await dispatch(getInstructors());
        }}
      >
        <div className="modal">
          <div className="modal-close">
            <button onClick={() => setModal({ ...modal, editSchedule: false })}>
              <X size={15} color="#9A9A9A" />
            </button>
          </div>
          <div className="modal-header">
            <div className="modal-header-title">
              <h2 className="roboto">
                {data[2].classTitle}{" "}
                {t("schoolAdmin:lower case class")}
              </h2>
            </div>
            <div className="modal-header-description">
              <p className="body-2">
                {editSchedule?.[0]?.weekDay}
                {/* , {editSchedule?.[0]?.shiftTime} урок */}
              </p>
            </div>
          </div>
          <div className="modal-body">
            {editSchedule.length > 0 &&
              editSchedule?.map((schedule, idx) => (
                <div className="modal-inputs-list" key={idx}>
                  <div className="modal-input">
                    {instructorsStore.length > 0 && courseStore.length > 0 && (
                      <SelectSearch
                        options={opt}
                        search
                        className="select-search drop-up small"
                        filterOptions={fuzzySearch}
                        name="instructorId"
                        value={as}
                        onChange={(_, k) => {
                          onEditInputChange(k, idx);
                        }}
                        required
                        placeholder={`${t("schoolAdmin:teacher")} - ${t(
                          "schoolAdmin:lesson upper case"
                        )}`}
                      />
                    )}
                    {!schedule.isGroup && (
                      <div className="remove-schedule">
                        <button
                          onClick={() => {
                            dispatch(deleteSchedule(schedule.id));
                            setModal({ ...modal, editSchedule: false });
                            setEditSchedule({});
                          }}
                        >
                          <Trash size={24} color="#FF7171" />
                          <span>{t("presetting:delete presetting")}</span>
                        </button>
                      </div>
                    )}
                  </div>
                  {schedule.isGroup && (
                    <>
                      <div className="modal-input">
                        {instructorsStore.length > 0 && courseStore.length > 0 && (
                          <SelectSearch
                            options={scheduleGroupsStore.map((item) => ({
                              name: item.groupTitle,
                              value: [item.groupTitle, item.groupId],
                              id: item.groupId,
                              key: "group",
                            }))}
                            value={`${[
                              schedule.groupTitle,
                              schedule.scheduleGroupId,
                            ]}`}
                            search
                            className="select-search"
                            filterOptions={fuzzySearch}
                            name="group"
                            onChange={(_, k) => {
                              onEditInputChange(k, idx);
                            }}
                            required
                            placeholder={`${t("schoolAdmin:a group")}`}
                          />
                        )}
                      </div>
                      <div className="remove-other-group-edit">
                        <button
                          onClick={() => {
                            dispatch(deleteSchedule(schedule.id));
                            setModal({ ...modal, editSchedule: false });
                            setEditSchedule({});
                          }}
                        >
                          <Trash size={24} color="#FF7171" />
                          <span>{t("schoolAdmin:delete group")}</span>
                        </button>
                      </div>
                      <div className="divider"></div>
                      {idx == editSchedule.length - 1 &&
                      newSchedule.length == 0 ? (
                            <div className="add-other-group">
                              <button
                                onClick={() =>
                                  setNewSchedule([
                                    ...newSchedule,
                                    {
                                      ...editSchedule?.[0],
                                    },
                                  ])
                                }
                              >
                                <PlusCircle size={24} color="#F0783A" />
                                <span>{t("schoolAdmin:add group")}</span>
                              </button>
                            </div>
                          ) : null}
                    </>
                  )}
                </div>
              ))}
            {newSchedule.length > 0 &&
              newSchedule.map((schedule, idx) => (
                <div
                  className="modal-inputs-list"
                  style={{ marginTop: "24px" }}
                  key={idx}
                >
                  <div className="modal-input">
                    {instructorsStore.length > 0 && courseStore.length > 0 && (
                      <SelectSearch
                        options={opt}
                        search
                        className="select-search drop-up small"
                        filterOptions={fuzzySearch}
                        name="intructorId"
                        // value={`${[
                        //   newSchedule.instructorId,
                        //   newSchedule.courseId,
                        // ]}`}
                        onChange={(_, k) => {
                          // onFilterHandler(k.value);
                          onEditInputChange(k, idx);
                        }}
                        required
                        placeholder={`${t("schoolAdmin:teacher")} - ${t(
                          "schoolAdmin:lesson upper case"
                        )}`}
                      />
                    )}
                    {!schedule.isGroup && (
                      <div className="remove-schedule">
                        <button
                          onClick={() => {
                            dispatch(deleteSchedule(schedule.id));
                            setModal({ ...modal, editSchedule: false });
                            setEditSchedule({});
                          }}
                        >
                          <Trash size={24} color="#FF7171" />
                          <span>{t("presetting:delete presetting")}</span>
                        </button>
                      </div>
                    )}
                  </div>

                  <div className="modal-input">
                    {instructorsStore.length > 0 && courseStore.length > 0 && (
                      <SelectSearch
                        options={scheduleGroupsStore.map((item) => ({
                          name: item.groupTitle,
                          value: [item.groupTitle, item.groupId],
                          id: item.groupId,
                          key: "group",
                        }))}
                        search
                        className="select-search"
                        filterOptions={fuzzySearch}
                        name="group"
                        onChange={(_, k) => {
                          onEditInputChange(k, idx);
                        }}
                        required
                        placeholder={`${t("schoolAdmin:a group")}`}
                      />
                    )}
                  </div>
                  <div className="remove-other-group">
                    <button
                      onClick={() => {
                        let schedule = newSchedule;
                        schedule.splice(idx, 1);
                        setNewSchedule([...schedule]);
                      }}
                    >
                      <Trash size={24} color="#FF7171" />
                      <span>{t("schoolAdmin:delete group")}</span>
                    </button>
                  </div>
                  <div className="divider"></div>
                  {idx == newSchedule.length - 1 ? (
                    <div className="add-other-group">
                      <button
                        onClick={() =>
                          setNewSchedule([
                            ...newSchedule,
                            {
                              ...newSchedule?.[0],
                              scheduleGroupId: null,
                              groupTitle: "",
                            },
                          ])
                        }
                      >
                        <PlusCircle size={24} color="#F0783A" />
                        <span>{t("schoolAdmin:add group")}</span>
                      </button>
                    </div>
                  ) : null}
                </div>
              ))}
          </div>

          <div className="modal-footer">
            <button
              className="large secondary"
              onClick={() => {
                setModal({ ...modal, editSchedule: false });
                setEditSchedule([{}]);
                setNewSchedule([]);
              }}
            >
              {t("presetting:consel presetting")}
            </button>
            <button
              className="large primary"
              onClick={async () => {
                await editSchedule.map((item) =>
                  dispatch(updateSchedule(item))
                );
                newSchedule.length > 0 &&
                  newSchedule.map((item) => dispatch(addSchedule(item)));
                await setModal({ ...modal, editSchedule: false });
                await setEditSchedule([{}]);
                await setNewSchedule([]);
              }}
            >
              {t("common:ready")}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
