/* eslint-disable indent */
import React, { useEffect } from "react";
import { useTable } from "react-table";
import PT from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { statuses } from "../../config";
import { getAllStudentsByClass } from "../../store/student/actions";
import { getQuarterMark } from "../../store/report/actions";
import { getCourses, getCoursesByClassId } from "../../store/course/actions";

GradeByClassTable.propTypes = {
  classId: PT.number.required,
  quarterId: PT.number.required,
};

export default function GradeByClassTable(props) {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  const { classId, quarterId } = props;
  const dispatch = useDispatch();
  const coursesStore = useSelector((store) => store.courses.courses);
  const courseByClassIdStore = useSelector((store) =>
    store.courses.coursesByClassId.data.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.courseId === value.courseId)
    )
  );
  const courseByClassIdStatus = useSelector(
    (store) => store.courses.coursesByClassId.status
  );

  const studentsStore = useSelector((store) => store.students.students);
  const quarterMarkStore = useSelector((store) => store.report.studentsRecord);
  const quarterMarkStatus = useSelector((store) => store.report.status);

  useEffect(async () => {
    await dispatch(getCourses());
    await dispatch(getCoursesByClassId(classId));

    await dispatch(
      getQuarterMark(
        classId,
        quarterId,
        JSON.parse(localStorage.getItem("chronicleActive"))?.id
      )
    );

    await dispatch(getAllStudentsByClass(classId));
  }, [classId, quarterId]);

  const b = courseByClassIdStore.map((item) =>
    coursesStore.find((c) => c.id === item.courseId)
  );
  let a =
    b.length > 0
      ? b?.map((course) => {
          return {
            Header:
              String(i18n.language).toUpperCase() !== "EN" &&
              String(i18n.language).toUpperCase() !== "TR"
                ? course?.[`title${String(i18n.language).toUpperCase()}`]
                : course?.title,
            // metaId: `${day.id}`,
            accessor: `courseId${course?.courseId}`,
          };
        })
      : [];
  const columns = React.useMemo(
    () => [
      {
        Header: "№",
        accessor: "number",
        // width: 150,
      },
      {
        Header: t("validation:full name"),
        accessor: "students",
        // width: 150,
      },
      ...a,
    ],
    [studentsStore]
  );

  const data = React.useMemo(() => {
    let a = [];
    studentsStore
      .sort((a, b) => a.studentTitle - b.studentTitle)
      .map((student, idx) => {
        if (
          quarterMarkStore.filter((grade) => grade.studentId == student.id)
            .length > 0
        ) {
          let b = {
            number: idx + 1,
            students: `${student.lastName} ${student.firstName}`,
            // courseId: student.id,
          };
          quarterMarkStore
            .filter((grade) => grade.studentId == student.id)
            .map((grade) => {
              b[`courseId${grade.courseId}`] = grade.quarterMark
                ? grade.quarterMark
                : "";
            });
          a.push(b);
        } else {
          a.push({
            number: idx + 1,
            students: `${student.lastName} ${student.firstName}`,
            // courseId: student.id,
          });
        }
      });
    return a;
  }, [quarterMarkStore, studentsStore, courseByClassIdStore]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  return (
    <>
      {quarterMarkStatus === statuses.SUCCESS &&
        courseByClassIdStatus === statuses.SUCCESS && (
          <>
            <table {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup, idx1) => (
                  <tr {...headerGroup.getHeaderGroupProps()} key={idx1}>
                    {headerGroup.headers.map((column, idx2) => (
                      <th
                        {...column.getHeaderProps()}
                        key={idx2}
                        style={{
                          writingMode: "vertical-lr",
                          transform: "rotate(180deg)",
                        }}
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              <tbody {...getTableBodyProps()}>
                {rows.map((row, idx3) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} key={idx3}>
                      {row.cells.map((cell, idx4) => {
                        return (
                          <td
                            {...row.getRowProps({
                              style: {
                                width: `${cell.column.width}`,
                              },
                            })}
                            key={idx4}
                            id={`${cell.column.parent?.columns?.[0]?.id}-${cell.row.original.studentCourseId}`}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        )}
    </>
  );
}
