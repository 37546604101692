import React from "react";
import { FooterLogo } from "../icons";
import whatsappLogo from "../../assets/images/whatsapp.png";
import { useTranslation, Trans } from "react-i18next";

export default function InstractorFooter() {
  const { t } = useTranslation(["presetting", "common"]);
  return (
    <div className="school-presetting__footer school-presetting__container">
      <div className="school-presetting__footer-logo">
        <FooterLogo />
      </div>
      <div className="school-presetting__footer-text">
        <p>
          <Trans
            i18nKey="presetting:contact us"
            t={t}
            components={[
              <a
                href="https://wa.me/message/DDC3NAUPTRPRB1"
                target="_blank"
                rel="noreferrer"
                key="0"
              ></a>,
            ]}
          ></Trans>
        </p>
        <img src={whatsappLogo} alt="whatsapp" />
      </div>
    </div>
  );
}
