import { urls, toastOption } from "../../config";
import { API } from "../../utils";
import { toast } from "react-toastify";
import i18n from "../../i18n";

import {
  GET_QUARTERS_REQUEST,
  GET_QUARTERS_SUCCESS,
  GET_QUARTERS_ERROR,
  GET_QUARTER_BY_ID_REQUEST,
  GET_QUARTER_BY_ID_SUCCESS,
  GET_QUARTER_BY_ID_ERROR,
} from "../actionTypes";

export const getQuarters = () => async (dispatch) => {
  dispatch(getQuartersRequest());

  await API.get(
    `${urls.QUARTER}school/${
      JSON.parse(localStorage.getItem("school"))?.id
    }/chronicle/${JSON.parse(localStorage.getItem("chronicleActive"))?.id}`
  )
    .then((res) => {
      console.log(res.data);

      dispatch(
        getQuartersSuccess(res.data.sort((a, b) => a.quarter - b.quarter))
      );
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getQuartersFailure(error.message));
      return error.message;
    });
};

export const getQuarterById = (id) => async (dispatch) => {
  dispatch(getQuarterByIdRequest());
  await API.get(`${urls.QUARTER}${id}`)
    .then((res) => {
      console.log(res.data);
      dispatch(getQuarterByIdSuccess(res.data));
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getQuarterByIdFailure(error.message));
      return error.message;
    });
};

export const deleteQuarter = (id) => async (dispatch) => {
  dispatch(getQuartersRequest());
  await API.delete(`${urls.QUARTER}?id=${id}`)
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess deleted"), toastOption);
      dispatch(getQuarters());
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getQuartersFailure(error.message));
      return error.message;
    });
};
export const editQuartersMassive = (values) => async (dispatch) => {
  dispatch(getQuartersRequest());
  const data = {
    chronicleId: JSON.parse(localStorage.getItem("chronicleActive"))?.id,
    quarters: values,
    schoolId: JSON.parse(localStorage.getItem("school"))?.id,
  };
  await API.post(`${urls.QUARTER}bulk-operation`, data)
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess updated"), toastOption);
      dispatch(getQuarters());
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getQuartersFailure(error.message));
      return error.message;
    });
};

export const createQuarter = (values) => async () => {
  const data = {
    ...values,
    schoolId: `${JSON.parse(localStorage.getItem("school"))?.id}`,
    chronicleId: `${JSON.parse(localStorage.getItem("chronicleActive"))?.id}`,
  };

  await API.post(`${urls.QUARTER}create`, data)
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess added"), toastOption);
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      return error.message;
    });
};
export const getQuartersRequest = () => {
  return {
    type: GET_QUARTERS_REQUEST,
  };
};

export const getQuartersSuccess = (data) => {
  return {
    type: GET_QUARTERS_SUCCESS,
    payload: data,
  };
};

export const getQuartersFailure = (error) => {
  return {
    type: GET_QUARTERS_ERROR,
    payload: error,
  };
};

export const getQuarterByIdRequest = () => {
  return {
    type: GET_QUARTER_BY_ID_REQUEST,
  };
};

export const getQuarterByIdSuccess = (data) => {
  return {
    type: GET_QUARTER_BY_ID_SUCCESS,
    payload: data,
  };
};

export const getQuarterByIdFailure = (error) => {
  return {
    type: GET_QUARTER_BY_ID_ERROR,
    payload: error,
  };
};
