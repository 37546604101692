// import React, { useEffect, useMemo, useState } from "react";
// import { GoBack, SchoolAdminLayout } from "../";
// import { useTranslation } from "react-i18next";
// import SelectSearch, { fuzzySearch } from "react-select-search";
// import { useTable } from "react-table";
// import PT from "prop-types";

// import "./ClassJournalReport.scss";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   getInstructors,
//   getkInstructorCourses,
//   getkInstructorsMark,
// } from "../../store/instructor/actions";
// import { getQuarterTitles } from "../../store/report/actions";
// import NoData from "../NoData/NoData";

// function Table({ columns, data, className }) {
//   const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
//     useTable({
//       columns,
//       data,
//     });

//   return (
//     <table
//       id="class_journal_report_id"
//       className={className}
//       {...getTableProps()}
//     >
//       <thead>
//         {headerGroups.map((headerGroup, idx) => (
//           <tr key={idx} {...headerGroup.getHeaderGroupProps()}>
//             {headerGroup.headers.map((column, idx) => (
//               <th key={idx} {...column.getHeaderProps()}>
//                 {column.render("Header")}
//               </th>
//             ))}
//           </tr>
//         ))}
//       </thead>
//       <tbody {...getTableBodyProps()}>
//         {rows.map((row, i) => {
//           prepareRow(row);
//           return (
//             <tr key={i} {...row.getRowProps()}>
//               {row.cells.map((cell, idx) => {
//                 return (
//                   <td key={idx} {...cell.getCellProps()}>
//                     {cell.render("Cell")}
//                   </td>
//                 );
//               })}
//             </tr>
//           );
//         })}
//       </tbody>
//     </table>
//   );
// }

// const ClassJournalReport = () => {
//   const dispatch = useDispatch();
//   const { t, i18n } = useTranslation();
//   const [teacher, setTeacher] = useState();
//   const [classData, setClassData] = useState();
//   const [quarterData, setQuarterData] = useState();

//   const [courseTitle, setcourseTitle] = useState();
//   const [instructorId, setInstructorId] = useState();
//   const [teacherFullName, setTeacherFullName] = useState(null);
//   const [quarterDate, setQuarterDate] = useState(null);

//   const [classInfo, setClassInfo] = useState();

//   const studentsGradeMark = useSelector(
//     (state) => state.instructors.instructorsGradeMark
//   );
//   const instructors = useSelector((state) => state.instructors.instructors);
//   const quarters = useSelector((state) => state.report.quarter);
//   const instructorCourses = useSelector(
//     (state) => state.instructors.instructorCourses
//   );
//   const students = studentsGradeMark.filter(
//     (item) => item.courseTitleRU == courseTitle
//   );

//   const columns = useMemo(
//     () => [
//       {
//         Header: `${t("instructor:students full name")}`,
//         accessor: "studentTitle",
//       },
//       {
//         Header: `${t("instructor:date")}`,
//         accessor: "gradeDate",
//       },
//       {
//         Header: `${t("instructor:grade")}`,
//         accessor: "gradeMark",
//       },
//     ],
//     [students]
//   );

//   const data = useMemo(() => students, [students]);

//   useEffect(() => {
//     dispatch(getInstructors());
//     dispatch(
//       getQuarterTitles(
//         JSON.parse(localStorage.getItem("school")).id,
//         JSON.parse(localStorage.getItem("chronicleActive")).id
//       )
//     );
//   }, []);

//   const getClassesByInstructorId = (id) => {
//     dispatch(getkInstructorCourses(id));
//   };

//   function handleQuater(k) {
//     setQuarterData(k.value);
//     dispatch(
//       getkInstructorsMark(instructorId, k.startDate, k.endDate, classData)
//     );
//   }

//   function instructorsLessonsOptions() {
//     let a = [];
//     instructors.sort().map((teacher) => {
//       teacher.instructorCourseTitleDtoList.map((lesson) =>
//         a.push({
//           name: `${teacher.instructorTitle}-${
//             String(i18n.language).toUpperCase() !== "EN" &&
//             String(i18n.language).toUpperCase() !== "TR"
//               ? `${lesson[`courseTitle${String(i18n.language).toUpperCase()}`]}`
//               : `${lesson.courseTitle}`
//           }`,
//           value: lesson.courseId,
//           key: lesson.courseId,
//           lessonId: lesson.courseId,
//           instructorId: teacher.instructorId,
//           courseTitleRU: lesson.courseTitleRU,
//         })
//       );
//     });
//     return a;
//   }

//   function instructorsCoursesOptions() {
//     let a = [];
//     instructorCourses
//       ?.sort(function (a, b) {
//         return a.classLevel - b.classLevel;
//       })
//       .map((course) => {
//         a.push({
//           name: `${course.classLevel}${course.classLabel}`,
//           value: course.id,
//           key: course.id,
//           schoolId: course.schoolId,
//         });
//       });
//     return a;
//   }

//   function quartersOptions() {
//     let a = [];
//     quarters
//       ?.sort(function (a, b) {
//         return a.quarter - b.quarter;
//       })
//       ?.map((quarter) => {
//         a.push({
//           name: `${quarter.quarter} ${t("instructor:quarter")}`,
//           value: quarter.quarter,
//           id: quarter.id,
//           key: quarter.id,
//           endDate: quarter.endDate,
//           startDate: quarter.startDate,
//         });
//       });
//     return a;
//   }

//   function handleInstructorsData(k) {
//     setcourseTitle(k.courseTitleRU);
//     getClassesByInstructorId(k.instructorId);
//     setTeacher(k.value);
//     setInstructorId(k.instructorId);
//     setClassData(null);
//     setQuarterData(null);
//   }

//   let people = [];
//   let names = [];

//   let date = [];

//   let newData = [];

//   function pro() {
//     data?.map((item) => {
//       people.push(item);
//     });

//     date = [...new Set(data?.map((item) => item.gradeDate))];
//     names = [...new Set(data?.map((item) => item.studentTitle))];
//     names?.map((name) => {
//       let person = {};
//       people?.map((item) => {
//         if (name == item.studentTitle) {
//           person[`studentTitle`] = item.studentTitle;
//           person[`courseTitleRU`] = item.courseTitleRU;
//           person[`courseTitleKG`] = item.courseTitleKG;
//           person[`courseTitle`] = item.courseTitle;
//           person[`${item.gradeDate}`] = item.gradeMark;
//         }
//       });
//       newData.push(person);
//     });
//     return { people, date, names, newData };
//   }

//   console.log("z", pro());

//   function exportTableToExcel() {
//     var tab_text = "<table border='1px'>";
//     let headerDate = `<th>${t("instructor:students full name")}</th>`;
//     let bodyDate = "";
//     newData?.map((object) => {
//       bodyDate = bodyDate + "<tr>";
//       bodyDate = bodyDate + `<td>${object.studentTitle}</td>`;
//       date?.map((date) => {
//         bodyDate = bodyDate + `<td>${object[date] ? object[date] : ""}</td>`;
//       });
//     });

//     date?.map((date) => {
//       headerDate = headerDate + `<th>${date}</th>`;
//       bodyDate = bodyDate + "</tr>";
//       console.log("date=>", date);
//     });

//     tab_text =
//       tab_text + "<thead><tr>" + headerDate + "</tr>" + bodyDate + "</thead>";
//     tab_text = tab_text + "</table>";

//     let fileName = `${t(
//       "instructor:class journal report"
//     )} ${teacherFullName} ${classInfo} ${quarterDate}`;

//     var a = document.createElement("a");
//     var data_type = "data:application/vnd.ms-excel";
//     a.href = data_type + ", " + encodeURIComponent(tab_text);
//     a.download = fileName + ".xls";
//     a.click();
//     // a.click();
//   }

//   return (
//     <>
//       <SchoolAdminLayout>
//         <GoBack title={`${t("common:back")}`} />
//         <div className="class_journal_report_container">
//           <span className="class_journal_report_topic">
//             {t("instructor:class journal report")}
//           </span>
//           <p className="class_journal_report_title">
//             {t("instructor:class journal report title")}
//           </p>
//           <div className="class_journal_report_buttons">
//             <SelectSearch
//               options={instructorsLessonsOptions()}
//               filterOptions={fuzzySearch}
//               value={teacher}
//               name="instructorId"
//               onChange={(_, k) => {
//                 handleInstructorsData(k);
//                 setTeacherFullName(k?.name);
//               }}
//               required
//               placeholder={`${t("login:role instructor")} - ${t(
//                 "presetting:subject"
//               ).toLowerCase()}`}
//             />
//             <SelectSearch
//               options={instructorsCoursesOptions()}
//               filterOptions={fuzzySearch}
//               value={classData}
//               name="classId"
//               onChange={(_, k) => {
//                 setClassData(k?.value);
//                 setClassInfo(k?.name);
//               }}
//               required
//               placeholder={t("schoolAdmin:class")}
//             />

//             <SelectSearch
//               options={quartersOptions()}
//               filterOptions={fuzzySearch}
//               value={quarterData}
//               name="quarterId"
//               onChange={(_, k) => {
//                 handleQuater(k);
//                 setQuarterDate(k?.name);
//               }}
//               required
//               placeholder={t("instructor:quarter")}
//             />
//             <button
//               disabled={studentsGradeMark[0] == undefined}
//               onClick={exportTableToExcel}
//               className="class_journal_report_export_button"
//             >
//               {t("common:export")}
//             </button>
//           </div>
//           <div className="class_journal_report_table">
//             {studentsGradeMark[0] == undefined ? (
//               <NoData messages={[`${t("schoolAdmin:empty")}`]} />
//             ) : (
//               <Table
//                 className="class_room_journal_table"
//                 columns={columns}
//                 data={data}
//               />
//             )}
//           </div>
//         </div>
//       </SchoolAdminLayout>
//     </>
//   );
// };

// Table.propTypes = {
//   columns: PT.array,
//   data: PT.array,
//   className: PT.string,
// };

// export default ClassJournalReport;
import React, { useEffect, useMemo, useState } from "react";
import { GoBack, SchoolAdminLayout } from "../";
import { useTranslation } from "react-i18next";
import SelectSearch, { fuzzySearch } from "react-select-search";
import { useTable } from "react-table";
import PT from "prop-types";

import "./ClassJournalReport.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getInstructors,
  getkInstructorsMark,
} from "../../store/instructor/actions";
import { getSchedulesByInstructorId } from "../../store/schedule/actions";
import { getQuarterTitles } from "../../store/report/actions";
import NoData from "../NoData/NoData";
// import DirectorLayout from "../Layouts/DirectorLayout";

function Table({ columns, data, className }) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  return (
    <table
      id="class_journal_report_id"
      className={className}
      {...getTableProps()}
    >
      <thead>
        {headerGroups.map((headerGroup, idx) => (
          <tr key={idx} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, idx) => (
              <th key={idx} {...column.getHeaderProps()}>
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr key={i} {...row.getRowProps()}>
              {row.cells.map((cell, idx) => {
                return (
                  <td key={idx} {...cell.getCellProps()}>
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

const ClassJournalReport = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [teacher, setTeacher] = useState();
  const [classData, setClassData] = useState();
  const [quarterData, setQuarterData] = useState();

  const [courseTitle, setcourseTitle] = useState();
  const [instructorId, setInstructorId] = useState();
  const [teacherFullName, setTeacherFullName] = useState(null);
  const [quarterDate, setQuarterDate] = useState(null);

  const [classInfo, setClassInfo] = useState();

  const studentsGradeMark = useSelector(
    (state) => state.instructors.instructorsGradeMark
  );
  const instructors = useSelector((state) => state.instructors.instructors);
  const quarters = useSelector((state) => state.report.quarter);
  // const instructorCourses = useSelector(
  //   (state) => state.instructors.instructorCourses
  // );
  const instructorCourses = useSelector((state) => state.schedules.schedules);
  const students = studentsGradeMark.filter(
    (item) => item.courseTitleRU == courseTitle
  );

  const columns = useMemo(
    () => [
      {
        Header: `${t("instructor:students full name")}`,
        accessor: "studentTitle",
      },
      {
        Header: `${t("instructor:date")}`,
        accessor: "gradeDate",
      },
      {
        Header: `${t("instructor:grade")}`,
        accessor: "gradeMark",
      },
    ],
    [students]
  );

  const data = useMemo(() => students, [students]);

  useEffect(() => {
    dispatch(getInstructors());
    dispatch(
      getQuarterTitles(
        JSON.parse(localStorage.getItem("school")).id,
        JSON.parse(localStorage.getItem("chronicleActive")).id
      )
    );
  }, []);

  const getClassesByInstructorId = (id) => {
    //! dispatch(getkInstructorCourses(id));
    dispatch(getSchedulesByInstructorId(id));
  };

  function handleQuater(k) {
    setQuarterData(k.value);
    dispatch(
      getkInstructorsMark(instructorId, k.startDate, k.endDate, classData)
    );
  }

  function instructorsLessonsOptions() {
    let a = [];
    instructors.sort().map((teacher) => {
      teacher.instructorCourseTitleDtoList.map((lesson) =>
        a.push({
          name: `${teacher.instructorTitle}-${
            String(i18n.language).toUpperCase() !== "EN" &&
            String(i18n.language).toUpperCase() !== "TR"
              ? `${lesson[`courseTitle${String(i18n.language).toUpperCase()}`]}`
              : `${lesson.courseTitle}`
          }`,
          value: `${lesson.courseId},${teacher.instructorId}`,
          key: lesson.courseId,
          lessonId: lesson.courseId,
          instructorId: teacher.instructorId,
          courseTitleRU: lesson.courseTitleRU,
        })
      );
    });
    return a;
  }

  function instructorsCoursesOptions() {
    let a = [];
    instructorCourses
      ?.sort(function (a, b) {
        return a.classLevel - b.classLevel;
      })
      .filter((a) => a.courseTitleRU == courseTitle)

      // ***********************************
      // .map((course) => {
      //   a.push({
      //     name: `${course.classLevel}${course.classLabel}`,
      //     value: course.id,
      //     key: course.id,
      //     schoolId: course.schoolId,
      //   });
      // });
      .map((course) => {
        a.push({
          name: `${course.classTitle}`,
          value: course.classId,
          key: course.classId,
          schoolId: course.schoolId,
        });
      });
    const arrayUniqueByKey = [
      ...new Map(a.map((item) => [item[`key`], item])).values(),
    ];
    return arrayUniqueByKey;
  }

  function quartersOptions() {
    let a = [];
    quarters
      ?.sort(function (a, b) {
        return a.quarter - b.quarter;
      })
      ?.map((quarter) => {
        a.push({
          name: `${quarter.quarter} ${t("instructor:quarter")}`,
          value: quarter.quarter,
          id: quarter.id,
          key: quarter.id,
          endDate: quarter.endDate,
          startDate: quarter.startDate,
        });
      });
    return a;
  }

  function handleInstructorsData(k) {
    setcourseTitle(k.courseTitleRU);
    getClassesByInstructorId(k.instructorId);
    setTeacher(k.value);
    setInstructorId(k.instructorId);
    setClassData(null);
    setQuarterData(null);
  }

  let people = [];
  let names = [];

  let date = [];

  let newData = [];

  function pro() {
    data?.map((item) => {
      people.push(item);
    });

    date = [...new Set(data?.map((item) => item.gradeDate))];
    names = [...new Set(data?.map((item) => item.studentTitle))];
    names?.map((name) => {
      let person = {};
      people?.map((item) => {
        if (name == item.studentTitle) {
          person[`studentTitle`] = item.studentTitle;
          person[`courseTitleRU`] = item.courseTitleRU;
          person[`courseTitleKG`] = item.courseTitleKG;
          person[`courseTitle`] = item.courseTitle;
          person[`${item.gradeDate}`] = item.gradeMark;
        }
      });
      newData.push(person);
    });
    return { people, date, names, newData };
  }

  console.log("z", pro());

  function exportTableToExcel() {
    var tab_text = "<table border='1px'>";
    let headerDate = `<th>${t("instructor:students full name")}</th>`;
    let bodyDate = "";
    newData?.map((object) => {
      bodyDate = bodyDate + "<tr>";
      bodyDate = bodyDate + `<td>${object.studentTitle}</td>`;
      date?.map((date) => {
        bodyDate = bodyDate + `<td>${object[date] ? object[date] : ""}</td>`;
      });
    });

    date?.map((date) => {
      headerDate = headerDate + `<th>${date}</th>`;
      bodyDate = bodyDate + "</tr>";
      // console.log("date=>", date);
    });

    tab_text =
      tab_text + "<thead><tr>" + headerDate + "</tr>" + bodyDate + "</thead>";
    tab_text = tab_text + "</table>";

    let fileName = `${t(
      "instructor:class journal report"
    )} ${teacherFullName} ${classInfo} ${quarterDate}`;

    var a = document.createElement("a");
    var data_type = "data:application/vnd.ms-excel";
    a.href = data_type + ", " + encodeURIComponent(tab_text);
    a.download = fileName + ".xls";
    a.click();
    // a.click();
  }

  return (
    <>
      {
        <SchoolAdminLayout>
          <GoBack title={`${t("common:back")}`} />
          <div className="class_journal_report_container">
            <span className="class_journal_report_topic">
              {t("instructor:class journal report")}
            </span>
            <p className="class_journal_report_title">
              {t("instructor:class journal report title")}
            </p>
            <div className="class_journal_report_buttons">
              <SelectSearch
                options={instructorsLessonsOptions()}
                filterOptions={fuzzySearch}
                value={teacher}
                name="instructorId"
                onChange={(_, k) => {
                  handleInstructorsData(k);
                  setTeacherFullName(k?.name);
                }}
                required
                placeholder={`${t("login:role instructor")} - ${t(
                  "presetting:subject"
                ).toLowerCase()}`}
              />
              <SelectSearch
                options={instructorsCoursesOptions()}
                filterOptions={fuzzySearch}
                value={classData}
                name="classId"
                onChange={(_, k) => {
                  setClassData(k?.value);
                  setClassInfo(k?.name);
                }}
                required
                placeholder={t("schoolAdmin:class")}
              />

              <SelectSearch
                options={quartersOptions()}
                filterOptions={fuzzySearch}
                value={quarterData}
                name="quarterId"
                onChange={(_, k) => {
                  handleQuater(k);
                  setQuarterDate(k?.name);
                }}
                required
                placeholder={t("instructor:quarter")}
              />
              <button
                disabled={studentsGradeMark[0] == undefined}
                onClick={exportTableToExcel}
                className="class_journal_report_export_button"
              >
                {t("common:export")}
              </button>
            </div>
            <div className="class_journal_report_table">
              {studentsGradeMark[0] == undefined ? (
                <NoData messages={[`${t("schoolAdmin:empty")}`]} />
              ) : (
                <Table
                  className="class_room_journal_table"
                  columns={columns}
                  data={data}
                />
              )}
            </div>
          </div>
        </SchoolAdminLayout>
      }
    </>
  );
};

Table.propTypes = {
  columns: PT.array,
  data: PT.array,
  className: PT.string,
};

export default ClassJournalReport;
