/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import { AutoScheduleLayout, NoData } from "../../../components";
import { useTranslation } from "react-i18next";
import { useTable } from "react-table";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getShifts } from "../../../store/shift/actions";
import { getShiftTimes } from "../../../store/shiftTime/actions";
import { getCourses } from "../../../store/course/actions";
import { X } from "react-feather";
import Modal from "react-modal";

import SelectSearch, { fuzzySearch } from "react-select-search";

import {
  // createSchedulesCourseConstraint,
  // deleteSchedulesCourseConstraint,
  getScheduleAutoGenerate,
  addMassiveSchedule,
  deleteScheduleByShiftId,
  getSchedules,
} from "../../../store/schedule/actions";
import { getClassBySchoolId } from "../../../store/class/actions";
import { constants, statuses } from "../../../config";

export default function AutoSchedule4() {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [shiftId, setShiftId] = useState();
  const [isGenerated, setIsGenerated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingCount, setLoadingCount] = useState(0);

  const courseStore = useSelector((store) => store.courses.courses);
  const classStore = useSelector((store) => store.class.class);
  const shiftsStore = useSelector((store) => store.shifts.shifts);
  const shiftTimesStore = useSelector((store) =>
    store.shiftTimes.shiftTimes.sort((a, b) =>
      a.startTime < b.startTime ? -1 : a.startTime > b.startTime ? 1 : 0
    )
  );
  const schedulesStore = useSelector((store) => store.schedules.schedules);
  const schdedulesStatus = useSelector((store) => store.schedules.status);
  const [modal, setModal] = useState({
    confirm: false,
  });
  useEffect(async () => {
    await dispatch(getSchedules());
    await dispatch(getShifts());
    await dispatch(getShiftTimes());
    await dispatch(getClassBySchoolId());
    await dispatch(getCourses());
  }, []);
  const onSubmit = async () => {
    await dispatch(deleteScheduleByShiftId(shiftId));
    await dispatch(
      addMassiveSchedule(
        schedulesStore.map((item) => ({
          chronicleId: JSON.parse(localStorage.getItem("chronicleActive"))?.id,
          classId: item.classId,
          courseId: item.courseId,
          instructorId: item.personId,
          shiftTimeId: item.shiftTimeId,
          weekDay: days.find((day) => day.id + 1 === item.day)?.en,
        }))
      )
    );
    await history.push("/school-admin/schedule");
  };

  let columnsData = classStore
    .filter((item) =>
      shiftId ? item.shiftId == shiftId : item.shiftId == shiftsStore?.[0]?.id
    )
    .sort((a, b) => a.classLevel - b.classLevel)
    .map((item) => ({
      Header: `${item.classLevel}${item.classLabel}`,
      accessor: `${item.id}`,
      width: 112,
      Cell: (value) => {
        if (value.cell.row.original?.[`${value.column.id}`]) {
          return value.cell.row.original?.[`${value.column.id}`]?.map(
            (item) => (
              <span
                id={item.id}
                key={item.id}
                // onClick={() => alert(item.id)}
                // style={{ background: "red" }}
              >
                {item.courseTitle}{" "}
              </span>
            )
          );
        } else {
          return "";
        }
      },
    }));
  let days = constants.days;
  let daysLetter = days
    .slice(0, JSON.parse(localStorage.getItem("school"))?.studyDay)
    .map((day) => {
      let str = day?.[`${String(i18n.language).toLowerCase()}`];
      let strEn = day?.id + 1;
      let a = [];
      if (
        shiftTimesStore
          .filter((shiftTime) => shiftTime.shiftId == shiftId)
          .map(
            (shiftTime) =>
              !!schedulesStore?.find(
                (schedule) => schedule.shiftTimeId == shiftTime.id
              )
          )
          .indexOf(true) !== -1
      ) {
        shiftTimesStore
          .filter((shiftTime) => shiftId && shiftTime.shiftId == shiftId)
          .map((shiftTime, idx) => {
            let c = {};
            if (
              schedulesStore
                .filter((h) => strEn == h.day)
                .filter((h) => h.shiftTimeId == shiftTime.id)
                .map((z) => {
                  return {
                    [z.classId]: courseStore.find(
                      (item) => item.id == z.courseId
                    )?.[
                      String(i18n.language).toLowerCase() === "en" ||
                      String(i18n.language).toLowerCase() === "tr"
                        ? `title`
                        : `title${String(i18n.language).toUpperCase()}`
                    ],
                  };
                }).length > 0
            ) {
              let d = [];
              schedulesStore
                .filter((h) => h.shiftTimeId == shiftTime.id)
                .map((z) => {
                  let l = schedulesStore
                    .filter((h) => h.shiftTimeId == shiftTime.id)
                    .filter((h) => strEn == h.day)
                    .filter((h) => z.classId === h.classId)
                    .map((z) => ({
                      ...z,
                      id: z.id,
                      classId: z.classId,
                      courseTitle: courseStore.find(
                        (item) => item.id == z.courseId
                      )?.[
                        String(i18n.language).toLowerCase() === "en" ||
                        String(i18n.language).toLowerCase() === "tr"
                          ? `courseTitle`
                          : `title${String(i18n.language).toUpperCase()}`
                      ],
                    }));
                  d.push({ [z.classId]: l });
                });
              if (d.length > 0) {
                c = Object.assign(
                  {},
                  {
                    dayKey: day.en,
                    dayId: day.id,
                    days: str[idx],
                    shiftTime: idx + 1,
                    shiftTimeId: shiftTime.id,
                  },
                  ...d
                );
              } else {
                c = {
                  dayKey: day.en,
                  dayId: day.id,
                  days: str[idx],
                  shiftTime: idx + 1,
                  shiftTimeId: shiftTime.id,
                };
              }

              a.push(c);
            } else {
              c = {
                dayKey: day.en,
                dayId: day.id,
                days: str[idx],
                shiftTime: idx + 1,
                shiftTimeId: shiftTime.id,
              };
              a.push(c);
            }
          });
      } else {
        let h = shiftTimesStore
          .filter((shiftTime) =>
            shiftId
              ? shiftTime.shiftId == shiftId
              : shiftTime.shiftId == shiftsStore?.[0]?.id
          )
          .map((shiftTime, idx) => {
            return {
              dayKey: day.en,
              dayId: day.id,
              days: str[idx],
              shiftTime: idx + 1,
              shiftTimeId: shiftTime.id,
            };
          });
        a = h;
      }

      return a;
    });
  const columns = React.useMemo(
    () => [
      {
        Header: "",
        accessor: "days",
        width: 56,
      },
      {
        Header: "",
        accessor: "shiftTime",
        width: 32,
      },
      ...columnsData,
    ],
    [classStore, schedulesStore]
  );

  const data = React.useMemo(() => {
    let a = [];
    for (
      let i = 0;
      i < JSON.parse(localStorage.getItem("school"))?.studyDay;
      i++
    ) {
      a.push(...daysLetter?.[i]);
      a.push({
        days: "-",
        shiftTime: " ",
      });
    }
    return a;
  }, [classStore, schedulesStore, daysLetter]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  const shiftsOptions = shiftsStore.map((item) => ({
    name: `${item.title} ${t("presetting:shift presetting")}`,
    value: item.id,
    key: "shiftId",
  }));
  let loadingNumber = 0;

  const startLoading = async () => {
    await setIsGenerated(false);
    await setIsLoading(true);
    await setInterval(frame, 100);
    function frame() {
      if (
        schdedulesStatus === statuses.SUCCESS &&
        localStorage.getItem("autoSchedule")
      ) {
        setIsGenerated(true);
        setIsLoading(false);
        loadingNumber = 0;
      } else {
        loadingNumber++;
      }
      setLoadingCount(loadingNumber);
    }
  };
  return (
    <AutoScheduleLayout id={4}>
      <div className="school-admin__schedule school-presetting__body school-presetting__body-color">
        <div className="school-presetting__body-row">
          <div className="school-presetting__body-title">
            <h2>{t("schoolAdmin:autoschedule")}</h2>
          </div>
          <div className="school-presetting__body-description">
            <p className="body-1">
              {t("schoolAdmin:autoshcedule step4 description")}
            </p>
          </div>
        </div>
        <div className="school-presetting__subjects-list">
          <div className="school-presetting__search-filter">
            <div className="school-presetting__search">
              <SelectSearch
                options={shiftsOptions}
                search
                className="select-search filter drop-up"
                filterOptions={fuzzySearch}
                name="shiftId"
                value={shiftId}
                onChange={(_, k) => {
                  // onFilterHandler(k.value);
                  setShiftId(k.value);
                }}
                required
                placeholder={`${t("schoolAdmin:all shifts")}`}
              />

              <button
                className="primary small"
                onClick={async () => {
                  await startLoading();
                  await localStorage.removeItem("autoSchedule");
                  // await setIsLoading(true);
                  // await setIsGenerated(false);
                  await dispatch(
                    getScheduleAutoGenerate({
                      shiftId,
                    })
                  );
                }}
                disabled={!shiftId || isLoading}
              >
                {t("schoolAdmin:autoschedule generate")}
              </button>
            </div>
            {isGenerated && (
              <div className="school-presetting__filter">
                <button
                  className="primary small"
                  onClick={() => setModal({ ...modal, confirm: true })}
                >
                  {t("schoolAdmin:autoschedule set")}
                </button>
              </div>
            )}
          </div>
        </div>
        {isLoading && (
          <div className="schedule-loader">
            <p className="schedule-loader__title">
              {t("schoolAdmin:autoschedule ready for")}{" "}
              <span className="roboto">{loadingCount}%</span>
            </p>
            <div
              className="schedule-loader__bar"
              // style={{ width: loadingCount, background: "red", height: 20 }}
            >
              <span style={{ width: `${loadingCount}%` }}></span>
            </div>
            <p className="schedule-loader__description">
              {t("schoolAdmin:autoschedule wait loading")}
            </p>
          </div>
        )}
        {!isLoading && !isGenerated && (
          <NoData
            messages={[
              t("schoolAdmin:autoschedule choose shift"),
              t("schoolAdmin:autoschedule click button genrate"),
            ]}
          />
        )}
        {isGenerated && (
          <div
            style={{
              marginTop: 100,
              width: "100%",
              height: 600,
              overflow: "scroll",
            }}
            className="table-layout"
          >
            <table {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup, idx1) => (
                  <tr {...headerGroup.getHeaderGroupProps()} key={idx1}>
                    {headerGroup.headers.map((column, idx2) => (
                      <th {...column.getHeaderProps()} key={idx2}>
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              <tbody {...getTableBodyProps()}>
                {rows.map((row, idx3) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} key={idx3}>
                      {row.cells.map((cell, idx4) => {
                        return (
                          <td
                            className={
                              cell.row.original.dayKey
                                ? cell.row.original?.[`${cell.column.id}`]?.[0]
                                    ?.isGroup
                                  ? "isGroup day"
                                  : "day"
                                : cell.row.original.days === "-" &&
                                  cell.row.original.shiftTime === " "
                                ? "empty"
                                : ""
                            }
                            title={`${
                              shiftTimesStore.find(
                                (item) =>
                                  item.id === cell.row.original.shiftTimeId
                              )?.startTime
                            }-${
                              shiftTimesStore.find(
                                (item) =>
                                  item.id === cell.row.original.shiftTimeId
                              )?.endTime
                            }`}
                            {...row.getRowProps({
                              style: {
                                width: cell.column.width,
                                cursor: "pointer",
                              },
                              onClick: () => {
                                console.log("ssss", cell);
                                if (cell.row.original?.[`${cell.column.id}`]) {
                                  // sadf
                                } else {
                                  // asdfasf
                                }
                                // console.log("aa", cell);
                                // console.log(
                                //   "zz",
                                //   cell.row.original?.[
                                //     `scheduleId${cell.column.id}`
                                //   ]
                                // );
                                // console.log({
                                //   class: cell.column.Header,
                                //   classId: cell.column.id,
                                //   shift: shiftsStore.find(
                                //     (item) => item.id == shiftId
                                //   )?.title,
                                //   shiftId: shiftId,
                                //   weekDay: cell.row.original.dayKey,
                                //   weekDayId: cell.row.original.dayId,
                                //   shiftTimeId: cell.row.original.shiftTimeId,
                                //   shiftTime: cell.row.original.shiftTime,
                                // });
                              },
                            })}
                            key={idx4}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <div className="school-presetting__body-row h-center">
        <Link to="/school-admin/schedule/auto/3">
          <button className="secondary large">{t("common:back")}</button>
        </Link>
      </div>
      <Modal
        isOpen={modal.confirm}
        onRequestClose={() => {
          setModal({ ...modal, confirm: false });
        }}
        overlayClassName="modal-overlay small"
        // onAfterOpen={async () => {
        // }}
      >
        <div className="modal">
          <div className="modal-close">
            <button
              onClick={() => {
                setModal({ ...modal, confirm: false });
              }}
            >
              <X size={15} color="#9A9A9A" />
            </button>
          </div>
          <div className="modal-header">
            <div className="modal-header-title">
              <h2>{t("schoolAdmin:autoschedule")}</h2>
            </div>
            <div className="modal-header-description">
              <p className="body-2">
                {t("schoolAdmin:autoschedule set modal warning")}
              </p>
            </div>
          </div>

          <div className="modal-footer" style={{ marginTop: "24px" }}>
            <button
              className="large secondary"
              onClick={() => setModal({ ...modal, confirm: false })}
            >
              {t("common:cancel")}
            </button>
            <button className="large primary" onClick={onSubmit}>
              {t("common:install")}
            </button>
          </div>
        </div>
      </Modal>
    </AutoScheduleLayout>
  );
}
