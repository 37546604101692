import React from "react";
import { useTranslation, Trans } from "react-i18next";
import { Link } from "react-router-dom";
import phoneImg from "../../assets/images/phone.png";

import { LoginLayout } from "../../components";
import "./SelectSchool.scss";

export default function StepFinish() {
  const { t } = useTranslation(["common", "userApplicationForm"]);

  return (
    <div className="select-school-form">
      <LoginLayout>
        <div className="select-school-form__title h-center">
          <p className="body-1 t-center">
            <Trans
              i18nKey="userApplicationForm:thank message"
              components={[
                <br key="0" />,
                <br key="1" />,
                <span className="orange-text" key="2"></span>,
              ]}
            />
          </p>
        </div>

        <div className="h-center">
          <span style={{ fontSize: 88, margin: "36px 0" }}>
            <img src={phoneImg} alt="phone" />
          </span>
        </div>
        <div
          className="select-school-form__button h-center"
          style={{ justifyContent: "center" }}
        >
          <Link to="/">
            <button className="primary large">
              {t("common:go to home page")}
            </button>
          </Link>
        </div>
      </LoginLayout>
    </div>
  );
}
