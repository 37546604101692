/* eslint-disable indent */
import React from "react";
import PT from "prop-types";
import Select, { components } from "react-select";
import { useTranslation } from "react-i18next";
import { constants } from "../../config";
import moment from "moment";

const options = constants.langugageOptions;

const icon = (icon) => ({
  display: "flex",
  alignItems: "center",
  padding: "0 12px",
  fontSize: 16,

  ":before": {
    content: `url("${icon}")`,
    // fontSize: 24,
    marginRight: 16,
    width: 24,
    height: 24,
  },
  ":after": {
    display: "none",
  },
});
const customStyles = {
  option: (styles) => ({
    ...styles,
    padding: "6px 24px",
    borderRadius: 18,
  }),
  control: () => ({
    width: 208,
    display: "flex",
    alignItems: "center",
    backgroundColor: "#fff",
    borderRadius: 18,
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
    fontSize: 16,
  }),
  singleValue: (styles, { data }) => ({
    ...styles,
    ...icon(data.icon),
    transition: "opacity 300ms",
  }),
  input: (styles) => ({
    ...styles,
    color: "transparent",
  }),
  placeholder: (styles) => ({ ...styles, backgroundColor: "#F3F3F3" }),
  menuList: (style) => ({
    ...style,
    paddingTop: 0,
    paddingBottom: 0,
  }),
};

const { Option } = components;

const IconOption = (props) => (
  <Option {...props}>
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      {/* <span style={{ fontSize: 24 }}>{props.data.icon}</span> */}
      <img
        src={props.data.icon}
        alt={props.data.label}
        style={{ width: 24, height: 24 }}
      />
      <span style={{ marginLeft: 16, fontSize: 16 }}>{props.data.label}</span>
    </div>
  </Option>
);
IconOption.propTypes = {
  data: PT.any,
};

export default function LanguageDropdown() {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  return (
    <Select
      defaultValue={options.filter((item) => item.value === i18n.language)}
      options={options}
      styles={customStyles}
      components={{ Option: IconOption, IndicatorSeparator: () => null }}
      onChange={(e) => {
        i18n.changeLanguage(e.value);
        console.log("lang", e.value);
        moment.locale(
          e.value == "kg" ? "ky" : e.value == "tr" ? "tr" : e.value
        );
      }}
      theme={(theme) => ({
        ...theme,
        borderRadius: 18,
        backgroundColor: "#F3F3F3",
        height: "auto",
        colors: {
          ...theme.colors,
          text: "red",
          primary25: "rgba(240, 119, 58, 0.166)",
          primary50: "rgba(240, 119, 58, 0.166)",
          primary75: "rgba(240, 119, 58, 0.166)",
          primary: "#F0783A",
        },
      })}
    />
  );
}
