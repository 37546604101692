import { urls, toastOption } from "../../config";
import { API } from "../../utils";
import { toast } from "react-toastify";
import i18n from "../../i18n";

import {
  GET_SECTIONS_REQUEST,
  GET_SECTIONS_SUCCESS,
  GET_SECTIONS_ERROR,
  GET_SECTION_REQUEST,
  GET_SECTION_SUCCESS,
  GET_SECTION_ERROR,
  GET_SECTION_INSTRUCTOR_REQUEST,
  GET_SECTION_INSTRUCTOR_SUCCESS,
  GET_SECTION_INSTRUCTOR_ERROR,
  GET_SECTION_SCHEDULE_REQUEST,
  GET_SECTION_SCHEDULE_SUCCESS,
  GET_SECTION_SCHEDULE_ERROR,
  GET_SECTION_STUDENT_REQUEST,
  GET_SECTION_STUDENT_SUCCESS,
  GET_SECTION_STUDENT_ERROR,
} from "../actionTypes";

export const getSections = () => async (dispatch) => {
  dispatch(getSectionsRequest());

  await API.get(
    `${urls.SECTION}school/${JSON.parse(localStorage.getItem("school"))?.id}`
  )
    .then((res) => {
      console.log(res.data);

      dispatch(getSectionsSuccess(res.data));
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getSectionsFailure(error.message));
      return error.message;
    });
};
export const getSectionById = (id) => async (dispatch) => {
  dispatch(getSectionRequest());

  await API.get(`${urls.SECTION}${id}`)
    .then((res) => {
      console.log(res.data);

      dispatch(getSectionSuccess(res.data));
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getSectionFailure(error.message));
      return error.message;
    });
};
export const deleteSection =
  (id, history, history_link) => async (dispatch) => {
    dispatch(getSectionsRequest());
    await API.delete(`${urls.SECTION}?id=${id}`)
      .then((res) => {
        console.log(res.data);
        toast.success(i18n.t("toastify:succsess deleted"), toastOption);
        dispatch(getSections());
        history && history.push(history_link);
        return res.data;
      })
      .catch((error) => {
        console.log(error);
        toast.error(i18n.t("toastify:error"), toastOption);
        dispatch(getSectionsFailure(error.message));
        return error.message;
      });
  };
export const editSection = (values) => async (dispatch) => {
  dispatch(getSectionsRequest());
  const data = values;
  await API.post(`${urls.SECTION}edit/${values.id}`, data)
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess updated"), toastOption);
      dispatch(getSections());
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getSectionsFailure(error.message));
      return error.message;
    });
};

export const createSection = (values) => async (dispatch) => {
  const data = {
    ...values,
    schoolId: `${JSON.parse(localStorage.getItem("school"))?.id}`,
  };

  await API.post(`${urls.SECTION}create`, data)
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess added"), toastOption);
      dispatch(getSections());
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      return error.message;
    });
};
export const getSectionInstructor = (value) => async (dispatch) => {
  dispatch(getSectionInstructorRequest());

  await API.get(
    `${urls.SECTION}instructor/section-instructor/${value.sectionInstructorId}`
  )
    .then((res) => {
      console.log(res.data);

      dispatch(getSectionInstructorSuccess(res.data));
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getSectionInstructorFailure(error.message));
      return error.message;
    });
};
export const createSectionInstructor = (values) => async (dispatch) => {
  let data;
  if (values?.isSameTime) {
    data = {
      ...values,
      scheduleWeekTimeList: values?.scheduleWeekTimeList?.map((item) => ({
        ...item,
        startTime: values?.sameTimeStart,
        endTime: values?.sameTimeEnd,
      })),
    };
  } else {
    data = values;
  }
  await API.post(`${urls.SECTION}instructor/create`, data)
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess added"), toastOption);
      dispatch(getSections());
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      return error.message;
    });
};
export const editSectionInstructor = (values) => async (dispatch) => {
  // dispatch(getSectionsRequest());
  const data = {
    instructorId: values.instructorId,
    scheduleWeekTimeList: values.scheduleWeekTimeList,
  };
  await API.post(
    `${urls.SECTION}instructor/edit/${values.sectionInstructorId}`,
    data
  )
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess updated"), toastOption);
      // dispatch(getSections());
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getSectionsFailure(error.message));
      return error.message;
    });
};
export const deleteSectionInstructor =
  (id, history, history_link) => async (dispatch) => {
    await API.delete(`${urls.SECTION}instructor?sectionInstructorId=${id}`)
      .then((res) => {
        console.log(res.data);
        toast.success(i18n.t("toastify:succsess deleted"), toastOption);
        // dispatch(getSectionScheduleSuccess(res.data));
        dispatch(getSections());
        history && history.push(history_link);
        return res.data;
      })
      .catch((error) => {
        console.log(error);
        toast.error(i18n.t("toastify:error"), toastOption);
        dispatch(getSectionInstructorFailure(error.message));
        return error.message;
      });
  };
export const getSectionSchedule = (value) => async (dispatch) => {
  dispatch(getSectionScheduleRequest());

  await API.get(`${urls.SECTION}schedule/${value.sectionInstructorId}`)
    .then((res) => {
      console.log(res.data);

      dispatch(getSectionScheduleSuccess(res.data));
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getSectionScheduleFailure(error.message));
      return error.message;
    });
};
export const createSectionSchedule = (values) => async (dispatch) => {
  dispatch(getSectionScheduleRequest());

  let data = values;
  await API.post(`${urls.SECTION}schedule/create`, data)
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess added"), toastOption);
      // dispatch(getSections());
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      return error.message;
    });
};
export const editSectionSchedule = (values) => async (dispatch) => {
  // dispatch(getSectionsRequest());
  const data = values;
  await API.post(`${urls.SECTION}schedule/edit/${values.id}`, data)
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess updated"), toastOption);
      // dispatch(getSections());
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getSectionsFailure(error.message));
      return error.message;
    });
};
export const deleteSectionSchedule = (id) => async (dispatch) => {
  // dispatch(getSectionStudentRequest());

  await API.delete(`${urls.SECTION}schedule?id=${id}`)
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess deleted"), toastOption);
      // dispatch(getSectionScheduleSuccess(res.data));
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getSectionScheduleFailure(error.message));
      return error.message;
    });
};
export const getSectionStudent = (value) => async (dispatch) => {
  dispatch(getSectionStudentRequest());

  await API.get(`${urls.SECTION}student/section/${value.sectionInstructorId}`)
    .then((res) => {
      console.log(res.data);

      dispatch(getSectionStudentSuccess(res.data));
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getSectionStudentFailure(error.message));
      return error.message;
    });
};
export const addSectionStudent = (value) => async (dispatch) => {
  dispatch(getSectionStudentRequest());
  const data = {
    ...value,
    chronicleId: JSON.parse(localStorage.getItem("chronicleActive"))?.id,
  };
  await API.post(`${urls.SECTION}student/create`, data)
    .then((res) => {
      console.log(res.data);

      dispatch(getSectionStudentSuccess(res.data));
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getSectionStudentFailure(error.message));
      return error.message;
    });
};
export const deleteSectionStudent = (id) => async (dispatch) => {
  // dispatch(getSectionStudentRequest());

  await API.delete(`${urls.SECTION}student?id=${id}`)
    .then((res) => {
      console.log(res.data);

      dispatch(getSectionStudentSuccess(res.data));
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getSectionStudentFailure(error.message));
      return error.message;
    });
};

export const getSectionsRequest = () => {
  return {
    type: GET_SECTIONS_REQUEST,
  };
};

export const getSectionsSuccess = (data) => {
  return {
    type: GET_SECTIONS_SUCCESS,
    payload: data,
  };
};

export const getSectionsFailure = (error) => {
  return {
    type: GET_SECTIONS_ERROR,
    payload: error,
  };
};
export const getSectionRequest = () => {
  return {
    type: GET_SECTION_REQUEST,
  };
};

export const getSectionSuccess = (data) => {
  return {
    type: GET_SECTION_SUCCESS,
    payload: data,
  };
};

export const getSectionFailure = (error) => {
  return {
    type: GET_SECTION_ERROR,
    payload: error,
  };
};
export const getSectionInstructorRequest = () => {
  return {
    type: GET_SECTION_INSTRUCTOR_REQUEST,
  };
};

export const getSectionInstructorSuccess = (data) => {
  return {
    type: GET_SECTION_INSTRUCTOR_SUCCESS,
    payload: data,
  };
};

export const getSectionInstructorFailure = (error) => {
  return {
    type: GET_SECTION_INSTRUCTOR_ERROR,
    payload: error,
  };
};

export const getSectionScheduleRequest = () => {
  return {
    type: GET_SECTION_SCHEDULE_REQUEST,
  };
};

export const getSectionScheduleSuccess = (data) => {
  return {
    type: GET_SECTION_SCHEDULE_SUCCESS,
    payload: data,
  };
};

export const getSectionScheduleFailure = (error) => {
  return {
    type: GET_SECTION_SCHEDULE_ERROR,
    payload: error,
  };
};

export const getSectionStudentRequest = () => {
  return {
    type: GET_SECTION_STUDENT_REQUEST,
  };
};

export const getSectionStudentSuccess = (data) => {
  return {
    type: GET_SECTION_STUDENT_SUCCESS,
    payload: data,
  };
};

export const getSectionStudentFailure = (error) => {
  return {
    type: GET_SECTION_STUDENT_ERROR,
    payload: error,
  };
};
