/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import { useDispatch } from "react-redux";

import {
  SchoolPresettingFooter,
  SchoolPresettingHeader,
  SchoolPresettingList,
} from "../../components";
import { useTranslation, Trans } from "react-i18next";
import { SchoolPresettingBanner } from "../../components/icons";
// import { checkSchoolPresetting } from "../../store/schoolPresetting/actions";

import "./SchoolPresetting.scss";

export default function SchoolPresetting() {
  // const dispatch = useDispatch();
  const [stepNumber, setStepNumber] = useState(0);
  useEffect(async () => {
    // await dispatch(checkSchoolPresetting());
    const stepNum = await JSON.parse(localStorage.getItem("schoolPresetting"))
      ?.stepNumber;
    setStepNumber(stepNum);
  }, []);
  const { t } = useTranslation(["presetting", "common"]);
  return (
    <div className="school-presetting">
      <SchoolPresettingHeader />
      <div className="school-presetting__body school-presetting__container">
        <div className="school-presetting__body-banner">
          <div className="school-presetting__body-banner-text">
            <div className="school-presetting__body-banner-title">
              <h1>{t("presetting:info aboute presetting")}</h1>
            </div>
            <div className="school-presetting__body-banner-description">
              <p className="body-1">{t("presetting:lorem ipsum presetting")}</p>
            </div>
            <div className="school-presetting__body-banner-description">
              <p className="body-1">
                <Trans
                  i18nKey="presetting:lorem ipsum presetting time"
                  t={t}
                  components={[
                    <span className="underline-text" key="0"></span>,
                  ]}
                ></Trans>
              </p>
            </div>
            <div className="school-presetting__body-banner-button">
              <Link to={`/school-presetting/${stepNumber}`}>
                {stepNumber > 1 ? (
                  <button className="primary large">{t("common:next")}</button>
                ) : (
                  <button className="primary large">{t("common:start")}</button>
                )}
              </Link>
            </div>
          </div>
          <div className="school-presetting__body-banner-image">
            <SchoolPresettingBanner />
          </div>
        </div>
        <SchoolPresettingList stepNumber={stepNumber} />
      </div>
      <SchoolPresettingFooter />
    </div>
  );
}
