import ru from "../assets/images/ru.png";
import kg from "../assets/images/kg.png";
import en from "../assets/images/en.png";
import tr from "../assets/images/tr.png";

export const phone_numbers_list = [
  "0550 075 713",
  "0709 379 477",
  "0777 705 709",
];

export const privacyAndPolicy =
  "https://docs.google.com/document/d/15iOWIKuQOyy2kJLrSzeAmOcvgEIykoy5Rcj01IcNP8g/export?format=pdf";

export const googlePlay =
  "https://play.google.com/store/apps/details?id=kg.kundoluk.app";

export const appStore = "https://apps.apple.com/kg/app/kundoluk/id1437049102";

export const langugageOptions = [
  { value: "ru", label: "Русский", icon: ru },
  { value: "kg", label: "Кыргызча", icon: kg },
  { value: "en", label: "English", icon: en },
  { value: "tr", label: "Türk", icon: tr },
];

export const days = [
  {
    id: 0,
    en: "MONDAY",
    ru: "ПОНЕДЕЛ",
    kg: "ПОНЕДЕЛ",
    tr: "PAZARTESI",
    enShort: "Mn",
    ruShort: "Пн",
    kgShort: "Пн",
    trShort: "Pt",
  },
  {
    id: 1,
    en: "TUESDAY",
    ru: "ВТОРНИК",
    kg: "ВТОРНИК",
    tr: "SALI",
    enShort: "Tu",
    ruShort: "Вт",
    kgShort: "Вт",
    trShort: "Sa",
  },
  {
    id: 2,
    en: "WEDNESDAY",
    ru: "СРЕДА",
    kg: "СРЕДА",
    tr: "ÇARŞAMBA",
    enShort: "We",
    ruShort: "Ср",
    kgShort: "Ср",
    trShort: "Ça",
  },
  {
    id: 3,
    en: "THURSDAY",
    ru: "ЧЕТВЕРГ",
    kg: "ЧЕТВЕРГ",
    tr: "PERŞEMBE",
    enShort: "Th",
    ruShort: "Чт",
    kgShort: "Чт",
    trShort: "Pe",
  },
  {
    id: 4,
    en: "FRIDAY",
    ru: "ПЯТНИЦА",
    kg: "ПЯТНИЦА",
    tr: "CUMA",
    enShort: "Fr",
    ruShort: "Пт",
    kgShort: "Пт",
    trShort: "Cu",
  },
  {
    id: 5,
    en: "SATURDAY",
    ru: "СУББОТА",
    kg: "СУББОТА",
    tr: "CUMARTESİ",
    enShort: "Sa",
    ruShort: "Cб",
    kgShort: "Cб",
    trShort: "Ct",
  },
];

export const fullDays = [
  {
    id: 0,
    en: "MONDAY",
    ru: "ПОНЕДЕЛЬНИК",
    kg: "ПОНЕДЕЛЬНИК",
    tr: "PAZARTESI",
  },
  { id: 1, en: "TUESDAY", ru: "ВТОРНИК", kg: "ВТОРНИК", tr: "SALI" },
  { id: 2, en: "WEDNESDAY", ru: "СРЕДА", kg: "СРЕДА", tr: "ÇARŞAMBA" },
  { id: 3, en: "THURSDAY", ru: "ЧЕТВЕРГ", kg: "ЧЕТВЕРГ", tr: "PERŞEMBE" },
  { id: 4, en: "FRIDAY", ru: "ПЯТНИЦА", kg: "ПЯТНИЦА", tr: "CUMA" },
  { id: 5, en: "SATURDAY", ru: "СУББОТА", kg: "СУББОТА", tr: "CUMARTESİ" },
];

export const fullMonth = [
  {
    id: 0,
    en: "January",
    ru: "Январь",
    kg: "Үчтүн айы",
    tr: "Ocak",
  },
  { id: 1, en: "February", ru: "Февраль", kg: "Бирдин айы", tr: "Şubat" },
  { id: 2, en: "March", ru: "Март", kg: "	Жалган Куран", tr: "Mart" },
  { id: 3, en: "April", ru: "Апрель", kg: "Чын Куран", tr: "Nisan" },
  { id: 4, en: "May", ru: "Май", kg: "Бугу", tr: "Mayıs" },
  { id: 5, en: "June", ru: "Июнь", kg: "Кулжа", tr: "Haziran" },
  { id: 6, en: "July", ru: "Июль", kg: "Теке", tr: "Temmuz" },
  { id: 7, en: "August", ru: "Август", kg: "Баш Оона", tr: "Ağustos" },
  {
    id: 8,
    en: "September",
    ru: "Сентябрь",
    kg: "Аяк Оона",
    tr: "Eylül",
  },
  { id: 9, en: "October", ru: "Октябрь", kg: "Тогуздун айы", tr: "Ekim" },
  {
    id: 10,
    en: "November",
    ru: "Ноябрь",
    kg: "Жетинин айы",
    tr: "Kasım",
  },
  {
    id: 11,
    en: "December",
    ru: "Декабрь",
    kg: "Бештин айы",
    tr: "Aralık",
  },
];
