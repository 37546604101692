import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import SelectSearch, { fuzzySearch } from "react-select-search";

import { NoData, SchoolAdminLayout, GoBack } from "../../components";
import { getClassBySchoolId } from "../../store/class/actions";
import { getQuarterById, getQuarters } from "../../store/quarter/actions";
import Table from "../../components/Reports/StudentsAttendanceComponent";
import "./Reports.scss";
import { getAllStudentsByClass } from "../../store/student/actions";
import { studentsAttendance } from "../../store/report/actions";
// !

function StudentsAttendance() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // const [exportDisabled, setExportDisabled] = useState(true);
  const [classTitle, setClassTitle] = useState();
  // const [quaterTitle, setQuaterTitle] = useState();
  const [quaterTitle, setQuaterTitle] = useState();
  const [quaterId, setQuaterId] = useState();
  const [classId, setClassId] = useState();
  const [quaterItem, setQuaterItem] = useState();
  // console.log("quaterId", quaterId);
  // console.log("classId", classId);

  useEffect(() => {
    dispatch(getClassBySchoolId());
    dispatch(getQuarters());
  }, []);

  function getIt(classDate) {
    dispatch(getQuarterById(quaterItem));
    dispatch(getAllStudentsByClass(classDate));
    dispatch(
      studentsAttendance(classDate, quaterTitle.startDate, quaterTitle.endDate)
    );
  }

  // !
  // const quartersStore = useSelector((store) =>
  //   store.quarters.quarters.sort(
  //     (a, b) => Number(a.quarter) - Number(b.quarter)
  //   )
  // );
  // !
  // console.log("quartersStore", quartersStore);
  const year = JSON.parse(localStorage.getItem("chronicleActive"));
  const quarter = useSelector((state) => state.quarters.quarters);
  const classesState = useSelector((state) => state.class.class);
  const schoolName = JSON.parse(localStorage.getItem("school"))?.name;

  const onQuaterChange = (e) => {
    setQuaterTitle(e);

    setQuaterItem(e.value);
  };

  const quaterTypesOptions = quarter?.map((item) => ({
    name: `${item?.quarter} ${t("instructor:quarter")}`,
    value: item.id,
    startDate: item?.startDate,
    endDate: item?.endDate,
    key: item?.id,
  }));

  const classesTypesOptions = classesState.map((item, idx) => ({
    name: `${item.classLevel} ${item.classLabel} `,
    value: item.id,
    key: item.id + idx,
  }));

  function exportTableToExcel(tableID, filename = "") {
    let downloadLink;
    let dataType = "application/vnd.ms-excel";
    let tableSelect = document.querySelector(tableID);
    tableSelect.border = 1;
    let tableHTML = tableSelect.outerHTML.replace(/ /g, "%20");

    // Specify file name
    filename = filename ? filename + ".xls" : "excel_data.xls";

    // Create download link element
    downloadLink = document.createElement("a");

    document.body.appendChild(downloadLink);

    if (navigator.msSaveOrOpenBlob) {
      let blob = new Blob(["\ufeff", tableHTML], {
        type: dataType,
      });
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      // Create a link to the file
      downloadLink.href = "data:" + dataType + ", " + tableHTML;

      // Setting the file name
      downloadLink.download = filename;

      //triggering the function
      downloadLink.click();
    }
  }
  return (
    <div>
      <SchoolAdminLayout>
        <div className="student_record">
          <div className="button_to_back">
            <GoBack title={t("common:back")} />
          </div>
          <div className="body">
            <span className="topic">
              {t(
                "reports:information about the number of days and lessons students missed"
              )}
            </span>
            <p className="title">
              {t(
                "reports:this report allows you to view the attendance of students in one grade for one academic quarter"
              )}
            </p>
            <div className="schedule">
              <div className="schedule_filter">
                <div className="year">
                  {year?.selectorTitle} {t("reports:y")}
                </div>
                <SelectSearch
                  options={quaterTypesOptions}
                  search
                  filterOptions={fuzzySearch}
                  value={`${quaterId && quaterId}`}
                  name="quaterId"
                  onChange={(_, k) => {
                    onQuaterChange(k);
                    setQuaterId(k.value);
                  }}
                  required
                  placeholder={`- ${t("instructor:quarter")} -`}
                />
                <SelectSearch
                  options={classesTypesOptions}
                  search
                  filterOptions={fuzzySearch}
                  value={`${classId && classId}`}
                  name="classId"
                  onChange={(_, k) => {
                    setClassTitle(k);
                    getIt(k.value);
                    setClassId(k.value);
                    // setExportDisabled(k && false);
                  }}
                  required
                  placeholder={`- ${t("schoolAdmin:class")} -`}
                />
              </div>
              <button
                // disabled={exportDisabled}
                onClick={() => {
                  exportTableToExcel(
                    "#table-to-xls",
                    `${schoolName}_${year.startYear}-${year.endYear}_${
                      quaterTitle?.name
                    }-${t("instructor:quarter")}_${classTitle?.name} ${t(
                      "reports:information about the number of days and lessons students missed"
                    )}`
                  );
                }}
                className="schedule_export"
              >
                {t("schoolAdmin:export")}
              </button>
            </div>

            <div
              style={{
                display: "grid",
                marginTop: "1rem",
                overflowX: "scroll",
              }}
            >
              {quaterTitle && classTitle ? (
                <Table id={"table-to-xls"} quaterTitle={quaterTitle.name} />
              ) : (
                <NoData
                  messages={[
                    `${t("schoolAdmin:choose shedule and type of render")}`,
                  ]}
                />
              )}
            </div>
          </div>
        </div>
      </SchoolAdminLayout>
    </div>
  );
}

export default StudentsAttendance;
