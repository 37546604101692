import React, { PureComponent } from "react";

export default class HeaderLogo extends PureComponent {
  render() {
    return (
      <svg
        width="29"
        height="32"
        viewBox="0 0 29 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.4599 2.06548L0 0V30.8751L11.4599 28.7632L2.68811 27.0054V3.95986L11.4599 2.06548Z"
          fill="#3C3C3C"
        />
        <path
          d="M4.55469 5.03792L28.7706 0V30.9807L18.2967 28.7697L11.617 31.9223L14.3689 28.0126L4.55526 25.8919L4.55469 5.03792Z"
          fill="#F0783A"
        />
        <path
          d="M11.5963 12.6166L16.5304 8.28574L20.9845 5.72156L13.4415 14.0508L17.8889 22.763L22.0013 23.0427L11.0815 18.9194L11.5963 12.6166Z"
          fill="white"
        />
        <path
          d="M9.09263 10.2869L10.2772 15.5321L8.66431 24.9633L13.2892 14.1816L9.09263 10.2869Z"
          fill="white"
        />
      </svg>
    );
  }
}
