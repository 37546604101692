import React, { useEffect, useState } from "react";
import { ApplicationFormLayout, SelectGradeDropdown } from "../../components";
import "./UserApplicationForm.scss";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Step2() {
  const { t } = useTranslation(["common", "userApplicationForm"]);

  const lStorage = JSON.parse(localStorage.getItem("userApplicationForm"));
  const history = useHistory();

  const [gradeId, setGradeId] = useState(lStorage.gradeId);

  useEffect(() => {
    localStorage.setItem(
      "userApplicationForm",
      JSON.stringify({ ...lStorage, activeStep: 3 })
    );
  }, []);

  const setLocalStorage = () => {
    localStorage.setItem(
      "userApplicationForm",
      JSON.stringify({ ...lStorage, gradeId })
    );
  };

  return (
    <div className="step">
      <ApplicationFormLayout style={{ minHeight: 600 }} id={2}>
        <div className="step__title">
          <p className="body-1">{t("userApplicationForm:class")}</p>
        </div>
        <div className="step__body">
          <SelectGradeDropdown onSelectGrade={setGradeId} gradeId={gradeId} />
          <div className="step__body-submit">
            <button
              className="secondary small"
              onClick={() => {
                history.push("/user-application-form/1");
              }}
            >
              {t("common:back")}
            </button>
            <Link to="/user-application-form/3">
              <button
                className="primary small"
                disabled={lStorage.role === "5" ? !gradeId : false}
                onClick={setLocalStorage}
              >
                {t("common:continue")}
              </button>
            </Link>
            {lStorage.role === ""}
          </div>
        </div>
      </ApplicationFormLayout>
    </div>
  );
}
