/* eslint-disable */
import React from "react";
import Pagination from "rc-pagination";
import PT from "prop-types";

import { PaginationRightIcon, PaginationLeftIcon } from "../icons";
import "./styles.scss";

export default function PaginationBlock(props) {
  const { current, total, onChange, pageSize } = props;
  return (
    <Pagination
      // showTotal={(total, range) =>
      //   range ? `${range?.[0]} - ${range?.[1]} / ${total}` : null
      // }
      pageSize={pageSize}
      showTitle={false}
      onChange={onChange}
      current={current}
      total={total}
      prevIcon={<PaginationLeftIcon />}
      nextIcon={<PaginationRightIcon />}
      jumpNextIcon={<p>...</p>}
      jumpPrevIcon={<p>...</p>}
      className="react-pagination"
    />
  );
}

PaginationBlock.propTypes = {
  onChange: PT.func,
  current: PT.number,
  total: PT.number,
  pageSize: PT.number,
};
