import {
  GET_CALENDAR_TOPICS_REQUEST,
  GET_CALENDAR_TOPICS_SUCCESS,
  GET_CALENDAR_TOPICS_ERROR,
  GET_CALENDAR_TOPIC_REQUEST,
  GET_CALENDAR_TOPIC_SUCCESS,
  GET_CALENDAR_TOPIC_ERROR,
} from "../actionTypes";
import { statuses } from "../../config";

const initialState = {
  calendarTopics: [],
  calendarTopic: [],
  error: "",
  status: statuses.INITIAL,
};

export const calendarTopicReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CALENDAR_TOPICS_REQUEST:
      return {
        ...state,
        calendarTopics: [],
        error: "",
        status: statuses.LOADING,
      };
    case GET_CALENDAR_TOPICS_SUCCESS:
      return {
        ...state,
        calendarTopics: action.payload,
        error: "",
        status: statuses.SUCCESS,
      };
    case GET_CALENDAR_TOPICS_ERROR:
      return {
        ...state,
        error: action.payload,
        status: statuses.ERROR,
        calendarTopics: [],
      };
    case GET_CALENDAR_TOPIC_REQUEST:
      return {
        ...state,
        calendarTopic: [],
        error: "",
        status: statuses.LOADING,
      };
    case GET_CALENDAR_TOPIC_SUCCESS:
      return {
        ...state,
        calendarTopic: action.payload,
        error: "",
        status: statuses.SUCCESS,
      };
    case GET_CALENDAR_TOPIC_ERROR:
      return {
        ...state,
        error: action.payload,
        status: statuses.ERROR,
        calendarTopic: [],
      };

    default:
      return state;
  }
};
