import React, { useEffect } from "react";
import { AutoScheduleLayout } from "../../../components";
import { useTranslation } from "react-i18next";
import { useTable } from "react-table";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getShifts } from "../../../store/shift/actions";
import { getShiftTimes } from "../../../store/shiftTime/actions";
import { getCourses } from "../../../store/course/actions";
import { X } from "react-feather";
import {
  getSchedulesCourseConstraint,
  createSchedulesCourseConstraint,
  deleteSchedulesCourseConstraint,
} from "../../../store/schedule/actions";
import { constants } from "../../../config";

export default function AutoSchedule3() {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const coursesStore = useSelector((store) => store.courses.courses);
  const shiftsStore = useSelector((store) =>
    store.shifts.shifts?.sort((a, b) => Number(a.title) - Number(b.title))
  );
  const shiftTimesStore = useSelector((store) =>
    store.shiftTimes.shiftTimes.sort((a, b) =>
      a.startTime < b.startTime ? -1 : a.startTime > b.startTime ? 1 : 0
    )
  );
  const schdedulesStore = useSelector((store) => store.schedules.schedules);

  useEffect(async () => {
    await dispatch(getSchedulesCourseConstraint());
    await dispatch(getShifts());
    await dispatch(getShiftTimes());
    await dispatch(getCourses());
  }, []);
  const onSubmit = () => {
    localStorage.setItem("autoScheduleStep", JSON.stringify({ step: 4 }));
  };
  let a = constants.fullDays
    .slice([0], [JSON.parse(localStorage.getItem("school"))?.studyDay])
    .map((day) => {
      return {
        Header: day.ru,
        metaId: `${day.id}`,
        accessor: day.en,
        columns: shiftsStore.map((shift) => {
          return {
            Header: `${shift.title} ${t("schoolAdmin:shift")}`,
            metaId: `${shift.id}`,
            accessor: `${day.id}-${shift.id}`,
            columns: shiftTimesStore
              .filter((shiftTime) => shiftTime.shiftId === shift.id)
              .map((shiftTime) => {
                return {
                  Header: `${shiftTime.title}`,
                  // Header: `${idx + 1}`,
                  metaId: `${shiftTime.id}`,
                  accessor: `${day.id}-${shiftTime.id}`,
                };
              }),
          };
        }),
      };
    });
  const columns = React.useMemo(
    () => [
      {
        Header: "",
        accessor: "courses",
        width: 150,
      },
      ...a,
    ],
    [schdedulesStore, coursesStore, shiftsStore, shiftTimesStore]
  );

  const data = React.useMemo(() => {
    let a = [];
    coursesStore
      // .sort((a, b) => a.instructorTitle - b.instructorTitle)
      .map((course) => {
        if (
          schdedulesStore.filter((schedule) => schedule.courseId === course.id)
            .length > 0
        ) {
          schdedulesStore
            .filter((schedule) => schedule.courseId === course.id)
            .map((schedule) => {
              if (a.find((item) => item.courseId === course.id)) {
                const objIndex = a.findIndex(
                  (obj) => obj.courseId === course.id
                );
                a[objIndex][`${schedule.weekDay - 1}-${schedule.shiftTimeId}`] =
                  <X color="#FF7171" size={24} />;
                a[objIndex][
                  `metaId-${schedule.weekDay - 1}-${schedule.shiftTimeId}`
                ] = schedule.id;
              } else {
                a.push({
                  courses:
                    course[
                      String(i18n.language).toLowerCase() === "en" ||
                      String(i18n.language).toLowerCase() === "tr"
                        ? `title`
                        : `title${String(i18n.language).toUpperCase()}`
                    ],
                  courseId: course.id,
                  [`metaId-${schedule.weekDay - 1}-${schedule.shiftTimeId}`]:
                    schedule.id,
                  [`${schedule.weekDay - 1}-${schedule.shiftTimeId}`]: (
                    <X color="#FF7171" size={24} />
                  ),
                });
              }
            });
        } else {
          a.push({
            courses:
              course[
                String(i18n.language).toLowerCase() === "en" ||
                String(i18n.language).toLowerCase() === "tr"
                  ? `title`
                  : `title${String(i18n.language).toUpperCase()}`
              ],
            courseId: course.id,
          });
        }
      });
    return a;
  }, [schdedulesStore, coursesStore, shiftsStore, shiftTimesStore]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  return (
    <AutoScheduleLayout id={3}>
      <div className="school-admin__schedule-group school-presetting__body school-presetting__body-color">
        <div className="school-presetting__body-row">
          <div className="school-presetting__body-title">
            <h2>{t("schoolAdmin:lesson upper case")}</h2>
          </div>
          <div className="school-presetting__body-description">
            <p className="body-1">
              {t("schoolAdmin:autoshcedule step3 description")}
            </p>
          </div>
        </div>
        <div
          style={{ width: "100%", height: 600, overflow: "scroll" }}
          className="autoschedule-table"
        >
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup, idx1) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={idx1}>
                  {headerGroup.headers.map((column, idx2) => (
                    <th {...column.getHeaderProps()} key={idx2}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {rows.map((row, idx3) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} key={idx3}>
                    {row.cells.map((cell, idx4) => {
                      return (
                        <td
                          {...row.getRowProps({
                            style: {
                              width: `${cell.column.width}`,
                              cursor: "pointer",
                            },
                            onClick: () => {
                              if (cell.row.original?.[`${cell.column.id}`]) {
                                dispatch(
                                  deleteSchedulesCourseConstraint(
                                    cell.row.original?.[
                                      `metaId-${cell.column.parent.parent.metaId}-${cell.column.metaId}`
                                    ]
                                  )
                                );
                              } else {
                                console.log("cell", cell);
                                // console.log(
                                //   "weekDay",
                                //   cell.column.parent.parent.id
                                // );
                                // console.log(
                                //   "personId",
                                //   cell.row.original.instructorId
                                // );
                                // console.log("shiftTimeId", cell.column.metaId);
                                dispatch(
                                  createSchedulesCourseConstraint({
                                    weekDay: cell.column.parent.parent.id,
                                    courseId: cell.row.original.courseId,
                                    shiftTimeId: cell.column.metaId,
                                  })
                                );
                              }
                            },
                          })}
                          key={idx4}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="school-presetting__body-row school-presetting__body-buttons">
        <Link to="/school-admin/schedule/auto/2">
          <button className="secondary small">{t("common:back")}</button>
        </Link>
        <Link to="/school-admin/schedule/auto/4">
          <button onClick={onSubmit} className="primary large">
            {t("presetting:save and continue presetting school regulations")}
          </button>
        </Link>
      </div>
    </AutoScheduleLayout>
  );
}
