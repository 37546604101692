import "rc-time-picker/assets/index.css";
import React from "react";
import moment from "moment";
import TimePicker from "rc-time-picker";
import PT from "prop-types";

export default function TimePickerCalendar(props) {
  const { defaultValue, onSelect } = props;
  // console.log(defaultValue);
  // console.log(moment(defaultValue));
  // const onChange = (value, name) => {
  //   // setValue(value);
  //   const data = {
  //     target: {
  //       name: name,
  //       value: value ? value.format(getFormat(true)) : "",
  //     },
  //   };
  //   onSelect(data);
  // };
  return (
    <div className="time-picker">
      <TimePicker
        defaultValue={moment(defaultValue, "HH:mm")}
        showSecond={false}
        timeOnly="true"
        hourFormat={24}
        allowEmpty={true}
        onChange={(e) => onSelect(moment(e).format("HH:mm"))}
      />
    </div>
  );
}
TimePickerCalendar.propTypes = {
  defaultValue: PT.string,
  onSelect: PT.func,
};
