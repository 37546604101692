import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ChevronDown, ChevronUp } from "react-feather";
import SelectSearch, { fuzzySearch } from "react-select-search";
import { useTable } from "react-table";
import PT from "prop-types";
import Modal from "react-modal";

import { GoBack, InstractorLayout } from "../../components";
import "./Table.scss";

Table.propTypes = {
  columns: PT.array,
  data: PT.array,
  className: PT.string,
};

function Table({ columns, data, className }) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  return (
    <table className={className} {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup, idx) => (
          <tr key={idx} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, idx) => (
              <th key={idx} {...column.getHeaderProps()}>
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr key={i} {...row.getRowProps()}>
              {row.cells.map((cell, idx) => {
                return (
                  <td key={idx} {...cell.getCellProps()}>
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

function ClassRoomJournalReportComponent() {
  const { t } = useTranslation();
  const [modal, setModal] = useState({
    createGrade: false,
    editGrade: false,
  });
  const [classValue, setClassValue] = useState(
    `- ${t("presetting:classes presetting")} -`
  );
  const [filterByAbc1, setFilterByAbc1] = useState();
  const classes = [
    {
      name: `1`,
      value: 1,
      key: 1,
    },
    {
      name: `2`,
      value: 2,
      key: 2,
    },
    {
      name: `3`,
      value: 3,
      key: 3,
    },
  ];

  const columns = useMemo(
    () => [
      {
        Header: "№",
        accessor: "index",
      },
      {
        Header: (
          <span style={{ display: " flex", alignItems: "center" }}>
            {t("presetting:full name presetting")}
            <span style={{ display: "flex", alignItems: "center" }}>
              <div
                onClick={() => setFilterByAbc1(!true)}
                style={{ width: "12px" }}
              >
                <ChevronDown style={{ width: "20px" }} />
              </div>
              <ChevronUp
                onClick={() => setFilterByAbc1(!false)}
                style={{ width: "20px" }}
              />
            </span>
          </span>
        ),
        accessor: "full_name",
      },
      {
        Header: `${t("presetting:gender presetting")}`,
        accessor: "gender",
      },
      {
        Header: `${t("presetting:date of birth presetting")}`,
        accessor: "date_of_birth",
      },
      {
        Header: `${t("schoolAdmin:nationality")}`,
        accessor: "nationality",
      },
      {
        Header: `${t("schoolAdmin:children's organization")}`,
        accessor: "children_organization",
      },
      {
        Header: `${t("schoolAdmin:where did you drop out")}`,
        accessor: "when_where_dropped_out",
      },
    ],
    []
  );

  const mockData = [
    {
      index: 1,
      full_name: "Torie Rustman",
      gender: "M",
      date_of_birth: "05.01.2022",
      nationality: "Кыргыз",
      class: "1",
      children_organization: "Футболл",
      when_where_dropped_out: "1 -> 2",
    },
    {
      index: 2,
      full_name: "Kordula Gecks",
      gender: "Ж",
      class: "1",
      date_of_birth: "05.01.2022",
      nationality: "Кыргыз",
      children_organization: "Футболл",
      when_where_dropped_out: "1 -> 2",
    },
    {
      index: 3,
      full_name: "Vikki Simoens",
      gender: "Ж",
      class: "2",
      date_of_birth: "05.01.2022",
      nationality: "Кыргыз",
      children_organization: "Футболл",
      when_where_dropped_out: "1 -> 2",
    },
    {
      index: 4,
      full_name: "Burnaby Cowern",
      gender: "M",
      date_of_birth: "05.01.2022",
      class: "3",
      nationality: "Кыргыз",
      children_organization: "Футболл",
      when_where_dropped_out: "1 -> 2",
    },
    {
      index: 5,
      full_name: "Teddie Traice",
      gender: "М",
      date_of_birth: "05.01.2022",
      class: "3",
      nationality: "Кыргыз",
      children_organization: "Футболл",
      when_where_dropped_out: "1 -> 2",
    },
    {
      index: 6,
      full_name: "Sapphira Hutchinges",
      gender: "Ж",
      date_of_birth: "05.01.2022",
      nationality: "Кыргыз",
      class: "3",
      children_organization: "Футболл",
      when_where_dropped_out: "1 -> 2",
    },
  ];
  const simpleMockData = [...mockData];

  const filtertedMockData = mockData.sort(function (a, b) {
    return a.full_name.localeCompare(b.full_name);
  });

  function rendering() {
    if (filterByAbc1) {
      return filtertedMockData;
    } else {
      return simpleMockData;
    }
  }

  const data = useMemo(() => rendering(), [filterByAbc1]);
  let exportArray = [
    `${t("schoolAdmin:children's organization")}`,
    `${t("schoolAdmin:where did you arrive")}`,
    `${t("schoolAdmin:where did you drop out")}`,
    `${t("presetting:phone presetting")}`,
    `${t("schoolAdmin:mother's workplace")}`,
    `${t("schoolAdmin:mother's profession")}`,
    `${t("schoolAdmin:mother's phone")}`,
    `${t("schoolAdmin:father's full name")}`,
    `${t("schoolAdmin:father's workplace")}`,
    `${t("schoolAdmin:father's profession")}`,
    `${t("schoolAdmin:father's phone")}`,
    `${t("schoolAdmin:address")}`,
  ];
  return (
    <>
      <InstractorLayout className="class_room_journal_report">
        <GoBack title={t("common:back")} />
        <div className="main__container">
          <h2>{t("schoolAdmin:general information about students")}</h2>
          <p>{t("schoolAdmin:classroom journal report title")}</p>
          <div className="table_filter">
            <SelectSearch
              options={classes}
              search
              filterOptions={fuzzySearch}
              value={classValue}
              defaulValue={classValue}
              name="schoolType"
              onChange={(_, k) => {
                // console.log(k);
                setClassValue(k);
              }}
              required
              placeholder={`- ${t("presetting:classes presetting")} -`}
            />
            <button
              className="export_button"
              onClick={() => setModal({ ...modal, createGrade: true })}
            >
              {t("common:export")}
            </button>
          </div>
          <div className="table_component">
            <Table
              className="class_room_journal_table"
              columns={columns}
              data={data}
            />
          </div>
        </div>
      </InstractorLayout>
      <Modal
        isOpen={modal.createGrade}
        onRequestClose={() => setModal({ ...modal, createGrade: false })}
        overlayClassName="modal-overlay"
        onAfterOpen={async () => {}}
        style={{
          minWidth: 441,
        }}
        className="modal_clasroom_journal"
      >
        <h3>{t("common:export")}</h3>
        <p>{t("schoolAdmin:select the data to be displayed in the report")}</p>
        <ul>
          {exportArray.map((item, idx) => (
            <li key={idx}>
              <label>
                <input type="checkbox" />
                <div className="box">{item}</div>
              </label>
            </li>
          ))}
        </ul>
        <div className="modal_clasroom_journal_buttons">
          <button
            className="button_outlined_cancel"
            onClick={() => setModal({ ...modal, createGrade: false })}
          >
            {t("common:cancel")}
          </button>
          <button className="button_export">{t("common:export")}</button>
        </div>
      </Modal>
    </>
  );
}

export default ClassRoomJournalReportComponent;
