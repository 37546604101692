/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import { useTable } from "react-table";
import PT from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { X } from "react-feather";
import moment from "moment";
import { statuses, toastOption } from "../../config";
import { toast } from "react-toastify";

import { getClassBySchoolId } from "../../store/class/actions";
import { getShifts } from "../../store/shift/actions";
import { getShiftTimes } from "../../store/shiftTime/actions";
import { getSchoolById } from "../../store/school/actions";
import { getStudentsByCourseIdClassIdInstructorId } from "../../store/student/actions";
import {
  getGrades,
  addGradeMassive,
  editGradeMassive,
} from "../../store/grade/actions";
import { getSchedulesByClassIdCourseIdInstructorId } from "../../store/schedule/actions";
import { getCourses } from "../../store/course/actions";
import { getQuarters } from "../../store/quarter/actions";

ClassJournalTable.propTypes = {
  classId: PT.number.required,
  courseId: PT.number.required,
  quarterId: PT.number,
  date: PT.string.required,
  topicId: PT.number,
};

export default function ClassJournalTable(props) {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  const { classId, courseId, quarterId, date, topicId } = props;
  const dispatch = useDispatch();
  const shiftsStore = useSelector((store) => store.shifts.shifts);
  const shiftTimesStore = useSelector((store) =>
    store.shiftTimes.shiftTimes.sort((a, b) =>
      a.startTime < b.startTime ? -1 : a.startTime > b.startTime ? 1 : 0
    )
  );
  const schedules2Store = useSelector((store) => store.schedules.schedules2);
  const quartersStore = useSelector((store) => store.quarters.quarters);
  const gradesStore = useSelector((store) => store.grades.grades);
  const gradesStatus = useSelector((store) => store.grades.status);
  const studentsStore = useSelector((store) => store.students.students);
  const [modal, setModal] = useState({
    createGrade: false,
    editGrade: false,
  });
  const [count, setCount] = useState(0);
  const [currentCell, setCurrentCell] = useState({});

  useEffect(async () => {
    await dispatch(getQuarters());
    await dispatch(getCourses());
    await dispatch(
      getSchedulesByClassIdCourseIdInstructorId({
        classId: classId,
        courseId: courseId,
        quarterId: quarterId,
        startDate: quartersStore.find((item) => item.id == quarterId)
          ?.startDate,
        endDate: quartersStore.find((item) => item.id == quarterId)?.endDate,
      })
    );
    await dispatch(
      getStudentsByCourseIdClassIdInstructorId({
        classId: classId,
        courseId: courseId,
      })
    );
    await dispatch(getClassBySchoolId());
    await dispatch(getShifts());
    await dispatch(getShiftTimes());
    await dispatch(
      getSchoolById(JSON.parse(localStorage.getItem("school"))?.id)
    );
    console.log("aa", {
      classId: classId,
      courseId: courseId,
      quarterId: quarterId,
      startDate: quartersStore.find((item) => item.id == quarterId)?.startDate,
      endDate: quartersStore.find((item) => item.id == quarterId)?.endDate,
    });
  }, [classId, courseId, quarterId]);

  useEffect(async () => {
    await dispatch(
      getGrades({
        classId: classId,
        courseId: courseId,
        startDate: quartersStore.find((item) => item.id == quarterId)
          ?.startDate,
        endDate: quartersStore.find((item) => item.id == quarterId)?.endDate,
      })
    );
  }, [count]);

  const sendMark = async (values) => {
    let gradeExist = await gradesStore
      .filter((item) => item.gradeDate === date)
      .filter((item) => item.shiftTimeId == values.shiftTimeId)
      .filter((item) => item.studentCourseId == values.studentCourseId)
      .filter((item) => item.gradeMarkType == values.gradeMarkType);
    if (gradeExist.length > 0) {
      await dispatch(
        editGradeMassive([
          {
            gradeId: gradeExist?.[0]?.gradeId,
            gradeMarkType: values.gradeMarkType,
            mark: values.mark,
            studentCourseId: values.studentCourseId,
          },
        ])
      );
    } else {
      await dispatch(
        addGradeMassive({
          gradeDate: date,
          shiftTimeId: values.shiftTimeId,
          topicId: topicId ? topicId : null,
          personId: JSON.parse(localStorage.getItem("person"))?.id,
          studentList: [
            {
              gradeMarkType: values.gradeMarkType,
              mark: `${values.mark}`,
              studentCourseId: values.studentCourseId,
            },
          ],
        })
      );
    }
    await setCount(count + 1);
  };

  const deleteMark = async (values) => {
    let gradeExist = gradesStore
      .filter((item) => item.gradeDate === date)
      .filter((item) => item.shiftTimeId == values.shiftTimeId)
      .filter((item) => item.studentCourseId == values.studentCourseId)
      .filter((item) => item.gradeMarkType == values.gradeMarkType);

    if (gradeExist.length > 0) {
      await dispatch(
        editGradeMassive([
          {
            gradeId: gradeExist?.[0]?.gradeId,
            gradeMarkType: "DELETE",
            mark: values.mark,
            studentCourseId: values.studentCourseId,
          },
        ])
      );
    }
    await setCount(count + 1);
  };

  function getAvg(data) {
    const total = data.reduce((acc, c) => acc + c, 0);
    return total / data.length;
  }

  let a =
    schedules2Store.length > 0
      ? schedules2Store?.map((schedule) => {
          return {
            Header: `${moment()
              .month(schedule.day?.split("-")[1] - 1)
              .format("MMMM")}`,
            // metaId: `${day.id}`,
            accessor: `${schedule.day?.split("-")[1]}`,
            columns: [
              {
                Header: `${schedule.day?.split("-")[2]} (${
                  schedule.shiftTimeTitle
                })`,
                shiftTimeId: `${schedule.shiftTimeId}`,
                date: `${schedule.day}`,
                accessor: `${schedule.day?.split("-")[1]}-${
                  schedule.day?.split("-")[2]
                }-${schedule.shiftTimeId}`,
              },
            ],
          };
        })
      : [];
  const columns = React.useMemo(
    () => [
      {
        Header: "№",
        accessor: "number",
        // width: 150,
      },
      {
        Header: t("validation:full name"),
        accessor: "students",
        width: 150,
      },
      ...a,
      {
        Header: t("instructor:average"),
        accessor: "average",
        // width: 150,
      },
    ],
    [schedules2Store, studentsStore, shiftsStore, shiftTimesStore]
  );
  const data = React.useMemo(() => {
    let a = [];
    studentsStore
      .sort((a, b) => a.studentTitle - b.studentTitle)
      .map((student, idx) => {
        if (
          gradesStore.filter(
            (grade) => grade.studentCourseId === student.studentCourseId
          ).length > 0
        ) {
          gradesStore
            .filter(
              (grade) => grade.studentCourseId === student.studentCourseId
            )
            .map((grade) => {
              if (
                a.find(
                  (item) => item.studentCourseId === student.studentCourseId
                )
              ) {
                const objIndex = a.findIndex(
                  (obj) => obj.studentCourseId === student.studentCourseId
                );
                a[objIndex][
                  `${grade.gradeDate?.split("-")[1]}-${
                    grade.gradeDate?.split("-")[2]
                  }-${grade.shiftTimeId}`
                ] =
                  grade.mark == "92"
                    ? `${t("instructor:not attendent")}`
                    : grade.mark == "95"
                    ? `${t("instructor:not attendent")}`
                    : grade.mark == "97"
                    ? gradesStore
                        .filter(
                          (item) =>
                            item.studentCourseId === student.studentCourseId
                        )
                        .filter((item) => item.gradeMarkType === "GRADE")
                        .filter((item) => item.date == date)
                        .filter(
                          (item) => item.shiftTimeId === grade.shiftTimeId
                        )?.[0]?.mark
                    : grade.mark;
                a[objIndex][
                  `${grade.gradeDate?.split("-")[1]}-${
                    grade.gradeDate?.split("-")[2]
                  }-${grade.shiftTimeId}-grade`
                ] = grade.mark;
              } else {
                a.push({
                  number: idx + 1,
                  students: student.studentTitle,
                  studentCourseId: student.studentCourseId,
                  [`${grade.gradeDate?.split("-")[1]}-${
                    grade.gradeDate?.split("-")[2]
                  }-${grade.shiftTimeId}`]:
                    grade.mark == "92"
                      ? `${t("instructor:not attendent")}`
                      : grade.mark == "95"
                      ? `${t("instructor:not attendent")}`
                      : grade.mark == "97"
                      ? gradesStore
                          .filter(
                            (item) =>
                              item.studentCourseId === student.studentCourseId
                          )
                          .filter((item) => item.gradeMarkType === "GRADE")
                          .filter((item) => item.date == date)
                          .filter(
                            (item) => item.shiftTimeId === grade.shiftTimeId
                          )?.[0]?.mark
                      : grade.mark,
                  [`${grade.gradeDate?.split("-")[1]}-${
                    grade.gradeDate?.split("-")[2]
                  }-${grade.shiftTimeId}-grade`]: grade.mark,
                  average:
                    Math.round(
                      getAvg(
                        gradesStore
                          .filter(
                            (grade) =>
                              grade.studentCourseId === student.studentCourseId
                          )
                          .filter((grade) => grade.gradeMarkType === "GRADE")
                          .map((grade) => Number(grade.mark))
                          .filter((grade) => typeof grade === "number")
                      ) * 100
                    ) / 100
                      ? (
                          (getAvg(
                            gradesStore
                              .filter(
                                (grade) =>
                                  grade.studentCourseId ===
                                  student.studentCourseId
                              )
                              .filter(
                                (grade) => grade.gradeMarkType === "GRADE"
                              )
                              .map((grade) => Number(grade.mark))
                              .filter((grade) => typeof grade === "number")
                          ) *
                            100) /
                          100
                        ).toFixed(1)
                      : " ",
                });
              }
            });
        } else {
          a.push({
            number: idx + 1,
            students: student.studentTitle,
            studentCourseId: student.studentCourseId,
          });
        }
      });
    return a;
  }, [gradesStore, studentsStore, classId, courseId, quarterId, date, topicId]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  return (
    <>
      {gradesStatus === statuses.SUCCESS && (
        <>
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup, idx1) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={idx1}>
                  {headerGroup.headers.map((column, idx2) => (
                    <th {...column.getHeaderProps()} key={idx2}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {rows.map((row, idx3) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} key={idx3}>
                    {row.cells.map((cell, idx4) => {
                      if (
                        `${
                          cell.column?.parent?.columns?.[0]?.id?.split("-")[0]
                        }-${
                          cell.column?.parent?.columns?.[0]?.id?.split("-")[1]
                        }` === `${date?.split("-")[1]}-${date?.split("-")[2]}`
                      ) {
                        return (
                          <td
                            {...row.getRowProps({
                              style: {
                                width: `${cell.column.width}`,
                                cursor: "pointer",
                              },
                              onClick: () => {
                                console.log("cell", cell);
                                setCurrentCell({
                                  studentCourseId:
                                    cell.row.original.studentCourseId,
                                  shiftTimeId:
                                    cell.column.parent?.columns?.[0]?.id?.split(
                                      "-"
                                    )[2],
                                });
                              },
                              onKeyDown: async (e) => {
                                if (
                                  (e.which >= 50 && e.which <= 53) ||
                                  (e.which >= 98 && e.which <= 101)
                                ) {
                                  if (!topicId) {
                                    toast.error(
                                      t("instructor:choose a topic"),
                                      toastOption
                                    );
                                  }
                                  if (
                                    e.target.textContent ==
                                      `${t("instructor:not attendent")}` ||
                                    cell.row.original?.[
                                      `${cell.column.parent?.columns?.[0]?.id}`
                                    ] == `${t("instructor:not attendent")}`
                                  ) {
                                    e.target.textContent = `${t(
                                      "instructor:not attendent"
                                    )}`;
                                    await sendMark({
                                      studentCourseId:
                                        cell.row.original.studentCourseId,
                                      shiftTimeId:
                                        cell.column.parent?.columns?.[0]?.id?.split(
                                          "-"
                                        )[2],
                                      gradeMarkType: "ATTENDANCE",
                                      mark: e.key,
                                    });
                                  } else {
                                    e.target.textContent = "";
                                    await sendMark({
                                      studentCourseId:
                                        cell.row.original.studentCourseId,
                                      shiftTimeId:
                                        cell.column.parent?.columns?.[0]?.id?.split(
                                          "-"
                                        )[2],
                                      gradeMarkType: "GRADE",
                                      mark: e.key,
                                    });
                                  }
                                } else if (
                                  (e.which >= 37 && e.which <= 40) ||
                                  e.which == 27
                                ) {
                                  console.log("ss");
                                }
                              },
                              onKeyUp: async (e) => {
                                if (
                                  (e.which >= 50 && e.which <= 53) ||
                                  (e.which >= 98 && e.which <= 101)
                                ) {
                                  console.log("aa");
                                  if (
                                    e.target.textContent ==
                                      `${t("instructor:not attendent")}` ||
                                    cell.row.original?.[
                                      `${cell.column.parent?.columns?.[0]?.id}`
                                    ] == `${t("instructor:not attendent")}`
                                  ) {
                                    e.target.textContent ==
                                      `${t("instructor:not attendent")}`;
                                  }
                                } else if (e.keyCode == 8) {
                                  console.log("8");
                                  if (
                                    e.target.textContent ==
                                      `${t("instructor:not attendent")}` ||
                                    cell.row.original?.[
                                      `${cell.column.parent?.columns?.[0]?.id}`
                                    ] == `${t("instructor:not attendent")}`
                                  ) {
                                    e.target.textContent ==
                                      `${t("instructor:not attendent")}`;
                                    await deleteMark({
                                      studentCourseId:
                                        cell.row.original.studentCourseId,
                                      shiftTimeId:
                                        cell.column.parent?.columns?.[0]?.id?.split(
                                          "-"
                                        )[2],
                                      gradeMarkType: "ATTENDANCE",
                                      mark: e.key,
                                    });
                                  } else if (
                                    cell.value == "2" ||
                                    cell.value == "3" ||
                                    cell.value == "4" ||
                                    cell.value == "5"
                                  ) {
                                    await deleteMark({
                                      studentCourseId:
                                        cell.row.original.studentCourseId,
                                      shiftTimeId:
                                        cell.column.parent?.columns?.[0]?.id?.split(
                                          "-"
                                        )[2],
                                      gradeMarkType: "GRADE",
                                      mark: e.key,
                                    });
                                  }
                                } else if (
                                  (e.which >= 37 && e.which <= 40) ||
                                  e.which == 27
                                ) {
                                  console.log("ss");
                                } else {
                                  if (
                                    e.target.textContent ==
                                      `${t("instructor:not attendent")}` ||
                                    cell.row.original?.[
                                      `${cell.column.parent?.columns?.[0]?.id}`
                                    ] == `${t("instructor:not attendent")}`
                                  ) {
                                    e.target.textContent ==
                                      `${t("instructor:not attendent")}`;
                                  } else {
                                    e.target.textContent = "";
                                  }
                                  setModal({ ...modal, createGrade: true });
                                }
                              },
                            })}
                            contentEditable={true}
                            key={idx4}
                            meta-type={
                              cell.column.id === "students"
                                ? "students"
                                : "grade"
                            }
                            id={`${cell.column.parent?.columns?.[0]?.id}-${cell.row.original.studentCourseId}`}
                            className={
                              gradesStore
                                .filter(
                                  (item) => item.gradeMarkType == "ATTENDANCE"
                                )
                                ?.filter(
                                  (item) =>
                                    item.studentCourseId ==
                                    cell.row.original.studentCourseId
                                )
                                ?.filter((item) => item.gradeDate == date)
                                ?.filter(
                                  (item) =>
                                    item.shiftTimeId == cell.column?.shiftTimeId
                                )?.length > 0
                                ? gradesStore
                                    .filter(
                                      (item) =>
                                        item.gradeMarkType == "ATTENDANCE"
                                    )
                                    ?.filter(
                                      (item) =>
                                        item.studentCourseId ==
                                        cell.row.original.studentCourseId
                                    )
                                    ?.filter((item) => item.gradeDate == date)
                                    .filter(
                                      (item) =>
                                        item.shiftTimeId ==
                                        cell.column?.shiftTimeId
                                    )?.[0]?.mark == "97"
                                  ? "grade-orange"
                                  : gradesStore
                                      .filter(
                                        (item) =>
                                          item.gradeMarkType == "ATTENDANCE"
                                      )
                                      ?.filter(
                                        (item) =>
                                          item.studentCourseId ==
                                          cell.row.original.studentCourseId
                                      )
                                      ?.filter((item) => item.gradeDate == date)
                                      ?.filter(
                                        (item) =>
                                          item.shiftTimeId ==
                                          cell.column?.shiftTimeId
                                      )?.[0]?.mark == "95"
                                  ? "grade-red"
                                  : gradesStore
                                      .filter(
                                        (item) =>
                                          item.gradeMarkType == "ATTENDANCE"
                                      )
                                      ?.filter(
                                        (item) =>
                                          item.studentCourseId ==
                                          cell.row.original.studentCourseId
                                      )
                                      ?.filter((item) => item.gradeDate == date)
                                      ?.filter(
                                        (item) =>
                                          item.shiftTimeId ==
                                          cell.column?.shiftTimeId
                                      )?.[0]?.mark == "92"
                                  ? "grade-blue"
                                  : ""
                                : "dd"
                            }
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      } else {
                        return (
                          <td
                            {...row.getRowProps({
                              style: {
                                width: `${cell.column.width}`,
                                background: "rgba(255, 0, 0, 0.03)",
                              },
                              onClick: () => {
                                toast.error(
                                  t("instructor:choose a date"),
                                  toastOption
                                );
                                console.log("cell", cell);
                              },
                            })}
                            key={idx4}
                            meta-type={
                              cell.column.id === "students"
                                ? "students"
                                : "grade"
                            }
                            className={
                              gradesStore
                                ?.filter(
                                  (item) => item.gradeMarkType == "ATTENDANCE"
                                )
                                ?.filter(
                                  (item) =>
                                    item.studentCourseId ==
                                    cell.row.original.studentCourseId
                                )
                                ?.filter(
                                  (item) => item.gradeDate == cell.column.date
                                )
                                .filter(
                                  (item) =>
                                    item.shiftTimeId == cell.column?.shiftTimeId
                                )?.[0]?.mark == 97
                                ? "grade-orange"
                                : gradesStore
                                    .filter(
                                      (item) =>
                                        item.gradeMarkType == "ATTENDANCE"
                                    )
                                    .filter(
                                      (item) =>
                                        item.studentCourseId ==
                                        cell.row.original.studentCourseId
                                    )
                                    ?.filter(
                                      (item) =>
                                        item.gradeDate == cell.column.date
                                    )
                                    .filter(
                                      (item) =>
                                        item.shiftTimeId ==
                                        cell.column?.shiftTimeId
                                    )[0]?.mark == 95
                                ? "grade-red"
                                : gradesStore
                                    ?.filter(
                                      (item) =>
                                        item.gradeMarkType == "ATTENDANCE"
                                    )
                                    ?.filter(
                                      (item) =>
                                        item.studentCourseId ==
                                        cell.row.original.studentCourseId
                                    )
                                    ?.filter(
                                      (item) =>
                                        item.gradeDate == cell.column.date
                                    )
                                    .filter(
                                      (item) =>
                                        item.shiftTimeId ==
                                        cell.column?.shiftTimeId
                                    )?.[0]?.mark == 92 && "grade-blue"
                            }
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      }
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      )}

      <Modal
        isOpen={modal.createGrade}
        onRequestClose={() => setModal({ ...modal, createGrade: false })}
        overlayClassName="modal-overlay"
        onAfterOpen={async () => {}}
      >
        <div className="modal">
          <div className="modal-close">
            <button onClick={() => setModal({ ...modal, createGrade: false })}>
              <X size={15} color="#9A9A9A" />
            </button>
          </div>
          <div className="modal-header">
            <div className="modal-header-title">
              <h2 className="roboto"></h2>
            </div>
            <div className="modal-header-description"></div>
          </div>
          <div className="modal-body">
            <div className="modal-inputs-list grades">
              <div className="modal-input">
                <h5>{t("instructor:late")}</h5>
                <button
                  onClick={() => {
                    sendMark({
                      ...currentCell,
                      gradeMarkType: "ATTENDANCE",
                      mark: "97",
                    });
                    setModal({ ...modal, createGrade: false });
                  }}
                >
                  &nbsp;
                </button>
              </div>
              <div className="modal-input">
                <h5>{t("instructor:absent for no valid reason")}</h5>
                <button
                  onClick={() => {
                    sendMark({
                      ...currentCell,
                      gradeMarkType: "ATTENDANCE",
                      mark: "95",
                    });
                    setModal({ ...modal, createGrade: false });
                  }}
                >
                  {t("instructor:not attendent")}
                </button>
              </div>
              <div className="modal-input">
                <h5>{t("instructor:missing for a good reason")}</h5>
                <button
                  onClick={() => {
                    sendMark({
                      ...currentCell,
                      gradeMarkType: "ATTENDANCE",
                      mark: "92",
                    });
                    setModal({ ...modal, createGrade: false });
                  }}
                >
                  {t("instructor:not attendent")}
                </button>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            {gradesStore
              .filter(
                (item) => item.studentCourseId == currentCell.studentCourseId
              )
              .filter((item) => item.gradeMarkType == "ATTENDANCE").length >
              0 && (
              <button
                className="large primary"
                onClick={async () => {
                  sendMark({
                    studentCourseId: currentCell.studentCourseId,
                    shiftTimeId: currentCell.shiftTimeId,
                    gradeMarkType: "ATTENDANCE",
                    mark: "",
                  });
                  setModal({ ...modal, createGrade: false });
                }}
              >
                {t("common:delete")}
              </button>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
}
