import {
  GET_SECTIONS_REQUEST,
  GET_SECTIONS_SUCCESS,
  GET_SECTIONS_ERROR,
  GET_SECTION_REQUEST,
  GET_SECTION_SUCCESS,
  GET_SECTION_ERROR,
  GET_SECTION_INSTRUCTOR_REQUEST,
  GET_SECTION_INSTRUCTOR_SUCCESS,
  GET_SECTION_INSTRUCTOR_ERROR,
  GET_SECTION_SCHEDULE_REQUEST,
  GET_SECTION_SCHEDULE_SUCCESS,
  GET_SECTION_SCHEDULE_ERROR,
  GET_SECTION_STUDENT_REQUEST,
  GET_SECTION_STUDENT_SUCCESS,
  GET_SECTION_STUDENT_ERROR,
} from "../actionTypes";
import { statuses } from "../../config";

const initialState = {
  sections: [],
  section: {},
  sectionInstructor: {},
  sectionSchedule: [],
  sectionStudent: [],
  error: "",
  status: statuses.INITIAL,
};

export const sectionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SECTIONS_REQUEST:
      return {
        ...state,
        sections: [],
        error: "",
        status: statuses.LOADING,
      };
    case GET_SECTIONS_SUCCESS:
      return {
        ...state,
        sections: action.payload,
        error: "",
        status: statuses.SUCCESS,
      };
    case GET_SECTIONS_ERROR:
      return {
        ...state,
        error: action.payload,
        status: statuses.ERROR,
        sections: [],
      };
    case GET_SECTION_REQUEST:
      return {
        ...state,
        section: {},
        error: "",
        status: statuses.LOADING,
      };
    case GET_SECTION_SUCCESS:
      return {
        ...state,
        section: action.payload,
        error: "",
        status: statuses.SUCCESS,
      };
    case GET_SECTION_ERROR:
      return {
        ...state,
        error: action.payload,
        status: statuses.ERROR,
        section: {},
      };
    case GET_SECTION_INSTRUCTOR_REQUEST:
      return {
        ...state,
        sectionInstructor: {},
        error: "",
        status: statuses.LOADING,
      };
    case GET_SECTION_INSTRUCTOR_SUCCESS:
      return {
        ...state,
        sectionInstructor: action.payload,
        error: "",
        status: statuses.SUCCESS,
      };
    case GET_SECTION_INSTRUCTOR_ERROR:
      return {
        ...state,
        error: action.payload,
        status: statuses.ERROR,
        sectionInstructor: {},
      };
    case GET_SECTION_SCHEDULE_REQUEST:
      return {
        ...state,
        sectionSchedule: [],
        error: "",
        status: statuses.LOADING,
      };
    case GET_SECTION_SCHEDULE_SUCCESS:
      return {
        ...state,
        sectionSchedule: action.payload,
        error: "",
        status: statuses.SUCCESS,
      };
    case GET_SECTION_SCHEDULE_ERROR:
      return {
        ...state,
        error: action.payload,
        status: statuses.ERROR,
        sectionSchedule: [],
      };
    case GET_SECTION_STUDENT_REQUEST:
      return {
        ...state,
        sectionStudent: [],
        error: "",
        status: statuses.LOADING,
      };
    case GET_SECTION_STUDENT_SUCCESS:
      return {
        ...state,
        sectionStudent: action.payload,
        error: "",
        status: statuses.SUCCESS,
      };
    case GET_SECTION_STUDENT_ERROR:
      return {
        ...state,
        error: action.payload,
        status: statuses.ERROR,
        sectionStudent: [],
      };

    default:
      return state;
  }
};
