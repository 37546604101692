/* eslint-disable indent */
import React, { useEffect, useState, useCallback } from "react";
import { debounce } from "lodash";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Edit2, Trash, X, Users, Download } from "react-feather";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import ReactExport from "react-export-excel";
import SelectSearch, { fuzzySearch } from "react-select-search";
import Tooltip from "react-simple-tooltip";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

import {
  SchoolAdminLayout,
  PaginationBlock,
  SearchField,
  NoData,
} from "../../components";
import { getShifts } from "../../store/shift/actions";
import { getLanguages } from "../../store/language/actions";
import { getInstructors } from "../../store/instructor/actions";
import { getAllStudentsByClass } from "../../store/student/actions";
import {
  getClassBySchoolId,
  deleteClass,
  getClassById,
  addClass,
  editClassOne,
} from "../../store/class/actions";
import { getParentsByClassId } from "../../store/parent/actions";

export default function Class() {
  const dispatch = useDispatch();
  const { t } = useTranslation(["presetting", "schoolAdmin"]);
  const classStore = useSelector((store) =>
    store.class.class
      .sort((a, b) => a.classLabel.localeCompare(b.classLabel))
      .sort((a, b) => a.classLevel - b.classLevel)
  );
  const parentsStore = useSelector((store) => store.parents.parents);
  const studentsStore = useSelector((store) => store.students.students);
  const languagesStore = useSelector((store) => store.languages.languages);
  const shiftsStore = useSelector((store) => store.shifts.shifts);
  const instructorsStore = useSelector(
    (store) => store.instructors.instructors
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [filterTypeNumber, setFilterTypeNumber] = useState(1);
  const [currentData, setCurrentData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [selectedData, setSelectedData] = useState([]);
  const [searchedCurrentData, setSearchedCurrentData] = useState([]);
  const [filteredAllData, setFilteredAllData] = useState([]);
  const [currentClassId, setCurrentClassId] = useState();
  const [editClass, setEditClass] = useState({
    classType: "PRIMARY",
    classLabel: "",
    classLevel: "",
    languageId: "",
    shiftId: "",
    personId: "",
  });

  const [modal, setModal] = useState({
    createClass: false,
    editClass: false,
    export: false,
  });
  const [newClass, setNewClass] = useState({
    classType: "PRIMARY",
    label: "",
    level: "",
    languageId: "",
    shiftId: "",
    personId: "",
  });

  const filterTypes = {
    type1: { min: 0, max: 12 },
    type2: { min: 1, max: 4 },
    type3: { min: 5, max: 9 },
    type4: { min: 10, max: 12 },
  };

  useEffect(async () => {
    await dispatch(getClassBySchoolId());
    await dispatch(getLanguages());
    await dispatch(getInstructors());
    await dispatch(getShifts());
  }, [modal]);
  const onPageChanged = (
    pageNumber,
    perPageNumber,
    data = classStore,
    typeNumber = 1
  ) => {
    const offset = (pageNumber - 1) * perPageNumber;
    let a = data.filter(
      (item) =>
        item.classLevel >= filterTypes[`type${typeNumber}`].min &&
        item.classLevel <= filterTypes[`type${typeNumber}`].max
    );
    const currentDataArr = a.slice(offset, offset + perPageNumber);
    setFilteredAllData(a);
    return setCurrentData(currentDataArr);
  };
  const createClass = () => {
    dispatch(addClass(newClass));
    setModal({ ...modal, createClass: false });
    setNewClass({
      classType: "PRIMARY",
      label: "",
      level: "",
      languageId: "",
      shiftId: "",
      personId: "",
    });
  };
  const updateClass = async () => {
    await dispatch(editClassOne(editClass));
    // await dispatch(getClassBySchoolId());

    await setModal({ ...modal, editClass: false });
    await setEditClass({
      classType: "PRIMARY",
      classLabel: "",
      classLevel: "",
      languageId: "",
      shiftId: "",
      personId: "",
    });

    await setFilterTypeNumber(1);
    await setFilteredAllData([]);
    await setSearchedCurrentData([]);
    await setCurrentData([]);
    await setSelectedData([]);
    // await onPageChanged(1, perPage, classStore, filterTypeNumber);
  };
  const languagesOptions = languagesStore.map((item) => ({
    name: `${item.name}`,
    value: `${item.id}`,
    key: "languageId",
  }));

  const shiftsOptions = shiftsStore.map((item) => ({
    name: `${item.title} ${t("presetting:shift presetting")}`,
    value: item.id,
    key: "shiftId",
  }));
  const instructorsOptions = instructorsStore.map((item) => ({
    name: item.instructorTitle,
    value: item.instructorId,
    key: "personId",
  }));

  const onInputChange = (e) => {
    if ((e.key || e.target.name) === "label") {
      setNewClass({
        ...newClass,
        [e.key || e.target.name]: String(
          e.value || e.target.value
        ).toUpperCase(),
      });
    } else {
      setNewClass({
        ...newClass,
        [e.key || e.target.name]: e.value || e.target.value,
      });
    }
  };
  const onEditInputChange = (e) => {
    if ((e.key || e.target.name) === "label") {
      setEditClass({
        ...editClass,
        [e.key || e.target.name]: String(
          e.value || e.target.value
        ).toUpperCase(),
      });
    } else {
      setEditClass({
        ...editClass,
        [e.key || e.target.name]: e.value || e.target.value,
      });
    }
  };

  const onFilterHandler = (typeNumber) => {
    setCurrentPage(1);
    setFilterTypeNumber(typeNumber);
    onPageChanged(1, perPage, classStore, typeNumber);
  };

  const debouncedPerPage = useCallback(
    debounce((text, classStore) => {
      if (filteredAllData.length > 0) {
        onPageChanged(1, Number(text), filteredAllData, filterTypeNumber);
      } else {
        onPageChanged(1, Number(text), classStore);
      }
      setPerPage(Number(text));
      setCurrentPage(1);
    }, 1000),
    [classStore, filteredAllData]
  );

  const debouncedSearch = useCallback(
    debounce((text) => {
      setSearchText(text.toLowerCase());
      setCurrentPage(1);
      setFilterTypeNumber(1);

      const a = classStore.filter((item) => {
        const a = String(`${item.classLevel}${item.classLabel}`).toLowerCase();
        const c = a.includes(text.toLowerCase());

        const b = String(`${item.personTitle}`).toLowerCase();
        const d = b.includes(text.toLowerCase());
        if (c) {
          return c;
        } else if (d) {
          return d;
        }
      });
      setSearchedCurrentData([...a]);
      onPageChanged(1, perPage, [...a]);
    }, 1000),
    [classStore, perPage]
  );
  const onChange = async (e) => {
    console.log(e);
    setCurrentPage(e);
    if (searchedCurrentData.length > 0) {
      onPageChanged(e, perPage, searchedCurrentData);
    } else if (filteredAllData.length > 0) {
      onPageChanged(e, perPage, filteredAllData, filterTypeNumber);
    } else {
      onPageChanged(e, perPage, classStore);
    }
  };
  const getPerPage = (e) => {
    debouncedPerPage(e.target.value);
  };
  const searchInputHandler = (e) => {
    debouncedSearch(e.target.value);
  };

  const removeItem = async (value) => {
    await dispatch(deleteClass(value.id));
    await dispatch(getClassBySchoolId());
  };

  const onSelect = (value) => {
    if (selectedData.find((item) => item.id === value.id)) {
      let a = selectedData.filter((item) => item.id !== value.id);
      setSelectedData([...a]);
    } else {
      setSelectedData([...selectedData, value]);
    }
  };
  const clearSelected = () => {
    setSelectedData([]);
  };
  const removeSelected = async (data) => {
    // let a = classStore;
    await data.map((item) => {
      dispatch(deleteClass(item.id));
    });
    await dispatch(getClassBySchoolId());
    setSelectedData([]);
    // setAllData(a);
    // onPageChanged(currentPage, perPage);
  };
  const classLevels = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  const classLevelRender = classLevels.map((item) => ({
    name: `${item}`,
    value: `${item}`,
    key: "level",
  }));
  const classLevelEditRender = classLevels.map((item) => ({
    name: `${item}`,
    value: item,
    key: "classLevel",
  }));
  const tableDataRender =
    searchedCurrentData.length > 0
      ? searchedCurrentData.map((item, idx) => (
          <tr
            className={
              selectedData.find((i) => i.id === item.id) ? "active" : ""
            }
            key={idx}
          >
            <td className="table-select">
              <label className="check">
                <input
                  type="checkbox"
                  onChange={() => onSelect(item)}
                  checked={selectedData.find((i) => i.id === item.id)}
                />
                <div className="box"></div>
              </label>
              <span>{idx + 1}</span>
            </td>
            <td>
              {item.classLevel}
              {item.classLabel}
            </td>
            <td>{item.languageTitle}</td>
            <td>{item.shift}</td>
            <td>{item.personTitle}</td>
            <td>
              <div>
                <Tooltip className="tooltip" content={t("common:students")}>
                  <Link to={`/school-admin/students/${item.id}`}>
                    <button>
                      <Users color="#F0783A" />
                    </button>
                  </Link>
                </Tooltip>
                <Tooltip className="tooltip" content={t("common:export")}>
                  <button
                    onClick={() => {
                      setCurrentClassId(item.id);
                      setModal({ ...modal, export: true });
                    }}
                  >
                    <Download color="#0066ff" />
                  </button>
                </Tooltip>
                <Tooltip className="tooltip" content={t("common:edit")}>
                  <button
                    onClick={() => {
                      setCurrentClassId(item.id);
                      setModal({ ...modal, editClass: true });
                    }}
                  >
                    <Edit2 color="#14A38B" />
                  </button>
                </Tooltip>
                <Tooltip className="tooltip" content={t("common:delete")}>
                  <button onClick={() => removeItem(item)}>
                    <Trash color="#FF7171" />
                  </button>
                </Tooltip>
              </div>
            </td>
          </tr>
        ))
      : currentData.length > 0
      ? currentData.map((item, idx) => (
          <tr
            className={
              selectedData.find((i) => i.id === item.id) ? "active" : ""
            }
            key={idx}
          >
            <td className="table-select">
              <label className="check">
                <input
                  type="checkbox"
                  onChange={() => onSelect(item)}
                  checked={selectedData.find((i) => i.id === item.id)}
                />
                <div className="box"></div>
              </label>
              <span>{idx + 1}</span>
            </td>
            <td>
              {item.classLevel}
              {item.classLabel}
            </td>
            <td>{item.languageTitle}</td>
            <td>{item.shift}</td>
            <td>{item.personTitle}</td>
            <td>
              <div>
                <Tooltip className="tooltip" content={t("common:students")}>
                  <Link to={`/school-admin/students/${item.id}`}>
                    <button>
                      <Users color="#F0783A" />
                    </button>
                  </Link>
                </Tooltip>
                <Tooltip className="tooltip" content={t("common:export")}>
                  <button
                    onClick={() => {
                      setCurrentClassId(item.id);
                      setModal({ ...modal, export: true });
                    }}
                  >
                    <Download color="#0066ff" />
                  </button>
                </Tooltip>
                <Tooltip className="tooltip" content={t("common:edit")}>
                  <button
                    onClick={() => {
                      setCurrentClassId(item.id);
                      setModal({ ...modal, editClass: true });
                    }}
                  >
                    <Edit2 color="#14A38B" />
                  </button>
                </Tooltip>
                <Tooltip className="tooltip" content={t("common:delete")}>
                  <button onClick={() => removeItem(item)}>
                    <Trash color="#FF7171" />
                  </button>
                </Tooltip>
              </div>
            </td>
          </tr>
        ))
      : filteredAllData.length > 0
      ? filteredAllData.map((item, idx) => (
          <tr
            className={
              selectedData.find((i) => i.id === item.id) ? "active" : ""
            }
            key={idx}
          >
            <td className="table-select">
              <label className="check">
                <input
                  type="checkbox"
                  onChange={() => onSelect(item)}
                  checked={selectedData.find((i) => i.id === item.id)}
                />
                <div className="box"></div>
              </label>
              <span>{idx + 1}</span>
            </td>
            <td>
              {item.classLevel}
              {item.classLabel}
            </td>
            <td>{item.languageTitle}</td>
            <td>{item.shift}</td>
            <td>{item.personTitle}</td>
            <td>
              <div>
                <Tooltip className="tooltip" content={t("common:students")}>
                  <Link to={`/school-admin/students/${item.id}`}>
                    <button>
                      <Users color="#F0783A" />
                    </button>
                  </Link>
                </Tooltip>
                <Tooltip className="tooltip" content={t("common:export")}>
                  <button
                    onClick={() => {
                      setCurrentClassId(item.id);
                      setModal({ ...modal, export: true });
                    }}
                  >
                    <Download color="#0066ff" />
                  </button>
                </Tooltip>
                <Tooltip className="tooltip" content={t("common:edit")}>
                  <button
                    onClick={() => {
                      setCurrentClassId(item.id);
                      setModal({ ...modal, editClass: true });
                    }}
                  >
                    <Edit2 color="#14A38B" />
                  </button>
                </Tooltip>
                <Tooltip className="tooltip" content={t("common:delete")}>
                  <button onClick={() => removeItem(item)}>
                    <Trash color="#FF7171" />
                  </button>
                </Tooltip>
              </div>
            </td>
          </tr>
        ))
      : classStore.slice([0], [10]).map((item, idx) => (
          <tr
            className={
              selectedData.find((i) => i.id === item.id) ? "active" : ""
            }
            key={idx}
          >
            <td className="table-select">
              <label className="check">
                <input
                  type="checkbox"
                  onChange={() => onSelect(item)}
                  checked={selectedData.find((i) => i.id === item.id)}
                />
                <div className="box"></div>
              </label>
              <span>{idx + 1}</span>
            </td>
            <td>
              {item.classLevel}
              {item.classLabel}
            </td>
            <td>{item.languageTitle}</td>
            <td>{item.shift}</td>
            <td>{item.personTitle}</td>
            <td>
              <div>
                <Tooltip className="tooltip" content={t("common:students")}>
                  <Link to={`/school-admin/students/${item.id}`}>
                    <button>
                      <Users color="#F0783A" />
                    </button>
                  </Link>
                </Tooltip>
                <Tooltip className="tooltip" content={t("common:export")}>
                  <button
                    onClick={() => {
                      setCurrentClassId(item.id);
                      setModal({ ...modal, export: true });
                    }}
                  >
                    <Download color="#0066ff" />
                  </button>
                </Tooltip>
                <Tooltip className="tooltip" content={t("common:edit")}>
                  <button
                    onClick={async () => {
                      await setCurrentClassId(item.id);
                      await setModal({ ...modal, editClass: true });
                    }}
                  >
                    <Edit2 color="#14A38B" />
                  </button>
                </Tooltip>
                <Tooltip className="tooltip" content={t("common:delete")}>
                  <button onClick={() => removeItem(item)}>
                    <Trash color="#FF7171" />
                  </button>
                </Tooltip>
              </div>
            </td>
          </tr>
        ));
  return (
    <SchoolAdminLayout>
      <div className="school-presetting__body school-presetting__body-color">
        <div className="school-presetting__body-row">
          <div className="school-presetting__body-title">
            <h2>{t("presetting:classes presetting")}</h2>
          </div>
          <div className="school-presetting__body-description">
            <p className="body-1">{t("schoolAdmin:lorem ipsum of classes")}</p>
          </div>
        </div>
        <div className="school-presetting__subjects-list">
          <div className="school-presetting__search-filter">
            <div className="school-presetting__search">
              <SearchField
                onChange={searchInputHandler}
                defaultValue={searchText}
                placeholder={t("common:search")}
              />
            </div>
            <div className="school-presetting__filter">
              <button
                id="create_class"
                className="primary small"
                onClick={() => setModal({ ...modal, createClass: true })}
              >
                {t("presetting:create classes presetting")}
              </button>
            </div>
          </div>
          <div className="school-presetting__tabs">
            <div className="school-presetting__tabs-item">
              <button
                onClick={() => onFilterHandler(1)}
                className={filterTypeNumber === 1 ? "active" : ""}
              >
                {t("presetting:all classes presetting")}
              </button>
            </div>
            <div className="school-presetting__tabs-item">
              <button
                onClick={() => onFilterHandler(2)}
                className={filterTypeNumber === 2 ? "active" : ""}
              >
                {t("presetting:Primary classes presetting")}
              </button>
            </div>
            <div className="school-presetting__tabs-item">
              <button
                onClick={() => onFilterHandler(3)}
                className={filterTypeNumber === 3 ? "active" : ""}
              >
                {t("presetting:Middle classes presetting")}
              </button>
            </div>
            <div className="school-presetting__tabs-item">
              <button
                onClick={() => onFilterHandler(4)}
                className={filterTypeNumber === 4 ? "active" : ""}
              >
                {t("presetting:Senior classes presetting")}
              </button>
            </div>
          </div>
          <div className="school-presetting__teachers-body">
            <div className="school-presetting__teachers-table">
              {/* <table>{tableRender}</table> */}
              {filteredAllData.length == 0 && filterTypeNumber > 1 ? (
                <NoData messages={[`${t("schoolAdmin:empty")}`]} />
              ) : (
                <>
                  <table>
                    <thead>
                      <tr>
                        <th>№</th>
                        <th>{t("schoolAdmin:class")}</th>
                        <th>{t("presetting:language of study presetting")}</th>
                        <th>{t("presetting:shift presetting")}</th>
                        <th>{t("presetting:classroom teacher presetting")}</th>
                        <th>{t("presetting:action presetting")}</th>
                      </tr>
                    </thead>
                    <tbody>{tableDataRender}</tbody>
                  </table>
                  {selectedData.length > 0 && (
                    <div className="edit-table">
                      <div className="edit-table-left">
                        <div>
                          <p className="caption-1">
                            {t("presetting:choosen presetting")}:
                            <span>{selectedData.length}</span>
                          </p>
                        </div>
                        <div>
                          <button onClick={clearSelected}>
                            {t("presetting:consel presetting")}
                          </button>
                        </div>
                      </div>
                      <div className="edit-table-right">
                        <div>
                          <button
                            onClick={() => {
                              removeSelected(selectedData);
                            }}
                          >
                            {t("presetting:delete presetting")}
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="pagination">
            <div className="paginaiton__inner">
              <div className="pagination__per-page">
                <p>
                  {t("presetting:showing presetting")}{" "}
                  <input
                    type="phone"
                    defaultValue={perPage}
                    onChange={getPerPage}
                  />{" "}
                  {t("presetting:from presetting")}{" "}
                  {searchedCurrentData.length > 0
                    ? searchedCurrentData.length
                    : filteredAllData.length > 0
                    ? filteredAllData.length
                    : classStore.length}{" "}
                  {t("presetting:lines presetting")}
                </p>
              </div>
              <div className="pagination__page">
                <PaginationBlock
                  total={
                    searchedCurrentData.length > 0
                      ? searchedCurrentData.length
                      : filteredAllData.length > 0
                      ? filteredAllData.length
                      : classStore.length
                  }
                  current={currentPage}
                  onChange={onChange}
                  pageSize={perPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modal.createClass}
        onRequestClose={() => {
          setModal({ ...modal, createClass: false });
          setNewClass({
            classType: "PRIMARY",
            label: "",
            level: "",
            languageId: "",
            shiftId: "",
            personId: "",
          });
        }}
        overlayClassName="modal-overlay"
        onAfterOpen={async () => {
          await dispatch(getShifts());
          await dispatch(getLanguages());
          await dispatch(getInstructors());
        }}
      >
        <div className="modal">
          <div className="modal-close">
            <button
              onClick={() => {
                setModal({ ...modal, createClass: false });
                setNewClass({
                  classType: "PRIMARY",
                  label: "",
                  level: "",
                  languageId: "",
                  shiftId: "",
                  personId: "",
                });
              }}
            >
              <X size={15} color="#9A9A9A" />
            </button>
          </div>
          <div className="modal-header">
            <div className="modal-header-title">
              <h2>{t("presetting:new classes presetting")}</h2>
            </div>
            <div className="modal-header-description">
              <p className="body-2">
                {t("presetting:to create new classes presetting")}
              </p>
            </div>
          </div>
          <div className="modal-body">
            <div className="modal-inputs-list">
              <div className="modal-input">
                <SelectSearch
                  options={classLevelRender}
                  search
                  filterOptions={fuzzySearch}
                  name="level"
                  value={newClass?.level}
                  onChange={(_, k) => {
                    onInputChange(k);
                  }}
                  required
                  placeholder={`${t("presetting:int presetting")}`}
                />
              </div>
              <div className="modal-input">
                <input
                  type="text"
                  placeholder={`${t("presetting:string presetting")}`}
                  name="label"
                  defaultValue={newClass?.label}
                  onChange={onInputChange}
                  required
                />
              </div>

              <div className="modal-input">
                <SelectSearch
                  options={languagesOptions}
                  search
                  filterOptions={fuzzySearch}
                  name="languageId"
                  value={newClass?.languageId}
                  onChange={(_, k) => {
                    onInputChange(k);
                  }}
                  required
                  placeholder={`${t(
                    "presetting:language of study presetting"
                  )}`}
                />
              </div>
              <div className="modal-input">
                <SelectSearch
                  options={shiftsOptions}
                  search
                  filterOptions={fuzzySearch}
                  name="shiftId"
                  value={newClass?.shiftId}
                  onChange={(_, k) => {
                    onInputChange(k);
                  }}
                  required
                  placeholder={`${t("presetting:shift presetting")}`}
                />
              </div>
              <div className="modal-input">
                <SelectSearch
                  options={instructorsOptions}
                  search
                  className="select-search drop-up"
                  filterOptions={fuzzySearch}
                  name="personId"
                  value={newClass?.personId}
                  onChange={(_, k) => {
                    onInputChange(k);
                  }}
                  required
                  placeholder={`${t(
                    "presetting:classroom teacher presetting"
                  )}`}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              id="create_class_modal"
              className="large primary"
              onClick={() => createClass()}
              disabled={
                newClass?.label === "" ||
                newClass?.languageId === "" ||
                newClass?.level === "" ||
                newClass?.personId === "" ||
                newClass?.shiftId === ""
              }
            >
              {t("common:create")}
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modal.editClass}
        onRequestClose={() => {
          setModal({ ...modal, editClass: false });
          setEditClass({
            classType: "PRIMARY",
            classLabel: "",
            classLevel: "",
            languageId: "",
            shiftId: "",
            personId: "",
          });
        }}
        overlayClassName="modal-overlay"
        onAfterOpen={async () => {
          await dispatch(getClassById(currentClassId, setEditClass));
        }}
      >
        <div className="modal">
          <div className="modal-close">
            <button
              onClick={() => {
                setModal({ ...modal, editClass: false });
                setEditClass({
                  classType: "PRIMARY",
                  classLabel: "",
                  classLevel: "",
                  languageId: "",
                  shiftId: "",
                  personId: "",
                });
              }}
            >
              <X size={15} color="#9A9A9A" />
            </button>
          </div>
          <div className="modal-header">
            <div className="modal-header-title">
              <h2 className="roboto">
                {editClass.classLevel}
                {editClass.classLabel} {t("schoolAdmin:lower case class")}
              </h2>
            </div>
          </div>
          <div className="modal-body">
            <div className="modal-inputs-list">
              <div className="modal-input">
                <SelectSearch
                  options={classLevelEditRender}
                  search
                  filterOptions={fuzzySearch}
                  name="classLevel"
                  value={editClass.classLevel}
                  onChange={(_, k) => {
                    onEditInputChange(k);
                  }}
                  required
                  placeholder={`${t("presetting:int presetting")}`}
                />
              </div>
              <div className="modal-input">
                <input
                  type="text"
                  placeholder="Буква"
                  name="classLabel"
                  defaultValue={editClass.classLabel}
                  onChange={onEditInputChange}
                />
              </div>

              <div className="modal-input">
                <SelectSearch
                  options={languagesOptions}
                  search
                  filterOptions={fuzzySearch}
                  name="languageId"
                  value={editClass.languageId}
                  onChange={(_, k) => {
                    onEditInputChange(k);
                  }}
                  required
                  placeholder={`${t(
                    "presetting:language of study presetting"
                  )}`}
                />
              </div>
              <div className="modal-input">
                <SelectSearch
                  options={shiftsOptions}
                  search
                  filterOptions={fuzzySearch}
                  name="shiftId"
                  value={editClass.shiftId}
                  onChange={(_, k) => {
                    onEditInputChange(k);
                  }}
                  required
                  placeholder={`${t("presetting:shift presetting")}`}
                />
              </div>
              <div className="modal-input">
                <SelectSearch
                  options={instructorsOptions}
                  search
                  className="select-search drop-up"
                  filterOptions={fuzzySearch}
                  name="personId"
                  value={editClass.personId}
                  onChange={(_, k) => {
                    onEditInputChange(k);
                  }}
                  required
                  placeholder={`${t(
                    "presetting:classroom teacher presetting"
                  )}`}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="large primary"
              onClick={async () => {
                await updateClass();
                // setTimeout(async () => {
                //   await onPageChanged(1, perPage, classStore, filterTypeNumber);
                // }, 1000);
              }}
            >
              {t("common:save")}
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modal.export}
        onRequestClose={() => {
          setModal({ ...modal, export: false });
        }}
        overlayClassName="modal-overlay"
        onAfterOpen={async () => {
          await dispatch(getParentsByClassId(currentClassId));
          await dispatch(getAllStudentsByClass(currentClassId));
        }}
      >
        <div className="modal">
          <div className="modal-close">
            <button
              onClick={() => {
                setModal({ ...modal, export: false });
                setEditClass({
                  classType: "PRIMARY",
                  classLabel: "",
                  classLevel: "",
                  languageId: "",
                  shiftId: "",
                  personId: "",
                });
              }}
            >
              <X size={15} color="#9A9A9A" />
            </button>
          </div>
          <div className="modal-header">
            <div className="modal-header-title">
              <h2>{t("schoolAdmin:export")}</h2>
            </div>
            <div className="modal-header-description">
              <p className="body-2">
                {t("schoolAdmin:export logins and passwords")}
              </p>
            </div>
          </div>

          <div className="modal-footer" style={{ marginTop: "24px" }}>
            <ExcelFile
              filename={`${
                JSON.parse(localStorage.getItem("school"))?.name
              } - ${
                classStore.find((item) => item.id == currentClassId)?.classLevel
              }${
                classStore.find((item) => item.id == currentClassId)?.classLabel
              } - ${t("schoolAdmin:parents")}`}
              element={
                <button className="large primary">
                  {t("schoolAdmin:export")} - {t("schoolAdmin:parents")}
                </button>
              }
            >
              <ExcelSheet
                data={parentsStore}
                name={`${t("schoolAdmin:parents")}`}
              >
                <ExcelColumn
                  label={`${t("schoolAdmin:students")} ${t(
                    "validation:full name"
                  )}`}
                  value="studentTitle"
                />
                <ExcelColumn
                  label={`${t("validation:last name")}`}
                  value="lastName"
                />
                <ExcelColumn
                  label={`${t("validation:first name")}`}
                  value="firstName"
                />
                <ExcelColumn
                  label={`${t("validation:login")}`}
                  value="username"
                />
                <ExcelColumn
                  label={`${t("validation:password")}`}
                  value="username"
                />
              </ExcelSheet>
            </ExcelFile>
            <ExcelFile
              filename={`${
                JSON.parse(localStorage.getItem("school"))?.name
              } - ${
                classStore.find((item) => item.id == currentClassId)?.classLevel
              }${
                classStore.find((item) => item.id == currentClassId)?.classLabel
              } - ${t("schoolAdmin:students")}`}
              element={
                <button className="large primary">
                  {t("schoolAdmin:export")} - {t("schoolAdmin:students")}
                </button>
              }
            >
              <ExcelSheet
                data={studentsStore?.filter((item) => item.archived === false)}
                name={`${t("schoolAdmin:students")}`}
              >
                <ExcelColumn
                  label={`${t("validation:last name")}`}
                  value="lastName"
                />
                <ExcelColumn
                  label={`${t("validation:first name")}`}
                  value="firstName"
                />
                <ExcelColumn
                  label={`${t("validation:login")}`}
                  value="username"
                />
                <ExcelColumn
                  label={`${t("validation:password")}`}
                  value="username"
                />
              </ExcelSheet>
            </ExcelFile>
          </div>
        </div>
      </Modal>
    </SchoolAdminLayout>
  );
}
