/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import PT from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import SelectSearch, { fuzzySearch } from "react-select-search";

import { X, Star } from "react-feather";
import Modal from "react-modal";
// import NoData from "../NoData/NoData";
import { getAllStudentsByClass } from "../../store/student/actions";
import studentManImage from "../../assets/images/student-man.png";
import studentWomanImage from "../../assets/images/student-woman.png";
import {
  getQuarterGradeAnalyticCount,
  getQuarterGradeAnalyticListExcellence,
  getQuarterMark,
} from "../../store/report/actions";

export default function ClassStatisticsGrade(props) {
  const { classId } = props;
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const studentsStore = useSelector((store) => store.students.students);

  const quarterGradeAnalyticCountStore = useSelector(
    (store) => store.report.count
  );
  const quarterGradeAnalyticListExcellenceStore = useSelector(
    (store) => store.report.listExcellence
  );
  const quartersStore = useSelector((store) => store.quarters.quarters);
  const quarterMarkStore = useSelector((store) => store.report.studentsRecord);

  const [quarterId, setQuarterId] = useState(quartersStore?.[0]?.id);
  const [listExcellenceType, setListExcellenceType] = useState("");
  const [modal, setModal] = useState({ studentsGrade: false });

  useEffect(async () => {
    await dispatch(getAllStudentsByClass(classId));
  }, [classId]);
  useEffect(async () => {
    await dispatch(
      getQuarterGradeAnalyticCount(classId, quarterId || quartersStore?.[0]?.id)
    );
    await dispatch(
      getQuarterGradeAnalyticListExcellence(
        classId,
        quarterId || quartersStore?.[0]?.id
      )
    );
    await dispatch(
      getQuarterMark(
        classId,
        quarterId || quartersStore?.[0]?.id,
        JSON.parse(localStorage.getItem("chronicleActive")).id
      )
    );
  }, [classId, quarterId]);

  const printStar = (number) => {
    const row = [];
    for (var i = 0; i < number; i++) {
      row.push(<Star fill="#FFD84F" color="#FFD84F" key={i} />);
    }
    return row;
  };

  const quartersOptions = quartersStore.map((item) => ({
    name: `${item.quarter} ${t("instructor:quarter")}`,
    value: item.id,
    key: "quarterId",
  }));

  return (
    <>
      <div className="school-admin__schedule-group school-admin__schedule school-presetting__body school-presetting__body-color class-statistics_grade">
        <div className="class-statistics_grade_student-count">
          <div className="school-presetting__body-row">
            <div className="school-presetting__body-title">
              <h2>
                {t("schoolAdmin:number of students")}{" "}
                <span className="roboto orange-text">
                  {studentsStore?.length}
                </span>
              </h2>
            </div>
          </div>
          <div className="school-presetting__body-row">
            <div className="class-statistics_grade-body">
              <div className="class-statistics_grade-body-count">
                <div className="class-statistics_grade-gender">
                  <div className="class-statistics_grade-gender-graph">
                    <div className="svg-item">
                      <svg
                        width="100%"
                        height="100%"
                        viewBox="0 0 40 40"
                        className="donut"
                      >
                        <circle
                          className="donut-hole"
                          cx="20"
                          cy="20"
                          r="15.91549430918954"
                          fill="#FFFFFF"
                        ></circle>
                        <circle
                          className="donut-ring"
                          cx="20"
                          cy="20"
                          r="15.91549430918954"
                          fill="transparent"
                          strokeWidth="3.5"
                        ></circle>
                        <circle
                          className="donut-segment donut-segment-3"
                          cx="20"
                          cy="20"
                          r="15.91549430918954"
                          fill="transparent"
                          strokeWidth="3.5"
                          strokeDasharray={`${
                            (studentsStore.filter((item) => item.gender === 1)
                              .length /
                              studentsStore.length) *
                            100
                          }${" "}${
                            100 -
                            (studentsStore.filter((item) => item.gender === 1)
                              .length /
                              studentsStore.length) *
                              100
                          }`}
                          strokeDashoffset="25"
                        ></circle>
                        <g className="donut-text donut-text-2">
                          <text y="50%" transform="translate(0, 2)">
                            <tspan
                              x="50%"
                              textAnchor="middle"
                              className="donut-percent roboto"
                            >
                              {(studentsStore.filter(
                                (item) => item.gender === 1
                              ).length /
                                studentsStore.length) *
                              100
                                ? Math.round(
                                    (studentsStore.filter(
                                      (item) => item.gender === 1
                                    ).length /
                                      studentsStore.length) *
                                      100
                                  )
                                : 0}
                              %
                            </tspan>
                          </text>
                          {/* <text y="60%" transform="translate(0, 2)">
                      <tspan x="50%" textAnchor="middle" className="donut-data">
                        {studentsStore?.length}
                      </tspan>
                    </text> */}
                        </g>
                      </svg>
                    </div>
                  </div>
                  <div className="class-statistics_grade-gender-text">
                    <p className="class-statistics_grade-gender-count roboto">
                      {studentsStore.filter((item) => item.gender === 1).length}
                    </p>
                    <p className="class-statistics_grade-gender-type">
                      {t("instructor:boys")}
                    </p>
                  </div>
                  <div className="class-statistics_grade-gender-image">
                    <img src={studentManImage} alt="" />{" "}
                  </div>
                </div>
                <div className="class-statistics_grade-gender">
                  <div className="class-statistics_grade-gender-graph">
                    <div className="svg-item">
                      <svg
                        width="100%"
                        height="100%"
                        viewBox="0 0 40 40"
                        className="donut"
                      >
                        <circle
                          className="donut-hole"
                          cx="20"
                          cy="20"
                          r="15.91549430918954"
                          fill="#FFFFFF"
                        ></circle>
                        <circle
                          className="donut-ring"
                          cx="20"
                          cy="20"
                          r="15.91549430918954"
                          fill="transparent"
                          strokeWidth="3.5"
                        ></circle>
                        <circle
                          className="donut-segment donut-segment-4"
                          cx="20"
                          cy="20"
                          r="15.91549430918954"
                          fill="transparent"
                          strokeWidth="3.5"
                          strokeDasharray={`${
                            (studentsStore.filter((item) => item.gender === 2)
                              .length /
                              studentsStore.length) *
                            100
                          }${" "}${
                            100 -
                            (studentsStore.filter((item) => item.gender === 2)
                              .length /
                              studentsStore?.length) *
                              100
                          }`}
                          strokeDashoffset="25"
                        ></circle>
                        <g className="donut-text donut-text-3">
                          <text y="50%" transform="translate(0, 2)">
                            <tspan
                              x="50%"
                              textAnchor="middle"
                              className="donut-percent roboto"
                            >
                              {(studentsStore.filter(
                                (item) => item.gender === 2
                              ).length /
                                studentsStore.length) *
                              100
                                ? Math.round(
                                    (studentsStore.filter(
                                      (item) => item.gender === 2
                                    ).length /
                                      studentsStore.length) *
                                      100
                                  )
                                : 0}
                              %
                            </tspan>
                          </text>
                          {/* <text y="60%" transform="translate(0, 2)">
                      <tspan x="50%" textAnchor="middle" className="donut-data">
                        50 widgets
                      </tspan>
                    </text> */}
                        </g>
                      </svg>
                    </div>
                  </div>
                  <div className="class-statistics_grade-gender-text">
                    <p className="class-statistics_grade-gender-count roboto">
                      {studentsStore.filter((item) => item.gender === 2).length}
                    </p>
                    <p className="class-statistics_grade-gender-type">
                      {t("instructor:girls")}
                    </p>
                  </div>

                  <div className="class-statistics_grade-gender-image">
                    <img src={studentWomanImage} alt="" />{" "}
                  </div>
                </div>
              </div>
              <div className="class-statistics_grade-data">
                {/* {sortedSchedule?.filter(
            (item) =>
              item.weekDay ==
              days.find((item) => item.id + 1 == currentWeekDayNumber)?.en
          )?.length > 0 ? (
            sortedSchedule
              ?.filter(
                (item) =>
                  item.weekDay ==
                  days.find((item) => item.id + 1 == currentWeekDayNumber)?.en
              )
              .map((item, idx) => (
                <div className="my-class__schedule-item-wrapper" key={idx}>
                  <div className="my-class__schedule-divider">
                    <span className="roboto">
                      {
                        shiftTimesStore.find(
                          (shiftTime) => shiftTime.id == item.shiftTimeId
                        )?.startTime
                      }
                    </span>
                    <div className="dashed"></div>
                  </div>

                  <div className="my-class__schedule-item">
                    <div className="my-class__schedule-item-icon">
                      {String(i18n.language).toUpperCase() !== "EN" &&
                      String(i18n.language).toUpperCase() !== "TR"
                        ? `${item[
                            `courseTitle${String(i18n.language).toUpperCase()}`
                          ]?.slice(0, 1)} `
                        : `${item.courseTitle?.slice(0, 1)}} `}
                    </div>
                    <div className="my-class__schedule-item-body">
                      <div className="my-class__schedule-item-title">
                        {String(i18n.language).toUpperCase() !== "EN" &&
                        String(i18n.language).toUpperCase() !== "TR"
                          ? `${
                              item[
                                `courseTitle${String(
                                  i18n.language
                                ).toUpperCase()}`
                              ]
                            } `
                          : `${item.courseTitle} `}
                      </div>
                      <div className="my-class__schedule-item-description roboto">
                        {
                          shiftTimesStore.find(
                            (shiftTime) => shiftTime.id == item.shiftTimeId
                          )?.startTime
                        }
                        -
                        {
                          shiftTimesStore.find(
                            (shiftTime) => shiftTime.id == item.shiftTimeId
                          )?.endTime
                        }
                      </div>
                    </div>
                    <div className="my-class__schedule-item-info roboto">
                      <p>{item.classTitle}</p>
                      <p>{item.isGroup && t("schoolAdmin:a group")}</p>
                    </div>
                  </div>
                </div>
              ))
          ) : (
            <NoData />
          )} */}
              </div>
            </div>
          </div>
        </div>

        <div className="school-presetting__body-row">
          <div className="school-presetting__body-title">
            <div>
              <h2>
                {t("instructor:class achievement")}{" "}
                <span className="roboto orange-text">
                  {isNaN(
                    studentsStore
                      .map((student) => {
                        return (
                          quarterMarkStore
                            .filter((mark) => mark.studentId === student.id)
                            .filter((mark) => mark.quarterMark !== null)
                            .reduce(
                              (acc, current) =>
                                acc + Number(current?.quarterMark),
                              0
                            ) /
                          quarterMarkStore
                            .filter((mark) => mark.studentId === student.id)
                            .filter((mark) => mark?.quarterMark !== null).length
                        );
                      })
                      .filter((item) => item)
                      .reduce((acc, curr) => acc + curr, 0) /
                      studentsStore.length
                  )
                    ? 0
                    : (
                        studentsStore
                          .map((student) => {
                            return (
                              quarterMarkStore
                                .filter((mark) => mark.studentId === student.id)
                                .filter((mark) => mark.quarterMark !== null)
                                .reduce(
                                  (acc, current) =>
                                    acc + Number(current?.quarterMark),
                                  0
                                ) /
                              quarterMarkStore
                                .filter((mark) => mark.studentId === student.id)
                                .filter((mark) => mark?.quarterMark !== null)
                                .length
                            );
                          })
                          .filter((item) => item)
                          .reduce((acc, curr) => acc + curr, 0) /
                        studentsStore.length
                      ).toFixed(1)}
                </span>
              </h2>
              <SelectSearch
                options={quartersOptions}
                search
                filterOptions={fuzzySearch}
                className="select-search small filter"
                name="quarterId"
                value={quarterId || quartersStore?.[0]?.id}
                onChange={(_, k) => {
                  setQuarterId(k.value);
                }}
                required
                placeholder={`${t("instructor:quarter")}`}
              />
            </div>
          </div>
        </div>

        <div className="class-statistics_grade-body">
          <div className="class-statistics_grade-body-achievements">
            <button
              onClick={() => {
                setListExcellenceType("fives");
                setModal({ ...modal, studentsQuarterGrade: true });
              }}
            >
              <div className="class-statistics_grade-achievement">
                <div className="class-statistics_grade-achievement-text">
                  {t("instructor:count of grade 5 students")}:
                  <span className="roboto bold">
                    {quarterGradeAnalyticCountStore?.[0]?.fives || 0}
                  </span>
                </div>
                <div className="class-statistics_grade-achievement-number roboto">
                  5
                </div>
              </div>
            </button>
            <button
              onClick={() => {
                setListExcellenceType("fours");
                setModal({ ...modal, studentsQuarterGrade: true });
              }}
            >
              <div className="class-statistics_grade-achievement">
                <div className="class-statistics_grade-achievement-text">
                  {t("instructor:count of grade 4 students")}:{" "}
                  <span className="roboto">
                    {quarterGradeAnalyticCountStore?.[0]?.fours || 0}
                  </span>
                </div>
                <div className="class-statistics_grade-achievement-number roboto">
                  4
                </div>
              </div>
            </button>

            <button
              onClick={() => {
                setListExcellenceType("threes");
                setModal({ ...modal, studentsQuarterGrade: true });
              }}
            >
              <div className="class-statistics_grade-achievement">
                <div className="class-statistics_grade-achievement-text">
                  {t("instructor:count of grade 3 students")}:{" "}
                  <span className="roboto">
                    {quarterGradeAnalyticCountStore?.[0]?.threes || 0}
                  </span>
                </div>
                <div className="class-statistics_grade-achievement-number roboto">
                  3
                </div>
              </div>
            </button>

            <button
              onClick={() => {
                setListExcellenceType("twos");
                setModal({ ...modal, studentsQuarterGrade: true });
              }}
            >
              <div className="class-statistics_grade-achievement">
                <div className="class-statistics_grade-achievement-text">
                  {t("instructor:count of grade 2 students")}:{" "}
                  <span className="roboto">
                    {quarterGradeAnalyticCountStore?.[0]?.twos || 0}
                  </span>
                </div>
                <div className="class-statistics_grade-achievement-number roboto">
                  2
                </div>
              </div>
            </button>
          </div>
        </div>

        <div className="class-statistics_grade-body">
          <div className="class-statistics_grade-students">
            {studentsStore
              .sort((a, b) => a.lastName - b.lastName)
              .map((student, idx) => (
                <div className="class-statistics_grade-student" key={idx}>
                  <div className="class-statistics_grade-student-title">
                    <span className="roboto">{idx + 1}</span> {student.lastName}{" "}
                    {student.firstName}
                  </div>
                  <div className="class-statistics_grade-student-mark">
                    <div className="class-statistics_grade-student-stars">
                      {printStar(
                        Math.round(
                          isNaN(
                            quarterMarkStore
                              .filter((mark) => mark.studentId === student.id)
                              .filter((mark) => mark.quarterMark !== null)
                              .reduce(
                                (acc, current) =>
                                  acc + Number(current?.quarterMark),
                                0
                              ) /
                              quarterMarkStore
                                .filter((mark) => mark.studentId === student.id)
                                .filter((mark) => mark.quarterMark !== null)
                                .length
                          )
                            ? 0
                            : Math.round(
                                quarterMarkStore
                                  .filter(
                                    (mark) => mark.studentId === student.id
                                  )
                                  .filter((mark) => mark.quarterMark !== null)
                                  .reduce(
                                    (acc, current) =>
                                      acc + Number(current?.quarterMark),
                                    0
                                  ) /
                                  quarterMarkStore
                                    .filter(
                                      (mark) => mark.studentId === student.id
                                    )
                                    .filter((mark) => mark.quarterMark !== null)
                                    .length
                              )
                        )
                      )}
                    </div>
                    <span className="roboto">
                      {isNaN(
                        quarterMarkStore
                          .filter((mark) => mark.studentId === student.id)
                          .filter((mark) => mark.quarterMark !== null)
                          .reduce(
                            (acc, current) =>
                              acc + Number(current?.quarterMark),
                            0
                          ) /
                          quarterMarkStore
                            .filter((mark) => mark.studentId === student.id)
                            .filter((mark) => mark.quarterMark !== null).length
                      )
                        ? 0
                        : (
                            quarterMarkStore
                              .filter((mark) => mark.studentId === student.id)
                              .filter((mark) => mark.quarterMark !== null)
                              .reduce(
                                (acc, current) =>
                                  acc + Number(current?.quarterMark),
                                0
                              ) /
                            quarterMarkStore
                              .filter((mark) => mark.studentId === student.id)
                              .filter((mark) => mark.quarterMark !== null)
                              .length
                          ).toFixed(1)}
                    </span>
                  </div>
                </div>
              ))
              .slice(0, 3)}
          </div>
          {studentsStore.length > 3 && (
            <div className="class-statistics_grade-students-more">
              <button
                className="pure"
                onClick={() => {
                  setModal({ ...modal, studentsGrade: true });
                }}
              >
                {t("instructor:view full list")}
              </button>
            </div>
          )}
        </div>
      </div>
      <Modal
        isOpen={modal.studentsGrade}
        onRequestClose={() => {
          setModal({ ...modal, studentsGrade: false });
        }}
        overlayClassName="modal-overlay"
        // onAfterOpen={async () => {
        //   // await dispatch(getParentsByCalendarPlanId(currentCalendarTopicId));
        // }}
      >
        <div className="modal">
          <div className="modal-close">
            <button
              onClick={() => {
                setModal({ ...modal, studentsGrade: false });
              }}
            >
              <X size={15} color="#9A9A9A" />
            </button>
          </div>
          <div className="modal-header">
            <div className="modal-header-title">
              <h2>{t("instructor:average student grade")}</h2>
            </div>
          </div>
          <div className="modal-body">
            <div className="class-statistics_grade-students">
              {studentsStore
                .sort((a, b) => a.lastName - b.lastName)
                .map((student, idx) => (
                  <div className="class-statistics_grade-student" key={idx}>
                    <div className="class-statistics_grade-student-title">
                      <span className="roboto">{idx + 1}</span>{" "}
                      {student.lastName} {student.firstName}
                    </div>
                    <div className="class-statistics_grade-student-mark">
                      <div className="class-statistics_grade-student-stars">
                        {printStar(
                          Math.round(
                            isNaN(
                              quarterMarkStore
                                .filter((mark) => mark.studentId === student.id)
                                .filter((mark) => mark.quarterMark !== null)
                                .reduce(
                                  (acc, current) =>
                                    acc + Number(current?.quarterMark),
                                  0
                                ) /
                                quarterMarkStore
                                  .filter(
                                    (mark) => mark.studentId === student.id
                                  )
                                  .filter((mark) => mark.quarterMark !== null)
                                  .length
                            )
                              ? 0
                              : Math.round(
                                  quarterMarkStore
                                    .filter(
                                      (mark) => mark.studentId === student.id
                                    )
                                    .filter((mark) => mark.quarterMark !== null)
                                    .reduce(
                                      (acc, current) =>
                                        acc + Number(current?.quarterMark),
                                      0
                                    ) /
                                    quarterMarkStore
                                      .filter(
                                        (mark) => mark.studentId === student.id
                                      )
                                      .filter(
                                        (mark) => mark.quarterMark !== null
                                      ).length
                                )
                          )
                        )}
                      </div>
                      <span className="roboto">
                        {isNaN(
                          quarterMarkStore
                            .filter((mark) => mark.studentId === student.id)
                            .filter((mark) => mark.quarterMark !== null)
                            .reduce(
                              (acc, current) =>
                                acc + Number(current?.quarterMark),
                              0
                            ) /
                            quarterMarkStore
                              .filter((mark) => mark.studentId === student.id)
                              .filter((mark) => mark.quarterMark !== null)
                              .length
                        )
                          ? 0
                          : (
                              quarterMarkStore
                                .filter((mark) => mark.studentId === student.id)
                                .filter((mark) => mark.quarterMark !== null)
                                .reduce(
                                  (acc, current) =>
                                    acc + Number(current?.quarterMark),
                                  0
                                ) /
                              quarterMarkStore
                                .filter((mark) => mark.studentId === student.id)
                                .filter((mark) => mark.quarterMark !== null)
                                .length
                            ).toFixed(1)}
                      </span>
                    </div>
                  </div>
                ))}
            </div>
          </div>

          {/* <div className="modal-footer" style={{ marginTop: "24px" }}></div> */}
        </div>
      </Modal>
      <Modal
        isOpen={modal.studentsQuarterGrade}
        onRequestClose={() => {
          setModal({ ...modal, studentsQuarterGrade: false });
        }}
        overlayClassName="modal-overlay"
        // onAfterOpen={async () => {
        //   // await dispatch(getParentsByCalendarPlanId(currentCalendarTopicId));
        // }}
      >
        <div className="modal">
          <div className="modal-close">
            <button
              onClick={() => {
                setModal({ ...modal, studentsQuarterGrade: false });
              }}
            >
              <X size={15} color="#9A9A9A" />
            </button>
          </div>
          <div className="modal-header">
            <div className="modal-header-title">
              <h2>{t("schoolAdmin:students")}</h2>
            </div>
          </div>
          <div className="modal-body">
            <div className="class-statistics_grade-students">
              {quarterGradeAnalyticListExcellenceStore
                .filter((student) => student?.[`${listExcellenceType}`] === 1)
                .sort((a, b) => a.lastName - b.lastName)
                .map((student, idx) => (
                  <div className="class-statistics_grade-student" key={idx}>
                    <div className="class-statistics_grade-student-title">
                      <span className="roboto">{idx + 1}</span>{" "}
                      {student.studentTitle}
                    </div>
                  </div>
                ))}
            </div>
          </div>

          {/* <div className="modal-footer" style={{ marginTop: "24px" }}></div> */}
        </div>
      </Modal>
    </>
  );
}

ClassStatisticsGrade.propTypes = {
  classId: PT.number,
};
