import {
  GET_GRADE_SHEET_REQUEST,
  GET_GRADE_SHEET_SUCCESS,
  GET_GRADE_SHEET_ERROR,
  GET_GRADE_SHEET_COURSES_SUCCESS,
  GET_GRADE_SHEET_PEOPLE_SUCCESS,
  GET_QUARTER_GRADE_ANALYTIC_CLASS_PERFORMANCE_REQUEST,
  GET_QUARTER_GRADE_ANALYTIC_CLASS_PERFORMANCE_SUCCESS,
  GET_QUARTER_GRADE_ANALYTIC_CLASS_PERFORMANCE_ERROR,
  GET_QUARTER_GRADE_ANALYTIC_LIST_EXCELLENCE_REQUEST,
  GET_QUARTER_GRADE_ANALYTIC_LIST_EXCELLENCE_SUCCESS,
  GET_QUARTER_GRADE_ANALYTIC_LIST_EXCELLENCE_ERROR,
  GET_QUARTER_GRADE_ANALYTIC_COUNT_REQUEST,
  GET_QUARTER_GRADE_ANALYTIC_COUNT_SUCCESS,
  GET_QUARTER_GRADE_ANALYTIC_COUNT_ERROR,
  GET_STUDENTS_REPORT_ATTENDANCE,
  GET_STUDENTS_REPORT_ATTENDANCE_SUCCESS,
  GET_STUDENTS_REPORT_ATTENDANCE_ERROR,
  QUARTER_TITLE_REQUEST,
  GET_QUARTER_TITLE_REQUEST_SUCCESS,
  GET_QUARTER_TITLE_REQUEST_ERROR,
  GET_STUDENT_ANALYTIC_REPORTS,
  GET_STUDENT_ANALYTIC_REPORTS_ERROR,
  GET_STUDENT_ANALYTIC_REPORTS_SUCCESS
} from "../actionTypes";
import { statuses } from "../../config";

const initialState = {
  studentsRecord: [],
  studentsAttendance: { data: [], status: statuses.INITIAL },
  courses: [],
  students: [],
  classPerformance: {},
  listExcellence: [],
  quarter: [],
  count: [],
  analyticStudents: [],
  error: "",
  status: statuses.INITIAL,
};

export const reportReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_GRADE_SHEET_REQUEST:
      return {
        ...state,
        studentsRecord: [],
        error: "",
        status: statuses.LOADING,
      };
    case GET_GRADE_SHEET_SUCCESS:
      return {
        ...state,
        studentsRecord: action.payload,
        error: "",
        status: statuses.SUCCESS,
      };
    case GET_GRADE_SHEET_COURSES_SUCCESS:
      return {
        ...state,
        courses: action.payload,
        error: "",
        status: statuses.SUCCESS,
      };
    case GET_GRADE_SHEET_PEOPLE_SUCCESS:
      return {
        ...state,
        students: action.payload,
        error: "",
        status: statuses.SUCCESS,
      };
    case GET_GRADE_SHEET_ERROR:
      return {
        ...state,
        error: action.payload,
        status: statuses.ERROR,
        studentsRecord: [],
      };
    case GET_QUARTER_GRADE_ANALYTIC_CLASS_PERFORMANCE_REQUEST:
      return {
        ...state,
        classPerformance: {},
        error: "",
        status: statuses.LOADING,
      };
    case GET_QUARTER_GRADE_ANALYTIC_CLASS_PERFORMANCE_SUCCESS:
      return {
        ...state,
        classPerformance: action.payload,
        error: "",
        status: statuses.SUCCESS,
      };

    case GET_QUARTER_GRADE_ANALYTIC_CLASS_PERFORMANCE_ERROR:
      return {
        ...state,
        error: action.payload,
        status: statuses.ERROR,
        classPerformance: {},
      };
    case GET_QUARTER_GRADE_ANALYTIC_LIST_EXCELLENCE_REQUEST:
      return {
        ...state,
        listExcellence: [],
        error: "",
        status: statuses.LOADING,
      };
    case GET_QUARTER_GRADE_ANALYTIC_LIST_EXCELLENCE_SUCCESS:
      return {
        ...state,
        listExcellence: action.payload,
        error: "",
        status: statuses.SUCCESS,
      };

    case GET_QUARTER_GRADE_ANALYTIC_LIST_EXCELLENCE_ERROR:
      return {
        ...state,
        error: action.payload,
        status: statuses.ERROR,
        listExcellence: [],
      };
    case GET_QUARTER_GRADE_ANALYTIC_COUNT_REQUEST:
      return {
        ...state,
        count: [],
        error: "",
        status: statuses.LOADING,
      };
    case GET_QUARTER_GRADE_ANALYTIC_COUNT_SUCCESS:
      return {
        ...state,
        count: action.payload,
        error: "",
        status: statuses.SUCCESS,
      };

    case GET_QUARTER_GRADE_ANALYTIC_COUNT_ERROR:
      return {
        ...state,
        error: action.payload,
        status: statuses.ERROR,
        count: [],
      };
    // !------------------------
    case GET_STUDENTS_REPORT_ATTENDANCE:
      return {
        ...state,
        studentsAttendance: { data: [], status: statuses.LOADING },
        error: "",
        status: statuses.LOADING,
      };
    case GET_STUDENTS_REPORT_ATTENDANCE_SUCCESS:
      return {
        ...state,
        studentsAttendance: { data: action.payload, status: statuses.SUCCESS },
        error: "",
        status: statuses.SUCCESS,
      };

    case GET_STUDENTS_REPORT_ATTENDANCE_ERROR:
      return {
        ...state,
        error: action.payload,
        status: statuses.ERROR,
        studentsAttendance: { data: [], status: statuses.ERROR },
      };
    // !------------------------QUARTER
    case QUARTER_TITLE_REQUEST:
      return {
        ...state,
        quarter: [],
        error: "",
        status: statuses.LOADING,
      };
    case GET_QUARTER_TITLE_REQUEST_SUCCESS:
      return {
        ...state,
        quarter: action.payload,
        error: "",
        status: statuses.SUCCESS,
      };

    case GET_QUARTER_TITLE_REQUEST_ERROR:
      return {
        ...state,
        error: action.payload,
        status: statuses.ERROR,
        quarter: [],
      };
    
    case GET_STUDENT_ANALYTIC_REPORTS:
      return {
        ...state,
        analyticStudents: [],
        error: "",
        status: statuses.LOADING
      };
    
    case GET_STUDENT_ANALYTIC_REPORTS_SUCCESS:
      return {
        ...state,
        analyticStudents: action.payload,
        error: "",
        status: statuses.SUCCESS
      };

    case GET_STUDENT_ANALYTIC_REPORTS_ERROR:
      return {
        ...state,
        analyticStudents: [],
        error: action.payload,
        status: statuses.ERROR
      };

    default:
      return state;
  }
};
