import {
  GET_INSTRUCTOR_REQUEST,
  GET_INSTRUCTOR_SUCCESS,
  GET_INSTRUCTOR_ERROR,
  GET_INSTRUCTOR_COURSE_REQUEST,
  GET_INSTRUCTOR_COURSE_SUCCESS,
  GET_INSTRUCTOR_COURSE_ERROR,
  GET_INSTRUCTOR_GRADE_MARK_REQUEST,
  GET_INSTRUCTOR_GRADE_MARK_SUCCESS,
  GET_INSTRUCTOR_GRADE_MARK_ERROR,
  GET_INSTRUCTOR_GRADE_TOPIC_MARK_REQUEST,
  GET_INSTRUCTOR_GRADE_TOPIC_MARK_SUCCESS,
  GET_INSTRUCTOR_GRADE_TOPIC_MARK_ERROR,
  GET_INSTRUCTOR_GRADE_HW_MARK_REQUEST,
  GET_INSTRUCTOR_GRADE_HW_MARK_SUCCESS,
  GET_INSTRUCTOR_GRADE_HW_MARK_ERROR,
  GET_INSTRUCTOR_GRADE_GRADE_SHEET_BY_COURSES_REQUEST,
  GET_INSTRUCTOR_GRADE_GRADE_SHEET_BY_COURSES_SUCCESS,
  GET_INSTRUCTOR_GRADE_GRADE_SHEET_BY_COURSES_ERROR,
  GET_INSTRUCTOR_COURSES_REQUEST,
  GET_INSTRUCTOR_COURSES_SUCCESS,
  GET_INSTRUCTOR_COURSES_ERROR,
  GET_CLASS_COURSES_REQUEST,
  GET_CLASS_COURSES_SUCCESS,
  GET_CLASS_COURSES_ERROR,
} from "../actionTypes";
import { statuses } from "../../config";

const initialState = {
  instructors: [],
  classCourses: [],
  instructorCourses: [],
  instructorsGradeMark: [],
  instructorsGradeTopicMark: [],
  instructorsGradeHWMark: [],
  instructorsGradeSheetByCourses: [],
  courses: [],
  error: "",
  status: statuses.INITIAL,
};

export const instructorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_INSTRUCTOR_REQUEST:
      return {
        ...state,
        instructors: [],
        error: "",
        status: statuses.LOADING,
      };
    case GET_INSTRUCTOR_SUCCESS:
      return {
        ...state,
        instructors: action.payload,
        error: "",
        status: statuses.SUCCESS,
      };
    case GET_INSTRUCTOR_ERROR:
      return {
        ...state,
        instructors: [],
        error: action.payload,
        status: statuses.ERROR,
      };
    case GET_INSTRUCTOR_COURSE_REQUEST:
      return {
        ...state,
        courses: [],
        error: "",
        status: statuses.LOADING,
      };
    case GET_INSTRUCTOR_COURSE_SUCCESS:
      return {
        ...state,
        courses: action.payload,
        error: "",
        status: statuses.SUCCESS,
      };
    case GET_INSTRUCTOR_COURSE_ERROR:
      return {
        ...state,
        courses: [],
        error: action.payload,
        status: statuses.ERROR,
      };

    case GET_INSTRUCTOR_GRADE_MARK_REQUEST:
      return {
        ...state,
        instructorsGradeMark: [],
        error: "",
        status: statuses.LOADING,
      };
    case GET_INSTRUCTOR_GRADE_MARK_SUCCESS:
      return {
        ...state,
        instructorsGradeMark: action.payload,
        error: "",
        status: statuses.SUCCESS,
      };
    case GET_INSTRUCTOR_GRADE_MARK_ERROR:
      return {
        ...state,
        instructorsGradeMark: [],
        error: action.payload,
        status: statuses.ERROR,
      };

    case GET_INSTRUCTOR_GRADE_TOPIC_MARK_REQUEST:
      return {
        ...state,
        instructorsGradeTopicMark: [],
        error: "",
        status: statuses.LOADING,
      };
    case GET_INSTRUCTOR_GRADE_TOPIC_MARK_SUCCESS:
      return {
        ...state,
        instructorsGradeTopicMark: action.payload,
        error: "",
        status: statuses.SUCCESS,
      };
    case GET_INSTRUCTOR_GRADE_TOPIC_MARK_ERROR:
      return {
        ...state,
        instructorsGradeTopicMark: [],
        error: action.payload,
        status: statuses.ERROR,
      };

    case GET_INSTRUCTOR_GRADE_HW_MARK_REQUEST:
      return {
        ...state,
        instructorsGradeHWMark: [],
        error: "",
        status: statuses.LOADING,
      };
    case GET_INSTRUCTOR_GRADE_HW_MARK_SUCCESS:
      return {
        ...state,
        instructorsGradeHWMark: action.payload,
        error: "",
        status: statuses.SUCCESS,
      };
    case GET_INSTRUCTOR_GRADE_HW_MARK_ERROR:
      return {
        ...state,
        instructorsGradeHWMark: [],
        error: action.payload,
        status: statuses.ERROR,
      };

    case GET_INSTRUCTOR_GRADE_GRADE_SHEET_BY_COURSES_REQUEST:
      return {
        ...state,
        instructorsGradeSheetByCourses: [],
        error: "",
        status: statuses.LOADING,
      };
    case GET_INSTRUCTOR_GRADE_GRADE_SHEET_BY_COURSES_SUCCESS:
      return {
        ...state,
        instructorsGradeSheetByCourses: action.payload,
        error: "",
        status: statuses.SUCCESS,
      };
    case GET_INSTRUCTOR_GRADE_GRADE_SHEET_BY_COURSES_ERROR:
      return {
        ...state,
        instructorsGradeSheetByCourses: [],
        error: action.payload,
        status: statuses.ERROR,
      };

    case GET_INSTRUCTOR_COURSES_REQUEST:
      return {
        ...state,
        instructorCourses: [],
        error: "",
        status: statuses.LOADING,
      };
    case GET_INSTRUCTOR_COURSES_SUCCESS:
      return {
        ...state,
        instructorCourses: action.payload,
        error: "",
        status: statuses.SUCCESS,
      };
    case GET_INSTRUCTOR_COURSES_ERROR:
      return {
        ...state,
        instructorCourses: [],
        error: action.payload,
        status: statuses.ERROR,
      };

    case GET_CLASS_COURSES_REQUEST:
      return {
        ...state,
        classCourses: [],
        error: "",
        status: statuses.LOADING,
      };
    case GET_CLASS_COURSES_SUCCESS:
      return {
        ...state,
        classCourses: action.payload,
        error: "",
        status: statuses.SUCCESS,
      };
    case GET_CLASS_COURSES_ERROR:
      return {
        ...state,
        classCourses: [],
        error: action.payload,
        status: statuses.ERROR,
      };

    default:
      return state;
  }
};
