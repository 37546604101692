import React from "react";
import { withRouter } from "react-router-dom";
import { set, pageview } from "react-ga";
import PT from "prop-types";

const RouteChangeTracker = ({ history }) => {
  history.listen((location) => {
    set({ page: location.pathname });
    pageview(location.pathname);
  });

  return <div></div>;
};
RouteChangeTracker.propTypes = {
  history: PT.any,
};
export default withRouter(RouteChangeTracker);
