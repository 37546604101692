import React from "react";
import { UnregisteredUserText, LoginLayout } from "../../components";
import { useParams } from "react-router-dom";

export default function UnregisteredUser() {
  const { phone_number } = useParams();
  console.log(phone_number);
  return (
    <div>
      <LoginLayout>
        <UnregisteredUserText user={phone_number} />
      </LoginLayout>
    </div>
  );
}
