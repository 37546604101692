/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import { useTable } from "react-table";
import PT from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { X, PlusCircle, Trash } from "react-feather";
import { constants } from "../../config";
import SelectSearch, { fuzzySearch } from "react-select-search";

import { getClassBySchoolId } from "../../store/class/actions";
import { getShifts } from "../../store/shift/actions";
import { getShiftTimes } from "../../store/shiftTime/actions";
import { getSchoolById } from "../../store/school/actions";
import { getInstructors } from "../../store/instructor/actions";
import { getScheduleGroupsByClassId } from "../../store/scheduleGroup/actions";
import {
  addSchedule,
  getSchedules,
  // getScheduleById,
  deleteSchedule,
  updateSchedule,
} from "../../store/schedule/actions";
import { getCourses } from "../../store/course/actions";

ManualScheduleClass.propTypes = {
  shiftId: PT.number,
  instructor: PT.boolean
};

export default function ManualScheduleClass(props) {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  const { shiftId } = props;
  const { instructor } = props;
  const dispatch = useDispatch();
  const classStore = useSelector((store) =>
    store.class.class
      .sort((a, b) => a.classLabel.localeCompare(b.classLabel))
      .sort((a, b) => a.classLevel - b.classLevel)
  );
  const shiftsStore = useSelector((store) => store.shifts.shifts);
  const shiftTimesStore = useSelector((store) =>
    store.shiftTimes.shiftTimes.sort((a, b) =>
      a.startTime < b.startTime ? -1 : a.startTime > b.startTime ? 1 : 0
    )
  );
  const schedulesStore = useSelector((store) => store.schedules.schedules);
  const courseStore = useSelector((store) => store.courses.courses);
  const scheduleGroupsStore = useSelector(
    (store) => store.scheduleGroups.scheduleGroups
  );
  const instructorsStore = useSelector(
    (store) => store.instructors.instructors
  );
  const [isGroup, setIsGroup] = useState(false);
  const [isDisabledButton, setIsDisabledButton] = useState(true);
  const [newSchedule, setNewSchedule] = useState([
    {
      instructorId: "",
      isGroup: false,
      courseId: "",
      groupTitle: "",
      scheduleGroupId: "",
    },
  ]);
  const [editSchedule, setEditSchedule] = useState({});
  const [modal, setModal] = useState({
    createSchedule: false,
    editSchedule: false,
  });
  useEffect(async () => {
    await dispatch(getSchedules());
    await dispatch(getCourses());
    await dispatch(getClassBySchoolId());
    await dispatch(getShifts());
    await dispatch(getShiftTimes());
    await dispatch(
      getSchoolById(JSON.parse(localStorage.getItem("school"))?.id)
    );
  }, []);

  const onInputChange = (e, index) => {
    if ((e.key || e.target.name) === "intructorId") {
      let a = newSchedule;
      let b = newSchedule?.[index];
      b.instructorId = e.value.shift();
      b.courseId = e.value.pop();
      b.isGroup = isGroup;
      a.splice(index, 1);
      a.splice(index, 0, b);
      setNewSchedule(a);

      // setNewSchedule({
      //   ...newSchedule,
      //   instructorId: e.target.value.split(",").shift(),
      //   courseId: e.target.value.split(",").pop(),
      // });
    } else if ((e.key || e.target.name) === "group") {
      let a = newSchedule;
      let b = newSchedule?.[index];
      b.groupTitle = e.value.shift();
      b.scheduleGroupId = e.value.pop();
      b.isGroup = isGroup;
      a.splice(index, 1);
      a.splice(index, 0, b);
      setNewSchedule(a);
      // setNewSchedule({
      //   ...newSchedule,
      //   groupTitle: e.target.value.split(",").shift(),
      //   scheduleGroupId: e.target.value.split(",").pop(),
      // });
    } else {
      setNewSchedule({
        ...newSchedule,
        [e.key || e.target.name]: e.value || e.target.value,
      });
    }
  };
  const onEditInputChange = (e, index) => {
    if ((e.key || e.target.name) === "intructorId") {
      let a = editSchedule;
      let b = editSchedule?.[index];
      b.instructorId = e.value.shift();
      b.courseId = e.value.pop();
      b.isGroup = isGroup;
      a.splice(index, 1);
      a.splice(index, 0, b);
      setEditSchedule(a);
      // console.log(e.target.value.split(","));
      // setEditSchedule({
      //   ...editSchedule,
      //   instructorId: e.target.value.split(",").shift(),
      //   courseId: e.target.value.split(",").pop(),
      // });
    } else if ((e.key || e.target.name) === "group") {
      let a = editSchedule;
      let b = editSchedule?.[index];
      b.groupTitle = e.value.shift();
      b.scheduleGroupId = e.value.pop();
      b.isGroup = isGroup;
      a.splice(index, 1);
      a.splice(index, 0, b);
      setEditSchedule(a);
      // setEditSchedule({
      //   ...editSchedule,
      //   groupTitle: e.target.value.split(",").shift(),
      //   scheduleGroupId: e.target.value.split(",").pop(),
      // });
    } else {
      setEditSchedule({
        ...editSchedule,
        [e.key || e.target.name]: e.value || e.target.value,
      });
    }
  };
  let arr = [];
  schedulesStore?.map((e) => (
      classStore.filter((item) =>
      shiftId ? item.shiftId == shiftId : item.shiftId == shiftsStore?.[0]?.id
      ).filter((a) => a.id === e.classId).sort((a, b) => a.classLevel - b.classLevel)
      .map((item) => (
        arr.push({
      Header: `${item.classLevel}${item.classLabel}`,
      accessor: `${e.classId}`,
      width: 112,
      Cell: (value) => {
        if (value.cell.row.original?.[`${value.column.id}`]) {
          return value.cell.row.original?.[`${value.column.id}`]?.map(
            (item) => (
              <span
                id={e.classId}
                key={e.classId}
              >
                {item.courseTitle}{" "}
              </span>
            )
          );
        } else {
          console.log(e);
          return "";
        }
      },
    })))
    ));
    let arr2 = [];
    arr.map(a=>{
      if(arr2?.filter(e=>e.accessor === a.accessor).length == 0){
        arr2.push(a);
      }
    });
  let columnsData = !instructor ? classStore
    .filter((item) =>
      shiftId ? item.shiftId == shiftId : item.shiftId == shiftsStore?.[0]?.id
    )
    .sort((a, b) => a.classLevel - b.classLevel)
    .map((item) => ({
      Header: `${item.classLevel}${item.classLabel}`,
      accessor: `${item.id}`,
      width: 112,
      Cell: (value) => {
        // console.log(value);
        if (value.cell.row.original?.[`${value.column.id}`]) {
          return value.cell.row.original?.[`${value.column.id}`]?.map(
            (item) => (
              <span
                id={item.id}
                key={item.id}
                // onClick={() => alert(item.id)}
                // style={{ background: "red" }}
              >
                {item.courseTitle}{" "}
              </span>
            )
          );
        } else {
          return "";
        }
      },
    }
    )):arr2;
    // console.log(arr, arr2, "columnsData2");
  
  
  console.log(schedulesStore, "schedulesStore");
  let days = constants.days;
  let daysLetter = days
    .slice(0, JSON.parse(localStorage.getItem("school"))?.studyDay)
    .map((day) => {
      let str = day?.[`${String(i18n.language).toLowerCase()}`];
      let strEn = day?.en;
      let a = [];
      if (
        shiftTimesStore
          .filter((shiftTime) => shiftTime.shiftId == shiftId)
          .map(
            (shiftTime) =>
              !!schedulesStore?.find(
                (schedule) => schedule.shiftTimeId == shiftTime.id
              )
          )
          .indexOf(true) !== -1
      ) {
        shiftTimesStore
          .filter((shiftTime) => shiftId && shiftTime.shiftId == shiftId)
          .map((shiftTime, idx) => {
            let c = {};
            if (
              schedulesStore
                ?.filter((h) => strEn == h.weekDay)
                .filter((h) => h.shiftTimeId == shiftTime.id)
                .map((z) => {
                  return {
                    [z.classId]: courseStore.find(
                      (item) => item.id == z.courseId
                    )?.[
                      String(i18n.language).toLowerCase() === "en" ||
                      String(i18n.language).toLowerCase() === "tr"
                        ? `title`
                        : `title${String(i18n.language).toUpperCase()}`
                    ],
                  };
                }).length > 0
            ) {
              let d = [];
              schedulesStore
                ?.filter((h) => h.shiftTimeId == shiftTime.id)
                .filter((h) => strEn == h.weekDay)
                .map((z) => {
                  // return {
                  //   [`scheduleId${z.classId}`]: z.id,
                  //   [z.classId]: courseStore.find(
                  //     (item) => item.id == z.courseId
                  //   )?.[
                  //     String(i18n.language).toLowerCase() === "en" ||
                  //     String(i18n.language).toLowerCase() === "tr"
                  //       ? `title`
                  //       : `title${String(i18n.language).toUpperCase()}`
                  //   ],
                  // };
                  let l = schedulesStore
                    ?.filter((h) => h.shiftTimeId == shiftTime.id)
                    .filter((h) => strEn == h.weekDay)
                    .filter((h) => z.classId === h.classId)
                    .map((z) => ({
                      ...z,
                      id: z.id,
                      classId: z.classId,
                      courseTitle: courseStore.find(
                        (item) => item.id == z.courseId
                      )?.[
                        String(i18n.language).toLowerCase() === "en" ||
                        String(i18n.language).toLowerCase() === "tr"
                          ? `title`
                          : `title${String(i18n.language).toUpperCase()}`
                      ],
                    }));
                  d.push({ [z.classId]: l });
                });
              if (d.length > 0) {
                c = Object.assign(
                  {},
                  {
                    dayKey: day.en,
                    dayId: day.id,
                    days: str[idx],
                    shiftTime: idx + 1,
                    shiftTimeId: shiftTime.id,
                  },
                  ...d
                );
              } else {
                c = {
                  dayKey: day.en,
                  dayId: day.id,
                  days: str[idx],
                  shiftTime: idx + 1,
                  shiftTimeId: shiftTime.id,
                };
              }

              a.push(c);
            } else {
              c = {
                dayKey: day.en,
                dayId: day.id,
                days: str[idx],
                shiftTime: idx + 1,
                shiftTimeId: shiftTime.id,
              };
              a.push(c);
            }
          });
      } else {
        let h = shiftTimesStore
          .filter((shiftTime) =>
            shiftId
              ? shiftTime.shiftId == shiftId
              : shiftTime.shiftId == shiftsStore?.[0]?.id
          )
          .map((shiftTime, idx) => {
            return {
              dayKey: day.en,
              dayId: day.id,
              days: str[idx],
              shiftTime: idx + 1,
              shiftTimeId: shiftTime.id,
            };
          });
        a = h;
      }
      return a;
    });

  const columns = React.useMemo(
    () => [
      {
        Header: "",
        accessor: "days",
        width: 56,
      },
      {
        Header: "",
        accessor: "shiftTime",
        width: 32,
      },
      ...columnsData,
    ],
    [classStore, shiftId, schedulesStore]
  );

  const data = React.useMemo(() => {
    let a = [];
    for (
      let i = 0;
      i < JSON.parse(localStorage.getItem("school"))?.studyDay;
      i++
    ) {
      a.push(...daysLetter?.[i]);
      a.push({
        days: "-",
        shiftTime: " ",
      });
    }
    return a;
  }, [classStore, shiftId, schedulesStore, daysLetter]);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  let opt = [];
  instructorsStore.map((instructor) => {
    return instructor.instructorCourseTitleDtoList.map((course) => {
      opt.push({
        name: `${instructor.instructorTitle} - ${
          courseStore.find((item) => item.id === course.courseId)?.[
            String(i18n.language).toLowerCase() === "en" ||
            String(i18n.language).toLowerCase() === "tr"
              ? `title`
              : `title${String(i18n.language).toUpperCase()}`
          ]
        }`,
        value: [instructor.instructorId, course.courseId],
        key: "intructorId",
      });
    });
  });
  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, idx1) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={idx1}>
              {headerGroup.headers.map((column, idx2) => (
                <th {...column.getHeaderProps()} key={idx2}>
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {rows.map((row, idx3) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={idx3}>
                {row.cells.map((cell, idx4) => {
                  return (
                    <td
                      className={
                        cell.row.original.dayKey
                          ? cell.row.original?.[`${cell.column.id}`]?.[0]
                              ?.isGroup
                            ? "isGroup day"
                            : "day"
                          : cell.row.original.days === "-" &&
                            cell.row.original.shiftTime === " "
                          ? "empty"
                          : ""
                      }
                      title={`${
                        shiftTimesStore.find(
                          (item) => item.id === cell.row.original.shiftTimeId
                        )?.startTime
                      }-${
                        shiftTimesStore.find(
                          (item) => item.id === cell.row.original.shiftTimeId
                        )?.endTime
                      }`}
                      {...row.getRowProps({
                        style: {
                          width: cell.column.width,
                          cursor: "pointer",
                        },
                        onClick: () => {
                          if (cell.row.original?.[`${cell.column.id}`]) {
                            setIsGroup(
                              cell.row.original?.[`${cell.column.id}`]?.[0]
                                .isGroup
                            );

                            console.log(cell.row.original?.[`${cell.column.id}`], "cell.row.original?.[`${cell.column.id}`");
                            setEditSchedule(
                              cell.row.original?.[`${cell.column.id}`]
                            );
                            setModal({ ...modal, editSchedule: true });
                          } else {
                            setNewSchedule([
                              {
                                ...newSchedule,
                                class: cell.column.Header,
                                classId: cell.column.id,
                                shift: shiftsStore.find(
                                  (item) => item.id == shiftId
                                )?.title,
                                shiftId: shiftId,
                                weekDay: cell.row.original.dayKey,
                                weekDayId: cell.row.original.dayId,
                                shiftTimeId: cell.row.original.shiftTimeId,
                                shiftTime: cell.row.original.shiftTime,
                              },
                            ]);
                            setModal({ ...modal, createSchedule: true });
                          }
                          // console.log("aa", cell);
                          // console.log(
                          //   "zz",
                          //   cell.row.original?.[
                          //     `scheduleId${cell.column.id}`
                          //   ]
                          // );
                          // console.log({
                          //   class: cell.column.Header,
                          //   classId: cell.column.id,
                          //   shift: shiftsStore.find(
                          //     (item) => item.id == shiftId
                          //   )?.title,
                          //   shiftId: shiftId,
                          //   weekDay: cell.row.original.dayKey,
                          //   weekDayId: cell.row.original.dayId,
                          //   shiftTimeId: cell.row.original.shiftTimeId,
                          //   shiftTime: cell.row.original.shiftTime,
                          // });
                        },
                      })}
                      key={idx4}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <Modal
        isOpen={modal.createSchedule}
        onRequestClose={() => setModal({ ...modal, createSchedule: false })}
        overlayClassName="modal-overlay"
        onAfterOpen={async () => {
          await dispatch(getScheduleGroupsByClassId(newSchedule?.[0]?.classId));
          await dispatch(getInstructors());
        }}
      >
        <div className="modal">
          <div className="modal-close">
            <button
              onClick={() => setModal({ ...modal, createSchedule: false })}
            >
              <X size={15} color="#9A9A9A" />
            </button>
          </div>
          <div className="modal-header">
            <div className="modal-header-title">
              <h2 className="roboto">
                {newSchedule?.[0]?.class} {t("schoolAdmin:lower case class")}
              </h2>
            </div>
            <div className="modal-header-description">
              <p className="body-2">
                {
                  days.find((item) => item.id == newSchedule?.[0]?.weekDayId)?.[
                    `${i18n.language}`
                  ]
                }
                , {newSchedule?.[0]?.shiftTime}{" "}
                {t("schoolAdmin:lesson lower case")}
              </p>
            </div>
          </div>
          <div className="modal-body">
            <div className="modal-input">
              <div className="gender">
                <div>
                  <input
                    type="radio"
                    name="isGroup"
                    value={false}
                    defaultChecked={isGroup == false}
                    onChange={() => {
                      setIsGroup(false);
                      setIsDisabledButton(true);
                    }}
                  />
                  <label htmlFor="gender">{t("schoolAdmin:all class")}</label>
                </div>
                <div>
                  <input
                    type="radio"
                    name="isGroup"
                    value={true}
                    defaultChecked={isGroup == true}
                    onChange={() => {
                      setIsGroup(true);
                      setIsDisabledButton(true);
                    }}
                  />
                  <label htmlFor="gender">{t("schoolAdmin:a group")}</label>
                </div>
              </div>
            </div>
            {newSchedule.map((item, idx) => (
              <div
                className="modal-inputs-list"
                style={{ marginTop: "24px" }}
                key={idx}
              >
                <div className="modal-input">
                  {/* <select
                    name="intructorId"
                    onChange={(e) => onInputChange(e, idx)}
                    required
                  >
                    <option value="" selected hidden disabled>
                      {t("schoolAdmin:teacher")} -{" "}
                      {t("schoolAdmin:lesson upper case")}
                    </option>
                    {instructorsStore.map((instructor) => {
                      return instructor?.instructorCourseTitleDtoList?.map(
                        (course, idx) => (
                          <option
                            value={[instructor.instructorId, course.courseId]}
                            key={idx}
                          >
                            {instructor.instructorTitle} -{" "}
                            {
                              courseStore.find(
                                (item) => item.id === course.courseId
                              )?.[
                                String(i18n.language).toLowerCase() === "en" ||
                                String(i18n.language).toLowerCase() === "tr"
                                  ? `title`
                                  : `title${String(
                                      i18n.language
                                    ).toUpperCase()}`
                              ]
                            }
                          </option>
                        )
                      );
                    })}
                  </select> */}
                  {instructorsStore.length > 0 && courseStore.length > 0 && (
                    <SelectSearch
                      options={opt}
                      search
                      className="select-search drop-up small"
                      filterOptions={fuzzySearch}
                      name="intructorId"
                      value={`${[
                        newSchedule.instructorId,
                        newSchedule.courseId,
                      ]}`}
                      onChange={(_, k) => {
                        // onFilterHandler(k.value);
                        onInputChange(k, idx);
                        newSchedule?.[0]?.isGroup
                          ? setIsDisabledButton(true)
                          : setIsDisabledButton(false);
                      }}
                      required
                      placeholder={`${t("schoolAdmin:teacher")} - ${t(
                        "schoolAdmin:lesson upper case"
                      )}`}
                    />
                  )}
                </div>

                {isGroup && (
                  <>
                    <div className="modal-input">
                      <SelectSearch
                        options={scheduleGroupsStore.map((item) => ({
                          name: item.groupTitle,
                          value: [item.groupTitle, item.groupId],
                          key: "group",
                        }))}
                        search
                        className="select-search"
                        filterOptions={fuzzySearch}
                        name="group"
                        value={`${[
                          newSchedule.groupTitle,
                          newSchedule.scheduleGroupId,
                        ]}`}
                        onChange={(_, k) => {
                          // onFilterHandler(k.value);
                          onInputChange(k, idx);
                          newSchedule?.[0]?.isGroup
                            ? setIsDisabledButton(false)
                            : setIsDisabledButton(true);
                        }}
                        required
                        placeholder={`${t("schoolAdmin:a group")}`}
                      />
                    </div>
                    <div className="divider"></div>
                    {idx + 1 > newSchedule.length - 1 ? (
                      <div className="add-other-group">
                        <button
                          onClick={() =>
                            setNewSchedule([
                              ...newSchedule,
                              {
                                ...newSchedule?.[0],
                                scheduleGroupId: null,
                                groupTitle: "",
                              },
                            ])
                          }
                        >
                          <PlusCircle size={24} color="#F0783A" />
                          <span>{t("schoolAdmin:add group")}</span>
                        </button>
                      </div>
                    ) : (
                      <div className="remove-other-group">
                        <button
                          onClick={() => {
                            let schedule = newSchedule;
                            schedule.splice(idx, 1);
                            setNewSchedule([...schedule]);
                          }}
                        >
                          <Trash size={24} color="#FF7171" />
                          <span>{t("schoolAdmin:delete group")}</span>
                        </button>
                      </div>
                    )}
                  </>
                )}
              </div>
            ))}
          </div>
          <div className="modal-footer">
            <button
              className="large secondary"
              onClick={() => {
                setModal({ ...modal, createSchedule: false });
                setIsGroup(false);
                setNewSchedule([]);
              }}
            >
              {t("presetting:consel presetting")}
            </button>
            <button
              className="large primary"
              disabled={isDisabledButton}
              onClick={async () => {
                await newSchedule.map((item) => dispatch(addSchedule(item)));
                await setModal({ ...modal, createSchedule: false });
                await setIsGroup(false);
                await setNewSchedule([]);
              }}
            >
              {t("common:ready")}
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modal.editSchedule}
        onRequestClose={() => setModal({ ...modal, editSchedule: false })}
        overlayClassName="modal-overlay"
        onAfterOpen={async () => {
          // editSchedule.map((item) => {
          //   let a = {};
          //   dispatch(getScheduleById(item.id, a));
          //   setEditSchedule([...editSchedule, a]);
          // });
          await setNewSchedule([]);
          await dispatch(
            getScheduleGroupsByClassId(editSchedule?.[0]?.classId)
          );
          await dispatch(getInstructors());
        }}
      >
        <div className="modal">
          <div className="modal-close">
            <button onClick={() => setModal({ ...modal, editSchedule: false })}>
              <X size={15} color="#9A9A9A" />
            </button>
          </div>
          <div className="modal-header">
            <div className="modal-header-title">
              <h2 className="roboto">
                {editSchedule?.[0]?.classTitle}{" "}
                {t("schoolAdmin:lower case class")}
              </h2>
            </div>
            <div className="modal-header-description">
              <p className="body-2">
                {
                  days.find((item) => item.en == editSchedule?.[0]?.weekDay)?.[
                    `${i18n.language}`
                  ]
                }
                {/* , {editSchedule?.[0]?.shiftTime} урок */}
              </p>
            </div>
          </div>
          <div className="modal-body">
            {/* <div className="modal-input">
              <div className="gender">
                <div>
                  <input
                    type="radio"
                    name="isGroup"
                    value={false}
                    defaultChecked={isGroup == false}
                    onChange={() => setIsGroup(false)}
                  />
                  <label htmlFor="gender">Весь класс</label>
                </div>
                <div>
                  <input
                    type="radio"
                    name="isGroup"
                    value={true}
                    defaultChecked={isGroup == true}
                    onChange={() => setIsGroup(true)}
                  />
                  <label htmlFor="gender">Группа</label>
                </div>
              </div>
            </div> */}
            {editSchedule.length > 0 &&
              editSchedule?.map((schedule, idx) => (
                <div className="modal-inputs-list" key={idx}>
                  <div className="modal-input">
                    {/* <select
                      name="intructorId"
                      onChange={(e) => onEditInputChange(e, idx)}
                      required
                    >
                      <option value="" hidden disabled>
                        {t("schoolAdin:teacher")} -{" "}
                        {t("schoolAdmin:lesson upper case")}
                      </option>
                      {instructorsStore.map((instructor) => {
                        return instructor?.instructorCourseTitleDtoList?.map(
                          (course, idx) => (
                            <option
                              value={[instructor.instructorId, course.courseId]}
                              key={idx}
                              selected={
                                instructor.instructorId ===
                                  schedule.instructorId &&
                                course.courseId === schedule.courseId
                              }
                            >
                              {instructor.instructorTitle} -{" "}
                              {
                                courseStore.find(
                                  (item) => item.id === course.courseId
                                )?.[
                                  String(i18n.language).toLowerCase() ===
                                    "en" ||
                                  String(i18n.language).toLowerCase() === "tr"
                                    ? `title`
                                    : `title${String(
                                        i18n.language
                                      ).toUpperCase()}`
                                ]
                              }
                            </option>
                          )
                        );
                      })}
                    </select> */}
                    {instructorsStore.length > 0 && courseStore.length > 0 && (
                      <SelectSearch
                        options={opt}
                        search
                        className="select-search drop-up small"
                        filterOptions={fuzzySearch}
                        name="intructorId"
                        value={`${[schedule.instructorId, schedule.courseId]}`}
                        onChange={(_, k) => {
                          // onFilterHandler(k.value);
                          onEditInputChange(k, idx);
                        }}
                        required
                        placeholder={`${t("schoolAdmin:teacher")} - ${t(
                          "schoolAdmin:lesson upper case"
                        )}`}
                      />
                    )}
                    {!schedule.isGroup && (
                      <div className="remove-schedule">
                        <button
                          onClick={() => {
                            dispatch(deleteSchedule(schedule.id));
                            setModal({ ...modal, editSchedule: false });
                            setEditSchedule({});
                          }}
                        >
                          <Trash size={24} color="#FF7171" />
                          <span>{t("presetting:delete presetting")}</span>
                        </button>
                      </div>
                    )}
                  </div>
                  {schedule.isGroup && (
                    <>
                      <div className="modal-input">
                        {instructorsStore.length > 0 && courseStore.length > 0 && (
                          <SelectSearch
                            options={scheduleGroupsStore.map((item) => ({
                              name: item.groupTitle,
                              value: [item.groupTitle, item.groupId],
                              id: item.groupId,
                              key: "group",
                            }))}
                            value={`${[
                              schedule.groupTitle,
                              schedule.scheduleGroupId,
                            ]}`}
                            search
                            className="select-search"
                            filterOptions={fuzzySearch}
                            name="group"
                            onChange={(_, k) => {
                              onEditInputChange(k, idx);
                            }}
                            required
                            placeholder={`${t("schoolAdmin:a group")}`}
                          />
                        )}
                      </div>
                      <div className="remove-other-group-edit">
                        <button
                          // onClick={() => {
                          //   let schedule = newSchedule;
                          //   schedule.splice(idx, 1);
                          //   setNewSchedule([...schedule]);
                          // }}
                          onClick={() => {
                            dispatch(deleteSchedule(schedule.id));
                            setModal({ ...modal, editSchedule: false });
                            setEditSchedule({});
                          }}
                        >
                          <Trash size={24} color="#FF7171" />
                          <span>{t("schoolAdmin:delete group")}</span>
                        </button>
                      </div>

                      {/* )} */}
                      <div className="divider"></div>
                      {idx == editSchedule.length - 1 &&
                      newSchedule.length == 0 ? (
                        <div className="add-other-group">
                          <button
                            onClick={() =>
                              setNewSchedule([
                                ...newSchedule,
                                {
                                  ...editSchedule?.[0],
                                  // scheduleGroupId: null,
                                  // groupTitle: "",
                                },
                              ])
                            }
                          >
                            <PlusCircle size={24} color="#F0783A" />
                            <span>{t("schoolAdmin:add group")}</span>
                          </button>
                        </div>
                      ) : null}
                    </>
                  )}
                </div>
              ))}
            {newSchedule.length > 0 &&
              newSchedule.map((schedule, idx) => (
                <div
                  className="modal-inputs-list"
                  style={{ marginTop: "24px" }}
                  key={idx}
                >
                  <div className="modal-input">
                    {/* <select
                      name="intructorId"
                      onChange={(e) => onInputChange(e, idx)}
                      required
                    >
                      <option value="" selected hidden disabled>
                        {t("schoolAdmin:teacher")} -{" "}
                        {t("schoolAdmin:lesson upper case")}
                      </option>
                      {instructorsStore.map((instructor) => {
                        return instructor?.instructorCourseTitleDtoList?.map(
                          (course, idx) => (
                            <option
                              value={[instructor.instructorId, course.courseId]}
                              key={idx}
                            >
                              {instructor.instructorTitle} -{" "}
                              {
                                courseStore.find(
                                  (item) => item.id === course.courseId
                                )?.[
                                  String(i18n.language).toLowerCase() ===
                                    "en" ||
                                  String(i18n.language).toLowerCase() === "tr"
                                    ? `title`
                                    : `title${String(
                                        i18n.language
                                      ).toUpperCase()}`
                                ]
                              }
                            </option>
                          )
                        );
                      })}
                    </select> */}
                    {instructorsStore.length > 0 && courseStore.length > 0 && (
                      <SelectSearch
                        options={opt}
                        search
                        className="select-search drop-up small"
                        filterOptions={fuzzySearch}
                        name="intructorId"
                        value={`${[
                          newSchedule.instructorId,
                          newSchedule.courseId,
                        ]}`}
                        onChange={(_, k) => {
                          // onFilterHandler(k.value);
                          onEditInputChange(k, idx);
                        }}
                        required
                        placeholder={`${t("schoolAdmin:teacher")} - ${t(
                          "schoolAdmin:lesson upper case"
                        )}`}
                      />
                    )}
                    {!schedule.isGroup && (
                      <div className="remove-schedule">
                        <button
                          onClick={() => {
                            dispatch(deleteSchedule(schedule.id));
                            setModal({ ...modal, editSchedule: false });
                            setEditSchedule({});
                          }}
                        >
                          <Trash size={24} color="#FF7171" />
                          <span>{t("presetting:delete presetting")}</span>
                        </button>
                      </div>
                    )}
                  </div>

                  <div className="modal-input">
                    {/* <select
                      name="group"
                      onChange={(e) => onInputChange(e, idx)}
                      required
                    >
                      <optiвon value="" selected hidden disabled>
                        {t("schoolAdmin:a group")}
                      </optiвon>
                      {scheduleGroupsStore.map((item, idx) => (
                        <option
                          value={[item.groupTitle, item.groupId]}
                          key={idx}
                        >
                          {item.groupTitle}
                        </option>
                      ))}
                    </select> */}
                    {instructorsStore.length > 0 && courseStore.length > 0 && (
                      <SelectSearch
                        options={scheduleGroupsStore.map((item) => ({
                          name: item.groupTitle,
                          value: [item.groupTitle, item.groupId],
                          id: item.groupId,
                          key: "group",
                        }))}
                        // value={`${[
                        //   schedule.groupTitle,
                        //   schedule.scheduleGroupId,
                        // ]}`}
                        search
                        className="select-search"
                        filterOptions={fuzzySearch}
                        name="group"
                        onChange={(_, k) => {
                          onEditInputChange(k, idx);
                        }}
                        required
                        placeholder={`${t("schoolAdmin:a group")}`}
                      />
                    )}
                  </div>
                  <div className="remove-other-group">
                    <button
                      onClick={() => {
                        let schedule = newSchedule;
                        schedule.splice(idx, 1);
                        setNewSchedule([...schedule]);
                      }}
                    >
                      <Trash size={24} color="#FF7171" />
                      <span>{t("schoolAdmin:delete group")}</span>
                    </button>
                  </div>
                  <div className="divider"></div>
                  {idx == newSchedule.length - 1 ? (
                    <div className="add-other-group">
                      <button
                        onClick={() =>
                          setNewSchedule([
                            ...newSchedule,
                            {
                              ...newSchedule?.[0],
                              scheduleGroupId: null,
                              groupTitle: "",
                            },
                          ])
                        }
                      >
                        <PlusCircle size={24} color="#F0783A" />
                        <span>{t("schoolAdmin:add group")}</span>
                      </button>
                    </div>
                  ) : null}
                </div>
              ))}
          </div>

          <div className="modal-footer">
            <button
              className="large secondary"
              onClick={() => {
                setModal({ ...modal, editSchedule: false });
                setEditSchedule([{}]);
                setNewSchedule([]);
              }}
            >
              {t("presetting:consel presetting")}
            </button>
            <button
              className="large primary"
              onClick={async () => {
                await editSchedule.map((item) =>
                  dispatch(updateSchedule(item))
                );
                newSchedule.length > 0 &&
                  newSchedule.map((item) => dispatch(addSchedule(item)));
                await setModal({ ...modal, editSchedule: false });
                await setEditSchedule([{}]);
                await setNewSchedule([]);
              }}
            >
              {t("common:ready")}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
