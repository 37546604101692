import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  ChevronRight,
  Users,
  BarChart,
  BookOpen,
  // FileText,
  // CheckCircle,
  Award,
} from "react-feather";

import { getClassByInstructorId } from "../../store/class/actions";
import { InstractorLayout, MyClassSchedule } from "../../components";
import { statuses } from "../../config";

export default function MyClass() {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const classStore = useSelector((store) =>
    store.class.class.filter(
      (item) => item.schoolId === JSON.parse(localStorage.getItem("school"))?.id
    )
  );
  const classStoreStatus = useSelector((store) => store.class.status);

  const [currenClassId, setCurrenClassId] = useState(classStore?.[0]?.id);

  useEffect(async () => {
    await dispatch(
      getClassByInstructorId(JSON.parse(localStorage.getItem("person"))?.id)
    );
  }, []);

  return (
    <InstractorLayout>
      <div className="my-class">
        <div className="school-admin__schedule-group school-admin__schedule school-presetting__body school-presetting__body-color">
          <div className="school-presetting__body-row my-class__header">
            <div className="my-class__header-left">
              <div className="school-presetting__body-title">
                <h2>{t("instructor:my class title")}</h2>
              </div>
              <div className="school-presetting__body-description">
                <p className="body-1">{t("instructor:my class description")}</p>
              </div>
            </div>
            <div className="my-class__header-right roboto">
              {classStore.map((item, idx) => (
                <button
                  key={idx}
                  onClick={() => setCurrenClassId(item.id)}
                  className={item.id == currenClassId ? "active" : ""}
                >
                  {item.classLevel}
                  {item.classLabel}
                </button>
              ))}
            </div>
          </div>
        </div>
        <div className="my-class__body">
          <div className="school-admin__schedule-group school-admin__schedule school-presetting__body school-presetting__body-color my-class__nav">
            <div className="my-class__nav-body">
              <div className="my-class__nav-list">
                <Link to={`/instructor/students/${currenClassId}`}>
                  <div className="my-class__nav-item">
                    <div className="my-class__nav-icon">
                      <Users />
                    </div>
                    <div className="my-class__nav-title">
                      <h3>{t("instructor:students list")}</h3>
                    </div>
                    <div className="my-class__nav-arrow">
                      <ChevronRight />
                    </div>
                  </div>
                </Link>
                {/* /instructor/class-statistics */}
                <Link to="/instructor/class-statistics">
                  <div className="my-class__nav-item">
                    <div className="my-class__nav-icon">
                      <BarChart />
                    </div>
                    <div className="my-class__nav-title">
                      <h3>{t("instructor:class statistics")}</h3>
                    </div>
                    <div className="my-class__nav-arrow">
                      <ChevronRight />
                    </div>
                  </div>
                </Link>
                <Link to="/instructor/class-journal">
                  <div className="my-class__nav-item">
                    <div className="my-class__nav-icon">
                      <BookOpen />
                    </div>
                    <div className="my-class__nav-title">
                      <h3>{t("instructor:class journal")}</h3>
                    </div>
                    <div className="my-class__nav-arrow">
                      <ChevronRight />
                    </div>
                  </div>
                </Link>
                {/* <Link to="/instructor/quarter-grade">
                  <div className="my-class__nav-item">
                    <div className="my-class__nav-icon">
                      <FileText />
                    </div>
                    <div className="my-class__nav-title">
                      <h3>{t("instructor:final grades")}</h3>
                    </div>
                    <div className="my-class__nav-arrow">
                      <ChevronRight />
                    </div>
                  </div>
                </Link> */}
                <Link to="/instructor/grade-by-class">
                  <div className="my-class__nav-item">
                    <div className="my-class__nav-icon">
                      <Award />
                    </div>
                    <div className="my-class__nav-title">
                      <h3>{t("instructor:student performance by subject")}</h3>
                    </div>
                    <div className="my-class__nav-arrow">
                      <ChevronRight />
                    </div>
                  </div>
                </Link>
                {/* <Link to="/instructor/my-class">
                  <div className="my-class__nav-item">
                    <div className="my-class__nav-icon">
                      <CheckCircle />
                    </div>
                    <div className="my-class__nav-title">
                      <h3>{t("instructor:weekly check")}</h3>
                    </div>
                    <div className="my-class__nav-arrow">
                      <ChevronRight />
                    </div>
                  </div>
                </Link> */}
              </div>
            </div>
          </div>
          {classStore.length > 0 && classStoreStatus === statuses.SUCCESS && (
            <MyClassSchedule classId={currenClassId || classStore?.[0]?.id} />
          )}
        </div>
      </div>
      {/* </div> */}
    </InstractorLayout>
  );
}
