import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { GoBack, SchoolAdminLayout } from "..";
import SelectSearch, { fuzzySearch } from "react-select-search";
import { useTable } from "react-table";
import PT from "prop-types";

import "./FinalClassGradesReport.scss";
import { useDispatch, useSelector } from "react-redux";
import { getClassBySchoolId } from "../../store/class/actions";
import { getQuarterMark, getQuarterTitles } from "../../store/report/actions";
import { getChronicleYears } from "../../store/chronicleYear/actions";
import NoData from "../NoData/NoData";
export function Table({ id, columns, data, start, setStart }) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  useEffect(() => {
    if (start) {
      document.querySelector("#tbody_td_0_0").setAttribute("rowspan", 4);
      document.querySelector("#tbody_td_4_0").setAttribute("rowspan", 4);
      document.querySelector("#tbody_td_8_0").setAttribute("rowspan", 4);
      document.querySelector("#tbody_td_12_0").setAttribute("rowspan", 4);
      document.querySelector("#tbody_td_12_0").setAttribute("border", 2);

      document
        .querySelector("#tbody_tr_1")
        .deleteCell(document.querySelector("#tbody_tr_1").cellIndex);
      document
        .querySelector("#tbody_tr_2")
        .deleteCell(document.querySelector("#tbody_tr_2").cellIndex);
      document
        .querySelector("#tbody_tr_3")
        .deleteCell(document.querySelector("#tbody_tr_3").cellIndex);
      document
        .querySelector("#tbody_tr_5")
        .deleteCell(document.querySelector("#tbody_tr_5").cellIndex);
      document
        .querySelector("#tbody_tr_6")
        .deleteCell(document.querySelector("#tbody_tr_6").cellIndex);
      document
        .querySelector("#tbody_tr_7")
        .deleteCell(document.querySelector("#tbody_tr_7").cellIndex);
      document
        .querySelector("#tbody_tr_9")
        .deleteCell(document.querySelector("#tbody_tr_9").cellIndex);
      document
        .querySelector("#tbody_tr_10")
        .deleteCell(document.querySelector("#tbody_tr_10").cellIndex);
      document
        .querySelector("#tbody_tr_11")
        .deleteCell(document.querySelector("#tbody_tr_11").cellIndex);
      document
        .querySelector("#tbody_tr_13")
        .deleteCell(document.querySelector("#tbody_tr_13").cellIndex);
      document
        .querySelector("#tbody_tr_14")
        .deleteCell(document.querySelector("#tbody_tr_14").cellIndex);
      document
        .querySelector("#tbody_tr_15")
        .deleteCell(document.querySelector("#tbody_tr_15").cellIndex);

      let length = document.querySelector("#thead_tr");

      let first = document.querySelector("#tbody_tr_16");
      let second = document.querySelector("#tbody_tr_17");
      let third = document.querySelector("#tbody_tr_18");

      document.querySelector("#tbody_td_16_0").align = "left";
      document.querySelector("#tbody_td_17_0").align = "left";
      document.querySelector("#tbody_td_18_0").align = "left";

      console.log("first", first.cells.length);

      for (let i = 2; i < first.cells.length; i++) {
        first.deleteCell(i);
        second.deleteCell(i);
        third.deleteCell(i);
      }

      for (let i = 2; i < first.cells.length; i + 2) {
        first.deleteCell(i);
        second.deleteCell(i);
        third.deleteCell(i);
      }

      document
        .querySelector("#tbody_td_16_1")
        .setAttribute("colspan", length.cells.length - 1);
      document
        .querySelector("#tbody_td_17_1")
        .setAttribute("colspan", length.cells.length - 1);
      document
        .querySelector("#tbody_td_18_1")
        .setAttribute("colspan", length.cells.length - 1);

      document.querySelector("#tbody_td_0_0").vAlign = "center";
      document.querySelector("#tbody_td_4_0").vAlign = "center";
      document.querySelector("#tbody_td_8_0").vAlign = "center";
      document.querySelector("#tbody_td_12_0").vAlign = "center";

      document.querySelector("#tbody_td_0_0").hAlign = "center";
      document.querySelector("#tbody_td_4_0").hAlign = "center";
      document.querySelector("#tbody_td_8_0").hAlign = "center";
      document.querySelector("#tbody_td_12_0").hAlign = "center";

      document.querySelector("#tbody_td_0_0").align = "center";
      document.querySelector("#tbody_td_4_0").align = "center";
      document.querySelector("#tbody_td_8_0").align = "center";
      document.querySelector("#tbody_td_12_0").align = "center";

      document.querySelector("#tbody_td_16_0").setAttribute("border", 2);
      document.querySelector("#tbody_td_17_0").setAttribute("border", 2);
      document.querySelector("#tbody_td_18_0").setAttribute("border", 2);
      document.querySelector("#finalClassGradesReport_table_export").border = 1;
      setStart(false);
    }
    console.log(document.querySelector("#thead_th_1"));
  }, [start]);

  return (
    <table
      id={id}
      style={{ border: "none" }}
      className="grade_summary_information_table"
      {...getTableProps()}
    >
      <thead id="thead">
        {headerGroups.map((headerGroup, idx) => (
          <tr id="thead_tr" key={idx} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, idx) => (
              <th
                textOrientation={90}
                rotate={90}
                id={`thead_th_${idx}`}
                align="left"
                key={idx}
                text-orientation={90}
                textorientation={90}
                {...column.getHeaderProps()}
              >
                <span>{column.render("Header")}</span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody id="tbody" {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr id={`tbody_tr_${i}`} key={i} {...row.getRowProps()}>
              {row.cells.map((cell, idx) => {
                return (
                  <td
                    id={`tbody_td_${i}_${idx}`}
                    hAlign="center"
                    vAlign="center"
                    align="center"
                    key={idx}
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

function FinalClassGradesReport() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const [classTitle, setClassTitle] = useState(null);
  const [yearTitle, setYearTitle] = useState(null);
  const [classData, setClassData] = useState(null);
  const [start, setStart] = useState(false);
  const [year, setYear] = useState(null);

  const classesStore = useSelector((state) => state.class.class);
  const chronicleYears = useSelector(
    (state) => state.chronicleYears.chronicleYears
  );
  const data = useSelector((state) => state.report.studentsRecord);
  const quarter = useSelector((state) => state.report.quarter);
  console.log({ quarter });
  const marksType = [5, 4, 3, 2];
  let excelData = [];

  useEffect(() => {
    dispatch(getClassBySchoolId());
    dispatch(getChronicleYears());
  }, []);

  const ids = data?.map((o) => o.courseId);
  const courses = data?.filter(
    ({ courseId }, index) => !ids.includes(courseId, index + 1)
  );
  console.log("courses", courses);

  function yearFunction() {
    let numbersCopy = [...chronicleYears];
    numbersCopy = numbersCopy?.map((item) => ({
      name: `${item.selectorTitle} ${t("schoolAdmin:year")}`,
      value: item.id,
      key: item.id,
    }));
    return numbersCopy;
  }

  const classes = classesStore?.map((item) => ({
    name: `${item?.classLevel} ${item?.classLabel}`,
    value: item.id,
    key: item.id,
  }));

  const yearState = yearFunction();

  const quarterMarkFunction = (k) => {
    dispatch(getQuarterMark(classData.key, undefined, k.key));
    dispatch(
      getQuarterTitles(JSON.parse(localStorage.getItem("school")).id, k.key)
    );
    setStart(true);
  };

  const column = [
    {
      Header: "",
      accessor: "quarter",
      width: 350,
    },
    {
      Header: `${t("schoolAdmin:evaluations")}`,
      accessor: "mark_type",
      width: 350,
    },
  ];

  courses?.map((item) => {
    column.push({
      Header:
        String(i18n.language).toUpperCase() !== "EN" &&
        String(i18n.language).toUpperCase() !== "TR"
          ? `${item[`courseTitle${String(i18n.language).toUpperCase()}`]}`
          : `${item.courseTitle}`,
      accessor: `${item.courseTitleRU}_`,
      width: 350,
    });
  });

  column.push({
    Header: `${t(
      "schoolAdmin:decision of the pedagogical council (date and number)"
    )}`,
    accessor: "_{}_",
    width: 350,
  });

  const newCoursesTitle = [
    ...data
      .reduce((r, e) => {
        let k = `${e.courseTitleRU}|${e.quarterMark}|${e.quarterId}`;
        if (!r.has(k)) r.set(k, { ...e, count: 1 });
        else r.get(k).count++;
        return r;
      }, new Map())
      .values(),
  ];
  quarter
    ?.sort(function (a, b) {
      return a.quarter - b.quarter;
    })
    ?.map((quarter) => {
      marksType?.map((marks) => {
        let z = {};
        z[`quarter`] = `${quarter.quarter} ${t("instructor:quarter")}`;
        z[`mark_type`] = marks;
        newCoursesTitle
          ?.filter(
            (filterByMark) =>
              filterByMark.quarterMark == marks ||
              filterByMark.quarterMark == null
          )
          ?.filter((item) => item.quarterId == quarter.id)
          ?.map((item) => {
            z[`${item.courseTitleRU}_`] = item.count;
            z[`courseId_`] = item.courseId;
            z[`courseTitleKG`] = item.count;
            z[`courseTitleRU`] = item.count;
            z[`gradeType`] = item.gradeType;
            z[`instructorId`] = item.instructorId;
            z[`quarterId`] = item.quarterId;
            z[`quarterMark`] = item.quarterMark;
            z[`studentGender`] = item.studentGender;
            z[`studentId`] = item.studentId;
            z[`studentTitle`] = item.studentTitle;
          });
        excelData.push(z);
      });
    });

  excelData.push({ quarter: t("schoolAdmin:head teacher") });
  excelData.push({
    quarter: t("schoolAdmin:deputy director for educational work"),
  });
  excelData.push({ quarter: t("schoolAdmin:classroom teacher") });

  const columns = React.useMemo(() => column, [excelData]);
  const mockData = useMemo(() => excelData, [excelData]);

  // !
  function tableToExcel(table) {
    var uri = "data:application/vnd.ms-excel;base64,",
      template =
        '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
      base64 = function (s) {
        return window.btoa(unescape(encodeURIComponent(s)));
      },
      format = function (s, c) {
        return s.replace(/{(\w+)}/g, function (m, p) {
          return c[p];
        });
      };
    if (!table.nodeType) table = document.getElementById(table);
    var ctx = { worksheet: year.name || "Worksheet", table: table.innerHTML };
    var a = document.createElement("a");
    a.href = uri + base64(format(template, ctx));
    a.download =
      `${t("schoolAdmin:information about final class grades")}` +
      " " +
      classData.name +
      " " +
      year.name +
      ".xls";
    a.click();
  }
  // !

  return (
    <SchoolAdminLayout>
      <GoBack title={t("common:back")} />
      <div className="final_class_grades_report_box">
        <span
          className="final_class_grades_report_topic"
          style={{ cursor: "pointer" }}
        >
          {t("schoolAdmin:information about final class grades")}
        </span>
        <p className="final_class_grades_report_title">
          {t("schoolAdmin:information about final class grades text")}
        </p>

        <div className="final_class_grades_report_buttons">
          <div className="final_class_grades_report_select_search">
            <SelectSearch
              options={classes}
              filterOptions={fuzzySearch}
              value={classTitle}
              name="quarterId"
              onChange={(_, k) => {
                setClassData(k);
                setClassTitle(k.value);
              }}
              required
              placeholder={`- ${t("schoolAdmin:class")} -`}
            />
            <SelectSearch
              options={yearState}
              filterOptions={fuzzySearch}
              value={yearTitle}
              name="classId"
              onChange={async (_, k) => {
                setYear(k);
                setYearTitle(k.value);
                classTitle && quarterMarkFunction(k);
              }}
              required
              placeholder={`- ${t("schoolAdmin:year")} -`}
            />
          </div>

          <button
            disabled={data[0] == undefined}
            onClick={() => {
              tableToExcel("finalClassGradesReport_table_export");
            }}
            className="export_button_excel"
          >
            {t("common:export")}
          </button>
        </div>
        {data[0] == undefined ? (
          <NoData
            messages={[`${t("schoolAdmin:select class and year of study")}`]}
          />
        ) : (
          <div className="final_class_grades_report_table_container">
            <Table
              id="finalClassGradesReport_table_export"
              columns={columns}
              data={mockData}
              start={start}
              setStart={setStart}
            />
          </div>
        )}
      </div>
    </SchoolAdminLayout>
  );
}

Table.propTypes = {
  id: PT.string,
  data: PT.array,
  columns: PT.array,
  className: PT.string,
  start: PT.bool,
  setStart: PT.func,
};

export default FinalClassGradesReport;
