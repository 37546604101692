import React, { useEffect } from "react";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import { schoolPresettingStatus } from "../../store/schoolPresetting/actions";
import { getPersonByUserId } from "../../store/person/actions";

export default function Home() {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const lRoleCode = JSON.parse(localStorage.getItem("roleCode"));
  let lUser = JSON.parse(localStorage.getItem("user"));
  const rolesCodes = lUser?.roles?.map((item) => item.id);
  const lPresetting = JSON.parse(
    localStorage.getItem("schoolPresetting")
  )?.preset;

  useEffect(async () => {
    let lUser = JSON.parse(localStorage.getItem("user"));
    // await dispatch(schoolPresettingStatus(history));
    if (rolesCodes.includes(4)) {
      dispatch(getPersonByUserId(lUser?.user.id));
    }
    if (localStorage.getItem("roleCode")) {
      await redirectAfterAuth();
    } else if (lUser?.roles?.length === 1) {
      redirectBeforeAuth();
    }
  }, []);

  const redirectAfterAuth = async () => {
    if (lRoleCode == 2) {
      if (
        (!lPresetting && window.location.pathname == "/") ||
        window.location.pathname == ""
      ) {
        // window.location.href = "/school-presetting";
        history.push("/school-presetting");
      } else if (
        (lPresetting && window.location.pathname == "/") ||
        window.location.pathname == ""
      ) {
        // window.location.href = "/school-admin";
        history.push("/school-admin");
      }
    } else if (rolesCodes[0] == 6) {
      localStorage.setItem("roleCode", 6);
      if (
        (!lPresetting && window.location.pathname == "/") ||
        window.location.pathname == ""
      ) {
        // window.location.href = "/school-presetting";
        history.push("/school-presetting");
      } else if (
        (lPresetting && window.location.pathname == "/") ||
        window.location.pathname == ""
      ) {
        // window.location.href = "/school-admin";
        history.push("/school-admin");
      }
    } else if (rolesCodes[0] == 4) {
      // window.location.href = "/instructor";
      await history.push("/instructor");
    } else if (rolesCodes[0] == 5) {
      // window.location.href = "/instructor";
      history.push("/parent");
    } else if (lRoleCode == 1) {
      window.location.href = "/super-admin";
      // history.push("/school-admin");
    }
  };
  const redirectBeforeAuth = async () => {
    if (rolesCodes[0] == 2) {
      localStorage.setItem("roleCode", 2);
      if (
        (!lPresetting && window.location.pathname == "/") ||
        window.location.pathname == ""
      ) {
        // window.location.href = "/school-presetting";
        history.push("/school-presetting");
      } else if (
        (lPresetting && window.location.pathname == "/") ||
        window.location.pathname == ""
      ) {
        // window.location.href = "/school-admin";
        history.push("/school-admin");
      }
    } else if (rolesCodes[0] == 6) {
      localStorage.setItem("roleCode", 6);
      if (
        (!lPresetting && window.location.pathname == "/") ||
        window.location.pathname == ""
      ) {
        // window.location.href = "/school-presetting";
        history.push("/school-presetting");
      } else if (
        (lPresetting && window.location.pathname == "/") ||
        window.location.pathname == ""
      ) {
        // window.location.href = "/school-admin";
        history.push("/school-admin");
      }
    } else if (rolesCodes[0] == 4) {
      // window.location.href = "/instructor";
      await dispatch(getPersonByUserId(lUser?.user.id));
      localStorage.setItem("roleCode", 4);
      history.push("/instructor");
    } else if (rolesCodes[0] == 5) {
      // window.location.href = "/parent";
      localStorage.setItem("roleCode", 5);
      history.push("/parent");
    } else if (rolesCodes[0] == 1) {
      localStorage.setItem("roleCode", 1);
      window.location.href = "/super-admin";
      // history.push("/super-admin");
    }
  };
  return (
    <div>
      {!localStorage.getItem("roleCode") && rolesCodes.length > 1 && (
        <Modal
          isOpen={true}
          overlayClassName="modal-overlay"
          // onAfterOpen={async () => {}}
          className="middle"
        >
          <div className="modal">
            <div className="modal-header">
              <div className="modal-header-title">
                <h2 className="roboto">{t("login:select role")}</h2>
              </div>
            </div>
            <div className="modal-body">
              <div className="modal__add-schedule-list">
                {rolesCodes?.includes(2) && (
                  <button
                    onClick={async () => {
                      await localStorage.setItem("roleCode", 2);
                      await dispatch(schoolPresettingStatus(history));
                      // await history.push("/school-admin");
                    }}
                  >
                    <div className="modal__add-schedule-item">
                      <div className="modal__add-schedule-title">
                        <p>{t("login:role school admin")}</p>
                      </div>
                      <div className="modal__add-schedule-description">
                        <p>
                          {t(
                            "schoolAdmin:set the desired settings and the schedule is automatically generated"
                          )}
                        </p>
                      </div>
                    </div>
                  </button>
                )}
                {rolesCodes?.includes(6) && (
                  <>
                    <div className="modal__add-schedule-divider"></div>
                    <button
                      onClick={async () => {
                        await localStorage.setItem("roleCode", 6);
                        await history.push("/school-admin");
                      }}
                    >
                      <div className="modal__add-schedule-item">
                        <div className="modal__add-schedule-title">
                          <p>{t("login:role director")}</p>
                        </div>
                        <div className="modal__add-schedule-description">
                          <p>
                            {t(
                              "schoolAdmin:set the desired settings and the schedule is automatically generated"
                            )}
                          </p>
                        </div>
                      </div>
                    </button>
                  </>
                )}
                {rolesCodes?.includes(4) && (
                  <>
                    <div className="modal__add-schedule-divider"></div>
                    <button
                      onClick={() => {
                        localStorage.setItem("roleCode", 4);
                        history.push("/instructor");
                      }}
                    >
                      <div className="modal__add-schedule-item">
                        <div className="modal__add-schedule-title">
                          <p>{t("login:role instructor")}</p>
                        </div>
                        <div className="modal__add-schedule-description">
                          <p>
                            {t(
                              "schoolAdmin:set the desired settings and the schedule is automatically generated"
                            )}
                          </p>
                        </div>
                      </div>
                    </button>
                  </>
                )}
                {rolesCodes?.includes(5) && (
                  <>
                    <div className="modal__add-schedule-divider"></div>
                    <button
                      onClick={() => {
                        // history.push("/school-admin");
                        localStorage.setItem("roleCode", 5);
                      }}
                    >
                      <div className="modal__add-schedule-item">
                        <div className="modal__add-schedule-title">
                          <p>{t("login:role parent")}</p>
                        </div>
                        <div className="modal__add-schedule-description">
                          <p>
                            {t(
                              "schoolAdmin:set the desired settings and the schedule is automatically generated"
                            )}
                          </p>
                        </div>
                      </div>
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}
