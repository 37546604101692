import { combineReducers } from "redux";

import { authReducer } from "./auth/reducers";
import { schoolsReducer } from "./school/reducers";
import { classReducer } from "./class/reducers";
import { chronicleYearReducer } from "./chronicleYear/reducers";
import { quartersReducer } from "./quarter/reducers";
import { adminCoursesReducer } from "./adminCourse/reducers";
import { coursesReducer } from "./course/reducers";
import { shiftsReducer } from "./shift/reducers";
import { shiftTimesReducer } from "./shiftTime/reducers";
import { personsReducer } from "./person/reducers";
import { instructorsReducer } from "./instructor/reducers";
import { languagesReducer } from "./language/reducers";
import { studentsReducer } from "./student/reducers";
import { parentsReducer } from "./parent/reducers";
import { regionsReducer } from "./region/reducers";
import { rayonsReducer } from "./rayon/reducers";
import { scheduleGroupReducer } from "./scheduleGroup/reducers";
import { scheduleReducer } from "./schedule/reducers";
import { studentCoursesReducer } from "./studentCourse/reducers";
import { paymentsReducer } from "./payment/reducers";
import { calendarPlanReducer } from "./calendarPlan/reducers";
import { calendarTopicReducer } from "./calendarTopic/reducers";
import { analyticReducer } from "./analytic/reducer";
import { gradesReducer } from "./grade/reducers";
import { reportReducer } from "./report/reducer";
import { quarterGradesReducer } from "./quarterGrade/reducers";
import { sectionsReducer } from "./section/reducers";
import { assignmentsReducer } from "./assignment/reducers";
import { townsReducer } from "./town/reducers";

export default combineReducers({
  auth: authReducer,
  schools: schoolsReducer,
  class: classReducer,
  chronicleYears: chronicleYearReducer,
  quarters: quartersReducer,
  adminCourses: adminCoursesReducer,
  courses: coursesReducer,
  shifts: shiftsReducer,
  shiftTimes: shiftTimesReducer,
  persons: personsReducer,
  instructors: instructorsReducer,
  languages: languagesReducer,
  students: studentsReducer,
  parents: parentsReducer,
  regions: regionsReducer,
  rayons: rayonsReducer,
  scheduleGroups: scheduleGroupReducer,
  schedules: scheduleReducer,
  studentCourses: studentCoursesReducer,
  payments: paymentsReducer,
  calendarPlans: calendarPlanReducer,
  calendarTopics: calendarTopicReducer,
  analytic: analyticReducer,
  grades: gradesReducer,
  report: reportReducer,
  quarterGrades: quarterGradesReducer,
  sections: sectionsReducer,
  assignments: assignmentsReducer,
  towns: townsReducer,
});
