/* eslint-disable indent */
import { urls, toastOption } from "../../config";
import { API } from "../../utils";
import { toast } from "react-toastify";
import i18n from "../../i18n";

import {
  GET_STUDENTS_REQUEST,
  GET_STUDENTS_SUCCESS,
  GET_STUDENTS_ERROR,
  GET_STUDENT_REQUEST,
  GET_STUDENT_SUCCESS,
  GET_STUDENT_ERROR,
} from "../actionTypes";

export const getAllStudentsBySchool =
  (archived = false) =>
  async (dispatch) => {
    dispatch(getStudentsRequest());

    await API.get(
      `${urls.STUDENT}school/${
        JSON.parse(localStorage.getItem("school"))?.id
      }?archived=${archived}`
    )
      .then(async (res) => {
        await dispatch(getStudentsSuccess(res.data));
        console.log(res.data);
        return res.data;
      })
      .catch(async (error) => {
        await dispatch(getStudentsFailure(error.message));
        console.log(error);
        toast.error(i18n.t("toastify:error"), toastOption);
        return error.message;
      });
  };
export const getAllStudentsByClass = (classId) => async (dispatch) => {
  dispatch(getStudentsRequest());

  await API.get(`${urls.STUDENT}class/${classId}`)
    .then((res) => {
      dispatch(getStudentsSuccess(res.data));
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getStudentsFailure(error.message));
      return error.message;
    });
};
export const getStudentsByCourseIdClassIdInstructorId =
  (values) => async (dispatch) => {
    dispatch(getStudentsRequest());

    await API.get(
      `${urls.STUDENT_MOBILE}course/person/${
        values.id ? values.id : JSON.parse(localStorage.getItem("person"))?.id
      }/class/${values.classId}/course/${values.courseId}/chronicle/${
        JSON.parse(localStorage.getItem("chronicleActive"))?.id
      }`
    )
      .then((res) => {
        console.log(res.data);
        dispatch(getStudentsSuccess(res.data));
        return res.data;
      })
      .catch((error) => {
        console.log(error);
        toast.error(i18n.t("toastify:error"), toastOption);
        dispatch(getStudentsFailure(error.message));
        return error.message;
      });
  };
export const getStudentById = (id) => async (dispatch) => {
  dispatch(getStudentRequest());

  await API.get(`${urls.STUDENT}${id}`)
    .then((res) => {
      console.log(res.data);
      dispatch(getStudentSuccess(res.data));
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getStudentFailure(error.message));
      return error.message;
    });
};
export const updateStudent = (values) => async (dispatch) => {
  const data = {
    ...values,
  };
  await API.post(`${urls.STUDENT}edit/${values.id}`, data)
    .then(async (res) => {
      await dispatch(getAllStudentsBySchool());
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess updated"), toastOption);
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      return error.message;
    });
};
export const addStudent = (values, setStudent, setModal) => async () => {
  const data = {
    ...values,
    schoolId: JSON.parse(localStorage.getItem("school"))?.id,
  };
  await API.post(`${urls.STUDENT}create`, data)
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess added"), toastOption);
      setStudent(res.data.message);
      setModal && setModal({ step1: false, step2: true, editClass: false });
      return res;
    })
    .catch((error) => {
      console.log(error);
      if (error.response.status === 409) {
        toast.error(i18n.t("toastify:student phone number exist"), toastOption);
      } else {
        toast.error(i18n.t("toastify:error"), toastOption);
      }
      setModal && setModal({ step1: false, step2: false, editClass: false });
      return error;
    });
};
export const removeStudent = (id) => async (dispatch) => {
  await API.delete(`${urls.STUDENT}?id=${id}`)
    .then(async (res) => {
      await dispatch(getAllStudentsBySchool());
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess deleted"), toastOption);
      return res.data;
    })
    .catch(async (error) => {
      console.log(error.response);
      if (error.response.status === 409) {
        await dispatch(getAllStudentsBySchool());
        toast.error(i18n.t("toastify:delete student archived"), toastOption);
      } else {
        toast.error(i18n.t("toastify:error"), toastOption);
      }
      // dispatch(getStudentFailure(error.message));
      return error.message;
    });
};

export const getStudentsRequest = () => {
  return {
    type: GET_STUDENTS_REQUEST,
  };
};

export const getStudentsSuccess = (data) => {
  return {
    type: GET_STUDENTS_SUCCESS,
    payload: data,
  };
};

export const getStudentsFailure = (error) => {
  return {
    type: GET_STUDENTS_ERROR,
    payload: error,
  };
};

export const getStudentRequest = () => {
  return {
    type: GET_STUDENT_REQUEST,
  };
};

export const getStudentSuccess = (data) => {
  return {
    type: GET_STUDENT_SUCCESS,
    payload: data,
  };
};

export const getStudentFailure = (error) => {
  return {
    type: GET_STUDENT_ERROR,
    payload: error,
  };
};
