import { urls, toastOption } from "../../config";
import { API } from "../../utils";
import { toast } from "react-toastify";
import i18n from "../../i18n";

export const stepUpSchoolPresetting = (values) => async () => {
  let data = {
    id: JSON.parse(localStorage.getItem("schoolPresetting"))?.id,
    chronicleId: values.chronicleId,
    isPreset: values.isPreset,
    schoolId: values.schoolId,
    stepNumber: values.stepNumber,
  };

  await API.post(
    `${urls.SCHOOL_PRESET}edit/${
      JSON.parse(localStorage.getItem("schoolPresetting"))?.id
    }`,
    data
  )
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:success"), toastOption);
      localStorage.setItem("schoolPresetting", JSON.stringify(data));
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      return error.message;
    });
};
export const schoolPresettingStatus = (history) => async () => {
  await API.post(
    `${urls.SCHOOL_PRESET}check?chronicleId=${
      JSON.parse(localStorage.getItem("chronicleActive"))?.id
    }&schoolId=${JSON.parse(localStorage.getItem("school"))?.id}`
  )
    .then(async (res) => {
      localStorage.setItem("schoolPresetting", JSON.stringify(res.data));
      if (res.data?.preset) {
        if (localStorage.getItem("roleCode") == 2) {
          history.push("/school-admin");
        } else if (localStorage.getItem("roleCode") == 6) {
          history.push("/school-admin");
        }
      } else if (res.data?.stepNumber > 0) {
        history.push(`/school-presetting`);
      } else {
        console.log("sdfsdfsdfs");
        await API.post(
          `${urls.SCHOOL_PRESET}create`,
          JSON.stringify({
            chronicleId: JSON.parse(localStorage.getItem("chronicleActive"))
              ?.id,
            isPreset: false,
            schoolId: JSON.parse(localStorage.getItem("school"))?.id,
            stepNumber: 1,
          })
        )
          .then((res4) => {
            console.log("SCHOOL_PRESET", res4);
            API.post(
              `${urls.SCHOOL_PRESET}check?chronicleId=${
                JSON.parse(localStorage.getItem("chronicleActive"))?.id
              }&schoolId=${JSON.parse(localStorage.getItem("school"))?.id}`
            )
              .then(async (res5) => {
                localStorage.setItem(
                  "schoolPresetting",
                  JSON.stringify(res5.data)
                );
                history.push("/school-presetting");
              })
              .catch((e) => {
                console.log("SCHOOL_PRESET", e.message);
                return e;
              });
            return res;
          })
          .catch((e) => {
            console.log("SCHOOL_PRESET", e.message);
            return e;
          });
      }
      return res;
    })
    .catch((e) => {
      console.log("SCHOOL_PRESET", e.message);
      return e;
    });
};

export const checkSchoolPresetting = (values) => async () => {
  let data = {
    id: JSON.parse(localStorage.getItem("schoolPresetting"))?.id,
    chronicleId: values.chronicleId,
    isPreset: values.isPreset,
    schoolId: values.schoolId,
    stepNumber: values.stepNumber,
  };

  await API.post(
    `${urls.SCHOOL_PRESET}check?chronicleId=${
      JSON.parse(localStorage.getItem("chronicleActive"))?.id
    }&schoolId=${JSON.parse(localStorage.getItem("school"))?.id}`,
    data
  )
    .then((res) => {
      console.log(res.data);
      localStorage.setItem("schoolPresetting", JSON.stringify(data));
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return error.message;
    });
};
