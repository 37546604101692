import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Picture from "../../assets/images/instructor-main-picture.png";
import SelectSearch, { fuzzySearch } from "react-select-search";
import { useTranslation } from "react-i18next";

import { getInstructorInstarctorId } from "../../store/instructor/actions";
import { getScheduleGroups } from "../../store/scheduleGroup/actions";
import { days, fullDays, fullMonth } from "../../config/constants";
import { getShiftTimes } from "../../store/shiftTime/actions";
import { getSchoolById } from "../../store/school/actions";
import { InstractorLayout, NoData } from "../../components/";
import { getShifts } from "../../store/shift/actions";
import "./Instactor.scss";

export default function Home() {
  const dispatch = useDispatch();
  const [state, setState] = useState();
  const [school, setSchool] = useState();
  const [scheduleShiftNumber, setScheduleShiftNumber] = useState(1);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    dispatch(getInstructorInstarctorId(setState));
    dispatch(getSchoolById(state?.instractorId, setSchool));
    dispatch(getShiftTimes());
    dispatch(getShifts());
    dispatch(getScheduleGroups());
  }, [dispatch]);

  const scheduleGroupsStore = useSelector(
    (state) => state.scheduleGroups.scheduleGroups
  );

  const activeAdmin = JSON.parse(localStorage.getItem("user"));
  const shiftsStore = useSelector((store) => store.shifts.shifts);
  const shiftTimesStore = useSelector((store) =>
    store.shiftTimes.shiftTimes.sort((a, b) =>
      a.startTime < b.startTime ? -1 : a.startTime > b.startTime ? 1 : 0
    )
  );

  const scheduleTimes = shiftTimesStore?.filter(
    (value) => value.shiftId == shiftsStore[scheduleShiftNumber - 1]?.id
  );

  const d = new Date();
  const month =
    (String(i18n.language).toLowerCase() === "en" &&
      fullMonth[d.getMonth()].en) ||
    (String(i18n.language).toLowerCase() === "tr" &&
      fullMonth[d.getMonth()].tr) ||
    (String(i18n.language).toLowerCase() === "kg" &&
      fullMonth[d.getMonth()].kg) ||
    fullMonth[d.getMonth()].ru;
  const weekDayToDay = d.getDate();

  const weekday = new Array(7);
  weekday[0] = "Sunday";
  weekday[1] = "Monday";
  weekday[2] = "Tuesday";
  weekday[3] = "Wednesday";
  weekday[4] = "Thursday";
  weekday[5] = "Friday";
  weekday[6] = "Saturday";
  const n = weekday[d.getDay()].toUpperCase();

  const shiftsOptions = shiftsStore.map((item) => ({
    name: `${item.title} ${t("presetting:shift presetting")}`,
    value: item.title,
    key: "shiftId",
  }));

  const arrayO5fDays = new Array(5);
  arrayO5fDays[0] = 1;
  arrayO5fDays[1] = 2;
  arrayO5fDays[2] = 3;
  arrayO5fDays[3] = 4;
  arrayO5fDays[4] = 5;

  const arrayOf6Days = new Array(6);
  arrayOf6Days[0] = 1;
  arrayOf6Days[1] = 2;
  arrayOf6Days[2] = 3;
  arrayOf6Days[3] = 4;
  arrayOf6Days[4] = 5;
  arrayOf6Days[5] = 6;

  function actionTimeLine(item, idx) {
    return (
      <tr key={(item?.id, idx)} className="schedule_content">
        <td className="schedule_time schedule_classes roboto">
          {scheduleTimes?.[idx]?.startTime}-{scheduleTimes?.[idx]?.endTime}
        </td>
        {school?.studyDay == 5 &&
          arrayO5fDays?.map((item, i) => (
            <td
              key={(item?.id + "qp", idx + "qp")}
              className={
                n == fullDays[i].en
                  ? "schedule_active_classes schedule_classes roboto"
                  : "schedule_classes roboto"
              }
            >
              <span>
                {Array.from(
                  new Set(
                    state?.map(
                      (lesson) =>
                        lesson.shiftTimeId == scheduleTimes?.[idx]?.id &&
                        lesson.weekDay == days[i]?.en && (
                          <div>
                            {(String(i18n.language).toLowerCase() === "kg" &&
                              lesson.courseTitleKG) ||
                              (String(i18n.language).toLowerCase() === "en" &&
                                lesson.courseTitle) ||
                              lesson.courseTitleRU + ` `}
                            <br></br>
                            {lesson.classTitle}{" "}
                            {lesson.weekDay == days[i]?.en &&
                              lesson.shiftTimeId == scheduleTimes?.[idx]?.id &&
                              lesson.isGroup &&
                              `(${t("instructor:is group")} ${
                                scheduleGroupsStore?.find(
                                  (item) =>
                                    item.groupId === lesson.scheduleGroupId
                                )?.groupTitle
                              })`}
                            {console.log(lesson)}
                          </div>
                        )
                    )
                  )
                )}
              </span>
            </td>
          ))}
        {school?.studyDay == 6 &&
          arrayOf6Days?.map((item, i) => (
            <td
              key={(item?.id + "qp", idx + "qp")}
              className={
                n == fullDays[i].en
                  ? "schedule_active_classes schedule_classes roboto"
                  : "schedule_classes roboto"
              }
            >
              <span>
                {Array.from(
                  new Set(
                    state?.map(
                      (lesson) =>
                        lesson.shiftTimeId == scheduleTimes?.[idx]?.id &&
                        lesson.weekDay == days[i]?.en && (
                          <div>
                            {(String(i18n.language).toLowerCase() === "kg" &&
                              lesson.courseTitleKG) ||
                              (String(i18n.language).toLowerCase() === "en" &&
                                lesson.courseTitle) ||
                              lesson.courseTitleRU + ` `}
                            <br></br>
                            {lesson.classTitle}{" "}
                            {lesson.weekDay == days[i]?.en &&
                              lesson.shiftTimeId == scheduleTimes?.[idx]?.id &&
                              lesson.isGroup &&
                              `(${t("instructor:is group")} ${
                                scheduleGroupsStore?.find(
                                  (item) =>
                                    item.groupId === lesson.scheduleGroupId
                                )?.groupTitle
                              })`}
                            {console.log(lesson)}
                          </div>
                        )
                    )
                  )
                )}
              </span>
            </td>
          ))}
      </tr>
    );
  }

  const scheduleLessons = scheduleTimes?.map((item, idx) =>
    actionTimeLine(item, idx)
  );

  return (
    <div>
      <InstractorLayout>
        <div className="school-admin__body">
          <div
            className="school-admin__body-item school-presetting__body school-presetting__body-color"
            style={{ paddingBottom: "0.5rem" }}
          >
            <div className="school-admin__banner" style={{ padding: "0" }}>
              <div className="school-admin__banner-left">
                <div
                  className="school-admin__banner-text"
                  style={{ margin: "-32px 0 0 0" }}
                >
                  <div
                    style={{
                      marginBottom: "20px",
                      height: "35px",
                      padding: "11px 35px",
                    }}
                  ></div>
                  <p
                    style={{
                      margin: "0 0 12px 0",
                    }}
                  >
                    <span className="orange-text" key="0">
                      {t("instructor:welcome")},{" "}
                      <span style={{ color: "black" }}>
                        {activeAdmin?.user?.firstName}
                        {"  "}
                        {activeAdmin?.user?.lastName}!
                      </span>
                    </span>{" "}
                  </p>
                </div>
              </div>
              <div className="school-admin__banner-right">
                <img
                  className="school-admin__banner-img"
                  style={{ right: "120px", top: "-86px" }}
                  src={Picture}
                  alt="school admin image"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="schedule_box">
          <div className="schedule_box_title">{t("schoolAdmin:schedule")}</div>
          <div className="schedule_box_under_title">
            <span className="schedule_box_under_title_number">
              {weekDayToDay}
            </span>
            {month},
            <span className="orange-text" key="0">
              {(n == fullDays[0].en && t("instructor:monday")) ||
                (n == fullDays[1].en && t("instructor:tuesday")) ||
                (n == fullDays[2].en && t("instructor:wednesday")) ||
                (n == fullDays[3].en && t("instructor:thursday")) ||
                (n == fullDays[4].en && t("instructor:friday")) ||
                (n == fullDays[5].en && t("instructor:saturday")) ||
                "Воскресенье"}
            </span>
            {shiftsStore.length > 0 && (
              <SelectSearch
                options={shiftsOptions}
                search
                className="select-search filter drop-up"
                filterOptions={fuzzySearch}
                name="shiftId"
                value={scheduleShiftNumber}
                onChange={(_, k) => {
                  setScheduleShiftNumber(k.value);
                  console.log("value", k.value);
                }}
                required
                placeholder={`${t("presetting:shift presetting")}`}
              />
            )}
          </div>
          {scheduleLessons == "" ? (
            <NoData messages={[`${t("instructor:here is nothing")}`]} />
          ) : (
            <table className="schedule_table">
              <tr className="schedule_title">
                <th>&nbsp;</th>
                <th className={n == days[0].en && "active_day"}>
                  {t("instructor:monday")}
                </th>
                <th className={n == days[1].en && "active_day"}>
                  {t("instructor:tuesday")}
                </th>
                <th className={n == days[2].en && "active_day"}>
                  {t("instructor:wednesday")}
                </th>
                <th className={n == days[3].en && "active_day"}>
                  {t("instructor:thursday")}
                </th>
                <th className={n == days[4].en && "active_day"}>
                  {t("instructor:friday")}
                </th>
                {school?.studyDay == 6 && (
                  <th className={n == days[5].en && "active_day"}>
                    {t("instructor:saturday")}
                  </th>
                )}
              </tr>
              {scheduleLessons}
            </table>
          )}
        </div>
      </InstractorLayout>
    </div>
  );
}
