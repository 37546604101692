/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { X, Users, Star, Clock, PlusCircle, Edit2, Trash } from "react-feather";
import Modal from "react-modal";
import SelectSearch, { fuzzySearch } from "react-select-search";

import { getClassBySchoolId } from "../../store/class/actions";
import { getAllStudentsByClass } from "../../store/student/actions";
import {
  GoBack,
  NoData,
  SchoolAdminLayout,
  TimePickerCalendar,
} from "../../components";
import { getInstructors } from "../../store/instructor/actions";
import {
  getSectionInstructor,
  getSectionStudent,
  getSectionSchedule,
  deleteSectionStudent,
  addSectionStudent,
  deleteSectionInstructor,
  editSectionInstructor,
  deleteSectionSchedule,
  createSectionSchedule,
  editSectionSchedule,
} from "../../store/section/actions";
import { days } from "../../config/constants";

export default function Section() {
  const { sectionInstructorId } = useParams();
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const sectionInstructorStore = useSelector(
    (store) => store.sections.sectionInstructor
  );
  const sectionScheduleStore = useSelector(
    (store) => store.sections.sectionSchedule
  );
  const sectionStudentStore = useSelector(
    (store) => store.sections.sectionStudent
  );
  const classStore = useSelector((store) => store.class.class);
  const studentsStore = useSelector((store) =>
    store.students.students.sort((a, b) => a.lastName - b.lastName)
  );
  const instructorsStore = useSelector(
    (store) => store.instructors.instructors
  );
  const [newStudents, setNewStudents] = useState({});

  const [modal, setModal] = useState({
    addStudent: false,
    editSection: false,
    confirm: false,
  });

  useEffect(() => {
    dispatch(getSectionInstructor({ sectionInstructorId }));
    dispatch(getSectionStudent({ sectionInstructorId }));
    dispatch(getSectionSchedule({ sectionInstructorId }));
  }, []);

  const removeItem = async (value) => {
    await dispatch(
      deleteSectionInstructor(value, history, "/school-admin/sections")
    );
    // await dispatch(getSections());
  };
  // const onChangeTime = (values) => {
  //   let scheduleWeekTimeList = editInstructor?.scheduleWeekTimeList;
  //   const index = scheduleWeekTimeList?.findIndex((element) => {
  //     if (element.weekDay === values.weekDay) {
  //       return true;
  //     }
  //   });
  //   scheduleWeekTimeList[index] = values;
  //   setEditInstructor({
  //     ...editInstructor,
  //     scheduleWeekTimeList: scheduleWeekTimeList,
  //   });
  // };

  const classOptions = classStore.map((item) => ({
    name: `${item.classLevel}${item.classLabel}`,
    value: item.id,
    key: "classId",
  }));

  const instructorsOptions = instructorsStore.map((item) => ({
    name: item.instructorTitle,
    value: item.instructorId,
    key: "personId",
  }));

  return (
    <SchoolAdminLayout>
      <GoBack title={t("common:back")} />
      <div className="school-admin__section">
        <div className="school-admin__section-left">
          <div className="school-admin__section-title">
            <div className="school-admin__section-title-star">
              <Star color="#F0783A" size={12} />
            </div>
            <h3>{sectionInstructorStore?.sectionTitle}</h3>
          </div>
          <div className="school-admin__section-instructor">
            <div className="school-admin__section-instructor-title">
              <Users color="#DADADA" size={20} />{" "}
              <h4>{t("schoolAdmin:teacher")}</h4>
            </div>

            <p>{sectionInstructorStore?.personTitle}</p>
          </div>
          <div className="school-admin__section-schedule">
            <div className="school-admin__section-schedule-title">
              <Clock color="#DADADA" size={20} />{" "}
              <h4>{t("schoolAdmin:graphic of lessons")}</h4>
            </div>

            <div className="school-admin__section-schedule-list">
              {sectionScheduleStore?.length > 0 &&
                sectionScheduleStore
                  ?.sort((a, b) => a.weekDay - b.weekDay)
                  ?.map((item, idx) => (
                    <div
                      className="school-admin__section-schedule-item"
                      key={idx}
                    >
                      <div className="school-admin__section-schedule-day">
                        <p>
                          {days[item.weekDay - 1]?.[`${i18n.language}Short`]}
                        </p>
                      </div>
                      <div className="school-admin__section-schedule-time roboto">{`${item?.startTime} - ${item.endTime}`}</div>
                    </div>
                  ))}
            </div>
          </div>
          <div className="school-admin__section-action">
            <div className="school-admin__section-action-edit">
              <button
                className="pure"
                onClick={() => {
                  setModal({ ...modal, editSection: true });
                }}
              >
                <Edit2 size={16} color="#14A38B" />
                <p>{t("common:edit")}</p>
              </button>
            </div>
            <div className="school-admin__section-action-delete">
              <button
                className="pure"
                onClick={() => {
                  setModal({ ...modal, confirm: true });
                }}
              >
                <Trash size={16} color="#FF7171" />
                <p>{t("common:delete")}</p>
              </button>
            </div>
          </div>
        </div>
        <div className="school-admin__section-right">
          <div className="school-admin__section-header">
            <div className="school-admin__section-header-left">
              <div className="school-admin__section-student-count">
                <Users color="#DADADA" size={20} />{" "}
                <h4>
                  {t("schoolAdmin:students")}:{" "}
                  <span className="roboto">{sectionStudentStore?.length}</span>
                </h4>
              </div>
            </div>
            <div className="school-admin__section-header-right">
              <button
                className="pure"
                onClick={() => {
                  setModal({
                    ...modal,
                    addStudent: true,
                  });
                }}
              >
                <PlusCircle size={24} color="#F0783A" />
                <span>{t("schoolAdmin:add students")}</span>
              </button>
            </div>
          </div>
          <div className="school-admin__section-body">
            {sectionStudentStore?.length > 0 ? (
              <div className="school-admin__section-students-list">
                {sectionStudentStore?.map((student, idx) => (
                  <div
                    className="school-admin__section-students-item"
                    key={idx}
                  >
                    <p>
                      {idx + 1}. {student?.studentTitle}
                    </p>
                    <p className="roboto">
                      {student?.classTitle} {t("schoolAdmin:lower case class")}
                    </p>
                  </div>
                ))}
              </div>
            ) : (
              <NoData messages={[`${t("schoolAdmin:here is nothing")}`]} />
            )}
          </div>
        </div>
      </div>
      <Modal
        isOpen={modal.addStudent}
        onRequestClose={() => {
          setModal({ ...modal, addStudent: false });
        }}
        overlayClassName="modal-overlay"
        onAfterOpen={async () => {
          await dispatch(getClassBySchoolId());
        }}
      >
        <div className="modal">
          <div className="modal-close">
            <button
              onClick={() => {
                setModal({ ...modal, addStudent: false });
              }}
            >
              <X size={15} color="#9A9A9A" />
            </button>
          </div>
          <div className="modal-header">
            <div className="modal-header-title">
              <h2 className="roboto">{t("schoolAdmin:students")}</h2>
            </div>
            <div className="modal-header-description">
              <p className="roboto">
                {t("schoolAdmin:add student to section description")}
              </p>
            </div>
          </div>
          <div className="modal-body">
            <div className="modal-inputs-list">
              <div className="modal-input">
                <SelectSearch
                  options={classOptions}
                  search
                  className="select-search"
                  filterOptions={fuzzySearch}
                  name="classId"
                  value={newStudents?.classId}
                  onChange={(_, k) => {
                    setNewStudents({
                      ...newStudents,
                      classId: k.value,
                    });
                    dispatch(getAllStudentsByClass(k.value));
                  }}
                  required
                  placeholder={`${t("schoolAdmin:class")}`}
                />
              </div>
              {/* <div className="modal-input"> */}
              {studentsStore.length > 0 ? (
                <>
                  <button
                    className="pure"
                    onClick={async () => {
                      await studentsStore.map((item) => {
                        dispatch(
                          addSectionStudent({
                            sectionInstructorId,
                            studentId: item.id,
                          })
                        );
                      });
                      await dispatch(
                        getSectionStudent({ sectionInstructorId })
                      );
                    }}
                  >
                    {t("schoolAdmin:add all")}
                  </button>
                  {studentsStore?.map((item, idx) => (
                    <div key={idx} className="section-modal_student-item">
                      <input
                        type="checkbox"
                        checked={
                          sectionStudentStore.length > 0 &&
                          sectionStudentStore?.find(
                            (student) => student.studentId === item.id
                          )
                        }
                        onChange={async () => {
                          if (
                            sectionStudentStore?.find(
                              (student) => student.studentId === item.id
                            )
                          ) {
                            await dispatch(
                              deleteSectionStudent(
                                sectionStudentStore?.find(
                                  (student) => student.studentId === item.id
                                )?.id
                              )
                            );
                            await dispatch(
                              getSectionStudent({ sectionInstructorId })
                            );
                          } else {
                            await dispatch(
                              addSectionStudent({
                                sectionInstructorId,
                                studentId: item.id,
                              })
                            );
                            await dispatch(
                              getSectionStudent({ sectionInstructorId })
                            );
                          }
                        }}
                      />
                      <p>
                        {item.lastName} {item.firstName}
                      </p>
                    </div>
                  ))}
                </>
              ) : (
                <NoData messages={[`${t("instructor:choose class")}`]} />
              )}

              {/* </div> */}
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modal.editSection}
        onRequestClose={() => {
          setModal({ ...modal, editSection: false });
          // setEditInstructor({});
        }}
        overlayClassName="modal-overlay"
        onAfterOpen={async () => {
          await dispatch(getInstructors());
          // dispatch(getSectionById(current));
          // dispatch(getSectionsByClassId(new.currentSectionId));
        }}
      >
        <div className="modal">
          <div className="modal-close">
            <button
              onClick={() => {
                setModal({ ...modal, editSection: false });
                // setSearchText("");
                // setSelectedData([]);
                // setEditInstructor({});
              }}
            >
              <X size={15} color="#9A9A9A" />
            </button>
          </div>
          <div className="modal-header">
            <div className="modal-header-title">
              <h2 className="roboto">{t("schoolAdmin:section")}</h2>
            </div>
          </div>
          <div className="modal-body">
            <div className="modal-inputs-list">
              <div className="modal-input">
                <SelectSearch
                  options={instructorsOptions}
                  search
                  className="select-search"
                  filterOptions={fuzzySearch}
                  name="instructorId"
                  value={sectionInstructorStore.personId}
                  onChange={(_, k) => {
                    dispatch(
                      editSectionInstructor({
                        instructorId: instructorsStore.find(
                          (item) => item.instructorId === k.value
                        )?.instructorId,
                        sectionInstructorId,
                        scheduleWeekTimeList: sectionScheduleStore,
                      })
                    );
                    // setEditInstructor({
                    //   ...editInstructor,
                    //   instructorId: k.value,
                    // });
                  }}
                  required
                  placeholder={`${t("schoolAdmin:insctructor")}`}
                />
              </div>
              <p>{t("schoolAdmin:graphic of lessons")}:</p>
              <div className="modal-input">
                <div className="days_buttons">
                  {days
                    .slice(
                      [0],
                      [JSON.parse(localStorage.getItem("school"))?.studyDay]
                    )
                    .map((day, idx) => (
                      <button
                        key={idx}
                        name={day.en}
                        className={
                          sectionScheduleStore?.length > 0 &&
                          sectionScheduleStore?.find(
                            (item) => item.weekDay === day.id + 1
                          )
                            ? "primary"
                            : "secondary"
                        }
                        onClick={(e) => {
                          if (
                            sectionScheduleStore?.find(
                              (item) => item.weekDay === day.id + 1
                            )
                          ) {
                            dispatch(
                              deleteSectionSchedule(
                                sectionScheduleStore?.find(
                                  (item) => item.weekDay === day.id + 1
                                )?.id
                              )
                            );
                            dispatch(
                              getSectionSchedule({ sectionInstructorId })
                            );
                          } else {
                            dispatch(
                              createSectionSchedule({
                                sectionInstructorId,
                                scheduleWeekTime: {
                                  endTime: "12:45",
                                  startTime: "12:00",
                                  weekDay: e.target.name,
                                },
                              })
                            );
                            dispatch(
                              getSectionSchedule({ sectionInstructorId })
                            );
                          }
                        }}
                      >
                        {day[`${i18n.language}Short`]}
                      </button>
                    ))}
                </div>
              </div>
              {sectionScheduleStore?.length > 0 &&
                sectionScheduleStore?.map((item, idx) => (
                  <div className="modal-input" key={idx}>
                    <div className="d-flex section-time">
                      {
                        days.find((day) => day.id + 1 === item.weekDay)[
                          `${i18n.language}Short`
                        ]
                      }
                      <TimePickerCalendar
                        defaultValue={`${item.startTime}`}
                        onSelect={async (e) => {
                          await dispatch(
                            editSectionSchedule({
                              scheduleWeekTime: {
                                ...item,
                                startTime: String(e),
                                weekDay: days.find(
                                  (day) => day.id + 1 === item.weekDay
                                )?.en,
                              },
                              sectionInstructorId,
                              id: item.id,
                            })
                          );
                          await dispatch(
                            getSectionSchedule({ sectionInstructorId })
                          );
                        }}
                      />
                      <TimePickerCalendar
                        defaultValue={`${item.endTime}`}
                        onSelect={async (e) => {
                          await dispatch(
                            editSectionSchedule({
                              scheduleWeekTime: {
                                ...item,
                                endTime: String(e),
                                weekDay: days.find(
                                  (day) => day.id + 1 === item.weekDay
                                )?.en,
                              },
                              sectionInstructorId,
                              id: item.id,
                            })
                          );
                          await dispatch(
                            getSectionSchedule({ sectionInstructorId })
                          );
                        }}
                      />
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modal.confirm}
        onRequestClose={() => {
          setModal({ ...modal, confirm: false });
        }}
        overlayClassName="modal-overlay"
      >
        <div className="modal">
          <div className="modal-close">
            <button
              onClick={() => {
                setModal({ ...modal, confirm: false });
              }}
            >
              <X size={15} color="#9A9A9A" />
            </button>
          </div>
          <div className="modal-header">
            <div className="modal-header-title">
              <h2>{t("presetting:are u shure presetting")}</h2>
            </div>
            <div className="modal-header-description">
              <p className="body-2">{t("schoolAdmin:delete warning")}</p>
            </div>
          </div>

          <div className="modal-footer" style={{ marginTop: "24px" }}>
            <button
              className="large secondary"
              onClick={() => setModal({ ...modal, confirm: false })}
            >
              {t("common:cancel")}
            </button>
            <button
              className="large primary"
              onClick={async () => {
                await removeItem(sectionInstructorId);
                await setModal({ ...modal, confirm: false });
              }}
            >
              {t("common:delete")}
            </button>
          </div>
        </div>
      </Modal>
    </SchoolAdminLayout>
  );
}
