import {
  GET_RAYONS_REQUEST,
  GET_RAYONS_SUCCESS,
  GET_RAYONS_ERROR,
} from "../actionTypes";
import { statuses } from "../../config";

const initialState = {
  rayons: [],
  error: "",
  status: statuses.INITIAL,
};

export const rayonsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_RAYONS_REQUEST:
      return {
        ...state,
        rayons: [],
        error: "",
        status: statuses.LOADING,
      };
    case GET_RAYONS_SUCCESS:
      return {
        ...state,
        rayons: action.payload,
        error: "",
        status: statuses.SUCCESS,
      };
    case GET_RAYONS_ERROR:
      return {
        ...state,
        rayons: [],
        error: action.payload,
        status: statuses.ERROR,
      };

    default:
      return state;
  }
};
