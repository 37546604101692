import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";

// import { useSelector, useDispatch } from "react-redux";

// import ReactGA from "react-ga";
// import TagManager from "react-gtm-module";

import "react-toastify/dist/ReactToastify.min.css";
import "./App.scss";
import ScrollToTop from "./ScrollToTop";
import RouteChangeTracker from "./RouteChangeTracker";
import { useRoutes } from "./routes";
import Modal from "react-modal";

// import { getShifts, createShift, deleteShift } from "../../store/shift/actions";

Modal.setAppElement("#root");
// const GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;
// const GTM_TRACKING_ID = process.env.REACT_APP_GTM_TRACKING_ID;

// const tagManagerArgs = {
//   gtmId: GTM_TRACKING_ID,
// };

// ReactGA.initialize(GA_TRACKING_ID);
// TagManager.initialize(tagManagerArgs);

// moment.updateLocale("ru_RU", {
//   weekdaysMin: ["111", "222", "ee", "We", "Th", "Fr", "Sa"],
// });
// moment.locale("ru_RU");
// moment.updateLocale("ru", {
//   weekdaysMin: ["111", "222", "Tu", "We", "Th", "Fr", "Sa"],
// });
export default function App() {
  // const history = useHistory();

  // const dispatch = useDispatch();
  // const userStore = useSelector((store) => store.user.user);

  useEffect(() => {
    // dispatch(getShifts());
    // console.log(history);
    // a();
  }, []);

  // const a = () => {
  //   const lUser = JSON.parse(localStorage.getItem("user"));
  //   // eslint-disable-next-line prettier/prettier
  //   const lPresetting = JSON.parse(
  //     localStorage.getItem("schoolPresetting")
  //   )?.preset;
  //   console.log(window.location.pathname);
  //   if (lUser?.roles?.map((item) => item?.id).includes(2)) {
  //     if (
  //       !lPresetting &&
  //       window.location.pathname == "/" &&
  //       window.location.pathname == ""
  //     ) {
  //       window.location.href = "/school-presetting";
  //       // history.push("/");
  //       // window.location.reload();
  //     } else if (
  //       window.location.pathname == "/" &&
  //       window.location.pathname == ""
  //     ) {
  //       window.location.href = "/school-admin";
  //     }
  //   } else if (lUser?.roles?.map((item) => item?.id).includes(1)) {
  //     window.location.href = "/super-admin";
  //   }
  // };

  const lStorageUser = JSON.parse(localStorage.getItem("user"))?.token;
  // const lPresetting = JSON.parse(localStorage.getItem("schoolPresetting"))
  //   ?.preset;

  const routes = useRoutes(lStorageUser);
  return (
    <div className="App">
      {process.env.REACT_APP_ENV === "DEVELOP" && (
        <div className="develop">
          <span>DEVELOP</span>
        </div>
      )}
      <ToastContainer />
      <Router>
        <RouteChangeTracker />
        <ScrollToTop>{routes}</ScrollToTop>
      </Router>
    </div>
  );
}
