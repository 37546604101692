import React, { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useTable } from "react-table";
import PT from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import SelectSearch, { fuzzySearch } from "react-select-search";

import { GoBack, NoData, SchoolAdminLayout } from "../../components";
import "./Reports.scss";
import { getQuarters } from "../../store/quarter/actions";
import { analyticStudents, getQuarterTitles } from "../../store/report/actions";
import { getStudentsCountAnalytics } from "../../store/analytic/action";
import { getCount } from "../../components/Reports/countPupils";
import { getPersons } from "../../store/person/actions";

function ReportPupils() {
  const { t } = useTranslation("reports");
  const dispatch = useDispatch();
  const [quaterId, setQuaterId] = useState();
  const [arr, setArr] = useState([]);
  const [newArr, setNewArr] = useState([]);
  const [directorName, setDirectorName] = useState("");
  const [schoolHeaderName, setSchoolHeaderName] = useState("");
  const quarter = useSelector((state) => state.report.quarter)?.sort(
    (a, b) => a.quarter - b.quarter
  );
  const analyticStudentsStore = useSelector(
    (state) => state.report.analyticStudents
  );
  const person = useSelector((state) => state.persons.persons);
  const countAnalyticStudents = useSelector(
    (state) => state.analytic.instructorStutisticsClasses
  );
  const year = JSON.parse(localStorage.getItem("chronicleActive"));

  const arr1 = [];
  const arr4 = [];
  const arr3 = [];
  let obj = {
    initialCount: 0,
    initialMaleCount: 0,
    initialFemaleCount: 0,
    finalMaleCount: 0,
    finalFemaleCount: 0,
    depCountryCount: 0,
    depRegionCount: 0,
    depRayonCount: 0,
    arrCountryCount: 0,
    arrRayonCount: 0,
    arrRegionCount: 0,
    finalCount: 0,
    quarterId: 0,
    arrAll: 0,
    depAll: 0,
    classTitle: "",
  };
  arr?.sort(function (a, b) {
    return a?.classTitle.localeCompare(b?.classTitle, undefined, {
      numeric: true,
      sensitivity: "base",
    });
  });
  arr &&
    arr?.forEach((obj, i) => {
      obj.classType = parseInt(obj.classTitle);
      obj.index = i + 1;
      if (obj.classType > -1 && obj.classType <= 4) arr1.push(obj);
      if (obj.classType > 4 && obj.classType <= 9) arr4.push(obj);
      if (obj.classType > 9 && obj.classType <= 11) arr3.push(obj);
    });

  let arr2 = [];
  const concatData = () => {
    quaterId &&
      countAnalyticStudents.map((el) => {
        const assignObj = analyticStudentsStore?.filter(
          (obj) => obj.classId == el.classId
        );
        let male = assignObj?.filter((obj) => obj.gender == 1);
        let female = assignObj?.filter((obj) => obj.gender == 2);
        let obj1 = Object.assign({}, obj);
        obj1.finalFemaleCount += female[0]?.finalCount;
        obj1.finalMaleCount += male[0]?.finalCount;
        obj1.initialMaleCount += male[0]?.initialCount;
        obj1.initialCount = female[0]?.initialCount + male[0]?.initialCount;
        obj1.finalCount += female[0]?.finalCount + male[0]?.finalCount;
        obj1.initialFemaleCount += female[0]?.initialCount;
        obj1.classTitle = el?.classTitle;
        obj1.depAll =
          female[0]?.depCountryCount +
          female[0]?.depRayonCount +
          female[0]?.depRegionCount +
          male[0]?.depCountryCount +
          male[0]?.depRayonCount +
          male[0]?.depRegionCount;
        obj1.arrAll =
          female[0]?.arrCountryCount +
          female[0]?.arrRayonCount +
          female[0]?.arrRegionCount +
          male[0]?.arrRayonCount +
          male[0]?.arrRegionCount +
          male[0]?.arrCountryCount;
        assignObj.length && arr2.push(obj1);
        setArr(arr2);
      });
  };
  const quaterTypesOptions = quarter?.map((item) => ({
    name: `${item?.quarter} ${t("instructor:quarter")}`,
    value: item.id,
    startDate: item?.startDate,
    endDate: item?.endDate,
    key: item?.id,
  }));

  useEffect(async () => {
    await dispatch(
      getQuarterTitles(
        JSON.parse(localStorage.getItem("school")).id,
        JSON.parse(localStorage.getItem("chronicleActive")).id
      )
    );
    await dispatch(getStudentsCountAnalytics());
    await dispatch(getPersons(6));
  }, []);

  useEffect(() => {
    dispatch(getQuarters());
    concatData();
  }, [quaterId, analyticStudentsStore]);

  useEffect(() => {
    if (person.length) {
      setDirectorName(
        `${person[0]?.firstName?.charAt(0)}.${person[0]?.lastName}`
      );
      dispatch(getPersons(7));
    }
  }, [person.length]);

  useEffect(async () => {
    await dispatch(getPersons(7));
    person.length &&
      (await setSchoolHeaderName(
        `${person[0]?.firstName?.charAt(0)}.${person[0]?.lastName}`
      ));
  }, [directorName.length]);

  useEffect(
    async () => quaterId && (await dispatch(analyticStudents(quaterId))),
    [quaterId]
  );

  const administrator = [
    {
      initialCount: `${t("reports:school director")}: _____ ${directorName}`,
    },
    {
      initialCount: `О.Б.Б: ______ ${schoolHeaderName}`,
    },
  ];
  useEffect(() => {
    const array = getCount([arr1, arr4, arr3]);
    arr.length ? setNewArr([...array, ...administrator]) : setNewArr([]);
  }, [quaterId, arr]);

  let columns = [
    {
      Header: " ",
      columns: [
        {
          Header: "№",
          accessor: "index",
        },
        {
          Header: `${t("reports:class")}`,
          accessor: "classTitle",
        },
        {
          Header: `${t("reports:beginning of the quarter")}`,
          accessor: "initialCount",
        },
        {
          Header: `${t("reports:boys")}`,
          accessor: "initialMaleCount",
        },
        {
          Header: `${t("reports:girls")}`,
          accessor: "initialFemaleCount",
        },
      ],
    },
    {
      Header: `${t("reports:gone")}`,
      columns: [
        {
          Header: `${t("reports:all")}`,
          accessor: "depAll",
        },
        {
          Header: `${t("reports:republic")}`,
          accessor: "depCountryCount",
        },
        {
          Header: `${t("reports:region")}`,
          accessor: "depRegionCount",
        },
        {
          Header: `${t("reports:area")}`,
          accessor: "depRayonCount",
        },
      ],
    },
    {
      Header: `${t("reports:came")}`,
      columns: [
        {
          Header: `${t("reports:all")}`,
          accessor: "arrAll",
        },
        {
          Header: `${t("reports:republic")}`,
          accessor: "arrCountryCount",
        },
        {
          Header: `${t("reports:region")}`,
          accessor: "arrRegionCount",
        },
        {
          Header: `${t("reports:area")}`,
          accessor: "arrRayonCount",
        },
      ],
    },
    {
      Header: " ",
      columns: [
        {
          Header: `${t("reports:end of the quarter")}`,
          accessor: "finalCount",
        },
        {
          Header: `${t("reports:boys")}`,
          accessor: "finalMaleCount",
        },
        {
          Header: `${t("reports:girls")}`,
          accessor: "finalFemaleCount",
        },
      ],
    },
  ];
  function tableToExcel(table) {
    var uri = "data:application/vnd.ms-excel;base64,",
      template =
        '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
      base64 = function (s) {
        return window.btoa(unescape(encodeURIComponent(s)));
      },
      format = function (s, c) {
        return s.replace(/{(\w+)}/g, function (m, p) {
          return c[p];
        });
      };
    if (!table.nodeType) table = document.getElementById(table);
    var ctx = { worksheet: year.name || "Worksheet", table: table.innerHTML };
    var a = document.createElement("a");
    a.href = uri + base64(format(template, ctx));
    a.download =
      `${JSON.parse(localStorage.getItem("school"))?.name} - ${t(
        "reports:report on pupils"
      )}` + ".xls";
    a.click();
  }

  const data = useMemo(() => newArr, [newArr]);
  const column = useMemo(() => columns, [columns]);
  return (
    <div>
      <SchoolAdminLayout>
        <div className="student_record">
          <div className="button_to_back">
            <GoBack title={t("common:back")} />
          </div>
          <div className="body report_pupils_table_inner">
            <span className="topic">
              {t(
                "reports:general information on classes"
              )}
            </span>
            <div>
              {/* <p className="title">
                {t(
                  "reports:this report allows you to view the attendance of students in one grade for one academic quarter"
                )}
              </p> */}
              <div className="schedule">
                <div className="schedule_filter">
                  <div className="year">
                    {year?.selectorTitle} {t("reports:y")}
                  </div>
                  <SelectSearch
                    options={quaterTypesOptions}
                    search
                    filterOptions={fuzzySearch}
                    value={`${quaterId && quaterId}`}
                    name="quaterId"
                    onChange={(_, k) => {
                      setQuaterId(k.value);
                    }}
                    required
                    placeholder={`- ${t("instructor:quarter")} -`}
                  />
                </div>
                <div className="report_pupils_btn">
                  <button
                    onClick={() => tableToExcel("pupilsReport_table")}
                    className="schedule_export"
                  >
                    {t("schoolAdmin:export")}
                  </button>
                </div>
              </div>
            </div>
            {quaterId ? (
              <Table id="pupilsReport_table" columns={column} data={data} />
            ) : (
              <NoData />
            )}
          </div>
        </div>
      </SchoolAdminLayout>
    </div>
  );
}

export default ReportPupils;

Table.propTypes = {
  columns: PT.array,
  data: PT.array,
  id: PT.string,
};

function Table({ columns, data, id = "pupilsReport_table" }) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });
  useEffect(() => {
    if (rows.length) {
      let rowLength = document.querySelector("#tbody").children.length;
      document
        .querySelector(`#tbody_tr_${rowLength - 1}`)
        ?.setAttribute("class", "first");
      document
        .querySelector(`#tbody_tr_${rowLength - 2}`)
        ?.setAttribute("class", "first");
    }
  }, [rows]);
  return (
    <table id={id} className="report_pupils_table" {...getTableProps()}>
      <thead id="thead">
        {headerGroups.map((headerGroup, idx) => (
          <tr id="thead_tr" key={idx} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, idx) => (
              <th
                textOrientation={90}
                rotate={90}
                id={`thead_th_${idx}`}
                align="center"
                hAlign="center"
                vAlign="center"
                key={idx}
                text-orientation={90}
                textorientation={90}
                {...column.getHeaderProps()}
              >
                <span>{column.render("Header")}</span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody id="tbody" {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr id={`tbody_tr_${i}`} key={i} {...row.getRowProps()}>
              {row.cells.map((cell, idx) => {
                return (
                  <td
                    id={`tbody_td_${i}`}
                    align="center"
                    vAlign="center"
                    hAlign="center"
                    key={idx}
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
