import React, { PureComponent } from "react";

export default class FooterLogo extends PureComponent {
  render() {
    return (
      <svg
        width="141"
        height="32"
        viewBox="0 0 141 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.4599 2.06548L0 0V30.8751L11.4599 28.7632L2.68811 27.0054V3.95986L11.4599 2.06548Z"
          fill="#3C3C3C"
        />
        <path
          d="M4.55469 5.03792L28.7706 0V30.9807L18.2967 28.7697L11.617 31.9223L14.3689 28.0126L4.55526 25.8919L4.55469 5.03792Z"
          fill="#F0783A"
        />
        <path
          d="M11.5961 12.6165L16.5303 8.28562L20.9844 5.72144L13.4414 14.0507L17.8888 22.7629L22.0012 23.0425L11.0814 18.9193L11.5961 12.6165Z"
          fill="white"
        />
        <path
          d="M9.09263 10.2869L10.2772 15.5321L8.66431 24.9633L13.2892 14.1816L9.09263 10.2869Z"
          fill="white"
        />
        <path
          d="M83.7811 24.0425C82.2338 24.0425 81.0259 23.5609 80.1575 22.5978C79.3049 21.6188 78.8391 20.2452 78.7602 18.4768L78.7365 17.6479L78.7602 16.7953C78.8233 15.09 79.2891 13.7401 80.1575 12.7454C81.0417 11.7506 82.2496 11.2533 83.7811 11.2533C85.2179 11.2533 86.3784 11.7191 87.2626 12.6506V7.58233C87.2626 7.40865 87.3179 7.26654 87.4284 7.15602C87.5389 7.0455 87.681 6.99023 87.8547 6.99023H90.7441C90.9178 6.99023 91.0599 7.0455 91.1704 7.15602C91.2809 7.26654 91.3362 7.40865 91.3362 7.58233V23.2135C91.3362 23.3714 91.2809 23.5135 91.1704 23.6398C91.0599 23.7504 90.9178 23.8056 90.7441 23.8056H88.0679C87.91 23.8056 87.7679 23.7504 87.6416 23.6398C87.531 23.5135 87.4758 23.3714 87.4758 23.2135V22.4557C86.6074 23.5135 85.3758 24.0425 83.7811 24.0425ZM85.0837 20.8689C85.7942 20.8689 86.3232 20.6478 86.6705 20.2057C87.0179 19.7478 87.2153 19.1636 87.2626 18.4531C87.2784 18.2479 87.2863 17.9479 87.2863 17.5531C87.2863 17.1742 87.2784 16.8821 87.2626 16.6768C87.231 16.0295 87.0337 15.4927 86.6705 15.0664C86.3074 14.6401 85.7784 14.4269 85.0837 14.4269C84.3416 14.4269 83.8048 14.6479 83.4732 15.09C83.1575 15.5321 82.9759 16.14 82.9285 16.9137C82.9127 17.0716 82.9048 17.3163 82.9048 17.6479C82.9048 17.9795 82.9127 18.2242 82.9285 18.3821C82.9759 19.1557 83.1575 19.7636 83.4732 20.2057C83.8048 20.6478 84.3416 20.8689 85.0837 20.8689Z"
          fill="black"
        />
        <path
          d="M99.2432 24.0425C97.2854 24.0425 95.7775 23.5767 94.7196 22.6451C93.6618 21.7136 93.0855 20.4031 92.9907 18.7136C92.9749 18.5084 92.967 18.1531 92.967 17.6479C92.967 17.1426 92.9749 16.7874 92.9907 16.5821C93.0855 14.9085 93.6776 13.6059 94.767 12.6743C95.8564 11.727 97.3485 11.2533 99.2432 11.2533C101.154 11.2533 102.654 11.727 103.743 12.6743C104.833 13.6059 105.425 14.9085 105.519 16.5821C105.535 16.7874 105.543 17.1426 105.543 17.6479C105.543 18.1531 105.535 18.5084 105.519 18.7136C105.425 20.4031 104.848 21.7136 103.79 22.6451C102.733 23.5767 101.217 24.0425 99.2432 24.0425ZM99.2432 21.1531C99.9379 21.1531 100.451 20.9478 100.783 20.5373C101.114 20.111 101.304 19.4636 101.351 18.5952C101.367 18.4373 101.375 18.1216 101.375 17.6479C101.375 17.1742 101.367 16.8584 101.351 16.7005C101.304 15.8479 101.106 15.2085 100.759 14.7822C100.427 14.3559 99.9221 14.1427 99.2432 14.1427C97.9327 14.1427 97.238 14.9953 97.159 16.7005L97.1354 17.6479L97.159 18.5952C97.1906 19.4636 97.3722 20.111 97.7038 20.5373C98.0511 20.9478 98.5643 21.1531 99.2432 21.1531Z"
          fill="black"
        />
        <path
          d="M107.777 23.8056C107.619 23.8056 107.477 23.7504 107.351 23.6398C107.24 23.5135 107.185 23.3714 107.185 23.2135V7.58233C107.185 7.40865 107.24 7.26654 107.351 7.15602C107.477 7.0455 107.619 6.99023 107.777 6.99023H110.572C110.745 6.99023 110.888 7.0455 110.998 7.15602C111.109 7.26654 111.164 7.40865 111.164 7.58233V23.2135C111.164 23.3872 111.109 23.5293 110.998 23.6398C110.888 23.7504 110.745 23.8056 110.572 23.8056H107.777Z"
          fill="black"
        />
        <path
          d="M117.775 24.0425C116.385 24.0425 115.264 23.5767 114.412 22.6451C113.559 21.6978 113.133 20.3794 113.133 18.69V12.0822C113.133 11.9085 113.188 11.7664 113.299 11.6559C113.409 11.5454 113.551 11.4901 113.725 11.4901H116.685C116.859 11.4901 117.001 11.5454 117.112 11.6559C117.238 11.7664 117.301 11.9085 117.301 12.0822V18.5479C117.301 20.0952 117.988 20.8689 119.362 20.8689C120.025 20.8689 120.546 20.6636 120.925 20.2531C121.304 19.8426 121.493 19.2742 121.493 18.5479V12.0822C121.493 11.9085 121.548 11.7664 121.659 11.6559C121.785 11.5454 121.927 11.4901 122.085 11.4901H125.046C125.219 11.4901 125.361 11.5454 125.472 11.6559C125.582 11.7664 125.638 11.9085 125.638 12.0822V23.2135C125.638 23.3714 125.582 23.5135 125.472 23.6398C125.361 23.7504 125.219 23.8056 125.046 23.8056H122.298C122.14 23.8056 121.998 23.7504 121.872 23.6398C121.761 23.5135 121.706 23.3714 121.706 23.2135V22.3136C120.885 23.4662 119.575 24.0425 117.775 24.0425Z"
          fill="black"
        />
        <path
          d="M128.305 23.8056C128.147 23.8056 128.005 23.7504 127.879 23.6398C127.768 23.5135 127.713 23.3714 127.713 23.2135V7.58233C127.713 7.40865 127.768 7.26654 127.879 7.15602C128.005 7.0455 128.147 6.99023 128.305 6.99023H131.052C131.226 6.99023 131.368 7.0455 131.479 7.15602C131.589 7.26654 131.644 7.40865 131.644 7.58233V15.5164L134.936 11.8928C134.968 11.8612 135.023 11.8059 135.102 11.727C135.197 11.648 135.292 11.5928 135.386 11.5612C135.481 11.5138 135.592 11.4901 135.718 11.4901H138.892C139.034 11.4901 139.152 11.5454 139.247 11.6559C139.357 11.7506 139.413 11.8691 139.413 12.0112C139.413 12.1848 139.35 12.3191 139.223 12.4138L134.96 17.0084L139.815 22.9057C139.942 23.032 140.005 23.1504 140.005 23.2609C140.005 23.4188 139.95 23.553 139.839 23.6635C139.744 23.7583 139.618 23.8056 139.46 23.8056H136.215C136.01 23.8056 135.852 23.7741 135.742 23.7109C135.647 23.6477 135.529 23.5451 135.386 23.403L131.644 18.9031V23.2135C131.644 23.3714 131.589 23.5135 131.479 23.6398C131.368 23.7504 131.226 23.8056 131.052 23.8056H128.305Z"
          fill="black"
        />
        <path
          d="M36.91 23.8647C36.7521 23.8647 36.61 23.8094 36.4837 23.6989C36.3731 23.5726 36.3179 23.4305 36.3179 23.2726V7.87822C36.3179 7.70454 36.3731 7.56244 36.4837 7.45192C36.61 7.34139 36.7521 7.28613 36.91 7.28613H39.8941C40.0678 7.28613 40.2099 7.34139 40.3204 7.45192C40.4309 7.56244 40.4862 7.70454 40.4862 7.87822V13.136L44.8677 7.73612C45.0571 7.43613 45.365 7.28613 45.7913 7.28613H49.2018C49.3439 7.28613 49.4623 7.34139 49.557 7.45192C49.6518 7.54665 49.6991 7.65718 49.6991 7.78349C49.6991 7.89401 49.6754 7.98875 49.6281 8.06769L43.944 15.2201L50.0544 23.0831C50.1175 23.1463 50.1491 23.241 50.1491 23.3673C50.1491 23.4936 50.1017 23.612 50.007 23.7226C49.9123 23.8173 49.7939 23.8647 49.6518 23.8647H46.1229C45.744 23.8647 45.4282 23.7068 45.1756 23.391L40.4862 17.5411V23.2726C40.4862 23.4462 40.4309 23.5884 40.3204 23.6989C40.2099 23.8094 40.0678 23.8647 39.8941 23.8647H36.91Z"
          fill="#F0783A"
        />
        <path
          d="M55.1121 24.1015C53.7227 24.1015 52.6017 23.6357 51.7491 22.7042C50.8964 21.7568 50.4701 20.4384 50.4701 18.749V12.1413C50.4701 11.9676 50.5254 11.8255 50.6359 11.715C50.7464 11.6044 50.8885 11.5492 51.0622 11.5492H54.0227C54.1964 11.5492 54.3385 11.6044 54.449 11.715C54.5753 11.8255 54.6385 11.9676 54.6385 12.1413V18.6069C54.6385 20.1542 55.3253 20.9279 56.6989 20.9279C57.3621 20.9279 57.8831 20.7226 58.2621 20.3121C58.641 19.9016 58.8305 19.3332 58.8305 18.6069V12.1413C58.8305 11.9676 58.8857 11.8255 58.9963 11.715C59.1226 11.6044 59.2647 11.5492 59.4226 11.5492H62.383C62.5567 11.5492 62.6988 11.6044 62.8093 11.715C62.9198 11.8255 62.9751 11.9676 62.9751 12.1413V23.2726C62.9751 23.4305 62.9198 23.5726 62.8093 23.6989C62.6988 23.8094 62.5567 23.8647 62.383 23.8647H59.6357C59.4778 23.8647 59.3357 23.8094 59.2094 23.6989C59.0989 23.5726 59.0436 23.4305 59.0436 23.2726V22.3726C58.2226 23.5252 56.9121 24.1015 55.1121 24.1015Z"
          fill="#F0783A"
        />
        <path
          d="M65.6425 23.8647C65.4688 23.8647 65.3267 23.8094 65.2162 23.6989C65.1056 23.5884 65.0504 23.4462 65.0504 23.2726V12.1413C65.0504 11.9676 65.1056 11.8255 65.2162 11.715C65.3267 11.6044 65.4688 11.5492 65.6425 11.5492H68.3661C68.5398 11.5492 68.6819 11.6044 68.7924 11.715C68.9187 11.8255 68.9819 11.9676 68.9819 12.1413V13.0413C69.4082 12.5202 69.9529 12.1018 70.616 11.786C71.295 11.4702 72.0607 11.3123 72.9133 11.3123C74.3028 11.3123 75.4317 11.786 76.3001 12.7334C77.1685 13.6649 77.6027 14.9754 77.6027 16.6648V23.2726C77.6027 23.4305 77.5474 23.5726 77.4369 23.6989C77.3264 23.8094 77.1843 23.8647 77.0106 23.8647H74.0265C73.8686 23.8647 73.7265 23.8094 73.6002 23.6989C73.4896 23.5726 73.4344 23.4305 73.4344 23.2726V16.8069C73.4344 16.0649 73.2528 15.4965 72.8897 15.1017C72.5265 14.6912 72.0055 14.486 71.3265 14.486C70.6634 14.486 70.1424 14.6912 69.7634 15.1017C69.3845 15.4965 69.195 16.0649 69.195 16.8069V23.2726C69.195 23.4305 69.1398 23.5726 69.0292 23.6989C68.9187 23.8094 68.7766 23.8647 68.6029 23.8647H65.6425Z"
          fill="#F0783A"
        />
      </svg>
    );
  }
}
