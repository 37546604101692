import { urls, toastOption } from "../../config";
import { API } from "../../utils";
import { toast } from "react-toastify";
import i18n from "../../i18n";

import {
  GET_ASSIGNMENTS_REQUEST,
  GET_ASSIGNMENTS_SUCCESS,
  GET_ASSIGNMENTS_ERROR,
} from "../actionTypes";

export const getAssignmentsByClassIdCourseId = (values) => async (dispatch) => {
  dispatch(getAssignmentsRequest());

  await API.get(
    `${urls.ASSIGNMENT}instructor/${
      JSON.parse(localStorage.getItem("person"))?.id
    }/class/${values.classId}/course/${values.courseId}/chronicle/${
      JSON.parse(localStorage.getItem("chronicleActive"))?.id
    }?page=${values.pageNumber - 1 || 0}`
  )
    .then((res) => {
      console.log(res.data);
      dispatch(getAssignmentsSuccess(res.data));
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getAssignmentsFailure(error.message));
      return error.message;
    });
};
export const removeAssignment = (id) => async (dispatch) => {
  dispatch(getAssignmentsRequest());
  await API.delete(`${urls.ASSIGNMENT}?id=${id}`)
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess deleted"), toastOption);
      // dispatch(getAssignments());
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getAssignmentsFailure(error.message));
      return error.message;
    });
};
export const editAssignment = (values) => async (dispatch) => {
  dispatch(getAssignmentsRequest());
  let bodyFormData = new FormData();
  bodyFormData.append("calendarTopicId", values.calendarTopicId);
  bodyFormData.append("content", values.content);
  bodyFormData.append("deadlineAt", values.deadlineAt);
  bodyFormData.append("id", values.id);
  values?.files?.map((item) => {
    bodyFormData.append("files", item);
  });

  await API.post(`${urls.ASSIGNMENT}edit/${values.id}`, bodyFormData, {
    headers: { "Content-Type": "multipart/form-data" },
  })
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess updated"), toastOption);
      // dispatch(getAssignments());
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getAssignmentsFailure(error.message));
      return error.message;
    });
};

export const addAssignment = (values) => async () => {
  let bodyFormData = new FormData();
  bodyFormData.append("calendarTopicId", values.calendarTopicId);
  bodyFormData.append("content", values.content);
  bodyFormData.append("deadlineAt", values.deadlineAt);
  bodyFormData.append("title", values.title);
  values?.files?.map((item) => {
    bodyFormData.append("files", item);
  });

  await API.post(`${urls.ASSIGNMENT}create`, bodyFormData, {
    headers: { "Content-Type": "multipart/form-data" },
  })
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess added"), toastOption);
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      return error.message;
    });
};
export const addAssignmentTopicless = (values) => async () => {
  let bodyFormData = new FormData();
  // bodyFormData.append("calendarTopicId", values.calendarTopicId);
  bodyFormData.append("content", values.content);
  bodyFormData.append("deadlineAt", values.deadlineAt);
  bodyFormData.append("title", values.title);
  bodyFormData.append("courseId", values.courseId);
  bodyFormData.append("classId", values.classId);
  bodyFormData.append(
    "chronicleId",
    JSON.parse(localStorage.getItem("chronicleActive"))?.id
  );
  bodyFormData.append(
    "instructorId",
    JSON.parse(localStorage.getItem("person"))?.id
  );
  bodyFormData.append("quarterId", values.quarterId);
  values?.files?.map((item) => {
    bodyFormData.append("files", item);
  });

  await API.post(`${urls.ASSIGNMENT}topicless/create`, bodyFormData, {
    headers: { "Content-Type": "multipart/form-data" },
  })
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess added"), toastOption);
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      return error.message;
    });
};

export const removeFiles = (values) => async () => {
  // dispatch(getAssignmentsRequest());
  const data = values;
  await API.delete(`${urls.ASSIGNMENT}files`, { data })
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess deleted"), toastOption);
      // dispatch(getAssignments());
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      // dispatch(getAssignmentsFailure(error.message));
      return error.message;
    });
};
export const getAssignmentsRequest = () => {
  return {
    type: GET_ASSIGNMENTS_REQUEST,
  };
};

export const getAssignmentsSuccess = (data) => {
  return {
    type: GET_ASSIGNMENTS_SUCCESS,
    payload: data,
  };
};

export const getAssignmentsFailure = (error) => {
  return {
    type: GET_ASSIGNMENTS_ERROR,
    payload: error,
  };
};
