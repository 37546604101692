import React from "react";
import Steps, { Step } from "rc-steps";
import PT from "prop-types";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "../../../node_modules/rc-steps/assets/index.css";
import "./Layout.scss";
import { SchoolPresettingFooter, SchoolAdminHeader, GoBack } from "../";

export default function AutoScheduleLayout(props) {
  const { children, id } = props;
  const history = useHistory();
  const { t } = useTranslation(["presetting", "common"]);
  return (
    <div className="school-presetting">
      <SchoolAdminHeader />
      <div className="school-presetting__body school-presetting__container">
        <GoBack
          // title={t(
          //   "presetting:return to home screen presetting school regulations"
          // )}
          title="Назад к расписанию"
          link="/school-admin/schedule"
        />
        <Steps current={id - 1} labelPlacement="vertical">
          <Step
            title={t("presetting:classes presetting")}
            disabled={id <= 1}
            onStepClick={() => {
              history.push("/school-admin/schedule/auto/1");
            }}
          />
          <Step
            title={t("presetting:teachers presetting")}
            disabled={id <= 2}
            onStepClick={() => {
              history.push("/school-admin/schedule/auto/2");
            }}
          />
          <Step
            title={t("presetting:lessons")}
            disabled={id <= 3}
            onStepClick={() => {
              history.push("/school-admin/schedule/auto/3");
            }}
          />
          <Step
            title={t("schoolAdmin:auto schedule")}
            disabled={id <= 4}
            onStepClick={() => {
              history.push("/school-admin/schedule/auto/4");
            }}
          />
        </Steps>
        {children}
      </div>
      <SchoolPresettingFooter />
    </div>
  );
}
AutoScheduleLayout.propTypes = {
  children: PT.any,
  id: PT.number,
};
