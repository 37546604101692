/* eslint-disable indent */
import React, { useEffect, useState, useCallback } from "react";
import { debounce } from "lodash";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Edit2, Trash, X, Check } from "react-feather";
import Modal from "react-modal";
import moment from "moment";
import { useTranslation } from "react-i18next";
import ReactExport from "react-export-excel";
import SelectSearch, { fuzzySearch } from "react-select-search";
import Tooltip from "react-simple-tooltip";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

import {
  InstractorLayout,
  PaginationBlock,
  SearchField,
  DatePickerCalendar,
  GoBack,
  NoData,
} from "../../components";
// import { getShifts } from "../../store/shift/actions";
import {
  removeCalendarTopic,
  addCalendarTopic,
  editCalendarTopic as editCalendarTopicAction,
  getCalendarTopicById,
  getCalendarTopicsByPlanId,
} from "../../store/calendarTopic/actions";
import { getCalendarPlanById } from "../../store/calendarPlan/actions";
import { getQuarters } from "../../store/quarter/actions";

export default function Topic() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation([
    "presetting",
    "schoolAdmin",
    "instructor",
  ]);
  const { ctp_id } = useParams();

  const calendarTopicsStore = useSelector(
    (store) => store.calendarTopics.calendarTopics
  );
  const calendarPlanStore = useSelector(
    (store) => store.calendarPlans.calendarPlan
  );
  // const schedulesStore = useSelector((store) => store.schedules.schedules);
  const quartersStore = useSelector((store) => store.quarters.quarters);

  // const classStore = useSelector((store) =>
  //   store.class.class
  //     .sort((a, b) => a.classLabel.localeCompare(b.classLabel))
  //     .sort((a, b) => a.classLevel - b.classLevel)
  // );
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [currentData, setCurrentData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [selectedData, setSelectedData] = useState([]);
  const [searchedCurrentData, setSearchedCurrentData] = useState([]);
  const [filteredAllData, setFilteredAllData] = useState([]);
  const [currentCalendarTopicId, setCurrentCalendarTopicId] = useState();
  const [editCalendarTopic, setEditCalendarPlan] = useState({
    title: "",
  });

  const [modal, setModal] = useState({
    createCalendarTopic: false,
    editCalendarTopic: false,
    export: false,
  });
  const [newCalendarPlan, setNewCalendarPlan] = useState({
    calendarPlanId: ctp_id,
    datePlan: "",
    hours: "",
    quarterId: "",
    title: "",
    topicResult: "",
    topicVisibility: "",
    isPassed: false,
  });

  useEffect(async () => {
    await dispatch(getCalendarPlanById(ctp_id));
    await dispatch(getCalendarTopicsByPlanId(ctp_id));
  }, []);
  const onPageChanged = (
    pageNumber,
    perPageNumber,
    data = calendarTopicsStore
  ) => {
    const offset = (pageNumber - 1) * perPageNumber;
    const currentDataArr = data.slice(offset, offset + perPageNumber);
    // setFilteredAllData(currentDataArr);
    return setCurrentData(currentDataArr);
  };
  const createCalendarTopic = async () => {
    await dispatch(addCalendarTopic(newCalendarPlan));
    await dispatch(getCalendarTopicsByPlanId(ctp_id));
    await dispatch(getCalendarPlanById(ctp_id));

    setModal({ ...modal, createCalendarTopic: false });
    setNewCalendarPlan({
      calendarPlanId: ctp_id,
      datePlan: "",
      hours: "",
      quarterId: "",
      title: "",
      topicResult: "",
      topicVisibility: "",
    });
    await setFilteredAllData([]);
    await setSearchedCurrentData([]);
    await setCurrentData([]);
    await setSelectedData([]);
    await setSearchText("");
  };
  const updateCalendarTopic = async () => {
    await dispatch(editCalendarTopicAction(editCalendarTopic));
    await dispatch(getCalendarTopicsByPlanId(ctp_id));
    await dispatch(getCalendarPlanById(ctp_id));

    await setModal({ ...modal, editCalendarTopic: false });
    await setEditCalendarPlan({
      title: "",
    });

    await setFilteredAllData([]);
    await setSearchedCurrentData([]);
    await setCurrentData([]);
    await setSelectedData([]);
    await setSearchText("");
    // await onPageChanged(1, perPage, calendarTopicsStore, filterTypeNumber);
  };

  const onInputChange = (e) => {
    if ((e.key || e.target.name) == "hours") {
      let { value, min, max } = e.target;
      value = Math.max(Number(min), Math.min(Number(max), Number(value)));
      console.log(value);
      // this.setState({ value });
      setNewCalendarPlan({
        ...newCalendarPlan,
        hours: String(value),
      });
    } else {
      setNewCalendarPlan({
        ...newCalendarPlan,
        [e.key || e.target.name]: e.value || e.target.value,
      });
    }
  };
  const onEditInputChange = (e) => {
    setEditCalendarPlan({
      ...editCalendarTopic,
      [e.key || e.target.name]: e.value || e.target.value,
    });
  };

  const debouncedPerPage = useCallback(
    debounce((text, calendarTopicsStore) => {
      if (filteredAllData.length > 0) {
        onPageChanged(1, Number(text), filteredAllData);
      } else {
        onPageChanged(1, Number(text), calendarTopicsStore);
      }
      setPerPage(Number(text));
      setCurrentPage(1);
    }, 1000),
    [calendarTopicsStore, filteredAllData]
  );

  const debouncedSearch = useCallback(
    debounce((text) => {
      setSearchText(text.toLowerCase());
      setCurrentPage(1);

      const a = calendarTopicsStore.filter((item) => {
        const a = String(`${item.title}`).toLowerCase();
        const c = a.includes(text.toLowerCase());

        const b = String(`${item.quarterSemester}`).toLowerCase();
        const d = b.includes(text.toLowerCase());
        if (c) {
          return c;
        } else if (d) {
          return d;
        }
      });
      setSearchedCurrentData([...a]);
      onPageChanged(1, perPage, [...a]);
    }, 1000),
    [calendarTopicsStore, perPage]
  );
  const onChange = async (e) => {
    console.log(e);
    setCurrentPage(e);
    if (searchedCurrentData.length > 0) {
      onPageChanged(e, perPage, searchedCurrentData);
    } else if (filteredAllData.length > 0) {
      onPageChanged(e, perPage, filteredAllData);
    } else {
      onPageChanged(e, perPage, calendarTopicsStore);
    }
  };
  const getPerPage = (e) => {
    debouncedPerPage(e.target.value);
  };
  const searchInputHandler = (e) => {
    debouncedSearch(e.target.value);
  };

  const removeItem = async (value) => {
    await dispatch(removeCalendarTopic(value.id));
    await dispatch(getCalendarTopicsByPlanId(ctp_id));
    await dispatch(getCalendarPlanById(ctp_id));
  };

  const onSelect = (value) => {
    if (selectedData.find((item) => item.id === value.id)) {
      let a = selectedData.filter((item) => item.id !== value.id);
      setSelectedData([...a]);
    } else {
      setSelectedData([...selectedData, value]);
    }
  };
  const clearSelected = () => {
    setSelectedData([]);
  };
  const removeSelected = async (data) => {
    // let a = calendarTopicsStore;
    await data.map((item) => {
      dispatch(removeCalendarTopic(item.id, ctp_id));
    });
    await dispatch(getCalendarPlanById(ctp_id));

    setSelectedData([]);
    // await dispatch(getCalendarTopicsByPlanId(ctp_id));

    // setAllData(a);
    // onPageChanged(currentPage, perPage);
  };
  // const classAndCourseRender = schedulesStore
  //   .filter(
  //     (thing, index, self) =>
  //       index ===
  //       self.findIndex(
  //         (t) => t.courseId === thing.courseId && t.classId === thing.classId
  //       )
  //   )
  //   .map((item) => ({
  //     name: `${item.classTitle} - ${
  //       String(i18n.language).toUpperCase() !== "EN" &&
  //       String(i18n.language).toUpperCase() !== "TR"
  //         ? `${item[`courseTitle${String(i18n.language).toUpperCase()}`]} `
  //         : `${item.courseTitle} `
  //     }`,
  //     value: [item.classId, item.courseId],
  //     key: "classId",
  //   }));

  const quartersRender = quartersStore.map((item) => ({
    name: `${item.quarter} ${t("instructor:quarter")}`,
    value: `${item.id}`,
    key: "quarterId",
  }));
  const isPassedRender = [
    {
      name: `${t("common:yes")}`,
      value: `${true}`,
      key: "isPassed",
    },
    {
      name: `${t("common:no")}`,
      value: `${false}`,
      key: "isPassed",
    },
  ];

  const tableDataRender =
    searchedCurrentData.length > 0
      ? searchedCurrentData.map((item, idx) => (
          <tr
            className={
              selectedData.find((i) => i.id === item.id) ? "active" : ""
            }
            key={idx}
          >
            <td className="table-select">
              <label className="check">
                <input
                  type="checkbox"
                  onChange={() => onSelect(item)}
                  checked={selectedData.find((i) => i.id === item.id)}
                />
                <div className="box"></div>
              </label>
              <span>{idx + 1}</span>
            </td>
            <td>{item.title}</td>
            <td>{item.topicHour}</td>
            <td>{item.topicDate}</td>
            <td>{item.quarterSemester}</td>
            <td>
              <div style={{ justifyContent: "center" }}>
                <button style={{ cursor: "default" }}>
                  {item.isPassed ? (
                    <Check color="#14A38B" />
                  ) : (
                    // <span style={{ color: "#14A38B" }}>✓</span>
                    <X color="#FF7171" />
                  )}
                </button>
              </div>
            </td>
            <td>
              <div>
                <Tooltip className="tooltip" content={t("common:edit")}>
                  <button
                    onClick={async () => {
                      await setCurrentCalendarTopicId(item.id);
                      await setModal({ ...modal, editCalendarTopic: true });
                    }}
                  >
                    <Edit2 color="#14A38B" />
                  </button>
                </Tooltip>
                <Tooltip className="tooltip" content={t("common:delete")}>
                  <button onClick={() => removeItem(item)}>
                    <Trash color="#FF7171" />
                  </button>
                </Tooltip>
              </div>
            </td>
          </tr>
        ))
      : currentData.length > 0
      ? currentData.map((item, idx) => (
          <tr
            className={
              selectedData.find((i) => i.id === item.id) ? "active" : ""
            }
            key={idx}
          >
            <td className="table-select">
              <label className="check">
                <input
                  type="checkbox"
                  onChange={() => onSelect(item)}
                  checked={selectedData.find((i) => i.id === item.id)}
                />
                <div className="box"></div>
              </label>
              <span>{idx + 1}</span>
            </td>
            <td>{item.title}</td>
            <td>{item.topicHour}</td>
            <td>{item.topicDate}</td>
            <td>{item.quarterSemester}</td>
            <td>
              <div style={{ justifyContent: "center" }}>
                <button style={{ cursor: "default" }}>
                  {item.isPassed ? (
                    <Check color="#14A38B" />
                  ) : (
                    // <span style={{ color: "#14A38B" }}>✓</span>
                    <X color="#FF7171" />
                  )}
                </button>
              </div>
            </td>
            <td>
              <div>
                <Tooltip className="tooltip" content={t("common:edit")}>
                  <button
                    onClick={async () => {
                      await setCurrentCalendarTopicId(item.id);
                      await setModal({ ...modal, editCalendarTopic: true });
                    }}
                  >
                    <Edit2 color="#14A38B" />
                  </button>
                </Tooltip>
                <Tooltip className="tooltip" content={t("common:delete")}>
                  <button onClick={() => removeItem(item)}>
                    <Trash color="#FF7171" />
                  </button>
                </Tooltip>
              </div>
            </td>
          </tr>
        ))
      : calendarTopicsStore.slice([0], [10]).map((item, idx) => (
          <tr
            className={
              selectedData.find((i) => i.id === item.id) ? "active" : ""
            }
            key={idx}
          >
            <td className="table-select">
              <label className="check">
                <input
                  type="checkbox"
                  onChange={() => onSelect(item)}
                  checked={selectedData.find((i) => i.id === item.id)}
                />
                <div className="box"></div>
              </label>
              <span>{idx + 1}</span>
            </td>
            <td>{item.title}</td>
            <td>{item.topicHour}</td>
            <td>{item.topicDate}</td>
            <td>{item.quarterSemester}</td>
            <td>
              <div style={{ justifyContent: "center" }}>
                <button style={{ cursor: "default" }}>
                  {item.isPassed ? (
                    <Check color="#14A38B" />
                  ) : (
                    // <span style={{ color: "#14A38B" }}>✓</span>
                    <X color="#FF7171" />
                  )}
                </button>
              </div>
            </td>
            <td>
              <div>
                <Tooltip className="tooltip" content={t("common:edit")}>
                  <button
                    onClick={async () => {
                      await setCurrentCalendarTopicId(item.id);
                      await setModal({ ...modal, editCalendarTopic: true });
                    }}
                  >
                    <Edit2 color="#14A38B" />
                  </button>
                </Tooltip>
                <Tooltip className="tooltip" content={t("common:delete")}>
                  <button onClick={() => removeItem(item)}>
                    <Trash color="#FF7171" />
                  </button>
                </Tooltip>
              </div>
            </td>
          </tr>
        ));
  return (
    <div>
      <InstractorLayout>
        <GoBack
          title={`${t("schoolAdmin:back to list")}`}
          link="/instructor/ctp"
        />
        <div className="school-presetting__body school-presetting__body-color">
          <div className="school-presetting__body-row">
            <div className="school-presetting__body-title">
              <h2>{calendarPlanStore.title}</h2>
            </div>
            <div className="school-presetting__body-description">
              <p className="body-1">{t("instructor:topic description")}</p>
              <br />
              <p className="body-1 roboto">
                {t("instructor:total topics")}:{" "}
                {calendarPlanStore.topicTotalCount}
                {"         "}
                {t("instructor:total hours")}:{" "}
                {calendarPlanStore.topicTotalHour}
              </p>
            </div>
          </div>
          <div className="school-presetting__subjects-list">
            <div className="school-presetting__search-filter">
              <div className="school-presetting__search">
                <SearchField
                  onChange={searchInputHandler}
                  defaultValue={searchText}
                  placeholder={t("common:search")}
                />
              </div>
              <div className="school-presetting__filter">
                <button
                  id="create_class"
                  className="primary small"
                  onClick={() =>
                    setModal({ ...modal, createCalendarTopic: true })
                  }
                >
                  {t("common:create")}
                </button>
              </div>
            </div>
            <div className="school-presetting__teachers-body">
              <div className="school-presetting__teachers-table">
                {calendarTopicsStore.length > 0 ? (
                  searchText !== "" && searchedCurrentData.length == 0 ? (
                    <NoData
                      messages={[`${t("schoolAdmin:here is nothing")}`]}
                    />
                  ) : (
                    <>
                      <table>
                        <thead>
                          <tr>
                            <th>№</th>
                            <th>{t("instructor:topic")}</th>
                            <th>{t("instructor:number of hours")}</th>
                            <th>{t("instructor:date according to plan")}</th>
                            <th>{t("instructor:quarter")}</th>
                            <th style={{ textAlign: "center" }}>
                              {t("instructor:passed")}
                            </th>
                            <th>{t("presetting:action presetting")}</th>
                          </tr>
                        </thead>
                        <tbody>{tableDataRender}</tbody>
                      </table>
                      {selectedData.length > 0 && (
                        <div className="edit-table">
                          <div className="edit-table-left">
                            <div>
                              <p className="caption-1">
                                {t("presetting:choosen presetting")}:
                                <span>{selectedData.length}</span>
                              </p>
                            </div>
                            <div>
                              <button onClick={clearSelected}>
                                {t("presetting:consel presetting")}
                              </button>
                            </div>
                          </div>
                          <div className="edit-table-right">
                            <div>
                              <button
                                onClick={() => {
                                  removeSelected(selectedData);
                                }}
                              >
                                {t("presetting:delete presetting")}
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )
                ) : (
                  <NoData messages={[`${t("schoolAdmin:here is nothing")}`]} />
                )}
              </div>
            </div>
            <div className="pagination">
              <div className="paginaiton__inner">
                <div className="pagination__per-page">
                  <p>
                    {t("presetting:showing presetting")}{" "}
                    <input
                      type="phone"
                      defaultValue={perPage}
                      onChange={getPerPage}
                    />{" "}
                    {t("presetting:from presetting")}{" "}
                    {searchedCurrentData.length > 0
                      ? searchedCurrentData.length
                      : filteredAllData.length > 0
                      ? filteredAllData.length
                      : calendarTopicsStore.length}{" "}
                    {t("presetting:lines presetting")}
                  </p>
                </div>
                <div className="pagination__page">
                  <PaginationBlock
                    total={
                      searchedCurrentData.length > 0
                        ? searchedCurrentData.length
                        : filteredAllData.length > 0
                        ? filteredAllData.length
                        : calendarTopicsStore.length
                    }
                    current={currentPage}
                    onChange={onChange}
                    pageSize={perPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          isOpen={modal.createCalendarTopic}
          onRequestClose={() => {
            setModal({ ...modal, createCalendarTopic: false });
            setNewCalendarPlan({
              calendarPlanId: ctp_id,
              datePlan: "",
              hours: "",
              quarterId: "",
              title: "",
              topicResult: "",
              topicVisibility: "",
            });
          }}
          overlayClassName="modal-overlay"
          onAfterOpen={async () => {
            await dispatch(getQuarters());
          }}
        >
          <div className="modal">
            <div className="modal-close">
              <button
                onClick={() => {
                  setModal({ ...modal, createCalendarTopic: false });
                  setNewCalendarPlan({
                    calendarPlanId: ctp_id,
                    datePlan: "",
                    hours: "",
                    quarterId: "",
                    title: "",
                    topicResult: "",
                    topicVisibility: "",
                  });
                }}
              >
                <X size={15} color="#9A9A9A" />
              </button>
            </div>
            <div className="modal-header">
              <div className="modal-header-title">
                <h2>{t("instructor:new topic")}</h2>
              </div>
            </div>
            <div className="modal-body">
              <div className="modal-inputs-list">
                <div className="modal-input">
                  <input
                    type="text"
                    placeholder={t("instructor:write topic name")}
                    name="title"
                    defaultValue={newCalendarPlan?.title}
                    onChange={onInputChange}
                    required
                  />
                </div>
                <div className="modal-input">
                  <SelectSearch
                    options={quartersRender}
                    search
                    filterOptions={fuzzySearch}
                    className="select-search drop-up small"
                    name="quarterId"
                    value={newCalendarPlan?.quarterId}
                    onChange={(_, k) => {
                      onInputChange(k);
                    }}
                    required
                    placeholder={`${t("instructor:choose quarter")}`}
                  />
                </div>
                <div className="modal-input">
                  <input
                    type="text"
                    placeholder={t("instructor:number of hours")}
                    name="hours"
                    value={newCalendarPlan.hours}
                    onChange={onInputChange}
                    min={0}
                    max={100}
                    required
                  />
                </div>
                <div className="modal-input">
                  <DatePickerCalendar
                    placeholder={`${t(
                      "instructor:choose a date according to plan"
                    )}`}
                    onSelect={onInputChange}
                    defaultValue={newCalendarPlan?.datePlan}
                    name="datePlan"
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                id="create_class_modal"
                className="large primary"
                onClick={() => createCalendarTopic()}
                disabled={
                  newCalendarPlan?.title === "" ||
                  newCalendarPlan?.courseId === "" ||
                  newCalendarPlan?.classId === ""
                }
              >
                {t("common:create")}
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={modal.editCalendarTopic}
          onRequestClose={() => {
            setModal({ ...modal, editCalendarTopic: false });
            setEditCalendarPlan({
              classType: "PRIMARY",
              classLabel: "",
              classLevel: "",
              languageId: "",
              shiftId: "",
              personId: "",
            });
          }}
          overlayClassName="modal-overlay"
          onAfterOpen={async () => {
            await dispatch(getQuarters());

            await dispatch(
              getCalendarTopicById(currentCalendarTopicId, setEditCalendarPlan)
            );
          }}
        >
          <div className="modal">
            <div className="modal-close">
              <button
                onClick={() => {
                  setModal({ ...modal, editCalendarTopic: false });
                  setEditCalendarPlan({
                    classType: "PRIMARY",
                    classLabel: "",
                    classLevel: "",
                    languageId: "",
                    shiftId: "",
                    personId: "",
                  });
                }}
              >
                <X size={15} color="#9A9A9A" />
              </button>
            </div>
            <div className="modal-header">
              <div className="modal-header-title">
                <h2>{t("instructor:edit topic")}</h2>
              </div>
            </div>
            <div className="modal-body">
              <div className="modal-inputs-list">
                <div className="modal-input">
                  <input
                    type="text"
                    placeholder={t("instructor:write topic name")}
                    name="title"
                    defaultValue={editCalendarTopic?.title}
                    onChange={onEditInputChange}
                    required
                  />
                </div>
                <div className="modal-input">
                  <SelectSearch
                    options={quartersRender}
                    search
                    filterOptions={fuzzySearch}
                    className="select-search drop-up small"
                    name="quarterId"
                    value={editCalendarTopic?.quarterId}
                    onChange={(_, k) => {
                      onEditInputChange(k);
                    }}
                    required
                    placeholder={`${t("instructor:choose quarter")}`}
                  />
                </div>
                <div className="modal-input">
                  <input
                    type="text"
                    placeholder={t("instructor:number of hours")}
                    name="hours"
                    defaultValue={editCalendarTopic?.hours}
                    onChange={onEditInputChange}
                    required
                  />
                </div>
                <div className="modal-input">
                  {editCalendarTopic?.datePlan && (
                    <DatePickerCalendar
                      placeholder={`${t(
                        "instructor:choose a date according to plan"
                      )}`}
                      onSelect={onEditInputChange}
                      defaultValue={moment(
                        editCalendarTopic?.datePlan,
                        "YYYY-MM-DD"
                      )}
                      name="datePlan"
                    />
                  )}
                </div>
                <div className="modal-input">
                  <SelectSearch
                    options={isPassedRender}
                    search
                    filterOptions={fuzzySearch}
                    className="select-search drop-up small"
                    name="isPassed"
                    value={String(editCalendarTopic?.isPassed)}
                    onChange={(_, k) => {
                      onEditInputChange(k);
                    }}
                    required
                    placeholder={`${t("instructor:passed")}`}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                className="large primary"
                onClick={async () => {
                  await updateCalendarTopic();
                }}
              >
                {t("common:save")}
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={modal.export}
          onRequestClose={() => {
            setModal({ ...modal, export: false });
          }}
          overlayClassName="modal-overlay"
          // onAfterOpen={async () => {
          //   // await dispatch(getParentsByCalendarPlanId(currentCalendarTopicId));
          // }}
        >
          <div className="modal">
            <div className="modal-close">
              <button
                onClick={() => {
                  setModal({ ...modal, export: false });
                }}
              >
                <X size={15} color="#9A9A9A" />
              </button>
            </div>
            <div className="modal-header">
              <div className="modal-header-title">
                <h2>{t("schoolAdmin:export")}</h2>
              </div>
            </div>

            <div className="modal-footer" style={{ marginTop: "24px" }}>
              <ExcelFile
                filename={`${
                  JSON.parse(localStorage.getItem("school"))?.name
                } - Календарно-тематический план`}
                element={
                  <button className="large primary">
                    {t("schoolAdmin:export")}
                  </button>
                }
              >
                <ExcelSheet
                  data={calendarTopicsStore}
                  name={"Календарный план"}
                >
                  <ExcelColumn label={"Класс"} value="classTitle" />
                  <ExcelColumn
                    label={"Предмет"}
                    value={
                      String(i18n.language).toUpperCase() !== "EN" &&
                      String(i18n.language).toUpperCase() !== "TR"
                        ? `courseTitle${String(i18n.language).toUpperCase()}`
                        : `courseTitle`
                    }
                  />
                  <ExcelColumn label={`Название КТП`} value="title" />
                </ExcelSheet>
              </ExcelFile>
            </div>
          </div>
        </Modal>
      </InstractorLayout>
    </div>
  );
}
