import {
  GET_SCHEDULE_GROUPS_REQUEST,
  GET_SCHEDULE_GROUPS_SUCCESS,
  GET_SCHEDULE_GROUPS_ERROR,
  GET_SCHEDULE_GROUP_REQUEST,
  GET_SCHEDULE_GROUP_SUCCESS,
  GET_SCHEDULE_GROUP_ERROR,
} from "../actionTypes";
import { statuses } from "../../config";

const initialState = {
  scheduleGroups: [],
  scheduleGroup: [],
  error: "",
  status: statuses.INITIAL,
};

export const scheduleGroupReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SCHEDULE_GROUPS_REQUEST:
      return {
        ...state,
        scheduleGroups: [],
        error: "",
        status: statuses.LOADING,
      };
    case GET_SCHEDULE_GROUPS_SUCCESS:
      return {
        ...state,
        scheduleGroups: action.payload,
        error: "",
        status: statuses.SUCCESS,
      };
    case GET_SCHEDULE_GROUPS_ERROR:
      return {
        ...state,
        scheduleGroups: [],
        error: action.payload,
        status: statuses.ERROR,
      };
    case GET_SCHEDULE_GROUP_REQUEST:
      return {
        ...state,
        scheduleGroup: [],
        error: "",
        status: statuses.LOADING,
      };
    case GET_SCHEDULE_GROUP_SUCCESS:
      return {
        ...state,
        scheduleGroup: action.payload,
        error: "",
        status: statuses.SUCCESS,
      };
    case GET_SCHEDULE_GROUP_ERROR:
      return {
        ...state,
        scheduleGroup: [],
        error: action.payload,
        status: statuses.ERROR,
      };

    default:
      return state;
  }
};
