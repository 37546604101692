/* eslint-disable indent */
import React, { useEffect, useState, useCallback } from "react";
import { debounce } from "lodash";
import { X, Plus } from "react-feather";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Tooltip from "react-simple-tooltip";

import { SchoolPresettingLayout, SearchField, NoData } from "../../components";
import { getAdminCourses } from "../../store/adminCourse/actions";
import { stepUpSchoolPresetting } from "../../store/schoolPresetting/actions";
import {
  addCourse,
  getCourses,
  removeCourese,
} from "../../store/course/actions";
import { statuses } from "../../config";

export default function SchoolPresetting3() {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const adminCoursesStore = useSelector(
    (store) => store.adminCourses.adminCourses
  );
  const coursesStore = useSelector((store) => store.courses.courses);
  const adminCoursesStoreStatus = useSelector(
    (store) => store.adminCourses.status
  );

  const coursesStoreStatus = useSelector((store) => store.courses.status);

  const [courseTypeFilter, setCourseTypeFilter] = useState("GENERAL");
  const [searchText, setSearchText] = useState("");
  const [searchedSelectedData, setSearchedSelectedData] = useState([]);
  const [searchedCurrentData, setSearchedCurrentData] = useState([]);

  useEffect(async () => {
    await dispatch(getAdminCourses());
    await dispatch(getCourses());
  }, []);

  const debouncedSearch = useCallback(
    debounce((text) => {
      setSearchText(text);
      const a = adminCoursesStore.filter((item) => {
        if (
          String(i18n.language).toUpperCase() !== "EN" &&
          String(i18n.language).toUpperCase() !== "TR"
        ) {
          return item[
            `title${
              String(i18n.language).charAt(0).toUpperCase() +
              String(i18n.language).slice(1)
            }`
          ]
            .toLowerCase()
            .includes(text.toLowerCase());
        } else {
          return item.title.toLowerCase().includes(text.toLowerCase());
        }
      });
      const b = coursesStore.filter((item) => {
        if (
          String(i18n.language).toUpperCase() !== "EN" &&
          String(i18n.language).toUpperCase() !== "TR"
        ) {
          return item[`title${String(i18n.language).toUpperCase()}`]
            .toLowerCase()
            .includes(text.toLowerCase());
        } else {
          return item.title.toLowerCase().includes(text.toLowerCase());
        }
      });
      setSearchedCurrentData([...a]);
      setSearchedSelectedData([...b]);
      console.log(text);
    }, 1000),
    [adminCoursesStore, coursesStore]
  );
  const onSubmit = () => {
    JSON.parse(localStorage.getItem("schoolPresetting"))?.preset === false &&
      dispatch(
        stepUpSchoolPresetting({
          chronicleId: JSON.parse(localStorage.getItem("chronicleActive"))?.id,
          isPreset: false,
          schoolId: JSON.parse(localStorage.getItem("school"))?.id,
          stepNumber: 4,
        })
      );
  };

  const searchInputHandler = (e) => {
    debouncedSearch(e.target.value);
  };

  const addToSelectedSubject = async (value) => {
    await dispatch(addCourse(value.id));
    await dispatch(getCourses());
  };

  const removeFromSelectedSubject = (value) => {
    dispatch(removeCourese(value.id));
  };

  let addedSubjects =
    coursesStore.length > 0 &&
    coursesStoreStatus === statuses.SUCCESS &&
    (searchedSelectedData.length == 0
      ? coursesStore.map((item, idx) => (
          <div className="school-presetting__subjects-table-item" key={idx}>
            <div className="school-presetting__subjects-table-item-name">
              <p className="body-1">
                {String(i18n.language).toUpperCase() !== "EN" &&
                String(i18n.language).toUpperCase() !== "TR"
                  ? item[`title${String(i18n.language).toUpperCase()}`]
                  : item.title}
              </p>
            </div>
            <div className="school-presetting__subjects-table-item-button">
              <Tooltip
                className="tooltip"
                content={t("common:delete")}
                placement="left"
              >
                <button onClick={() => removeFromSelectedSubject(item)}>
                  <X size={16} color={"#C4C4C4"} />
                </button>
              </Tooltip>
            </div>
          </div>
        ))
      : searchedSelectedData.map((item, idx) => (
          <div className="school-presetting__subjects-table-item" key={idx}>
            <div className="school-presetting__subjects-table-item-name">
              <p className="body-1">
                {String(i18n.language).toUpperCase() !== "EN" &&
                String(i18n.language).toUpperCase() !== "TR"
                  ? item[`title${String(i18n.language).toUpperCase()}`]
                  : item.title}
              </p>
            </div>
            <div className="school-presetting__subjects-table-item-button">
              <Tooltip
                className="tooltip"
                content={t("common:delete")}
                placement="left"
              >
                <button onClick={() => removeFromSelectedSubject(item)}>
                  <X size={16} color={"#C4C4C4"} />
                </button>
              </Tooltip>
            </div>
          </div>
        )));

  let notAddedSubjects =
    adminCoursesStore.length > 0 &&
    adminCoursesStoreStatus === statuses.SUCCESS &&
    (searchedCurrentData.filter((item) => item.courseType === courseTypeFilter)
      .length == 0
      ? adminCoursesStore
          .filter((item) => item.courseType === courseTypeFilter)
          .map((item, idx) => {
            if (
              coursesStore
                .filter((item) => item.courseType === courseTypeFilter)
                .map((el) => el.courseId)
                .includes(item.id)
            ) {
              return null;
            } else {
              return (
                <div
                  className="school-presetting__subjects-table-item"
                  key={idx}
                >
                  <div className="school-presetting__subjects-table-item-name">
                    <p className="body-1">
                      {String(i18n.language).toUpperCase() !== "EN" &&
                      String(i18n.language).toUpperCase() !== "TR"
                        ? item[
                            `title${
                              String(i18n.language).charAt(0).toUpperCase() +
                              String(i18n.language).slice(1)
                            }`
                          ]
                        : item.title}
                    </p>
                  </div>
                  <div className="school-presetting__subjects-table-item-button">
                    <Tooltip
                      className="tooltip"
                      content={t("common:add course")}
                      placement="left"
                    >
                      <button onClick={() => addToSelectedSubject(item)}>
                        <Plus size={16} color={"#C4C4C4"} />
                      </button>
                    </Tooltip>
                  </div>
                </div>
              );
            }
          })
      : searchedCurrentData
          .filter((item) => item.courseType === courseTypeFilter)
          .map((item, idx) => {
            if (
              coursesStore
                .filter((item) => item.courseType === courseTypeFilter)
                .map((el) => el.adminCourseId)
                .includes(item.id)
            ) {
              return null;
            } else {
              return (
                <div
                  className="school-presetting__subjects-table-item"
                  key={idx}
                >
                  <div className="school-presetting__subjects-table-item-name">
                    <p className="body-1">
                      {String(i18n.language).toUpperCase() !== "EN" &&
                      String(i18n.language).toUpperCase() !== "TR"
                        ? item[
                            `title${
                              String(i18n.language).charAt(0).toUpperCase() +
                              String(i18n.language).slice(1)
                            }`
                          ]
                        : item.title}
                    </p>
                  </div>
                  <div className="school-presetting__subjects-table-item-button">
                    <Tooltip
                      className="tooltip"
                      content={t("common:add course")}
                      placement="left"
                    >
                      <button onClick={() => addToSelectedSubject(item)}>
                        <Plus size={16} color={"#C4C4C4"} />
                      </button>
                    </Tooltip>
                  </div>
                </div>
              );
            }
          }));

  return (
    <SchoolPresettingLayout id={3}>
      <div className="school-presetting__body school-presetting__body-color">
        <div className="school-presetting__body-row">
          <div className="school-presetting__body-title">
            <h2>{t("presetting:subjects presetting")}</h2>
          </div>
          <div className="school-presetting__body-description">
            <p className="body-1">
              {t("presetting:subjects lorem ipsum presetting")}
            </p>
          </div>
        </div>
        <div className="school-presetting__subjects-list">
          <div className="school-presetting__search-filter">
            <div className="school-presetting__search">
              <SearchField
                onChange={searchInputHandler}
                defaultValue={searchText}
                placeholder={t("common:search")}
              />
            </div>
          </div>
          <div className="school-presetting__subjects-body">
            <div className="school-presetting__subjects-table">
              <div className="school-presetting__subjects-table-left">
                <div className="school-presetting__subjects-table-title">
                  <h3>{t("presetting:added subjects presetting")}</h3>
                </div>
                <div className="school-presetting__subjects-table-body">
                  {addedSubjects.length > 0 ? (
                    <div className="school-presetting__subjects-table-list">
                      {addedSubjects}
                    </div>
                  ) : (
                    <NoData
                      messages={[
                        `${t("presetting:item list is empty presetting")}`,
                      ]}
                    />
                  )}
                </div>
              </div>
              <div className="school-presetting__subjects-table-right">
                <div className="school-presetting__subjects-table-title">
                  <h3>{t("presetting:subjects not added presetting")}</h3>
                </div>
                <div className="school-presetting__subjects-table-body">
                  <div className="school-presetting__subjects-table-filter">
                    <div>
                      <button
                        className={
                          courseTypeFilter == "GENERAL"
                            ? "primary large"
                            : "secondary large"
                        }
                        onClick={() => setCourseTypeFilter("GENERAL")}
                      >
                        {t("schoolAdmin:course general")}
                      </button>
                    </div>
                    <div>
                      <button
                        className={
                          courseTypeFilter == "TECHNICAL"
                            ? "primary large"
                            : "secondary large"
                        }
                        onClick={() => setCourseTypeFilter("TECHNICAL")}
                      >
                        {t("schoolAdmin:course technical")}
                      </button>
                    </div>
                  </div>
                  {notAddedSubjects.length > 0 ? (
                    <div className="school-presetting__subjects-table-list">
                      {notAddedSubjects}
                    </div>
                  ) : (
                    <NoData
                      messages={[
                        `${t("presetting:item list is empty presetting")}`,
                      ]}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="school-presetting__body-row school-presetting__body-buttons">
          <Link to="/school-presetting/2">
            <button className="secondary small">{t("common:back")}</button>
          </Link>
          <Link to="/school-presetting/4">
            <button
              onClick={onSubmit}
              className="primary large"
              disabled={
                coursesStoreStatus === statuses.SUCCESS &&
                coursesStore.length == 0
              }
            >
              {t("presetting:save and continue presetting school regulations")}
            </button>
          </Link>
        </div>
      </div>
    </SchoolPresettingLayout>
  );
}
