/* eslint-disable indent */
import React, { useEffect, useState, useCallback } from "react";
import { debounce } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import Modal from "react-modal";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Key, Edit2, Trash, PlusSquare, X, RefreshCcw } from "react-feather";
import ReactExport from "react-export-excel";
import SelectSearch, { fuzzySearch } from "react-select-search";
import Tooltip from "react-simple-tooltip";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

import {
  SchoolAdminLayout,
  PaginationBlock,
  SearchField,
  DatePickerCalendar,
  NoData,
} from "../../components";

import {
  removePerson,
  createPerson,
  getPersonById,
  editPerson,
  activatePerson,
} from "../../store/person/actions";
import {
  getInstructors,
  getInstructorCourses,
  addCourse,
  removeCourse,
} from "../../store/instructor/actions";
import { getCourses } from "../../store/course/actions";

export default function Instructors() {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation("common");
  const dispatch = useDispatch();
  // const personsStore = useSelector((store) => store.persons.persons);
  const instructorsStore = useSelector(
    (store) => store.instructors.instructors
  );
  const coursesStore = useSelector((store) => store.courses.courses);
  const instructorCoursesStore = useSelector(
    (store) => store.instructors.courses
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [filterTypeNumber, setFilterTypeNumber] = useState(1);
  const [filteredAllData] = useState([]);
  const [currentInstructorId, setCurrentInstructorId] = useState();
  const [currentInstructor, setCurrentInstructor] = useState();
  const [selectedCourseId, setSelectedCourseId] = useState();
  const [editInstructor, setEditInstructor] = useState({});

  // const [allSubjects, setAllSubjects] = useState([]);
  const [currentData, setCurrentData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedCurrentData, setSearchedCurrentData] = useState([]);
  // const [copySuccess, setCopySuccess] = useState("");
  const [modal, setModal] = useState({
    step1: false,
    step2: false,
    editTeacher: false,
    editSubjects: false,
    userInfo: false,
    export: false,
    confirm: false,
    confirm2: false,
  });

  const [newTeacher, setNewTeacher] = useState({
    lastName: "",
    firstName: "",
    middleName: "",
    phone: "",
    dateOfBirth: "1980-01-01",
    roleId: 4,
    gender: "2",
  });

  useEffect(async () => {
    await dispatch(getInstructors());
  }, []);

  const onInputChange = (e) => {
    setNewTeacher({
      ...newTeacher,
      [e.key || e.target.name]: e.value || e.target.value,
    });
  };
  const onEditInputChange = (e) => {
    setEditInstructor({ ...editInstructor, [e.target.name]: e.target.value });
  };

  const onPageChanged = (
    pageNumber,
    perPageNumber,
    data = instructorsStore,
    typeNumber = 1
  ) => {
    const offset = (pageNumber - 1) * perPageNumber;
    console.log(typeNumber);
    const currentDataArr = data.slice(offset, offset + perPageNumber);
    return setCurrentData(currentDataArr);
  };

  const createTeacher = async () => {
    await dispatch(createPerson(newTeacher, setCurrentInstructorId));
    await dispatch(getInstructors());
    await setNewTeacher({
      lastName: "",
      firstName: "",
      middleName: "",
      phone: "",
      dateOfBirth: "1980-01-01",
      roleId: 4,
      gender: "2",
    });
    await setModal({ ...modal, step1: false, step2: true });
  };
  const editTeacher = async () => {
    await dispatch(
      editPerson({
        lastName: editInstructor.lastName,
        firstName: editInstructor.firstName,
        middleName: editInstructor.middleName,
        phone: editInstructor.phone,
        dateOfBirth: moment(editInstructor.dateOfBirth).format("YYYY-MM-DD"),
        gender: editInstructor.gender,
        id: editInstructor.id,
      })
    );
    await dispatch(getInstructors());
    await setEditInstructor({});
    await setModal({ ...modal, editTeacher: false });
  };

  const subjectsRender = coursesStore.map((item) => ({
    name:
      String(i18n.language).toUpperCase() !== "EN" &&
      String(i18n.language).toUpperCase() !== "TR"
        ? item[`title${String(i18n.language).toUpperCase()}`]
        : item.title,
    value: item.id,
    key: "course",
  }));
  const removeSubject = async (id) => {
    await dispatch(
      removeCourse({ courseId: id, personId: currentInstructorId })
    );
    await dispatch(getInstructorCourses(currentInstructorId));
  };
  const addSubject = async (instructorId, courseId) => {
    await dispatch(addCourse(instructorId, courseId));
    await dispatch(getInstructorCourses(instructorId));
  };
  const onFilterHandler = async (typeNumber) => {
    await setSearchText("");
    await setCurrentData([]);
    await setSearchedCurrentData([]);
    await setCurrentPage(1);
    await setFilterTypeNumber(typeNumber);
    // await onPageChanged(1, perPage, instructorsStore, typeNumber);
  };
  const debouncedPerPage = useCallback(
    debounce((text, instructorsStore) => {
      if (filteredAllData.length > 0) {
        onPageChanged(1, Number(text), filteredAllData, filterTypeNumber);
      } else {
        onPageChanged(1, Number(text), instructorsStore);
      }
      setPerPage(Number(text));
      setCurrentPage(1);
    }, 1000),
    [instructorsStore]
  );
  const debouncedSearch = useCallback(
    debounce((text) => {
      setSearchText(String(text).toLowerCase());
      setCurrentPage(1);
      // setFilterTypeNumber(1);
      const a = instructorsStore.filter((item) => {
        let obj;
        Object.keys(item).map((key) => {
          if (key == "instructorTitle" || key == "instructorPhone") {
            const c = String(item[key]).toLowerCase();
            const d = c.includes(String(text).toLowerCase());
            if (d) {
              obj = item;
              return item;
            }
          }
        });
        return obj;
      });
      setSearchedCurrentData([...a]);
      onPageChanged(1, perPage, [...a], filterTypeNumber);
    }, 1000),
    [perPage, instructorsStore, filterTypeNumber]
  );

  const onChange = async (e) => {
    console.log(e);
    setCurrentPage(e);
    if (searchedCurrentData.length > 0) {
      onPageChanged(e, perPage, searchedCurrentData);
    } else if (filteredAllData.length > 0) {
      onPageChanged(e, perPage, filteredAllData, filterTypeNumber);
    } else {
      onPageChanged(e, perPage, instructorsStore);
    }
  };
  const getPerPage = (e) => {
    debouncedPerPage(e.target.value);
  };
  const searchInputHandler = (e) => {
    debouncedSearch(e.target.value);
  };

  const removeItem = async (value) => {
    await dispatch(removePerson(value.instructorId));
    await dispatch(getInstructors());
  };
  const restoreItem = async (value) => {
    await dispatch(activatePerson(value.instructorId));
    await dispatch(getInstructors(true));
  };

  const onSelect = (value) => {
    console.log(selectedData);
    if (selectedData.find((item) => item.instructorId === value.instructorId)) {
      let a = selectedData.filter(
        (item) => item.instructorId !== value.instructorId
      );
      setSelectedData([...a]);
    } else {
      setSelectedData([...selectedData, value]);
    }
  };
  const clearSelected = () => {
    setSelectedData([]);
  };
  const removeSelected = async (data) => {
    await data.map((item) => {
      dispatch(removePerson(item.instructorId));
    });
    await setSelectedData([]);
    await dispatch(getInstructors());

    // setAllData(a);
    // onPageChanged(currentPage, perPage, a);
  };
  // function copyToClipboard(e) {
  //   textAreaRef.current.i;
  //   document.execCommand("copy");
  //   // This is just personal preference.
  //   // I prefer to not show the whole text area selected.
  //   e.target.focus();
  //   setCopySuccess("Copied!");
  // }
  const tableDataRender =
    currentData.length > 0
      ? currentData.map((item, idx) => (
          <tr
            className={
              selectedData.find((i) => i.instructorId === item.instructorId)
                ? "active"
                : ""
            }
            key={idx}
          >
            <td className="table-select">
              <label className="check">
                <input
                  type="checkbox"
                  onChange={() => onSelect(item)}
                  checked={selectedData.find(
                    (i) => i.instructorId === item.instructorId
                  )}
                />
                <div className="box"></div>
              </label>
              <span>{idx + 1}</span>
            </td>
            <td>{item.instructorTitle}</td>
            <td>
              {item?.instructorCourseTitleDtoList?.map((item) =>
                String(i18n.language).toUpperCase() !== "EN" &&
                String(i18n.language).toUpperCase() !== "TR"
                  ? `${
                      item[`courseTitle${String(i18n.language).toUpperCase()}`]
                    } `
                  : `${item.courseTitle} `
              )}
            </td>
            <td>{item.instructorPhone}</td>
            <td>
              {filterTypeNumber === 2 ? (
                <div className="table-restore">
                  <button onClick={() => restoreItem(item)}>
                    <span>{t("schoolAdmin:restore")}</span>
                    <RefreshCcw color="#DADADA" />
                  </button>
                </div>
              ) : (
                <div>
                  <Tooltip className="tooltip" content={t("common:show login")}>
                    <button
                      onClick={() => {
                        setModal({ ...modal, userInfo: true });
                        setCurrentInstructorId(item.instructorId);
                      }}
                    >
                      <Key color="#0066ff" />
                    </button>
                  </Tooltip>
                  <Tooltip className="tooltip" content={t("common:add course")}>
                    <button
                      onClick={() => {
                        setModal({ ...modal, editSubjects: true }),
                          setCurrentInstructorId(item.instructorId);
                      }}
                    >
                      <PlusSquare color="#F0783A" />
                    </button>
                  </Tooltip>
                  <Tooltip className="tooltip" content={t("common:edit")}>
                    <button
                      onClick={() => {
                        setModal({ ...modal, editTeacher: true }),
                          setCurrentInstructorId(item.instructorId);
                      }}
                    >
                      <Edit2 color="#14A38B" />
                    </button>
                  </Tooltip>

                  <Tooltip className="tooltip" content={t("common:delete")}>
                    <button
                      onClick={async () => {
                        await setCurrentInstructor(item);
                        await setModal({ ...modal, confirm: true });
                      }}
                    >
                      <Trash color="#FF7171" />
                    </button>
                  </Tooltip>
                </div>
              )}
            </td>
          </tr>
        ))
      : instructorsStore.slice([0], [10]).map((item, idx) => (
          <tr
            className={
              selectedData.find((i) => i.instructorId == item.instructorId)
                ? "active"
                : ""
            }
            key={idx}
          >
            <td className="table-select">
              <label className="check">
                <input
                  type="checkbox"
                  onChange={() => onSelect(item)}
                  checked={selectedData.find(
                    (i) => i.instructorId === item.instructorId
                  )}
                />
                <div className="box"></div>
              </label>
              <span>{idx + 1}</span>
            </td>
            <td title={item.instructorUsername}>{item.instructorTitle}</td>
            <td>
              {item?.instructorCourseTitleDtoList?.map((item) =>
                String(i18n.language).toUpperCase() !== "EN" &&
                String(i18n.language).toUpperCase() !== "TR"
                  ? `${
                      item[`courseTitle${String(i18n.language).toUpperCase()}`]
                    } `
                  : `${item.courseTitle} `
              )}
            </td>
            <td>{item.instructorPhone}</td>
            <td>
              {filterTypeNumber === 2 ? (
                <div className="table-restore">
                  <button onClick={() => restoreItem(item)}>
                    <span>{t("schoolAdmin:restore")}</span>
                    <RefreshCcw color="#DADADA" />
                  </button>
                </div>
              ) : (
                <div>
                  <Tooltip className="tooltip" content={t("common:show login")}>
                    <button
                      onClick={() => {
                        setModal({ ...modal, userInfo: true });
                        setCurrentInstructorId(item.instructorId);
                      }}
                    >
                      <Key color="#0066ff" />
                    </button>
                  </Tooltip>
                  <Tooltip className="tooltip" content={t("common:add course")}>
                    <button
                      onClick={() => {
                        setModal({ ...modal, editSubjects: true }),
                          setCurrentInstructorId(item.instructorId);
                      }}
                    >
                      <PlusSquare color="#F0783A" />
                    </button>
                  </Tooltip>
                  <Tooltip className="tooltip" content={t("common:edit")}>
                    <button
                      onClick={() => {
                        setModal({ ...modal, editTeacher: true }),
                          setCurrentInstructorId(item.instructorId);
                      }}
                    >
                      <Edit2 color="#14A38B" />
                    </button>
                  </Tooltip>
                  <Tooltip className="tooltip" content={t("common:delete")}>
                    <button
                      onClick={async () => {
                        await setCurrentInstructor(item);
                        await setModal({ ...modal, confirm: true });
                      }}
                    >
                      <Trash color="#FF7171" />
                    </button>
                  </Tooltip>
                </div>
              )}
            </td>
          </tr>
        ));
  return (
    <SchoolAdminLayout>
      <div className="school-presetting__body school-presetting__body-color">
        <div className="school-presetting__body-row">
          <div className="school-presetting__body-title">
            <h2>{t("schoolAdmin:insctructor")}</h2>
          </div>
          <div className="school-presetting__body-description">
            <p className="body-1">
              {t("schoolAdmin:lorem ipsum of insctructor")}
            </p>
          </div>
        </div>

        <div className="school-presetting__subjects-list">
          <div className="school-presetting__search-filter">
            <div className="school-presetting__search">
              <SearchField
                onChange={searchInputHandler}
                defaultValue={searchText}
                placeholder={`${t("common:search")}`}
              />
            </div>
            <div className="school-presetting__filter">
              <button
                className="secondary small"
                onClick={() => setModal({ ...modal, export: true })}
              >
                {t("schoolAdmin:export")}
              </button>
              <button
                id="create_instructor"
                className="primary small"
                onClick={() => setModal({ ...modal, step1: true })}
              >
                {t("schoolAdmin:create insctructor")}
              </button>
            </div>
          </div>
          <div className="school-presetting__tabs">
            <div className="school-presetting__tabs-item">
              <button
                onClick={async () => {
                  await dispatch(getInstructors());
                  await onFilterHandler(1);
                }}
                className={filterTypeNumber === 1 ? "active" : ""}
              >
                {t("schoolAdmin:active")}
              </button>
            </div>
            <div className="school-presetting__tabs-item">
              <button
                onClick={async () => {
                  await dispatch(getInstructors(true));
                  await onFilterHandler(2);
                }}
                className={filterTypeNumber === 2 ? "active" : ""}
              >
                {t("schoolAdmin:archived")}
              </button>
            </div>
          </div>
          {instructorsStore.length > 0 ? (
            searchText !== "" && searchedCurrentData.length == 0 ? (
              <NoData messages={[`${t("schoolAdmin:here is nothing")}`]} />
            ) : (
              <>
                <div className="school-presetting__teachers-body">
                  <div className="school-presetting__teachers-table">
                    <table>
                      <thead>
                        <tr>
                          <th>№</th>
                          <th>{t("presetting:full name presetting")}</th>
                          <th>{t("presetting:subjects presetting")}</th>
                          <th>{t("presetting:phone presetting")}</th>
                          <th>{t("presetting:action presetting")}</th>
                        </tr>
                      </thead>
                      <tbody>{filterTypeNumber && tableDataRender}</tbody>
                    </table>

                    {selectedData.length > 0 && (
                      <div className="edit-table">
                        <div className="edit-table-left">
                          <div>
                            <p className="caption-1">
                              {t("presetting:choosen presetting")}:
                              <span>{selectedData.length}</span>
                            </p>
                          </div>
                          <div>
                            <button onClick={clearSelected}>
                              {t("presetting:consel presetting")}
                            </button>
                          </div>
                        </div>
                        <div className="edit-table-right">
                          <div>
                            <button
                              onClick={() =>
                                setModal({ ...modal, confirm2: true })
                              }
                            >
                              {t("presetting:delete presetting")}
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="pagination">
                  <div className="paginaiton__inner">
                    <div className="pagination__per-page">
                      <p>
                        {t("presetting:showing presetting")}{" "}
                        <input
                          type="phone"
                          defaultValue={perPage}
                          onChange={getPerPage}
                        />{" "}
                        {t("presetting:from presetting")}{" "}
                        {searchedCurrentData.length > 0
                          ? searchedCurrentData.length
                          : instructorsStore.length}{" "}
                        {t("presetting:lines presetting")}
                      </p>
                    </div>
                    <div className="pagination__page">
                      <PaginationBlock
                        total={
                          searchedCurrentData.length > 0
                            ? searchedCurrentData.length
                            : instructorsStore.length
                        }
                        current={currentPage}
                        onChange={onChange}
                        pageSize={perPage}
                      />
                    </div>
                  </div>
                </div>
              </>
            )
          ) : (
            <NoData messages={[`${t("schoolAdmin:here is nothing")}`]} />
          )}
        </div>
      </div>
      <Modal
        isOpen={modal.step1}
        onRequestClose={() => setModal({ ...modal, step1: false })}
        overlayClassName="modal-overlay"
      >
        <div className="modal">
          <div className="modal-close">
            <button onClick={() => setModal({ ...modal, step1: false })}>
              <X size={15} color="#9A9A9A" />
            </button>
          </div>
          <div className="modal-header">
            <div className="modal-header-title">
              <h2>{t("presetting:creating a teacher profile presetting")}</h2>
            </div>
            <div className="modal-header-description">
              <p className="body-2">
                {t(
                  "presetting:fill in all the fields below and proceed presetting"
                )}
              </p>
            </div>
          </div>
          <div className="modal-body">
            <div className="modal-inputs-list">
              <div className="modal-input">
                <input
                  type="text"
                  placeholder={`${t("presetting:surname presetting")}`}
                  name="lastName"
                  defaultValue={newTeacher.lastName}
                  onChange={onInputChange}
                />
              </div>
              <div className="modal-input">
                <input
                  type="text"
                  placeholder={`${t("presetting:name presetting")}`}
                  name="firstName"
                  defaultValue={newTeacher.firstName}
                  onChange={onInputChange}
                />
              </div>
              <div className="modal-input">
                <input
                  type="text"
                  placeholder={`${t("presetting:middlename presetting")}`}
                  name="middleName"
                  defaultValue={newTeacher.middleName}
                  onChange={onInputChange}
                />
              </div>
              <div className="modal-input">
                <input
                  type="text"
                  placeholder="996700345123"
                  name="phone"
                  defaultValue={newTeacher.phone}
                  onChange={onInputChange}
                />
              </div>
              <div className="modal-input">
                <DatePickerCalendar
                  placeholder={`${t("presetting:date of birth presetting")}`}
                  onSelect={onInputChange}
                  defaultValue={newTeacher.dateOfBirth}
                  name="dateOfBirth"
                />
              </div>
              {/* <div className="modal-input">
                <SelectSearch
                  options={[
                    {
                      name: `${t("presetting:director presetting")}`,
                      value: "6",
                      key: "roleId",
                    },
                    {
                      name: `${t("presetting:head teacher presetting")}`,
                      value: "7",
                      key: "roleId",
                    },
                  ]}
                  search
                  filterOptions={fuzzySearch}
                  name="roleId"
                  onChange={(_, k) => {
                    onInputChange(k);
                  }}
                  required
                  placeholder={t("presetting:role presetting")}
                /> 
              </div> */}
              <div className="modal-input">
                <p className="body-2">{t("presetting:gender presetting")}</p>
                <div className="gender">
                  <div>
                    <input
                      type="radio"
                      name="gender"
                      value="1"
                      checked={newTeacher.gender == 1}
                      onChange={onInputChange}
                    />
                    <label htmlFor="gender">
                      {t("presetting:male presetting")}
                    </label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      name="gender"
                      value="2"
                      checked={newTeacher.gender == 2}
                      onChange={onInputChange}
                    />
                    <label htmlFor="gender">
                      {t("presetting:female presetting")}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              id="create_instructor_step1"
              className="large primary"
              onClick={() => createTeacher()}
            >
              {t("presetting:go to next step presetting")}
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modal.step2}
        onRequestClose={() => {
          setModal({ ...modal, step2: false });
          setCurrentInstructorId("");
          setSelectedCourseId("");
          dispatch(getInstructors());
        }}
        overlayClassName="modal-overlay"
        preventScroll={true}
        onAfterOpen={() => {
          dispatch(getCourses());
          dispatch(getInstructorCourses(currentInstructorId));
        }}
      >
        <div className="modal">
          <div className="modal-close">
            <button
              onClick={() => {
                setModal({ ...modal, step2: false });
                setCurrentInstructorId("");
                setSelectedCourseId("");
                dispatch(getInstructors());
              }}
            >
              <X size={15} color="#9A9A9A" />
            </button>
          </div>
          <div className="modal-header">
            <div className="modal-header-title">
              <h2>{t("presetting:adding subjects presetting")}</h2>
            </div>
            <div className="modal-header-description">
              <p className="body-2">
                {t("presetting:find in the search engine presetting")}
              </p>
            </div>
          </div>
          <div className="modal-body">
            <div className="modal-subject">
              <div className="modal-subject-select">
                <div className="modal-subject-selector" style={{ width: 300 }}>
                  <SelectSearch
                    options={subjectsRender}
                    search
                    filterOptions={fuzzySearch}
                    name="course"
                    value={selectedCourseId}
                    onChange={(_, k) => {
                      setSelectedCourseId(k.value);
                    }}
                    required
                    placeholder={t("presetting:subject")}
                  />
                </div>
                <div className="modal-subject-select-button">
                  <button
                    id="add_subject"
                    className="primary small"
                    disabled={!selectedCourseId}
                    onClick={() =>
                      addSubject(currentInstructorId, selectedCourseId)
                    }
                  >
                    {t("common:add")}
                  </button>
                </div>
              </div>
              <div className="modal-subject-list">
                {instructorCoursesStore.length > 0 ? (
                  instructorCoursesStore?.map((item, idx) => (
                    <div className="modal-subject-item" key={idx}>
                      <div className="modal-subject-item-title">
                        <p className="body-1">
                          {String(i18n.language).toUpperCase() !== "EN" &&
                          String(i18n.language).toUpperCase() !== "TR"
                            ? item[
                                `title${String(i18n.language).toUpperCase()}`
                              ]
                            : item.title}
                        </p>
                      </div>
                      <div className="modal-subject-item-buttons">
                        <div>
                          <button onClick={() => removeSubject(item.id)}>
                            <Trash size={24} color="#FF7171" />
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <NoData
                    messages={[`${t("presetting:no added items presetting")}`]}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              id="create_instructor_step2"
              className="large primary"
              onClick={() => {
                setModal({ ...modal, step2: false });
                setCurrentInstructorId("");
                setSelectedCourseId("");
                dispatch(getInstructors());
              }}
            >
              Готово
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modal.editTeacher}
        onRequestClose={() => setModal({ ...modal, editTeacher: false })}
        onAfterOpen={() =>
          dispatch(getPersonById(currentInstructorId, setEditInstructor))
        }
        overlayClassName="modal-overlay"
      >
        <div className="modal">
          <div className="modal-close">
            <button onClick={() => setModal({ ...modal, editTeacher: false })}>
              <X size={15} color="#9A9A9A" />
            </button>
          </div>
          <div className="modal-header">
            <div className="modal-header-title">
              <h2>{t("presetting:editing teacher profile presetting")}</h2>
            </div>
            <div className="modal-header-description">
              <p className="body-2">
                {t("presetting:edit the fields presetting")}
              </p>
            </div>
          </div>
          <div className="modal-body">
            <div className="modal-inputs-list">
              <div className="modal-input">
                <input
                  type="text"
                  placeholder={`${t("presetting:surname presetting")}`}
                  name="lastName"
                  defaultValue={editInstructor.lastName}
                  onChange={onEditInputChange}
                />
              </div>
              <div className="modal-input">
                <input
                  type="text"
                  placeholder={`${t("presetting:name presetting")}`}
                  name="firstName"
                  defaultValue={editInstructor.firstName}
                  onChange={onEditInputChange}
                />
              </div>
              <div className="modal-input">
                <input
                  type="text"
                  placeholder={`${t("presetting:middlename presetting")}`}
                  name="middleName"
                  defaultValue={editInstructor.middleName}
                  onChange={onEditInputChange}
                />
              </div>
              <div className="modal-input">
                <input
                  type="text"
                  placeholder="996700345123"
                  name="phone"
                  defaultValue={editInstructor.phone}
                  onChange={onEditInputChange}
                />
              </div>
              {/* <div className="modal-input">
              <DatePickerCalendar
                placeholder="Дата рождения"
                onSelect={onEditInputChange}
                defaultValue={moment(editInstructor.dateOfBirth, "YYYY-MM-DD")}
                name="dateOfBirth"
              />
            </div> */}
              <div className="modal-input">
                <p className="body-2">{t("presetting:gender presetting")}</p>
                <div className="gender">
                  <div>
                    <input
                      type="radio"
                      name="gender"
                      value="1"
                      checked={editInstructor.gender == 1}
                      onChange={onEditInputChange}
                    />
                    <label htmlFor="gender">
                      {t("presetting:male presetting")}
                    </label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      name="gender"
                      checked={editInstructor.gender == 2}
                      value="2"
                      onChange={onEditInputChange}
                    />
                    <label htmlFor="gender">
                      {t("presetting:female presetting")}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button className="large primary" onClick={() => editTeacher()}>
              {t("common:save")}
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modal.editSubjects}
        onRequestClose={() => {
          setModal({ ...modal, editSubjects: false });
          setCurrentInstructorId("");
          setSelectedCourseId("");
          dispatch(getInstructors());
        }}
        overlayClassName="modal-overlay"
        preventScroll={true}
        onAfterOpen={() => {
          dispatch(getCourses());
          dispatch(getInstructorCourses(currentInstructorId));
        }}
      >
        <div className="modal">
          <div className="modal-close">
            <button
              onClick={() => {
                setModal({ ...modal, editSubjects: false });
                setCurrentInstructorId("");
                setSelectedCourseId("");
                dispatch(getInstructors());
              }}
            >
              <X size={15} color="#9A9A9A" />
            </button>
          </div>
          <div className="modal-header">
            <div className="modal-header-title">
              <h2>{t("presetting:adding subjects only string presetting")}</h2>
            </div>
            <div className="modal-header-description">
              <p className="body-2">
                {t("presetting:find in the search engine presetting")}
              </p>
            </div>
          </div>
          <div className="modal-body">
            <div className="modal-subject">
              <div className="modal-subject-select">
                <div className="modal-subject-selector" style={{ width: 300 }}>
                  <SelectSearch
                    options={subjectsRender}
                    search
                    filterOptions={fuzzySearch}
                    name="course"
                    value={selectedCourseId}
                    onChange={(_, k) => {
                      setSelectedCourseId(k.value);
                    }}
                    required
                    placeholder={t("presetting:subject")}
                  />
                </div>
                <div className="modal-subject-select-button">
                  <button
                    className="primary small"
                    disabled={!selectedCourseId}
                    onClick={() =>
                      addSubject(currentInstructorId, selectedCourseId)
                    }
                  >
                    {t("common:add")}
                  </button>
                </div>
              </div>
              <div className="modal-subject-list">
                {instructorCoursesStore.length > 0 ? (
                  instructorCoursesStore?.map((item, idx) => (
                    <div className="modal-subject-item" key={idx}>
                      <div className="modal-subject-item-title">
                        <p className="body-1">
                          {String(i18n.language).toUpperCase() !== "EN" &&
                          String(i18n.language).toUpperCase() !== "TR"
                            ? item[
                                `title${String(i18n.language).toUpperCase()}`
                              ]
                            : item.title}
                        </p>
                      </div>
                      <div className="modal-subject-item-buttons">
                        <div>
                          <button onClick={() => removeSubject(item.id)}>
                            <Trash size={24} color="#FF7171" />
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <NoData
                    messages={[`${t("presetting:no added items presetting")}`]}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="large primary"
              onClick={() => {
                setModal({ ...modal, editSubjects: false });
                setCurrentInstructorId("");
                setSelectedCourseId("");
                dispatch(getInstructors());
              }}
            >
              {t("common:save")}
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modal.export}
        onRequestClose={() => {
          setModal({ ...modal, export: false });
        }}
        overlayClassName="modal-overlay"
        onAfterOpen={async () => {
          // await dispatch(getParentsByClassId(currentClassId));
        }}
      >
        <div className="modal">
          <div className="modal-close">
            <button
              onClick={() => {
                setModal({ ...modal, export: false });
                // setEditClass({
                //   classType: "PRIMARY",
                //   classLabel: "",
                //   classLevel: "",
                //   languageId: "",
                //   shiftId: "",
                //   personId: "",
                // });
              }}
            >
              <X size={15} color="#9A9A9A" />
            </button>
          </div>
          <div className="modal-header">
            <div className="modal-header-title">
              <h2>{t("schoolAdmin:export")}</h2>
            </div>
            <div className="modal-header-description">
              <p className="body-2">
                {t("schoolAdmin:export logins and passwords")}
              </p>
            </div>
          </div>

          <div className="modal-footer" style={{ marginTop: "24px" }}>
            <ExcelFile
              filename={`${
                JSON.parse(localStorage.getItem("school"))?.name
              } - ${t("schoolAdmin:insctructor")}`}
              element={
                <button className="large primary">
                  {t("schoolAdmin:export")}
                </button>
              }
            >
              <ExcelSheet data={instructorsStore} name="Instructors">
                <ExcelColumn
                  label={`${t("validation:full name")}`}
                  value="instructorTitle"
                />
                <ExcelColumn
                  label={`${t("validation:login")}`}
                  value="instructorUsername"
                />
                <ExcelColumn
                  label={`${t("validation:password")}`}
                  value="instructorUsername"
                />
              </ExcelSheet>
            </ExcelFile>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modal.userInfo}
        onRequestClose={() => {
          setModal({ ...modal, userInfo: false });
          setCurrentInstructorId();
        }}
        onAfterOpen={
          () =>
            setEditInstructor(
              instructorsStore.find(
                (instructor) => instructor.instructorId == currentInstructorId
              )
            )
          // dispatch(getPersonById(currentInstructorId, setEditInstructor))
        }
        overlayClassName="modal-overlay"
        className="small"
      >
        <div className="modal">
          <div className="modal-close">
            <button
              onClick={() => {
                setModal({ ...modal, userInfo: false });
                setCurrentInstructorId();
              }}
            >
              <X size={15} color="#9A9A9A" />
            </button>
          </div>
          <div className="modal-header">
            <div className="modal-header-title">
              <h2>{editInstructor.instructorTitle}</h2>
            </div>
          </div>
          <div className="modal-body">
            <div className="modal-inputs-list">
              <div>
                <p
                  className="roboto"
                  style={{
                    cursor: "pointer",
                    padding: "8px 64px",
                    borderRadius: 4,
                  }}
                  onClick={async (e) => {
                    await navigator.clipboard.writeText(
                      editInstructor.instructorUsername
                    );
                    e.target.style.background = "#b9f7c3";
                    setTimeout(() => {
                      e.target.style.background = "#fff";
                      setModal({ ...modal, userInfo: false });
                      setCurrentInstructorId();
                    }, 1000);
                  }}
                >
                  {editInstructor.instructorUsername}
                </p>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="large primary"
              onClick={() => {
                setModal({ ...modal, userInfo: false });
                setCurrentInstructorId();
              }}
            >
              {t("common:close")}
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modal.confirm}
        onRequestClose={() => {
          setModal({ ...modal, confirm: false });
        }}
        overlayClassName="modal-overlay"
      >
        <div className="modal">
          <div className="modal-close">
            <button
              onClick={() => {
                setModal({ ...modal, confirm: false });
              }}
            >
              <X size={15} color="#9A9A9A" />
            </button>
          </div>
          <div className="modal-header">
            <div className="modal-header-title">
              <h2>{t("presetting:are u shure presetting")}</h2>
            </div>
            <div className="modal-header-description">
              <p className="body-2">{t("schoolAdmin:delete warning")}</p>
            </div>
          </div>

          <div className="modal-footer" style={{ marginTop: "24px" }}>
            <button
              className="large secondary"
              onClick={() => setModal({ ...modal, confirm: false })}
            >
              {t("common:cancel")}
            </button>
            <button
              className="large primary"
              onClick={async () => {
                await removeItem(currentInstructor);
                await setModal({ ...modal, confirm: false });
                await setCurrentInstructor({});
              }}
            >
              {t("common:delete")}
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modal.confirm2}
        onRequestClose={() => {
          setModal({ ...modal, confirm2: false });
        }}
        overlayClassName="modal-overlay"
      >
        <div className="modal">
          <div className="modal-close">
            <button
              onClick={() => {
                setModal({ ...modal, confirm2: false });
              }}
            >
              <X size={15} color="#9A9A9A" />
            </button>
          </div>
          <div className="modal-header">
            <div className="modal-header-title">
              <h2>{t("presetting:are u shure presetting")}</h2>
            </div>
            <div className="modal-header-description">
              <p className="body-2">{t("schoolAdmin:delete warning")}</p>
            </div>
          </div>

          <div className="modal-footer" style={{ marginTop: "24px" }}>
            <button
              className="large secondary"
              onClick={() => setModal({ ...modal, confirm2: false })}
            >
              {t("common:cancel")}
            </button>
            <button
              className="large primary"
              onClick={async () => {
                await removeSelected(selectedData);
                await setModal({ ...modal, confirm2: false });
                await setCurrentInstructor({});
              }}
            >
              {t("common:delete")}
            </button>
          </div>
        </div>
      </Modal>
    </SchoolAdminLayout>
  );
}
