// AUTH
export const AUTH_LOGIN_REQUEST = "AUTH_LOGIN_REQUEST";
export const AUTH_LOGIN_SUCCESS = "AUTH_LOGIN_SUCCESS";
export const AUTH_LOGIN_ERROR = "AUTH_LOGIN_ERROR";

export const AUTH_LOGOUT_REQUEST = "AUTH_LOGOUT_REQUEST";
export const AUTH_LOGOUT_SUCCESS = "AUTH_LOGOUT_SUCCESS";
export const AUTH_LOGOUT_ERROR = "AUTH_LOGOUT_ERROR";

export const GET_USER_INFO_REQUEST = "GET_USER_INFO_REQUEST";
export const GET_USER_INFO_SUCCESS = "GET_USER_INFO_SUCCESS";
export const GET_USER_INFO_ERROR = "GET_USER_INFO_ERROR";

export const GET_SCHOOLS_REQUEST = "GET_SCHOOLS_REQUEST";
export const GET_SCHOOLS_SUCCESS = "GET_SCHOOLS_SUCCESS";
export const GET_SCHOOLS_ERROR = "GET_SCHOOLS_ERROR";
export const GET_SCHOOL_REQUEST = "GET_SCHOOL_REQUEST";
export const GET_SCHOOL_SUCCESS = "GET_SCHOOL_SUCCESS";
export const GET_SCHOOL_ERROR = "GET_SCHOOL_ERROR";

export const GET_CLASS_REQUEST = "GET_CLASS_REQUEST";
export const GET_CLASS_SUCCESS = "GET_CLASS_SUCCESS";
export const GET_CLASS_ERROR = "GET_CLASS_ERROR";

export const GET_CHRONICLE_YEAR_REQUEST = "GET_CHRONICLE_YEAR_REQUEST";
export const GET_CHRONICLE_YEAR_SUCCESS = "GET_CHRONICLE_YEAR_SUCCESS";
export const GET_CHRONICLE_YEAR_ERROR = "GET_CHRONICLE_YEAR_ERROR";

export const GET_QUARTERS_REQUEST = "GET_QUARTERS_REQUEST";
export const GET_QUARTERS_SUCCESS = "GET_QUARTERS_SUCCESS";
export const GET_QUARTERS_ERROR = "GET_QUARTERS_ERROR";

export const GET_ADMIN_COURSES_REQUEST = "GET_ADMIN_COURSES_REQUEST";
export const GET_ADMIN_COURSES_SUCCESS = "GET_ADMIN_COURSES_SUCCESS";
export const GET_ADMIN_COURSES_ERROR = "GET_ADMIN_COURSES_ERROR";

export const GET_COURSES_REQUEST = "GET_COURSES_REQUEST";
export const GET_COURSES_SUCCESS = "GET_COURSES_SUCCESS";
export const GET_COURSES_ERROR = "GET_COURSES_ERROR";

export const GET_SHIFTS_REQUEST = "GET_SHIFTS_REQUEST";
export const GET_SHIFTS_SUCCESS = "GET_SHIFTS_SUCCESS";
export const GET_SHIFTS_ERROR = "GET_SHIFTS_ERROR";

export const GET_SHIFTS_TIMES_REQUEST = "GET_SHIFTS_TIMES_REQUEST";
export const GET_SHIFTS_TIMES_SUCCESS = "GET_SHIFTS_TIMES_SUCCESS";
export const GET_SHIFTS_TIMES_ERROR = "GET_SHIFTS_TIMES_ERROR";

export const GET_PERSONS_REQUEST = "GET_PERSONS_REQUEST";
export const GET_PERSONS_SUCCESS = "GET_PERSONS_SUCCESS";
export const GET_PERSONS_ERROR = "GET_PERSONS_ERROR";

export const GET_PERSON_REQUEST = "GET_PERSON_REQUEST";
export const GET_PERSON_SUCCESS = "GET_PERSON_SUCCESS";
export const GET_PERSON_ERROR = "GET_PERSON_ERROR";

export const GET_INSTRUCTOR_REQUEST = "GET_INSTRUCTOR_REQUEST";
export const GET_INSTRUCTOR_SUCCESS = "GET_INSTRUCTOR_SUCCESS";
export const GET_INSTRUCTOR_ERROR = "GET_INSTRUCTOR_ERROR";

export const GET_INSTRUCTOR_COURSE_REQUEST = "GET_INSTRUCTOR_COURSE_REQUEST";
export const GET_INSTRUCTOR_COURSE_SUCCESS = "GET_INSTRUCTOR_COURSE_SUCCESS";
export const GET_INSTRUCTOR_COURSE_ERROR = "GET_INSTRUCTOR_COURSE_ERROR";

export const GET_LANGUAGES_REQUEST = "GET_LANGUAGES_REQUEST";
export const GET_LANGUAGES_SUCCESS = "GET_LANGUAGES_SUCCESS";
export const GET_LANGUAGES_ERROR = "GET_LANGUAGES_ERROR";

export const GET_CLASS_ONE_REQUEST = "GET_CLASS_ONE_REQUEST";
export const GET_CLASS_ONE_SUCCESS = "GET_CLASS_ONE_SUCCESS";
export const GET_CLASS_ONE_ERROR = "GET_CLASS_ONE_ERROR";

export const GET_STUDENTS_REQUEST = "GET_STUDENTS_REQUEST";
export const GET_STUDENTS_SUCCESS = "GET_STUDENTS_SUCCESS";
export const GET_STUDENTS_ERROR = "GET_STUDENTS_ERROR";

export const GET_STUDENT_REQUEST = "GET_STUDENT_REQUEST";
export const GET_STUDENT_SUCCESS = "GET_STUDENT_SUCCESS";
export const GET_STUDENT_ERROR = "GET_STUDENT_ERROR";

export const GET_PARENTS_REQUEST = "GET_PARENTS_REQUEST";
export const GET_PARENTS_SUCCESS = "GET_PARENTS_SUCCESS";
export const GET_PARENTS_ERROR = "GET_PARENTS_ERROR";

export const GET_PARENT_REQUEST = "GET_PARENT_REQUEST";
export const GET_PARENT_SUCCESS = "GET_PARENT_SUCCESS";
export const GET_PARENT_ERROR = "GET_PARENT_ERROR";

export const GET_REGIONS_REQUEST = "GET_REGIONS_REQUEST";
export const GET_REGIONS_SUCCESS = "GET_REGIONS_SUCCESS";
export const GET_REGIONS_ERROR = "GET_REGIONS_ERROR";

export const GET_RAYONS_REQUEST = "GET_RAYONS_REQUEST";
export const GET_RAYONS_SUCCESS = "GET_RAYONS_SUCCESS";
export const GET_RAYONS_ERROR = "GET_RAYONS_ERROR";

export const GET_SCHEDULE_GROUPS_REQUEST = "GET_SCHEDULE_GROUPS_REQUEST";
export const GET_SCHEDULE_GROUPS_SUCCESS = "GET_SCHEDULE_GROUPS_SUCCESS";
export const GET_SCHEDULE_GROUPS_ERROR = "GET_SCHEDULE_GROUPS_ERROR";

export const GET_SCHEDULE_GROUP_REQUEST = "GET_SCHEDULE_GROUP_REQUEST";
export const GET_SCHEDULE_GROUP_SUCCESS = "GET_SCHEDULE_GROUP_SUCCESS";
export const GET_SCHEDULE_GROUP_ERROR = "GET_SCHEDULE_GROUP_ERROR";

export const GET_SCHEDULES_REQUEST = "GET_SCHEDULES_REQUEST";
export const GET_SCHEDULES_SUCCESS = "GET_SCHEDULES_SUCCESS";
export const GET_SCHEDULES_ERROR = "GET_SCHEDULES_ERROR";
export const GET_SCHEDULES2_REQUEST = "GET_SCHEDULES2_REQUEST";
export const GET_SCHEDULES2_SUCCESS = "GET_SCHEDULES2_SUCCESS";
export const GET_SCHEDULES2_ERROR = "GET_SCHEDULES2_ERROR";

export const GET_SCHEDULE_REQUEST = "GET_SCHEDULE_REQUEST";
export const GET_SCHEDULE_SUCCESS = "GET_SCHEDULE_SUCCESS";
export const GET_SCHEDULE_ERROR = "GET_SCHEDULE_ERROR";

export const GET_STUDENT_COURSES_REQUEST = "GET_STUDENT_COURSES_REQUEST";
export const GET_STUDENT_COURSES_SUCCESS = "GET_STUDENT_COURSES_SUCCESS";
export const GET_STUDENT_COURSES_ERROR = "GET_STUDENT_COURSES_ERROR";

export const GET_PAYMENTS_REQUEST = "GET_PAYMENTS_REQUEST";
export const GET_PAYMENTS_SUCCESS = "GET_PAYMENTS_SUCCESS";
export const GET_PAYMENTS_ERROR = "GET_PAYMENTS_ERROR";

export const GET_CALENDAR_PLANS_REQUEST = "GET_CALENDAR_PLANS_REQUEST";
export const GET_CALENDAR_PLANS_SUCCESS = "GET_CALENDAR_PLANS_SUCCESS";
export const GET_CALENDAR_PLANS_ERROR = "GET_CALENDAR_PLANS_ERROR";

export const GET_CALENDAR_PLAN_REQUEST = "GET_CALENDAR_PLAN_REQUEST";
export const GET_CALENDAR_PLAN_SUCCESS = "GET_CALENDAR_PLAN_SUCCESS";
export const GET_CALENDAR_PLAN_ERROR = "GET_CALENDAR_PLAN_ERROR";

export const GET_CALENDAR_TOPICS_REQUEST = "GET_CALENDAR_TOPICS_REQUEST";
export const GET_CALENDAR_TOPICS_SUCCESS = "GET_CALENDAR_TOPICS_SUCCESS";
export const GET_CALENDAR_TOPICS_ERROR = "GET_CALENDAR_TOPICS_ERROR";

export const GET_CALENDAR_TOPIC_REQUEST = "GET_CALENDAR_TOPIC_REQUEST";
export const GET_CALENDAR_TOPIC_SUCCESS = "GET_CALENDAR_TOPIC_SUCCESS";
export const GET_CALENDAR_TOPIC_ERROR = "GET_CALENDAR_TOPIC_ERROR";

export const GET_INSTRUCTOR_ANALYTIC_REQUEST =
  "GET_INSTRUCTOR_ANALYTIC_REQUEST";
export const GET_INSTRUCTOR_ANALYTIC_SUCCESS =
  "GET_INSTRUCTOR_ANALYTIC_SUCCESS";
export const GET_INSTRUCTOR_ANALYTIC_ERROR = "GET_INSTRUCTOR_ANALYTIC_ERROR";

export const GET_STUDENTS_ANALYTIC_REQUEST = "GET_STUDENTS_ANALYTIC_REQUEST";
export const GET_STUDENTS_ANALYTIC_SUCCESS = "GET_STUDENTS_ANALYTIC_SUCCESS";
export const GET_STUDENTS_ANALYTIC_ERROR = "GET_STUDENTS_ANALYTIC_ERROR";

export const GET_PARENTS_ANALYTIC_REQUEST = "GET_PARENTS_ANALYTIC_REQUEST";
export const GET_PARENTS_ANALYTIC_SUCCESS = "GET_PARENTS_ANALYTIC_SUCCESS";
export const GET_PARENTS_ANALYTIC_ERROR = "GET_PARENTS_ANALYTIC_ERROR";

export const GET_ASSESSMENTS_ANALYTIC_REQUEST =
  "GET_ASSESSMENTS_ANALYTIC_REQUEST";
export const GET_ASSESSMENTS_ANALYTIC_SUCCESS =
  "GET_ASSESSMENTS_ANALYTIC_SUCCESS";
export const GET_ASSESSMENTS_ANALYTIC_ERROR = "GET_ASSESSMENTS_ANALYTIC_ERROR";

export const GET_GRADES_REQUEST = "GET_GRADES_REQUEST";
export const GET_GRADES_SUCCESS = "GET_GRADES_SUCCESS";
export const GET_GRADES_ERROR = "GET_GRADES_ERROR";

export const GET_QUARTER_GRADES_REQUEST = "GET_QUARTER_GRADES_REQUEST";
export const GET_QUARTER_GRADES_SUCCESS = "GET_QUARTER_GRADES_SUCCESS";
export const GET_QUARTER_GRADES_ERROR = "GET_QUARTER_GRADES_ERROR";

export const GET_ASSESSMENTS_TOPIC_REQUEST = "GET_ASSESSMENTS_TOPIC_REQUEST";
export const GET_ASSESSMENTS_TOPIC_SUCCESS = "GET_ASSESSMENTS_TOPIC_SUCCESS";
export const GET_ASSESSMENTS_TOPIC_ERROR = "GET_ASSESSMENTS_TOPIC_ERROR";

export const GET_ASSESSMENTS_HW_REQUEST = "GET_ASSESSMENTS_HW_REQUEST";
export const GET_ASSESSMENTS_HW_SUCCESS = "GET_ASSESSMENTS_HW_SUCCESS";
export const GET_ASSESSMENTS_HW_ERROR = "GET_ASSESSMENTS_HW_ERROR";

export const GET_GRADE_SHEET_REQUEST = "GET_GRADE_SHEET_REQUEST";
export const GET_GRADE_SHEET_SUCCESS = "GET_GRADE_SHEET_SUCCESS";
export const GET_GRADE_SHEET_ERROR = "GET_GRADE_SHEET_ERROR";
export const GET_GRADE_SHEET_COURSES_SUCCESS =
  "GET_GRADE_SHEET_COURSES_SUCCESS";
export const GET_GRADE_SHEET_PEOPLE_SUCCESS = "GET_GRADE_SHEET_PEOPLE_SUCCESS";

export const GET_QUARTER_GRADE_ANALYTIC_CLASS_PERFORMANCE_REQUEST =
  "GET_QUARTER_GRADE_ANALYTIC_CLASS_PERFORMANCE_REQUEST";
export const GET_QUARTER_GRADE_ANALYTIC_CLASS_PERFORMANCE_SUCCESS =
  "GET_QUARTER_GRADE_ANALYTIC_CLASS_PERFORMANCE_SUCCESS";
export const GET_QUARTER_GRADE_ANALYTIC_CLASS_PERFORMANCE_ERROR =
  "GET_QUARTER_GRADE_ANALYTIC_CLASS_PERFORMANCE_ERROR";

export const GET_QUARTER_GRADE_ANALYTIC_LIST_EXCELLENCE_REQUEST =
  "GET_QUARTER_GRADE_ANALYTIC_LIST_EXCELLENCE_REQUEST";
export const GET_QUARTER_GRADE_ANALYTIC_LIST_EXCELLENCE_SUCCESS =
  "GET_QUARTER_GRADE_ANALYTIC_LIST_EXCELLENCE_SUCCESS";
export const GET_QUARTER_GRADE_ANALYTIC_LIST_EXCELLENCE_ERROR =
  "GET_QUARTER_GRADE_ANALYTIC_LIST_EXCELLENCE_ERROR";

export const GET_STUDENT_ANALYTIC_REPORTS = "GET_STUDENT_ANALYTIC_REPORTS";
export const GET_STUDENT_ANALYTIC_REPORTS_SUCCESS = "GET_STUDENT_ANALYTIC_REPORTS_SUCCESS";
export const GET_STUDENT_ANALYTIC_REPORTS_ERROR = "GET_STUDENT_ANALYTIC_REPORTS_ERROR";

export const GET_STUDENTS_REPORT_ATTENDANCE = "GET_STUDENTS_REPORT_ATTENDANCE";
export const GET_STUDENTS_REPORT_ATTENDANCE_SUCCESS =
  "GET_STUDENTS_REPORT_ATTENDANCE_SUCCESS";
export const GET_STUDENTS_REPORT_ATTENDANCE_ERROR =
  "GET_STUDENTS_REPORT_ATTENDANCE_ERROR";

export const GET_QUARTER_BY_ID_REQUEST = "GET_QUARTER_BY_ID_REQUEST";
export const GET_QUARTER_BY_ID_SUCCESS = "GET_QUARTER_BY_ID_SUCCESS";
export const GET_QUARTER_BY_ID_ERROR = "GET_QUARTER_BY_ID_ERROR";
export const GET_SECTIONS_REQUEST = "GET_SECTIONS_REQUEST";
export const GET_SECTIONS_SUCCESS = "GET_SECTIONS_SUCCESS";
export const GET_SECTIONS_ERROR = "GET_SECTIONS_ERROR";

export const GET_SECTION_REQUEST = "GET_SECTION_REQUEST";
export const GET_SECTION_SUCCESS = "GET_SECTION_SUCCESS";
export const GET_SECTION_ERROR = "GET_SECTION_ERROR";

export const GET_SECTION_INSTRUCTOR_REQUEST = "GET_SECTION_INSTRUCTOR_REQUEST";
export const GET_SECTION_INSTRUCTOR_SUCCESS = "GET_SECTION_INSTRUCTOR_SUCCESS";
export const GET_SECTION_INSTRUCTOR_ERROR = "GET_SECTION_INSTRUCTOR_ERROR";

export const GET_SECTION_SCHEDULE_REQUEST = "GET_SECTION_SCHEDULE_REQUEST";
export const GET_SECTION_SCHEDULE_SUCCESS = "GET_SECTION_SCHEDULE_SUCCESS";
export const GET_SECTION_SCHEDULE_ERROR = "GET_SECTION_SCHEDULE_ERROR";

export const GET_SECTION_STUDENT_REQUEST = "GET_SECTION_STUDENT_REQUEST";
export const GET_SECTION_STUDENT_SUCCESS = "GET_SECTION_STUDENT_SUCCESS";
export const GET_SECTION_STUDENT_ERROR = "GET_SECTION_STUDENT_ERROR";
// !
export const GET_INSTRUCTOR_STUTISTICS_REQUEST =
  "GET_INSTRUCTOR_STUTISTICS_REQUEST";
export const GET_INSTRUCTOR_STUTISTICS_SUCCESS =
  "GET_INSTRUCTOR_STUTISTICS_SUCCESS";
export const GET_STUDENTS_COUNT_SUCCESS = "GET_STUDENTS_COUNT_SUCCESS";
export const GET_INSTRUCTOR_STUTISTICS_ERROR =
  "GET_INSTRUCTOR_STUTISTICS_ERROR";

export const GET_INSTRUCTOR_GRADE_MARK_REQUEST =
  "GET_INSTRUCTOR_GRADE_MARK_REQUEST";
export const GET_INSTRUCTOR_GRADE_MARK_SUCCESS =
  "GET_INSTRUCTOR_GRADE_MARK_SUCCESS";
export const GET_INSTRUCTOR_GRADE_MARK_ERROR =
  "GET_INSTRUCTOR_GRADE_MARK_ERROR";
export const GET_ASSIGNMENTS_REQUEST = "GET_ASSIGNMENTS_REQUEST";
export const GET_ASSIGNMENTS_SUCCESS = "GET_ASSIGNMENTS_SUCCESS";
export const GET_ASSIGNMENTS_ERROR = "GET_ASSIGNMENTS_ERROR";

export const GET_INSTRUCTOR_GRADE_TOPIC_MARK_REQUEST =
  "GET_INSTRUCTOR_GRADE_TOPIC_MARK_REQUEST";
export const GET_INSTRUCTOR_GRADE_TOPIC_MARK_SUCCESS =
  "GET_INSTRUCTOR_GRADE_TOPIC_MARK_SUCCESS";
export const GET_INSTRUCTOR_GRADE_TOPIC_MARK_ERROR =
  "GET_INSTRUCTOR_GRADE_TOPIC_MARK_ERROR";

export const GET_INSTRUCTOR_GRADE_HW_MARK_REQUEST =
  "GET_INSTRUCTOR_GRADE_HW_MARK_REQUEST";
export const GET_INSTRUCTOR_GRADE_HW_MARK_SUCCESS =
  "GET_INSTRUCTOR_GRADE_HW_MARK_SUCCESS";
export const GET_INSTRUCTOR_GRADE_HW_MARK_ERROR =
  "GET_INSTRUCTOR_GRADE_HW_MARK_ERROR";

export const GET_INSTRUCTOR_GRADE_GRADE_SHEET_BY_COURSES_REQUEST =
  "GET_INSTRUCTOR_GRADE_GRADE_SHEET_BY_COURSES_REQUEST";
export const GET_INSTRUCTOR_GRADE_GRADE_SHEET_BY_COURSES_SUCCESS =
  "GET_INSTRUCTOR_GRADE_GRADE_SHEET_BY_COURSES_SUCCESS";
export const GET_INSTRUCTOR_GRADE_GRADE_SHEET_BY_COURSES_ERROR =
  "GET_INSTRUCTOR_GRADE_GRADE_SHEET_BY_COURSES_ERROR";

export const GET_INSTRUCTOR_COURSES_REQUEST = "GET_INSTRUCTOR_COURSES_REQUEST";
export const GET_INSTRUCTOR_COURSES_SUCCESS = "GET_INSTRUCTOR_COURSES_SUCCESS";
export const GET_INSTRUCTOR_COURSES_ERROR = "GET_INSTRUCTOR_COURSES_ERROR";

export const GET_CLASS_COURSES_REQUEST = "GET_CLASS_COURSES_REQUEST";
export const GET_CLASS_COURSES_SUCCESS = "GET_CLASS_COURSES_SUCCESS";
export const GET_CLASS_COURSES_ERROR = "GET_CLASS_COURSES_ERROR";

export const QUARTER_TITLE_REQUEST = "QUARTER_TITLE_REQUEST";
export const GET_QUARTER_TITLE_REQUEST_SUCCESS =
  "GET_QUARTER_TITLE_REQUEST_SUCCESS";
export const GET_QUARTER_TITLE_REQUEST_ERROR =
  "GET_QUARTER_TITLE_REQUEST_ERROR";
export const GET_QUARTER_GRADE_ANALYTIC_COUNT_REQUEST =
  "GET_QUARTER_GRADE_ANALYTIC_COUNT_REQUEST";
export const GET_QUARTER_GRADE_ANALYTIC_COUNT_SUCCESS =
  "GET_QUARTER_GRADE_ANALYTIC_COUNT_SUCCESS";
export const GET_QUARTER_GRADE_ANALYTIC_COUNT_ERROR =
  "GET_QUARTER_GRADE_ANALYTIC_COUNT_ERROR";

export const GET_COURSES_CLASS_ID_REQUEST = "GET_COURSES_CLASS_ID_REQUEST";
export const GET_COURSES_CLASS_ID_SUCCESS = "GET_COURSES_CLASS_ID_SUCCESS";
export const GET_COURSES_CLASS_ID_ERROR = "GET_COURSES_CLASS_ID_ERROR";

export const GET_COURSES_STUDENT_ID_REQUEST = "GET_COURSES_STUDENT_ID_REQUEST";
export const GET_COURSES_STUDENT_ID_SUCCESS = "GET_COURSES_STUDENT_ID_SUCCESS";
export const GET_COURSES_STUDENT_ID_ERROR = "GET_COURSES_STUDENT_ID_ERROR";

export const GET_TOWNS_REQUEST = "GET_TOWNS_REQUEST";
export const GET_TOWNS_SUCCESS = "GET_TOWNS_SUCCESS";
export const GET_TOWNS_ERROR = "GET_TOWNS_ERROR";

export const GET_STUDENT_GRADE_ANALYTICS_REQUEST =
  "GET_STUDENT_GRADE_ANALYTICS_REQUEST";
export const GET_STUDENT_GRADE_ANALYTICS_SUCCESS =
  "GET_STUDENT_GRADE_ANALYTICS_SUCCESS";
export const GET_STUDENT_GRADE_ANALYTICS_ERROR =
  "GET_STUDENT_GRADE_ANALYTICS_ERROR";

export const GET_STUDENT_ATTENDANCE_ANALYTICS_REQUEST =
  "GET_STUDENT_ATTENDANCE_ANALYTICS_REQUEST";
export const GET_STUDENT_ATTENDANCE_ANALYTICS_SUCCESS =
  "GET_STUDENT_ATTENDANCE_ANALYTICS_SUCCESS";
export const GET_STUDENT_ATTENDANCE_ANALYTICS_ERROR =
  "GET_STUDENT_ATTENDANCE_ANALYTICS_ERROR";
