import {
  GET_CLASS_REQUEST,
  GET_CLASS_SUCCESS,
  GET_CLASS_ERROR,
  GET_CLASS_ONE_REQUEST,
  GET_CLASS_ONE_SUCCESS,
  GET_CLASS_ONE_ERROR,
} from "../actionTypes";
import { statuses } from "../../config";

const initialState = {
  class: [],
  classOne: [],
  error: "",
  status: statuses.INITIAL,
};

export const classReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CLASS_REQUEST:
      return {
        ...state,
        class: [],
        error: "",
        status: statuses.LOADING,
      };
    case GET_CLASS_SUCCESS:
      return {
        ...state,
        class: action.payload,
        error: "",
        status: statuses.SUCCESS,
      };
    case GET_CLASS_ERROR:
      return {
        ...state,
        class: [],
        error: action.payload,
        status: statuses.ERROR,
      };
    case GET_CLASS_ONE_REQUEST:
      return {
        ...state,
        classOne: [],
        error: "",
        status: statuses.LOADING,
      };
    case GET_CLASS_ONE_SUCCESS:
      return {
        ...state,
        classOne: action.payload,
        error: "",
        status: statuses.SUCCESS,
      };
    case GET_CLASS_ONE_ERROR:
      return {
        ...state,
        classOne: [],
        error: action.payload,
        status: statuses.ERROR,
      };

    default:
      return state;
  }
};
