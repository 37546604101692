import React from "react";
import PT from "prop-types";
import { LanguageDropdown, GoBack } from "../../components";

import "./Layout.scss";

export default function LoginLayout(props) {
  const { children, style } = props;
  return (
    <div className="login-layout">
      <div className="login-layout__inner container">
        <div className="login-layout__header">
          <GoBack />
          <LanguageDropdown />
        </div>
        <div className="v-center h-center">
          <div className="layout__card" style={{ ...style }}>
            <div className="layout__card-inner">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

LoginLayout.propTypes = {
  children: PT.any,
  style: PT.object,
};
