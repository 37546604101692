import { urls, toastOption } from "../../config";
import { API } from "../../utils";
import { toast } from "react-toastify";
import i18n from "../../i18n";

import {
  GET_LANGUAGES_REQUEST,
  GET_LANGUAGES_SUCCESS,
  GET_LANGUAGES_ERROR,
} from "../actionTypes";

export const getLanguages = () => async (dispatch) => {
  dispatch(getLanguagesRequest());
  await API.get(`${urls.LANGUAGE}`)
    .then((res) => {
      console.log(res.data);
      dispatch(getLanguagesSuccess(res.data));
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getLanguagesFailure(error.message));
      return error.message;
    });
};

export const getLanguagesRequest = () => {
  return {
    type: GET_LANGUAGES_REQUEST,
  };
};

export const getLanguagesSuccess = (data) => {
  return {
    type: GET_LANGUAGES_SUCCESS,
    payload: data,
  };
};

export const getLanguagesFailure = (error) => {
  return {
    type: GET_LANGUAGES_ERROR,
    payload: error,
  };
};
