import React, { useEffect, useState } from "react";
import { ChevronRight, HelpCircle, Play } from "react-feather";
import { useTranslation } from "react-i18next";

import { Chart, SchoolAdminLayout, DatePickerCalendar } from "../../components";
import Student from "../../assets/images/statistic-student.png";
import Teacher from "../../assets/images/statistic-teacher.png";
import Perents from "../../assets/images/statistic-perents.png";
import "./SchoolAdmin.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  instructorGenderAnalytic,
  studentsGenderAnalytic,
  parentGenderAnalytic,
  assignmentCountAnalyticBySchool,
  homeWorkAnalyticBySchool,
  topicAnalyticBySchool,
} from "../../store/analytic/action";
import { Link } from "react-router-dom";

function Statistics() {
  const date = new Date();
  let start = new Date();
  start.setDate(start.getDate() - 20);

  const { t } = useTranslation();
  const [activeButton, setActiveButton] = useState(3);
  const [startDate, setStartDate] = useState(`${start.getFullYear()}-${getD(start.getMonth()+1)}-${getD(start.getDate())}`);
  const [endDate, setEndDate] = useState(`${date.getFullYear()}-${getD(date.getMonth()+1)}-${getD(date.getDate())}`);
  const dispatch = useDispatch();
  const rayon = JSON.parse(localStorage.getItem("school")).rayon.id;
  const school = JSON.parse(localStorage.getItem("school")).id;
  console.log(activeButton);
  
  function getD(date){
    if(date>9) {
      return date;
    }
    return `0${date}`;
  }
  useEffect(() => {
    dispatch(instructorGenderAnalytic(rayon, school));
    dispatch(studentsGenderAnalytic(rayon, school));
    dispatch(parentGenderAnalytic(school));
    dispatch(assignmentCountAnalyticBySchool(school, startDate, endDate));
    dispatch(topicAnalyticBySchool(school, startDate, endDate));
    dispatch(homeWorkAnalyticBySchool(school, startDate, endDate));
  }, [dispatch]);

  function handleButton() {
    dispatch(assignmentCountAnalyticBySchool(school, startDate, endDate));
    dispatch(topicAnalyticBySchool(school, startDate, endDate));
    dispatch(homeWorkAnalyticBySchool(school, startDate, endDate));
  }
  const state = useSelector((state) => state.analytic);

  const intructors =
    state?.instructorGenderAnalytic?.maleCount +
    state?.instructorGenderAnalytic?.femaleCount;
  const students =
    state?.studentsGenderAnalytic?.maleCount +
    state?.studentsGenderAnalytic?.femaleCount;
  const parents =
    state?.parentsGenderAnalytic?.maleCount +
    state?.parentsGenderAnalytic?.femaleCount;
  const homeWork = state.assessments;
  const topic = state.topic;
  const assessments = state.hw;

  return (
    <SchoolAdminLayout>
      <div className="top_title">
        <div className="top_title_content">
          <span className="top_title_text">
            <p className="top_title_text_bold">
              {t("schoolAdmin:school statistics")}
            </p>
            {t("schoolAdmin:all data is updated in real time")}
          </span>
        </div>
        <div className="year_button_box">
          <div className="year_button">
            {JSON.parse(localStorage.getItem("chronicleActive")).selectorTitle}
          </div>
        </div>
      </div>
      <div className="content_box">
        <div className="school_info">
          <div>
            <p>{t("schoolAdmin:students")}</p>
            <span>{students ? students : "0"}</span>
          </div>
          <img src={Student} />
        </div>
        <div className="school_info">
          <div>
            <p>{t("schoolAdmin:insctructor")}</p>
            <span>{intructors ? intructors : "0"}</span>
          </div>
          <img src={Teacher} />
        </div>
        <div className="school_info">
          <div>
            <p>{t("schoolAdmin:parents")}</p>
            <span>{parents ? parents : 0}</span>
          </div>
          <img src={Perents} />
        </div>
      </div>
      <div className="statistics_main">
        <HelpCircle className="question" />
        <div className="statistics_main_header">
          <p className="statistics_main_header_title">
            {t("schoolAdmin:teacher activity")}
          </p>
          <Link
            to="/school-admin/statistics/detail"
            className="link_to_description"
          >
            <ChevronRight />
            {t("schoolAdmin:more details")}
          </Link>
          <span className="statistics_main_header_under_title">
            {t("schoolAdmin:select period and type")}
          </span>
          <div></div>
          <div className="filter_statistic_date">
            <div className="filter_statistic_date_start">
              <DatePickerCalendar
                onSelect={(e) => {
                  setStartDate(e?.target?.value);
                  console.log(e?.target?.value);
                }}
                onChange
                placeholder={startDate}
                name="startDate"
              />
            </div>
            <div className="filter_statistic_date_end">
              <DatePickerCalendar
                onSelect={(e) => {
                  setEndDate(e?.target?.value);
                  console.log(e?.target?.value);
                }}
                onChange
                placeholder={endDate}
                name="startDate"
              />
            </div>
            <div className="filter_statistic_date_end">
              <button
                onClick={() => {
                  handleButton();
                }}
                style={{ color: "#a3aed0" }}
              >
                {t("common:search")} <Play />
              </button>
            </div>
          </div>
          <div className="filter_statistic_another">
            <button
              className={
                activeButton == 1
                  ? "filter_active"
                  : "filter_statistic_another_button"
              }
              onClick={() => setActiveButton(1)}
            >
              {t("instructor:home work")}
            </button>

            <button
              onClick={() => setActiveButton(2)}
              className={
                activeButton == 2
                  ? "filter_active"
                  : "filter_statistic_another_button"
              }
            >
              {t("instructor:topic")}
            </button>
            <button
              onClick={() => setActiveButton(3)}
              className={
                activeButton == 3
                  ? "filter_active"
                  : "filter_statistic_another_button"
              }
            >
              {t("schoolAdmin:evaluations")}
            </button>
          </div>
        </div>
        <Chart
          assessments={
            (activeButton == 3 && assessments) ||
            (activeButton == 2 && topic) ||
            (activeButton == 1 && homeWork)
          }
        />
      </div>
    </SchoolAdminLayout>
  );
}

export default Statistics;
