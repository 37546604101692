import {
  GET_QUARTERS_REQUEST,
  GET_QUARTERS_SUCCESS,
  GET_QUARTERS_ERROR,
  GET_QUARTER_BY_ID_REQUEST,
  GET_QUARTER_BY_ID_SUCCESS,
  GET_QUARTER_BY_ID_ERROR,
} from "../actionTypes";
import { statuses } from "../../config";

const initialState = {
  quarters: [],
  quarter: [],
  error: "",
  status: statuses.INITIAL,
};

export const quartersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_QUARTERS_REQUEST:
      return {
        ...state,
        quarters: [],
        error: "",
        status: statuses.LOADING,
      };
    case GET_QUARTERS_SUCCESS:
      return {
        ...state,
        quarters: action.payload,
        error: "",
        status: statuses.SUCCESS,
      };
    case GET_QUARTERS_ERROR:
      return {
        ...state,
        error: action.payload,
        status: statuses.ERROR,
        quarters: [],
      };
    case GET_QUARTER_BY_ID_REQUEST:
      return {
        ...state,
        quarter: [],
        error: "",
        status: statuses.LOADING,
      };
    case GET_QUARTER_BY_ID_SUCCESS:
      return {
        ...state,
        quarter: action.payload,
        error: "",
        status: statuses.SUCCESS,
      };
    case GET_QUARTER_BY_ID_ERROR:
      return {
        ...state,
        error: action.payload,
        status: statuses.ERROR,
        quarter: [],
      };

    default:
      return state;
  }
};
