import { urls, toastOption } from "../../config";
import { API } from "../../utils";
import { toast } from "react-toastify";
import i18n from "../../i18n";

import {
  GET_SCHEDULE_GROUPS_REQUEST,
  GET_SCHEDULE_GROUPS_SUCCESS,
  GET_SCHEDULE_GROUPS_ERROR,
  GET_SCHEDULE_GROUP_REQUEST,
  GET_SCHEDULE_GROUP_SUCCESS,
  GET_SCHEDULE_GROUP_ERROR,
} from "../actionTypes";

export const getScheduleGroups = () => async (dispatch) => {
  dispatch(getScheduleGroupsRequest());
  await API.get(
    `${urls.SCHEDULE_GROUP}school/${
      JSON.parse(localStorage.getItem("school"))?.id
    }`
  )
    .then((res) => {
      console.log(res.data);
      dispatch(getScheduleGroupsSuccess(res.data));
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getScheduleGroupsFailure(error.message));
      return error.message;
    });
};
export const getScheduleGroupsByClassId = (classId) => async (dispatch) => {
  dispatch(getScheduleGroupsRequest());
  await API.get(`${urls.SCHEDULE_GROUP}class/${classId}`)
    .then((res) => {
      console.log(res.data);
      dispatch(getScheduleGroupsSuccess(res.data));
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getScheduleGroupsFailure(error.message));
      return error.message;
    });
};
export const getScheduleGroupsById = (id) => async (dispatch) => {
  dispatch(getScheduleGroupRequest());
  await API.get(`${urls.SCHEDULE_GROUP}${id}`)
    .then((res) => {
      console.log(res.data);
      dispatch(getScheduleGroupSuccess(res.data));
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getScheduleGroupFailure(error.message));
      return error.message;
    });
};
export const addScheduleGroup = (value) => async (dispatch) => {
  const data = {
    ...value,
    schoolId: JSON.parse(localStorage.getItem("school"))?.id,
  };
  await API.post(`${urls.SCHEDULE_GROUP}create`, data)
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess added"), toastOption);
      dispatch(getScheduleGroups());
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      return error.message;
    });
};
export const editScheduleGroup = (value) => async (dispatch) => {
  const data = {
    ...value,
    schoolId: JSON.parse(localStorage.getItem("school"))?.id,
  };
  await API.post(`${urls.SCHEDULE_GROUP}edit/${value.groupId}`, data)
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess updated"), toastOption);
      dispatch(getScheduleGroups());
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      return error.message;
    });
};
export const deleteScheduleGroup = (id) => async (dispatch) => {
  dispatch(getScheduleGroupsRequest());
  await API.delete(
    `${urls.SCHEDULE_GROUP}?id=${id}&schoolId=${
      JSON.parse(localStorage.getItem("school"))?.id
    }`
  )
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess deleted"), toastOption);
      dispatch(getScheduleGroups());
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getScheduleGroupsFailure(error.message));
      return error.message;
    });
};
export const getScheduleGroupsRequest = () => {
  return {
    type: GET_SCHEDULE_GROUPS_REQUEST,
  };
};

export const getScheduleGroupsSuccess = (data) => {
  return {
    type: GET_SCHEDULE_GROUPS_SUCCESS,
    payload: data,
  };
};

export const getScheduleGroupsFailure = (error) => {
  return {
    type: GET_SCHEDULE_GROUPS_ERROR,
    payload: error,
  };
};

export const getScheduleGroupRequest = () => {
  return {
    type: GET_SCHEDULE_GROUP_REQUEST,
  };
};

export const getScheduleGroupSuccess = (data) => {
  return {
    type: GET_SCHEDULE_GROUP_SUCCESS,
    payload: data,
  };
};

export const getScheduleGroupFailure = (error) => {
  return {
    type: GET_SCHEDULE_GROUP_ERROR,
    payload: error,
  };
};
