import { urls, toastOption } from "../../config";
import { API } from "../../utils";
import { toast } from "react-toastify";
import i18n from "../../i18n";

import {
  GET_PAYMENTS_REQUEST,
  GET_PAYMENTS_SUCCESS,
  GET_PAYMENTS_ERROR,
} from "../actionTypes";

export const editPayment = (values) => async () => {
  let data = values;

  await API.post(`${urls.STUDENT}payment/subscription/edit/`, data)
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:success"), toastOption);
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      return error.message;
    });
};

export const getHistoryPayment = (studentId) => async (dispatch) => {
  dispatch(getPaymentsRequest());
  await API.get(`${urls.STUDENT}payment/student/${studentId}`)
    .then((res) => {
      console.log(res.data);
      dispatch(getPaymentsSuccess(res.data));
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      dispatch(getPaymentsFailure(error.message));
      return error.message;
    });
};

export const getPaymentsRequest = () => {
  return {
    type: GET_PAYMENTS_REQUEST,
  };
};

export const getPaymentsSuccess = (data) => {
  return {
    type: GET_PAYMENTS_SUCCESS,
    payload: data,
  };
};

export const getPaymentsFailure = (error) => {
  return {
    type: GET_PAYMENTS_ERROR,
    payload: error,
  };
};
