import React, { useState } from "react";
import { AlertCircle } from "react-feather";
import { useTranslation, Trans } from "react-i18next";
import { Link } from "react-router-dom";

import { SelectSchoolDropdown, LoginLayout } from "../../components";
import "./SelectSchool.scss";

export default function SelectSchool() {
  const { t } = useTranslation(["common", "userApplicationForm"]);

  const [schoolId, setSchoolId] = useState(
    JSON.parse(localStorage.getItem("userApplicationForm"))?.schoolId
  );
  const setLocalStorage = () => {
    const lStorage = JSON.parse(localStorage.getItem("userApplicationForm"));
    localStorage.setItem(
      "userApplicationForm",
      JSON.stringify({ ...lStorage, schoolId: schoolId })
    );
  };
  return (
    <div className="select-school-form">
      <LoginLayout>
        <div className="select-school-form__title h-center">
          <h3>{t("userApplicationForm:full school name")}</h3>
        </div>

        <div className="h-center">
          <SelectSchoolDropdown
            onSelectSchool={setSchoolId}
            schoolId={schoolId}
          />
        </div>
        <div className="select-school-form__button">
          <Link to="/user-application-form/1">
            <button
              className="primary small"
              disabled={!schoolId}
              onClick={setLocalStorage}
            >
              {t("common:continue")}
            </button>
          </Link>
        </div>

        <div className="select-school-form__message">
          <div className="select-school-form__message-icon">
            <AlertCircle size={20} color="#5FA9FF" />
          </div>
          <div className="select-school-form__message-text">
            <p className="caption-1">
              <Trans
                i18nKey="userApplicationForm:school not found support"
                t={t}
                components={[<br key="0" />]}
              />
            </p>
          </div>
        </div>
      </LoginLayout>
    </div>
  );
}
