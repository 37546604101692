import React from "react";
import PT from "prop-types";
import "./styles.scss";

export default function SearchField(props) {
  const { onChange, defaultValue, placeholder, args } = props;
  return (
    <div className="search_field">
      <input
        type="text"
        onChange={onChange}
        defaultValue={defaultValue}
        // value={defaultValue}
        placeholder={placeholder}
        {...args}
      />
    </div>
  );
}
SearchField.propTypes = {
  onChange: PT.func,
  defaultValue: PT.any,
  placeholder: PT.any,
  args: PT.any,
};
