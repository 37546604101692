/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import PT from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { days } from "../../config/constants";
import { FullCurrentDate } from "../../components";
import { getSchedulesByClassId } from "../../store/schedule/actions";
import { getShiftTimes } from "../../store/shiftTime/actions";
import NoData from "../NoData/NoData";

export default function MyClassSchedule(props) {
  const { classId } = props;
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const [date] = useState(moment().format("YYYY-MM-DD"));
  const [currentWeekDayNumber, setCurrentWeekDayNumber] = useState(
    moment().format("d")
  );

  const schedulesStore = useSelector((store) =>
    store.schedules.schedules.sort((a, b) =>
      a.startTime < b.startTime ? -1 : a.startTime > b.startTime ? 1 : 0
    )
  );
  const classStore = useSelector((store) => store.class.class);
  const shiftTimesStore = useSelector((store) =>
    store.shiftTimes.shiftTimes
      .sort((a, b) => a.title - b.title)
      .sort((a, b) => a.shiftTitle - b.shiftTitle)
  );
  // let a = [];

  // const sortSchedule = () => {
  //   shiftTimesStore.map((shiftTime) => {
  //     schedulesStore.map((schedule) => {
  //       if (schedule.shiftTimeId == shiftTime.shiftId) {
  //         a.push(schedule);
  //       }
  //     });
  //   });
  // };
  // sortSchedule();
  // let sorterdSchedule;
  useEffect(async () => {
    await dispatch(getSchedulesByClassId(classId));
    await dispatch(getShiftTimes());
    // sorterdSchedule = await sortSchedule();
  }, [classId]);

  const sortSchedule = () => {
    let a = [];
    shiftTimesStore.map((shiftTime) => {
      schedulesStore.map((schedule) => {
        if (schedule.shiftTimeId == shiftTime.id) {
          a.push(schedule);
        }
      });
    });
    return a;
  };
  const sortedSchedule = sortSchedule();
  console.log("sorterdSchedule", sortedSchedule);
  return (
    <div className="school-admin__schedule-group school-admin__schedule school-presetting__body school-presetting__body-color my-class__schedule">
      <div className="school-presetting__body-row">
        <div className="school-presetting__body-title">
          <h2>
            {t("schoolAdmin:schedule")}{" "}
            <span className="roboto">
              {classStore?.find((item) => item.id == classId)?.classLevel}
              {classStore?.find((item) => item.id == classId)?.classLabel}
            </span>
          </h2>
        </div>
        <div className="school-presetting__body-description">
          <p className="body-1">
            <FullCurrentDate date={date} />
          </p>
        </div>
      </div>
      <div className="my-class__schedule-body">
        <div className="my-class__schedule-body-filters">
          {days
            .slice(0, JSON.parse(localStorage.getItem("school"))?.studyDay)
            .map((item, idx) => (
              <button
                key={idx}
                className={item.id + 1 == currentWeekDayNumber ? "active" : ""}
                onClick={() => {
                  setCurrentWeekDayNumber(item.id + 1);
                }}
              >
                {item[`${i18n.language}Short`]}
              </button>
            ))}
        </div>
        <div className="my-class__schedule-data">
          {sortedSchedule?.filter(
            (item) =>
              item.weekDay ==
              days.find((item) => item.id + 1 == currentWeekDayNumber)?.en
          )?.length > 0 ? (
            sortedSchedule
              ?.filter(
                (item) =>
                  item.weekDay ==
                  days.find((item) => item.id + 1 == currentWeekDayNumber)?.en
              )
              .map((item, idx) => (
                <div className="my-class__schedule-item-wrapper" key={idx}>
                  <div className="my-class__schedule-divider">
                    <span className="roboto">
                      {
                        shiftTimesStore.find(
                          (shiftTime) => shiftTime.id == item.shiftTimeId
                        )?.startTime
                      }
                    </span>
                    <div className="dashed"></div>
                  </div>

                  <div className="my-class__schedule-item">
                    <div className="my-class__schedule-item-icon">
                      {String(i18n.language).toUpperCase() !== "EN" &&
                      String(i18n.language).toUpperCase() !== "TR"
                        ? `${item[
                            `courseTitle${String(i18n.language).toUpperCase()}`
                          ]?.slice(0, 1)}`
                        : `${item.courseTitle?.slice(0, 1)}`}
                    </div>
                    <div className="my-class__schedule-item-body">
                      <div className="my-class__schedule-item-title">
                        {String(i18n.language).toUpperCase() !== "EN" &&
                        String(i18n.language).toUpperCase() !== "TR"
                          ? `${
                              item[
                                `courseTitle${String(
                                  i18n.language
                                ).toUpperCase()}`
                              ]
                            } `
                          : `${item.courseTitle} `}
                      </div>
                      <div className="my-class__schedule-item-description roboto">
                        {
                          shiftTimesStore.find(
                            (shiftTime) => shiftTime.id == item.shiftTimeId
                          )?.startTime
                        }
                        -
                        {
                          shiftTimesStore.find(
                            (shiftTime) => shiftTime.id == item.shiftTimeId
                          )?.endTime
                        }
                      </div>
                    </div>
                    <div className="my-class__schedule-item-info roboto">
                      <p>{item.classTitle}</p>
                      <p>{item.isGroup && t("schoolAdmin:a group")}</p>
                    </div>
                  </div>
                </div>
              ))
          ) : (
            <NoData />
          )}
        </div>
      </div>
    </div>
  );
}

MyClassSchedule.propTypes = {
  classId: PT.number,
};
