/* eslint-disable indent */
import React, { useEffect, useState, useCallback } from "react";
import { debounce } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import { X, Edit2, DollarSign } from "react-feather";
import { useParams } from "react-router-dom";
import SelectSearch, { fuzzySearch } from "react-select-search";

import {
  SchoolAdminLayout,
  PaginationBlock,
  SearchField,
  NoData,
} from "../../components";

import { getAllStudentsBySchool } from "../../store/student/actions";

import { getClassBySchoolId } from "../../store/class/actions";
import { editPayment, getHistoryPayment } from "../../store/payment/actions";

export default function Students() {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  const { class_id } = useParams();

  const dispatch = useDispatch();
  // const personsStore = useSelector((store) => store.persons.persons);
  const studentsStore = useSelector((store) => store.students.students);
  const classStore = useSelector((store) => store.class.class);
  const paymentsStore = useSelector((store) => store.payments.payments);

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  // const [allData] = useState([]);
  const [filterClassTitle, setFilterClassTitle] = useState("");
  const [filteredAllData, setFilteredAllData] = useState([]);
  const [currentStudentId, setCurrentStudentId] = useState();
  const [currentData, setCurrentData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedCurrentData, setSearchedCurrentData] = useState([]);
  const [modal, setModal] = useState({
    historyPayment: false,
    editPayment: false,
    editPaymentSelected: false,
  });

  const [editPaymentType, setEditPaymentType] = useState("");
  // const [newParent, setNewParent] = useState({
  //   lastName: "",
  //   firstName: "",
  //   middleName: "",
  //   phone: "",
  //   dateOfBirth: "",
  //   job: "",
  //   jobPlace: "",
  //   roleId: 5,
  //   parentalType: "",
  // });

  useEffect(async () => {
    await dispatch(getAllStudentsBySchool());
    await dispatch(getClassBySchoolId());
  }, []);

  // const classOptions = classStore.map((item) => ({
  //   name: `${item.classLevel}${item.classLabel}`,
  //   value: item.id,
  //   key: "classId",
  // }));
  const classTitleOptions = classStore.map((item) => ({
    name: `${item.classLevel}${item.classLabel}`,
    value: `${item.classLevel}${item.classLabel}`,
    key: "filterClassTitle",
    id: item.id,
  }));
  const onInputChange = (e) => {
    setEditPaymentType(e.target.value);
  };
  // const onParentInputChange = (e) => {
  //   setNewParent({
  //     ...newParent,
  //     [e.key || e.target.name]: e.value || e.target.value,
  //   });
  // };

  const onPageChanged = (
    pageNumber,
    perPageNumber,
    data = studentsStore,
    filter = filterClassTitle
  ) => {
    const offset = (pageNumber - 1) * perPageNumber;
    let currentDataArr = [];
    if (filter) {
      let a = data.filter((item) => `${item.classTitle}` === filter);
      currentDataArr = a.slice(offset, offset + perPageNumber);
      setFilteredAllData(a);
    } else {
      currentDataArr = data.slice(offset, offset + perPageNumber);
      setFilteredAllData([]);
    }

    console.log(data);
    console.log(currentDataArr);
    return setCurrentData(currentDataArr);
  };

  const onFilterHandler = (classTitle) => {
    console.log(classTitle);
    setCurrentPage(1);
    setFilterClassTitle(classTitle);
    onPageChanged(1, perPage, studentsStore, classTitle);
  };
  // const createStudent = async () => {
  //   await dispatch(addStudent(newStudent, setCurrentStudentId));
  //   await dispatch(getAllStudentsBySchool());
  //   await setNewStudent({
  //     lastName: "",
  //     firstName: "",
  //     middleName: "",
  //     phone: "",
  //     dateOfBirth: "",
  //     nationality: "",
  //     classId: "",
  //     gender: "",
  //   });
  //   await setModal({ ...modal, step1: false, step2: true });
  // };

  const updatePayment = async (users) => {
    // console.log({
    //   parentalType: newParent.parentalType,
    //   personDto: {
    //     ...newParent,
    //     gender: newParent.parentalType == 1 ? 1 : 2,
    //   },
    //   studentId: currentStudentId,
    // });
    // await dispatch(
    //   addParent({
    //     parentalType: newParent.parentalType,
    //     personDto: {
    //       ...newParent,
    //       gender: newParent.parentalType == 1 ? 1 : 2,
    //     },
    //     studentId: currentStudentId,
    //   })
    // );
    await dispatch(
      editPayment({
        studentList: users,
        subscriptionType: editPaymentType,
      })
    );

    // // setCurrentStudentId("");
    // await setNewParent({
    //   lastName: "",
    //   firstName: "",
    //   middleName: "",
    //   phone: "",
    //   dateOfBirth: "",
    //   job: "",
    //   jobPlace: "",
    //   roleId: 5,
    //   parentalType: "",
    // });
    await setModal({
      ...modal,
      editPayment: false,
      editPaymentSelected: false,
    });
    await setEditPaymentType("");
  };

  const debouncedPerPage = useCallback(
    debounce((text, studentsStore) => {
      if (filteredAllData.length > 0) {
        onPageChanged(1, Number(text), filteredAllData);
      } else {
        onPageChanged(1, Number(text), studentsStore);
      }
      setPerPage(Number(text));
      setCurrentPage(1);
    }, 1000),
    [studentsStore, filteredAllData]
  );
  const debouncedSearch = useCallback(
    debounce((text) => {
      if (text !== "") {
        setSearchText(String(text).toLowerCase());
        setCurrentPage(1);
        setFilterClassTitle("");

        const a = studentsStore.filter((item) => {
          // let obj;
          // Object.keys(item).map((key) => {
          //   if (key !== "id" && key !== "subscriptionType") {
          //     const c = String(item[key]).toLowerCase();
          //     const d = c.includes(String(text).toLowerCase());
          //     if (d) {
          //       obj = item;
          //       return item;
          //     }
          //   }
          // });
          // return obj;
          const b = String(
            `${item.lastName} ${item.firstName} ${item.middleName}`
          ).toLowerCase();
          const c = b.includes(text.toLowerCase());
          return c;
        });
        setSearchedCurrentData([...a]);
        onPageChanged(1, perPage, [...a]);
      } else {
        setSearchedCurrentData([]);
        onPageChanged(1, perPage, studentsStore);
      }
    }, 1000),
    [perPage, studentsStore]
  );

  const onChange = async (e) => {
    console.log(e);
    setCurrentPage(e);
    if (searchedCurrentData.length > 0) {
      onPageChanged(e, perPage, searchedCurrentData);
    } else if (filteredAllData.length > 0) {
      onPageChanged(e, perPage, filteredAllData);
    } else {
      onPageChanged(e, perPage, studentsStore);
    }
  };
  const getPerPage = (e) => {
    debouncedPerPage(e.target.value);
  };
  const searchInputHandler = (e) => {
    debouncedSearch(e.target.value);
  };

  const onSelect = (value) => {
    console.log(selectedData);
    if (selectedData.find((item) => item.id === value.id)) {
      let a = selectedData.filter((item) => item.id !== value.id);
      setSelectedData([...a]);
    } else {
      setSelectedData([...selectedData, value]);
    }
  };
  const clearSelected = () => {
    setSelectedData([]);
  };
  const selectAll = () => {
    searchedCurrentData.length > 0
      ? setSelectedData(searchedCurrentData)
      : filteredAllData.length > 0
      ? setSelectedData(filteredAllData)
      : setSelectedData(studentsStore);
  };

  const tableDataRender =
    searchedCurrentData.length > 0
      ? searchedCurrentData.map((item, idx) => (
          <tr
            className={
              selectedData.find((i) => i.id === item.id) ? "active" : ""
            }
            key={idx}
          >
            <td className="table-select">
              <label className="check">
                <input
                  type="checkbox"
                  onChange={() => onSelect(item)}
                  checked={selectedData.find((i) => i.id === item.id)}
                />
                <div className="box"></div>
              </label>
              <span>{idx + 1}</span>
            </td>
            <td>
              {item.lastName} {item.firstName} {item.middleName}
            </td>
            <td>{item.phone}</td>
            <td>{item.classTitle}</td>
            <td>
              {item.subscriptionType === 1 ? (
                <span className="orange-text">
                  {t("schoolAdmin:payment premium")}
                </span>
              ) : (
                <span className="gray-text">
                  {t("schoolAdmin:payment free")}
                </span>
              )}
            </td>
            <td>
              <div className="school-admin__profile-link">
                <button
                  onClick={() => {
                    setModal({ ...modal, historyPayment: true }),
                      setCurrentStudentId(item.id);
                  }}
                >
                  <DollarSign color="#0066ff" />
                </button>
                <button
                  onClick={() => {
                    setModal({ ...modal, editPayment: true }),
                      setCurrentStudentId(item.id);
                  }}
                >
                  <Edit2 color="#14A38B" />
                </button>
              </div>
            </td>
          </tr>
        ))
      : currentData.length > 0
      ? currentData.map((item, idx) => (
          <tr
            className={
              selectedData.find((i) => i.id === item.id) ? "active" : ""
            }
            key={idx}
          >
            <td className="table-select">
              <label className="check">
                <input
                  type="checkbox"
                  onChange={() => onSelect(item)}
                  checked={selectedData.find((i) => i.id === item.id)}
                />
                <div className="box"></div>
              </label>
              <span>{idx + 1}</span>
            </td>
            <td>
              {item.lastName} {item.firstName} {item.middleName}
            </td>
            <td>{item.phone}</td>
            <td>{item.classTitle}</td>
            <td>
              {item.subscriptionType === 1 ? (
                <span className="orange-text">
                  {t("schoolAdmin:payment premium")}
                </span>
              ) : (
                <span className="gray-text">
                  {t("schoolAdmin:payment free")}
                </span>
              )}
            </td>
            <td>
              <div className="school-admin__profile-link">
                <button
                  onClick={() => {
                    setModal({ ...modal, historyPayment: true }),
                      setCurrentStudentId(item.id);
                  }}
                >
                  <DollarSign color="#0066ff" />
                </button>
                <button
                  onClick={() => {
                    setModal({ ...modal, editPayment: true }),
                      setCurrentStudentId(item.id);
                  }}
                >
                  <Edit2 color="#14A38B" />
                </button>
              </div>
            </td>
          </tr>
        ))
      : filteredAllData.length > 0
      ? filteredAllData.map((item, idx) => (
          <tr
            className={
              selectedData.find((i) => i.id === item.id) ? "active" : ""
            }
            key={idx}
          >
            <td className="table-select">
              <label className="check">
                <input
                  type="checkbox"
                  onChange={() => onSelect(item)}
                  checked={selectedData.find((i) => i.id === item.id)}
                />
                <div className="box"></div>
              </label>
              <span>{idx + 1}</span>
            </td>
            <td>
              {item.lastName} {item.firstName} {item.middleName}
            </td>
            <td>{item.phone}</td>
            <td>{item.classTitle}</td>
            <td>
              {item.subscriptionType === 1 ? (
                <span className="orange-text">
                  {t("schoolAdmin:payment premium")}
                </span>
              ) : (
                <span className="gray-text">
                  {t("schoolAdmin:payment free")}
                </span>
              )}
            </td>
            <td>
              <div className="school-admin__profile-link">
                <button
                  onClick={() => {
                    setModal({ ...modal, historyPayment: true }),
                      setCurrentStudentId(item.id);
                  }}
                >
                  <DollarSign color="#0066ff" />
                </button>
                <button
                  onClick={() => {
                    setModal({ ...modal, editPayment: true }),
                      setCurrentStudentId(item.id);
                  }}
                >
                  <Edit2 color="#14A38B" />
                </button>
              </div>
            </td>
          </tr>
        ))
      : studentsStore.slice([0], [10]).map((item, idx) => (
          <tr
            className={
              selectedData.find((i) => i.id === item.id) ? "active" : ""
            }
            key={idx}
          >
            <td className="table-select">
              <label className="check">
                <input
                  type="checkbox"
                  onChange={() => onSelect(item)}
                  checked={selectedData.find((i) => i.id === item.id)}
                />
                <div className="box"></div>
              </label>
              <span>{idx + 1}</span>
            </td>
            <td>
              {item.lastName} {item.firstName} {item.middleName}
            </td>
            <td>{item.phone}</td>
            <td>{item.classTitle}</td>
            <td>
              {item.subscriptionType === 1 ? (
                <span className="orange-text">
                  {t("schoolAdmin:payment premium")}
                </span>
              ) : (
                <span className="gray-text">
                  {t("schoolAdmin:payment free")}
                </span>
              )}
            </td>
            <td>
              <div className="school-admin__profile-link">
                <button
                  onClick={() => {
                    setModal({ ...modal, historyPayment: true }),
                      setCurrentStudentId(item.id);
                  }}
                >
                  <DollarSign color="#0066ff" />
                </button>
                <button
                  onClick={() => {
                    setModal({ ...modal, editPayment: true }),
                      setCurrentStudentId(item.id);
                  }}
                >
                  <Edit2 color="#14A38B" />
                </button>
              </div>
            </td>
          </tr>
        ));
  return (
    <SchoolAdminLayout>
      <div className="school-presetting__body school-presetting__body-color">
        <div className="school-presetting__body-row">
          <div className="school-presetting__body-title">
            <h2>{t("schoolAdmin:payment title")}</h2>
          </div>
          <div className="school-presetting__body-description">
            <p className="body-1">{t("schoolAdmin:payment description")}</p>
          </div>
        </div>

        <div className="school-presetting__subjects-list">
          <div className="school-presetting__search-filter">
            <div className="school-presetting__search">
              <SearchField
                onChange={searchInputHandler}
                defaultValue={searchText}
                placeholder={`${t("common:search")}`}
              />
              <SelectSearch
                options={[
                  {
                    name: `${t("schoolAdmin:all")}`,
                    value: "",
                    key: "filterClassTitle",
                  },
                  ...classTitleOptions,
                ]}
                search
                className="select-search filter"
                filterOptions={fuzzySearch}
                name="filterClassTitle"
                onChange={(_, k) => {
                  onFilterHandler(k.value);
                }}
                required
                placeholder={`${t("schoolAdmin:class")}`}
              />
            </div>
            {/* <div className="school-presetting__filter">
              <button
                className="primary small"
                onClick={() => setModal({ ...modal, step1: true })}
              >
                {t("schoolAdmin:create a student")}
              </button>
            </div> */}
          </div>
          {filteredAllData.length == 0 &&
          (filterClassTitle !== "" || class_id) ? (
            <NoData messages={[`${t("schoolAdmin:empty")}`]} />
          ) : studentsStore.length > 0 ? (
            <>
              <div className="school-presetting__teachers-body">
                <div className="school-presetting__teachers-table">
                  <table>
                    <thead>
                      <tr>
                        <th>№</th>
                        <th>{t("presetting:full name presetting")}</th>
                        <th>{t("presetting:phone presetting")}</th>
                        <th>{t("presetting:classes presetting")}</th>
                        <th>{t("schoolAdmin:payment package")}</th>
                        <th>{t("presetting:action presetting")}</th>
                      </tr>
                    </thead>
                    <tbody>{tableDataRender}</tbody>
                  </table>

                  {selectedData.length > 0 && (
                    <div className="edit-table">
                      <div className="edit-table-left">
                        <div>
                          <p className="caption-1">
                            {t("presetting:choosen presetting")}:
                            <span>{selectedData.length}</span>
                          </p>
                        </div>
                        <div>
                          <button onClick={selectAll}>
                            {t("schoolAdmin:select all")}
                          </button>
                        </div>
                        <div>
                          <button onClick={clearSelected}>
                            {t("presetting:consel presetting")}
                          </button>
                        </div>
                      </div>
                      <div className="edit-table-right">
                        <div>
                          <button
                            onClick={() => {
                              setModal({ ...modal, editPaymentSelected: true });
                            }}
                          >
                            {t("schoolAdmin:payment choose package")}
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="pagination">
                <div className="paginaiton__inner">
                  <div className="pagination__per-page">
                    <p>
                      {t("presetting:showing presetting")}{" "}
                      <input
                        type="phone"
                        defaultValue={perPage}
                        onChange={getPerPage}
                      />{" "}
                      {t("presetting:from presetting")}{" "}
                      {searchedCurrentData.length > 0
                        ? searchedCurrentData.length
                        : filteredAllData.length > 0
                        ? filteredAllData.length
                        : studentsStore.length}{" "}
                      {t("presetting:lines presetting")}
                    </p>
                  </div>
                  <div className="pagination__page">
                    <PaginationBlock
                      total={
                        searchedCurrentData.length > 0
                          ? searchedCurrentData.length
                          : filteredAllData.length > 0
                          ? filteredAllData.length
                          : studentsStore.length
                      }
                      current={currentPage}
                      onChange={onChange}
                      pageSize={perPage}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <NoData messages={[`${t("schoolAdmin:here is nothing")}`]} />
          )}
        </div>
      </div>
      <Modal
        isOpen={modal.editPayment}
        onRequestClose={() => {
          setModal({ ...modal, editPayment: false });
          setEditPaymentType("");
        }}
        overlayClassName="modal-overlay small"
        onAfterOpen={() => dispatch(getClassBySchoolId())}
      >
        <div className="modal">
          <div className="modal-close">
            <button
              onClick={() => {
                setModal({ ...modal, editPayment: false });
                setEditPaymentType("");
              }}
            >
              <X size={15} color="#9A9A9A" />
            </button>
          </div>
          <div className="modal-header">
            <div className="modal-header-title">
              <h2>
                {
                  studentsStore.find((item) => item.id === currentStudentId)
                    ?.lastName
                }{" "}
                {
                  studentsStore.find((item) => item.id === currentStudentId)
                    ?.firstName
                }{" "}
                {
                  studentsStore.find((item) => item.id === currentStudentId)
                    ?.middleName
                }
              </h2>
            </div>
            <div className="modal-header-description">
              <p className="body-2">
                {t("schoolAdmin:payment show student payment type description")}
              </p>
            </div>
          </div>
          <div className="modal-body">
            <div className="modal-inputs-list">
              <div className="modal-input">
                {/* <p className="body-2">{t("presetting:gender presetting")}</p> */}
                <div className="gender">
                  <div>
                    <input
                      type="radio"
                      name="subscriptionType"
                      value="FREE"
                      checked={
                        editPaymentType === "FREE" ||
                        studentsStore.find(
                          (item) => item.id === currentStudentId
                        )?.subscriptionType === 0 ||
                        studentsStore.find(
                          (item) => item.id === currentStudentId
                        )?.subscriptionType === null
                      }
                      onChange={onInputChange}
                    />
                    <label htmlFor="subscriptionType">
                      {t("schoolAdmin:payment free")}
                    </label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      name="subscriptionType"
                      value="PREMIUM"
                      checked={
                        editPaymentType === "PREMIUM" ||
                        studentsStore.find(
                          (item) => item.id === currentStudentId
                        )?.subscriptionType === 1
                      }
                      onChange={onInputChange}
                    />
                    <label htmlFor="subscriptionType">
                      {t("schoolAdmin:payment premium")}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="large primary"
              onClick={async () => {
                await updatePayment([currentStudentId]);
                await dispatch(getAllStudentsBySchool());
              }}
            >
              {t("common:ready")}
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modal.editPaymentSelected}
        onRequestClose={() => {
          setModal({ ...modal, editPaymentSelected: false });
          setEditPaymentType("");
        }}
        overlayClassName="modal-overlay small"
        onAfterOpen={() => dispatch(getClassBySchoolId())}
      >
        <div className="modal">
          <div className="modal-close">
            <button
              onClick={() => {
                setModal({ ...modal, editPaymentSelected: false });
                setEditPaymentType("");
              }}
            >
              <X size={15} color="#9A9A9A" />
            </button>
          </div>
          <div className="modal-header">
            <div className="modal-header-title">
              <h2>
                {t("schoolAdmin:payment show student payment type title")}
              </h2>
            </div>
            <div className="modal-header-description">
              <p className="body-2">
                {t("schoolAdmin:payment show student payment type description")}
              </p>
            </div>
          </div>
          <div className="modal-body">
            <div className="modal-inputs-list">
              <div className="modal-input">
                {/* <p className="body-2">{t("presetting:gender presetting")}</p> */}
                <div className="gender">
                  <div>
                    <input
                      type="radio"
                      name="subscriptionType"
                      value="FREE"
                      checked={
                        editPaymentType === "FREE" ||
                        studentsStore.find(
                          (item) => item.id === currentStudentId
                        )?.subscriptionType === 0 ||
                        studentsStore.find(
                          (item) => item.id === currentStudentId
                        )?.subscriptionType === null
                      }
                      onChange={onInputChange}
                    />
                    <label htmlFor="subscriptionType">
                      {t("schoolAdmin:payment free")}
                    </label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      name="subscriptionType"
                      value="PREMIUM"
                      checked={
                        editPaymentType === "PREMIUM" ||
                        studentsStore.find(
                          (item) => item.id === currentStudentId
                        )?.subscriptionType === 1
                      }
                      onChange={onInputChange}
                    />
                    <label htmlFor="subscriptionType">
                      {t("schoolAdmin:payment premium")}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="large primary"
              onClick={async () => {
                await updatePayment(selectedData.map((item) => item.id));
                await setSelectedData([]);
                await dispatch(getAllStudentsBySchool());
              }}
            >
              {t("common:ready")}
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modal.historyPayment}
        onRequestClose={() => {
          setModal({ ...modal, historyPayment: false });
        }}
        overlayClassName="modal-overlay small"
        onAfterOpen={() => dispatch(getHistoryPayment(currentStudentId))}
      >
        <div className="modal">
          <div className="modal-close">
            <button
              onClick={() => {
                setModal({ ...modal, historyPayment: false });
              }}
            >
              <X size={15} color="#9A9A9A" />
            </button>
          </div>
          <div className="modal-header">
            <div className="modal-header-title">
              <h2>{t("schoolAdmin:payment history")}</h2>
            </div>
          </div>
          <div className="modal-body">
            <div className="modal-inputs-list">
              <div className="payment-history">
                {paymentsStore.length > 0 ? (
                  paymentsStore.map((item, idx) => (
                    <div key={idx} className="payment-history-item">
                      <div className="payment-history__header">
                        <div className="payment-history__header-left">
                          <p>{item.amount}</p>
                        </div>
                        <div className="payment-history__header-right">
                          <p>{item.createdAt}</p>
                        </div>
                      </div>
                      <div className="payment-history__description">
                        <p>{item.description}</p>
                      </div>
                    </div>
                  ))
                ) : (
                  <NoData messages={[`${t("schoolAdmin:here is nothing")}`]} />
                )}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="large primary"
              onClick={async () => {
                setModal({ ...modal, historyPayment: false });
              }}
            >
              {t("common:close")}
            </button>
          </div>
        </div>
      </Modal>
    </SchoolAdminLayout>
  );
}
