/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { X, PlusCircle } from "react-feather";
import { useTranslation } from "react-i18next";
// import { Link } from "react-router-dom";
import { RotateSpinner } from "react-spinners-kit";
import Tooltip from "react-simple-tooltip";

import { SchoolAdminLayout, TimePickerCalendar } from "../../components";

import { getShifts, createShift, deleteShift } from "../../store/shift/actions";
import {
  getShiftTimes,
  createShiftTime,
  removeShiftTime,
  editShiftTime,
  // createMassiveShiftTime,
} from "../../store/shiftTime/actions";
// import { stepUpSchoolPresetting } from "../../store/schoolPresetting/actions";
import { statuses } from "../../config";

export default function SchoolPresetting2() {
  const dispatch = useDispatch();
  const { t } = useTranslation(["presetting", "common"]);
  const shiftsStore = useSelector((store) =>
    store.shifts.shifts?.sort((a, b) => Number(a.title) - Number(b.title))
  );
  const shiftTimesStore = useSelector((store) =>
    store.shiftTimes.shiftTimes?.sort(
      (a, b) => Number(a.title) - Number(b.title)
    )
  );
  const shiftsStoreStatus = useSelector((store) => store.shifts.status);
  const shiftTimesStoreStatus = useSelector((store) => store.shiftTimes.status);

  const [shifts, setShifts] = useState([{ title: 1, id: 1 }]);
  const [shiftTimes, setShiftTimes] = useState([
    {
      id: 1,
      title: 1,
      startTime: "08:00",
      endTime: "08:45",
      shiftId: 1,
    },
    {
      id: 2,
      title: 2,
      startTime: "08:50",
      endTime: "09:35",
      shiftId: 1,
    },
    {
      id: 3,
      title: 3,
      startTime: "09:40",
      endTime: "10:25",
      shiftId: 1,
    },
    {
      id: 4,
      title: 4,
      startTime: "10:30",
      endTime: "11:15",
      shiftId: 1,
    },
    {
      id: 5,
      title: 5,
      startTime: "11:20",
      endTime: "12:05",
      shiftId: 1,
    },
  ]);

  useEffect(() => {
    dispatch(getShifts());
    dispatch(getShiftTimes());
  }, []);

  const addZeroLesson = async (id) => {
    if (
      shiftTimesStoreStatus === statuses.SUCCESS &&
      shiftTimesStore.length > 0
    ) {
      await dispatch(
        createShiftTime({
          startTime: "08:00",
          endTime: "08:45",
          schoolId: JSON.parse(localStorage.getItem("school"))?.id,
          shiftId: id,
          title: 0,
          shiftType: 0,
        })
      );
      await dispatch(getShiftTimes());
    } else {
      setShiftTimes([
        {
          title: 0,
          startTime: "08:00",
          endTime: "08:45",
          shiftId: id,
          shiftType: 0,
        },
        ...shiftTimes,
      ]);
    }
  };
  const removeZeroLesson = (shiftId) => {
    let a = shiftTimes;
    let b = a.filter(
      (item) => item.shiftId !== shiftId || item.shiftType !== 0
    );
    console.log(b);
    setShiftTimes([...b]);
  };
  const addLesson = (id) => {
    if (
      (shiftTimesStoreStatus === statuses.SUCCESS &&
        shiftTimesStore.length > 0) ||
      shiftsStore.length > 0
    ) {
      dispatch(
        createShiftTime({
          startTime: "08:00",
          endTime: "08:45",
          schoolId: JSON.parse(localStorage.getItem("school"))?.id,
          shiftId: id,
          title:
            shiftTimesStore.filter((item) => item.shiftId === id).length + 1,
        })
      );
    } else {
      setShiftTimes([
        ...shiftTimes,
        {
          id: Number(shiftTimes?.slice(-1)[0]?.id) + 1,
          title:
            shiftTimes?.filter((item) => item.shiftId == id).length > 0
              ? Number(
                  shiftTimes?.filter((item) => item.shiftId == id)?.slice(-1)[0]
                    ?.title
                ) + 1
              : 1,
          startTime: "08:00",
          endTime: "08:45",
          shiftId: id,
        },
      ]);
    }
  };
  const removeLastLesson = (id) => {
    if (
      shiftTimesStoreStatus === statuses.SUCCESS &&
      shiftTimesStore.length > 0
    ) {
      dispatch(removeShiftTime(id));
    } else {
      let a = shiftTimes;
      a.pop();
      setShiftTimes([...a]);
    }
  };
  const addShift = () => {
    if (
      shiftTimesStoreStatus === statuses.SUCCESS &&
      shiftTimesStore.length > 0
    ) {
      dispatch(
        createShift({
          title:
            Number(
              shiftsStore?.sort((a, b) => Number(a.title) - Number(b.title))[
                shiftsStore.length - 1
              ]?.title
            ) + 1,
        })
      );
    } else {
      setShifts([
        ...shifts,
        {
          title:
            Number(
              shifts
                ?.sort((a, b) => Number(a.title) - Number(b.title))
                .slice(-1)[0]?.title
            ) + 1,
          id: shifts?.[shifts.length - 1].id + 1,
        },
      ]);
      console.log(shifts);
    }
  };
  const removeShift = (id) => {
    if (
      shiftTimesStoreStatus === statuses.SUCCESS &&
      shiftTimesStore.length > 0
    ) {
      dispatch(deleteShift(id));
    } else {
      let a = shifts;
      a.pop();
      let b = shiftTimes.filter((item) => item.shiftId !== id);
      console.log("id", id);
      console.log("b", b);
      setShifts([...a]);
      setShiftTimes([...b]);
    }
  };

  const onChangeTime = (values) => {
    if (
      shiftTimesStoreStatus === statuses.SUCCESS &&
      shiftTimesStore.length > 0
    ) {
      // console.log("hhshsh", {
      //   ...values,
      //   title:
      //     shiftTimesStore
      //       ?.filter((item) => item.shiftId === values.shiftId)
      //       ?.filter((item) => item.shiftType !== 0)
      //       .findIndex((element) => {
      //         if (element.id === values.id) {
      //           return true;
      //         }
      //       }) + 1,
      // });
      dispatch(
        editShiftTime({
          ...values,
          title:
            shiftTimesStore
              ?.filter((item) => item.shiftId === values.shiftId)
              ?.filter((item) => item.shiftType !== 0)
              .findIndex((element) => {
                if (element.id === values.id) {
                  return true;
                }
              }) + 1,
        })
      );
    } else {
      // let allData = shiftTimes;
      // allData.find((item) => item.id === values.id) = values;
      const result = shiftTimes.map((item) => {
        return item.id === values.id ? values : item;
      });
      setShiftTimes(result);
    }
  };

  let shiftsRender =
    shiftsStoreStatus === statuses.SUCCESS &&
    (shiftsStore.length > 0
      ? shiftsStore?.map((shift, idx) => (
          <div className="school-presetting__shifts" key={idx}>
            <div className="school-presetting__shift-title">
              <h3 className="orange-text">
                {t("presetting:shifts presetting")} {idx + 1}
              </h3>
              {shiftsStore.length === idx + 1 && shiftsStore.length > 1 && (
                <div className="school-presetting__shift-remove">
                  <button onClick={() => removeShift(shift.id)}>
                    <X />
                  </button>
                </div>
              )}
            </div>
            <div className="school-presetting__shift">
              <div>
                <p className="">
                  {t("presetting:lesson presetting school regulations")}
                </p>
              </div>
              <div>
                <p className="">
                  {t("presetting:start presetting school regulations")}
                </p>
              </div>
              <div>
                <p className="">
                  {t("presetting:end presetting school regulations")}
                </p>
              </div>
            </div>
            {shiftTimesStore
              .map((item) => item.shiftType === 0 && item.shiftId === shift.id)
              .includes(true) ? null : (
              <div className="school-presetting__shift-extra">
                <button onClick={() => addZeroLesson(shift.id)}>
                  <PlusCircle size={24} color="#F0783A" />
                  <span>
                    {t(
                      "presetting:add zero lesson presetting school regulations"
                    )}
                  </span>
                </button>
              </div>
            )}

            {shiftTimesStore.length > 0 &&
            shiftTimesStoreStatus === statuses.SUCCESS
              ? shiftTimesStore
                  ?.filter((item) => item.shiftId === shift.id)
                  ?.map((lesson, i) => {
                    return (
                      <div className="school-presetting__shift" key={i}>
                        <div>
                          <p>
                            {shiftTimesStore
                              ?.filter((item) => item.shiftId === shift.id)
                              .map((z) => z.shiftType)
                              .includes(0)
                              ? i
                              : i + 1}
                          </p>
                        </div>
                        <div>
                          <TimePickerCalendar
                            defaultValue={`${lesson.startTime}`}
                            onSelect={(e) =>
                              onChangeTime({
                                ...lesson,
                                endTime: moment(
                                  `${lesson.endTime}`,
                                  "H:mm"
                                ).format("HH:mm"),
                                startTime: String(e),
                              })
                            }
                          />
                        </div>
                        <div>
                          <TimePickerCalendar
                            defaultValue={`${lesson.endTime}`}
                            onSelect={(e) =>
                              onChangeTime({
                                ...lesson,
                                endTime: String(e),
                                startTime: moment(
                                  `${lesson.startTime}`,
                                  "H:mm"
                                ).format("HH:mm"),
                              })
                            }
                          />
                        </div>
                        {lesson.shiftType === 0 && (
                          <div className="school-presetting__shift-remove">
                            <Tooltip
                              className="tooltip"
                              content={t("common:delete")}
                            >
                              <button
                                onClick={() => removeLastLesson(lesson.id)}
                              >
                                <X />
                              </button>
                            </Tooltip>
                          </div>
                        )}
                        {shiftTimesStore?.filter(
                          (item) => item.shiftId === shift.id
                        ).length ===
                          i + 1 && (
                          <div className="school-presetting__shift-remove">
                            <Tooltip
                              className="tooltip"
                              content={t("common:delete")}
                            >
                              <button
                                onClick={() => removeLastLesson(lesson.id)}
                              >
                                <X />
                              </button>
                            </Tooltip>
                          </div>
                        )}
                      </div>
                    );
                  })
              : null}
            {shiftTimesStoreStatus === statuses.LOADING && (
              <div className="loader">
                <RotateSpinner size={30} color="#F0783A" loading={true} />
              </div>
            )}
            <div className="school-presetting__shift-extra">
              <button onClick={() => addLesson(shift.id)}>
                <PlusCircle size={24} color="#F0783A" />
                <span>
                  {t("presetting:add lesson presetting school regulations")}
                </span>
              </button>
            </div>
          </div>
        ))
      : shifts
          ?.sort((a, b) => Number(a.title) - Number(b.title))
          .map((shift, idx) => (
            <div className="school-presetting__shifts" key={idx}>
              <div className="school-presetting__shift-title">
                <h3 className="orange-text">Смена {shift.title}</h3>
                {shifts.length === idx + 1 && shifts.length > 1 && (
                  <div className="school-presetting__shift-remove">
                    <button onClick={() => removeShift(shift.id)}>
                      <X />
                    </button>
                  </div>
                )}
              </div>
              <div className="school-presetting__shift">
                <div>
                  <p className="">
                    {t("presetting:lesson presetting school regulations")}
                  </p>
                </div>
                <div>
                  <p className="">
                    {t("presetting:start presetting school regulations")}
                  </p>
                </div>
                <div>
                  <p className="">
                    {t("presetting:end presetting school regulations")}
                  </p>
                </div>
              </div>

              {shiftTimes
                .map(
                  (item) => item.shiftType === 0 && item.shiftId === shift.id
                )
                .includes(true) ? null : (
                <div className="school-presetting__shift-extra">
                  <button onClick={() => addZeroLesson(shift.id)}>
                    <PlusCircle size={24} color="#F0783A" />
                    <span>
                      {t(
                        "presetting:add zero lesson presetting school regulations"
                      )}
                    </span>
                  </button>
                </div>
              )}
              {shiftTimes
                ?.filter((item) => item.shiftId === shift.id)
                ?.map((lesson, i) => {
                  return (
                    <div className="school-presetting__shift" key={i}>
                      <div>
                        <p>
                          {shiftTimes
                            ?.filter((item) => item.shiftId === shift.id)
                            .map((z) => z.shiftType)
                            .includes(0)
                            ? i
                            : i + 1}
                        </p>
                      </div>
                      <div>
                        <TimePickerCalendar
                          defaultValue={`${lesson.startTime}`}
                          onSelect={(e) =>
                            onChangeTime({
                              ...lesson,
                              endTime: moment(
                                `${lesson.endTime}`,
                                "H:mm"
                              ).format("HH:mm"),
                              startTime: String(e),
                            })
                          }
                        />
                      </div>
                      <div>
                        <TimePickerCalendar
                          defaultValue={`${lesson.endTime}`}
                          onSelect={(e) =>
                            onChangeTime({
                              ...lesson,
                              endTime: String(e),
                              startTime: moment(
                                `${lesson.startTime}`,
                                "H:mm"
                              ).format("HH:mm"),
                            })
                          }
                        />
                      </div>
                      {lesson.shiftType === 0 && (
                        <div className="school-presetting__shift-remove">
                          <Tooltip
                            className="tooltip"
                            content={t("common:delete")}
                          >
                            <button
                              onClick={() => removeZeroLesson(lesson.shiftId)}
                            >
                              <X />
                            </button>
                          </Tooltip>
                        </div>
                      )}
                      {shiftTimes?.filter((item) => item.shiftId === shift.id)
                        ?.length ===
                        i + 1 && (
                        <div className="school-presetting__shift-remove">
                          <Tooltip
                            className="tooltip"
                            content={t("common:delete")}
                          >
                            <button onClick={() => removeLastLesson(shift.id)}>
                              <X />
                            </button>
                          </Tooltip>
                        </div>
                      )}
                    </div>
                  );
                })}
              <div className="school-presetting__shift-extra">
                <button onClick={() => addLesson(shift.id)}>
                  <PlusCircle size={24} color="#F0783A" />
                  <span>
                    {t("presetting:add lesson presetting school regulations")}
                  </span>
                </button>
              </div>
            </div>
          )));

  return (
    <SchoolAdminLayout>
      <div className="school-presetting__body school-presetting__body-color">
        <div className="school-presetting__body-row">
          <div className="school-presetting__body-title">
            <h2>{t("presetting:shifts presetting")}</h2>
          </div>
          <div className="school-presetting__body-description">
            <p className="body-1">{t("schoolAdmin:lorem ipsum shift")}</p>
          </div>
        </div>

        <div className="school-presetting__shifts-list">
          {shiftsRender}
          <div className="school-presetting__shifts-add">
            <button onClick={(e) => addShift(e)}>
              {t("presetting:add shift regulations")}{" "}
              {shiftsStore.length > 0
                ? shiftsStore.length + 1
                : shifts.length + 1}
            </button>
          </div>
        </div>
        <div
          className="school-presetting__body-row school-presetting__body-buttons"
          style={{ justifyContent: "center" }}
        >
          {/* <Link to="/school-presetting/1">
            <button onClick={onSubmit} className="secondary small">
              Назад
            </button>
          </Link> */}
          {/* <Link to="/school-presetting/3"> */}
          {/* <button onClick={onSubmit} className="primary large">
            Сохранить и продолжить
          </button> */}
          {/* </Link> */}
        </div>
      </div>
    </SchoolAdminLayout>
  );
}
