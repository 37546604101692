import React from "react";

import { Bar } from "react-chartjs-2";

function Chart(assessments) {
  const teacherTitle = assessments?.assessments?.map(
    (item) => `${item.instructorTitle}`
  );
  const teacherAssessments = assessments?.assessments?.map(
    (item) => `${item.totalCount}`
  );
  // console.log(teacherAssessments);
  return (
    <div>
      <Bar
        data={{
          labels: teacherTitle,
          datasets: [
            {
              label: "",
              data: teacherAssessments,
              backgroundColor: ["rgba(240, 120, 58, 1)"],
              border: "none",
            },
          ],
        }}
        style={{
          height: "451px",
          width: "1140px",
        }}
        options={{ maintainAspectRatio: false }}
      />
    </div>
  );
}

export default Chart;
