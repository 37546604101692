import React from "react";
import PT from "prop-types";
import { Field, ErrorMessage } from "formik";
import InputMask from "react-input-mask";

import TextError from "./TextError";
import { Check, X } from "react-feather";
import Kg from "../../assets/images/kg.png";

PhoneInput.propTypes = {
  label: PT.string,
  name: PT.string,
  icon: PT.element,
  form: PT.any,
  field: PT.any,
  placeholder: PT.any,
  type: PT.any,
  showIcon: PT.any,
  hideIcon: PT.any,
  changed: PT.any,
  isValid: PT.any,
  formik: PT.any,
};

function PhoneInput(props) {
  const { label, name, icon, changed, isValid, ...rest } = props;

  return (
    <div className="form-control">
      <div className="form-control__label">
        <label htmlFor={name}>{label}</label>
      </div>
      <div className="form-control__input">
        <div className="form-control__input-item">
          {icon && <span className="form-control__input-icon">{icon}</span>}

          <div className="input_container phone">
            <div className="phone_code">
              <img src={Kg} alt="KG" />
              <span className="roboto orange-text">996</span>
            </div>
            <Field
              {...rest}
              render={() => (
                <InputMask
                  id={name}
                  name={name}
                  type="text"
                  mask="(999) 999 999"
                  maskChar={" "}
                  className={
                    changed ? (isValid ? "error-input" : "success-input") : ""
                  }
                  {...rest}
                />
              )}
            />
            {changed &&
              (isValid ? (
                <X color="#FB3030" size={16} />
              ) : (
                <Check color="#3CC13B" size={16} />
              ))}
          </div>
        </div>
        <div className="form-control__input-item">
          <ErrorMessage component={TextError} name={name} />
        </div>
      </div>
    </div>
  );
}

export default PhoneInput;
