const BASE_URL = process.env.REACT_APP_BASE_URL;
const API_PREFIX = "/api/web/v1";
const API_ANALYTIC = "/api/analytic/v1";
const API_PREFIX_MOBILE = "/api/mobile/v1";

export { BASE_URL };

export const AUTH = `${BASE_URL}/api/v1/auth/`;
export const LOGIN = `${BASE_URL}/api/v1/auth/login/`;
export const USER_APPLICATION_FORM = `${BASE_URL}${API_PREFIX}/application/form/`;
export const SMS = `${BASE_URL}${API_PREFIX}/sms/`;
export const SCHOOLS = `${BASE_URL}${API_PREFIX}/school/`;
export const CLASS = `${BASE_URL}${API_PREFIX}/class/`;
export const SCHOOL_PRESET = `${BASE_URL}${API_PREFIX}/school-preset/`;
export const CHRONICLE_YEAR = `${BASE_URL}${API_PREFIX}/chronicle/`;
export const QUARTER = `${BASE_URL}${API_PREFIX}/quarter/`;
export const ADMIN_COURSE = `${BASE_URL}${API_PREFIX}/course/`;
export const COURSE = `${BASE_URL}${API_PREFIX}/school/course/`;
export const SHIFT = `${BASE_URL}${API_PREFIX}/school/shift/`;
export const SHIFT_TIME = `${BASE_URL}${API_PREFIX}/school/shift/time/`;
export const PERSON = `${BASE_URL}${API_PREFIX}/person/`;
export const INSTRUCTOR = `${BASE_URL}${API_PREFIX}/person/instructor/`;
export const LANGUAGE = `${BASE_URL}${API_PREFIX}/language/`;
export const STUDENT = `${BASE_URL}${API_PREFIX}/student/`;
export const STUDENT_MOBILE = `${BASE_URL}${API_PREFIX_MOBILE}/student/`;
export const PARENT = `${BASE_URL}${API_PREFIX}/parent/`;
export const REGION = `${BASE_URL}${API_PREFIX}/region/`;
export const RAYON = `${BASE_URL}${API_PREFIX}/rayon/`;
export const TOWN = `${BASE_URL}${API_PREFIX}/town/`;
export const USER = `${BASE_URL}${API_PREFIX}/user/`;
export const SCHEDULE = `${BASE_URL}${API_PREFIX}/schedule/`;
export const SCHEDULE_GROUP = `${BASE_URL}${API_PREFIX}/schedule/group/`;

export const INSTRUCTOR_COURSES = `${BASE_URL}${API_PREFIX}/class/instructor`;
export const CLASS_COURSES = `${BASE_URL}${API_PREFIX}/schedule/class`;

export const CALENDAR_PLAN = `${BASE_URL}${API_PREFIX_MOBILE}/calendar/plan/`;
export const CALENDAR_TOPIC = `${BASE_URL}${API_PREFIX_MOBILE}/calendar/topic/`;

export const GRADE_ANALYTIC = `${BASE_URL}${API_ANALYTIC}/grade/analytic/`;
export const ATTENDANCE_ANALYTIC = `${BASE_URL}${API_ANALYTIC}/attendance/analytic/`;

export const INSTRUCTOR_ANALYTIC = `${BASE_URL}${API_ANALYTIC}/instructor/analytic/gender?`;
export const STUDENTS_ANALYTIC = `${BASE_URL}${API_ANALYTIC}/student/analytic/gender?`;
export const PARENTS_ANALYTIC = `${BASE_URL}${API_ANALYTIC}/parent/analytic/gender?`;
export const ASSESSMENTS_ANALYTIC = `${BASE_URL}${API_ANALYTIC}/assignment/analytic/count/school`;
export const TOPIC_ANALYTIC = `${BASE_URL}${API_ANALYTIC}/topic/analytic/count/school`;
export const HW_ANALYTIC = `${BASE_URL}${API_ANALYTIC}/grade/analytic/grade-count/school`;
export const TEACHER_ANALYTIC = `${BASE_URL}${API_ANALYTIC}/grade/analytic/grade-count/instructor`;
export const STUDENTS_COUNT_ANALYTIC = `${BASE_URL}${API_ANALYTIC}/student/analytic/school`;
export const TEACHERS_GRADE_MARK = `${BASE_URL}${API_ANALYTIC}/grade/analytic/instructor`;
export const TEACHERS_TOPIC_GRADE_MARK = `${BASE_URL}${API_ANALYTIC}/topic/analytic/instructor`;
export const TEACHERS_HW_GRADE_MARK = `${BASE_URL}${API_ANALYTIC}/assignment/analytic/instructor`;

export const QUARTER_GRADE_ANALYTIC = `${BASE_URL}${API_ANALYTIC}/quarter-grade/analytic/`;

export const GRADE = `${BASE_URL}${API_PREFIX_MOBILE}/grade/`;
export const QUARTER_GRADE = `${BASE_URL}${API_PREFIX_MOBILE}/quarter-grade/`;

export const STUDENTS_ATTENDACE = `${BASE_URL}${API_ANALYTIC}/attendance/analytic/month/reason-count/`;
export const SECTION = `${BASE_URL}${API_PREFIX}/school/section/`;
export const ASSIGNMENT = `${BASE_URL}${API_PREFIX}/assignment/`;

export const QUATERS_TITLE = `${BASE_URL}${API_PREFIX}/quarter/school/`;

export const REPORT_PUPILS = `${BASE_URL}${API_ANALYTIC}/move/analytic/quarter/`;
