/* eslint-disable indent */
import { urls, toastOption } from "../../config";
import { API } from "../../utils";
import { toast } from "react-toastify";
import i18n from "../../i18n";

import {
  GET_PARENTS_REQUEST,
  GET_PARENTS_SUCCESS,
  GET_PARENTS_ERROR,
  GET_PARENT_REQUEST,
  GET_PARENT_SUCCESS,
  GET_PARENT_ERROR,
} from "../actionTypes";

export const getParentsByStudentId = (studentId) => async (dispatch) => {
  dispatch(getParentsRequest());

  await API.get(`${urls.PARENT}student/${studentId}`)
    .then((res) => {
      console.log(res.data);
      dispatch(getParentsSuccess(res.data));
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getParentsFailure(error.message));
      return error.message;
    });
};
export const getParentsByClassId = (classId) => async (dispatch) => {
  dispatch(getParentsRequest());

  await API.get(`${urls.PARENT}class/${classId}`)
    .then((res) => {
      console.log(res.data);
      dispatch(getParentsSuccess(res.data));
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getParentsFailure(error.message));
      return error.message;
    });
};

export const addParent = (values) => async () => {
  const data = {
    ...values,
  };
  await API.post(`${urls.PARENT}create`, data)
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess added"), toastOption);
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      return error.message;
    });
};
export const removeParent = (id) => async (dispatch) => {
  await API.delete(`${urls.PARENT}${id}`)
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess deleted"), toastOption);
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getParentFailure(error.message));
      return error.message;
    });
};

export const getParentsRequest = () => {
  return {
    type: GET_PARENTS_REQUEST,
  };
};

export const getParentsSuccess = (data) => {
  return {
    type: GET_PARENTS_SUCCESS,
    payload: data,
  };
};

export const getParentsFailure = (error) => {
  return {
    type: GET_PARENTS_ERROR,
    payload: error,
  };
};

export const getParentRequest = () => {
  return {
    type: GET_PARENT_REQUEST,
  };
};

export const getParentSuccess = (data) => {
  return {
    type: GET_PARENT_SUCCESS,
    payload: data,
  };
};

export const getParentFailure = (error) => {
  return {
    type: GET_PARENT_ERROR,
    payload: error,
  };
};
