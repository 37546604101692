import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import {
  ClassRoomJournalReport,
  ClassJournalReports,
  FinalClassGradesReport,
  SummaryStudentsReport,
} from "./components";

import {
  Login,
  Home,
  SMSVerification,
  UnregisteredUser,
  SelectSchool,
  Step1,
  Step2,
  Step3,
  StepFinish,
  SchoolPresetting,
  SchoolPresetting1,
  SchoolPresetting2,
  SchoolPresetting3,
  SchoolPresetting4,
  SchoolPresetting5,
  Courses,
  Instructors,
  Quarters,
  Class,
  Students,
  School,
  InstructorHome,
  StudentProfile,
  Schedule,
  ScheduleGroup,
  Shifts,
  Payments,
  AutoSchedule1,
  AutoSchedule2,
  AutoSchedule3,
  AutoSchedule4,
  CTP,
  Topic,
  Statistics,
  ClassJournal,
  Reports,
  StudentRecord,
  QuarterGrade,
  StudentsAttendance,
  ChangeSchool,
  Sections,
  Section,
  Assignment,
  AssignmentList,
  StatisticsDetails,
  MyClass,
  InstructorStudents,
  InstructorStudentProfile,
  ClassStatistics,
  GradeSummaryInformation,
  GradeByClass,
  ReportPupils
} from "./pages";

export const useRoutes = (isAuthenticated) => {
  if (isAuthenticated) {
    return (
      <Switch>
        <Route exact path={"/"} component={Home} />
        <Route exact path={"/roles"} component={Home} />
        <Route path={"/school-presetting"} component={SchoolPresetting} exact />
        <Route path={"/school-admin/courses"} component={Courses} exact />
        <Route path={"/school-admin/quarters"} component={Quarters} exact />
        <Route path={"/school-admin/shifts"} component={Shifts} exact />
        <Route path={"/school-admin/statistics"} component={Statistics} exact />
        <Route path={"/school-admin/reports"} component={Reports} exact />
        <Route
          path={"/school-admin/reports/student-record"}
          component={StudentRecord}
          exact
        />
        <Route path={"/school-admin/reports/pupils"} component={ReportPupils} exact/>
        <Route
          path={"/school-admin/reports/students-attendance"}
          component={StudentsAttendance}
          exact
        />
        <Route path={"/school-admin/class"} component={Class} exact />
        <Route path={"/school-admin/schedule"} component={Schedule} exact />
        <Route
          path={"/school-admin/schedule/group"}
          component={ScheduleGroup}
          exact
        />
        <Route
          path={"/school-admin/students/:class_id"}
          component={Students}
          exact
        />
        <Route path={"/school-admin/students"} component={Students} exact />
        <Route path={"/school-admin/school"} component={School} exact />
        <Route
          path={"/school-admin/student-profile/:student_id"}
          component={StudentProfile}
          exact
        />
        <Route
          path={"/school-admin/instructors"}
          component={Instructors}
          exact
        />
        <Route path={"/school-admin/payments"} component={Payments} exact />
        <Route path={"/school-admin/sections"} component={Sections} exact />
        <Route
          path={"/school-admin/sections/:sectionInstructorId"}
          component={Section}
          exact
        />
        <Route
          path={"/school-admin/schedule/auto/1"}
          component={AutoSchedule1}
          exact
        />
        <Route
          path={"/school-admin/schedule/auto/2"}
          component={AutoSchedule2}
          exact
        />
        <Route
          path={"/school-admin/schedule/auto/3"}
          component={AutoSchedule3}
          exact
        />
        <Route
          path={"/school-admin/schedule/auto/4"}
          component={AutoSchedule4}
          exact
        />
        <Route path={"/school-admin"} component={School} exact />
        <Route
          path={"/school-presetting/1"}
          component={SchoolPresetting1}
          exact
        />
        <Route
          path={"/school-presetting/2"}
          component={SchoolPresetting2}
          exact
        />
        <Route
          path={"/school-presetting/3"}
          component={SchoolPresetting3}
          exact
        />
        <Route
          path={"/school-presetting/4"}
          component={SchoolPresetting4}
          exact
        />
        <Route
          path={"/school-presetting/5"}
          component={SchoolPresetting5}
          exact
        />
        <Route path={"/instructor"} component={InstructorHome} exact />
        <Route path={"/instructor/ctp"} component={CTP} exact />
        <Route path={"/instructor/ctp/:ctp_id"} component={Topic} exact />
        <Route
          path={"/instructor/class-journal"}
          component={ClassJournal}
          exact
        />
        <Route
          path={"/instructor/quarter-grade"}
          component={QuarterGrade}
          exact
        />
        <Route path={"/instructor/reports"} component={Reports} exact />
        <Route
          path={"/instructor/reports/student-record"}
          component={StudentRecord}
          exact
        />
        <Route path={"/instructor/assignments"} component={Assignment} exact />
        <Route
          path={"/instructor/assignments/:classId_courseId"}
          component={AssignmentList}
          exact
        />
        <Route path={"/instructor/my-class"} component={MyClass} exact />
        <Route
          path={"/instructor/grade-by-class"}
          component={GradeByClass}
          exact
        />
        <Route path={"/change-school"} component={ChangeSchool} exact />
        <Route
          path={"/school-admin/statistics/detail"}
          component={StatisticsDetails}
          exact
        />
        <Route
          path={"/instructor/class-statistics"}
          component={ClassStatistics}
          exact
        />
        <Route
          path={"/instructor/students/:class_id"}
          component={InstructorStudents}
          exact
        />{" "}
        <Route
          path={"/instructor/student-profile/:student_id"}
          component={InstructorStudentProfile}
          exact
        />
        <Route
          path={"/school-admin/reports/classroom-journal"}
          component={ClassRoomJournalReport}
          exact
        />
        <Route
          path={"/school-admin/reports/grade-summary-information"}
          component={GradeSummaryInformation}
          exact
        />
        <Route
          path={"/school-admin/reports/class-journal"}
          component={ClassJournalReports}
          exact
        />
        <Route
          path={"/school-admin/reports/final-class-grades"}
          component={FinalClassGradesReport}
          exact
        />
        <Route
          path={"/school-admin/reports/summary-student-progress-records"}
          component={SummaryStudentsReport}
          exact
        />
      </Switch>
    );
  }
  return (
    <Switch>
      <Route path={"/"} component={Login} exact />
      <Route
        path={"/unregistered-user/:phone_number"}
        component={UnregisteredUser}
        exact
      />
      <Route path={"/user-select-school"} component={SelectSchool} exact />
      <Route path={"/user-application-form/1"} component={Step1} exact />
      <Route path={"/user-application-form/2"} component={Step2} exact />
      <Route path={"/user-application-form/3"} component={Step3} exact />

      <Route
        path={"/user-application-form/finish"}
        component={StepFinish}
        exact
      />
      <Route
        path={"/sms-verify/:phone_number"}
        component={SMSVerification}
        exact
      />
      <Route path={"/school-presetting"} component={SchoolPresetting} exact />

      <Route
        path={"/school-presetting/1"}
        component={SchoolPresetting1}
        exact
      />
      <Route
        path={"/school-presetting/2"}
        component={SchoolPresetting2}
        exact
      />
      <Route
        path={"/school-presetting/3"}
        component={SchoolPresetting3}
        exact
      />
      <Route
        path={"/school-presetting/4"}
        component={SchoolPresetting4}
        exact
      />
      <Route
        path={"/school-presetting/5"}
        component={SchoolPresetting5}
        exact
      />
      <Redirect to={"/"} />
    </Switch>
  );
};
