import { urls, toastOption } from "../../config";
import { API } from "../../utils";
import { toast } from "react-toastify";
import i18n from "../../i18n";

export const createUserApplicationForm = (values, history) => async () => {
  let data = {
    firstName: values.firstName,
    lastName: values.lastName,
    middleName: values.middleName,
    classId: values.gradeId,
    comment: values.message,
    phoneNumber: values.phoneNumber,
    roleId: values.role,
    schoolId: values.schoolId,
    statusType: "NEW",
  };

  await API.post(`${urls.USER_APPLICATION_FORM}create`, data)
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:create success"), toastOption);
      localStorage.removeItem("userApplicationForm");
      history.push("/user-application-form/finish");
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:create error"), toastOption);
      return error.message;
    });
};
