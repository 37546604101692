import { urls, toastOption } from "../../config";
import { API } from "../../utils";
import { toast } from "react-toastify";
import i18n from "../../i18n";

import {
  GET_CALENDAR_TOPICS_REQUEST,
  GET_CALENDAR_TOPICS_SUCCESS,
  GET_CALENDAR_TOPICS_ERROR,
  GET_CALENDAR_TOPIC_REQUEST,
  GET_CALENDAR_TOPIC_SUCCESS,
  GET_CALENDAR_TOPIC_ERROR,
} from "../actionTypes";

// export const getCalendarTopics = () => async (dispatch) => {
//   dispatch(getCalendarTopicsRequest());

//   await API.get(
//     `${urls.CALENDAR_TOPIC}instructor/${
//       JSON.parse(localStorage.getItem("person"))?.id
//     }/chronicle/${JSON.parse(localStorage.getItem("chronicleActive"))?.id}`
//   )
//     .then((res) => {
//       console.log(res.data);
//       dispatch(getCalendarTopicsSuccess(res.data));
//       return res.data;
//     })
//     .catch((error) => {
//       console.log(error);
//       toast.error(i18n.t("toastify:error"), toastOption);
//       dispatch(getCalendarTopicsFailure(error.message));
//       return error.message;
//     });
// };
export const getCalendarTopicById =
  (id, setEditCalendarPlan) => async (dispatch) => {
    dispatch(getCalendarTopicRequest());

    await API.get(`${urls.CALENDAR_TOPIC}${id}`)
      .then((res) => {
        console.log(res.data);
        setEditCalendarPlan && setEditCalendarPlan(res.data);
        dispatch(getCalendarTopicSuccess(res.data));
        return res.data;
      })
      .catch((error) => {
        console.log(error);
        toast.error(i18n.t("toastify:error"), toastOption);
        dispatch(getCalendarTopicFailure(error.message));
        return error.message;
      });
  };
export const getCalendarTopicsByPlanId = (id) => async (dispatch) => {
  dispatch(getCalendarTopicsRequest());

  await API.get(`${urls.CALENDAR_TOPIC}plan/${id}`)
    .then((res) => {
      console.log(res.data);
      dispatch(getCalendarTopicsSuccess(res.data));
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getCalendarTopicsFailure(error.message));
      return error.message;
    });
};
export const getCalendarTopicByClassIdAndCourseId =
  (values) => async (dispatch) => {
    dispatch(getCalendarTopicsRequest());
    await API.get(
      `${urls.CALENDAR_TOPIC}instructor/${
        JSON.parse(localStorage.getItem("person"))?.id
      }/class/${values.classId}/course/${values.courseId}/chronicle/${
        JSON.parse(localStorage.getItem("chronicleActive"))?.id
      }`
    )
      .then((res) => {
        console.log(res.data);
        dispatch(getCalendarTopicsSuccess(res.data));
        return res.data;
      })
      .catch((error) => {
        console.log(error);
        toast.error(i18n.t("toastify:error"), toastOption);
        dispatch(getCalendarTopicsFailure(error.message));
        return error.message;
      });
  };
export const removeCalendarTopic = (id, planId) => async (dispatch) => {
  dispatch(getCalendarTopicsRequest());
  await API.delete(`${urls.CALENDAR_TOPIC}?id=${id}`)
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess deleted"), toastOption);
      planId && dispatch(getCalendarTopicsByPlanId(planId));
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      if (error.response.status === 409) {
        toast.error(i18n.t("toastify:delete grade exist"), toastOption);
      } else {
        toast.error(i18n.t("toastify:error"), toastOption);
      }
      // dispatch(getCalendarTopicsFailure(error.message));
      return error.message;
    });
};
export const editCalendarTopic = (values) => async (dispatch) => {
  dispatch(getCalendarTopicsRequest());
  const data = values;
  await API.post(`${urls.CALENDAR_TOPIC}edit/${values.id}`, data)
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess updated"), toastOption);
      // dispatch(getCalendarTopics());
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getCalendarTopicsFailure(error.message));
      return error.message;
    });
};

export const addCalendarTopic = (values) => async () => {
  const data = {
    ...values,
    chronicleId: JSON.parse(localStorage.getItem("chronicleActive"))?.id,
    personId: JSON.parse(localStorage.getItem("person"))?.id,
  };
  await API.post(`${urls.CALENDAR_TOPIC}create`, data)
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess added"), toastOption);
      // dispatch(getCalendarTopics());
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      return error.message;
    });
};
export const getCalendarTopicsRequest = () => {
  return {
    type: GET_CALENDAR_TOPICS_REQUEST,
  };
};

export const getCalendarTopicsSuccess = (data) => {
  return {
    type: GET_CALENDAR_TOPICS_SUCCESS,
    payload: data,
  };
};

export const getCalendarTopicsFailure = (error) => {
  return {
    type: GET_CALENDAR_TOPICS_ERROR,
    payload: error,
  };
};
export const getCalendarTopicRequest = () => {
  return {
    type: GET_CALENDAR_TOPIC_REQUEST,
  };
};

export const getCalendarTopicSuccess = (data) => {
  return {
    type: GET_CALENDAR_TOPIC_SUCCESS,
    payload: data,
  };
};

export const getCalendarTopicFailure = (error) => {
  return {
    type: GET_CALENDAR_TOPIC_ERROR,
    payload: error,
  };
};
