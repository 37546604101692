import React from "react";
import { Link, useHistory } from "react-router-dom";
import PT from "prop-types";
import * as Yup from "yup";
import { useTranslation, Trans } from "react-i18next";
import { Formik, Form } from "formik";
import { useDispatch } from "react-redux";

import FormikControl from "../../components/formik/FormikControl";
import { constants } from "../../config";
import { createCode } from "../../store/auth/actions";
import "./LoginByLogin.scss";

LoginByPhone.propTypes = {
  setIsLogin: PT.func,
};

export default function LoginByPhone(props) {
  const { setIsLogin } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation(["login", "common", "validation"]);

  const initialValues = {
    phone: "",
  };
  const validationSchema = Yup.object({
    phone: Yup.string()
      .matches(
        /^[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}?[-\s\\.]?[0-9]{3}$/,
        `${t("validation:valid")}`
      )
      .required(`${t("validation:required")}`),
    //   /^[\\+][0-9]{1,3}[0-9]{3}[0-9]{5,7}$/
  });

  const onSubmit = async (values) => {
    const number = `996${values.phone.replace(/\D/g, "")}`;
    // localStorage.setItem("phoneNumber", number);
    dispatch(createCode(number, history));
  };
  return (
    <div className="login">
      <div className="login__header">
        <div className="login__header-title">
          <h2>{t("login:enter phone number")}</h2>
        </div>
        <div className="login__header-description">
          <p className="body-2">{t("login:will send sms")}</p>
        </div>
      </div>
      <div className="login__body login_form large">
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(formik) => {
            return (
              <Form>
                <FormikControl
                  control="phoneinput"
                  // control="phoneinput"
                  type="text"
                  label={`${t("validation:phone number")}`}
                  // placeholder="Введите Логин"
                  name="phone"
                  value={formik.values.phone}
                  onBlur={formik.handleBlur}
                  changed={formik.touched.phone}
                  isValid={formik.errors.phone}
                  onChange={(e) => (
                    formik.setFieldTouched(e.target.name, true, true),
                    formik.handleChange(e)
                  )}
                />
                <button
                  className="primary large"
                  type="submit"
                  disabled={!formik.isValid}
                >
                  {t("common:continue")}
                </button>
              </Form>
            );
          }}
        </Formik>
      </div>
      <div className="login__footer">
        <div className="login__footer-text">
          <p className="caption-2 large">
            <Trans
              i18nKey="login:privacy and policy"
              t={t}
              components={[<a key="0" href={constants.privacyAndPolicy}></a>]}
            />
          </p>
        </div>
      </div>
      <div className="login-exist">
        <p className="caption-1 large">
          <Trans
            i18nKey="login:have login and password"
            t={t}
            components={[
              <Link to="/" key="0" onClick={() => setIsLogin(true)} />,
            ]}
          />
        </p>
      </div>
    </div>
  );
}
