/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import Modal from "react-modal";
import moment from "moment";
import { useTranslation } from "react-i18next";
import SelectSearch, { fuzzySearch } from "react-select-search";
import Tooltip from "react-simple-tooltip";

import {
  InstractorLayout,
  DatePickerCalendar,
  NoData,
  StudentProfileGrade,
  StudentProfileAnalytic,
} from "../../components";
import { useSelector, useDispatch } from "react-redux";
import {
  getStudentById,
  removeStudent,
  updateStudent,
} from "../../store/student/actions";
import { getClassBySchoolId } from "../../store/class/actions";
import {
  getParentsByStudentId,
  addParent,
  removeParent,
} from "../../store/parent/actions";
import { getPersonById, editPerson } from "../../store/person/actions";
import { getSchedulesByClassId } from "../../store/schedule/actions";
import {
  getStudentCourses,
  removeStudentCourse,
  addStudentCourse,
} from "../../store/studentCourse/actions";
import { getQuarters } from "../../store/quarter/actions";

import studentAvatarMale from "../../assets/images/student-avatar-male.png";
import studentAvatarFemale from "../../assets/images/student-avatar-female.png";
import parentAvatarMale from "../../assets/images/parent-avatar-male.png";
import parentAvatarFemale from "../../assets/images/parent-avatar-female.png";
import { Trash, Edit2, PlusCircle, X } from "react-feather";

export default function StudentProfile() {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  const { student_id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const studentStore = useSelector((store) => store.students.student);
  const studentCoursesStore = useSelector(
    (store) => store.studentCourses.studentCourses
  );
  const parentsStore = useSelector((store) => store.parents.parents);
  const scheduleStore = useSelector((store) => store.schedules.schedules);
  const classStore = useSelector((store) => store.class.class);
  const quartersStore = useSelector((store) => store.quarters.quarters);

  const [tab, setTab] = useState({
    parents: true,
    courses: false,
    grades: false,
    analytics: false,
  });
  const [parent, setParent] = useState({});
  const [student, setStudent] = useState({});
  const [studentParentId, setStudentParentId] = useState({});
  const [quarterId, setQuarterId] = useState("");
  const [modal, setModal] = useState({
    editStudent: false,
    editParent: false,
    createParent: false,
    confirm: false,
    confirm2: false,
  });
  const [newParent, setNewParent] = useState({
    lastName: "",
    firstName: "",
    middleName: "",
    phone: "",
    dateOfBirth: "",
    job: "",
    jobPlace: "",
    roleId: 5,
    parentalType: "",
  });
  useEffect(async () => {
    await dispatch(getStudentById(student_id));
    await dispatch(getParentsByStudentId(student_id));
    await dispatch(getQuarters());
  }, []);

  function exportTableToExcel(tableID, filename = "") {
    let downloadLink;
    let dataType = "application/vnd.ms-excel";
    let tableSelect = document.querySelector(tableID);
    tableSelect.border = 1;
    var els = document.querySelectorAll("td[meta-type=grade]");

    for (var i = 0; i < els.length; i++) {
      els[i].style.textAlign = "center";
      // els[i].style.transform = "rotate(270deg)";
      // els[i].style.display = "inline-block";
      // els[i].style.filter =
      //   "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
    }

    let tableHTML = tableSelect.outerHTML.replace(/ /g, "%20");

    // Specify file name
    filename = filename ? filename + ".xls" : "excel_data.xls";

    // Create download link element
    downloadLink = document.createElement("a");

    document.body.appendChild(downloadLink);

    if (navigator.msSaveOrOpenBlob) {
      let blob = new Blob(["\ufeff", tableHTML], {
        type: dataType,
      });
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      // Create a link to the file
      downloadLink.href = "data:" + dataType + ", " + tableHTML;

      // Setting the file name
      downloadLink.download = filename;

      //triggering the function
      downloadLink.click();
    }
  }
  const onParentInputChange = (e) => {
    setNewParent({
      ...newParent,
      [e.key || e.target.name]: e.value || e.target.value,
    });
  };
  const onEditParentInputChange = (e) => {
    if (e.target.name === "gender") {
      setParent({
        ...parent,
        [e.target.name]: e.target.value,
        parentalType: e.target.value == 1 ? 1 : 2,
      });
    } else {
      setParent({ ...parent, [e.target.name]: e.target.value });
    }
  };
  const onEditStudentInputChange = (e) => {
    setStudent({
      ...student,
      [e.key || e.target.name]: e.value || e.target.value,
    });
  };
  const onCourseSelect = async (value) => {
    if (studentCoursesStore?.find((i) => i.courseId === value.courseId)) {
      let id = studentCoursesStore?.find(
        (i) => i.courseId === value.courseId
      )?.id;
      await dispatch(removeStudentCourse(id));
    } else {
      let a = {
        classId: value.classId,
        courseId: value.courseId,
        personId: value.instructorId,
        studentId: student_id,
      };
      await dispatch(addStudentCourse(a));
    }
    await dispatch(getStudentCourses(student_id));
    await dispatch(getSchedulesByClassId(studentStore.classId));
    // setNewParent({ ...newParent, [e.target.name]: e.target.value });
  };
  const addAllCourse = async () => {
    await scheduleStore
      .filter(
        (thing, index, self) =>
          index === self.findIndex((t) => t.courseId === thing.courseId)
      )
      .filter((item) => {
        const a = studentCoursesStore.map((i) => i.courseId);

        if (!a.includes(item.courseId)) {
          return item;
        }
      })
      .map((item) =>
        dispatch(
          addStudentCourse({
            classId: item.classId,
            courseId: item.courseId,
            personId: item.instructorId,
            studentId: student_id,
          })
        )
      );
    await dispatch(getStudentCourses(student_id));
    await dispatch(getSchedulesByClassId(studentStore.classId));
  };

  const classOptions = classStore.map((item) => ({
    name: `${item.classLevel}${item.classLabel}`,
    value: item.id,
    key: "classId",
  }));

  const createParent = async () => {
    console.log({
      parentalType: newParent.parentalType,
      personDto: {
        ...newParent,
        gender: newParent.parentalType == 1 ? 1 : 2,
      },
      studentId: student_id,
    });
    await dispatch(
      addParent({
        parentalType: newParent.parentalType,
        personDto: {
          ...newParent,
          gender: newParent.parentalType == 1 ? 1 : 2,
        },
        studentId: student_id,
      })
    );
    await dispatch(getParentsByStudentId(student_id));
    await setNewParent({
      lastName: "",
      firstName: "",
      middleName: "",
      phone: "",
      dateOfBirth: "",
      job: "",
      jobPlace: "",
      roleId: 5,
      parentalType: "",
    });
    await setModal({ ...modal, createParent: false });
  };
  const editParent = async () => {
    await dispatch(
      editPerson({
        lastName: parent.lastName,
        firstName: parent.firstName,
        middleName: parent.middleName,
        parentalType: parent.parentalType,
        phone: parent.phone,
        dateOfBirth: moment(parent.dateOfBirth).format("YYYY-MM-DD"),
        gender: parent.gender,
        id: parent.id,
        job: parent.job,
        jobPlace: parent.jobPlace,
      })
    );
    await dispatch(getParentsByStudentId(student_id));
    await setParent({});
    await setModal({ ...modal, editParent: false });
  };

  const editStudent = async () => {
    await dispatch(
      updateStudent({
        lastName: student.lastName,
        firstName: student.firstName,
        middleName: student.middleName,
        phone: student.phone,
        nationality: student.nationality,
        dateOfBirth: moment(student.dateOfBirth).format("YYYY-MM-DD"),
        gender: student.gender,
        classId: student.classId,
        id: student.id,
      })
    );
    await dispatch(getStudentById(student_id));
    await setStudent({});
    await setModal({ ...modal, editStudent: false });
  };

  const quartersOptions = quartersStore.map((item) => ({
    name: `${item.quarter} ${t("instructor:quarter")}`,
    value: item.id,
    key: "quarterId",
  }));
  return (
    <InstractorLayout>
      <div className="school-admin__profile school-presetting__body school-presetting__body-color">
        <div className="school-admin__profile-left">
          <div className="school-admin__profile-basic">
            <div className="school-admin__profile-basic-buttons">
              {/* <div className="school-admin__profile-basic-button">
                <button>
                  <Repeat size={16} color="#F2AC57" />
                </button>
              </div> */}
              <div className="school-admin__profile-basic-button">
                <Tooltip className="tooltip" content={t("common:delete")}>
                  <button onClick={() => setModal({ ...modal, confirm: true })}>
                    <Trash size={16} color="#FF7171" />
                  </button>
                </Tooltip>
              </div>
            </div>
            <div className="school-admin__profile-avatar">
              <img
                src={
                  studentStore.avatar
                    ? studentStore.avatar
                    : studentStore.gender === 1
                    ? studentAvatarMale
                    : studentAvatarFemale
                }
                // width={102}
                // height={102}
                style={{}}
                alt="student avatar"
              />
            </div>
            <div className="school-admin__profile-name">
              <p>
                {studentStore.firstName} {studentStore.lastName}{" "}
                {studentStore.middleName}
              </p>
            </div>
            <div className="school-admin__profile-class">
              <span className="roboto">{studentStore.classTitle}</span>{" "}
              {t("schoolAdmin:lower case class")}
            </div>
            <div className="school-admin__profile-edit">
              <button
                className="fourth small"
                onClick={() => {
                  setStudent(studentStore);
                  setModal({ ...modal, editStudent: true });
                }}
              >
                {t("schoolAdmin:edit")}
              </button>
            </div>
          </div>
          <div className="school-admin__profile-info">
            <div className="school-admin__profile-info-title">
              <p>{t("schoolAdmin:personal data")}</p>
            </div>
            <div className="school-admin__profile-info-row">
              <div className="school-admin__profile-info-label">
                <p>{t("validation:login")}</p>
              </div>
              <div className="school-admin__profile-info-data">
                <p
                  className="roboto"
                  style={{
                    cursor: "pointer",
                    padding: "8px 0px",
                    borderRadius: 4,
                    width: "",
                  }}
                  onClick={async (e) => {
                    await navigator.clipboard.writeText(studentStore.username);
                    e.target.style.background = "#b9f7c3";
                    setTimeout(() => {
                      e.target.style.background = "#fff";
                    }, 1000);
                  }}
                >
                  {studentStore.username}
                </p>
              </div>
            </div>
            <div className="school-admin__profile-info-divider"></div>
            <div className="school-admin__profile-info-row">
              <div className="school-admin__profile-info-label">
                <p>{t("presetting:date of birth presetting")}</p>
              </div>
              <div className="school-admin__profile-info-data">
                <p>{studentStore.dateOfBirth}</p>
              </div>
            </div>
            <div className="school-admin__profile-info-divider"></div>
            <div className="school-admin__profile-info-row">
              <div className="school-admin__profile-info-label">
                <p>{t("presetting:phone presetting")}</p>
              </div>
              <div className="school-admin__profile-info-data">
                <p>{studentStore.phone}</p>
              </div>
            </div>
            <div className="school-admin__profile-info-divider"></div>
            <div className="school-admin__profile-info-row">
              <div className="school-admin__profile-info-label">
                <p>{t("presetting:gender presetting")}</p>
              </div>
              <div className="school-admin__profile-info-data">
                <p>
                  {studentStore.gender === 1
                    ? `${t("presetting:male presetting")}`
                    : `${t("presetting:female presetting")}`}
                </p>
              </div>
            </div>
            <div className="school-admin__profile-info-divider"></div>
            <div className="school-admin__profile-info-row">
              <div className="school-admin__profile-info-label">
                <p>{t("schoolAdmin:nationality")}</p>
              </div>
              <div className="school-admin__profile-info-data">
                <p>{studentStore.nationality}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="school-admin__profile-right">
          <div className="school-admin__profile-tabs">
            <div
              className={
                tab.parents
                  ? "school-admin__profile-tab active"
                  : "school-admin__profile-tab"
              }
            >
              <button
                onClick={() => {
                  dispatch(getParentsByStudentId(student_id));
                  setTab({
                    ...tab,
                    courses: false,
                    grades: false,
                    analytics: false,
                    parents: true,
                  });
                }}
              >
                <p>{t("schoolAdmin:parents")}</p>
              </button>
            </div>
            <div
              className={
                tab.courses
                  ? "school-admin__profile-tab active"
                  : "school-admin__profile-tab"
              }
            >
              <button
                onClick={async () => {
                  await dispatch(getStudentCourses(student_id));
                  await dispatch(getSchedulesByClassId(studentStore.classId));
                  setTab({
                    ...tab,
                    parents: false,
                    grades: false,
                    analytics: false,
                    courses: true,
                  });
                }}
              >
                <p>{t("schoolAdmin:subjects")}</p>
              </button>
            </div>
            <div
              className={
                tab.grades
                  ? "school-admin__profile-tab active"
                  : "school-admin__profile-tab"
              }
            >
              <button
                onClick={() => {
                  setTab({
                    ...tab,
                    parents: false,
                    courses: false,
                    analytics: false,
                    grades: true,
                  });
                }}
              >
                <p>{t("schoolAdmin:evaluations")}</p>
              </button>
            </div>
            <div
              className={
                tab.analytics
                  ? "school-admin__profile-tab active"
                  : "school-admin__profile-tab"
              }
            >
              <button
                onClick={() => {
                  setTab({
                    ...tab,
                    parents: false,
                    courses: false,
                    grades: false,
                    analytics: true,
                  });
                }}
              >
                <p>{t("schoolAdmin:analytics")}</p>
              </button>
            </div>
          </div>
          <div className="school-admin__profile-extra">
            {tab.parents && (
              <div className="school-admin__profile-tab1">
                <div className="school-admin__profile-parents">
                  {parentsStore.map((item, idx) => (
                    <div className="school-admin__profile-parent" key={idx}>
                      <div className="school-admin__profile-parent-header">
                        <div className="school-admin__profile-parent-header-left">
                          <div className="school-admin__profile-parent-avatar">
                            <img
                              width={62}
                              height={62}
                              src={
                                item.avatar
                                  ? item.avatar
                                  : item.gender === 1
                                  ? parentAvatarMale
                                  : parentAvatarFemale
                              }
                              alt="parent avatar"
                            />
                          </div>
                          <div className="school-admin__profile-parent-text">
                            <div className="school-admin__profile-parent-name">
                              <p>
                                {item.lastName} {item.firstName}{" "}
                                {item.middleName}
                              </p>
                            </div>
                            <div className="school-admin__profile-parent-type">
                              <p>
                                {item.parentalType === 2
                                  ? `${t("schoolAdmin:relationship mather")}`
                                  : item.parentalType === 1
                                  ? `${t("schoolAdmin:relationship father")}`
                                  : `${t("schoolAdmin:relationship other")}`}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="school-admin__profile-parent-header-right">
                          <div className="school-admin__profile-basic-buttons">
                            <div className="school-admin__profile-basic-button">
                              <Tooltip
                                className="tooltip"
                                content={t("common:edit")}
                              >
                                <button
                                  onClick={() => {
                                    dispatch(getPersonById(item.id, setParent));
                                    setModal({ ...modal, editParent: true });
                                  }}
                                >
                                  <Edit2 size={16} color="#14A38B" />
                                </button>
                              </Tooltip>
                            </div>
                            <div className="school-admin__profile-basic-button">
                              <Tooltip
                                className="tooltip"
                                content={t("common:delete")}
                              >
                                <button
                                  onClick={async () => {
                                    await setStudentParentId(
                                      item.studentParentId
                                    );
                                    await setModal({
                                      ...modal,
                                      confirm2: true,
                                    });
                                  }}
                                >
                                  <Trash size={16} color="#FF7171" />
                                </button>
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="school-admin__profile-parent-body">
                        <div className="school-admin__profile-info-row">
                          <div className="school-admin__profile-info-label">
                            <p>{t("validation:login")}</p>
                          </div>
                          <div className="school-admin__profile-info-data">
                            <p
                              className="roboto"
                              style={{
                                cursor: "pointer",
                                padding: "8px 0px",
                                borderRadius: 4,
                              }}
                              onClick={async (e) => {
                                await navigator.clipboard.writeText(
                                  item.username
                                );
                                e.target.style.background = "#b9f7c3";
                                setTimeout(() => {
                                  e.target.style.background = "#fff";
                                }, 1000);
                              }}
                            >
                              {item.username}
                              {/* asdfadfasdfs */}
                            </p>
                          </div>
                        </div>
                        <div className="school-admin__profile-info-divider"></div>
                        <div className="school-admin__profile-info-row">
                          <div className="school-admin__profile-info-label">
                            <p>{t("presetting:date of birth presetting")}</p>
                          </div>
                          <div className="school-admin__profile-info-data">
                            <p>{item.dateOfBirth}</p>
                          </div>
                        </div>
                        <div className="school-admin__profile-info-divider"></div>
                        <div className="school-admin__profile-info-row">
                          <div className="school-admin__profile-info-label">
                            <p>{t("presetting:phone presetting")}</p>
                          </div>
                          <div className="school-admin__profile-info-data">
                            <p>{item.phone}</p>
                          </div>
                        </div>
                        <div className="school-admin__profile-info-divider"></div>
                        <div className="school-admin__profile-info-row">
                          <div className="school-admin__profile-info-label">
                            <p>{t("presetting:gender presetting")}</p>
                          </div>
                          <div className="school-admin__profile-info-data">
                            <p>
                              {item.gender === 1
                                ? `${t("presetting:male presetting")}`
                                : `${t("presetting:female presetting")}`}
                            </p>
                          </div>
                        </div>
                        <div className="school-admin__profile-info-divider"></div>
                        <div className="school-admin__profile-info-row">
                          <div className="school-admin__profile-info-label">
                            <p>{t("schoolAdmin:profession")}</p>
                          </div>
                          <div className="school-admin__profile-info-data">
                            <p>{item.job}</p>
                          </div>
                        </div>
                        <div className="school-admin__profile-info-divider"></div>
                        <div className="school-admin__profile-info-row">
                          <div className="school-admin__profile-info-label">
                            <p>{t("schoolAdmin:work address")}</p>
                          </div>
                          <div className="school-admin__profile-info-data">
                            <p>{item.jobPlace}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                  {parentsStore.length < 2 && (
                    <div className="school-admin__profile-parent-add">
                      <button
                        onClick={() => {
                          setModal({ ...modal, createParent: true });
                        }}
                      >
                        <PlusCircle size={24} color="#F0783A" />
                        <span className="orange-text">
                          {t("schoolAdmin:add new parent")}
                        </span>
                      </button>
                    </div>
                  )}
                  {parentsStore.length == 0 && (
                    <NoData
                      messages={[
                        `${t("schoolAdmin:no parent")}`,
                        `${t("schoolAdmin:to add parent")}`,
                      ]}
                    />
                  )}
                </div>
              </div>
            )}
            {tab.courses && (
              <div className="school-admin__profile-tab2">
                {scheduleStore.length > 0 ? (
                  <div className="school-admin__profile-courses">
                    <div className="school-admin__profile-courses-description">
                      <span>
                        {t("schoolAdmin:choose some ting for students")}
                      </span>
                    </div>
                    <div className="school-admin__profile-course-select">
                      <button className="pure" onClick={addAllCourse}>
                        {t("schoolAdmin:add all")}
                      </button>
                    </div>
                    {scheduleStore
                      .filter(
                        (thing, index, self) =>
                          index ===
                          self.findIndex((t) => t.courseId === thing.courseId)
                      )
                      .map((item, idx) => (
                        <>
                          <div key={idx} className="table-select">
                            <p>
                              <span className="roboto">{idx + 1}.</span>
                              {"  "}

                              {String(i18n.language).toUpperCase() !== "EN" &&
                              String(i18n.language).toUpperCase() !== "TR"
                                ? item[
                                    `courseTitle${String(
                                      i18n.language
                                    ).toUpperCase()}`
                                  ]
                                : item.courseTitle}
                            </p>
                            <label className="check">
                              <input
                                type="checkbox"
                                onChange={() => onCourseSelect(item)}
                                checked={studentCoursesStore?.find(
                                  (i) => i.courseId === item.courseId
                                )}
                                // checked={selectedEditData?.includes(item.id)}
                              />
                              <div className="box"></div>
                            </label>
                          </div>
                          <div className="school-admin__divider"></div>
                        </>
                      ))}
                  </div>
                ) : (
                  <NoData messages={[`${t("schoolAdmin:here is nothing")}`]} />
                )}
              </div>
            )}
            {tab.grades && (
              // <NoData messages={[`${t("schoolAdmin:here is nothing")}`]} />
              <div className="student-profile__grades">
                <div className="student-profile__grades-filter">
                  <SelectSearch
                    options={quartersOptions}
                    search
                    filterOptions={fuzzySearch}
                    className="select-search small filter"
                    name="quarterId"
                    value={quarterId}
                    onChange={(_, k) => {
                      setQuarterId(k.value);
                    }}
                    required
                    placeholder={`${t("instructor:quarter")}`}
                  />
                  {quarterId !== "" && (
                    <button
                      className="primary small"
                      onClick={() => {
                        exportTableToExcel(
                          "#student-profile__grades-table-wrapper table",
                          `${
                            JSON.parse(localStorage.getItem("school"))?.name
                          } - ${t("schoolAdmin:evaluations")} - ${
                            quartersStore.find((item) => item.id == quarterId)
                              ?.quarter
                          } ${`${t("instructor:quarter")}`} - ${
                            studentStore.firstName
                          } ${studentStore.lastName} ${studentStore.middleName}`
                        );
                      }}
                    >
                      {t("schoolAdmin:export")}
                    </button>
                  )}
                </div>
                {quarterId !== "" && (
                  <div
                    className="student-profile__grades-table-wrapper"
                    id="student-profile__grades-table-wrapper"
                  >
                    <StudentProfileGrade
                      studentId={student_id}
                      quarterId={quarterId}
                    />
                  </div>
                )}
              </div>
            )}
            {tab.analytics && (
              <div className="student-profile__analytic">
                <div className="student-profile__analytic-filter">
                  <SelectSearch
                    options={quartersOptions}
                    search
                    filterOptions={fuzzySearch}
                    className="select-search small filter"
                    name="quarterId"
                    value={quarterId}
                    onChange={(_, k) => {
                      setQuarterId(k.value);
                    }}
                    required
                    placeholder={`${t("instructor:quarter")}`}
                  />
                </div>
                {quarterId !== "" && (
                  <div className="student-profile__analytic-data">
                    <StudentProfileAnalytic
                      studentId={student_id}
                      quarterId={quarterId}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        isOpen={modal.createParent}
        onRequestClose={() => setModal({ ...modal, createParent: false })}
        overlayClassName="modal-overlay"
        preventScroll={true}
        onAfterOpen={() => {
          // dispatch(getCourses());
          // dispatch(getInstructorCourses(currentStudentId));
        }}
      >
        <div className="modal">
          <div className="modal-close">
            <button onClick={() => setModal({ ...modal, createParent: false })}>
              <X size={15} color="#9A9A9A" />
            </button>
          </div>
          <div className="modal-header">
            <div className="modal-header-title">
              <h2>{t("schoolAdmin:info about student's perents")}</h2>
            </div>
            <div className="modal-header-description">
              <p className="body-2">
                {t("schoolAdmin:fill info abount this students  parants")}
              </p>
            </div>
          </div>
          <div className="modal-body">
            <div className="modal-inputs-list">
              <div className="modal-input">
                <input
                  type="text"
                  placeholder={`${t("presetting:surname presetting")}`}
                  name="lastName"
                  defaultValue={newParent.lastName}
                  onChange={onParentInputChange}
                />
              </div>
              <div className="modal-input">
                <input
                  type="text"
                  placeholder={`${t("presetting:name presetting")}`}
                  name="firstName"
                  defaultValue={newParent.firstName}
                  onChange={onParentInputChange}
                />
              </div>
              <div className="modal-input">
                <input
                  type="text"
                  placeholder={`${t("presetting:middlename presetting")}`}
                  name="middleName"
                  defaultValue={newParent.middleName}
                  onChange={onParentInputChange}
                />
              </div>
              <div className="modal-input">
                <input
                  type="text"
                  placeholder="996700124578"
                  name="phone"
                  defaultValue={newParent.phone}
                  onChange={onParentInputChange}
                />
              </div>
              <div className="modal-input">
                <DatePickerCalendar
                  placeholder={`${t("presetting:date of birth presetting")}`}
                  onSelect={onParentInputChange}
                  defaultValue={newParent.dateOfBirth}
                  name="dateOfBirth"
                />
              </div>
              <div className="modal-input">
                <SelectSearch
                  options={[
                    {
                      name: `${t("schoolAdmin:relationship father")}`,
                      value: 1,
                      key: "parentalType",
                    },
                    {
                      name: `${t("schoolAdmin:relationship mather")}`,
                      value: 2,
                      key: "parentalType",
                    },
                    {
                      name: `${t("schoolAdmin:relationship other")}`,
                      value: 3,
                      key: "parentalType",
                    },
                  ]}
                  search
                  // className="select-search drop-up"
                  filterOptions={fuzzySearch}
                  name="parentalType"
                  value={`${newParent?.parentalType}`}
                  onChange={(_, k) => {
                    onParentInputChange(k);
                  }}
                  required
                  placeholder={`${t("schoolAdmin:relationship")}`}
                />
              </div>
              <div className="modal-input">
                <input
                  type="text"
                  placeholder={`${t("schoolAdmin:profession")}`}
                  name="job"
                  defaultValue={newParent.job}
                  onChange={onParentInputChange}
                />
              </div>
              <div className="modal-input">
                <input
                  type="text"
                  placeholder={`${t("schoolAdmin:work address")}`}
                  name="jobPlace"
                  defaultValue={newParent.jobPlace}
                  onChange={onParentInputChange}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button className="large primary" onClick={() => createParent()}>
              {t("common:ready")}
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modal.editParent}
        onRequestClose={() => {
          setModal({ ...modal, editParent: false });
          setParent({});
        }}
        // onAfterOpen={() =>
        // dispatch(getPersonById(currentInstructorId, setParent))
        // }
        overlayClassName="modal-overlay"
      >
        <div className="modal">
          <div className="modal-close">
            <button
              onClick={() => {
                setModal({ ...modal, editParent: false });
                setParent({});
              }}
            >
              <X size={15} color="#9A9A9A" />
            </button>
          </div>
          <div className="modal-header">
            <div className="modal-header-title">
              <h2>{t("schoolAdmin:editing a parent's profile")}</h2>
            </div>
            <div className="modal-header-description">
              <p className="body-2">
                {t("presetting:edit the fields presetting")}
              </p>
            </div>
          </div>
          <div className="modal-body">
            <div className="modal-inputs-list">
              <div className="modal-input">
                <input
                  type="text"
                  placeholder={`${t("presetting:surname presetting")}`}
                  name="lastName"
                  defaultValue={parent.lastName}
                  onChange={onEditParentInputChange}
                />
              </div>
              <div className="modal-input">
                <input
                  type="text"
                  placeholder={`${t("presetting:name presetting")}`}
                  name="firstName"
                  defaultValue={parent.firstName}
                  onChange={onEditParentInputChange}
                />
              </div>
              <div className="modal-input">
                <input
                  type="text"
                  placeholder={`${t("presetting:middlename presetting")}`}
                  name="middleName"
                  defaultValue={parent.middleName}
                  onChange={onEditParentInputChange}
                />
              </div>
              <div className="modal-input">
                <input
                  type="text"
                  placeholder="996700124578"
                  name="phone"
                  defaultValue={parent.phone}
                  onChange={onEditParentInputChange}
                />
              </div>
              {/* <div className="modal-input">
                <DatePickerCalendar
                  placeholder="Дата рождения"
                  onSelect={onEditParentInputChange}
                  defaultValue={moment(parent.dateOfBirth, "YYYY-MM-DD")}
                  name="dateOfBirth"
                />
              </div> */}
              <div className="modal-input">
                <input
                  type="text"
                  placeholder={`${t("schoolAdmin:profession")}`}
                  name="job"
                  defaultValue={parent.job}
                  onChange={onEditParentInputChange}
                />
              </div>
              <div className="modal-input">
                <input
                  type="text"
                  placeholder={`${t("schoolAdmin:work address")}`}
                  name="jobPlace"
                  defaultValue={parent.jobPlace}
                  onChange={onEditParentInputChange}
                />
              </div>
              <div className="modal-input">
                <p className="body-2">{t("presetting:gender presetting")}</p>
                <div className="gender">
                  <div>
                    <input
                      type="radio"
                      name="gender"
                      value="1"
                      checked={parent.gender == 1}
                      onChange={onEditParentInputChange}
                    />
                    <label htmlFor="gender">
                      {t("presetting:male presetting")}
                    </label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      name="gender"
                      checked={parent.gender == 2}
                      value="2"
                      onChange={onEditParentInputChange}
                    />
                    <label htmlFor="gender">
                      {t("presetting:female presetting")}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button className="large primary" onClick={() => editParent()}>
              {t("common:save")}
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modal.editStudent}
        onRequestClose={() => setModal({ ...modal, editStudent: false })}
        onAfterOpen={() => dispatch(getClassBySchoolId())}
        overlayClassName="modal-overlay"
      >
        <div className="modal">
          <div className="modal-close">
            <button onClick={() => setModal({ ...modal, editStudent: false })}>
              <X size={15} color="#9A9A9A" />
            </button>
          </div>
          <div className="modal-header">
            <div className="modal-header-title">
              <h2>{t("schoolAdmin:editing a student's profile")}</h2>
            </div>
            <div className="modal-header-description">
              <p className="body-2">
                {t("presetting:edit the fields presetting")}
              </p>
            </div>
          </div>
          <div className="modal-body">
            <div className="modal-inputs-list">
              <div className="modal-input">
                <input
                  type="text"
                  placeholder={`${t("presetting:surname presetting")}`}
                  name="lastName"
                  defaultValue={student.lastName}
                  onChange={onEditStudentInputChange}
                />
              </div>
              <div className="modal-input">
                <input
                  type="text"
                  placeholder={`${t("presetting:name presetting")}`}
                  name="firstName"
                  defaultValue={student.firstName}
                  onChange={onEditStudentInputChange}
                />
              </div>
              <div className="modal-input">
                <input
                  type="text"
                  placeholder={`${t("presetting:middlename presetting")}`}
                  name="middleName"
                  defaultValue={student.middleName}
                  onChange={onEditStudentInputChange}
                />
              </div>
              <div className="modal-input">
                <SelectSearch
                  options={classOptions}
                  search
                  className="select-search drop-up"
                  filterOptions={fuzzySearch}
                  name="classId"
                  value={student.classId}
                  onChange={(_, k) => {
                    onEditStudentInputChange(k);
                  }}
                  required
                  placeholder={`${t("schoolAdmin:class")}`}
                />
              </div>
              <div className="modal-input">
                <input
                  type="text"
                  placeholder="996700124578"
                  name="phone"
                  defaultValue={student.phone}
                  onChange={onEditStudentInputChange}
                />
              </div>
              <div className="modal-input">
                <DatePickerCalendar
                  placeholder="Дата рождения"
                  onSelect={onEditStudentInputChange}
                  defaultValue={moment(student.dateOfBirth, "YYYY-MM-DD")}
                  name="dateOfBirth"
                />
              </div>
              <div className="modal-input">
                <input
                  type="text"
                  placeholder={`${t("schoolAdmin:nationality")}`}
                  name="nationality"
                  defaultValue={student.nationality}
                  onChange={onEditStudentInputChange}
                />
              </div>
              <div className="modal-input">
                <p className="body-2">{t("presetting:gender presetting")}</p>
                <div className="gender">
                  <div>
                    <input
                      type="radio"
                      name="gender"
                      value="1"
                      checked={student.gender == 1}
                      onChange={onEditStudentInputChange}
                    />
                    <label htmlFor="gender">
                      {t("presetting:male presetting")}
                    </label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      name="gender"
                      checked={student.gender == 2}
                      value="2"
                      onChange={onEditStudentInputChange}
                    />
                    <label htmlFor="gender">
                      {t("presetting:female presetting")}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button className="large primary" onClick={() => editStudent()}>
              {t("common:save")}
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modal.confirm}
        onRequestClose={() => {
          setModal({ ...modal, confirm: false });
        }}
        overlayClassName="modal-overlay"
      >
        <div className="modal">
          <div className="modal-close">
            <button
              onClick={() => {
                setModal({ ...modal, confirm: false });
              }}
            >
              <X size={15} color="#9A9A9A" />
            </button>
          </div>
          <div className="modal-header">
            <div className="modal-header-title">
              <h2>{t("presetting:are u shure presetting")}</h2>
            </div>
            <div className="modal-header-description">
              <p className="body-2">{t("schoolAdmin:delete warning")}</p>
            </div>
          </div>

          <div className="modal-footer" style={{ marginTop: "24px" }}>
            <button
              className="large secondary"
              onClick={() => setModal({ ...modal, confirm: false })}
            >
              {t("common:cancel")}
            </button>
            <button
              className="large primary"
              onClick={async () => {
                await dispatch(removeStudent(student_id));
                await setModal({ ...modal, confirm: false });
                await setTimeout(() => {
                  history.push("/school-admin/students");
                }, 2000);
              }}
            >
              {t("common:delete")}
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modal.confirm2}
        onRequestClose={() => {
          setModal({ ...modal, confirm2: false });
        }}
        overlayClassName="modal-overlay"
      >
        <div className="modal">
          <div className="modal-close">
            <button
              onClick={() => {
                setModal({ ...modal, confirm2: false });
              }}
            >
              <X size={15} color="#9A9A9A" />
            </button>
          </div>
          <div className="modal-header">
            <div className="modal-header-title">
              <h2>{t("presetting:are u shure presetting")}</h2>
            </div>
            <div className="modal-header-description">
              <p className="body-2">{t("schoolAdmin:delete warning")}</p>
            </div>
          </div>

          <div className="modal-footer" style={{ marginTop: "24px" }}>
            <button
              className="large secondary"
              onClick={() => setModal({ ...modal, confirm2: false })}
            >
              {t("common:cancel")}
            </button>
            <button
              className="large primary"
              onClick={async () => {
                await dispatch(removeParent(studentParentId));
                await dispatch(getParentsByStudentId(student_id));
                await setModal({ ...modal, confirm2: false });
                await setStudentParentId({});
              }}
            >
              {t("common:delete")}
            </button>
          </div>
        </div>
      </Modal>
    </InstractorLayout>
  );
}
