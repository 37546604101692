import React, { useEffect, useMemo, useState } from "react";
import { GoBack, SchoolAdminLayout } from "../../components";
import { useTranslation } from "react-i18next";
import PT from "prop-types";

import { useTable } from "react-table";
import { useDispatch, useSelector } from "react-redux";
import { getQuarterMark, getQuarterTitles } from "../../store/report/actions";
import SelectSearch, { fuzzySearch } from "react-select-search";
import "./Reports.scss";

Table.propTypes = {
  columns: PT.array,
  data: PT.array,
  className: PT.string,
};

export function Table({ columns, data }) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });
  return (
    <table className="grade_summary_information_table" {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup, idx) => (
          <tr key={idx} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, idx) => (
              <th key={idx} {...column.getHeaderProps()}>
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr key={i} {...row.getRowProps()}>
              {row.cells.map((cell, idx) => {
                return (
                  <td key={idx} {...cell.getCellProps()}>
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

function GradeSummaryInformation() {
  const { t } = useTranslation();
  const [classValue, setClassValue] = useState(
    `- ${t("presetting:classes presetting")} -`
  );
  const studentMarksStore = useSelector((state) => state.report.studentsRecord);
  const [year, setYear] = useState("2021-2022 г");
  const dispatch = useDispatch();
  const quaterStore = useSelector((state) => state.report.quarter);

  useEffect(() => {
    dispatch(getQuarterMark(7116, 8, 3));
    dispatch(getQuarterTitles(4, 3));
  }, [dispatch]);

  const classes = [
    {
      name: `1`,
      value: 1,
      key: 1,
    },
    {
      name: `2`,
      value: 2,
      key: 2,
    },
    {
      name: `3`,
      value: 3,
      key: 3,
    },
  ];

  const yearState = [
    {
      name: `2021-2022 г`,
      value: 2021,
      key: 2021,
    },
    {
      name: `2021-2022 г`,
      value: 2022,
      key: 2022,
    },
    {
      name: `2022-2023 г`,
      value: 2023,
      key: 2023,
    },
  ];

  let columnsData = [
    {
      Header: `${t("presetting:full name presetting")}`,
      accessor: "full_name",
    },
    {
      Header: " ",
      accessor: " ",
    },
  ];

  function courses() {
    studentMarksStore
      .filter((v, i, a) => a.findIndex((t) => t.courseId === v.courseId) === i)
      ?.map((item) => {
        columnsData.push({
          Header: item.courseTitleRU,
          accessor: item.courseTitle,
        });
      });
    columnsData.push({
      Header: "Решение педсовета",
      accessor: "(дата и номер)",
    });
  }

  console.log("columnsData", columnsData);

  // let quaters = [
  //   "1 четверть",
  //   "2 четверть",
  //   "3 четверть",
  //   "4 четверть",
  //   "Годовая",
  //   "Гос",
  //   "Итоговая",
  // ];

  // console.log(quaters);
  const mockData = studentMarksStore
    .filter(
      (v, i, a) => a.findIndex((t) =>t.studentTitle === v.studentTitle && t.quarterMark === v.quarterMark) === i
    )
    ?.map((item) => {
      let studentsArray = {};
      // quaters?.map((quater) => {
      studentsArray[`${item.courseTitle}`] = item.quarterMark;
      studentsArray[`full_name`] = item.studentTitle;
      studentsArray[" "] = quaterStore?.map((quater) => {
        if (quater.id == item.quarterId) {
          console.log("quater", quater.quarter);
          return `${quater.quarter} четверть`;
        }
      });
      return studentsArray;
      // });
    });

  console.log("students", mockData);

  const columns = useMemo(() => columnsData, [studentMarksStore]);

  const data = useMemo(() => mockData, []);

  return (
    <>
      <SchoolAdminLayout>
        {courses()}
        <GoBack title={`${t("common:back")}`} />
        <div className="grade__summary__information">
          <h2>Сводная ведомость учета успеваемости учащихся</h2>
          <p>
            В отчете представлены оценки учеников по всем учебным четветям, а
            также годовая, государственная и итоговая.
          </p>
          <div className="grade__summary__information_filter">
            <SelectSearch
              options={classes}
              search
              filterOptions={fuzzySearch}
              value={classValue}
              defaulValue={classValue}
              name="schoolType"
              onChange={(_, k) => {
                setClassValue(k);
              }}
              required
              placeholder={`- ${t("presetting:classes presetting")} -`}
            />
            <SelectSearch
              options={yearState}
              search
              filterOptions={fuzzySearch}
              value={year}
              defaulValue={year}
              name="schoolType"
              onChange={(_, k) => {
                setYear(k);
              }}
              required
              placeholder={year}
            />
            <button className="export_button">{t("common:export")}</button>
          </div>
          <div className="grade__summary__information_content">
            <Table columns={columns} data={data} />
          </div>
        </div>
      </SchoolAdminLayout>
    </>
  );
}

export default GradeSummaryInformation;
