import React from "react";
import PT from "prop-types";
import { AlertCircle } from "react-feather";

TextError.propTypes = { children: PT.any };

function TextError(props) {
  return (
    <div className="error">
      <div>
        <AlertCircle color="#FB3030" size={10} />
      </div>
      <span>{props.children}</span>
    </div>
  );
}

export default TextError;
