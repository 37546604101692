import { urls, toastOption } from "../../config";
import { API } from "../../utils";
import { toast } from "react-toastify";
import i18n from "../../i18n";

import {
  GET_GRADES_REQUEST,
  GET_GRADES_SUCCESS,
  GET_GRADES_ERROR,
} from "../actionTypes";

export const getGrades = (values) => async (dispatch) => {
  dispatch(getGradesRequest());
  const data = {
    ...values,
    personId: JSON.parse(localStorage.getItem("person"))?.id,
  };
  await API.post(`${urls.GRADE}month-fetch`, data)
    .then((res) => {
      console.log(res.data);

      dispatch(getGradesSuccess(res.data));
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getGradesFailure(error.message));
      return error.message;
    });
};
export const getGradesStudentFetch = (values) => async (dispatch) => {
  dispatch(getGradesRequest());
  const data = {
    ...values,
  };
  await API.post(`${urls.GRADE}student-fetch`, data)
    .then((res) => {
      console.log(res.data);
      dispatch(getGradesSuccess(res.data));
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getGradesFailure(error.message));
      return error.message;
    });
};
export const removeCourese = (id) => async (dispatch) => {
  dispatch(getGradesRequest());
  await API.delete(`${urls.COURSE}?id=${id}`)
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess deleted"), toastOption);
      dispatch(getGrades());
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getGradesFailure(error.message));
      return error.message;
    });
};
export const editGradeMassive = (values) => async () => {
  // dispatch(getGradesRequest());
  const data = values;
  await API.post(`${urls.GRADE}bulk-edit`, data)
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess updated"), toastOption);
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      // dispatch(getGradesFailure(error.message));
      return error.message;
    });
};

export const addGradeMassive = (values) => async () => {
  const data = values;
  await API.post(`${urls.GRADE}bulk-create`, data)
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess added"), toastOption);
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      return error.message;
    });
};
export const getGradesRequest = () => {
  return {
    type: GET_GRADES_REQUEST,
  };
};

export const getGradesSuccess = (data) => {
  return {
    type: GET_GRADES_SUCCESS,
    payload: data,
  };
};

export const getGradesFailure = (error) => {
  return {
    type: GET_GRADES_ERROR,
    payload: error,
  };
};
