import {
  GET_TOWNS_REQUEST,
  GET_TOWNS_SUCCESS,
  GET_TOWNS_ERROR,
} from "../actionTypes";
import { statuses } from "../../config";

const initialState = {
  towns: [],
  error: "",
  status: statuses.INITIAL,
};

export const townsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TOWNS_REQUEST:
      return {
        ...state,
        towns: [],
        error: "",
        status: statuses.LOADING,
      };
    case GET_TOWNS_SUCCESS:
      return {
        ...state,
        towns: action.payload,
        error: "",
        status: statuses.SUCCESS,
      };
    case GET_TOWNS_ERROR:
      return {
        ...state,
        towns: [],
        error: action.payload,
        status: statuses.ERROR,
      };

    default:
      return state;
  }
};
