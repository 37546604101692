import React from "react";
import { SMSVerificationValidator, LoginLayout } from "../../components";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function SMSVerification() {
  const { phone_number } = useParams();
  const { t } = useTranslation(["login"]);

  return (
    <div className="sms-verification">
      <LoginLayout>
        <div className="sms-verification__body">
          <div className="sms-verification__body-title">
            <h3>{t("login:enter sms code")}</h3>
          </div>
          <div className="sms-verification__body-description">
            <p className="body-2">
              {t("login:sms code was sent to")}{" "}
              <span className="orange-text roboto">+{phone_number}</span>
            </p>
          </div>
          <div className="sms-verification__body-form h-center">
            <SMSVerificationValidator />
          </div>
        </div>
      </LoginLayout>
    </div>
  );
}
