/* eslint-disable indent */
import React, { useEffect } from "react";
import PT from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
// import Modal from "react-modal";
// import { X } from "react-feather";
import moment from "moment";

import { statuses } from "../../config";
import {
  getStudentGradeAnalytics,
  getStudentAttendanceAnalytics,
} from "../../store/analytic/action";
import { getGradesStudentFetch } from "../../store/grade/actions";
import { getCoursesByStudentId } from "../../store/course/actions";
import GraphIcon from "../../assets/images/student-profile-analytic-graph.png";
import ChartIcon from "../../assets/images/student-profile-analytic-chart.png";

StudentProfileGrade.propTypes = {
  quarterId: PT.number.required,
  studentId: PT.number.required,
};

export default function StudentProfileGrade(props) {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  const { quarterId, studentId } = props;
  const dispatch = useDispatch();

  const quartersStore = useSelector((store) => store.quarters.quarters);
  const gradesStore = useSelector((store) => store.grades.grades);
  const studentGradeAnalyticsStore = useSelector(
    (store) => store.analytic.studentGradeAnalytics
  );
  const studentAttendanceAnalyticStore = useSelector(
    (store) => store.analytic.studentAttendanceAnalytic
  );
  const gradesStatus = useSelector((store) => store.grades.status);
  const coursesByStudentIdStore = useSelector(
    (store) => store.courses.coursesByStudentId.data
  );

  useEffect(async () => {
    await dispatch(getCoursesByStudentId(studentId));
  }, [quarterId]);

  useEffect(async () => {
    await dispatch(
      getGradesStudentFetch({
        courseId: null,
        // endDate: "2021-11-17",
        // startDate: "2021-11-17",
        endDate: moment().format("YYYY-MM-DD"),
        startDate: moment().format("YYYY-MM-DD"),

        studentId: studentId,
      })
    );
    await dispatch(
      getStudentAttendanceAnalytics({
        startDate: quartersStore.find((item) => item.id == quarterId)
          ?.startDate,
        endDate: quartersStore.find((item) => item.id == quarterId)?.endDate,
        studentId: studentId,
      })
    );
    await dispatch(
      getStudentGradeAnalytics({
        startDate: quartersStore.find((item) => item.id == quarterId)
          ?.startDate,
        endDate: quartersStore.find((item) => item.id == quarterId)?.endDate,
        studentId: studentId,
      })
    );
  }, [studentId, quarterId]);

  function getAvg(data) {
    const total = data.reduce((acc, c) => acc + c, 0);
    return total / data.length;
  }

  return (
    <>
      {gradesStatus === statuses.SUCCESS && (
        <div className="student-profile__analytic-data-inner">
          <div className="student-profile__row student-profile__anlytic-main">
            <div className="student-profile__anlytic-grade">
              <div>
                <p>{t("reports:achievement")}</p>
                <h3>
                  {isNaN(
                    getAvg(
                      studentGradeAnalyticsStore.map(
                        (item) => item.averageGrade
                      )
                    )
                  )
                    ? 0
                    : getAvg(
                        studentGradeAnalyticsStore.map(
                          (item) => item.averageGrade
                        )
                      ).toFixed(1)}
                </h3>
              </div>
              <div>
                <img src={GraphIcon} alt="Bar" />
              </div>
            </div>
            <div className="student-profile__anlytic-attendance">
              <div>
                <p> {t("instructor:attendance")}</p>
                <h3>
                  {Math.round(
                    100 -
                      (studentAttendanceAnalyticStore?.attendanceCount /
                        studentAttendanceAnalyticStore?.dayCount) *
                        100
                  )}
                  %
                </h3>
              </div>
              <div>
                <img src={ChartIcon} alt="Bar" />
              </div>
            </div>
          </div>
          {gradesStore.length > 0 && (
            <div className="student-profile__row">
              <div className="student-profile__analytic-grade-today">
                <div className="student-profile__analytic-grade-today-header">
                  <div className="student-profile__analytic-grade-today-header-left">
                    {t("schoolAdmin:today")}
                  </div>

                  <div className="student-profile__analytic-grade-today-header-right"></div>
                </div>

                {gradesStore?.map((item, idx) => (
                  <div
                    key={idx}
                    className="student-profile__analytic-grade-today-item"
                  >
                    <div className="student-profile__analytic-grade-today-title">
                      <div className="title-letter">
                        {coursesByStudentIdStore
                          .find(
                            (course) =>
                              course.studentCourseId === item.studentCourseId
                          )
                          ?.[
                            String(i18n.language).toUpperCase() !== "EN" &&
                            String(i18n.language).toUpperCase() !== "TR"
                              ? `${`courseTitle${String(
                                  i18n.language
                                ).toUpperCase()}`}`
                              : `${item.courseTitle}`
                          ]?.trim()
                          ?.toUpperCase()
                          ?.charAt(0)}
                      </div>
                      <div className="course-title">
                        {
                          coursesByStudentIdStore.find(
                            (course) =>
                              course.studentCourseId === item.studentCourseId
                          )?.[
                            String(i18n.language).toUpperCase() !== "EN" &&
                            String(i18n.language).toUpperCase() !== "TR"
                              ? `${`courseTitle${String(
                                  i18n.language
                                ).toUpperCase()}`}`
                              : `${item.courseTitle}`
                          ]
                        }
                      </div>
                    </div>
                    <div className="student-profile__analytic-grade-today-grade">
                      {item.mark}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          {studentGradeAnalyticsStore.length > 0 && (
            <div className="student-profile__row">
              <div className="student-profile__analytic-grade-course">
                <div className="student-profile__analytic-grade-course-title">
                  {t("reports:statistics by course")}
                </div>
                {studentGradeAnalyticsStore?.map((item, idx) => (
                  <div
                    key={idx}
                    className="student-profile__analytic-grade-course-item"
                  >
                    <div className="student-profile__analytic-grade-course-item-title">
                      {String(i18n.language).toUpperCase() !== "EN" &&
                      String(i18n.language).toUpperCase() !== "TR"
                        ? `${
                            item[
                              `courseTitle${String(
                                i18n.language
                              ).toUpperCase()}`
                            ]
                          } `
                        : `${item.courseTitle}`}
                    </div>
                    <div className="student-profile__analytic-grade-course-item-data">
                      <div className="average-bar-border">
                        <div
                          className="average-bar"
                          style={{ width: `${(item.averageGrade / 5) * 100}%` }}
                        ></div>
                      </div>
                      <div className="average-grade">
                        {item.averageGrade?.toFixed(1)}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
      {/* <Modal
        isOpen={modal.createGrade}
        onRequestClose={() => setModal({ ...modal, createGrade: false })}
        overlayClassName="modal-overlay"
        onAfterOpen={async () => {}}
      >
        <div className="modal">
          <div className="modal-close">
            <button onClick={() => setModal({ ...modal, createGrade: false })}>
              <X size={15} color="#9A9A9A" />
            </button>
          </div>
          <div className="modal-header">
            <div className="modal-header-title">
              <h2 className="roboto"></h2>
            </div>
            <div className="modal-header-description"></div>
          </div>
          <div className="modal-body">
            <div className="modal-inputs-list grades">
              <div className="modal-input">
                <h5>{t("instructor:late")}</h5>
                <button
                  onClick={() => {
                    // sendMark({
                    //   ...currentCell,
                    //   gradeMarkType: "ATTENDANCE",
                    //   mark: "97",
                    // });
                    setModal({ ...modal, createGrade: false });
                  }}
                >
                  &nbsp;
                </button>
              </div>
              <div className="modal-input">
                <h5>{t("instructor:absent for no valid reason")}</h5>
                <button
                  onClick={() => {
                    // sendMark({
                    //   ...currentCell,
                    //   gradeMarkType: "ATTENDANCE",
                    //   mark: "95",
                    // });
                    setModal({ ...modal, createGrade: false });
                  }}
                >
                  {t("instructor:not attendent")}
                </button>
              </div>
              <div className="modal-input">
                <h5>{t("instructor:missing for a good reason")}</h5>
                <button
                  onClick={() => {
                    // sendMark({
                    //   ...currentCell,
                    //   gradeMarkType: "ATTENDANCE",
                    //   mark: "92",
                    // });
                    setModal({ ...modal, createGrade: false });
                  }}
                >
                  {t("instructor:not attendent")}
                </button>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            {gradesStore
              .filter(
                (item) => item.studentCourseId == currentCell.studentCourseId
              )
              .filter((item) => item.gradeMarkType == "ATTENDANCE").length >
              0 && (
              <button
                className="large primary"
                onClick={async () => {
                  // sendMark({
                  //   studentCourseId: currentCell.studentCourseId,
                  //   shiftTimeId: currentCell.shiftTimeId,
                  //   gradeMarkType: "ATTENDANCE",
                  //   mark: "",
                  // });
                  setModal({ ...modal, createGrade: false });
                }}
              >
                {t("common:delete")}
              </button>
            )}
          </div>
        </div>
      </Modal> */}
    </>
  );
}
