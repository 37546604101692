import { urls, toastOption } from "../../config";
import { API } from "../../utils";
import { toast } from "react-toastify";
import i18n from "../../i18n";

import {
  GET_CHRONICLE_YEAR_REQUEST,
  GET_CHRONICLE_YEAR_SUCCESS,
  GET_CHRONICLE_YEAR_ERROR,
} from "../actionTypes";

export const getChronicleYears = () => async (dispatch) => {
  dispatch(getChronicleYearsRequest());
  await API.get(`${urls.CHRONICLE_YEAR}`)
    .then((res) => {
      console.log("aaa", res.data);
      dispatch(getChronicleYearsSuccess(res.data));
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getChronicleYearsFailure(error.message));
      return error.message;
    });
};

export const getChronicleYearsRequest = () => {
  return {
    type: GET_CHRONICLE_YEAR_REQUEST,
  };
};

export const getChronicleYearsSuccess = (data) => {
  return {
    type: GET_CHRONICLE_YEAR_SUCCESS,
    payload: data,
  };
};

export const getChronicleYearsFailure = (error) => {
  return {
    type: GET_CHRONICLE_YEAR_ERROR,
    payload: error,
  };
};
