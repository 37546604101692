import React from "react";
import PT from "prop-types";

import "../../../node_modules/rc-steps/assets/index.css";
import "./Layout.scss";
import { SchoolPresettingFooter, SchoolAdminHeader } from "../";

export default function SchoolAdminLayout(props) {
  const { children } = props;
  return (
    <div className="school-presetting">
      <SchoolAdminHeader />
      <div className="school-presetting__body school-presetting__container">
        {children}
      </div>
      <SchoolPresettingFooter />
    </div>
  );
}
SchoolAdminLayout.propTypes = {
  children: PT.any,
};
