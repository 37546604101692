import React, { useMemo } from "react";
import PT from "prop-types";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useTable } from "react-table";
import { useSelector } from "react-redux";

import "./Table.scss";
const StudentsAttendanceComponent = (props) => {
  const { quaterTitle, id } = props;
  const { t } = useTranslation();
  const quartersStore = useSelector((store) => store?.quarters?.quarter);
  const studentsStore = useSelector((store) => store?.students?.students);
  const attendanceStore = useSelector(
    (store) => store?.report?.studentsAttendance
  );
  let quarterMonths = [];

  for (
    let i = quartersStore?.startDate?.split("-")[1];
    i <= quartersStore?.endDate?.split("-")[1];
    i++
  ) {
    quarterMonths.push(i);
  }
  const quarterMonthsEn = quarterMonths.map((item) => ({
    monthEn: moment(item, "MM").locale("en").format("MMMM"),
    code: item,
  }));

  const a = quarterMonths.map((item) => ({
    Header: `${moment(item, "MM").format("MMMM")}`,
    accessor: `${item}`,
    columns: [
      {
        Header: `${t("reports:full days")}`,
        accessor: `day-${item}`,

        columns: [
          {
            Header: `${t("reports:total")}`,
            accessor: `day-${item}-95`,
          },
          {
            Header: `${t("reports:absent due to illness")}`,
            accessor: `day-${item}-92`,
          },
        ],
      },
      {
        Header: `${t("reports:lessons")}`,
        accessor: `lesson-${item}`,

        columns: [
          {
            Header: `${t("reports:total")}`,
            accessor: `lesson-${item}-95`,
          },
          {
            Header: `${t("reports:absent due to illness")}`,
            accessor: `lesson-${item}-92`,
          },
        ],
      },
    ],
  }));

  const COLUMNS = [
    {
      Header: "№",
      accessor: "id-parent",
      columns: [{ Header: ``, accessor: "id" }],
    },
    {
      Header: `${t("presetting:full name presetting")}`,
      accessor: "firstName-parent",
      columns: [{ Header: ``, accessor: "firstName" }],
    },
    ...a,
    {
      Header: `${quaterTitle}`,
      accessor: `1-quater`,
      columns: [
        {
          Header: `${t("reports:full days")}`,
          accessor: `day-count`,
          columns: [
            {
              Header: `${t("reports:total")}`,
              accessor: `day-count-95`,
            },
            {
              Header: `${t("reports:absent due to illness")}`,
              accessor: `day-count-92`,
            },
          ],
        },
        {
          Header: `${t("reports:lessons")}`,
          accessor: `lesson-count`,

          columns: [
            {
              Header: `${t("reports:total")}`,
              accessor: `lesson-count-95`,
            },
            {
              Header: `${t("reports:absent due to illness")}`,
              accessor: `lesson-count-92`,
            },
          ],
        },
      ],
    },
  ];

  const columns = useMemo(() => COLUMNS, [studentsStore, attendanceStore]);

  const data = useMemo(() => {
    let a = [];
    let numeration = 0;

    studentsStore?.map((student) => {
      let b = [];
      quarterMonthsEn?.map((item) => {
        console.log("attendanceStore", attendanceStore);
        attendanceStore?.status == "SUCCESS" &&
          attendanceStore.data
            ?.filter((attendance) => attendance.studentId === student.id)
            ?.filter(
              (attendance) => attendance.monthName.trim() === item.monthEn
            )
            ?.map((item) => b.push(item));
      });
      if (b.length === quarterMonthsEn.length) {
        let count = {
          dayCount95: 0,
          dayCount92: 0,
          lessonCount95: 0,
          lessonCount92: 0,
        };

        let b = {
          id: (numeration += 1),
          firstName: `${student?.lastName} ${student?.firstName} ${student?.middleName}`,
        };

        quarterMonthsEn.map((item) => {
          attendanceStore.data
            .filter((attendance) => attendance.studentId === student.id)
            .filter(
              (attendance) => attendance.monthName.trim() === item.monthEn
            )
            .map((attendance) => {
              b[`day-${item.code}-95`] = attendance.count95Day;
              b[`day-${item.code}-92`] = attendance.count92Day;
              b[`lesson-${item.code}-95`] = attendance.count95Lesson;
              b[`lesson-${item.code}-92`] = attendance.count92Lesson;
              count.dayCount95 = count.dayCount95 + attendance.count95Day;
              count.dayCount92 = count.dayCount92 + attendance.count92Day;
              count.lessonCount95 =
                count.lessonCount95 + attendance.count95Lesson;
              count.lessonCount92 =
                count.lessonCount92 + attendance.count92Lesson;
            });
        });
        b[`day-count-95`] = count.dayCount95;
        b[`day-count-92`] = count.dayCount92;
        b[`lesson-count-95`] = count.lessonCount95;
        b[`lesson-count-92`] = count.lessonCount92;
        a.push(b);
      } else {
        let z = {};
        let count = {
          dayCount95: 0,
          dayCount92: 0,
          lessonCount95: 0,
          lessonCount92: 0,
        };
        quarterMonthsEn.map((item) => {
          const k =
            attendanceStore?.status == "SUCCESS" &&
            attendanceStore?.data
              ?.filter((attendance) => attendance.studentId === student.id)
              ?.map((attendance) => attendance.monthName.trim());
          console.log("k==>", k);
          if (k !== false && k?.includes(item.monthEn)) {
            z[`day-${item.code}-95`] =
              attendanceStore?.status == "SUCCESS" &&
              attendanceStore?.data
                ?.filter((attendance) => attendance.studentId === student.id)
                ?.filter(
                  (attendance) => attendance.monthName.trim() === item.monthEn
                )?.[0]?.count95Day;
            z[`day-${item.code}-92`] = attendanceStore?.data
              ?.filter((attendance) => attendance.studentId === student.id)
              ?.filter(
                (attendance) => attendance.monthName.trim() === item.monthEn
              )?.[0]?.count92Day;
            z[`lesson-${item.code}-95`] = attendanceStore?.data
              ?.filter((attendance) => attendance.studentId === student.id)
              ?.filter(
                (attendance) => attendance.monthName.trim() === item.monthEn
              )?.[0]?.count95Lesson;
            z[`lesson-${item.code}-92`] = attendanceStore?.data
              ?.filter((attendance) => attendance.studentId === student.id)
              ?.filter(
                (attendance) => attendance.monthName.trim() === item.monthEn
              )?.[0]?.count92Lesson;
            attendanceStore?.data
              ?.filter((attendance) => attendance.studentId === student.id)
              ?.filter(
                (attendance) => attendance.monthName.trim() === item.monthEn
              )
              ?.map((attendance) => {
                count.dayCount95 = count.dayCount95 + attendance.count95Day;
                count.dayCount92 = count.dayCount92 + attendance.count92Day;
                count.lessonCount95 =
                  count.lessonCount95 + attendance.count95Lesson;
                count.lessonCount92 =
                  count.lessonCount92 + attendance.count92Lesson;
              });
          } else {
            z[`day-${item.code}-95`] = "0";
            z[`day-${item.code}-92`] = "0";
            z[`lesson-${item.code}-95`] = "0";
            z[`lesson-${item.code}-92`] = "0";

            b[`day-count-95`] = count.dayCount95;
            b[`day-count-92`] = count.dayCount92;
            b[`lesson-count-95`] = count.lessonCount95;
            b[`lesson-count-92`] = count.lessonCount92;
          }
        });
        z[`day-count-95`] = count.dayCount95;
        z[`day-count-92`] = count.dayCount92;
        z[`lesson-count-95`] = count.lessonCount95;
        z[`lesson-count-92`] = count.lessonCount92;
        z[`className`] = "allCount";
        z.id = numeration += 1;
        z.firstName = `${student?.lastName} ${student?.firstName} ${student?.middleName}`;
        a.push(z);
      }
    });
    return a;
  }, [studentsStore, attendanceStore]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  return (
    <>
      {getTableProps && (
        <table id={id} className="roboto borj" {...getTableProps()}>
          <thead>
            {headerGroups?.map((headerGroup, idx) => (
              <tr key={idx} {...headerGroup?.getHeaderGroupProps()}>
                {headerGroup?.headers?.map((column, idx) => (
                  <th key={idx} {...column?.getHeaderProps()}>
                    {column?.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {getTableBodyProps && (
            <tbody {...getTableBodyProps()}>
              {rows?.map((row, idx) => {
                prepareRow(row);
                return (
                  <tr key={idx} {...row?.getRowProps()}>
                    {row?.cells?.map((cell, idx) => {
                      return (
                        <td key={idx} {...cell?.getCellProps()}>
                          {cell?.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      )}
    </>
  );
};

StudentsAttendanceComponent.propTypes = {
  students: PT.any,
  quaterTitle: PT.string,
  id: PT.string,
};

export default StudentsAttendanceComponent;
