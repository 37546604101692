import {
  GET_ASSIGNMENTS_REQUEST,
  GET_ASSIGNMENTS_SUCCESS,
  GET_ASSIGNMENTS_ERROR,
} from "../actionTypes";
import { statuses } from "../../config";

const initialState = {
  assignments: {},
  error: "",
  status: statuses.INITIAL,
};

export const assignmentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ASSIGNMENTS_REQUEST:
      return {
        ...state,
        assignments: {},
        error: "",
        status: statuses.LOADING,
      };
    case GET_ASSIGNMENTS_SUCCESS:
      return {
        ...state,
        assignments: action.payload,
        error: "",
        status: statuses.SUCCESS,
      };
    case GET_ASSIGNMENTS_ERROR:
      return {
        ...state,
        error: action.payload,
        status: statuses.ERROR,
        assignments: {},
      };

    default:
      return state;
  }
};
