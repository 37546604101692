/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { constants } from "../../config";
import SelectSearch, { fuzzySearch } from "react-select-search";
// import { toast } from "react-toastify";
// import { Link } from "react-router-dom";
// import moment from "moment";
import { getClassBySchoolId } from "../../store/class/actions";
// import { getShifts } from "../../store/shift/actions";
import { getShiftTimes } from "../../store/shiftTime/actions";
import { getSchoolById } from "../../store/school/actions";
import { getCalendarTopicByClassIdAndCourseId } from "../../store/calendarTopic/actions";

import {
  InstractorLayout,
  // PaginationBlock,
  // SearchField,
  // GoBack,
  NoData,
  // FullCurrentDate,
  QuarterGradeTable,
} from "../../components";
import { getSchedulesByInstructorId } from "../../store/schedule/actions";
import { getCourses } from "../../store/course/actions";

export default function QuarterGrade() {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  // const shiftsStore = useSelector((store) => store.shifts.shifts);
  const shiftTimesStore = useSelector((store) =>
    store.shiftTimes.shiftTimes.sort((a, b) =>
      a.startTime < b.startTime ? -1 : a.startTime > b.startTime ? 1 : 0
    )
  );
  const [courseId, setCourseId] = useState();
  const [classId, setClassId] = useState();
  // const [quarterId, setQuarterId] = useState();
  // const [topicId, setTopicId] = useState();
  // const [date, setDate] = useState(moment().format("YYYY-MM-DD"));

  const schedulesStore = useSelector((store) =>
    store.schedules.schedules.filter(
      (item) => item.schoolId === JSON.parse(localStorage.getItem("school"))?.id
    )
  );
  // const calendarTopicsStore = useSelector(
  //   (store) => store.calendarTopics.calendarTopics
  // );
  const courseStore = useSelector((store) => store.courses.courses);
  const instructorsStore = useSelector(
    (store) => store.instructors.instructors
  );
  // const quartersStore = useSelector((store) => store.quarters.quarters);

  console.log(schedulesStore);
  useEffect(async () => {
    await dispatch(
      getSchedulesByInstructorId(JSON.parse(localStorage.getItem("person"))?.id)
    );
    // await dispatch(getSchedules());
    await dispatch(getCourses());
    await dispatch(getClassBySchoolId());
    await dispatch(getShiftTimes());
    await dispatch(
      getSchoolById(JSON.parse(localStorage.getItem("school"))?.id)
    );
  }, [classId]);

  let days = constants.days;
  let daysLetter = days
    .slice(0, JSON.parse(localStorage.getItem("school"))?.studyDay)
    .map((day) => {
      let str = day?.[`${String(i18n.language).toLowerCase()}`];
      let strEn = day?.en;
      let a = [];
      if (
        shiftTimesStore
          // .filter((shiftTime) => shiftTime.shiftId == shiftId)
          .map(
            (shiftTime) =>
              !!schedulesStore?.find(
                (schedule) => schedule.shiftTimeId == shiftTime.id
              )
          )
          .indexOf(true) !== -1
      ) {
        shiftTimesStore
          // .filter((shiftTime) => shiftId && shiftTime.shiftId == shiftId)
          .map((shiftTime, idx) => {
            let c = {};
            if (
              schedulesStore
                ?.filter((h) => strEn == h.weekDay)
                .filter((h) => h.shiftTimeId == shiftTime.id)
                .map((z) => {
                  return {
                    [z.classId]: courseStore.find(
                      (item) => item.id == z.courseId
                    )?.[
                      String(i18n.language).toLowerCase() === "en" ||
                      String(i18n.language).toLowerCase() === "tr"
                        ? `title`
                        : `title${String(i18n.language).toUpperCase()}`
                    ],
                  };
                }).length > 0
            ) {
              let d = [];
              schedulesStore
                ?.filter((h) => h.shiftTimeId == shiftTime.id)
                .filter((h) => strEn == h.weekDay)
                .map((z) => {
                  // return {
                  //   [`scheduleId${z.classId}`]: z.id,
                  //   [z.classId]: courseStore.find(
                  //     (item) => item.id == z.courseId
                  //   )?.[
                  //     String(i18n.language).toLowerCase() === "en" ||
                  //     String(i18n.language).toLowerCase() === "tr"
                  //       ? `title`
                  //       : `title${String(i18n.language).toUpperCase()}`
                  //   ],
                  // };
                  let l = schedulesStore
                    ?.filter((h) => h.shiftTimeId == shiftTime.id)
                    .filter((h) => strEn == h.weekDay)
                    .filter((h) => z.classId === h.classId)
                    .map((z) => ({
                      ...z,
                      id: z.id,
                      classId: z.classId,
                      courseTitle: courseStore.find(
                        (item) => item.id == z.courseId
                      )?.[
                        String(i18n.language).toLowerCase() === "en" ||
                        String(i18n.language).toLowerCase() === "tr"
                          ? `title`
                          : `title${String(i18n.language).toUpperCase()}`
                      ],
                    }));
                  d.push({ [z.classId]: l });
                });
              if (d.length > 0) {
                c = Object.assign(
                  {},
                  {
                    dayKey: day.en,
                    dayId: day.id,
                    days: str[idx],
                    shiftTime: idx + 1,
                    shiftTimeId: shiftTime.id,
                  },
                  ...d
                );
              } else {
                c = {
                  dayKey: day.en,
                  dayId: day.id,
                  days: str[idx],
                  shiftTime: idx + 1,
                  shiftTimeId: shiftTime.id,
                };
              }

              a.push(c);
            } else {
              c = {
                dayKey: day.en,
                dayId: day.id,
                days: str[idx],
                shiftTime: idx + 1,
                shiftTimeId: shiftTime.id,
              };
              a.push(c);
            }
          });
      } else {
        let h = shiftTimesStore
          // .filter((shiftTime) =>
          //   shiftId
          //     ? shiftTime.shiftId == shiftId
          //     : shiftTime.shiftId == shiftsStore?.[0]?.id
          // )
          .map((shiftTime, idx) => {
            return {
              dayKey: day.en,
              dayId: day.id,
              days: str[idx],
              shiftTime: idx + 1,
              shiftTimeId: shiftTime.id,
            };
          });
        a = h;
      }

      return a;
    });
  console.log("daysLetter", daysLetter);

  let opt = [];
  instructorsStore.map((instructor) => {
    return instructor.instructorCourseTitleDtoList.map((course) => {
      opt.push({
        name: `${instructor.instructorTitle} - ${
          courseStore.find((item) => item.id === course.courseId)?.[
            String(i18n.language).toLowerCase() === "en" ||
            String(i18n.language).toLowerCase() === "tr"
              ? `title`
              : `title${String(i18n.language).toUpperCase()}`
          ]
        }`,
        value: [instructor.instructorId, course.courseId],
        key: "intructorId",
      });
    });
  });

  // const quartersOptions = quartersStore.map((item) => ({
  //   name: `${item.quarter} ${t("instructor:quarter")}`,
  //   value: item.id,
  //   key: "quarterId",
  // }));

  const courseOptions = schedulesStore
    .filter(
      (thing, index, self) =>
        index === self.findIndex((t) => t.courseId === thing.courseId)
    )
    .map((item) => ({
      name: `${
        String(i18n.language).toUpperCase() !== "EN" &&
        String(i18n.language).toUpperCase() !== "TR"
          ? `${item[`courseTitle${String(i18n.language).toUpperCase()}`]} `
          : `${item.courseTitle} `
      }`,
      value: item.courseId,
      key: "courseId",
    }));
  const classOptions = schedulesStore
    .filter((item) => item.courseId == courseId)
    .filter(
      (thing, index, self) =>
        index ===
        self.findIndex(
          (t) => t.courseId === thing.courseId && t.classId === thing.classId
        )
    )
    .map((item) => ({
      name: `${item.classTitle}`,
      value: item.classId,
      key: "classId",
    }));
  return (
    <InstractorLayout>
      <div className="school-admin__schedule school-presetting__body school-presetting__body-color">
        <div className="school-presetting__body-row">
          <div className="school-presetting__body-title">
            <h2>{t("instructor:final grades")}</h2>
          </div>
        </div>
        <div
          className="school-presetting__search-filter"
          style={{ margin: "16px 0" }}
        >
          <div className="school-presetting__search">
            <p style={{ width: 100 }}>{t("presetting:subject")}:</p>{" "}
            <SelectSearch
              options={courseOptions}
              search
              className="select-search filter drop-up"
              filterOptions={fuzzySearch}
              name="courseId"
              value={courseId}
              onChange={(_, k) => {
                setCourseId(k.value);
                setClassId(null);
              }}
              required
              placeholder={`${t("presetting:subject")}`}
            />
          </div>
          <div className="school-presetting__filter">
            {courseId && (
              <>
                <p style={{ width: 100 }}>{t("schoolAdmin:class")}:</p>{" "}
                <SelectSearch
                  options={classOptions}
                  search
                  className="select-search filter drop-up"
                  filterOptions={fuzzySearch}
                  name="classId"
                  value={classId}
                  onChange={(_, k) => {
                    setClassId(k.value);
                    dispatch(
                      getCalendarTopicByClassIdAndCourseId({
                        classId: k.value,
                        courseId: courseId,
                      })
                    );
                  }}
                  required
                  placeholder={`${t("schoolAdmin:class")}`}
                />
              </>
            )}
          </div>
        </div>

        {/* <div
          className="school-presetting__search-filter"
          style={{ margin: "16px 0" }}
        >
          <div className="school-presetting__search">
            <FullCurrentDate date={date} />{" "}
            {classId && courseId && (
              <SelectSearch
                options={topicsOptions}
                search
                className="select-search filter drop-up"
                filterOptions={fuzzySearch}
                name="topicId"
                value={topicId}
                onChange={(_, k) => {
                  setTopicId(k.value);
                }}
                required
                placeholder={`${t("instructor:topic")}`}
              />
            )}
          </div>
          <div className="school-presetting__filter">
            <button
              className="primary small"
              // onClick={() => ()}
            >
              {t("schoolAdmin:export")}
            </button>
          </div>
        </div> */}
        {classId && courseId ? (
          <div
            id="table-layout"
            className="table-layout class-journal quarter-grade"
            style={{
              width: "100%",
              height: 600,
              overflow: "scroll",
              position: "relative",
            }}
            onScroll={(e) => {
              localStorage.setItem("scheduleScrollTop", e.target.scrollTop);
            }}
          >
            <QuarterGradeTable classId={classId} courseId={courseId} />
          </div>
        ) : (
          <NoData
            messages={[`${t("schoolAdmin:choose shedule and type of render")}`]}
          />
        )}
      </div>
    </InstractorLayout>
  );
}
