import React, { PureComponent } from "react";

export default class NotificationBadge extends PureComponent {
  render() {
    return (
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.9999 28.5666C15.5249 28.5545 16.0286 28.3572 16.4221 28.0095C16.8156 27.6618 17.0734 27.1861 17.1499 26.6666H12.7666C12.8453 27.2002 13.1152 27.6871 13.526 28.0366C13.9368 28.3861 14.4606 28.5744 14.9999 28.5666Z"
          fill="#797979"
        />
        <path
          d="M27.0917 23.1916C26.2882 22.4752 25.5846 21.654 25.0001 20.75C24.3619 19.5021 23.9795 18.1393 23.8751 16.7416V12.625C23.8717 12.1249 23.8271 11.626 23.7417 11.1333C23.0858 11.0022 22.4555 10.7658 21.8751 10.4333C22.0963 11.1457 22.2087 11.8874 22.2084 12.6333V16.75C22.3106 18.455 22.7796 20.1179 23.5834 21.625C24.1587 22.5366 24.8414 23.376 25.6167 24.125H4.27508C5.05042 23.376 5.73308 22.5366 6.30841 21.625C7.11226 20.1179 7.58127 18.455 7.68341 16.75V12.625C7.67903 11.6605 7.86492 10.7047 8.23045 9.81223C8.59598 8.91974 9.13396 8.10812 9.81359 7.42384C10.4932 6.73957 11.3012 6.19607 12.1911 5.82447C13.0811 5.45287 14.0356 5.26047 15.0001 5.25829C16.4116 5.25942 17.7913 5.67684 18.9667 6.45829C18.8372 5.98245 18.7644 5.49292 18.7501 4.99996V4.47496C17.88 4.04693 16.9452 3.76537 15.9834 3.64163V2.59163C15.9834 2.29657 15.8662 2.0136 15.6576 1.80497C15.4489 1.59634 15.166 1.47913 14.8709 1.47913C14.5759 1.47913 14.2929 1.59634 14.0843 1.80497C13.8756 2.0136 13.7584 2.29657 13.7584 2.59163V3.68329C11.6048 3.98709 9.63389 5.05975 8.20943 6.70324C6.78497 8.34673 6.00319 10.4501 6.00841 12.625V16.7416C5.90404 18.1393 5.52156 19.5021 4.88341 20.75C4.30915 21.6519 3.61691 22.4731 2.82508 23.1916C2.73619 23.2697 2.66495 23.3658 2.6161 23.4736C2.56724 23.5814 2.5419 23.6983 2.54175 23.8166V24.95C2.54175 25.171 2.62955 25.3829 2.78583 25.5392C2.94211 25.6955 3.15407 25.7833 3.37508 25.7833H26.5417C26.7628 25.7833 26.9747 25.6955 27.131 25.5392C27.2873 25.3829 27.3751 25.171 27.3751 24.95V23.8166C27.3749 23.6983 27.3496 23.5814 27.3007 23.4736C27.2519 23.3658 27.1806 23.2697 27.0917 23.1916Z"
          fill="#797979"
        />
        <path
          d="M24.9999 9.16671C27.3011 9.16671 29.1666 7.30123 29.1666 5.00004C29.1666 2.69885 27.3011 0.833374 24.9999 0.833374C22.6987 0.833374 20.8333 2.69885 20.8333 5.00004C20.8333 7.30123 22.6987 9.16671 24.9999 9.16671Z"
          fill="#797979"
        />
      </svg>
    );
  }
}
