/* eslint-disable indent */
// import { useTranslation } from "react-i18next";

import moment from "moment";
import pdfMake from "pdfmake-lite";
import pdfFonts from "pdfmake-lite/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function StudentRecordExportPDF(
  classPerformance,
  listExcellence,
  classTitle,
  quarterTitle,
  instructorName,
  t,
  i18n
) {
  // const { t, i18n } = useTranslation();
  const schoolName = JSON.parse(localStorage.getItem("school"))?.name;
  const instructorTitle = instructorName;
  const chronicleName = JSON.parse(
    localStorage.getItem("chronicleActive")
  )?.selectorTitle;
  const quarterName = quarterTitle;
  const className = classTitle;
  const studentSize = listExcellence.length;

  const a = classPerformance.instructorCoursePerformanceReports;
  let courses = [];
  a.map((item) => {
    if (courses.find((d) => d.courseId === item.courseId)) {
      console.log("asdfaf");
    } else {
      const c = a
        .filter((z) => z.courseId === item.courseId)
        .filter((z) => z.instructorId !== item.instructorId);
      if (c.length == 0) {
        courses.push(item);
      } else {
        const another = c?.[0];
        let g;
        if (item?.quality == 0 || another?.quality == 0) {
          g = {
            ...item,
            totalFive: item?.totalFive + another?.totalFive,
            totalFour: item?.totalFour + another?.totalFour,
            totalThree: item?.totalThree + another?.totalThree,
            totalTwo: item?.totalTwo + another?.totalTwo,
            quality: Math.round(item?.quality + another?.quality),
            achievement: Math.round(item?.achievement + another?.achievement),
          };
        } else {
          g = {
            ...item,
            totalFive: item?.totalFive + another?.totalFive,
            totalFour: item?.totalFour + another?.totalFour,
            totalThree: item?.totalThree + another?.totalThree,
            totalTwo: item?.totalTwo + another?.totalTwo,
            quality: Math.round((item?.quality + another?.quality) / 2),
            achievement: Math.round(
              (item?.achievement + another?.achievement) / 2
            ),
          };
        }

        console.log("gg", g);
        courses.push(g);
      }
    }
  });
  console.log("bbbbbb", courses);

  const writeRotatedText = function (text) {
    const canvas = document.createElement("canvas");
    canvas.width = 100;
    canvas.height = 600;
    const x = canvas.height / 2;
    const ctx = canvas.getContext("2d");
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.font = "bold 40pt Helvetica";
    ctx.fillStyle = "#000";
    ctx.rotate((90 * Math.PI) / 180);
    ctx.textAlign = "center";
    ctx.fillText(text, x, -10);
    return canvas.toDataURL();
  };
  const rotateFullName = function (text) {
    const canvas = document.createElement("canvas");
    canvas.width = 600;
    canvas.height = 600;
    const x = canvas.height / 2;
    const y = canvas.width / 2;
    const ctx = canvas.getContext("2d");
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.font = "bold 40pt Helvetica";
    ctx.fillStyle = "#000";
    ctx.rotate((90 * Math.PI) / 180);
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.fillText(text, x, -y);
    return canvas.toDataURL();
  };
  const achievement = {
    image: writeRotatedText(t("reports:achievement")),
    alignment: "center",
    width: 10,
    height: 60,
    border: [false, false, false, false],
  };
  const quality = {
    image: writeRotatedText(t("reports:knowledge quality")),
    alignment: "center",
    width: 10,
    height: 60,
    border: [false, false, false, false],
  };
  const data = [];
  let o = {
    image: writeRotatedText("№"),
    alignment: "center",
    width: 10,
    height: 60,
  };
  data.push(o);
  o = {
    image: rotateFullName(t("validation:full name")),
    alignment: "center",
    width: 80,
    height: 60,
  };
  data.push(o);
  courses.map((course) => {
    const o = {
      image: writeRotatedText(
        String(i18n.language).toUpperCase() !== "EN" &&
          String(i18n.language).toUpperCase() !== "TR"
          ? `${course[`courseTitle${String(i18n.language).toUpperCase()}`]}`
          : `${course.courseTitle}`
      ),
      alignment: "center",
      width: 10,
      height: 60,
    };
    data.push(o);
  });
  data.push({
    image: writeRotatedText(""),
    alignment: "center",
    width: 1,
    height: 60,
    border: [false, false, false, false],
  });
  data.push(quality);
  data.push(achievement);
  const tableData = [data];
  classPerformance.studentPerformanceReports.map((item, index) => {
    const o = [];
    o.push(index + 1);
    o.push(item.studentTitle);
    courses.map((course) => {
      let c = item.courseDtoList.find(
        (el) => el.courseId === course.courseId && el.quarterMark !== null
      );
      if (c !== undefined) {
        o.push(c.quarterMark);
      } else {
        c = item.courseDtoList.find((el) => el.courseId === course.courseId);
        if (c !== undefined) {
          o.push(c.quarterMark);
        } else {
          o.push("");
        }
      }
    });
    const num = isNaN(item.quality) ? 0 : Math.round(item.quality);
    const num1 = isNaN(item.achievement) ? 0 : Math.round(item.achievement);

    o.push({
      text: "",
      border: [false, false, false, false],
    });
    o.push({
      text: `${num}%`,
      border: [false, false, false, false],
    });
    o.push({
      text: `${num1}%`,
      border: [false, false, false, false],
    });
    tableData.push(o);
  });
  const fiv = { text: '"5"', style: "tableStat" };
  const fou = { text: '"4"', style: "tableStat" };
  const thr = { text: '"3"', style: "tableStat" };
  const tw = { text: '"2"', style: "tableStat" };
  const qual = {
    text: t("reports:knowledge quality"),
    style: "tableStat",
  };
  const perf = { text: t("reports:achievement"), style: "tableStat" };
  const stat = [];
  const five = [fiv];
  const four = [fou];
  const three = [thr];
  const two = [tw];
  const qua = [qual];
  const per = [perf];
  const colWidth = [];
  courses.forEach((course, index) => {
    five.push({
      text: course.totalFive,
      style: "colStyle",
    });
    four.push({
      text: course.totalFour,
      style: "colStyle",
    });
    three.push({
      text: course.totalThree,
      style: "colStyle",
    });
    two.push({
      text: course.totalTwo,
      style: "colStyle",
    });
    qua.push({
      text: `${course.quality}%`,
      bold: true,
      alignment: "center",
    });
    per.push({
      text: `${course.achievement}%`,
      bold: true,
      alignment: "center",
    });
    colWidth.push(index === 0 ? 103 : 18);
  });
  colWidth.push(10);
  stat.push(five);
  stat.push(four);
  stat.push(three);
  stat.push(two);
  stat.push(qua);
  stat.push(per);

  const checkUndefined = (data) => {
    return data === undefined ? 0 : data;
  };
  const lastCol1 = [
    t("reports:excellents"),
    t("reports:strikers"),
    "",
    t("reports:percent of excellents and strikers"),
  ];
  const lastColKey1 = [
    listExcellence.filter((item) => item.fives === 1).length,
    listExcellence.filter((item) => item.fours === 1).length,
    "",
    t("reports:percent of excellents and strikers"),
  ];
  const lastColKey2 = [
    checkUndefined(
      listExcellence
        .filter((item) => item.fives === 1)
        .filter((item) => item.studentGender === 1).length
    ),
    checkUndefined(
      listExcellence
        .filter((item) => item.fours === 1)
        .filter((item) => item.studentGender === 1).length
    ),
  ];
  const lastColData = [
    checkUndefined(
      listExcellence
        .filter((item) => item.fives === 1)
        .filter((item) => item.studentGender === 2).length
    ),
    checkUndefined(
      listExcellence
        .filter((item) => item.fours === 1)
        .filter((item) => item.studentGender === 2).length
    ),
  ];
  const lastData = [
    [
      "",
      {
        text: t("reports:total"),
        style: "lastTableColumns2",
      },
      { text: t("presetting:male presetting"), style: "lastTableColumns2" },
      { text: t("presetting:female presetting"), style: "lastTableColumns2" },
      { text: t("reports:excellents"), style: "headCol2" },
      { text: t("reports:strikers"), style: "headCol2" },
    ],
  ];
  const dataPercent =
    ((listExcellence.filter((item) => item.fives === 1).length +
      listExcellence.filter((item) => item.fours === 1).length) *
      100) /
    studentSize;
  const dataLength = Math.max(
    lastCol1.length,
    listExcellence.filter((item) => item.fives === 1).length,
    listExcellence.filter((item) => item.fours === 1).length
  );
  for (let i = 0; i < dataLength; i++) {
    const d = [
      {
        text: lastCol1[i] === undefined ? "" : lastCol1[i],
        style: "lastTableColumns",
      },
      {
        text:
          lastColKey1[i] === t("reports:percent of excellents and strikers")
            ? `${dataPercent.toFixed(0)}%`
            : lastColKey1[i] === undefined
            ? ""
            : lastColKey1[i],
        style: "lastTableColumns2",
      },
      {
        text: lastColKey2[i] === undefined ? "" : lastColKey2[i],
        style: "lastTableColumns2",
      },
      { text: lastColData[i], style: "lastTableColumns2" },
      {
        text:
          listExcellence.filter((item) => item.fives === 1)[i]?.studentTitle ===
          undefined
            ? ""
            : `${i + 1} ${
                listExcellence.filter((item) => item.fives === 1)[i]
                  ?.studentTitle
              }`,
        style: "lastTableColumns3",
      },
      {
        text:
          listExcellence.filter((item) => item.fours === 1)[i]?.studentTitle ===
          undefined
            ? ""
            : `${i + 1} ${
                listExcellence.filter((item) => item.fours === 1)[i]
                  ?.studentTitle
              }`,
        style: "lastTableColumns3",
      },
    ];
    lastData.push(d);
  }
  const docDefinition = {
    info: {
      title: t("reports:student record"),
      author: "Kundoluk Team",
    },
    pageOrientation: "portrait",
    pageMargins: [30, 60, 20, 60],
    pageSize: "A4",
    ownerPassword: "123456",
    permissions: {
      printing: "highResolution", //'lowResolution'
      modifying: false,
      copying: false,
      annotating: true,
      fillingForms: true,
      contentAccessibility: true,
      documentAssembly: true,
    },
    content: [
      {
        text: t("reports:student record"),
        style: "header",
      },
      {
        columns: [
          {
            style: "subHeader",
            width: 150,
            alignment: "right",
            text: t("userApplicationForm:class"),
          },
          {
            style: "subHeader",
            width: 150,
            alignment: "left",
            text: className,
          },
        ],
      },
      {
        columns: [
          {
            style: "subHeader",
            width: 150,
            alignment: "right",
            text: t("schoolAdmin:school"),
          },
          {
            width: 400,
            style: "subHeader",
            alignment: "left",
            text: schoolName,
          },
        ],
      },
      {
        columns: [
          {
            style: "subHeader",
            width: 150,
            alignment: "right",
            text: t("reports:class instructor"),
          },
          {
            style: "subHeader",
            alignment: "left",
            text: instructorTitle,
          },
        ],
      },
      {
        columns: [
          {
            style: "subHeader",
            width: 150,
            alignment: "right",
            text: t("presetting:chrinocal year presetting"),
          },
          {
            style: "subHeader",
            alignment: "left",
            text: chronicleName,
          },
        ],
      },
      {
        columns: [
          {
            style: "subHeader",
            width: 150,
            alignment: "right",
            text: t("instructor:quarter"),
          },
          {
            style: "subHeader",
            alignment: "left",
            text: quarterName,
          },
        ],
      },
      {
        style: "tableExample",
        table: {
          body: tableData,
        },
        layout: {
          hLineWidth: function (i, node) {
            return i === 0 || i === 1 || i === node.table.body.length ? 0.5 : 0;
          },
          vLineWidth: function () {
            return 0;
          },
          fillColor: function (rowIndex, node, columnIndex) {
            return rowIndex % 2 && columnIndex < node.table.widths.length - 3
              ? "silver"
              : "white";
          },
        },
      },
      {
        style: "tableExample",
        // margin: [50,0,0,0],
        table: {
          widths: colWidth,
          body: stat,
        },
        layout: {
          hLineWidth: function (i, node) {
            return i === 0 || i === 1 || i === node.table.body.length ? 0 : 0;
          },
          vLineWidth: function () {
            return 0;
          },
          paddingLeft: function () {
            return 0;
          },
          paddingRight: function () {
            return 0;
          },
        },
      },
      {
        style: "lastTable",
        margin: [0, 20, 0, 0],
        table: {
          widths: [130, 30, 20, 20, 110, 140],
          body: lastData,
        },
        layout: {
          hLineWidth: function (i, node) {
            return i === 0 || i === 1 || i === node.table.body.length ? 0 : 0;
          },
          vLineWidth: function () {
            return 0;
          },
          paddingLeft: function () {
            return 0;
          },
          paddingRight: function () {
            return 0;
          },
        },
      },
      {
        margin: [45, 20, 0, 0],
        alignment: "justify",
        bold: true,
        fontSize: 7,
        text: `${t(
          "reports:class instructor"
        )} _______________________ ${instructorTitle}`,
      },
    ],
    styles: {
      header: {
        fontSize: 9,
        bold: true,
        margin: [20, 0, 0, 10],
      },
      subHeader: {
        fontSize: 6,
        bold: true,
        margin: [0, 0, 0, 2],
      },
      tableHeader: {
        fontSize: 7,
      },
      tableExample: {
        fontSize: 6,
        margin: [0, 20, 0, 5],
      },
      tableStat: {
        alignment: "right",
        margin: [0, 0, 10, 0],
        bold: true,
      },
      colStyle: {
        alignment: "center",
      },
      lastTable: {
        bold: true,
        fontSize: 6,
      },
      lastTableColumns: {
        alignment: "right",
      },
      lastTableColumns2: {
        alignment: "center",
      },
      lastTableColumns3: {
        margin: [20, 0, 0, 0],
      },
      headCol2: {
        margin: [10, 0, 0, 0],
      },
      lastCol3: {
        margin: [10, 0, 0, 0],
      },
    },
    defaultStyle: {
      columnGap: 20,
    },
  };
  const fileName = `${schoolName}  ${chronicleName} ${quarterName} ${className} - ${t(
    "reports:student record"
  )}  ${moment(Date.now()).format("LLL")}`;
  pdfMake.createPdf(docDefinition).download(`${fileName}.pdf`);
}
