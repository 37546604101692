/* eslint-disable indent */
import React, { useCallback, useState, useEffect } from "react";
import { debounce } from "lodash";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import { InstractorLayout, NoData, SearchField } from "../../components";
import { getSchedulesByInstructorId } from "../../store/schedule/actions";
import { Link } from "react-router-dom";

export default function Assignment() {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const schedulesStore = useSelector((store) =>
    store.schedules.schedules
      .filter(
        (item) =>
          item.schoolId === JSON.parse(localStorage.getItem("school"))?.id
      )
      .filter(
        (thing, index, self) =>
          index ===
          self.findIndex(
            (t) => t.courseId === thing.courseId && t.classId === thing.classId
          )
      )
      .sort((a, b) =>
        a.classTitle
          .replace(/[0-9]/g, "")
          .localeCompare(b.classTitle.replace(/[0-9]/g, ""))
      )
      .sort(
        (a, b) =>
          a.classTitle.replace(/[^\d].*/, "") -
          b.classTitle.replace(/[^\d].*/, "")
      )
  );
  const [searchText, setSearchText] = useState("");
  const [searchedCurrentData, setSearchedCurrentData] = useState([]);

  useEffect(async () => {
    await dispatch(
      getSchedulesByInstructorId(JSON.parse(localStorage.getItem("person"))?.id)
    );
  }, []);

  const debouncedSearch = useCallback(
    debounce((text) => {
      setSearchText(text.toLowerCase());
      // setFilterTypeNumber(1);

      const a = schedulesStore.filter((item) => {
        const a = String(`${item.classTitle}`).toLowerCase();
        const c = a.includes(text.toLowerCase());

        const b = String(
          `${
            String(i18n.language).toUpperCase() !== "EN" &&
            String(i18n.language).toUpperCase() !== "TR"
              ? `${item[`courseTitle${String(i18n.language).toUpperCase()}`]} `
              : `${item.courseTitle} `
          }`
        ).toLowerCase();
        const d = b.includes(text.toLowerCase());
        if (c) {
          return c;
        } else if (d) {
          return d;
        }
      });
      setSearchedCurrentData([...a]);
    }, 1000),
    []
  );
  const searchInputHandler = (e) => {
    debouncedSearch(e.target.value);
  };

  const tableDataRender =
    searchedCurrentData.length > 0 ? (
      searchedCurrentData.map((item, idx) => (
        <Link
          to={`/instructor/assignments/${item.classId}-${item.courseId}`}
          key={idx}
          className="assignment_item roboto"
        >
          <div>
            <h2>{item.classTitle}</h2>
            <p>
              {`${
                String(i18n.language).toUpperCase() !== "EN" &&
                String(i18n.language).toUpperCase() !== "TR"
                  ? `${
                      item[`courseTitle${String(i18n.language).toUpperCase()}`]
                    } `
                  : `${item.courseTitle} `
              }`}
            </p>
          </div>
        </Link>
      ))
    ) : searchText.length == 0 ? (
      schedulesStore.map((item, idx) => (
        <Link
          to={`/instructor/assignments/${item.classId}-${item.courseId}`}
          key={idx}
          className="assignment_item roboto"
        >
          <div>
            <h2>{item.classTitle}</h2>
            <p>
              {`${
                String(i18n.language).toUpperCase() !== "EN" &&
                String(i18n.language).toUpperCase() !== "TR"
                  ? `${
                      item[`courseTitle${String(i18n.language).toUpperCase()}`]
                    } `
                  : `${item.courseTitle} `
              }`}
            </p>
          </div>
        </Link>
      ))
    ) : (
      <NoData messages={[`${t("schoolAdmin:here is nothing")}`]} />
    );
  return (
    <InstractorLayout>
      <div className="school-admin__schedule-group school-admin__schedule school-presetting__body school-presetting__body-color sections">
        <div className="school-presetting__body-row">
          <div className="school-presetting__body-title">
            <h2>{t("schoolAdmin:assignment")}</h2>
          </div>
          <div className="school-presetting__body-description">
            <p className="body-1">{t("schoolAdmin:assignment description")}</p>
          </div>
        </div>
        <div className="school-presetting__search-filter">
          <div className="school-presetting__search">
            <SearchField
              onChange={searchInputHandler}
              defaultValue={searchText}
              placeholder={t("common:search")}
            />
          </div>
          <div className="school-presetting__filter"></div>
        </div>
        <div className="school-presetting__subjects-list">
          <div className="school-presetting__teachers-body">
            <div className="school-presetting__teachers-table assignment_list">
              {tableDataRender}
            </div>
          </div>
        </div>
      </div>
    </InstractorLayout>
  );
}
