import React from "react";
import PT from "prop-types";
import Steps, { Step } from "rc-steps";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { LanguageDropdown, GoBack } from "../../components";
import "../../../node_modules/rc-steps/assets/index.css";
import "./Layout.scss";

export default function ApplicationFormLayout(props) {
  const { t } = useTranslation(["userApplicationForm"]);

  const { children, style, id } = props;
  const history = useHistory();

  return (
    <div className="login-layout">
      <div className="login-layout__inner container">
        <div className="login-layout__header">
          <GoBack />
          <Steps
            current={id - 1}
            labelPlacement="vertical"
            style={{ width: 500 }}
          >
            <Step
              title={t("userApplicationForm:step1")}
              disabled={id <= 1}
              onStepClick={() => {
                history.push("/user-application-form/1");
              }}
            />
            <Step
              title={t("userApplicationForm:step2")}
              disabled={id <= 2}
              onStepClick={() => {
                history.push("/user-application-form/2");
              }}
            />
            <Step
              title={t("userApplicationForm:step3")}
              disabled={id <= 3}
              onStepClick={() => {
                history.push("/user-application-form/3");
              }}
            />
          </Steps>
          <LanguageDropdown />
        </div>
        <div className="v-center h-center">
          <div className="layout__card" style={{ ...style }}>
            <div className="layout__card-inner">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

ApplicationFormLayout.propTypes = {
  children: PT.any,
  style: PT.object,
  id: PT.number,
};
