import { urls, toastOption } from "../../config";
import { API } from "../../utils";
import { toast } from "react-toastify";
import i18n from "../../i18n";

import {
  GET_STUDENT_COURSES_REQUEST,
  GET_STUDENT_COURSES_SUCCESS,
  GET_STUDENT_COURSES_ERROR,
} from "../actionTypes";

export const getStudentCourses = (studentId) => async (dispatch) => {
  dispatch(getStudentCoursesRequest());

  await API.get(
    `${urls.STUDENT}course/student/${studentId}/chronicle/${
      JSON.parse(localStorage.getItem("chronicleActive"))?.id
    }`
  )
    .then((res) => {
      console.log(res.data);
      dispatch(getStudentCoursesSuccess(res.data));
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getStudentCoursesFailure(error.message));
      return error.message;
    });
};

export const removeStudentCourse = (id) => async (dispatch) => {
  dispatch(getStudentCoursesRequest());
  await API.delete(`${urls.STUDENT}course?id=${id}`)
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess deleted"), toastOption);
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getStudentCoursesFailure(error.message));
      return error.message;
    });
};

export const addStudentCourse = (values) => async () => {
  const data = {
    ...values,
    chronicleId: JSON.parse(localStorage.getItem("chronicleActive"))?.id,
  };
  await API.post(`${urls.STUDENT}course/create`, data)
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess added"), toastOption);
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      return error.message;
    });
};
export const getStudentCoursesRequest = () => {
  return {
    type: GET_STUDENT_COURSES_REQUEST,
  };
};

export const getStudentCoursesSuccess = (data) => {
  return {
    type: GET_STUDENT_COURSES_SUCCESS,
    payload: data,
  };
};

export const getStudentCoursesFailure = (error) => {
  return {
    type: GET_STUDENT_COURSES_ERROR,
    payload: error,
  };
};
