import React from "react";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getSchoolById } from "../../store/school/actions";
export default function ChangeSchool() {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  let schoolList = JSON.parse(localStorage.getItem("user"))?.schools;

  // useEffect(async () => {
  // }, []);
  return (
    <div>
      <Modal
        isOpen={true}
        overlayClassName="modal-overlay"
        // onAfterOpen={async () => {}}
        className="middle"
      >
        <div className="modal">
          <div className="modal-header">
            <div className="modal-header-title">
              <h2 className="roboto">{t("schoolAdmin:change school")}</h2>
            </div>
          </div>
          <div className="modal-body">
            <div className="modal__add-schedule-list">
              {schoolList.map((item) => (
                <>
                  <button
                    onClick={async () => {
                      await dispatch(getSchoolById(item.id));
                      await history.push("/instructor/");
                    }}
                  >
                    <div className="modal__add-schedule-item">
                      <div className="modal__add-schedule-title">
                        <p>{item.name}</p>
                      </div>
                      <div className="modal__add-schedule-description">
                        <p>
                          {t("schoolAdmin:address")}: {item.address}
                        </p>
                      </div>
                    </div>
                  </button>
                </>
              ))}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
