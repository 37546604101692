import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { HeaderLogo, NotificationBadge, ProfileAccount } from "../icons";
import { ChevronDown, ChevronUp, LogOut } from "react-feather";
import { LanguageDropdown } from "..";
import "../../pages/SchoolAdmin/SchoolAdmin.scss";

function InstractorHeader() {
  const [dropdown, setDropdown] = useState(false);
  const [isProfileShown, setIsProfileShown] = useState(false);
  const [isNotificationShown, setIsNotificationShown] = useState(false);
  const location = useLocation();
  const { t } = useTranslation(["schoolAdmin"]);

  useEffect(() => {
    setDropdown(false);
  }, [location]);
  const toggleDropdown = () => {
    setDropdown(!dropdown);
  };
  return (
    <>
      <div className="school-admin__header school-admin__container">
        <div className="school-admin__header-top">
          <div className="school-admin__header-left">
            <div className="school-admin__header-logo">
              <HeaderLogo />
            </div>
            <div className="school-admin__header-school-title">
              {t("login:role instructor")} -{" "}
              {JSON.parse(localStorage.getItem("school"))?.name}
            </div>
          </div>
          <div className="school-admin__header-right">
            <div className="school-admin__header-language">
              <LanguageDropdown />
            </div>
            <div className="school-admin__header-notification">
              <button
                onClick={() => {
                  setIsNotificationShown(!isNotificationShown);
                }}
              >
                <NotificationBadge />
              </button>
              <div className="profile-dropdown-wrapper">
                {isNotificationShown && (
                  <div
                    className="profile-dropdown"
                    style={
                      isNotificationShown
                        ? { display: "block" }
                        : { display: "none" }
                    }
                  >
                    <div
                      className="school-admin__dropdown-route-overlay"
                      onClick={() => {
                        setIsNotificationShown(!isNotificationShown);
                      }}
                    ></div>
                    <div className="school-admin__dropdown-nav">
                      <ul>
                        <li>{t("schoolAdmin:no notifications")}</li>
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="school-admin__header-profile">
              <button
                onClick={() => {
                  setIsProfileShown(!isProfileShown);
                }}
              >
                <ProfileAccount />
              </button>
              <div className="profile-dropdown-wrapper">
                {isProfileShown && (
                  <div
                    className="profile-dropdown"
                    style={
                      isProfileShown
                        ? { display: "block" }
                        : { display: "none" }
                    }
                  >
                    <div
                      className="school-admin__dropdown-route-overlay"
                      onClick={() => {
                        setIsProfileShown(!isProfileShown);
                      }}
                    ></div>
                    <div className="school-admin__dropdown-nav">
                      <ul>
                        {JSON.parse(localStorage.getItem("user"))?.roles
                          ?.length > 1 && (
                          <li>
                            <NavLink
                              exact
                              // activeClassName="active"
                              to="/"
                              onClick={() => {
                                localStorage.removeItem("roleCode");
                              }}
                            >
                              {t("schoolAdmin:change role")}
                            </NavLink>
                          </li>
                        )}
                        {JSON.parse(localStorage.getItem("user"))?.schools
                          ?.length > 1 && (
                          <li>
                            <NavLink
                              exact
                              // activeClassName="active"
                              to="/change-school"
                              onClick={() => {
                                localStorage.removeItem("currentSchool");
                              }}
                            >
                              {t("schoolAdmin:change school")}
                            </NavLink>
                          </li>
                        )}

                        <li>
                          <NavLink
                            exact
                            // activeClassName="active"
                            to="/instructor"
                          >
                            {t("schoolAdmin:settings")}
                          </NavLink>
                        </li>
                        <li>
                          <div className="logout">
                            <button
                              onClick={() => {
                                localStorage.clear();
                                window.location.href = "/";
                              }}
                            >
                              <span>{t("schoolAdmin:logout school")}</span>
                              <LogOut size={12} color="#212121" />
                            </button>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="school-admin__header-bottom">
          <div className="school-admin__header-nav">
            <ul>
              <li>
                <NavLink exact activeClassName="active" to="/instructor">
                  {t("instructor:main")}
                </NavLink>
              </li>
              <li
                onClick={toggleDropdown}
                className={dropdown && "school-admin__header-nav-active"}
              >
                {t("instructor:journal")}
                {dropdown ? (
                  <ChevronUp color="#F0783A" />
                ) : (
                  <ChevronDown color="#D1D1D1" />
                )}
              </li>
              <li>
                <NavLink
                  exact
                  activeClassName="active"
                  to="/instructor/my-class"
                >
                  {t("instructor:my class")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  exact
                  activeClassName="active"
                  to="/instructor/reports"
                >
                  {t("schoolAdmin:reports")}
                </NavLink>
              </li>
              <li>
                <NavLink exact activeClassName="active" to="/instructor">
                  {t("instructor:online lesson")}
                </NavLink>
              </li>
              <li>
                <NavLink exact activeClassName="active" to="/instructor">
                  {t("instructor:online conference")}
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        className="school-admin__dropdown-route"
        style={dropdown ? { display: "block" } : { display: "none" }}
      >
        <div
          className="school-admin__dropdown-route-overlay"
          onClick={toggleDropdown}
        ></div>
        <div className="school-admin__dropdown-nav">
          <ul>
            <li>
              <NavLink
                exact
                activeClassName="active"
                to="/instructor/class-journal"
              >
                {t("instructor:class journal")}
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                activeClassName="active"
                to="/instructor/quarter-grade"
              >
                {t("instructor:final grades")}
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                activeClassName="active"
                to="/instructor/assignments"
              >
                {t("instructor:home work")}
              </NavLink>
            </li>

            <li>
              <NavLink exact activeClassName="active" to="/instructor/ctp">
                {t("instructor:calendar-thematic plan")}
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default InstractorHeader;
