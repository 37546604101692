import {
  GET_INSTRUCTOR_ANALYTIC_REQUEST,
  GET_INSTRUCTOR_ANALYTIC_SUCCESS,
  GET_INSTRUCTOR_ANALYTIC_ERROR,
  GET_STUDENTS_ANALYTIC_REQUEST,
  GET_STUDENTS_ANALYTIC_SUCCESS,
  GET_STUDENTS_ANALYTIC_ERROR,
  GET_PARENTS_ANALYTIC_REQUEST,
  GET_PARENTS_ANALYTIC_SUCCESS,
  GET_PARENTS_ANALYTIC_ERROR,
  GET_ASSESSMENTS_ANALYTIC_REQUEST,
  GET_ASSESSMENTS_ANALYTIC_SUCCESS,
  GET_ASSESSMENTS_ANALYTIC_ERROR,
  GET_ASSESSMENTS_TOPIC_REQUEST,
  GET_ASSESSMENTS_TOPIC_SUCCESS,
  GET_ASSESSMENTS_TOPIC_ERROR,
  GET_ASSESSMENTS_HW_REQUEST,
  GET_ASSESSMENTS_HW_SUCCESS,
  GET_ASSESSMENTS_HW_ERROR,
  GET_INSTRUCTOR_STUTISTICS_REQUEST,
  GET_INSTRUCTOR_STUTISTICS_SUCCESS,
  GET_STUDENTS_COUNT_SUCCESS,
  GET_INSTRUCTOR_STUTISTICS_ERROR,
  GET_STUDENT_GRADE_ANALYTICS_REQUEST,
  GET_STUDENT_GRADE_ANALYTICS_SUCCESS,
  GET_STUDENT_GRADE_ANALYTICS_ERROR,
  GET_STUDENT_ATTENDANCE_ANALYTICS_REQUEST,
  GET_STUDENT_ATTENDANCE_ANALYTICS_SUCCESS,
  GET_STUDENT_ATTENDANCE_ANALYTICS_ERROR,
} from "../actionTypes";
import { statuses } from "../../config";

const initialState = {
  instructorGenderAnalytic: [],
  instructorStutisticsClasses: [],
  studentsGenderAnalytic: [],
  studentsCountAnalytics: [],
  parentsGenderAnalytic: [],
  studentGradeAnalytics: [],
  assessments: [],
  topic: [],
  hw: [],
  studentAttendanceAnalytic: {},
  error: "",
  status: statuses.INITIAL,
};

export const analyticReducer = (state = initialState, action) => {
  switch (action.type) {
    //! INSTRUCTOR
    case GET_INSTRUCTOR_ANALYTIC_REQUEST:
      return {
        ...state,
        instructorGenderAnalytic: [],
        error: "",
        status: statuses.LOADING,
      };
    case GET_INSTRUCTOR_ANALYTIC_SUCCESS:
      return {
        ...state,
        instructorGenderAnalytic: action.payload[0],
        error: "",
        status: statuses.SUCCESS,
      };
    case GET_INSTRUCTOR_ANALYTIC_ERROR:
      return {
        ...state,
        error: action.payload,
        status: statuses.ERROR,
        instructorGenderAnalytic: [],
      };
    //! STUDENTS
    case GET_STUDENTS_ANALYTIC_REQUEST:
      return {
        ...state,
        studentsGenderAnalytic: [],
        error: "",
        status: statuses.LOADING,
      };
    case GET_STUDENTS_ANALYTIC_SUCCESS:
      return {
        ...state,
        studentsGenderAnalytic: action.payload[0],
        error: "",
        status: statuses.SUCCESS,
      };
    case GET_STUDENTS_ANALYTIC_ERROR:
      return {
        ...state,
        error: action.payload,
        status: statuses.ERROR,
        studentsGenderAnalytic: [],
      };
    //! STUDENTS
    case GET_PARENTS_ANALYTIC_REQUEST:
      return {
        ...state,
        parentsGenderAnalytic: [],
        error: "",
        status: statuses.LOADING,
      };
    case GET_PARENTS_ANALYTIC_SUCCESS:
      return {
        ...state,
        parentsGenderAnalytic: action.payload[0],
        error: "",
        status: statuses.SUCCESS,
      };
    case GET_PARENTS_ANALYTIC_ERROR:
      return {
        ...state,
        error: action.payload,
        status: statuses.ERROR,
        parentsGenderAnalytic: [],
      };
    //! ANALYTIC
    case GET_ASSESSMENTS_ANALYTIC_REQUEST:
      return {
        ...state,
        assessments: [],
        error: "",
        status: statuses.LOADING,
      };
    case GET_ASSESSMENTS_ANALYTIC_SUCCESS:
      return {
        ...state,
        assessments: action.payload,
        error: "",
        status: statuses.SUCCESS,
      };
    case GET_ASSESSMENTS_ANALYTIC_ERROR:
      return {
        ...state,
        error: action.payload,
        status: statuses.ERROR,
        assessments: [],
      };
    //! ANALYTIC BY TOPIC
    case GET_ASSESSMENTS_TOPIC_REQUEST:
      return {
        ...state,
        topic: [],
        error: "",
        status: statuses.LOADING,
      };
    case GET_ASSESSMENTS_TOPIC_SUCCESS:
      return {
        ...state,
        topic: action.payload,
        error: "",
        status: statuses.SUCCESS,
      };
    case GET_ASSESSMENTS_TOPIC_ERROR:
      return {
        ...state,
        error: action.payload,
        status: statuses.ERROR,
        topic: [],
      };
    //! ANALYTIC BY HW
    case GET_ASSESSMENTS_HW_REQUEST:
      return {
        ...state,
        hw: [],
        error: "",
        status: statuses.LOADING,
      };
    case GET_ASSESSMENTS_HW_SUCCESS:
      return {
        ...state,
        hw: action.payload,
        error: "",
        status: statuses.SUCCESS,
      };
    case GET_ASSESSMENTS_HW_ERROR:
      return {
        ...state,
        error: action.payload,
        status: statuses.ERROR,
        hw: [],
      };

    //! ANALYTIC BY HW
    case GET_INSTRUCTOR_STUTISTICS_REQUEST:
      return {
        ...state,
        instructorStutisticsClasses: [],
        error: "",
        status: statuses.LOADING,
      };
    case GET_INSTRUCTOR_STUTISTICS_SUCCESS:
      return {
        ...state,
        studentsCountAnalytics: action.payload,
        error: "",
        status: statuses.SUCCESS,
      };
    case GET_STUDENTS_COUNT_SUCCESS:
      return {
        ...state,
        instructorStutisticsClasses: action.payload,
        error: "",
        status: statuses.SUCCESS,
      };
    case GET_INSTRUCTOR_STUTISTICS_ERROR:
      return {
        ...state,
        error: action.payload,
        status: statuses.ERROR,
        instructorStutisticsClasses: [],
      };

    case GET_STUDENT_GRADE_ANALYTICS_REQUEST:
      return {
        ...state,
        studentGradeAnalytics: [],
        error: "",
        status: statuses.LOADING,
      };
    case GET_STUDENT_GRADE_ANALYTICS_SUCCESS:
      return {
        ...state,
        studentGradeAnalytics: action.payload,
        error: "",
        status: statuses.SUCCESS,
      };
    case GET_STUDENT_GRADE_ANALYTICS_ERROR:
      return {
        ...state,
        error: action.payload,
        status: statuses.ERROR,
        studentGradeAnalytics: [],
      };
    case GET_STUDENT_ATTENDANCE_ANALYTICS_REQUEST:
      return {
        ...state,
        studentAttendanceAnalytic: {},
        error: "",
        status: statuses.LOADING,
      };
    case GET_STUDENT_ATTENDANCE_ANALYTICS_SUCCESS:
      return {
        ...state,
        studentAttendanceAnalytic: action.payload,
        error: "",
        status: statuses.SUCCESS,
      };
    case GET_STUDENT_ATTENDANCE_ANALYTICS_ERROR:
      return {
        ...state,
        error: action.payload,
        status: statuses.ERROR,
        studentAttendanceAnalytic: {},
      };

    default:
      return state;
  }
};
