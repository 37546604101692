/* eslint-disable prettier/prettier */
import { urls, toastOption } from "../../config";
import { API } from "../../utils";
import { toast } from "react-toastify";
import i18n from "../../i18n";
import { getSchoolById } from "../school/actions";

import {
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_ERROR,
  AUTH_LOGOUT_SUCCESS,
} from "../actionTypes";

export const loginByLogin = (values) => async (dispatch) => {
  let data = {
    username: values.username,
    password: values.password,
  };
  dispatch(authLoginRequest());
  await API.post(urls.LOGIN, data)
    .then(async (res) => {
      res.data.schools?.[0]?.id &&
        dispatch(getSchoolById(res.data.schools?.[0]?.id));
      const rolesCodes = res.data.roles.map((item) => item.id);
      if (
        rolesCodes.includes(2) ||
        rolesCodes.includes(4) ||
        rolesCodes.includes(5) ||
        rolesCodes.includes(6)
      ) {
        localStorage.setItem("user", JSON.stringify(res.data));

        toast.success(i18n.t("toastify:welcome"), toastOption);

        await API.get(`${urls.CHRONICLE_YEAR}active`)
          .then(async (res2) => {
            console.log("CHRONICLE_YEAR", res2);
            localStorage.setItem("chronicleActive", JSON.stringify(res2.data));
            // history.push("/roles");
            // history.push("/");
            // history.go(0);

            await API.post(
              `${urls.SCHOOL_PRESET}check?chronicleId=${res2.data.id}&schoolId=${res.data.schools[0].id}`
            )
              .then(async (res3) => {
                console.log("SCHOOL_PRESET", res3);
                localStorage.setItem(
                  "schoolPresetting",
                  JSON.stringify(res3.data)
                );

                // if (
                //   rolesCodes.length === 1 &&
                //   (rolesCodes.includes(2) || rolesCodes.includes(6))
                // ) {
                // if (res3.data?.preset) {
                //   window.location.href = "/school-admin";
                // } else if (res3.data?.stepNumber > 0) {
                //   history.push(`/school-presetting`);
                //   console.log(res3.data?.stepNumber);
                // } else {
                if (!res3.data?.stepNumber || res3.data?.stepNumber < 1) {
                  await API.post(
                    `${urls.SCHOOL_PRESET}create`,
                    JSON.stringify({
                      chronicleId: res2.data.id,
                      isPreset: false,
                      schoolId: res.data.schools[0].id,
                      stepNumber: 1,
                    })
                  )
                    .then((res4) => {
                      console.log("SCHOOL_PRESET", res4);
                      API.post(
                        `${urls.SCHOOL_PRESET}check?chronicleId=${res2.data.id}&schoolId=${res.data.schools[0].id}`
                      )
                        .then(async (res4) => {
                          console.log("SCHOOL_PRESET", res3);
                          localStorage.setItem(
                            "schoolPresetting",
                            JSON.stringify(res4.data)
                          );
                          // history.push("/school-presetting");
                          window.location.href = "/";
                        })
                        .catch((e) => {
                          console.log("SCHOOL_PRESET", e.message);
                          return e;
                        });
                      return res;
                    })
                    .catch((e) => {
                      console.log("SCHOOL_PRESET", e.message);
                      return e;
                    });
                } else {
                  window.location.href = "/";
                }
                return res;
              })
              .catch((e) => {
                console.log("SCHOOL_PRESET", e.message);
                return e;
              });
            return res;
          })
          .catch((e) => {
            console.log("CHRONICLE_YEAR", e.message);
            return e;
          });
      } else if (rolesCodes.includes(1)) {
        localStorage.setItem("user", JSON.stringify(res.data));
        toast.success(i18n.t("toastify:welcome"), toastOption);
        window.location.href = "/super-admin";
        console.log("aaaa");
      } else {
        toast.error(i18n.t("toastify:invalid"), toastOption);
      }
      return res;
    })
    .catch((e) => {
      console.log("LOGIN", e.message);
      let errorMessage = "Неверные данные";
      dispatch(authLoginFailure(errorMessage));
      toast.error(i18n.t("toastify:invalid"), toastOption);
      return e;
    });
};
export const loginByPhone =
  (values, setIsDisabled, setIsLoading) => async (dispatch) => {
    const params = {
      mobile: values.phoneNumber,
      smsCode: values.smsCode,
    };
    setIsLoading(true);
    const data = JSON.stringify({});
    JSON.stringify({});
    dispatch(authLoginRequest());
    await API.post(`${urls.LOGIN}mobile`, data, {
      params,
    })
      .then((res) => {
        console.log("LOGIN", res);
        localStorage.setItem("user", JSON.stringify(res.data));
        dispatch(authLoginSuccess(res.data));
        // history.push("/");
        setIsDisabled(false);
        setIsLoading(false);

        toast.success(i18n.t("toastify:welcome"), toastOption);
        return res;
      })
      .catch((e) => {
        console.log("LOGIN", e.message);
        let errorMessage = "Неверные данные";
        dispatch(authLoginFailure(errorMessage));
        toast.error(i18n.t("toastify:invalidCode"), toastOption);
        setIsLoading(false);
        return e;
      });
  };

export const logout = () => async (dispatch) => {
  localStorage.clear();
  await dispatch(authLogoutSuccess([]));
  window.location.reload();
};

export const createCode = (number, history) => async () => {
  const params = {
    mobile: number,
  };

  await API.get(`${urls.SMS}code`, { params })

    .then((res) => {
      toast.success(i18n.t("toastify:smsSent"), toastOption);
      history && history.push(`/sms-verify/${number}`);
      return res;
    })
    .catch(async (e) => {
      console.log("LOGIN", e.message);
      await API.post(
        `${urls.USER_APPLICATION_FORM}check`,
        {},
        { params: { phoneNumber: number } }
      )

        .then((res) => {
          if (res.data.success === false && res.data.message === "NOT_FOUND") {
            toast.error(i18n.t("toastify:phoneNumberNotFound"), toastOption);
            history && history.push(`/unregistered-user/${number}`);
          } else if (res.data.success === true && res.data.message === "0") {
            toast.success(i18n.t("toastify:application0"), toastOption);
          } else if (res.data.success === true && res.data.message === "1") {
            toast.success(i18n.t("toastify:application1"), toastOption);
          } else if (res.data.success === true && res.data.message === "2") {
            toast.success(i18n.t("toastify:application2"), toastOption);
          }
          return res;
        })
        .catch((e) => {
          console.log("LOGIN", e.message);
          history && history.push(`/unregistered-user/${number}`);
          return e;
        });
      return e;
    });
};

export const authLoginRequest = () => {
  return {
    type: AUTH_LOGIN_REQUEST,
  };
};

export const authLoginSuccess = (data) => {
  return {
    type: AUTH_LOGIN_SUCCESS,
    payload: data,
  };
};

export const authLoginFailure = (error) => {
  return {
    type: AUTH_LOGIN_ERROR,
    payload: error,
  };
};

export const authLogoutSuccess = (data) => {
  return {
    type: AUTH_LOGOUT_SUCCESS,
    payload: data,
  };
};
