import {
  GET_STUDENTS_REQUEST,
  GET_STUDENTS_SUCCESS,
  GET_STUDENTS_ERROR,
  GET_STUDENT_REQUEST,
  GET_STUDENT_SUCCESS,
  GET_STUDENT_ERROR,
} from "../actionTypes";
import { statuses } from "../../config";

const initialState = {
  students: [],
  student: [],
  error: "",
  status: statuses.INITIAL,
};

export const studentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_STUDENTS_REQUEST:
      return {
        ...state,
        students: [],
        error: "",
        status: statuses.LOADING,
      };
    case GET_STUDENTS_SUCCESS:
      return {
        ...state,
        students: action.payload,
        error: "",
        status: statuses.SUCCESS,
      };
    case GET_STUDENTS_ERROR:
      return {
        ...state,
        students: [],
        error: action.payload,
        status: statuses.ERROR,
      };
    case GET_STUDENT_REQUEST:
      return {
        ...state,
        student: [],
        error: "",
        status: statuses.LOADING,
      };
    case GET_STUDENT_SUCCESS:
      return {
        ...state,
        student: action.payload,
        error: "",
        status: statuses.SUCCESS,
      };
    case GET_STUDENT_ERROR:
      return {
        ...state,
        student: [],
        error: action.payload,
        status: statuses.ERROR,
      };

    default:
      return state;
  }
};
