import React, { PureComponent } from "react";

export default class PaginationRightIcon extends PureComponent {
  render() {
    return (
      <svg
        width="6"
        height="8"
        viewBox="0 0 6 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.84"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.70711 7.53681L5.24264 4.00128L1.70711 0.465745C1.31658 0.0752205 0.683418 0.0752205 0.292893 0.465745C-0.0976311 0.856269 -0.0976311 1.48943 0.292893 1.87996L2.41421 4.00128L0.292893 6.1226C-0.0976311 6.51312 -0.0976311 7.14629 0.292893 7.53681C0.683418 7.92734 1.31658 7.92734 1.70711 7.53681Z"
          fill="#272727"
        />
      </svg>
    );
  }
}
