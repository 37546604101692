/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import { useTable } from "react-table";
import PT from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { statuses } from "../../config";

import { getClassBySchoolId } from "../../store/class/actions";
import { getShifts } from "../../store/shift/actions";
import { getShiftTimes } from "../../store/shiftTime/actions";
import { getSchoolById } from "../../store/school/actions";
import { getStudentsByCourseIdClassIdInstructorId } from "../../store/student/actions";
import { getQuarters } from "../../store/quarter/actions";

import {
  getQuarterGrades,
  // addQuarterGrade,
  // editQuarterGrade,
  // deleteQuarterGrade,
} from "../../store/quarterGrade/actions";
// import { getSchedulesByClassIdCourseIdInstructorId } from "../../store/schedule/actions";
import { getCourses } from "../../store/course/actions";

StatisticModalQuaterGradeTable.propTypes = {
  classId: PT.number.required,
  courseId: PT.number.required,
  instructorId: PT.number.required,
};

export default function StatisticModalQuaterGradeTable(props) {
  const { t } = useTranslation();
  const { classId, courseId, instructorId } = props;
  const dispatch = useDispatch();
  const classStore = useSelector((store) => store.class.class);
  const quartersStore = useSelector((store) =>
    store.quarters.quarters.sort(
      (a, b) => Number(a.quarter) - Number(b.quarter)
    )
  );

  const quarterGradesStore = useSelector(
    (store) => store.quarterGrades.quarterGrades
  );

  const quarterGradesStatus = useSelector(
    (store) => store.quarterGrades.status
  );
  const studentsStore = useSelector((store) => store.students.students);
  const [quarterId] = useState();

  useEffect(async () => {
    await dispatch(getQuarters());
    await dispatch(getCourses());
    await dispatch(getQuarters());

    await dispatch(
      getStudentsByCourseIdClassIdInstructorId({
        classId: classId,
        courseId: courseId,
        id: instructorId,
      })
    );
    await dispatch(getClassBySchoolId());
    await dispatch(getShifts());
    await dispatch(getShiftTimes());
    await dispatch(
      getSchoolById(JSON.parse(localStorage.getItem("school"))?.id)
    );
    console.log("aa", {
      classId: classId,
      courseId: courseId,
      quarterId: quarterId,
      startDate: quartersStore.find((item) => item.id == quarterId)?.startDate,
      endDate: quartersStore.find((item) => item.id == quarterId)?.endDate,
    });
  }, [classId, courseId]);

  useEffect(async () => {
    await dispatch(
      getQuarterGrades({
        classId: classId,
        personId: instructorId,
        courseId: courseId,
        startDate: quartersStore.find((item) => item.id == quarterId)
          ?.startDate,
        endDate: quartersStore.find((item) => item.id == quarterId)?.endDate,
      })
    );
  }, [classId, courseId]);

  let a =
    quartersStore.length > 0
      ? quartersStore?.map((quarter) => {
          return {
            Header: `${quarter.quarter} ${t("instructor:quarter")}`,
            quarterId: `${quarter.id}`,
            accessor: `${quarter.id}-QUARTER_GRADE`,
            gradeMarkType: "QUARTER_GRADE",
          };
        })
      : [];
  const columns = React.useMemo(
    () =>
      [
        {
          Header: "№",
          accessor: "number",
        },
        {
          Header: t("validation:full name"),
          accessor: "students",
        },
        ...a,
        {
          Header: `${t("instructor:half a year")}`,
          quarterId:
            quartersStore.length % 2 === 0
              ? `${quartersStore[quartersStore.length / 2 - 1]?.id}`
              : `${quartersStore[0]?.id}`,
          accessor:
            quartersStore.length % 2 === 0
              ? `${
                  quartersStore[quartersStore.length / 2 - 1]?.id
                }-HALF_YEAR_GRADE`
              : `${quartersStore[0]?.id}-HALF_YEAR_GRADE`,
          gradeMarkType: "HALF_YEAR_GRADE",
        },
        {
          Header: `${t("instructor:year")}`,
          quarterId: `${quartersStore[quartersStore.length - 1]?.id}`,
          accessor: `${
            quartersStore[quartersStore.length - 1]?.id
          }-FULL_YEAR_GRADE`,
          gradeMarkType: "FULL_YEAR_GRADE",
        },
        classStore.find((item) => item.id == classId)?.classLevel == 9 ||
        classStore.find((item) => item.id == classId)?.classLevel == 11
          ? {
              Header: `${t("instructor:gos")}`,
              quarterId: `${quartersStore[quartersStore.length - 1]?.id}`,
              accessor: `${
                quartersStore[quartersStore.length - 1]?.id
              }-STATE_EXAM_GRADE`,
              gradeMarkType: "STATE_EXAM_GRADE",
            }
          : { accessor: "001" },
        {
          Header: `${t("instructor:final")}`,
          quarterId: `${quartersStore[quartersStore.length - 1]?.id}`,
          accessor: `${
            quartersStore[quartersStore.length - 1]?.id
          }-FINAL_GRADE`,
          gradeMarkType: "FINAL_GRADE",
        },
      ].filter((item) => item.Header),
    [quartersStore, studentsStore]
  );

  const data = React.useMemo(() => {
    let a = [];
    studentsStore
      .sort((a, b) => a.studentTitle - b.studentTitle)
      .map((student, idx) => {
        if (
          quarterGradesStore.filter(
            (grade) => grade.studentCourseId === student.studentCourseId
          ).length > 0
        ) {
          let b = {
            number: idx + 1,
            students: student.studentTitle,
            studentCourseId: student.studentCourseId,
          };
          quarterGradesStore
            .filter(
              (grade) => grade.studentCourseId === student.studentCourseId
            )
            .map((grade) => {
              b[`${grade.quarterId}-${grade.gradeMarkType}`] = grade.mark;
            });
          a.push(b);
        } else {
          a.push({
            number: idx + 1,
            students: student.studentTitle,
            studentCourseId: student.studentCourseId,
          });
        }
      });
    return a;
  }, [quarterGradesStore, studentsStore]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  return (
    <>
      {quarterGradesStatus === statuses.SUCCESS && (
        <>
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup, idx1) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={idx1}>
                  {headerGroup.headers.map((column, idx2) => (
                    <th
                      {...column.getHeaderProps()}
                      key={idx2}
                      style={{
                        writingMode: "vertical-lr",
                        transform: "rotate(180deg)",
                      }}
                      className="modal_quater_grade"
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {rows.map((row, idx3) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} key={idx3}>
                    {row.cells.map((cell, idx4) => {
                      return (
                        <td
                          {...row.getRowProps({
                            style: {
                              width: `${cell.column.width}`,
                            },
                          })}
                          key={idx4}
                          id={`${cell.column.parent?.columns?.[0]?.id}-${cell.row.original.studentCourseId}`}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      )}
    </>
  );
}
