/* eslint-disable indent */
import React, { useEffect, useState, useCallback } from "react";

import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { X, Edit2, Trash, PlusCircle } from "react-feather";
import { useTranslation } from "react-i18next";
import SelectSearch, { fuzzySearch } from "react-select-search";
import Modal from "react-modal";
import Tooltip from "react-simple-tooltip";

import { debounce } from "lodash";
import { AutoScheduleLayout, PaginationBlock } from "../../../components";

import { getClassBySchoolId } from "../../../store/class/actions";
import { getShifts } from "../../../store/shift/actions";
import {
  getScheduleClassLoad,
  createScheduleClassLoad,
  deleteScheduleClassLoad,
  editScheduleClassLoad,
} from "../../../store/schedule/actions";
import { getInstructors } from "../../../store/instructor/actions";
import { getCourses } from "../../../store/course/actions";

export default function AutoSchedule1() {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const classStore = useSelector((store) =>
    store.class.class.sort((a, b) => a.classLevel - b.classLevel)
  );
  const instructorsStore = useSelector(
    (store) => store.instructors.instructors
  );
  const shiftsStore = useSelector((store) => store.shifts.shifts);
  const scheduleClassLoad = useSelector((store) => store.schedules.schedules);
  const courseStore = useSelector((store) => store.courses.courses);

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [currentData, setCurrentData] = useState([]);

  const [editLoad, setEditLoad] = useState();
  const [shiftId, setShiftId] = useState();
  const [classId, setClassId] = useState();
  const [filterClassId, setFilterClassId] = useState();
  const [newClassLoad, setNewClassLoad] = useState({
    classId: "",
    courseId: "",
    hourLoad: "",
    personId: "",
  });

  const [modal, setModal] = useState({
    createLoad: false,
    editLoad: false,
  });
  useEffect(async () => {
    await dispatch(getShifts());
    await dispatch(getClassBySchoolId());
    await dispatch(getScheduleClassLoad());
    await dispatch(getCourses());
  }, []);
  const debouncedPerPage = useCallback(
    debounce((text, scheduleGroupStore) => {
      onPageChanged(1, Number(text), scheduleGroupStore);
      setPerPage(Number(text));
      setCurrentPage(1);
    }, 1000),
    [scheduleClassLoad]
  );
  const onPageChanged = (pageNumber, perPageNumber, data = classStore) => {
    const offset = (pageNumber - 1) * perPageNumber;
    console.log(data);
    const currentDataArr = data.slice(offset, offset + perPageNumber);
    console.log("currentDataArr", currentDataArr);
    return setCurrentData(currentDataArr);
  };
  const getPerPage = (e) => {
    debouncedPerPage(e.target.value);
  };
  const onChange = async (e) => {
    console.log(e);
    setCurrentPage(e);
    onPageChanged(
      e,
      perPage,
      classStore
        .filter((item) => (shiftId ? item.shiftId == shiftId : item))
        .filter((item) => (classId ? item.id == classId : item))
    );
  };

  const removeItem = async (value) => {
    dispatch(deleteScheduleClassLoad(value.id));
  };

  const onSubmit = () => {
    localStorage.setItem("autoScheduleStep", JSON.stringify({ step: 2 }));
  };
  const addClassLoad = () => {
    dispatch(createScheduleClassLoad([newClassLoad]));
    setModal({ ...modal, createLoad: false });
    setNewClassLoad({
      classId: "",
      courseId: "",
      hourLoad: "",
      personId: "",
    });
  };
  const updateClassLoad = () => {
    dispatch(editScheduleClassLoad([editLoad]));
    setModal({ ...modal, editLoad: false });
    setEditLoad({});
  };
  const onEditInputChange = (e) => {
    setEditLoad({
      id: editLoad.id,
      [e.key || e.target.name]: e.value || e.target.value,
    });
  };
  const onInputChange = (e, index) => {
    if ((e.key || e.target.name) === "personId") {
      let a = newClassLoad;
      // let b = newClassLoad?.[index];
      console.log(e.value);
      a.personId = e.value.shift();
      a.courseId = e.value.pop();
      // a.splice(index, 1);
      // a.splice(index, 0, b);
      setNewClassLoad(a);

      // setNewSchedule({
      //   ...newSchedule,
      //   instructorId: e.target.value.split(",").shift(),
      //   courseId: e.target.value.split(",").pop(),
      // });
    } else if ((e.key || e.target.name) === "group") {
      let a = newClassLoad;
      let b = newClassLoad?.[index];
      b.groupTitle = e.value.shift();
      b.scheduleGroupId = e.value.pop();
      a.splice(index, 1);
      a.splice(index, 0, b);
      setNewClassLoad(a);
      // setNewSchedule({
      //   ...newSchedule,
      //   groupTitle: e.target.value.split(",").shift(),
      //   scheduleGroupId: e.target.value.split(",").pop(),
      // });
    } else {
      setNewClassLoad({
        ...newClassLoad,
        [e.key || e.target.name]: e.value || e.target.value,
      });
    }
  };
  const shiftsOptions = shiftsStore.map((item) => ({
    name: `${item.title} ${t("presetting:shift presetting")}`,
    value: item.id,
    key: "shiftId",
  }));

  let opt = [];
  instructorsStore.map((instructor) => {
    return instructor.instructorCourseTitleDtoList.map((course) => {
      opt.push({
        name: `${instructor.instructorTitle} - ${
          courseStore.find((item) => item.id === course.courseId)?.[
            String(i18n.language).toLowerCase() === "en" ||
            String(i18n.language).toLowerCase() === "tr"
              ? `title`
              : `title${String(i18n.language).toUpperCase()}`
          ]
        }`,
        value: [instructor.instructorId, course.courseId],
        key: "personId",
      });
    });
  });
  const classOptions = classStore
    .filter((item) => (shiftId ? item.shiftId == shiftId : item))
    .map((item) => ({
      name: `${item.classLevel}${item.classLabel}`,
      value: item.id,
      key: "classId",
    }));
  const tableDataRender =
    currentData.length > 0
      ? currentData
          // .filter((item) => item.shiftId === shiftId)
          .map((item, idx) => (
            <div className="school-admin__group" key={idx}>
              <div className="school-admin__group-header">
                <div className="school-admin__group-header-left">
                  <p>
                    {item.classLevel}
                    {item.classLabel}
                  </p>
                </div>
                <div className="school-admin__group-header-right">
                  <button
                    onClick={() => {
                      setModal({ ...modal, createLoad: true });
                      setClassId(item.id);
                      // setNewGroup({
                      //   class: `${item.classLevel}${item.classLabel}`,
                      //   classId: item.id,
                      // });
                    }}
                  >
                    <span>{t("schoolAdmin:autoshcedule add load")}</span>
                    <PlusCircle size={18} color="#F0783A" />
                  </button>
                </div>
              </div>
              <div className="school-admin__group-body">
                <div className="school-admin__group-list">
                  {scheduleClassLoad.filter(
                    (classLoad) => classLoad.classId === item.id
                  ).length > 0 && (
                    <>
                      <div className="school-admin__group-item">
                        <div className="school-admin__group-subject">
                          {t("schoolAdmin:subjects")}
                        </div>
                        <div className="school-admin__group-instructor">
                          {t("schoolAdmin:insctructor")}
                        </div>
                        <div className="school-admin__group-hour">
                          {t(
                            "schoolAdmin:autoschedule number of subjects hour per week"
                          )}
                        </div>
                        <div className="school-admin__group-action">
                          {t("presetting:action presetting")}
                        </div>
                      </div>
                      <div className="school-admin__group-time">
                        <div>
                          {t(
                            "schoolAdmin:autoschedule total number of subjects hour per week"
                          )}
                          :{" "}
                          {scheduleClassLoad
                            .filter(
                              (classLoad) => classLoad.classId === item.id
                            )
                            .reduce((a, v) => (a = a + v.hourLoad), 0)}
                        </div>
                      </div>
                    </>
                  )}
                  {scheduleClassLoad
                    .filter((classLoad) => classLoad.classId === item.id)
                    .map((classLoad, idx2) => (
                      <div className="school-admin__group-item" key={idx2}>
                        <div className="school-admin__group-subject">
                          {String(i18n.language).toUpperCase() !== "EN" &&
                          String(i18n.language).toUpperCase() !== "TR"
                            ? `${
                                classLoad[
                                  `courseTitle${String(
                                    i18n.language
                                  ).toUpperCase()}`
                                ]
                              } `
                            : `${classLoad.courseTitle} `}
                        </div>
                        <div className="school-admin__group-instructor">
                          {classLoad.personTitle}
                        </div>
                        <div className="school-admin__group-hour">
                          {classLoad.hourLoad}
                        </div>
                        <div className="school-admin__group-action">
                          <div>
                            <Tooltip
                              className="tooltip"
                              content={t("common:edit")}
                            >
                              <button
                                title="edit"
                                onClick={async () => {
                                  await setEditLoad(classLoad);
                                  await setClassId(item.id);
                                  await setModal({ ...modal, editLoad: true });
                                }}
                              >
                                <Edit2 size={16} color="#14A38B" />
                              </button>
                            </Tooltip>
                            <Tooltip
                              className="tooltip"
                              content={t("common:delete")}
                            >
                              <button onClick={() => removeItem(classLoad)}>
                                <Trash size={16} color="#FF7171" />
                              </button>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          ))
      : classStore
          // .filter((item) => item.shiftId === shiftId)
          .slice([0], [5])
          .map((item, idx) => (
            <div className="school-admin__group" key={idx}>
              <div className="school-admin__group-header">
                <div className="school-admin__group-header-left">
                  <p>
                    {item.classLevel}
                    {item.classLabel}
                  </p>
                </div>
                <div className="school-admin__group-header-right">
                  <button
                    onClick={() => {
                      setModal({ ...modal, createLoad: true });
                      setClassId(item.id);
                      // setNewGroup({
                      //   class: `${item.classLevel}${item.classLabel}`,
                      //   classId: item.id,
                      // });
                    }}
                  >
                    <span>{t("schoolAdmin:autoshcedule add load")}</span>
                    <PlusCircle size={18} color="#F0783A" />
                  </button>
                </div>
              </div>
              <div className="school-admin__group-body">
                <div className="school-admin__group-list">
                  {scheduleClassLoad.filter(
                    (classLoad) => classLoad.classId === item.id
                  ).length > 0 && (
                    <>
                      <div className="school-admin__group-item">
                        <div className="school-admin__group-subject">
                          {t("schoolAdmin:subjects")}
                        </div>
                        <div className="school-admin__group-instructor">
                          {t("schoolAdmin:insctructor")}
                        </div>
                        <div className="school-admin__group-hour">
                          {t(
                            "schoolAdmin:autoschedule number of subjects hour per week"
                          )}
                        </div>
                        <div className="school-admin__group-action">
                          {t("presetting:action presetting")}
                        </div>
                      </div>
                      <div className="school-admin__group-time">
                        <div>
                          {t(
                            "schoolAdmin:autoschedule total number of subjects hour per week"
                          )}
                          :{" "}
                          {scheduleClassLoad
                            .filter(
                              (classLoad) => classLoad.classId === item.id
                            )
                            .reduce((a, v) => (a = a + v.hourLoad), 0)}
                        </div>
                      </div>
                    </>
                  )}
                  {scheduleClassLoad
                    .filter((classLoad) => classLoad.classId === item.id)
                    .map((classLoad, idx2) => (
                      <div className="school-admin__group-item" key={idx2}>
                        <div className="school-admin__group-subject">
                          {String(i18n.language).toUpperCase() !== "EN" &&
                          String(i18n.language).toUpperCase() !== "TR"
                            ? `${
                                classLoad[
                                  `courseTitle${String(
                                    i18n.language
                                  ).toUpperCase()}`
                                ]
                              } `
                            : `${classLoad.courseTitle} `}
                        </div>
                        <div className="school-admin__group-instructor">
                          {classLoad.personTitle}
                        </div>
                        <div className="school-admin__group-hour">
                          {classLoad.hourLoad}
                        </div>
                        <div className="school-admin__group-action">
                          <div>
                            <Tooltip
                              className="tooltip"
                              content={t("common:edit")}
                            >
                              <button
                                title="edit"
                                onClick={async () => {
                                  await setEditLoad(classLoad);
                                  await setClassId(item.id);
                                  await setModal({ ...modal, editLoad: true });
                                }}
                              >
                                <Edit2 size={16} color="#14A38B" />
                              </button>
                            </Tooltip>
                            <Tooltip
                              className="tooltip"
                              content={t("common:delete")}
                            >
                              <button onClick={() => removeItem(classLoad)}>
                                <Trash size={16} color="#FF7171" />
                              </button>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          ));
  return (
    <AutoScheduleLayout id={1}>
      <div className="school-admin__schedule-group school-presetting__body school-presetting__body-color">
        <div className="school-presetting__body-row">
          <div className="school-presetting__body-title">
            <h2>{t("schoolAdmin:class")}</h2>
          </div>
          <div className="school-presetting__body-description">
            <p className="body-1">
              {t("schoolAdmin:autoshcedule step1 description")}
            </p>
          </div>
        </div>
        <div className="school-presetting__search-filter">
          <div className="school-presetting__search">
            <SelectSearch
              options={shiftsOptions}
              search
              className="select-search filter"
              filterOptions={fuzzySearch}
              name="shiftId"
              value={shiftId}
              onChange={(_, k) => {
                // onFilterHandler(k.value);
                setShiftId(k.value);
                onPageChanged(
                  currentPage,
                  perPage,
                  classStore.filter((item) => item.shiftId == k.value)
                );
              }}
              required
              placeholder={`${t("schoolAdmin:all shifts")}`}
            />

            <SelectSearch
              options={classOptions}
              search
              className="select-search filter"
              filterOptions={fuzzySearch}
              name="classId"
              value={filterClassId}
              onChange={(_, k) => {
                // onFilterHandler(k.value);
                setFilterClassId(k.value);
                onPageChanged(
                  currentPage,
                  perPage,
                  classStore.filter((item) => item.id == k.value)
                );
              }}
              required
              placeholder={`${t("schoolAdmin:all classes")}`}
            />
          </div>
        </div>
        <div className="school-presetting__subjects-list">
          <div className="school-presetting__teachers-body">
            <div className="school-presetting__teachers-table">
              {shiftId || shiftsStore?.[0]?.id ? tableDataRender : null}
            </div>
          </div>
          <div className="pagination">
            <div className="paginaiton__inner">
              <div className="pagination__per-page">
                <p>
                  {t("presetting:showing presetting")}{" "}
                  <input
                    type="phone"
                    defaultValue={perPage}
                    onChange={getPerPage}
                  />{" "}
                  {t("presetting:from presetting")}{" "}
                  {
                    classStore
                      .filter((item) =>
                        shiftId ? item.shiftId == shiftId : item
                      )
                      .filter((item) =>
                        filterClassId ? item.classId == filterClassId : item
                      ).length
                  }{" "}
                  {t("presetting:lines presetting")}
                </p>
              </div>
              <div className="pagination__page">
                <PaginationBlock
                  total={
                    classStore
                      .filter((item) =>
                        shiftId ? item.shiftId == shiftId : item
                      )
                      .filter((item) =>
                        filterClassId ? item.classId == filterClassId : item
                      ).length
                  }
                  current={currentPage}
                  onChange={onChange}
                  pageSize={perPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="school-presetting__body-row h-center">
        <Link to="/school-admin/schedule/auto/2">
          <button onClick={onSubmit} className="primary large">
            {t("common:next")}
          </button>
        </Link>
      </div>
      <Modal
        isOpen={modal.createLoad}
        onRequestClose={() => {
          setModal({ ...modal, createLoad: false });
          setNewClassLoad({
            classId: "",
            courseId: "",
            hourLoad: "",
            personId: "",
          });
        }}
        overlayClassName="modal-overlay"
        onAfterOpen={async () => {
          // await dispatch(getClassById(currentClassId, setEditClass));
          // await dispatch(getShifts());
          // await dispatch(getLanguages());
          // await dispatch(getInstructors());
          setNewClassLoad({
            classId: classId,
            courseId: "",
            hourLoad: "",
            personId: "",
          });
          await dispatch(getInstructors());
        }}
      >
        <div className="modal">
          <div className="modal-close">
            <button
              onClick={() => {
                setModal({ ...modal, createLoad: false });
                setNewClassLoad({
                  classId: "",
                  courseId: "",
                  hourLoad: "",
                  personId: "",
                });
              }}
            >
              <X size={15} color="#9A9A9A" />
            </button>
          </div>
          <div className="modal-header">
            <div className="modal-header-title">
              <h2 className="roboto">
                {classStore.find((item) => item.id === classId)?.classLevel}
                {
                  classStore.find((item) => item.id === classId)?.classLabel
                }{" "}
                {t("schoolAdmin:lower case class")}
              </h2>
            </div>
            <div className="modal-header-description">
              <p className="body-2">
                {t("schoolAdmin:autoshcedule step1 modal choose hour load")}
              </p>
            </div>
          </div>
          <div className="modal-body">
            <div className="modal-inputs-list">
              <div className="modal-input">
                {instructorsStore.length > 0 && courseStore.length > 0 && (
                  <SelectSearch
                    options={opt}
                    search
                    className="select-search drop-up small"
                    filterOptions={fuzzySearch}
                    name="personId"
                    onChange={(_, k) => {
                      // onFilterHandler(k.value);
                      onInputChange(k);
                    }}
                    required
                    placeholder={`${t("schoolAdmin:teacher")} - ${t(
                      "schoolAdmin:lesson upper case"
                    )}`}
                  />
                )}
              </div>
              <div className="modal-input">
                <input
                  type="text"
                  placeholder="Кол-во часов в неделю"
                  name="hourLoad"
                  onChange={onInputChange}
                  required
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="large primary"
              onClick={() => addClassLoad()}
              disabled={!newClassLoad.hourLoad}
            >
              {t("common:save")}
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modal.editLoad}
        onRequestClose={() => {
          setModal({ ...modal, editLoad: false });
        }}
        overlayClassName="modal-overlay"
        onAfterOpen={async () => {}}
      >
        <div className="modal">
          <div className="modal-close">
            <button
              onClick={() => {
                setModal({ ...modal, editLoad: false });
              }}
            >
              <X size={15} color="#9A9A9A" />
            </button>
          </div>
          <div className="modal-header">
            <div className="modal-header-title">
              <h2 className="roboto">
                {classStore.find((item) => item.id === classId)?.classLevel}
                {
                  classStore.find((item) => item.id === classId)?.classLabel
                }{" "}
                {t("schoolAdmin:lower case class")}
              </h2>
            </div>
            <div className="modal-header-description">
              <p className="body-2">
                {t("schoolAdmin:autoshcedule step1 modal choose hour load")}
              </p>
            </div>
          </div>
          <div className="modal-body">
            <div className="modal-inputs-list">
              <div className="modal-input">
                <input
                  type="text"
                  placeholder="Кол-во часов в неделю"
                  name="hourLoad"
                  defaultValue={editLoad?.hourLoad}
                  onChange={onEditInputChange}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="large primary"
              onClick={() => updateClassLoad()}
              disabled={editLoad?.hourLoad === ""}
            >
              {t("common:save")}
            </button>
          </div>
        </div>
      </Modal>
    </AutoScheduleLayout>
  );
}
