/* eslint-disable indent */
import React, { useEffect, useState, useCallback } from "react";
import { debounce } from "lodash";
import { useSelector, useDispatch } from "react-redux";
// import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import { Eye } from "react-feather";
import { Link, useParams } from "react-router-dom";
import SelectSearch, { fuzzySearch } from "react-select-search";

import {
  InstractorLayout,
  PaginationBlock,
  // SearchField,
  // DatePickerCalendar,
  NoData,
} from "../../components";

import {
  getAllStudentsByClass,
  // addStudent,
  // removeStudent,
  // updateStudent,
} from "../../store/student/actions";

// import { addParent } from "../../store/parent/actions";
import {
  getClassByInstructorId,
  // editStudentsClass,
} from "../../store/class/actions";
import { statuses } from "../../config";

export default function Students() {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  const { class_id } = useParams();

  const dispatch = useDispatch();
  // const personsStore = useSelector((store) => store.persons.persons);
  const studentsStore = useSelector((store) => store.students.students);
  const studentsStatus = useSelector((store) => store.students.status);
  const classStore = useSelector((store) =>
    store.class.class.filter(
      (item) => item.schoolId === JSON.parse(localStorage.getItem("school"))?.id
    )
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [filterTypeNumber, setFilterTypeNumber] = useState(1);
  const [filterClassTitle, setFilterClassTitle] = useState("");
  const [filteredAllData, setFilteredAllData] = useState([]);
  // const [currentStudentId, setCurrentStudentId] = useState();
  const [currentData, setCurrentData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  // const [searchText, setSearchText] = useState("");
  // const [searchedCurrentData, setSearchedCurrentData] = useState([]);
  // const [editClassId, setEditClassId] = useState();

  // const [newStudent, setNewStudent] = useState({
  //   lastName: "",
  //   firstName: "",
  //   middleName: "",
  //   phone: "",
  //   dateOfBirth: "2010-01-01",
  //   nationality: "",
  //   gender: 1,
  //   classId: "",
  // });
  // const [newParent, setNewParent] = useState({
  //   lastName: "",
  //   firstName: "",
  //   middleName: "",
  //   phone: "",
  //   dateOfBirth: "1980-01-01",
  //   job: "",
  //   jobPlace: "",
  //   roleId: 5,
  //   parentalType: "",
  // });

  useEffect(async () => {
    await dispatch(getAllStudentsByClass(class_id));
    await dispatch(
      getClassByInstructorId(JSON.parse(localStorage.getItem("person"))?.id)
    );
    if (class_id) {
      await studentsByParam();
    }
  }, []);

  // const classOptions = classStore.map((item) => ({
  //   name: `${item.classLevel}${item.classLabel}`,
  //   value: item.id,
  //   key: "classId",
  // }));
  const classTitleOptions = classStore.map((item) => ({
    name: `${item.classLevel}${item.classLabel}`,
    value: `${item.classLevel}${item.classLabel}`,
    key: "filterClassTitle",
    id: item.id,
  }));
  // const onInputChange = (e) => {
  //   setNewStudent({
  //     ...newStudent,
  //     [e.key || e.target.name]: e.value || e.target.value,
  //   });
  // };
  // const onParentInputChange = (e) => {
  //   setNewParent({
  //     ...newParent,
  //     [e.key || e.target.name]: e.value || e.target.value,
  //   });
  // };

  const onPageChanged = (
    pageNumber,
    perPageNumber,
    data = studentsStore,
    filter = filterClassTitle
  ) => {
    const offset = (pageNumber - 1) * perPageNumber;
    let currentDataArr = [];
    if (filter) {
      let a = data.filter((item) => `${item.classTitle}` === filter);
      currentDataArr = a.slice(offset, offset + perPageNumber);
      setFilteredAllData(a);
    } else {
      currentDataArr = data.slice(offset, offset + perPageNumber);
      setFilteredAllData([]);
    }
    return setCurrentData(currentDataArr);
  };

  const onFilterHandler = async (k) => {
    const classTitle = k.value;
    // console.log(classTitle);

    setCurrentPage(1);
    setFilterClassTitle(classTitle);
    classTitle?.length > 0
      ? onPageChanged(1, perPage, studentsStore, classTitle)
      : onPageChanged(1, perPage, studentsStore);
  };
  const onFilterTypeHandler = async (typeNumber) => {
    // await setSearchText("");
    // await setCurrentData([]);
    // await setFilteredAllData([]);
    // await setSearchedCurrentData([]);
    await setCurrentPage(1);
    await setFilterTypeNumber(typeNumber);
    // await setFilterClassTitle("");
  };
  const studentsByParam = () => {
    const classById = classStore?.find((item) => item.id == class_id);
    // console.log("classById", classById?.classLevel);
    onFilterHandler(`${classById?.classLevel}${classById?.classLabel}`);
  };
  // const createStudent = async () => {
  //   await dispatch(addStudent(newStudent, setCurrentStudentId, setModal));
  //   await dispatch(getAllStudentsBySchool());
  //   await setNewStudent({
  //     lastName: "",
  //     firstName: "",
  //     middleName: "",
  //     phone: "",
  //     dateOfBirth: "2010-01-01",
  //     nationality: "",
  //     classId: "",
  //     gender: "1",
  //   });
  // };

  // const createParent = async () => {
  //   // console.log({
  //   //   parentalType: newParent.parentalType,
  //   //   personDto: {
  //   //     ...newParent,
  //   //     gender: newParent.parentalType == 1 ? 1 : 2,
  //   //   },
  //   //   studentId: currentStudentId,
  //   // });
  //   await dispatch(
  //     addParent({
  //       parentalType: newParent.parentalType,
  //       personDto: {
  //         ...newParent,
  //         gender: newParent.parentalType == 1 ? 1 : 2,
  //       },
  //       studentId: currentStudentId,
  //     })
  //   );
  //   await dispatch(getAllStudentsBySchool());
  //   // setCurrentStudentId("");
  //   await setNewParent({
  //     lastName: "",
  //     firstName: "",
  //     middleName: "",
  //     phone: "",
  //     dateOfBirth: "1980-01-01",
  //     job: "",
  //     jobPlace: "",
  //     roleId: 5,
  //     parentalType: "",
  //   });
  //   await setModal({ ...modal, step2: false });
  // };

  const debouncedPerPage = useCallback(
    debounce((text, studentsStore) => {
      if (filteredAllData.length > 0) {
        onPageChanged(1, Number(text), filteredAllData);
      } else {
        onPageChanged(1, Number(text), studentsStore);
      }
      setPerPage(Number(text));
      setCurrentPage(1);
    }, 1000),
    [studentsStore, filteredAllData]
  );
  // const debouncedSearch = useCallback(
  //   debounce((text) => {
  //     if (
  //       studentsStore.length > 0 &&
  //       perPage &&
  //       String(text).toLowerCase() !== ""
  //     ) {
  //       setSearchText(String(text).toLowerCase());
  //       setCurrentPage(1);
  //       setFilterClassTitle("");

  //       const a = studentsStore.filter((item) => {
  //         let obj;
  //         Object.keys(item).map((key) => {
  //           if (
  //             key == "firstName" ||
  //             key == "lastName" ||
  //             key == "middleName" ||
  //             key == "phone"
  //           ) {
  //             const c = String(item[key]).toLowerCase();
  //             // if (c != "undefined" && c !== "NULL" && c !== null) {
  //             const d = c.includes(String(text).toLowerCase());
  //             if (d) {
  //               obj = item;
  //               return item;
  //             }
  //             // }
  //           }
  //         });
  //         return obj;
  //       });
  //       setSearchedCurrentData([...a]);
  //       onPageChanged(1, perPage, [...a]);
  //     } else {
  //       setSearchText(String(text).toLowerCase());
  //       setCurrentPage(1);
  //       setFilterClassTitle("");
  //       setSearchedCurrentData([]);
  //       onPageChanged(1, perPage);
  //     }
  //   }, 1000),
  //   [perPage, studentsStore]
  // );

  const onChange = async (e) => {
    console.log("sss", e);
    setCurrentPage(e);
    if (filteredAllData.length > 0) {
      onPageChanged(e, perPage, filteredAllData);
    } else {
      onPageChanged(e, perPage, studentsStore);
    }
  };
  const getPerPage = (e) => {
    debouncedPerPage(e.target.value);
  };
  // const searchInputHandler = async (e) => {
  //   await debouncedSearch(e.target.value);
  // };

  const onSelect = (value) => {
    console.log(selectedData);
    if (selectedData.find((item) => item.id === value.id)) {
      let a = selectedData.filter((item) => item.id !== value.id);
      setSelectedData([...a]);
    } else {
      setSelectedData([...selectedData, value]);
    }
  };
  const clearSelected = () => {
    setSelectedData([]);
  };
  // const restoreItem = async (value) => {
  //   await dispatch(updateStudent(value));
  //   await dispatch(getAllStudentsBySchool(true));
  // };

  const tableDataRender =
    currentData.length > 0
      ? currentData
          .filter(
            (item) => item.archived == (filterTypeNumber == 2 ? true : false)
          )
          .map((item, idx) => (
            <tr
              className={
                selectedData.find((i) => i.id === item.id) ? "active" : ""
              }
              key={idx}
            >
              <td className="table-select">
                <label className="check">
                  <input
                    type="checkbox"
                    onChange={() => onSelect(item)}
                    checked={selectedData.find((i) => i.id === item.id)}
                  />
                  <div className="box"></div>
                </label>
                <span>{idx + 1}</span>
              </td>
              <td>
                {item.lastName} {item.firstName} {item.middleName}
              </td>
              <td>
                {classStore.find((item) => item.id == class_id)?.classLevel}
                {classStore.find((item) => item.id == class_id)?.classLabel}
              </td>
              <td>
                <div className="school-admin__profile-link">
                  {filterTypeNumber == 1 ? (
                    <Link to={`/instructor/student-profile/${item.id}`}>
                      <button>
                        <span>{t("schoolAdmin:view profile")}</span>
                        <Eye color="#7D7D7D" />
                      </button>
                    </Link>
                  ) : (
                    <div className="table-restore">
                      {/* <button
                      onClick={() =>
                        restoreItem({ id: item.id, archived: false })
                      }
                    >
                      <span>{t("schoolAdmin:restore")}</span>
                      <RefreshCcw color="#7D7D7D" />
                    </button> */}
                    </div>
                  )}
                </div>
              </td>
            </tr>
          ))
      : filteredAllData.length > 0
      ? filteredAllData
          .filter(
            (item) => item.archived === (filterTypeNumber == 2 ? true : false)
          )
          .map((item, idx) => (
            <tr
              className={
                selectedData.find((i) => i.id === item.id) ? "active" : ""
              }
              key={idx}
            >
              <td className="table-select">
                <label className="check">
                  <input
                    type="checkbox"
                    onChange={() => onSelect(item)}
                    checked={selectedData.find((i) => i.id === item.id)}
                  />
                  <div className="box"></div>
                </label>
                <span>{idx + 1}</span>
              </td>
              <td>
                {item.lastName} {item.firstName} {item.middleName}
              </td>
              <td>
                {classStore.find((item) => item.id == class_id)?.classLevel}
                {classStore.find((item) => item.id == class_id)?.classLabel}
              </td>
              <td>
                <div className="school-admin__profile-link">
                  {filterTypeNumber == 1 ? (
                    <Link to={`/instructor/student-profile/${item.id}`}>
                      <button>
                        <span>{t("schoolAdmin:view profile")}</span>
                        <Eye color="#7D7D7D" />
                      </button>
                    </Link>
                  ) : (
                    <div className="table-restore">
                      {/* <button
                      onClick={() =>
                        restoreItem({ id: item.id, archived: false })
                      }
                    >
                      <span>{t("schoolAdmin:restore")}</span>
                      <RefreshCcw color="#7D7D7D" />
                    </button> */}
                    </div>
                  )}
                </div>
              </td>
            </tr>
          ))
      : studentsStore
          .filter(
            (item) => item.archived === (filterTypeNumber == 2 ? true : false)
          )
          .slice([0], [10])
          .map((item, idx) => (
            <tr
              className={
                selectedData.find((i) => i.id === item.id) ? "active" : ""
              }
              key={idx}
            >
              <td className="table-select">
                <label className="check">
                  <input
                    type="checkbox"
                    onChange={() => onSelect(item)}
                    checked={selectedData.find((i) => i.id === item.id)}
                  />
                  <div className="box"></div>
                </label>
                <span>{idx + 1}</span>
              </td>
              <td>
                {item.lastName} {item.firstName} {item.middleName}
              </td>
              <td>
                {filterClassTitle
                  ? `${
                      classStore.find(
                        (item) =>
                          `${item.classLevel}${item.classLabel}` ==
                          filterClassTitle
                      )?.classLevel
                    }${
                      classStore.find(
                        (item) =>
                          `${item.classLevel}${item.classLabel}` ==
                          filterClassTitle
                      )?.classLabel
                    }`
                  : `${
                      classStore.find((item) => item.id == class_id)?.classLevel
                    }${
                      classStore.find((item) => item.id == class_id)?.classLabel
                    }`}
              </td>
              <td>
                <div className="school-admin__profile-link">
                  {filterTypeNumber == 1 ? (
                    <Link to={`/instructor/student-profile/${item.id}`}>
                      <button>
                        <span>{t("schoolAdmin:view profile")}</span>
                        <Eye color="#7D7D7D" />
                      </button>
                    </Link>
                  ) : (
                    <div className="table-restore">
                      {/* <button
                      onClick={() =>
                        restoreItem({ id: item.id, archived: false })
                      }
                    >
                      <span>{t("schoolAdmin:restore")}</span>
                      <RefreshCcw color="#7D7D7D" />
                    </button> */}
                    </div>
                  )}
                </div>
              </td>
            </tr>
          ));
  return (
    <InstractorLayout>
      <div className="school-presetting__body school-presetting__body-color">
        <div className="school-presetting__body-row">
          <div className="school-presetting__body-title">
            <h2>{t("schoolAdmin:students")}</h2>
          </div>
          <div className="school-presetting__body-description">
            {/* <p className="body-1">{t("schoolAdmin:lorem ipsum students")}</p> */}
          </div>
        </div>

        <div className="school-presetting__subjects-list">
          <div className="school-presetting__search-filter">
            <div className="school-presetting__search">
              {studentsStatus === statuses.SUCCESS && (
                <>
                  {/* <SearchField
                    onChange={searchInputHandler}
                    defaultValue={searchText}
                    placeholder={`${t("common:search")}`}
                  /> */}
                  <SelectSearch
                    options={classTitleOptions}
                    search
                    className="select-search filter"
                    filterOptions={fuzzySearch}
                    value={
                      classStore.find((item) => item.id == class_id)
                        ?.classTitle || filterClassTitle
                    }
                    name="filterClassTitle"
                    onChange={(_, k) => {
                      onFilterHandler(k);
                      setFilterTypeNumber(1);
                      dispatch(getAllStudentsByClass(k.id));
                    }}
                    required
                    placeholder={`${t("schoolAdmin:class")}`}
                  />
                </>
              )}
            </div>
          </div>
          <div className="school-presetting__tabs">
            <div className="school-presetting__tabs-item">
              <button
                onClick={async () => {
                  // await dispatch(getAllStudentsBySchool());
                  await onFilterTypeHandler(1);
                }}
                className={filterTypeNumber === 1 ? "active" : ""}
              >
                {t("schoolAdmin:active")}
              </button>
            </div>
            <div className="school-presetting__tabs-item">
              <button
                onClick={async () => {
                  // await dispatch(getAllStudentsBySchool(true));
                  await onFilterTypeHandler(2);
                }}
                className={filterTypeNumber === 2 ? "active" : ""}
              >
                {t("schoolAdmin:archived")}
              </button>
            </div>
          </div>
          {studentsStore.length > 0 ? (
            <>
              <div className="school-presetting__teachers-body">
                <div className="school-presetting__teachers-table">
                  <table>
                    <thead>
                      <tr>
                        <th>№</th>
                        <th>{t("presetting:full name presetting")}</th>
                        <th>{t("presetting:classes presetting")}</th>
                        <th>{t("presetting:action presetting")}</th>
                      </tr>
                    </thead>
                    <tbody>{tableDataRender}</tbody>
                  </table>

                  {selectedData.length > 0 && (
                    <div className="edit-table">
                      <div className="edit-table-left">
                        <div>
                          <p className="caption-1">
                            {t("presetting:choosen presetting")}:
                            <span>{selectedData.length}</span>
                          </p>
                        </div>
                        <div>
                          <button onClick={clearSelected}>
                            {t("presetting:consel presetting")}
                          </button>
                        </div>
                      </div>
                      <div className="edit-table-right">
                        {/* {filterTypeNumber === 1 && (
                          <>
                            <div>
                              <button
                                onClick={() => {
                                  setModal({ ...modal, editClass: true });
                                }}
                              >
                                Перевести
                              </button>
                            </div>
                            <div>
                              <button
                                onClick={() =>
                                  setModal({ ...modal, confirm2: true })
                                }
                              >
                                Удалить
                              </button>
                            </div>
                          </>
                        )} */}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="pagination">
                <div className="paginaiton__inner">
                  <div className="pagination__per-page">
                    <p>
                      {t("presetting:showing presetting")}{" "}
                      <input
                        type="phone"
                        defaultValue={perPage}
                        onChange={getPerPage}
                      />{" "}
                      {t("presetting:from presetting")}{" "}
                      {filteredAllData.length > 0
                        ? filteredAllData.filter(
                            (item) =>
                              item.archived ==
                              (filterTypeNumber == 2 ? true : false)
                          ).length
                        : studentsStore.filter(
                            (item) =>
                              item.archived ==
                              (filterTypeNumber == 2 ? true : false)
                          ).length}{" "}
                      {t("presetting:lines presetting")}
                    </p>
                  </div>
                  <div className="pagination__page">
                    <PaginationBlock
                      total={
                        filteredAllData.length > 0
                          ? filteredAllData.filter(
                              (item) =>
                                item.archived ==
                                (filterTypeNumber == 2 ? true : false)
                            ).length
                          : studentsStore.filter(
                              (item) =>
                                item.archived ==
                                (filterTypeNumber == 2 ? true : false)
                            ).length
                      }
                      current={currentPage}
                      onChange={onChange}
                      pageSize={perPage}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <NoData messages={[`${t("schoolAdmin:here is nothing")}`]} />
          )}
        </div>
      </div>
    </InstractorLayout>
  );
}
