import React from "react";
import { useTable } from "react-table";
import PT from "prop-types";

function TeacherStatisticDetailes(props) {
  const { data, columns } = props;

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });
  return (
    <>
      {getTableProps && (
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, idx) => (
              <tr key={idx} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, idx) => (
                  <th key={idx} {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {getTableBodyProps && (
            <tbody {...getTableBodyProps()}>
              {rows.map((row, idx) => {
                prepareRow(row);
                return (
                  <tr key={idx} {...row.getRowProps()}>
                    {row.cells.map((cell, idx) => {
                      return (
                        <td key={idx} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      )}
    </>
  );
}
TeacherStatisticDetailes.propTypes = {
  data: PT.any,
  columns: PT.any,
};

export default TeacherStatisticDetailes;
