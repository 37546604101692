/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectSearch, { fuzzySearch } from "react-select-search";
import { useTranslation } from "react-i18next";

import {
  GoBack,
  SchoolAdminLayout,
  InstractorLayout,
  NoData,
} from "../../components";
import { getClassBySchoolId, getClassById } from "../../store/class/actions";
import { getQuarters } from "../../store/quarter/actions";
import {
  getQuarterMark,
  getQuarterGradeAnalyticClassPerformance,
  getQuarterGradeAnalyticListExcellence,
} from "../../store/report/actions";
import { getAllStudentsByClass } from "../../store/student/actions";
import StudentRecordExportPDF from "./StudentRecordExport";
import "./Reports.scss";

function StudentRecord() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const year = JSON.parse(localStorage.getItem("chronicleActive"));
  // const schoolName = JSON.parse(localStorage.getItem("school"))?.name;
  const [quaterId, setQuaterId] = useState();
  const [classTitle, setClassTitle] = useState();
  const [quaterTitle, setQuaterTitle] = useState();

  let answer = {
    five: { index: 0, value: 0 },
    four: { index: 0, value: 0 },
    three: { index: 0, value: 0 },
    two: { index: 0, value: 0 },
  };

  useEffect(() => {
    dispatch(getClassBySchoolId());
    dispatch(getQuarters());
  }, []);

  const classesState = useSelector((state) => state.class.class);
  const classOneStore = useSelector((state) => state.class.classOne);
  const quarter = useSelector((state) => state.quarters.quarters);
  const lesson = useSelector((state) => state.report.courses);
  const person = useSelector((state) => state.report.students);
  const quarterMark = useSelector((state) => state.report.studentsRecord);
  const classPerformance = useSelector(
    (state) => state.report.classPerformance
  );
  const listExcellence = useSelector((state) => state.report.listExcellence);

  const courses = lesson?.map((item, idx) => (
    <th
      className="t_header"
      key={`${item}, ${idx}`}
      id={item.courseId}
      style={{
        writingMode: "vertical-lr",
        transform: "rotate(180deg)",
        msoRotate: "90",
      }}
    >
      <div className="th_text">
        {String(i18n.language).toUpperCase() !== "EN" &&
        String(i18n.language).toUpperCase() !== "TR"
          ? `${item[`courseTitle${String(i18n.language).toUpperCase()}`]} `
          : `${item.courseTitle} `}
      </div>
    </th>
  ));

  const classesTypesOptions = classesState.map((item, idx) => ({
    name: `${item.classLevel} ${item.classLabel} `,
    value: item.id,
    key: item.id + idx,
  }));

  function sumDateRight(e) {
    (e == 5 || e == 4) && (answer.three.index = answer.three.index + 1);
    (e == 3 || e == 5 || e == 4) && (answer.two.index = answer.two.index + 1);
    return e;
  }

  const studentTable = person?.map(
    (item, idx) => (
      (answer.three.index = 0),
      (answer.two.index = 0),
      (
        <tr key={`${item?.studentId + idx})${idx}`} className="student">
          <td className="student_id">{idx + 1}</td>
          <td className="student_name" id={item.studentId}>
            <div className="student_name">{item.studentTitle}</div>
          </td>
          {lesson?.map((stidentCourse, idx) => (
            <th
              key={`${stidentCourse.courseId}-${idx}`}
              id={stidentCourse.courseId}
            >
              {quarterMark?.map((mark) =>
                stidentCourse.courseId == mark.courseId
                  ? mark.studentTitle == item.studentTitle
                    ? (mark.quarterMark == 3 ||
                        mark.quarterMark == 4 ||
                        mark.quarterMark == 4) &&
                      mark.quarterMark !== null
                      ? sumDateRight(mark.quarterMark)
                      : sumDateRight(mark.quarterMark)
                    : ""
                  : ""
              )}
            </th>
          ))}
          <th className="th_text_fix_right_1">
            {isNaN(
              (answer.three.index * 100) /
                quarterMark
                  .filter((mark) => mark.studentTitle == item.studentTitle)
                  .filter((mark) => mark.quarterMark !== null).length
            )
              ? "0%"
              : (
                  (answer.three.index * 100) /
                  quarterMark
                    .filter((mark) => mark.studentTitle == item.studentTitle)
                    .filter((mark) => mark.quarterMark !== null).length
                ).toFixed(2) + "%"}
          </th>
          <th className="th_text_fix_right_2">
            {isNaN(
              (answer.two.index * 100) /
                quarterMark
                  .filter((mark) => mark.studentTitle == item.studentTitle)
                  .filter((mark) => mark.quarterMark !== null).length
            )
              ? "0%"
              : (
                  (answer.two.index * 100) /
                  quarterMark
                    .filter((mark) => mark.studentTitle == item.studentTitle)
                    .filter((mark) => mark.quarterMark !== null).length
                ).toFixed(2) + "%"}
          </th>
        </tr>
      )
    )
  );
  // function printTable(wrapperId) {
  //   let originalContents = document;
  //   let test = document;

  //   const w = window.open();

  //   // test.querySelector(wrapperId).border = 1;

  //   test.querySelector(`${wrapperId} table`).style.borderCollapse = "collapse";
  //   const printContents = test.querySelector(wrapperId).innerHTML;

  //   w.document.body.innerHTML = printContents;
  //   w.document.body.style.zoom = "0.4";
  //   w.document.head.innerHTML = document.head.innerHTML;

  //   w.print();
  //   test.querySelector(`${wrapperId} table`).style.borderCollapse = "unset";

  //   document.innerHTML = originalContents;
  //   w.close();
  // }

  // function exportTableToExcel(tableID, filename = "") {
  //   let downloadLink;
  //   let dataType = "application/vnd.ms-excel";
  //   let tableSelect = document.querySelector(tableID);
  //   tableSelect.border = 1;
  //   let tableHTML = tableSelect.outerHTML.replace(/ /g, "%20");

  //   // Specify file name
  //   filename = filename ? filename + ".xls" : "excel_data.xls";

  //   // Create download link element
  //   downloadLink = document.createElement("a");

  //   document.body.appendChild(downloadLink);

  //   if (navigator.msSaveOrOpenBlob) {
  //     let blob = new Blob(["\ufeff", tableHTML], {
  //       type: dataType,
  //     });
  //     navigator.msSaveOrOpenBlob(blob, filename);
  //   } else {
  //     // Create a link to the file
  //     downloadLink.href = "data:" + dataType + ", " + tableHTML;

  //     // Setting the file name
  //     downloadLink.download = filename;

  //     //triggering the function
  //     downloadLink.click();
  //   }
  // }

  const LessonKnowledgeQuality = (permition) =>
    lesson?.map(
      (item, idx) => (
        ((answer.five.value = 0),
        (answer.four.value = 0),
        (answer.two.value = 0),
        (answer.three.value = 0)),
        (
          <td key={idx} className="student_name" id={item.courseId}>
            <div className="student_name">
              {
                quarterMark
                  ?.filter((item) => item.quarterMark == permition)
                  ?.filter((z) => z.courseId == item.courseId).length
              }
            </div>
          </td>
        )
      )
    );

  function sumDate() {
    answer.five.index = answer.five.index + 1;
  }

  const LessonSchoolPerformance = (e) =>
    lesson?.map(
      (item, idx) => (
        (answer.five.index = 0),
        (
          <td key={`${idx + idx}/${idx}`}>
            {
              (e == 3 &&
                quarterMark?.map(
                  (date) =>
                    date.courseId == item.courseId &&
                    date.quarterMark > 2 &&
                    sumDate()
                ),
              e !== 3 &&
                quarterMark?.map(
                  (date) =>
                    date.courseId == item.courseId &&
                    date.quarterMark > 3 &&
                    sumDate()
                ))
            }
            {isNaN(
              (answer.five.index * 100) /
                quarterMark
                  .filter((mark) => mark.courseId == item.courseId)
                  .filter((mark) => mark.quarterMark !== null).length
            )
              ? "0%"
              : (
                  (answer.five.index * 100) /
                  quarterMark
                    .filter((mark) => mark.courseId == item.courseId)
                    .filter((mark) => mark.quarterMark !== null).length
                ).toFixed(2) + "%"}
          </td>
        )
      )
    );

  const onInputChange = (e) => {
    dispatch(getQuarterMark(e.value, quaterId, year?.id));
    dispatch(
      getQuarterGradeAnalyticClassPerformance(e.value, quaterId, year?.id)
    );
    dispatch(getQuarterGradeAnalyticListExcellence(e.value, quaterId));
    dispatch(getQuarterGradeAnalyticListExcellence(e.value, quaterId));
    dispatch(getClassById(e.value));
    console.log("classOneStore", classOneStore);
  };

  const onQuaterChange = (e) => {
    setQuaterTitle(e);

    setQuaterId(e.value);
    dispatch(getAllStudentsByClass(e.value));
  };

  console.log(year.startYear, year.endYear);

  const quaterTypesOptions = quarter?.map((item) => ({
    name: `${item?.quarter} ${t("instructor:quarter")}`,
    value: item.id,
    key: item.id,
  }));

  const [quaterState, setQuaterState] = useState();
  const [classState, setClassState] = useState();
  const [className, setClassName] = useState();
  const [quaterIdSelectSearch, setQuaterIdSelectSearch] = useState();

  return (
    <div>
      {JSON.parse(localStorage.getItem("roleCode")) == 4 && (
        <InstractorLayout>
          <div className="student_record">
            <div className="button_to_back">
              <GoBack title={t("common:back")} />
            </div>
            <div className="body">
              <span className="topic">{t("reports:student record")}</span>
              <p className="title">
                {t("reports:student record loran ipsune")}
              </p>
              <div className="schedule">
                <div className="schedule_filter">
                  <div className="year">
                    {year?.selectorTitle} {t("reports:y")}
                  </div>
                  <SelectSearch
                    options={quaterTypesOptions}
                    search
                    filterOptions={fuzzySearch}
                    value={quaterState}
                    name="quarterId"
                    onChange={(_, k) => {
                      onQuaterChange(k);
                      setQuaterState(k.value);
                    }}
                    required
                    placeholder={`- ${t("instructor:quarter")} -`}
                  />
                  <SelectSearch
                    options={classesTypesOptions}
                    search
                    filterOptions={fuzzySearch}
                    value={classState}
                    name="classId"
                    onChange={(_, k) => {
                      onInputChange(k);
                      // setClassTitle(k);
                      setClassState(k.value);
                    }}
                    required
                    placeholder={`- ${t("schoolAdmin:class")} -`}
                  />
                </div>

                {/* <div
                  onClick={() => {
                    exportTableToExcel(
                      "#student-record_table",
                      `${schoolName}_${year.startYear}-${year.endYear}_${
                        quaterTitle?.name
                      }-${t("instructor:quarter")}_${
                        classTitle?.name
                      } "Ведомость успеваемости учащихся"`
                    );
                  }}
                  className="schedule_export"
                >
                  {t("schoolAdmin:export")}
                </div> */}
                <div
                  onClick={async () => {
                    await StudentRecordExportPDF(
                      classPerformance,
                      listExcellence,
                      classTitle?.name,
                      quaterTitle?.name,
                      classOneStore?.personTitle
                        ? classOneStore?.personTitle
                        : "",
                      t,
                      i18n
                    );
                  }}
                  className="schedule_export"
                >
                  {t("schoolAdmin:export")}
                </div>
              </div>
              <div className="table_box" id="student-record_table-wrapper">
                <table
                  className="schedule_box_table roboto"
                  id="student-record_table"
                >
                  <thead className="table_box_title">
                    <td>
                      <div className="th_text">№</div>
                    </td>
                    <th className="t_header">
                      <div className="th_text">
                        {t("presetting:full name presetting")}
                      </div>
                    </th>
                    {classTitle && courses}
                    <th
                      className="th_text_fix_right_1"
                      style={{
                        writingMode: "vertical-lr",
                        transform: "rotate(180deg)",
                      }}
                    >
                      <div className="th_text">
                        {t("reports:knowledge quality")}
                      </div>
                    </th>
                    <th
                      className="th_text_fix_right_2"
                      style={{
                        writingMode: "vertical-lr",
                        transform: "rotate(180deg)",
                      }}
                    >
                      <div className="th_text">{t("reports:achievement")}</div>
                    </th>
                  </thead>
                  <tbody>{classTitle && studentTable}</tbody>
                  <tbody className="footer">
                    <tr className="student">
                      <td></td>
                      <td>{t("reports:length")} 5:</td>
                      {classTitle && LessonKnowledgeQuality(5)}
                    </tr>
                    <tr className="student">
                      <td></td>
                      <td>{t("reports:length")} 4:</td>
                      {classTitle && LessonKnowledgeQuality(4)}
                    </tr>
                    <tr className="student">
                      <td></td>
                      <td>{t("reports:length")} 3:</td>
                      {classTitle && LessonKnowledgeQuality(3)}
                    </tr>
                    <tr className="student">
                      <td></td>
                      <td>{t("reports:length")} 2:</td>
                      {classTitle && LessonKnowledgeQuality(2)}
                    </tr>
                    <tr className="student">
                      <td></td>
                      <td>{t("reports:knowledge quality")}</td>
                      {classTitle && LessonSchoolPerformance(1)}
                    </tr>
                    <tr className="student">
                      <td></td>
                      <td>{t("reports:achievement")}</td>
                      {classTitle && LessonSchoolPerformance(3)}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </InstractorLayout>
      )}
      {JSON.parse(localStorage.getItem("roleCode")) == 2 && (
        <SchoolAdminLayout>
          <div className="student_record">
            <div className="button_to_back">
              <GoBack title={t("common:back")} />
            </div>

            <div className="body">
              <span className="topic">{t("reports:student record")}</span>
              <p className="title">
                {t("reports:student record loran ipsune")}
              </p>
              <div className="schedule">
                <div className="schedule_filter">
                  <div className="year">
                    {year?.selectorTitle} {t("reports:y")}
                  </div>
                  <SelectSearch
                    options={quaterTypesOptions}
                    search
                    filterOptions={fuzzySearch}
                    value={quaterIdSelectSearch}
                    name="schoolType"
                    onChange={(_, k) => {
                      onQuaterChange(k);
                      setQuaterIdSelectSearch(k.value);
                    }}
                    required
                    placeholder={`- ${t("instructor:quarter")} -`}
                  />
                  <SelectSearch
                    options={classesTypesOptions}
                    search
                    filterOptions={fuzzySearch}
                    value={classTitle}
                    name="schoolType"
                    onChange={(_, k) => {
                      onInputChange(k);
                      setClassTitle(k.value);
                      setClassName(k.name);
                    }}
                    required
                    placeholder={`- ${t("schoolAdmin:class")} -`}
                  />
                </div>
                {/* <div
                  onClick={() => {
                    exportTableToExcel(
                      "#student-record_table",
                      `${schoolName}_${year.startYear}-${year.endYear}_${
                        quaterTitle?.name
                      }-${t("instructor:quarter")}_${
                        classTitle?.name
                      } "Ведомость успеваемости учащихся"`
                    );
                  }}
                  className="schedule_export"
                >
                  {t("schoolAdmin:export")}
                </div> */}
                <div
                  onClick={async () => {
                    await StudentRecordExportPDF(
                      classPerformance,
                      listExcellence,
                      className,
                      quaterTitle?.name,
                      classOneStore?.personTitle
                        ? classOneStore?.personTitle
                        : "",
                      t,
                      i18n
                    );
                  }}
                  className="schedule_export"
                >
                  {t("schoolAdmin:export")}
                </div>
              </div>
              <div className="table_box" id="student-record_table-wrapper">
                {classTitle ? (
                  <table
                    className="schedule_box_table roboto"
                    id="student-record_table"
                  >
                    <thead className="table_box_title">
                      <td>
                        <div className="th_text">№</div>
                      </td>
                      <th className="t_header">
                        <div className="th_text">
                          {t("presetting:full name presetting")}
                        </div>
                      </th>
                      {classTitle && courses}
                      <th
                        className="th_text_fix_right_1"
                        style={{
                          writingMode: "vertical-lr",
                          transform: "rotate(180deg)",
                        }}
                      >
                        <div className="th_text">
                          {t("reports:knowledge quality")}
                        </div>
                      </th>
                      <th
                        className="th_text_fix_right_2"
                        style={{
                          writingMode: "vertical-lr",
                          transform: "rotate(180deg)",
                        }}
                      >
                        <div className="th_text">
                          {t("reports:achievement")}
                        </div>
                      </th>
                    </thead>
                    <tbody>{classTitle && studentTable}</tbody>
                    <tbody className="footer">
                      <tr className="student">
                        <td></td>
                        <td>{t("reports:length")} 5:</td>
                        {classTitle && LessonKnowledgeQuality(5)}
                      </tr>
                      <tr className="student">
                        <td></td>
                        <td>{t("reports:length")} 4:</td>
                        {classTitle && LessonKnowledgeQuality(4)}
                      </tr>
                      <tr className="student">
                        <td></td>
                        <td>{t("reports:length")} 3:</td>
                        {classTitle && LessonKnowledgeQuality(3)}
                      </tr>
                      <tr className="student">
                        <td></td>
                        <td>{t("reports:length")} 2:</td>
                        {classTitle && LessonKnowledgeQuality(2)}
                      </tr>
                      <tr className="student">
                        <td></td>
                        <td>{t("reports:knowledge quality")}</td>
                        {classTitle && LessonSchoolPerformance(1)}
                      </tr>
                      <tr className="student">
                        <td></td>
                        <td>{t("reports:achievement")}</td>
                        {classTitle && LessonSchoolPerformance(3)}
                      </tr>
                    </tbody>
                  </table>
                ) : (
                  <NoData />
                )}
              </div>
            </div>
          </div>
        </SchoolAdminLayout>
      )}
    </div>
  );
}

export default StudentRecord;
