import { urls, toastOption } from "../../config";
import { API } from "../../utils";
import { toast } from "react-toastify";
import i18n from "../../i18n";

import {
  GET_CLASS_REQUEST,
  GET_CLASS_SUCCESS,
  GET_CLASS_ERROR,
  GET_CLASS_ONE_REQUEST,
  GET_CLASS_ONE_SUCCESS,
  GET_CLASS_ONE_ERROR,
} from "../actionTypes";

export const getClassBySchoolId = (schoolId) => async (dispatch) => {
  dispatch(getClassRequest());
  await API.get(
    `${urls.CLASS}school/${
      schoolId ? schoolId : JSON.parse(localStorage.getItem("school"))?.id
    }`
  )
    .then((res) => {
      console.log(res.data);
      dispatch(
        getClassSuccess(
          res.data
            .sort((a, b) => a.classLabel.localeCompare(b.classLabel))
            .sort((a, b) => a.classLevel - b.classLevel)
        )
      );
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      // console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getClassFailure(error.message));
      return error.message;
    });
};
export const getClassById = (classId, setClass) => async (dispatch) => {
  dispatch(getOneClassRequest());
  await API.get(`${urls.CLASS}${classId}`)
    .then((res) => {
      console.log(res.data);
      setClass && setClass(res.data);
      dispatch(getOneClassSuccess(res.data));
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getOneClassFailure(error.message));
      return error.message;
    });
};
export const getClassByInstructorId = (instructorId) => async (dispatch) => {
  dispatch(getClassRequest());
  await API.get(`${urls.CLASS}instructor/${instructorId}`)
    .then((res) => {
      console.log(res.data);
      dispatch(
        getClassSuccess(
          res.data
            .sort((a, b) => a.classLabel.localeCompare(b.classLabel))
            .sort((a, b) => a.classLevel - b.classLevel)
        )
      );
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      // console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getClassFailure(error.message));
      return error.message;
    });
};

export const addClass = (value) => async (dispatch) => {
  const data = {
    ...value,
    schoolId: JSON.parse(localStorage.getItem("school"))?.id,
  };
  await API.post(`${urls.CLASS}create`, data)
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess added"), toastOption);
      dispatch(getClassBySchoolId());
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      return error.message;
    });
};
export const editClassOne = (value) => async (dispatch) => {
  const data = {
    level: value.classLevel,
    label: value.classLabel,
    classType: "PRIMARY",
    shiftId: value.shiftId,
    personId: value.personId,
    languageId: value.languageId,
    schoolId: JSON.parse(localStorage.getItem("school"))?.id,
    id: value.id,
  };
  await API.post(`${urls.CLASS}edit/${value.id}`, data)
    .then((res) => {
      console.log(res.data);
      dispatch(getClassBySchoolId());
      toast.success(i18n.t("toastify:succsess updated"), toastOption);
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      return error.message;
    });
};
export const editStudentsClass = (value) => async () => {
  const data = value;
  await API.post(`${urls.STUDENT}class-edit`, data)
    .then((res) => {
      console.log(res.data);
      // dispatch(getClassBySchoolId());
      toast.success(i18n.t("toastify:succsess updated"), toastOption);
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      return error.message;
    });
};
export const deleteClass = (id) => async (dispatch) => {
  dispatch(getClassRequest());
  await API.delete(
    `${urls.CLASS}?id=${id}&schoolId=${
      JSON.parse(localStorage.getItem("school"))?.id
    }`
  )
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess deleted"), toastOption);
      dispatch(getClassBySchoolId());
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getClassFailure(error.message));
      return error.message;
    });
};
export const getClassRequest = () => {
  return {
    type: GET_CLASS_REQUEST,
  };
};

export const getClassSuccess = (data) => {
  return {
    type: GET_CLASS_SUCCESS,
    payload: data,
  };
};

export const getClassFailure = (error) => {
  return {
    type: GET_CLASS_ERROR,
    payload: error,
  };
};

export const getOneClassRequest = () => {
  return {
    type: GET_CLASS_ONE_REQUEST,
  };
};

export const getOneClassSuccess = (data) => {
  return {
    type: GET_CLASS_ONE_SUCCESS,
    payload: data,
  };
};

export const getOneClassFailure = (error) => {
  return {
    type: GET_CLASS_ONE_ERROR,
    payload: error,
  };
};
