/* eslint-disable indent */
import React, { useEffect } from "react";
import PT from "prop-types";
import Select, { components } from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { getSchools } from "../../store/school/actions";
import { statuses } from "../../config";
import { useTranslation } from "react-i18next";

const customStyles = {
  option: (styles) => ({
    ...styles,
    padding: "24px 24px",
    // borderRadius: 8,
  }),
  control: () => ({
    width: 520,
    display: "flex",
    alignItems: "center",
    backgroundColor: "#fff",
    borderRadius: 8,
    border: "1px solid #E2E8F0",
    // boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
    fontSize: 14,
    // lineHeight: 23,
  }),
  singleValue: (styles) => ({
    ...styles,
    transition: "opacity 300ms",
  }),
  input: (styles) => ({
    ...styles,
    // color: "transparent",
    // ":active": {
    //   border: "1px solid red",
    // },
  }),
  // placeholder: (styles) => ({ ...styles, backgroundColor: "#F3F3F3" }),
  menuList: (style) => ({
    ...style,
    paddingTop: 0,
    paddingBottom: 0,
    maxHeight: 180,
  }),
};

const { Option } = components;

const IconOption = (props) => (
  <Option {...props}>
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      {/* <span style={{ fontSize: 24 }}>{props.data.icon}</span> */}
      <span style={{ fontSize: 16 }}>{props.data.label}</span>
    </div>
  </Option>
);
IconOption.propTypes = {
  data: PT.any,
};

export default function SelectSchoolDropdown(props) {
  const { t } = useTranslation(["userApplicationForm"]);

  const { onSelectSchool, schoolId } = props;
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  useEffect(() => {
    dispatch(getSchools());
  }, []);
  let options = [];
  if (store.schools?.status === statuses.SUCCESS) {
    options = store.schools?.schools?.map((item) => ({
      label: item?.name,
      value: item?.id,
    }));
  }

  return (
    <>
      {store.schools?.status === statuses.SUCCESS ? (
        <Select
          className="select-school-form__dropdown"
          // isLoading={store.schools?.status === statuses.LOADING}
          defaultValue={
            store.schools?.status === statuses.SUCCESS
              ? options?.filter(
                  (item) => Number(item.value) === Number(schoolId)
                )
              : []
          }
          options={store.schools?.status === statuses.SUCCESS ? options : []}
          styles={customStyles}
          placeholder={t("userApplicationForm:full school name")}
          components={{ Option: IconOption, IndicatorSeparator: () => null }}
          onChange={(e) => onSelectSchool(e.value)}
          theme={(theme) => ({
            ...theme,
            borderRadius: 8,
            backgroundColor: "#F3F3F3",
            // height: "auto",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.12)",

            colors: {
              ...theme.colors,
              text: "red",
              primary25: "rgba(240, 119, 58, 0.166)",
              primary50: "rgba(240, 119, 58, 0.166)",
              primary75: "rgba(240, 119, 58, 0.166)",
              primary: "#F0783A",
            },
          })}
        />
      ) : null}
    </>
  );
}

SelectSchoolDropdown.propTypes = {
  onSelectSchool: PT.func,
  schoolId: PT.any,
};
