import React, {
  // useEffect,
  useMemo,
  useState,
} from "react";
import { useTable } from "react-table";
import { X } from "react-feather";
import Modal from "react-modal";
import PT from "prop-types";
import { useTranslation } from "react-i18next";

import { NoData, PaginationBlock } from "..";
import "./StatisticModal.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  // getkClassCourses,
  // getkInstructorCourses,
  getkInstructorsGradeSheetByCourses,
} from "../../store/instructor/actions";
import SelectSearch, { fuzzySearch } from "react-select-search";
import StatisticModalQuaterGradeTable from "./StatisticModalQuaterGradeTable";

function StatisticModal(props) {
  const {
    modal,
    instructorId,
    activeButton,
    classId,
    setModal,
    data,
    buttons,
    currentPage,
    perPage,
    onChange,
    getPerPage,
    currentData,
    setCurrentData,
    // ! TOPIC
    currentTopicPage,
    topicPerPage,
    topicCurrentData,
    getTopicPerPage,
    onTopicChange,
    setTopicCurrentData,
    topicDate,
    // ! HW
    currentHWPage,
    hwPerPage,
    hwCurrentData,
    getHWPerPage,
    onHWChange,
    setHWCurrentData,
    hwData,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // const [inputeClass, setInputeClass] = useState();
  const [inputeCourses, setInputeCourses] = useState();

  // useEffect(() => {

  // }, [instructorId]);

  // const instructorCoursesStore = useSelector(
  //   (state) => state.instructors.instructorCourses
  // );

  // const gradeSheetMarkStore = useSelector(
  //   (state) => state.instructors.instructorsGradeSheetByCourses
  // );

  const classCoursesStore = useSelector(
    (state) => state.instructors.classCourses
  );

  const columns = useMemo(
    () => [
      {
        Header: `${t("instructor:students full name")}`,
        accessor: "studentTitle",
      },
      {
        Header: `${t("schoolAdmin:class")}`,
        accessor: "classTitle",
      },
      {
        Header: `${t("instructor:date")}`,
        accessor: "gradeDate",
      },
      {
        Header: `${t("instructor:grade")}`,
        accessor: "gradeMark",
      },
      {
        Header: `${t("presetting:subject")}`,
        accessor: "courseTitleRU",
      },
    ],
    [data, currentData]
  );

  const columnsTopic = useMemo(
    () => [
      {
        Header: `${t("schoolAdmin:classes")}`,
        accessor: "classTitle",
      },
      {
        Header: `${t("presetting:subject")}`,
        accessor: "courseTitleRU",
      },
      {
        Header: `${t("schoolAdmin:themes")}`,
        accessor: "topicTitle",
      },
      {
        Header: `${t("instructor:date according to plan")}`,
        accessor: "topicDatePlan",
      },
    ],
    [topicDate, topicCurrentData]
  );

  const columnsHW = useMemo(
    () => [
      {
        Header: `${t("schoolAdmin:classes")}`,
        accessor: "classTitle",
      },
      {
        Header: `${t("presetting:subject")}`,
        accessor: "courseTitleRU",
      },
      {
        Header: `${t("schoolAdmin:themes")}`,
        accessor: "topicTitle",
      },
      {
        Header: `${t("schoolAdmin:deadline")}`,
        accessor: "deadlineAt",
      },
    ],
    [hwData, hwCurrentData]
  );

  function getData(e) {
    dispatch(getkInstructorsGradeSheetByCourses(e));
  }

  // function getCourses(e) {
  //   dispatch(getkClassCourses(e));
  // }

  // const optionClasses = instructorCoursesStore?.map((item) => ({
  //   name: `${item.classLevel}${item.classLabel}`,
  //   id: item.id,
  //   schoolId: item.schoolId,
  //   value: [item.classLevel, item.classLabel],
  //   key: "intructorId",
  // }));

  const dataa = currentData?.length > 0 ? currentData : data.slice(0, perPage);

  const topicData =
    topicCurrentData?.length > 0
      ? topicCurrentData
      : topicDate.slice(0, topicPerPage);

  const hwMarkData =
    hwCurrentData?.length > 0 ? hwCurrentData : hwData.slice(0, hwPerPage);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns:
        (activeButton == "Оценки" && columns) ||
        (activeButton == "Темы" && columnsTopic) ||
        (activeButton == "Домашнее задание" && columnsHW) ||
        (activeButton == "Итоговые оценки" && columnsHW),
      data:
        (activeButton == "Оценки" && dataa) ||
        (activeButton == "Темы" && topicData) ||
        (activeButton == "Домашнее задание" && hwMarkData) ||
        (activeButton == "Итоговые оценки" && hwMarkData),
    });

  // console.log("classCoursesStore", classCoursesStore);

  // console.log("gradeSheetMarkStore", gradeSheetMarkStore);
  // const [classId, setClassId] = useState();
  const [courseId, setCourseId] = useState();
  return (
    <Modal
      isOpen={modal}
      onRequestClose={() => {
        setModal(false);
        setCurrentData([]);
        setTopicCurrentData([]);
        setHWCurrentData([]);
      }}
      overlayClassName="modal-overlay "
      className="stutistic_modal_grade"
    >
      <div className="modal" style={{ alignItems: "inherit" }}>
        <div className="modal-close">
          <button style={{ cursor: "pointer" }} onClick={() => setModal(false)}>
            <X size={15} color="#9A9A9A" />
          </button>
        </div>
        <div className="modal-header">
          <div className="modal-header-title">
            <h2 className="roboto"></h2>
          </div>
          <div className="modal-header-description">
            {t("instructor:teacher activity")}
          </div>
          <p className="modal-header-p">{`${t(
            "instructor:on this page, you can view the teacher's activity in detail"
          )}`}</p>
        </div>
        <div className="modal-header">
          <div className="modal-header-title">
            <h2 className="roboto"></h2>
          </div>
          <div className="modal-header-buttons">{buttons}</div>
          {activeButton == "Итоговые оценки" && (
            <div className="modal-header-buttons helper">
              {/* <span>{t("presetting:classes presetting")}: </span>
              <SelectSearch
                options={optionClasses}
                value={inputeClass}
                search
                className="select-search drop-up small helper"
                filterOptions={fuzzySearch}
                name="intructorId"
                onChange={(_, k) => {
                  // getCourses(k.id);
                  setClassId(k.id);
                  setInputeClass(k);
                }}
                required
                placeholder={t("presetting:classes presetting")}
              />*/}
              <div
                className="select-search drop-up small helper"
                style={{ gridColumnStart: 3, gridColumnEnd: 4 }}
              >
                <span>{t("presetting:subject")}: </span>
                <SelectSearch
                  options={classCoursesStore?.map((item) => ({
                    name: item.courseTitleRU,
                    value: [item.courseTitleRU, item.courseId],
                    courseId: item.courseId,
                    classId: item.classId,
                    id: item.courseId,
                    key: "course",
                  }))}
                  value={inputeCourses}
                  search
                  className="select-search helper"
                  filterOptions={fuzzySearch}
                  name="course"
                  onChange={(_, k) => {
                    getData(k.classId);
                    setCourseId(k.courseId);
                    setInputeCourses(k);
                  }}
                  required
                  placeholder={t("presetting:lessons")}
                />
              </div>
            </div>
          )}
          {activeButton == "Итоговые оценки" && classId && courseId ? (
            <StatisticModalQuaterGradeTable
              classId={classId}
              courseId={courseId}
              instructorId={instructorId}
            />
          ) : (
            activeButton == "Итоговые оценки" && <NoData />
          )}

          {activeButton !== "Итоговые оценки" && (
            <div className="table">
              <div className="teachers-active-table">
                {activeButton !== "Итоговые оценки" && getTableProps && (
                  <table {...getTableProps()}>
                    <thead>
                      {headerGroups.map((headerGroup, idx) => (
                        <tr key={idx} {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column, idx) => (
                            <th key={idx} {...column.getHeaderProps()}>
                              {column.render("Header")}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    {activeButton !== "Итоговые оценки" && getTableBodyProps && (
                      <tbody {...getTableBodyProps()}>
                        {rows.map((row, idx) => {
                          prepareRow(row);
                          return (
                            <tr key={idx} {...row.getRowProps()}>
                              {row.cells.map((cell, idx) => {
                                return (
                                  <td key={idx} {...cell.getCellProps()}>
                                    {cell.render("Cell")}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    )}
                  </table>
                )}

                <div className="pagination">
                  <div className="input">
                    {t("presetting:showing presetting")}
                    {getPerPage && activeButton == "Оценки" && (
                      <input
                        type="phone"
                        defaultValue={activeButton == "Оценки" && perPage}
                        onChange={activeButton == "Оценки" && getPerPage}
                      />
                    )}
                    {getTopicPerPage && activeButton == "Темы" && (
                      <input
                        type="phone"
                        defaultValue={activeButton == "Темы" && topicPerPage}
                        onChange={activeButton == "Темы" && getTopicPerPage}
                      />
                    )}
                    {hwPerPage && activeButton == "Домашнее задание" && (
                      <input
                        type="phone"
                        defaultValue={
                          activeButton == "Домашнее задание" && hwPerPage
                        }
                        onChange={
                          activeButton == "Домашнее задание" && getHWPerPage
                        }
                      />
                    )}
                    <span>{`из ${
                      (activeButton == "Оценки" && data?.length) ||
                      (activeButton == "Темы" && topicDate?.length) ||
                      (activeButton == "Домашнее задание" && hwData?.length)
                    }`}</span>
                    <p>{t("presetting:lines presetting")}</p>
                  </div>
                  {activeButton == "Оценки" && (
                    <PaginationBlock
                      className="roboto"
                      style={{ display: "flex" }}
                      total={activeButton == "Оценки" && data?.length}
                      current={activeButton == "Оценки" && currentPage}
                      onChange={activeButton == "Оценки" && onChange}
                      pageSize={activeButton == "Оценки" && perPage}
                    />
                  )}
                  {activeButton == "Темы" && (
                    <PaginationBlock
                      className="roboto"
                      style={{ display: "flex" }}
                      total={activeButton == "Темы" && topicDate?.length}
                      current={activeButton == "Темы" && currentTopicPage}
                      onChange={activeButton == "Темы" && onTopicChange}
                      pageSize={activeButton == "Темы" && topicPerPage}
                    />
                  )}
                  {activeButton == "Домашнее задание" && (
                    <PaginationBlock
                      className="roboto"
                      style={{ display: "flex" }}
                      total={
                        activeButton == "Домашнее задание" && hwData?.length
                      }
                      current={
                        activeButton == "Домашнее задание" && currentHWPage
                      }
                      onChange={
                        activeButton == "Домашнее задание" && onHWChange
                      }
                      pageSize={activeButton == "Домашнее задание" && hwPerPage}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}

StatisticModal.propTypes = {
  modal: PT.bool,
  setModal: PT.func,
  endDate: PT.string,
  instructorId: PT.number,
  startDate: PT.string,
  classId: PT.number,
  marksStore: PT.any,
  activeButton: PT.any,
  setActiveButton: PT.any,
  data: PT.any,
  filterButtons: PT.any,
  buttons: PT.any,
  currentPage: PT.any,
  perPage: PT.any,
  onChange: PT.any,
  setCurrentData: PT.func,
  getPerPage: PT.any,
  currentData: PT.any,
  // ! TOPIC
  currentTopicPage: PT.any,
  topicPerPage: PT.any,
  topicCurrentData: PT.any,
  getTopicPerPage: PT.any,
  onTopicChange: PT.any,
  setTopicCurrentData: PT.any,
  topicDate: PT.any,
  // ! TOPIC
  currentHWPage: PT.any,
  hwPerPage: PT.any,
  hwCurrentData: PT.any,
  getHWPerPage: PT.any,
  onHWChange: PT.any,
  setHWCurrentData: PT.any,
  hwData: PT.any,
};

export default StatisticModal;
