import React from "react";
import PT from "prop-types";
import { useHistory } from "react-router-dom";
import { ChevronLeft } from "react-feather";

import "./GoBack.scss";
export default function GoBack(props) {
  const { title, link } = props;
  const history = useHistory();
  const onClickHandler = () => {
    if (link) {
      history.push(link);
    } else {
      history.goBack();
    }
  };

  return (
    <div className="go-back__button">
      <div className="go-back__button-inner">
        <button onClick={onClickHandler}>
          <ChevronLeft size={24} />
        </button>
      </div>
      {title && <h3>{title}</h3>}
    </div>
  );
}

GoBack.propTypes = {
  title: PT.string,
  link: PT.string,
};
