import i18n from "i18next";
// import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import {
  commonEN,
  glossaryEN,
  validationEN,
  loginEN,
  toastifyEN,
  userApplicationFormEN,
  commonRU,
  glossaryRU,
  validationRU,
  loginRU,
  toastifyRU,
  userApplicationFormRU,
  commonKG,
  glossaryKG,
  validationKG,
  loginKG,
  toastifyKG,
  userApplicationFormKG,
  commonTR,
  glossaryTR,
  validationTR,
  loginTR,
  toastifyTR,
  userApplicationFormTR,
  presettingEN,
  presettingRU,
  presettingKG,
  presettingTR,
  schoolAdminTR,
  schoolAdminKG,
  schoolAdminEN,
  schoolAdminRU,
  instructorEN,
  instructorRU,
  instructorKG,
  instructorTR,
  reportsEN,
  reportsRU,
  reportsKG,
  reportsTR,
} from "./assets/locales";

const resources = {
  en: {
    reports: reportsEN,
    schoolAdmin: schoolAdminEN,
    common: commonEN,
    glossary: glossaryEN,
    validation: validationEN,
    login: loginEN,
    toastify: toastifyEN,
    presetting: presettingEN,
    userApplicationForm: userApplicationFormEN,
    instructor: instructorEN,
  },

  ru: {
    reports: reportsRU,
    schoolAdmin: schoolAdminRU,
    common: commonRU,
    presetting: presettingRU,
    glossary: glossaryRU,
    validation: validationRU,
    login: loginRU,
    toastify: toastifyRU,
    userApplicationForm: userApplicationFormRU,
    instructor: instructorRU,
  },
  kg: {
    reports: reportsKG,
    schoolAdmin: schoolAdminKG,
    common: commonKG,
    presetting: presettingKG,
    glossary: glossaryKG,
    validation: validationKG,
    login: loginKG,
    toastify: toastifyKG,
    userApplicationForm: userApplicationFormKG,
    instructor: instructorKG,
  },
  tr: {
    reports: reportsTR,
    schoolAdmin: schoolAdminTR,
    common: commonTR,
    presetting: presettingTR,
    glossary: glossaryTR,
    validation: validationTR,
    login: loginTR,
    toastify: toastifyTR,
    userApplicationForm: userApplicationFormTR,
    instructor: instructorTR,
  },
};

i18n
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "ru",
    cleanCode: true,
    lowerCaseLng: true,
    whitelist: ["ru", "en", "kg", "tr"],
    debug: false,
    defaultNS: "common",
    // nsSeparator: ":",
    interpolation: {
      escapeValue: false,
    },
    react: {
      wait: true,
    },
  });

export default i18n;
