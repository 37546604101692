/* eslint-disable indent */
import React, { useEffect, useState, useCallback } from "react";
import { debounce } from "lodash";
import { SchoolAdminLayout, PaginationBlock, GoBack } from "../../components";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { X, Edit2, Trash, PlusCircle } from "react-feather";
import SelectSearch, { fuzzySearch } from "react-select-search";
import Tooltip from "react-simple-tooltip";

import { getClassBySchoolId } from "../../store/class/actions";
import { getShifts } from "../../store/shift/actions";
import { getAllStudentsByClass } from "../../store/student/actions";
import {
  getScheduleGroups,
  addScheduleGroup,
  editScheduleGroup,
  getScheduleGroupsById,
  deleteScheduleGroup,
} from "../../store/scheduleGroup/actions";
import { statuses } from "../../config";

export default function ScheduleGroup() {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const classStore = useSelector((store) =>
    store.class.class.sort((a, b) => a.classLevel - b.classLevel)
  );
  const shiftsStore = useSelector((store) => store.shifts.shifts);
  const studentsStore = useSelector((store) =>
    store.students.students.filter((item) => item.archived === false)
  );
  const scheduleGroupStore = useSelector(
    (store) => store.scheduleGroups.scheduleGroup
  );
  const scheduleGroupStatus = useSelector(
    (store) => store.scheduleGroups.status
  );
  const scheduleGroupsStore = useSelector(
    (store) => store.scheduleGroups.scheduleGroups
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(2);
  const [currentData, setCurrentData] = useState([]);

  const [shiftId, setShiftId] = useState();
  const [classId, setClassId] = useState();
  // const [groupId, setGroupId] = useState();
  const [selectedData, setSelectedData] = useState([]);
  const [selectedEditData, setSelectedEditData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [newGroup, setNewGroup] = useState({});
  const [editGroup, setEditGroup] = useState({});
  const [currentGroupId, setCurrentGroupId] = useState();
  const [modal, setModal] = useState({
    createGroup: false,
    editGroup: false,
  });
  useEffect(async () => {
    await dispatch(getClassBySchoolId());
    await dispatch(getShifts());
    await dispatch(getScheduleGroups());
    //   await dispatch(getShiftTimes());
    //   await dispatch(
    //     getSchoolById(JSON.parse(localStorage.getItem("school"))?.id)
    //   );
  }, [modal]);
  const debouncedPerPage = useCallback(
    debounce((text, scheduleGroupStore) => {
      onPageChanged(1, Number(text), scheduleGroupStore);
      setPerPage(Number(text));
      setCurrentPage(1);
    }, 1000),
    [scheduleGroupStore]
  );
  const onPageChanged = (pageNumber, perPageNumber, data = classStore) => {
    const offset = (pageNumber - 1) * perPageNumber;
    console.log(data);
    const currentDataArr = data.slice(offset, offset + perPageNumber);
    console.log("currentDataArr", currentDataArr);
    return setCurrentData(currentDataArr);
  };
  const getPerPage = (e) => {
    debouncedPerPage(e.target.value);
  };
  const onChange = async (e) => {
    console.log(e);
    setCurrentPage(e);
    onPageChanged(
      e,
      perPage,
      classStore
        .filter((item) => (shiftId ? item.shiftId == shiftId : item))
        .filter((item) => (classId ? item.id == classId : item))
    );
  };
  const onInputChange = (e) => {
    setNewGroup({ ...newGroup, [e.target.name]: e.target.value });
  };
  const onEditInputChange = (e) => {
    setEditGroup({ ...editGroup, [e.target.name]: e.target.value });
  };
  const removeItem = async (value) => {
    console.log(value);
    await dispatch(deleteScheduleGroup(value.groupId));
  };
  const onSelect = (value) => {
    if (selectedData.includes(value.id)) {
      setSelectedData(selectedData.filter((item) => item != value.id));
    } else {
      setSelectedData([...selectedData, value.id]);
    }
  };
  const onSelectAll = (values) => {
    setSelectedData(values);
  };
  const onEditSelect = (value) => {
    console.log(selectedEditData);
    console.log(value);
    let a = selectedEditData || [];
    console.log(a.includes(value.id));
    let c = a.includes(value.id);
    if (!c) {
      a.push(value.id);
    } else {
      a = a.filter((item) => item !== value.id);
      console.log(a);
    }
    setSelectedEditData(a);
  };
  const shiftsOptions = shiftsStore.map((item) => ({
    name: `${item.title} ${t("presetting:shift presetting")}`,
    value: item.id,
    key: "shiftId",
  }));
  const classOptions = classStore
    .filter((item) => (shiftId ? item.shiftId == shiftId : item))
    .map((item) => ({
      name: `${item.classLevel}${item.classLabel}`,
      value: item.id,
      key: "classId",
    }));
  const tableDataRender =
    currentData.length > 0
      ? currentData.map((item, idx) => (
          <div className="school-admin__group" key={idx}>
            <div className="school-admin__group-header">
              <div className="school-admin__group-header-left">
                <p>
                  {item.classLevel}
                  {item.classLabel}
                </p>
              </div>
              <div className="school-admin__group-header-right">
                <button
                  onClick={() => {
                    setModal({ ...modal, createGroup: true });
                    setClassId(item.id);
                    setNewGroup({
                      class: `${item.classLevel}${item.classLabel}`,
                      classId: item.id,
                    });
                  }}
                >
                  <span>{t("schoolAdmin:add group")}</span>
                  <PlusCircle size={18} color="#F0783A" />
                </button>
              </div>
            </div>
            <div className="school-admin__group-body">
              <div className="school-admin__group-list">
                {scheduleGroupsStore.filter(
                  (group) => group.classId === item.id
                ).length > 0 && (
                  <div className="school-admin__group-item">
                    <div>{t("schoolAdmin:groups name")}</div>
                    <div>{t("schoolAdmin:number of students")}</div>
                    <div>{t("presetting:action presetting")}</div>
                  </div>
                )}
                {scheduleGroupsStore
                  .filter((group) => group.classId === item.id)
                  .map((group, idx2) => (
                    <div className="school-admin__group-item" key={idx2}>
                      <div>{group.groupTitle}</div>
                      <div>{group.studentCount}</div>
                      <div>
                        <div>
                          <Tooltip
                            className="tooltip"
                            content={t("common:edit")}
                          >
                            <button
                              title="edit"
                              onClick={async () => {
                                await dispatch(
                                  getScheduleGroupsById(group.groupId)
                                );
                                await dispatch(
                                  getAllStudentsByClass(group.classId)
                                );
                                scheduleGroupStatus === statuses.SUCCESS &&
                                  (await setSelectedEditData(
                                    scheduleGroupStore.studentList
                                  ));
                                await console.log(scheduleGroupStore);
                                await setCurrentGroupId(group.groupId);
                                // setGroupId(group.id);
                                await setModal({ ...modal, editGroup: true });
                                await setEditGroup(group);
                              }}
                            >
                              <Edit2 size={16} color="#14A38B" />
                            </button>
                          </Tooltip>
                          <Tooltip
                            className="tooltip"
                            content={t("common:delete")}
                          >
                            <button onClick={() => removeItem(group)}>
                              <Trash size={16} color="#FF7171" />
                            </button>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        ))
      : classStore.slice([0], [2]).map((item, idx) => (
          <div className="school-admin__group" key={idx}>
            <div className="school-admin__group-header">
              <div className="school-admin__group-header-left">
                <p>
                  {item.classLevel}
                  {item.classLabel}
                </p>
              </div>
              <div className="school-admin__group-header-right">
                <button
                  onClick={() => {
                    setModal({ ...modal, createGroup: true });
                    setClassId(item.id);
                    setNewGroup({
                      class: `${item.classLevel}${item.classLabel}`,
                      classId: item.id,
                    });
                  }}
                >
                  <span>{t("schoolAdmin:add group")}</span>
                  <PlusCircle size={18} color="#F0783A" />
                </button>
              </div>
            </div>
            <div className="school-admin__group-body">
              <div className="school-admin__group-list">
                {scheduleGroupsStore.filter(
                  (group) => group.classId === item.id
                ).length > 0 && (
                  <div className="school-admin__group-item">
                    <div>{t("schoolAdmin:groups name")}</div>
                    <div>{t("schoolAdmin:number of students")}</div>
                    <div>{t("presetting:action presetting")}</div>
                  </div>
                )}
                {scheduleGroupsStore
                  .filter((group) => group.classId === item.id)
                  .map((group, idx2) => (
                    <div className="school-admin__group-item" key={idx2}>
                      <div>{group.groupTitle}</div>
                      <div>{group.studentCount}</div>
                      <div>
                        <div>
                          <Tooltip
                            className="tooltip"
                            content={t("common:edit")}
                          >
                            <button
                              title="edit"
                              onClick={async () => {
                                await dispatch(
                                  getScheduleGroupsById(group.groupId)
                                );
                                await dispatch(
                                  getAllStudentsByClass(group.classId)
                                );
                                scheduleGroupStatus === statuses.SUCCESS &&
                                  (await setSelectedEditData(
                                    scheduleGroupStore.studentList
                                  ));
                                await console.log(scheduleGroupStore);
                                await setCurrentGroupId(group.groupId);
                                // setGroupId(group.id);
                                await setModal({ ...modal, editGroup: true });
                                await setEditGroup(group);
                              }}
                            >
                              <Edit2 size={16} color="#14A38B" />
                            </button>
                          </Tooltip>
                          <Tooltip
                            className="tooltip"
                            content={t("common:delete")}
                          >
                            <button onClick={() => removeItem(group)}>
                              <Trash size={16} color="#FF7171" />
                            </button>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        ));
  return (
    <SchoolAdminLayout>
      <GoBack title={`${t("schoolAdmin:back to schedule")}`} />
      <div className="school-admin__schedule-group school-presetting__body school-presetting__body-color">
        <div className="school-presetting__body-row">
          <div className="school-presetting__body-title">
            <h2>{t("schoolAdmin:group")}</h2>
          </div>
          <div className="school-presetting__body-description">
            <p className="body-1">
              {t(
                "schoolAdmin:on this page you can create and configure groups"
              )}
            </p>
          </div>
        </div>
        <div className="school-presetting__search-filter">
          <div className="school-presetting__search">
            <SelectSearch
              options={shiftsOptions}
              search
              className="select-search filter"
              filterOptions={fuzzySearch}
              name="shiftId"
              value={shiftId}
              onChange={(_, k) => {
                // onFilterHandler(k.value);
                setShiftId(k.value);
                onPageChanged(
                  currentPage,
                  perPage,
                  classStore.filter((item) => item.shiftId == k.value)
                );
              }}
              required
              placeholder={`${t("schoolAdmin:all shifts")}`}
            />

            <SelectSearch
              options={classOptions}
              search
              className="select-search filter"
              filterOptions={fuzzySearch}
              name="classId"
              value={classId}
              onChange={(_, k) => {
                // onFilterHandler(k.value);
                setClassId(k.value);
                onPageChanged(
                  currentPage,
                  perPage,
                  classStore.filter((item) => item.id == k.value)
                );
              }}
              required
              placeholder={`${t("schoolAdmin:all classes")}`}
            />
          </div>
        </div>
        <div className="school-presetting__subjects-list">
          <div className="school-presetting__teachers-body">
            <div className="school-presetting__teachers-table">
              {tableDataRender}
            </div>
          </div>
          <div className="pagination">
            <div className="paginaiton__inner">
              <div className="pagination__per-page">
                <p>
                  {t("presetting:showing presetting")}{" "}
                  <input
                    type="phone"
                    defaultValue={perPage}
                    onChange={getPerPage}
                  />{" "}
                  {t("presetting:from presetting")}{" "}
                  {
                    classStore
                      .filter((item) =>
                        shiftId ? item.shiftId == shiftId : item
                      )
                      .filter((item) => (classId ? item.id == classId : item))
                      .length
                  }{" "}
                  {t("presetting:lines presetting")}
                </p>
              </div>
              <div className="pagination__page">
                <PaginationBlock
                  total={
                    classStore
                      .filter((item) =>
                        shiftId ? item.shiftId == shiftId : item
                      )
                      .filter((item) => (classId ? item.id == classId : item))
                      .length
                  }
                  current={currentPage}
                  onChange={onChange}
                  pageSize={perPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modal.createGroup}
        onRequestClose={() => {
          setModal({ ...modal, createGroup: false });
          setSearchText("");
          setSelectedData([]);
          setNewGroup({});
        }}
        overlayClassName="modal-overlay"
        onAfterOpen={() => {
          dispatch(getAllStudentsByClass(newGroup.classId));
          // dispatch(getScheduleGroupsByClassId(new.classId));
        }}
      >
        <div className="modal">
          <div className="modal-close">
            <button
              onClick={() => {
                setModal({ ...modal, createGroup: false });
                setSearchText("");
                setSelectedData([]);
                setNewGroup({});
              }}
            >
              <X size={15} color="#9A9A9A" />
            </button>
          </div>
          <div className="modal-header">
            <div className="modal-header-title">
              <h2 className="roboto">
                {newGroup.class} {t("schoolAdmin:lower case class")}
              </h2>
            </div>
            <div className="modal-header-description">
              <p className="body-2">
                {t(
                  "schoolAdmin:enter a group name for this class and select students"
                )}
              </p>
            </div>
          </div>
          <div className="modal-body">
            <div className="modal-inputs-list">
              <div className="modal-input">
                <input
                  type="text"
                  placeholder={`${t("schoolAdmin:groups name")}`}
                  name="title"
                  defaultValue={newGroup.title}
                  onChange={onInputChange}
                />
              </div>
              <div>
                <h3>{t("schoolAdmin:students")}</h3>
              </div>
              <div className="modal-input">
                <input
                  type="text"
                  placeholder={`${t("schoolAdmin:search by student name")}`}
                  name="searchText"
                  defaultValue={searchText}
                  onChange={(e) => setSearchText(e.target.value.toLowerCase())}
                />
              </div>
              <div className="modal-input">
                <div className="school-admin__group-students">
                  <>
                    <div className="table-select school-admin__group-student">
                      <label className="check">
                        <input
                          type="checkbox"
                          onChange={() => {
                            if (
                              selectedData.length ===
                              studentsStore.filter((item) =>
                                searchText
                                  ? `${item.firstName.toLowerCase()} ${item.lastName.toLowerCase()} ${item.middleName.toLowerCase()}`.includes(
                                      searchText
                                    )
                                  : item
                              ).length
                            ) {
                              onSelectAll([]);
                            } else {
                              onSelectAll(
                                studentsStore
                                  .filter((item) =>
                                    searchText
                                      ? `${item.firstName.toLowerCase()} ${item.lastName.toLowerCase()} ${item.middleName.toLowerCase()}`.includes(
                                          searchText
                                        )
                                      : item
                                  )
                                  .map((item) => item.id)
                              );
                            }
                          }}
                          checked={
                            selectedData.length ===
                            studentsStore.filter((item) =>
                              searchText
                                ? `${item.firstName.toLowerCase()} ${item.lastName.toLowerCase()} ${item.middleName.toLowerCase()}`.includes(
                                    searchText
                                  )
                                : item
                            ).length
                          }
                        />
                        <div className="box"></div>
                      </label>
                      <span></span>
                      <p>{t("schoolAdmin:select all")}</p>
                    </div>
                    <div className="school-admin__group-student-divider"></div>
                  </>
                  {studentsStore
                    .filter((item) =>
                      searchText
                        ? `${item.firstName.toLowerCase()} ${item.lastName.toLowerCase()} ${item.middleName.toLowerCase()}`.includes(
                            searchText
                          )
                        : item
                    )
                    .map((item, idx) => (
                      <>
                        <div
                          key={idx}
                          className="table-select school-admin__group-student"
                        >
                          <label className="check">
                            <input
                              type="checkbox"
                              onChange={() => onSelect(item)}
                              checked={selectedData?.find((i) => i === item.id)}
                            />
                            <div className="box"></div>
                          </label>
                          <span>{idx + 1}</span>
                          <p>
                            {item.firstName} {item.lastName} {item.middleName}
                          </p>
                        </div>
                        <div className="school-admin__group-student-divider"></div>
                      </>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="small secondary"
              onClick={() => {
                setModal({ ...modal, createGroup: false });
                setSearchText("");
                setSelectedData([]);
                setNewGroup({});
              }}
            >
              {t("presetting:consel presetting")}
            </button>
            <button
              className="large primary"
              onClick={() => {
                dispatch(
                  addScheduleGroup({
                    ...newGroup,
                    studentList: selectedData,
                  })
                );
                setSelectedData([]);
                setSearchText("");
                setNewGroup({});
                setModal({ ...modal, createGroup: false });
              }}
            >
              {t("common:save")}
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modal.editGroup}
        onRequestClose={() => {
          setModal({ ...modal, editGroup: false });
          setSearchText("");
          setSelectedData([]);
          setEditGroup({});
          setSelectedEditData([]);
        }}
        overlayClassName="modal-overlay"
        onAfterOpen={async () => {
          // await dispatch(getAllStudentsByClass(editGroup.classId));
          // await dispatch(getScheduleGroupsById(editGroup.groupId));
          await setSelectedEditData(scheduleGroupStore.studentList);
          // console.log(scheduleGroupStore);
          // await setCurrentGroupId(editGroup.groupId);
        }}
      >
        <div className="modal">
          <div className="modal-close">
            <button
              onClick={() => {
                setModal({ ...modal, editGroup: false });
                setSearchText("");
                setSelectedData([]);
                setEditGroup({});
                setSelectedEditData([]);
              }}
            >
              <X size={15} color="#9A9A9A" />
            </button>
          </div>
          <div className="modal-header">
            <div className="modal-header-title">
              <h2 className="roboto">
                {editGroup.classTitle} {t("schoolAdmin:lower case class")}
              </h2>
            </div>
            <div className="modal-header-description">
              <p className="body-2">
                {t(
                  "schoolAdmin:enter a group name for this class and select students"
                )}
              </p>
            </div>
          </div>
          <div className="modal-body">
            <div className="modal-inputs-list">
              <div className="modal-input">
                <input
                  type="text"
                  placeholder={t("schoolAdmin:groups name")}
                  name="title"
                  defaultValue={scheduleGroupStore.title}
                  onChange={onEditInputChange}
                />
              </div>
              <div>
                <h3>{t("schoolAdmin:students")}</h3>
              </div>
              <div className="modal-input">
                <input
                  type="text"
                  placeholder={`${t("schoolAdmin:search by student name")}`}
                  name="searchText"
                  defaultValue={searchText}
                  onChange={(e) => setSearchText(e.target.value.toLowerCase())}
                />
              </div>
              <div className="modal-input">
                <div className="school-admin__group-students">
                  <>
                    <div className="table-select school-admin__group-student">
                      <label className="check">
                        <input
                          type="checkbox"
                          onChange={() => {
                            if (
                              selectedEditData.length ===
                              studentsStore.filter((item) =>
                                searchText
                                  ? `${item.firstName.toLowerCase()} ${item.lastName.toLowerCase()} ${item.middleName.toLowerCase()}`.includes(
                                      searchText
                                    )
                                  : item
                              ).length
                            ) {
                              setSelectedEditData([]);
                            } else {
                              setSelectedEditData(
                                studentsStore
                                  .filter((item) =>
                                    searchText
                                      ? `${item.firstName.toLowerCase()} ${item.lastName.toLowerCase()} ${item.middleName.toLowerCase()}`.includes(
                                          searchText
                                        )
                                      : item
                                  )
                                  .map((item) => item.id)
                              );
                            }
                          }}
                          checked={
                            selectedEditData?.length ===
                            studentsStore?.filter((item) =>
                              searchText
                                ? `${item.firstName.toLowerCase()} ${item.lastName.toLowerCase()} ${item.middleName.toLowerCase()}`.includes(
                                    searchText
                                  )
                                : item
                            ).length
                          }
                        />
                        <div className="box"></div>
                      </label>
                      <span></span>
                      <p>{t("schoolAdmin:select all")}</p>
                    </div>
                    <div className="school-admin__group-student-divider"></div>
                  </>
                  {scheduleGroupStatus === statuses.SUCCESS &&
                    editGroup &&
                    selectedEditData &&
                    studentsStore
                      .filter((item) =>
                        searchText
                          ? `${item.firstName.toLowerCase()} ${item.lastName.toLowerCase()} ${item.middleName.toLowerCase()}`.includes(
                              searchText
                            )
                          : item
                      )
                      .map((item, idx) => (
                        <>
                          <div
                            key={idx}
                            className="table-select school-admin__group-student"
                          >
                            <label className="check">
                              <input
                                type="checkbox"
                                onChange={() => onEditSelect(item)}
                                checked={selectedEditData?.find(
                                  (i) => i === item.id
                                )}
                                // checked={selectedEditData?.includes(item.id)}
                              />
                              <div className="box"></div>
                            </label>
                            <span>{idx + 1}</span>
                            <p>
                              {item.firstName} {item.lastName} {item.middleName}
                            </p>
                          </div>
                          <div className="school-admin__group-student-divider"></div>
                        </>
                      ))}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="small secondary"
              onClick={() => {
                setModal({ ...modal, editGroup: false });
                setSearchText("");
                setSelectedData([]);
                setEditGroup({});
                setSelectedEditData([]);
              }}
            >
              {t("presetting:consel presetting")}
            </button>
            <button
              className="large primary"
              onClick={() => {
                dispatch(
                  editScheduleGroup({
                    // ...editGroup,
                    groupId: currentGroupId,
                    title: editGroup.title || scheduleGroupStore.title,
                    studentList: selectedEditData,
                  })
                );
                setSearchText("");
                setSelectedData([]);
                setSelectedEditData([]);
                setEditGroup({});
                setModal({ ...modal, editGroup: false });
              }}
            >
              {t("common:save")}
            </button>
          </div>
        </div>
      </Modal>
    </SchoolAdminLayout>
  );
}
