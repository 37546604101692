/* eslint-disable indent */
import React, { useEffect, useState, useCallback } from "react";
import { debounce } from "lodash";
import { Link } from "react-router-dom";
import {
  SchoolAdminLayout,
  PaginationBlock,
  SearchField,
  TimePickerCalendar,
} from "../../components";
import SelectSearch, { fuzzySearch } from "react-select-search";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { X, PlusSquare, Edit2, Trash } from "react-feather";
import Tooltip from "react-simple-tooltip";

import { getInstructors } from "../../store/instructor/actions";
// import { getClassBySchoolId } from "../../store/class/actions";
// import { getShifts } from "../../store/shift/actions";
import {
  getSections,
  createSection,
  editSection as editSectionAction,
  // getSectionById,
  createSectionInstructor,
  deleteSection,
} from "../../store/section/actions";
import { days } from "../../config/constants";

export default function ScheduleGroup() {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  // const sectionsStore = useSelector((store) =>
  //   store.students.students.filter((item) => item.archived === false)
  // );
  const instructorsStore = useSelector(
    (store) => store.instructors.instructors
  );
  // const sectionsStatus = useSelector((store) => store.sections.status);
  const sectionsStore = useSelector((store) => store.sections.sections);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [currentData, setCurrentData] = useState([]);

  const [currentSectionId, setCurrentSectionId] = useState();
  // const [groupId, setGroupId] = useState();
  const [searchedCurrentData, setSearchedCurrentData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [newSection, setNewGroup] = useState({});
  const [newInstructor, setNewInstructor] = useState({});
  const [editSection, setEditSection] = useState({});
  // const [currentGroupId, setCurrentGroupId] = useState();
  const [modal, setModal] = useState({
    createSection: false,
    editSection: false,
    addInstructor: false,
    confirm: false,
  });
  useEffect(async () => {
    // await dispatch(getClassBySchoolId());
    // await dispatch(getShifts());
    await dispatch(getSections());
    //   await dispatch(getShiftTimes());
    //   await dispatch(
    //     getSchoolById(JSON.parse(localStorage.getItem("school"))?.id)
    //   );
  }, []);
  const debouncedPerPage = useCallback(
    debounce((text, sectionsStore) => {
      onPageChanged(1, Number(text), sectionsStore);
      setPerPage(Number(text));
      setCurrentPage(1);
    }, 1000),
    [sectionsStore]
  );
  const onPageChanged = (pageNumber, perPageNumber, data = sectionsStore) => {
    const offset = (pageNumber - 1) * perPageNumber;
    console.log(data);
    const currentDataArr = data.slice(offset, offset + perPageNumber);
    console.log("currentDataArr", currentDataArr);
    return setCurrentData(currentDataArr);
  };
  const getPerPage = (e) => {
    debouncedPerPage(e.target.value);
  };
  const onChange = async (e) => {
    console.log(e);
    setCurrentPage(e);
    if (searchedCurrentData.length > 0) {
      onPageChanged(e, perPage, searchedCurrentData);
    } else {
      onPageChanged(e, perPage, sectionsStore);
    }
  };
  const debouncedSearch = useCallback(
    debounce((text) => {
      setSearchText(text.toLowerCase());
      setCurrentPage(1);
      // setFilterTypeNumber(1);

      const a = sectionsStore.filter((item) => {
        const a = String(`${item.sectionTitle}`).toLowerCase();
        const c = a.includes(text.toLowerCase());
        return c;
      });
      setSearchedCurrentData([...a]);
      onPageChanged(1, perPage, [...a]);
    }, 1000),
    [sectionsStore, perPage]
  );
  const searchInputHandler = (e) => {
    debouncedSearch(e.target.value);
  };
  const onInputChange = (e) => {
    setNewGroup({ ...newSection, [e.target.name]: e.target.value });
  };
  const onEditInputChange = (e) => {
    setEditSection({ ...editSection, [e.target.name]: e.target.value });
  };
  // const removeItem = async (value) => {
  //   console.log(value);
  //   await dispatch(deleteSection(value.groupId));
  // };
  const removeItem = async (value) => {
    await dispatch(deleteSection(value));
    // await dispatch(getSections());
  };

  const tableDataRender =
    currentData.length > 0
      ? currentData.map((item, idx) => (
          <div className="school-admin__group" key={idx}>
            <div className="school-admin__group-header">
              <div className="school-admin__group-header-left">
                <p>{item.sectionTitle}</p>
              </div>
              <div className="school-admin__group-header-right">
                <Tooltip className="tooltip" content={t("common:add")}>
                  <button
                    onClick={() => {
                      setModal({ ...modal, addInstructor: true });
                      setCurrentSectionId(item.sectionId);
                    }}
                  >
                    <PlusSquare size={18} color="#F0783A" />
                  </button>
                </Tooltip>
                <Tooltip className="tooltip" content={t("common:edit")}>
                  <button
                    onClick={() => {
                      setModal({ ...modal, editSection: true });
                      setCurrentSectionId(item.sectionId);
                      setEditSection(item);
                    }}
                  >
                    <Edit2 size={16} color="#14A38B" />
                  </button>
                </Tooltip>
                <Tooltip className="tooltip" content={t("common:delete")}>
                  <button
                    onClick={() => {
                      setCurrentSectionId(item.sectionId);
                      setModal({ ...modal, confirm: true });
                    }}
                  >
                    <Trash size={16} color="#FF7171" />
                  </button>
                </Tooltip>
              </div>
            </div>
            <div className="school-admin__group-body">
              <div className="school-admin__group-list">
                {item?.sectionInstructorResponses?.length > 0 && (
                  <div className="school-admin__group-item">
                    <div>{t("schoolAdmin:insctructor")}</div>
                    <div>{t("schoolAdmin:number of students")}</div>
                    <div>{t("presetting:action presetting")}</div>
                  </div>
                )}
                {item?.sectionInstructorResponses?.map((instructor, idx2) => (
                  <div className="school-admin__group-item" key={idx2}>
                    <div>{instructor.personTitle}</div>
                    <div>{instructor.studentCount}</div>
                    <div>
                      <Link
                        to={`/school-admin/sections/${instructor.sectionInstructorId}`}
                      >
                        {t("schoolAdmin:detail")}
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))
      : sectionsStore.slice([0], [perPage]).map((item, idx) => (
          <div className="school-admin__group" key={idx}>
            <div className="school-admin__group-header">
              <div className="school-admin__group-header-left">
                <p>{item.sectionTitle}</p>
              </div>
              <div className="school-admin__group-header-right">
                <Tooltip className="tooltip" content={t("common:add")}>
                  <button
                    onClick={() => {
                      setModal({ ...modal, addInstructor: true });
                      setCurrentSectionId(item.sectionId);
                      setNewGroup({
                        class: `${item.classLevel}${item.classLabel}`,
                        currentSectionId: item.id,
                      });
                    }}
                  >
                    <PlusSquare size={18} color="#F0783A" />
                  </button>
                </Tooltip>
                <Tooltip className="tooltip" content={t("common:edit")}>
                  <button
                    onClick={() => {
                      setModal({ ...modal, editSection: true });
                      setCurrentSectionId(item.sectionId);
                      setEditSection(item);
                    }}
                  >
                    <Edit2 size={16} color="#14A38B" />
                  </button>
                </Tooltip>
                <Tooltip className="tooltip" content={t("common:delete")}>
                  <button
                    onClick={() => {
                      setCurrentSectionId(item.sectionId);
                      setModal({ ...modal, confirm: true });
                    }}
                  >
                    <Trash size={16} color="#FF7171" />
                  </button>
                </Tooltip>
              </div>
            </div>
            <div className="school-admin__group-body">
              <div className="school-admin__group-list">
                {item?.sectionInstructorResponses?.length > 0 && (
                  <div className="school-admin__group-item">
                    <div>{t("schoolAdmin:insctructor")}</div>
                    <div>{t("schoolAdmin:number of students")}</div>
                    <div>{t("presetting:action presetting")}</div>
                  </div>
                )}
                {item?.sectionInstructorResponses?.map((instructor, idx2) => (
                  <div className="school-admin__group-item" key={idx2}>
                    <div>{instructor.personTitle}</div>
                    <div>{instructor.studentCount}</div>
                    <div>
                      <Link
                        to={`/school-admin/sections/${instructor.sectionInstructorId}`}
                      >
                        {t("schoolAdmin:detail")}
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ));

  const instructorsOptions = instructorsStore.map((item) => ({
    name: item.instructorTitle,
    value: item.instructorId,
    key: "personId",
  }));

  const onChangeTime = (values) => {
    let scheduleWeekTimeList = newInstructor?.scheduleWeekTimeList;
    const index = scheduleWeekTimeList?.findIndex((element) => {
      if (element.weekDay === values.weekDay) {
        return true;
      }
    });
    scheduleWeekTimeList[index] = values;
    setNewInstructor({
      ...newInstructor,
      scheduleWeekTimeList: scheduleWeekTimeList,
    });
  };

  return (
    <SchoolAdminLayout>
      <div className="school-admin__schedule-group school-admin__schedule school-presetting__body school-presetting__body-color sections">
        <div className="school-presetting__body-row">
          <div className="school-presetting__body-title">
            <h2>{t("schoolAdmin:sections")}</h2>
          </div>
          <div className="school-presetting__body-description">
            <p className="body-1">{t("schoolAdmin:sections description")}</p>
          </div>
        </div>
        <div className="school-presetting__search-filter">
          <div className="school-presetting__search">
            <SearchField
              onChange={searchInputHandler}
              defaultValue={searchText}
              placeholder={t("common:search")}
            />
          </div>
          <div className="school-presetting__filter">
            <button
              id="create_class"
              className="primary small"
              onClick={() => setModal({ ...modal, createSection: true })}
            >
              {t("schoolAdmin:create section")}
            </button>
          </div>
        </div>
        <div className="school-presetting__subjects-list">
          <div className="school-presetting__teachers-body">
            <div className="school-presetting__teachers-table">
              {/* {sectionsStore.length > 0 && (
                <div className="school-admin__group-item">
                  <div>{t("schoolAdmin:insctructor")}</div>
                  <div>{t("schoolAdmin:number of students")}</div>
                  <div>{t("presetting:action presetting")}</div>
                </div>
              )} */}
              {tableDataRender}
            </div>
          </div>
          <div className="pagination">
            <div className="paginaiton__inner">
              <div className="pagination__per-page">
                <p>
                  {t("presetting:showing presetting")}{" "}
                  <input
                    type="phone"
                    defaultValue={perPage}
                    onChange={getPerPage}
                  />{" "}
                  {t("presetting:from presetting")}{" "}
                  {searchedCurrentData.length > 0
                    ? searchedCurrentData.length
                    : sectionsStore.length}{" "}
                  {t("presetting:lines presetting")}
                </p>
              </div>
              <div className="pagination__page">
                <PaginationBlock
                  total={
                    searchedCurrentData.length > 0
                      ? searchedCurrentData.length
                      : sectionsStore.length
                  }
                  current={currentPage}
                  onChange={onChange}
                  pageSize={perPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modal.createSection}
        onRequestClose={() => {
          setModal({ ...modal, createSection: false });
          setNewGroup({});
        }}
        overlayClassName="modal-overlay"
        onAfterOpen={() => {
          // dispatch(getSectionById(current));
          // dispatch(getSectionsByClassId(new.currentSectionId));
        }}
      >
        <div className="modal">
          <div className="modal-close">
            <button
              onClick={() => {
                setModal({ ...modal, createSection: false });
                // setSearchText("");
                // setSelectedData([]);
                setNewGroup({});
              }}
            >
              <X size={15} color="#9A9A9A" />
            </button>
          </div>
          <div className="modal-header">
            <div className="modal-header-title">
              <h2 className="roboto">
                {t("schoolAdmin:create section title")}
              </h2>
            </div>
          </div>
          <div className="modal-body">
            <div className="modal-inputs-list">
              <div className="modal-input">
                <input
                  type="text"
                  placeholder={`${t("schoolAdmin:title")}`}
                  name="title"
                  defaultValue={newSection.title}
                  onChange={onInputChange}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="small secondary"
              onClick={() => {
                setModal({ ...modal, createSection: false });
                setNewGroup({});
              }}
            >
              {t("presetting:consel presetting")}
            </button>
            <button
              className="large primary"
              disabled={newSection.title?.length > 0 ? false : true}
              onClick={() => {
                dispatch(createSection(newSection));
                setNewGroup({});
                setModal({ ...modal, createSection: false });
              }}
            >
              {t("common:create")}
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modal.editSection}
        onRequestClose={() => {
          setModal({ ...modal, editSection: false });
          // setSearchText("");
          // setSelectedData([]);
          setEditSection({});
        }}
        overlayClassName="modal-overlay"
        onAfterOpen={async () => {
          // await dispatch(getAllStudentsByClass(editSection.currentSectionId));
          // await dispatch(getSectionById(editSection.groupId));
          // await setSelectedEditData(sectionsStore.studentList);
          // console.log(sectionsStore);
          // await setCurrentGroupId(editSection.groupId);
        }}
      >
        <div className="modal">
          <div className="modal-close">
            <button
              onClick={() => {
                setModal({ ...modal, editSection: false });
                // setSearchText("");
                // setSelectedData([]);
                setEditSection({});
                // setSelectedEditData([]);
              }}
            >
              <X size={15} color="#9A9A9A" />
            </button>
          </div>
          <div className="modal-header">
            <div className="modal-header-title">
              <h2 className="roboto">{t("schoolAdmin:edit section title")}</h2>
            </div>
          </div>
          <div className="modal-body">
            <div className="modal-inputs-list">
              <div className="modal-input">
                <input
                  type="text"
                  placeholder={t("schoolAdmin:title")}
                  name="title"
                  defaultValue={editSection?.sectionTitle}
                  onChange={onEditInputChange}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="small secondary"
              onClick={() => {
                setModal({ ...modal, editSection: false });
                // setSelectedData([]);
                setEditSection({});
              }}
            >
              {t("presetting:consel presetting")}
            </button>
            <button
              className="large primary"
              onClick={() => {
                dispatch(
                  editSectionAction({
                    title: editSection.title,
                    id: editSection.sectionId,
                  })
                );
                // setSelectedData([]);
                setEditSection({});
                setModal({ ...modal, editSection: false });
              }}
            >
              {t("common:save")}
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modal.addInstructor}
        onRequestClose={() => {
          setModal({ ...modal, addInstructor: false });
          setNewInstructor({});
        }}
        overlayClassName="modal-overlay"
        onAfterOpen={async () => {
          await dispatch(getInstructors());
          // dispatch(getSectionById(current));
          // dispatch(getSectionsByClassId(new.currentSectionId));
        }}
      >
        <div className="modal">
          <div className="modal-close">
            <button
              onClick={() => {
                setModal({ ...modal, addInstructor: false });
                // setSearchText("");
                // setSelectedData([]);
                setNewInstructor({});
              }}
            >
              <X size={15} color="#9A9A9A" />
            </button>
          </div>
          <div className="modal-header">
            <div className="modal-header-title">
              <h2 className="roboto">{t("schoolAdmin:section")}</h2>
            </div>
          </div>
          <div className="modal-body">
            <div className="modal-inputs-list">
              <div className="modal-input">
                <SelectSearch
                  options={instructorsOptions}
                  search
                  className="select-search"
                  filterOptions={fuzzySearch}
                  name="instructorId"
                  value={newInstructor?.instructorId}
                  onChange={(_, k) => {
                    setNewInstructor({
                      ...newInstructor,
                      instructorId: k.value,
                    });
                  }}
                  required
                  placeholder={`${t("schoolAdmin:insctructor")}`}
                />
              </div>
              <p>{t("schoolAdmin:graphic of lessons")}:</p>
              <div className="modal-input">
                <div className="days_buttons">
                  {days
                    .slice(
                      [0],
                      [JSON.parse(localStorage.getItem("school"))?.studyDay]
                    )
                    .map((day, idx) => (
                      <button
                        key={idx}
                        name={day.en}
                        className={
                          newInstructor?.scheduleWeekTimeList?.find(
                            (item) => item.weekDay === day.en
                          )
                            ? "primary"
                            : "secondary"
                        }
                        onClick={(e) => {
                          newInstructor?.scheduleWeekTimeList?.find(
                            (item) => item.weekDay === e.target.name
                          )
                            ? setNewInstructor({
                                ...newInstructor,
                                scheduleWeekTimeList:
                                  newInstructor?.scheduleWeekTimeList?.filter(
                                    (z) => z.weekDay !== e.target.name
                                  ),
                              })
                            : setNewInstructor({
                                ...newInstructor,
                                scheduleWeekTimeList:
                                  newInstructor?.scheduleWeekTimeList?.length >
                                  0
                                    ? [
                                        ...newInstructor.scheduleWeekTimeList,
                                        {
                                          endTime: "12:45",
                                          startTime: "12:00",
                                          weekDay: e.target.name,
                                        },
                                      ]
                                    : [
                                        {
                                          endTime: "12:45",
                                          startTime: "12:00",
                                          weekDay: e.target.name,
                                        },
                                      ],
                              });
                        }}
                      >
                        {day[`${i18n.language}Short`]}
                      </button>
                    ))}
                </div>
              </div>
              <div className="d-flex">
                <p>{t("schoolAdmin:same time to all selected days")} </p>
                <input
                  type="checkBox"
                  onChange={() => {
                    newInstructor?.isSameTime
                      ? setNewInstructor({
                          ...newInstructor,
                          isSameTime: false,
                        })
                      : setNewInstructor({
                          ...newInstructor,
                          isSameTime: true,
                          sameTimeStart: "12:00",
                          sameTimeEnd: "12:45",
                        });
                  }}
                  checked={newInstructor?.isSameTime}
                />
              </div>
              {(newInstructor?.scheduleWeekTimeList?.length > 0 ||
                newInstructor?.isSameTime) && (
                <div className="modal-input">
                  <div className="d-flex section-time">
                    <p>{t("schoolAdmin:day")}</p>
                    <p>{t("schoolAdmin:start")}</p>
                    <p>{t("schoolAdmin:end")}</p>
                  </div>
                </div>
              )}
              {newInstructor?.isSameTime && (
                <div className="modal-input">
                  <div className="d-flex section-time">
                    <p>{t("schoolAdmin:all")}</p>
                    <TimePickerCalendar
                      defaultValue={`${newInstructor.sameTimeStart}`}
                      onSelect={(e) =>
                        setNewInstructor({
                          ...newInstructor,
                          sameTimeStart: String(e),
                        })
                      }
                    />
                    <TimePickerCalendar
                      defaultValue={`${newInstructor.sameTimeEnd}`}
                      onSelect={(e) =>
                        setNewInstructor({
                          ...newInstructor,
                          sameTimeEnd: String(e),
                        })
                      }
                    />
                  </div>
                </div>
              )}
              {!newInstructor?.isSameTime &&
                newInstructor?.scheduleWeekTimeList?.map((item, idx) => (
                  <div className="modal-input" key={idx}>
                    <div className="d-flex section-time">
                      {
                        days.find((day) => day.en === item.weekDay)[
                          `${i18n.language}Short`
                        ]
                      }
                      <TimePickerCalendar
                        defaultValue={`${item.startTime}`}
                        onSelect={(e) =>
                          onChangeTime({
                            ...item,
                            startTime: String(e),
                          })
                        }
                      />
                      <TimePickerCalendar
                        defaultValue={`${item.endTime}`}
                        onSelect={(e) =>
                          onChangeTime({
                            ...item,
                            // endTime: moment(`${lesson.endTime}`, "H:mm").format(
                            //   "HH:mm"
                            // ),
                            endTime: String(e),
                          })
                        }
                      />
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="small secondary"
              onClick={() => {
                setModal({ ...modal, addInstructor: false });
                setNewInstructor({});
              }}
            >
              {t("presetting:consel presetting")}
            </button>
            <button
              className="large primary"
              disabled={
                !(
                  newInstructor?.instructorId &&
                  newInstructor?.scheduleWeekTimeList?.length > 0
                )
              }
              onClick={() => {
                dispatch(
                  createSectionInstructor({
                    ...newInstructor,
                    sectionId: currentSectionId,
                  })
                );
                setNewInstructor({});
                setModal({ ...modal, addInstructor: false });
              }}
            >
              {t("common:save")}
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modal.confirm}
        onRequestClose={() => {
          setModal({ ...modal, confirm: false });
        }}
        overlayClassName="modal-overlay"
      >
        <div className="modal">
          <div className="modal-close">
            <button
              onClick={() => {
                setModal({ ...modal, confirm: false });
              }}
            >
              <X size={15} color="#9A9A9A" />
            </button>
          </div>
          <div className="modal-header">
            <div className="modal-header-title">
              <h2>{t("presetting:are u shure presetting")}</h2>
            </div>
            <div className="modal-header-description">
              <p className="body-2">{t("schoolAdmin:delete warning")}</p>
            </div>
          </div>

          <div className="modal-footer" style={{ marginTop: "24px" }}>
            <button
              className="large secondary"
              onClick={() => setModal({ ...modal, confirm: false })}
            >
              {t("common:cancel")}
            </button>
            <button
              className="large primary"
              onClick={async () => {
                await removeItem(currentSectionId);
                await setModal({ ...modal, confirm: false });
                await setCurrentSectionId();
              }}
            >
              {t("common:delete")}
            </button>
          </div>
        </div>
      </Modal>
    </SchoolAdminLayout>
  );
}
