import React from "react";
import PT from "prop-types";

import EmptyDataImg from "../../assets/images/empty-data.png";
import "./NoData.scss";

export default function NoData(props) {
  const { messages } = props;

  return (
    <div className="no-data">
      <div className="no-data-title">
        {messages?.map((item, idx) => (
          <p key={idx}>{item}</p>
        ))}
      </div>
      <div className="no-data-img">
        <img src={EmptyDataImg} alt="no data" />
      </div>
    </div>
  );
}

NoData.propTypes = {
  messages: PT.array,
};
