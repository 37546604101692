/* eslint-disable indent */
import { urls, toastOption } from "../../config";
import { API } from "../../utils";
import { toast } from "react-toastify";
import i18n from "../../i18n";

import {
  GET_INSTRUCTOR_REQUEST,
  GET_INSTRUCTOR_SUCCESS,
  GET_INSTRUCTOR_ERROR,
  GET_INSTRUCTOR_COURSE_REQUEST,
  GET_INSTRUCTOR_COURSE_SUCCESS,
  GET_INSTRUCTOR_COURSE_ERROR,
  GET_INSTRUCTOR_GRADE_MARK_REQUEST,
  GET_INSTRUCTOR_GRADE_MARK_SUCCESS,
  GET_INSTRUCTOR_GRADE_MARK_ERROR,
  GET_INSTRUCTOR_GRADE_TOPIC_MARK_REQUEST,
  GET_INSTRUCTOR_GRADE_TOPIC_MARK_SUCCESS,
  GET_INSTRUCTOR_GRADE_TOPIC_MARK_ERROR,
  GET_INSTRUCTOR_GRADE_HW_MARK_REQUEST,
  GET_INSTRUCTOR_GRADE_HW_MARK_SUCCESS,
  GET_INSTRUCTOR_GRADE_HW_MARK_ERROR,
  GET_INSTRUCTOR_GRADE_GRADE_SHEET_BY_COURSES_REQUEST,
  GET_INSTRUCTOR_GRADE_GRADE_SHEET_BY_COURSES_SUCCESS,
  GET_INSTRUCTOR_GRADE_GRADE_SHEET_BY_COURSES_ERROR,
  GET_INSTRUCTOR_COURSES_REQUEST,
  GET_INSTRUCTOR_COURSES_SUCCESS,
  GET_INSTRUCTOR_COURSES_ERROR,
  GET_CLASS_COURSES_REQUEST,
  GET_CLASS_COURSES_SUCCESS,
  GET_CLASS_COURSES_ERROR,
} from "../actionTypes";

export const getInstructors =
  (archived = false) =>
  async (dispatch) => {
    dispatch(getInstructorsRequest());

    await API.get(
      `${urls.INSTRUCTOR}school/${
        JSON.parse(localStorage.getItem("school"))?.id
      }?archived=${archived}`
    )
      .then((res) => {
        console.log(res.data);
        const a = res.data?.map((item) => ({
          ...item,
          title: item.courseTitle,
        }));

        dispatch(getInstructorsSuccess(a));
        return a;
      })
      .catch((error) => {
        console.log(error);
        toast.error(i18n.t("toastify:error"), toastOption);
        dispatch(getInstructorsFailure(error.message));
        return error.message;
      });
  };

export const getInstructorCourses = (instructorId) => async (dispatch) => {
  dispatch(getCoursesRequest());

  await API.get(
    `${urls.PERSON}course/person/${instructorId}?schoolId=${
      JSON.parse(localStorage.getItem("school"))?.id
    }`
  )
    .then((res) => {
      console.log(res.data);

      dispatch(getCoursesSuccess(res.data));
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getCoursesFailure(error.message));
      return error.message;
    });
};
// !

export const getInstructorInstarctorId = (setState) => async () => {
  const info = JSON.parse(localStorage.getItem("user"));
  // dispatch(getCoursesRequest());
  await API.get(`${urls.PERSON}user/${info?.user.id}`)
    .then((res) => {
      // console.log(res.data);
      // dispatch(getCoursesSuccess(res.data));
      API.get(`${urls.SCHEDULE}instructor/${res.data.id}`)
        .then((res) => {
          setState(res.data);
          // console.log(res.data);
          // setScheduleCourses(res.data);
          // dispatch(getCoursesSuccess(res.data));
          return res.data;
        })
        .catch((error) => {
          console.log(error);
          // toast.error(i18n.t("toastify:error"), toastOption);
          // dispatch(getCoursesFailure(error.message));
          return error.message;
        });
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      // toast.error(i18n.t("toastify:error"), toastOption);
      // dispatch(getCoursesFailure(error.message));
      return error.message;
    });
};
// !

export const addCourse = (instructorId, courseId) => async () => {
  const data = {
    personId: Number(instructorId),
    courseId: Number(courseId),
  };
  await API.post(`${urls.PERSON}course/create`, data)
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess added"), toastOption);
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      return error.message;
    });
};

export const removeCourse = (values) => async (dispatch) => {
  dispatch(getCoursesRequest());
  const data = values;
  await API.post(`${urls.PERSON}course/remove`, data)
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess deleted"), toastOption);
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getCoursesFailure(error.message));
      return error.message;
    });
};

export const getInstructorsRequest = () => {
  return {
    type: GET_INSTRUCTOR_REQUEST,
  };
};

export const getInstructorsSuccess = (data) => {
  return {
    type: GET_INSTRUCTOR_SUCCESS,
    payload: data,
  };
};

export const getInstructorsFailure = (error) => {
  return {
    type: GET_INSTRUCTOR_ERROR,
    payload: error,
  };
};

export const getCoursesRequest = () => {
  return {
    type: GET_INSTRUCTOR_COURSE_REQUEST,
  };
};

export const getCoursesSuccess = (data) => {
  return {
    type: GET_INSTRUCTOR_COURSE_SUCCESS,
    payload: data,
  };
};

export const getCoursesFailure = (error) => {
  return {
    type: GET_INSTRUCTOR_COURSE_ERROR,
    payload: error,
  };
};

export const getkInstructorsMark =
  (instructorId, startDate, endDate, classId) => async (dispatch) => {
    dispatch(getInstructorsMarkRequest());
    await API.get(
      `${urls.TEACHERS_GRADE_MARK}/${instructorId}/class/${classId}?endDate=${endDate}&startDate=${startDate}`
    )
      .then((res) => {
        dispatch(getInstructorsMarkSuccess(res.data));
        return res.data;
      })
      .catch((error) => {
        dispatch(getInstructorsMarkFailure(error.message));
        return error.message;
      });
  };

export const getInstructorsMarkRequest = () => {
  return {
    type: GET_INSTRUCTOR_GRADE_MARK_REQUEST,
  };
};

export const getInstructorsMarkSuccess = (data) => {
  return {
    type: GET_INSTRUCTOR_GRADE_MARK_SUCCESS,
    payload: data,
  };
};

export const getInstructorsMarkFailure = (error) => {
  return {
    type: GET_INSTRUCTOR_GRADE_MARK_ERROR,
    payload: error,
  };
};

export const getkInstructorsTopicMark =
  (instructorId, classId) => async (dispatch) => {
    dispatch(getInstructorsTopicMarkRequest());
    await API.get(
      `${urls.TEACHERS_TOPIC_GRADE_MARK}/${instructorId}/class/${classId}`
    )
      .then((res) => {
        dispatch(getInstructorsTopicMarkSuccess(res.data));
        console.log(res.data);
        return res.data;
      })
      .catch((error) => {
        dispatch(getInstructorsTopicMarkFailure(error.message));
        return error.message;
      });
  };

export const getInstructorsTopicMarkRequest = () => {
  return {
    type: GET_INSTRUCTOR_GRADE_TOPIC_MARK_REQUEST,
  };
};

export const getInstructorsTopicMarkSuccess = (data) => {
  return {
    type: GET_INSTRUCTOR_GRADE_TOPIC_MARK_SUCCESS,
    payload: data,
  };
};

export const getInstructorsTopicMarkFailure = (error) => {
  return {
    type: GET_INSTRUCTOR_GRADE_TOPIC_MARK_ERROR,
    payload: error,
  };
};

export const getkInstructorsHWMark =
  (instructorId, classId) => async (dispatch) => {
    dispatch(getInstructorsHWMarkRequest());
    await API.get(
      `${urls.TEACHERS_HW_GRADE_MARK}/${instructorId}/class/${classId}`
    )
      .then((res) => {
        dispatch(getInstructorsHWMarkSuccess(res.data));
        console.log(res.data);
        return res.data;
      })
      .catch((error) => {
        dispatch(getInstructorsHWMarkFailure(error.message));
        return error.message;
      });
  };

export const getInstructorsHWMarkRequest = () => {
  return {
    type: GET_INSTRUCTOR_GRADE_HW_MARK_REQUEST,
  };
};

export const getInstructorsHWMarkSuccess = (data) => {
  return {
    type: GET_INSTRUCTOR_GRADE_HW_MARK_SUCCESS,
    payload: data,
  };
};

export const getInstructorsHWMarkFailure = (error) => {
  return {
    type: GET_INSTRUCTOR_GRADE_HW_MARK_ERROR,
    payload: error,
  };
};

export const getkInstructorsGradeSheetByCourses =
  (classId) => async (dispatch) => {
    dispatch(getInstructorsGradeSheetByCoursesRequest());
    await API.get(
      `${urls.QUARTER_GRADE_ANALYTIC}grade-sheet?chronicleId=${
        JSON.parse(localStorage.getItem("chronicleActive")).id
      }&classId=${classId}`
    )
      .then((res) => {
        dispatch(getInstructorsGradeSheetByCoursesSuccess(res.data));
        console.log(res.data);
        return res.data;
      })
      .catch((error) => {
        dispatch(getInstructorsGradeSheetByCoursesFailure(error.message));
        return error.message;
      });
  };

export const getInstructorsGradeSheetByCoursesRequest = () => {
  return {
    type: GET_INSTRUCTOR_GRADE_GRADE_SHEET_BY_COURSES_REQUEST,
  };
};

export const getInstructorsGradeSheetByCoursesSuccess = (data) => {
  return {
    type: GET_INSTRUCTOR_GRADE_GRADE_SHEET_BY_COURSES_SUCCESS,
    payload: data,
  };
};

export const getInstructorsGradeSheetByCoursesFailure = (error) => {
  return {
    type: GET_INSTRUCTOR_GRADE_GRADE_SHEET_BY_COURSES_ERROR,
    payload: error,
  };
};

export const getkInstructorCourses = (chronicleId) => async (dispatch) => {
  dispatch(getkInstructorCoursesRequest());
  await API.get(`${urls.INSTRUCTOR_COURSES}/${chronicleId}`)
    .then((res) => {
      dispatch(getkInstructorCoursesSuccess(res.data));
      console.log(res.data);
      return res.data;
    })
    .catch((error) => {
      dispatch(getkInstructorCoursesFailure(error.message));
      return error.message;
    });
};

export const getkInstructorCoursesRequest = () => {
  return {
    type: GET_INSTRUCTOR_COURSES_REQUEST,
  };
};

export const getkInstructorCoursesSuccess = (data) => {
  return {
    type: GET_INSTRUCTOR_COURSES_SUCCESS,
    payload: data,
  };
};

export const getkInstructorCoursesFailure = (error) => {
  return {
    type: GET_INSTRUCTOR_COURSES_ERROR,
    payload: error,
  };
};

export const getkClassCourses = (classId) => async (dispatch) => {
  dispatch(getkClassCoursesRequest());
  await API.get(`${urls.CLASS_COURSES}/${classId}`)
    .then((res) => {
      dispatch(getkClassCoursesSuccess(res.data));
      console.log(res.data);
      return res.data;
    })
    .catch((error) => {
      dispatch(getkClassCoursesFailure(error.message));
      return error.message;
    });
};

export const getkClassCoursesRequest = () => {
  return {
    type: GET_CLASS_COURSES_REQUEST,
  };
};

export const getkClassCoursesSuccess = (data) => {
  return {
    type: GET_CLASS_COURSES_SUCCESS,
    payload: data,
  };
};

export const getkClassCoursesFailure = (error) => {
  return {
    type: GET_CLASS_COURSES_ERROR,
    payload: error,
  };
};
