import React from "react";
import PT from "prop-types";
import { FileText } from "react-feather";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function index({ date }) {
  const { t } = useTranslation();
  console.log(date?.map((item) => console.log(item.active)));

  const card = date?.map((item, idx) => (
    <Link
      to={item?.url}
      key={(item, idx)}
      className={`reports ${item?.active}`}
    >
      <FileText className="img" />
      <span className="title">{item.title}</span>
      <div className="button">{t("common:open")}</div>
    </Link>
  ));

  return card;
}

index.propTypes = {
  date: PT.any,
};

export default index;
