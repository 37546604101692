import {
  GET_REGIONS_REQUEST,
  GET_REGIONS_SUCCESS,
  GET_REGIONS_ERROR,
} from "../actionTypes";
import { statuses } from "../../config";

const initialState = {
  regions: [],
  error: "",
  status: statuses.INITIAL,
};

export const regionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REGIONS_REQUEST:
      return {
        ...state,
        regions: [],
        error: "",
        status: statuses.LOADING,
      };
    case GET_REGIONS_SUCCESS:
      return {
        ...state,
        regions: action.payload,
        error: "",
        status: statuses.SUCCESS,
      };
    case GET_REGIONS_ERROR:
      return {
        ...state,
        regions: [],
        error: action.payload,
        status: statuses.ERROR,
      };

    default:
      return state;
  }
};
