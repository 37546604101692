import { urls, toastOption } from "../../config";
import { API } from "../../utils";
import { toast } from "react-toastify";
import i18n from "../../i18n";

import {
  GET_SHIFTS_TIMES_REQUEST,
  GET_SHIFTS_TIMES_SUCCESS,
  GET_SHIFTS_TIMES_ERROR,
} from "../actionTypes";

export const getShiftTimes = () => async (dispatch) => {
  dispatch(getShiftTimesRequest());

  await API.get(
    `${urls.SHIFT_TIME}school/${JSON.parse(localStorage.getItem("school"))?.id}`
  )
    .then((res) => {
      console.log(res.data);
      let data;
      let data1 = res.data.filter((item) => item.shiftType === 0);
      let data2 = res.data.filter((item) => item.shiftType !== 0);
      data = [...data1, ...data2];
      console.log(data);
      dispatch(getShiftTimesSuccess(data));
      return data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getShiftTimesFailure(error.message));
      return error.message;
    });
};

export const createShiftTime = (values) => async (dispatch) => {
  const data = values;
  console.log(values);
  await API.post(`${urls.SHIFT_TIME}create`, data)
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess added"), toastOption);
      dispatch(getShiftTimes());
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      return error.message;
    });
};
export const createMassiveShiftTime = (values) => async (dispatch) => {
  const data = values;

  await API.post(`${urls.SHIFT_TIME}bulk-operation`, data)
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess added"), toastOption);
      dispatch(getShiftTimes());
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      return error.message;
    });
};

export const removeShiftTime = (id) => async (dispatch) => {
  dispatch(getShiftTimesRequest());
  await API.delete(`${urls.SHIFT_TIME}?id=${id}`)
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess deleted"), toastOption);
      dispatch(getShiftTimes());
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getShiftTimesFailure(error.message));
      return error.message;
    });
};
export const editShiftTime = (values) => async (dispatch) => {
  dispatch(getShiftTimesRequest());
  const data = values;
  await API.post(`${urls.SHIFT_TIME}edit/${values.id}`, data)
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess updated"), toastOption);
      dispatch(getShiftTimes());
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getShiftTimesFailure(error.message));
      return error.message;
    });
};
export const editQuartersMassive = (values) => async (dispatch) => {
  dispatch(getShiftTimesRequest());
  const data = {
    quarterDtoList: values,
  };
  await API.post(`${urls.QUARTER}update-massive`, data)
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess updated"), toastOption);
      dispatch(getShiftTimes());
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getShiftTimesFailure(error.message));
      return error.message;
    });
};

export const getShiftTimesRequest = () => {
  return {
    type: GET_SHIFTS_TIMES_REQUEST,
  };
};

export const getShiftTimesSuccess = (data) => {
  return {
    type: GET_SHIFTS_TIMES_SUCCESS,
    payload: data,
  };
};

export const getShiftTimesFailure = (error) => {
  return {
    type: GET_SHIFTS_TIMES_ERROR,
    payload: error,
  };
};
