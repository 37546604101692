import React, { PureComponent } from "react";

export default class Logo extends PureComponent {
  render() {
    return (
      <svg
        width="64"
        height="59"
        viewBox="0 0 64 59"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M27.2486 2.81697L11.6191 0V42.1085L27.2486 39.2282L15.2853 36.8309V5.40059L27.2486 2.81697Z"
          fill="#3C3C3C"
        />
        <path
          d="M17.8313 6.87089L50.8578 0V42.2526L36.5731 39.2371L27.4632 43.5368L31.2163 38.2045L17.8321 35.3122L17.8313 6.87089Z"
          fill="#F0783A"
        />
        <path
          d="M27.4346 17.207L34.164 11.3003L40.2387 7.80322L29.9513 19.1629L36.0167 31.045L41.6254 31.4263L26.7327 25.8029L27.4346 17.207Z"
          fill="white"
        />
        <path
          d="M24.0199 14.0293L25.6356 21.1829L23.4358 34.0455L29.7433 19.3411L24.0199 14.0293Z"
          fill="white"
        />
        <path
          d="M0.361158 58.7545C0.264849 58.7545 0.178172 58.7207 0.101124 58.6533C0.0337081 58.5763 0 58.4896 0 58.3933V49.0032C0 48.8972 0.0337081 48.8106 0.101124 48.7431C0.178172 48.6757 0.264849 48.642 0.361158 48.642H2.1814C2.28734 48.642 2.37401 48.6757 2.44143 48.7431C2.50885 48.8106 2.54256 48.8972 2.54256 49.0032V52.2103L5.21513 48.9165C5.3307 48.7335 5.5185 48.642 5.77853 48.642H7.85881C7.94548 48.642 8.01772 48.6757 8.0755 48.7431C8.13329 48.8009 8.16218 48.8683 8.16218 48.9454C8.16218 49.0128 8.14773 49.0706 8.11884 49.1188L4.65172 53.4815L8.37887 58.2777C8.4174 58.3163 8.43666 58.374 8.43666 58.4511C8.43666 58.5281 8.40777 58.6004 8.34998 58.6678C8.2922 58.7256 8.21996 58.7545 8.13329 58.7545H5.98078C5.74964 58.7545 5.55702 58.6581 5.40293 58.4655L2.54256 54.8973V58.3933C2.54256 58.4992 2.50885 58.5859 2.44143 58.6533C2.37401 58.7207 2.28734 58.7545 2.1814 58.7545H0.361158Z"
          fill="black"
        />
        <path
          d="M11.464 58.8989C10.6164 58.8989 9.93264 58.6148 9.41258 58.0466C8.89251 57.4687 8.63247 56.6646 8.63247 55.634V51.6035C8.63247 51.4976 8.66618 51.4109 8.7336 51.3435C8.80102 51.2761 8.88769 51.2424 8.99363 51.2424H10.7994C10.9054 51.2424 10.992 51.2761 11.0595 51.3435C11.1365 51.4109 11.175 51.4976 11.175 51.6035V55.5474C11.175 56.4912 11.594 56.9631 12.4319 56.9631C12.8364 56.9631 13.1542 56.8379 13.3853 56.5875C13.6165 56.3371 13.732 55.9904 13.732 55.5474V51.6035C13.732 51.4976 13.7657 51.4109 13.8332 51.3435C13.9102 51.2761 13.9969 51.2424 14.0932 51.2424H15.899C16.0049 51.2424 16.0916 51.2761 16.159 51.3435C16.2264 51.4109 16.2601 51.4976 16.2601 51.6035V58.3933C16.2601 58.4896 16.2264 58.5763 16.159 58.6533C16.0916 58.7207 16.0049 58.7545 15.899 58.7545H14.2232C14.1269 58.7545 14.0402 58.7207 13.9632 58.6533C13.8958 58.5763 13.862 58.4896 13.862 58.3933V57.8443C13.3612 58.5474 12.5619 58.8989 11.464 58.8989Z"
          fill="black"
        />
        <path
          d="M17.8872 58.7545C17.7812 58.7545 17.6945 58.7207 17.6271 58.6533C17.5597 58.5859 17.526 58.4992 17.526 58.3933V51.6035C17.526 51.4976 17.5597 51.4109 17.6271 51.3435C17.6945 51.2761 17.7812 51.2424 17.8872 51.2424H19.5485C19.6544 51.2424 19.7411 51.2761 19.8085 51.3435C19.8856 51.4109 19.9241 51.4976 19.9241 51.6035V52.1525C20.1841 51.8347 20.5164 51.5794 20.9209 51.3868C21.335 51.1942 21.8021 51.0979 22.3222 51.0979C23.1697 51.0979 23.8583 51.3868 24.388 51.9647C24.9177 52.5329 25.1826 53.3323 25.1826 54.3628V58.3933C25.1826 58.4896 25.1488 58.5763 25.0814 58.6533C25.014 58.7207 24.9273 58.7545 24.8214 58.7545H23.0012C22.9049 58.7545 22.8182 58.7207 22.7411 58.6533C22.6737 58.5763 22.64 58.4896 22.64 58.3933V54.4494C22.64 53.9968 22.5292 53.6501 22.3077 53.4093C22.0862 53.1589 21.7684 53.0337 21.3543 53.0337C20.9498 53.0337 20.632 53.1589 20.4008 53.4093C20.1697 53.6501 20.0541 53.9968 20.0541 54.4494V58.3933C20.0541 58.4896 20.0204 58.5763 19.953 58.6533C19.8856 58.7207 19.7989 58.7545 19.693 58.7545H17.8872Z"
          fill="black"
        />
        <path
          d="M29.1781 58.8989C28.2343 58.8989 27.4975 58.6052 26.9678 58.0177C26.4477 57.4206 26.1636 56.5827 26.1155 55.504L26.101 54.9984L26.1155 54.4783C26.154 53.4382 26.4381 52.6148 26.9678 52.008C27.5071 51.4013 28.2439 51.0979 29.1781 51.0979C30.0545 51.0979 30.7624 51.382 31.3017 51.9502V48.8587C31.3017 48.7528 31.3354 48.6661 31.4028 48.5987C31.4702 48.5313 31.5569 48.4976 31.6629 48.4976H33.4253C33.5313 48.4976 33.6179 48.5313 33.6854 48.5987C33.7528 48.6661 33.7865 48.7528 33.7865 48.8587V58.3933C33.7865 58.4896 33.7528 58.5763 33.6854 58.6533C33.6179 58.7207 33.5313 58.7545 33.4253 58.7545H31.7929C31.6966 58.7545 31.6099 58.7207 31.5329 58.6533C31.4654 58.5763 31.4317 58.4896 31.4317 58.3933V57.931C30.902 58.5763 30.1508 58.8989 29.1781 58.8989ZM29.9726 56.9631C30.406 56.9631 30.7287 56.8283 30.9406 56.5586C31.1524 56.2793 31.2728 55.923 31.3017 55.4896C31.3113 55.3644 31.3162 55.1814 31.3162 54.9406C31.3162 54.7095 31.3113 54.5313 31.3017 54.4061C31.2824 54.0112 31.1621 53.6838 30.9406 53.4238C30.719 53.1637 30.3964 53.0337 29.9726 53.0337C29.52 53.0337 29.1925 53.1685 28.9903 53.4382C28.7977 53.7079 28.6869 54.0787 28.658 54.5506C28.6484 54.6469 28.6436 54.7962 28.6436 54.9984C28.6436 55.2007 28.6484 55.3499 28.658 55.4462C28.6869 55.9182 28.7977 56.2889 28.9903 56.5586C29.1925 56.8283 29.52 56.9631 29.9726 56.9631Z"
          fill="black"
        />
        <path
          d="M38.6095 58.8989C37.4153 58.8989 36.4955 58.6148 35.8503 58.0466C35.205 57.4784 34.8535 56.679 34.7957 55.6485C34.7861 55.5233 34.7812 55.3066 34.7812 54.9984C34.7812 54.6902 34.7861 54.4735 34.7957 54.3483C34.8535 53.3275 35.2146 52.5329 35.8792 51.9647C36.5437 51.3868 37.4538 51.0979 38.6095 51.0979C39.7749 51.0979 40.6898 51.3868 41.3543 51.9647C42.0189 52.5329 42.38 53.3275 42.4378 54.3483C42.4474 54.4735 42.4522 54.6902 42.4522 54.9984C42.4522 55.3066 42.4474 55.5233 42.4378 55.6485C42.38 56.679 42.0285 57.4784 41.3832 58.0466C40.7379 58.6148 39.8134 58.8989 38.6095 58.8989ZM38.6095 57.1365C39.0333 57.1365 39.3463 57.0113 39.5485 56.7609C39.7508 56.5008 39.8664 56.106 39.8952 55.5763C39.9049 55.48 39.9097 55.2873 39.9097 54.9984C39.9097 54.7095 39.9049 54.5169 39.8952 54.4206C39.8664 53.9005 39.746 53.5104 39.5341 53.2504C39.3318 52.9904 39.0237 52.8604 38.6095 52.8604C37.8102 52.8604 37.3864 53.3804 37.3382 54.4206L37.3238 54.9984L37.3382 55.5763C37.3575 56.106 37.4683 56.5008 37.6705 56.7609C37.8824 57.0113 38.1954 57.1365 38.6095 57.1365Z"
          fill="black"
        />
        <path
          d="M43.8149 58.7545C43.7186 58.7545 43.632 58.7207 43.5549 58.6533C43.4875 58.5763 43.4538 58.4896 43.4538 58.3933V48.8587C43.4538 48.7528 43.4875 48.6661 43.5549 48.5987C43.632 48.5313 43.7186 48.4976 43.8149 48.4976H45.5196C45.6256 48.4976 45.7122 48.5313 45.7796 48.5987C45.8471 48.6661 45.8808 48.7528 45.8808 48.8587V58.3933C45.8808 58.4992 45.8471 58.5859 45.7796 58.6533C45.7122 58.7207 45.6256 58.7545 45.5196 58.7545H43.8149Z"
          fill="black"
        />
        <path
          d="M49.9132 58.8989C49.0657 58.8989 48.3819 58.6148 47.8618 58.0466C47.3418 57.4687 47.0817 56.6646 47.0817 55.634V51.6035C47.0817 51.4976 47.1154 51.4109 47.1829 51.3435C47.2503 51.2761 47.337 51.2424 47.4429 51.2424H49.2487C49.3546 51.2424 49.4413 51.2761 49.5087 51.3435C49.5858 51.4109 49.6243 51.4976 49.6243 51.6035V55.5474C49.6243 56.4912 50.0432 56.9631 50.8811 56.9631C51.2856 56.9631 51.6034 56.8379 51.8346 56.5875C52.0657 56.3371 52.1813 55.9904 52.1813 55.5474V51.6035C52.1813 51.4976 52.215 51.4109 52.2824 51.3435C52.3595 51.2761 52.4461 51.2424 52.5424 51.2424H54.3482C54.4542 51.2424 54.5409 51.2761 54.6083 51.3435C54.6757 51.4109 54.7094 51.4976 54.7094 51.6035V58.3933C54.7094 58.4896 54.6757 58.5763 54.6083 58.6533C54.5409 58.7207 54.4542 58.7545 54.3482 58.7545H52.6725C52.5762 58.7545 52.4895 58.7207 52.4124 58.6533C52.345 58.5763 52.3113 58.4896 52.3113 58.3933V57.8443C51.8105 58.5474 51.0111 58.8989 49.9132 58.8989Z"
          fill="black"
        />
        <path
          d="M56.3364 58.7545C56.2401 58.7545 56.1534 58.7207 56.0764 58.6533C56.009 58.5763 55.9753 58.4896 55.9753 58.3933V48.8587C55.9753 48.7528 56.009 48.6661 56.0764 48.5987C56.1534 48.5313 56.2401 48.4976 56.3364 48.4976H58.0122C58.1181 48.4976 58.2048 48.5313 58.2722 48.5987C58.3396 48.6661 58.3734 48.7528 58.3734 48.8587V53.6982L60.3814 51.488C60.4007 51.4687 60.4344 51.435 60.4825 51.3868C60.5403 51.3387 60.5981 51.305 60.6559 51.2857C60.7137 51.2568 60.7811 51.2424 60.8581 51.2424H62.7939C62.8806 51.2424 62.9528 51.2761 63.0106 51.3435C63.078 51.4013 63.1118 51.4735 63.1118 51.5602C63.1118 51.6661 63.0732 51.748 62.9962 51.8058L60.3958 54.6084L63.3573 58.2055C63.4344 58.2825 63.4729 58.3548 63.4729 58.4222C63.4729 58.5185 63.4392 58.6004 63.3718 58.6678C63.314 58.7256 63.237 58.7545 63.1406 58.7545H61.1615C61.0363 58.7545 60.94 58.7352 60.8726 58.6967C60.8148 58.6581 60.7426 58.5955 60.6559 58.5089L58.3734 55.7641V58.3933C58.3734 58.4896 58.3396 58.5763 58.2722 58.6533C58.2048 58.7207 58.1181 58.7545 58.0122 58.7545H56.3364Z"
          fill="black"
        />
        <path
          d="M0.489088 58.8053C0.392779 58.8053 0.306101 58.7716 0.229054 58.7042C0.161638 58.6271 0.12793 58.5405 0.12793 58.4441V49.054C0.12793 48.9481 0.161638 48.8614 0.229054 48.794C0.306101 48.7266 0.392779 48.6929 0.489088 48.6929H2.30933C2.41527 48.6929 2.50194 48.7266 2.56936 48.794C2.63678 48.8614 2.67049 48.9481 2.67049 49.054V52.2611L5.34306 48.9674C5.45863 48.7844 5.64643 48.6929 5.90646 48.6929H7.98674C8.07342 48.6929 8.14565 48.7266 8.20343 48.794C8.26122 48.8518 8.29011 48.9192 8.29011 48.9962C8.29011 49.0637 8.27566 49.1214 8.24677 49.1696L4.77965 53.5324L8.50681 58.3286C8.54533 58.3671 8.56459 58.4249 8.56459 58.5019C8.56459 58.579 8.5357 58.6512 8.47791 58.7186C8.42013 58.7764 8.3479 58.8053 8.26122 58.8053H6.10871C5.87757 58.8053 5.68495 58.709 5.53086 58.5164L2.67049 54.9481V58.4441C2.67049 58.5501 2.63678 58.6368 2.56936 58.7042C2.50194 58.7716 2.41527 58.8053 2.30933 58.8053H0.489088Z"
          fill="#F0783A"
        />
        <path
          d="M11.5919 58.9498C10.7444 58.9498 10.0606 58.6657 9.54051 58.0974C9.02044 57.5196 8.76041 56.7154 8.76041 55.6849V51.6544C8.76041 51.5484 8.79411 51.4618 8.86153 51.3943C8.92895 51.3269 9.01562 51.2932 9.12156 51.2932H10.9274C11.0333 51.2932 11.12 51.3269 11.1874 51.3943C11.2644 51.4618 11.303 51.5484 11.303 51.6544V55.5982C11.303 56.542 11.7219 57.014 12.5598 57.014C12.9643 57.014 13.2821 56.8888 13.5133 56.6384C13.7444 56.388 13.86 56.0412 13.86 55.5982V51.6544C13.86 51.5484 13.8937 51.4618 13.9611 51.3943C14.0381 51.3269 14.1248 51.2932 14.2211 51.2932H16.0269C16.1329 51.2932 16.2195 51.3269 16.2869 51.3943C16.3544 51.4618 16.3881 51.5484 16.3881 51.6544V58.4441C16.3881 58.5405 16.3544 58.6271 16.2869 58.7042C16.2195 58.7716 16.1329 58.8053 16.0269 58.8053H14.3511C14.2548 58.8053 14.1682 58.7716 14.0911 58.7042C14.0237 58.6271 13.99 58.5405 13.99 58.4441V57.8952C13.4892 58.5982 12.6898 58.9498 11.5919 58.9498Z"
          fill="#F0783A"
        />
        <path
          d="M18.0151 58.8053C17.9091 58.8053 17.8225 58.7716 17.7551 58.7042C17.6876 58.6368 17.6539 58.5501 17.6539 58.4441V51.6544C17.6539 51.5484 17.6876 51.4618 17.7551 51.3943C17.8225 51.3269 17.9091 51.2932 18.0151 51.2932H19.6764C19.7824 51.2932 19.869 51.3269 19.9365 51.3943C20.0135 51.4618 20.052 51.5484 20.052 51.6544V52.2033C20.3121 51.8855 20.6443 51.6303 21.0488 51.4377C21.4629 51.2451 21.93 51.1487 22.4501 51.1487C23.2976 51.1487 23.9862 51.4377 24.5159 52.0155C25.0456 52.5838 25.3105 53.3831 25.3105 54.4136V58.4441C25.3105 58.5405 25.2768 58.6271 25.2094 58.7042C25.1419 58.7716 25.0553 58.8053 24.9493 58.8053H23.1291C23.0328 58.8053 22.9461 58.7716 22.8691 58.7042C22.8016 58.6271 22.7679 58.5405 22.7679 58.4441V54.5003C22.7679 54.0476 22.6572 53.7009 22.4357 53.4602C22.2142 53.2098 21.8963 53.0846 21.4822 53.0846C21.0777 53.0846 20.7599 53.2098 20.5288 53.4602C20.2976 53.7009 20.182 54.0476 20.182 54.5003V58.4441C20.182 58.5405 20.1483 58.6271 20.0809 58.7042C20.0135 58.7716 19.9268 58.8053 19.8209 58.8053H18.0151Z"
          fill="#F0783A"
        />
      </svg>
    );
  }
}
