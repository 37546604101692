import {
  GET_SCHEDULES_REQUEST,
  GET_SCHEDULES_SUCCESS,
  GET_SCHEDULES_ERROR,
  GET_SCHEDULES2_REQUEST,
  GET_SCHEDULES2_SUCCESS,
  GET_SCHEDULES2_ERROR,
  GET_SCHEDULE_REQUEST,
  GET_SCHEDULE_SUCCESS,
  GET_SCHEDULE_ERROR,
} from "../actionTypes";
import { statuses } from "../../config";

const initialState = {
  schedules: [],
  schedules2: [],
  schedule: [],
  error: "",
  status: statuses.INITIAL,
};

export const scheduleReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SCHEDULES_REQUEST:
      return {
        ...state,
        schedules: [],
        error: "",
        status: statuses.LOADING,
      };
    case GET_SCHEDULES_SUCCESS:
      return {
        ...state,
        schedules: action.payload,
        error: "",
        status: statuses.SUCCESS,
      };
    case GET_SCHEDULES_ERROR:
      return {
        ...state,
        schedules: [],
        error: action.payload,
        status: statuses.ERROR,
      };
    case GET_SCHEDULES2_REQUEST:
      return {
        ...state,
        schedules2: [],
        error: "",
        status: statuses.LOADING,
      };
    case GET_SCHEDULES2_SUCCESS:
      return {
        ...state,
        schedules2: action.payload,
        error: "",
        status: statuses.SUCCESS,
      };
    case GET_SCHEDULES2_ERROR:
      return {
        ...state,
        schedules2: [],
        error: action.payload,
        status: statuses.ERROR,
      };
    case GET_SCHEDULE_REQUEST:
      return {
        ...state,
        schedule: [],
        error: "",
        status: statuses.LOADING,
      };
    case GET_SCHEDULE_SUCCESS:
      return {
        ...state,
        schedule: action.payload,
        error: "",
        status: statuses.SUCCESS,
      };
    case GET_SCHEDULE_ERROR:
      return {
        ...state,
        schedule: [],
        error: action.payload,
        status: statuses.ERROR,
      };

    default:
      return state;
  }
};
