/* eslint-disable indent */
import { urls, toastOption } from "../../config";
import { API } from "../../utils";
import { toast } from "react-toastify";
import i18n from "../../i18n";

import {
  GET_REGIONS_REQUEST,
  GET_REGIONS_SUCCESS,
  GET_REGIONS_ERROR,
} from "../actionTypes";

export const getRegions = () => async (dispatch) => {
  dispatch(getRegionsRequest());

  await API.get(`${urls.REGION}`)
    .then((res) => {
      console.log(res.data);

      dispatch(getRegionsSuccess(res.data));
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getRegionsFailure(error.message));
      return error.message;
    });
};

export const getRegionsRequest = () => {
  return {
    type: GET_REGIONS_REQUEST,
  };
};

export const getRegionsSuccess = (data) => {
  return {
    type: GET_REGIONS_SUCCESS,
    payload: data,
  };
};

export const getRegionsFailure = (error) => {
  return {
    type: GET_REGIONS_ERROR,
    payload: error,
  };
};
