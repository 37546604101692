import React from "react";
import PT from "prop-types";

import "../../../node_modules/rc-steps/assets/index.css";
import "./Layout.scss";
import { InstractorHeader, InstractorFooter } from "../";

export default function InstractorLayout(props) {
  const { children } = props;
  return (
    <div className="school-presetting">
      <InstractorHeader />
      <div className="school-presetting__body school-presetting__container">
        {children}
      </div>
      <InstractorFooter />
    </div>
  );
}
InstractorLayout.propTypes = {
  children: PT.any,
};
