import React from "react";
import PT from "prop-types";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function SchoolPresettingList(props) {
  const { stepNumber } = props;
  const { t } = useTranslation(["presetting"]);

  return (
    <div className="school-presetting__list">
      <div className="school-presetting__list-tabs">
        <div className="school-presetting__list-tab">
          <h3>{t("presetting:chapter presetting")}</h3>
        </div>
        <div className="school-presetting__list-tab">
          <h3>{t("presetting:section info presetting")}</h3>
        </div>
        <div className="school-presetting__list-tab">
          <h3>{t("presetting:status presetting")}</h3>
        </div>
      </div>
      <div className="school-presetting__list-steps">
        <Link to={stepNumber >= 1 ? "/school-presetting/1" : "#"}>
          <div className="school-presetting__list-step">
            <div className="school-presetting__list-step-title">
              <div className="school-presetting__list-step-number">1</div>
              <h2>{t("presetting:school regulations presetting")}</h2>
            </div>
            <div className="school-presetting__list-step-info">
              <ul>
                <li>
                  <p className="body-1">
                    {t("presetting:chrinocal year presetting")}
                  </p>
                </li>
                <li>
                  <p className="body-1">
                    {t("presetting:chrinocal quater presetting")}
                  </p>
                </li>
                <li>
                  <p className="body-1">
                    {t("presetting:type of study presetting")}
                  </p>
                </li>
              </ul>
            </div>
            <div className="school-presetting__list-step-status">
              {stepNumber > 1 ? (
                <div className="status-success">
                  <p className="body-1">
                    {t("presetting:finished presetting status")}
                  </p>
                </div>
              ) : (
                <div className="status-error">
                  <p className="body-1">
                    {t("presetting:not finished presetting status")}
                  </p>
                </div>
              )}
            </div>
          </div>
        </Link>
        <Link to={stepNumber >= 2 ? "/school-presetting/2" : "#"}>
          <div
            className={
              stepNumber < 2
                ? "school-presetting__list-step school-presetting__list-step-blur"
                : "school-presetting__list-step"
            }
          >
            <div className="school-presetting__list-step-title">
              <div className="school-presetting__list-step-number">2</div>

              <h2>{t("presetting:shifts presetting")}</h2>
            </div>
            <div className="school-presetting__list-step-info">
              <ul>
                <li>
                  <p className="body-1">
                    {t("presetting:add shifts presetting")}
                  </p>
                </li>
              </ul>
            </div>
            <div className="school-presetting__list-step-status">
              {stepNumber > 2 ? (
                <div className="status-success">
                  <p className="body-1">
                    {t("presetting:finished presetting status")}
                  </p>
                </div>
              ) : (
                <div className="status-error">
                  <p className="body-1">
                    {t("presetting:not finished presetting status")}
                  </p>
                </div>
              )}
            </div>
          </div>
        </Link>

        <Link to={stepNumber >= 3 ? "/school-presetting/3" : "#"}>
          <div
            className={
              stepNumber < 3
                ? "school-presetting__list-step school-presetting__list-step-blur"
                : "school-presetting__list-step"
            }
          >
            <div className="school-presetting__list-step-title">
              <div className="school-presetting__list-step-number">3</div>
              <h2>{t("presetting:subjects presetting")}</h2>
            </div>
            <div className="school-presetting__list-step-info">
              <ul>
                <li>
                  <p className="body-1">
                    {t("presetting:add subjects presetting")}
                  </p>
                </li>
              </ul>
            </div>
            <div className="school-presetting__list-step-status">
              {stepNumber > 3 ? (
                <div className="status-success">
                  <p className="body-1">
                    {t("presetting:finished presetting status")}
                  </p>
                </div>
              ) : (
                <div className="status-error">
                  <p className="body-1">
                    {t("presetting:not finished presetting status")}
                  </p>
                </div>
              )}
            </div>
          </div>
        </Link>
        <Link to={stepNumber >= 4 ? "/school-presetting/4" : "#"}>
          <div
            className={
              stepNumber < 4
                ? "school-presetting__list-step school-presetting__list-step-blur"
                : "school-presetting__list-step"
            }
          >
            <div className="school-presetting__list-step-title">
              <div className="school-presetting__list-step-number">4</div>
              <h2>{t("presetting:teachers presetting")}</h2>
            </div>
            <div className="school-presetting__list-step-info">
              <ul>
                <li>
                  <p className="body-1">
                    {t("presetting:add teachers presetting")}
                  </p>
                </li>
              </ul>
            </div>
            <div className="school-presetting__list-step-status">
              {stepNumber > 4 ? (
                <div className="status-success">
                  <p className="body-1">
                    {t("presetting:finished presetting status")}
                  </p>
                </div>
              ) : (
                <div className="status-error">
                  <p className="body-1">
                    {t("presetting:not finished presetting status")}
                  </p>
                </div>
              )}
            </div>
          </div>
        </Link>
        <Link to={stepNumber >= 5 ? "/school-presetting/5" : "#"}>
          <div
            className={
              stepNumber < 5
                ? "school-presetting__list-step school-presetting__list-step-blur"
                : "school-presetting__list-step"
            }
          >
            <div className="school-presetting__list-step-title">
              <div className="school-presetting__list-step-number">5</div>
              <h2>{t("presetting:classes presetting")}</h2>
            </div>
            <div className="school-presetting__list-step-info">
              <ul>
                <li>
                  <p className="body-1">
                    {t("presetting:add classes presetting")}
                  </p>
                </li>
              </ul>
            </div>
            <div className="school-presetting__list-step-status">
              <div className="status-error">
                <p className="body-1">
                  {t("presetting:not finished presetting status")}
                </p>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}

SchoolPresettingList.propTypes = {
  stepNumber: PT.number,
};
