/* eslint-disable indent */
import { urls, toastOption } from "../../config";
import { API } from "../../utils";
import { toast } from "react-toastify";
import i18n from "../../i18n";

import {
  GET_TOWNS_REQUEST,
  GET_TOWNS_SUCCESS,
  GET_TOWNS_ERROR,
} from "../actionTypes";

export const getTownByRayonId = (rayonId) => async (dispatch) => {
  dispatch(getTownsRequest());

  await API.get(`${urls.TOWN}rayon/${rayonId}`)
    .then((res) => {
      console.log(res.data);

      dispatch(getTownsSuccess(res.data));
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getTownsFailure(error.message));
      return error.message;
    });
};

export const getTowns = () => async (dispatch) => {
  dispatch(getTownsRequest());

  await API.get(`${urls.TOWN}`)
    .then((res) => {
      console.log(res.data);

      dispatch(getTownsSuccess(res.data));
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getTownsFailure(error.message));
      return error.message;
    });
};

export const getTownsRequest = () => {
  return {
    type: GET_TOWNS_REQUEST,
  };
};

export const getTownsSuccess = (data) => {
  return {
    type: GET_TOWNS_SUCCESS,
    payload: data,
  };
};

export const getTownsFailure = (error) => {
  return {
    type: GET_TOWNS_ERROR,
    payload: error,
  };
};
