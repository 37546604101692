import React, { useEffect, useState } from "react";
import { SchoolAdminLayout } from "../../components";
import schoolBannerImage from "../../assets/images/school-admin-banner.png";
import { Settings, User } from "react-feather";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
import SelectSearch, { fuzzySearch } from "react-select-search";

import { getSchoolById, editSchool } from "../../store/school/actions";
import { getRegions } from "../../store/region/actions";
import { getRayons } from "../../store/rayon/actions";
import { getInstructors } from "../../store/instructor/actions";
import { getTowns } from "../../store/town/actions";
import { switchRole, getPersons } from "../../store/person/actions";
import { statuses } from "../../config";

export default function School() {
  const dispatch = useDispatch();
  const personsStore = useSelector((store) => store.persons.persons);
  const schoolStore = useSelector((store) => store.schools.school);
  const schoolStatus = useSelector((store) => store.schools.status);
  const regionsStore = useSelector((store) => store.regions.regions);
  const rayonsStore = useSelector((store) => store.rayons.rayons);
  const townsStore = useSelector((store) => store.towns.towns);
  const townsStatus = useSelector((store) => store.towns.status);
  const instructorsStore = useSelector(
    (store) => store.instructors.instructors
  );
  const [school, setSchool] = useState();
  const [regionId, setRegionId] = useState("");
  const [rayonId, setRayonId] = useState("");
  const [townId, setTownId] = useState("");
  const [directorUserId, setDirectorUserId] = useState("");
  const { t } = useTranslation(["schoolAdmin", "presetting"]);

  useEffect(async () => {
    await dispatch(getInstructors());
    await dispatch(
      getSchoolById(JSON.parse(localStorage.getItem("school"))?.id, setSchool)
    );
    await dispatch(getRegions());
    await dispatch(getRayons());
    await dispatch(getTowns());
    await dispatch(getPersons(6));
  }, []);
  // useEffect(async () => {
  //   setTownId("");
  // }, [rayonId]);

  const schoolTypes = [
    {
      value: "STATE",
      name: `${t("schoolAdmin:state school")}`,
    },
    {
      value: "PRIVATE",
      name: `${t("schoolAdmin:private school")}`,
    },
    {
      value: "MUNICIPAL",
      name: `${t("schoolAdmin:municipal school")}`,
    },
  ];

  const schoolOrganizationTypes = [
    {
      value: "GENERAL_MIDDLE_SCHOOL",
      name: `${t("schoolAdmin:general middle school")}`,
    },
    {
      value: "GYMNASIUM_SCHOOL",
      name: `${t("schoolAdmin:gymnasium school")}`,
    },
    {
      value: "INNOVATIVE_GYMNASIUM_SCHOOL",
      name: `${t("schoolAdmin:innovative gymnasium school")}`,
    },
    {
      value: "GENERAL_MIDDLE_GYMNASIUM_SCHOOL",
      name: `${t("schoolAdmin:general middle gymnasium school")}`,
    },
    {
      value: "BOARDING_SCHOOL",
      name: `${t("schoolAdmin:boarding school")}`,
    },
    {
      value: "COMPLETE_HIGH_SCHOOL",
      name: `${t("schoolAdmin:complete high school")}`,
    },
    {
      value: "NOT_COMPLETE_HIGH_SCHOOL",
      name: `${t("schoolAdmin:not complete high school")}`,
    },
    {
      value: "HIGH_SCHOOL",
      name: `${t("schoolAdmin:high school")}`,
    },
    {
      value: "RESIDENTIAL_BOARDING_SCHOOL",
      name: `${t("schoolAdmin:residential boarding school")}`,
    },
    {
      value: "GENERAL_PRIMARY_SCHOOL",
      name: `${t("schoolAdmin:general primary school")}`,
    },
    {
      value: "GENERAL_BASIC_SCHOOL",
      name: `${t("schoolAdmin:general basic school")}`,
    },
    {
      value: "BASIC_SCHOOL",
      name: `${t("schoolAdmin:basic school")}`,
    },
    {
      value: "LYCEUM_BOARDING_SCHOOL",
      name: `${t("schoolAdmin:lyceum boarding school")}`,
    },
  ];

  const onInputChange = (e) => {
    console.log(e);
    setSchool({ ...school, [e.key || e.name]: e.value });
  };
  const onSubmit = async () => {
    let schoolData = {
      address: school?.address,
      code: school?.code,
      email: school?.email,
      gradeType: school?.gradeType,
      isTest: school?.instructor,
      name: school?.name,
      phoneNumber: school?.phoneNumber,
      schoolLanguageType: school?.schoolLanguageType,
      schoolOrganizationType: school?.schoolOrganizationType,
      schoolType: school?.schoolType,
      studyDay: school?.studyDay,
    };
    if (rayonId && regionId) {
      schoolData = { ...schoolData, regionId, rayonId };
    }
    if (townId) {
      schoolData = { ...schoolData, townId };
    }
    await dispatch(editSchool(schoolData));
    directorUserId &&
      (await dispatch(switchRole({ userId: directorUserId, roleId: 6 })));
    await dispatch(
      getSchoolById(JSON.parse(localStorage.getItem("school"))?.id, setSchool)
    );
    await dispatch(getPersons(6));
    // setRegionId(null);
    // setRayonId(null);
    // setTownId(null);
  };
  const rayonsOptions = rayonsStore
    .filter(
      (item) => item.region?.id == (regionId || schoolStore?.rayon?.region?.id)
    )
    .map((item) => ({
      name: `${item.title}`,
      value: item.id,
      key: "rayonId",
    }));

  const schoolTypesOptions = schoolTypes.map((item) => ({
    name: `${item.name}`,
    value: item.value,
    key: "schoolType",
  }));
  const schoolOrganizationTypesOptions = schoolOrganizationTypes.map(
    (item) => ({
      name: `${item.name}`,
      value: item.value,
      key: "schoolOrganizationType",
    })
  );
  const instructorsOptions = instructorsStore.map((item) => ({
    name: `${item.instructorTitle}`,
    value: item.instructorUserId,
    key: "instructor",
  }));
  const regionsOptions = regionsStore.map((item) => ({
    name: `${item.title}`,
    value: item.id,
    key: "regionId",
  }));

  const townsOptions = townsStore
    .filter((item) => item.rayon?.id == (rayonId || schoolStore?.rayon?.id))
    .map((item) => ({
      name: `${item.title}`,
      value: item.id,
      key: "townId",
    }));

  return (
    <SchoolAdminLayout>
      <div className="school-admin__body">
        <div className="school-admin__body-item school-presetting__body school-presetting__body-color">
          <div className="school-admin__banner">
            <div className="school-admin__banner-left">
              <div className="school-admin__banner-text">
                <p>
                  <Trans
                    i18nKey="schoolAdmin:welcome to the Kundoluk educational platform!"
                    t={t}
                    components={[<span className="orange-text" key="0"></span>]}
                  ></Trans>
                </p>
              </div>
            </div>
            <div className="school-admin__banner-right">
              <div className="school-admin__banner-img">
                <img src={schoolBannerImage} alt="school admin image" />
              </div>
            </div>
          </div>
        </div>
        {schoolStatus === statuses.SUCCESS && (
          <div className="school-admin__body-item school-presetting__body school-presetting__body-color">
            <div className="school-admin__body-data">
              <div className="school-admin__body-data-row">
                <div className="school-admin__body-data-left">
                  <div className="school-admin__body-row">
                    <div className="school-admin__body-title">
                      <div className="school-admin__body-icon">
                        <Settings size={18} color="#ffffff" />
                      </div>
                      <div className="school-admin__body-text">
                        <p>{t("schoolAdmin:school info")}</p>
                      </div>
                    </div>
                  </div>
                  <div className="school-admin__body-row">
                    <label htmlFor="schoolType">
                      {t("schoolAdmin:organization type")}
                    </label>
                    {schoolOrganizationTypesOptions.length > 0 && (
                      <SelectSearch
                        options={schoolOrganizationTypesOptions}
                        search
                        filterOptions={fuzzySearch}
                        value={`${
                          school?.schoolOrganizationType ||
                          schoolStore?.schoolOrganizationType
                        }`}
                        name="schoolOrganizationType"
                        onChange={(_, k) => {
                          onInputChange(k);
                        }}
                        required
                        placeholder={`- ${t("schoolAdmin:choose")} -`}
                      />
                    )}
                  </div>
                  <div className="school-admin__body-row">
                    <label htmlFor="schoolType">
                      {t("schoolAdmin:ownership type")}
                    </label>
                    {schoolTypesOptions.length > 0 && (
                      <SelectSearch
                        options={schoolTypesOptions}
                        search
                        filterOptions={fuzzySearch}
                        value={`${
                          school?.schoolType || schoolStore?.schoolType
                        }`}
                        name="schoolType"
                        onChange={(_, k) => {
                          onInputChange(k);
                        }}
                        required
                        placeholder={`- ${t("schoolAdmin:choose")} -`}
                      />
                    )}
                  </div>
                  <div className="school-admin__body-row">
                    <label htmlFor="name">
                      {t("schoolAdmin:title of school")}
                    </label>
                    <input
                      type="text"
                      placeholder={`${t(
                        "schoolAdmin:full name of the school"
                      )}`}
                      name="name"
                      defaultValue={schoolStore.name}
                      onChange={(e) => onInputChange(e.target)}
                    />
                  </div>
                  <div className="school-admin__body-row">
                    <label htmlFor="regionId">{t("schoolAdmin:region")}</label>
                    {regionsOptions.length > 0 && (
                      <SelectSearch
                        options={regionsOptions}
                        search
                        filterOptions={fuzzySearch}
                        value={`${
                          regionId === null
                            ? regionId
                            : school?.regionId || schoolStore?.rayon?.region?.id
                        }`}
                        name="regionId"
                        onChange={(_, k) => {
                          setRegionId(k.value);
                          setSchool({
                            ...school,
                            regionId: k.value,
                            rayonId: null,
                            townId: null,
                            town: { ...school?.town, id: null },
                          });
                          onInputChange(k);
                          setRayonId(null);
                          setTownId(null);
                        }}
                        required
                        placeholder={`- ${t("schoolAdmin:choose")} -`}
                      />
                    )}
                  </div>
                  <div className="school-admin__body-row">
                    <label htmlFor="rayonId">{t("schoolAdmin:area")}</label>
                    {rayonsOptions.length > 0 && (
                      <SelectSearch
                        options={rayonsOptions}
                        search
                        filterOptions={fuzzySearch}
                        value={`${
                          rayonId === null
                            ? rayonId
                            : school?.rayonId || schoolStore?.rayon?.id
                        }`}
                        name="rayonId"
                        onChange={(_, k) => {
                          setTownId(null);
                          // setTownId("");
                          setSchool({
                            ...school,
                            townId: null,
                            town: { ...school?.town, id: null },
                          });
                          setRayonId(k.value);
                          onInputChange(k);
                        }}
                        required
                        placeholder={`- ${t("schoolAdmin:choose")} -`}
                      />
                    )}
                  </div>
                  {townsStatus === statuses.SUCCESS && (
                    <div className="school-admin__body-row">
                      {townsOptions.length > 0 && (
                        <>
                          <label htmlFor="townId">
                            {t("schoolAdmin:town")}
                          </label>

                          <SelectSearch
                            options={townsOptions}
                            search
                            filterOptions={fuzzySearch}
                            value={`${
                              townId === null
                                ? townId
                                : school?.town?.id || schoolStore?.town?.id
                            }`}
                            name="townId"
                            onChange={(_, k) => {
                              // onInputChange(k);
                              setSchool({
                                ...school,
                                townId: townId,
                                town: { ...k, id: k.value },
                              });
                              setTownId(k.value);
                              console.log("townId", townId);
                            }}
                            required
                            placeholder={`- ${t("schoolAdmin:choose")} -`}
                          />
                        </>
                      )}
                    </div>
                  )}
                </div>
                <div className="school-admin__body-data-divider"></div>
                <div className="school-admin__body-data-right">
                  <div className="school-admin__body-row">
                    <div className="school-admin__body-title">
                      <div className="school-admin__body-icon">
                        <User size={18} color="#ffffff" />
                      </div>
                      <div className="school-admin__body-text">
                        <p>{t("schoolAdmin:school contacts")}</p>
                      </div>
                    </div>
                  </div>
                  <div className="school-admin__body-row">
                    <label htmlFor="phoneNumber">
                      {t("presetting:phone presetting")}
                    </label>
                    <input
                      type="text"
                      placeholder="996700124567"
                      name="phoneNumber"
                      defaultValue={schoolStore.phoneNumber}
                      onChange={(e) => onInputChange(e.target)}
                    />
                  </div>
                  <div className="school-admin__body-row">
                    <label htmlFor="email">
                      {t("schoolAdmin:school email")}
                    </label>
                    <input
                      type="text"
                      placeholder={`${t("schoolAdmin:email placeHolder")}`}
                      name="email"
                      defaultValue={schoolStore.email}
                      onChange={(e) => onInputChange(e.target)}
                    />
                  </div>
                  <div className="school-admin__body-row">
                    <label htmlFor="instructor">
                      {t("presetting:director presetting")}
                    </label>
                    {instructorsOptions.length > 0 && (
                      <SelectSearch
                        options={instructorsOptions}
                        search
                        filterOptions={fuzzySearch}
                        value={`${
                          school?.instructor || personsStore?.[0]?.userId
                        }`}
                        name="instructor"
                        onChange={(_, k) => {
                          // setRayonId(k.value);
                          onInputChange(k);
                          setDirectorUserId(k.value);
                        }}
                        required
                        placeholder={`- ${t("schoolAdmin:choose")} -`}
                      />
                    )}
                  </div>
                  <div className="school-admin__body-row">
                    <label htmlFor="studyDay">
                      {t("presetting:type of study presetting")}
                    </label>
                    <SelectSearch
                      options={[
                        {
                          name: `${t(
                            "presetting:five day presetting school regulations"
                          )}`,
                          value: 5,
                          key: "studyDay",
                        },
                        {
                          name: `${t(
                            "presetting:six day presetting school regulations"
                          )}`,
                          value: 6,
                          key: "studyDay",
                        },
                      ]}
                      search
                      filterOptions={fuzzySearch}
                      value={`${school?.studyDay || schoolStore?.studyDay}`}
                      name="studyDay"
                      onChange={(_, k) => {
                        // setRayonId(k.value);
                        setSchool({
                          ...school,
                          studyDay: k.value,
                        });
                        onInputChange(k);
                        // setDirectorUserId(k.value);
                      }}
                      required
                      placeholder={`- ${t("schoolAdmin:choose")} -`}
                    />
                  </div>
                </div>
              </div>
              <div className="school-presetting__body-row h-center">
                <button
                  id="save_changes_btn"
                  className="primary large"
                  onClick={onSubmit}
                >
                  {t("schoolAdmin:save changes")}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </SchoolAdminLayout>
  );
}
