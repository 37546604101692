import React from "react";
import { useTranslation } from "react-i18next";

import { SchoolAdminLayout, InstractorLayout } from "../../components";
import ReportsCard from "../../components/Reports";
import "./Reports.scss";

function Reports() {
  const { t } = useTranslation();
  const dataSchoolAdmin = [
    // {
    //   title: "Общие сведения об обучающихся",
    //   url: "/school-admin/reports/student-record",
    //   active: "disabled",
    // },
    {
      title: `${t(
        "reports:information about the number of days and lessons students missed"
      )}`,
      url: "/school-admin/reports/students-attendance",
      active: "active",
    },
    {
      title: `${t("reports:general information on classes")}`,
      url: "/school-admin/reports/pupils",
      active: "active",
    },
    // ! classroom-journal
    // {
    //   title: `1${t("schoolAdmin:general information about students")}`,
    //   url: "/school-admin/reports/classroom-journal",
    //   active: "active",
    // },
    // !student-record
    {
      title: `${t("reports:student record")}`,
      url: "/school-admin/reports/student-record",
      active: "active",
    },
    // !student-record
    {
      title: `${t("schoolAdmin:summary of student progress records")}`,
      url: "/school-admin/reports/summary-student-progress-records",
      active: "active",
    },
    // !
    // {
    //   title: "Сводная ведомость учета успеваемости учащихся",
    //   url: "/school-admin/reports/grade-summary-information",
    //   active: "active",
    // },
    // !
    // {
    //   title: "1Сводная ведомость учета успеваемости учащихся",
    //   url: "/school-admin/reports/student-record",
    //   active: "active",
    // },
    // !
    {
      title: t("instructor:class journal report"),
      url: "/school-admin/reports/class-journal",
      active: "active",
    },
    {
      title: t("schoolAdmin:information about final class grades"),
      url: "/school-admin/reports/final-class-grades",
      active: "active",
    },
    // {
    //   title: "Ведомость достижения",
    //   url: "/school-admin/reports/student-record",
    //   active: "active",
    // },
  ];
  const dataInstructor = [
    // {
    //   title: "Общие сведения об обучающихся",
    //   url: "/school-admin/reports/student-record",
    //   active: "disabled",
    // },
    // {
    //   title: "Сведения о количестве дней и уроков, пропущенных учащимися",
    //   url: "/school-admin/reports/student-record",
    //   active: "disabled",
    // },
    // {
    //   title: "Отчет по ведению классного журнала",
    //   url: "/school-admin/reports/student-record",
    //   active: "disabled",
    // },
    {
      title: `${t("reports:student record")}`,
      url: "/instructor/reports/student-record",
      active: "active",
    },
    // {
    //   title: "Сведения об итоговых оценках класса",
    //   url: "/school-admin/reports/student-record",
    //   active: "active",
    // },
    // {
    //   title: "Сводная ведомость учета успеваемости учащихся",
    //   url: "/school-admin/reports/student-record",
    //   active: "active",
    // },
    // {
    //   title: "Ведомость достижения",
    //   url: "/school-admin/reports/student-record",
    //   active: "active",
    // },
  ];

  return (
    <div>
      {JSON.parse(localStorage.getItem("roleCode")) == 4 && (
        <InstractorLayout>
          <div className="reposts_body">
            <span className="reposts_body_topic">
              {t("schoolAdmin:reports")}
            </span>
            <p className="reposts_body_title">
              {t("reports:report main loran ipsune")}
            </p>
            <div>
              <ReportsCard date={dataInstructor} />
            </div>
          </div>
        </InstractorLayout>
      )}
      {JSON.parse(localStorage.getItem("roleCode")) == 2 && (
        <SchoolAdminLayout>
          <div className="reposts_body">
            <span className="reposts_body_topic">
              {t("schoolAdmin:reports")}
            </span>
            <p className="reposts_body_title">
              {t("reports:report main loran ipsune")}
            </p>
            <div>
              <ReportsCard date={dataSchoolAdmin} />
            </div>
          </div>
        </SchoolAdminLayout>
      )}
    </div>
  );
}

export default Reports;
