/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { X, Check } from "react-feather";
import { useTranslation } from "react-i18next";
import Tooltip from "react-simple-tooltip";

import {
  SchoolPresettingLayout,
  DatePickerCalendar,
  NoData,
} from "../../components";
import { stepUpSchoolPresetting } from "../../store/schoolPresetting/actions";
import { editSchool, getSchoolById } from "../../store/school/actions";
import {
  editQuartersMassive,
  getQuarters,
  deleteQuarter,
  createQuarter,
} from "../../store/quarter/actions";
import { statuses } from "../../config";
import "./SchoolPresetting.scss";

export default function SchoolPresetting1() {
  const dispatch = useDispatch();
  const schoolsStore = useSelector((store) => store.schools);
  const quartersStore = useSelector((store) => store.quarters.quarters);
  const quartersStoreStatus = useSelector((store) => store.quarters.status);
  const activeYear = JSON.parse(localStorage.getItem("chronicleActive"));
  const [quarters, setQuarters] = useState([
    {
      quarter: "1",
      startDate: moment(`${activeYear?.startYear}-09-01`),
      endDate: moment(`${activeYear?.startYear}-10-25`),
      status: true,
    },
    {
      quarter: "2",
      startDate: moment(`${activeYear?.startYear}-11-01`),
      endDate: moment(`${activeYear?.startYear}-12-25`),
      status: false,
    },
    {
      quarter: "3",
      startDate: moment(`${activeYear?.endYear}-01-14`),
      endDate: moment(`${activeYear?.endYear}-03-21`),
      status: false,
    },
    {
      quarter: "4",
      startDate: moment(`${activeYear?.endYear}-03-28`),
      endDate: moment(`${activeYear?.endYear}-05-25`),
      status: false,
    },
  ]);

  useEffect(async () => {
    getAllQuarter();

    getSchool();
  }, [dispatch]);

  const getAllQuarter = () => {
    dispatch(getQuarters());
  };
  const getSchool = () => {
    dispatch(getSchoolById(JSON.parse(localStorage.getItem("school"))?.id));
  };
  const onSubmit = () => {
    if (quartersStore.length > 0) {
      editMassive();
    } else {
      quarters.map((item) => {
        let b = {
          ...item,
          startDate: moment(item.startDate).format("YYYY-MM-DD"),
          endDate: moment(item.endDate).format("YYYY-MM-DD"),
          schoolId: JSON.parse(localStorage.getItem("school"))?.id,
          chronicleId: JSON.parse(localStorage.getItem("chronicleActive"))?.id,
        };
        dispatch(createQuarter(b));
      });
    }
    JSON.parse(localStorage.getItem("schoolPresetting"))?.preset === false &&
      dispatch(
        stepUpSchoolPresetting({
          chronicleId: JSON.parse(localStorage.getItem("chronicleActive"))?.id,
          isPreset: false,
          schoolId: JSON.parse(localStorage.getItem("school"))?.id,
          stepNumber: 2,
        })
      );
  };
  const onChangeQuarter = (e, id) => {
    if (quartersStore.length > 0) {
      const a = quartersStore.map((item) => {
        if (item.quarter === id) {
          return {
            ...item,
            [e.target.name]: moment(e.target.value).format("YYYY-MM-DD"),
          };
        } else {
          return item;
        }
      });
      dispatch(editQuartersMassive(a));
    } else {
      let idx = id - 1;
      let b = quarters[idx];
      let a = quarters.filter((item) => item[idx] !== idx);
      a[idx] = { ...b, [e.target.name]: e.target.value };
      setQuarters(a);
    }
  };

  const editMassive = () => {
    const a = quartersStore.map((item) => {
      const data = {
        id: item.id,
        chronicleId: JSON.parse(localStorage.getItem("chronicleActive"))?.id,
        endDate: moment(item.endDate).format("YYYY-MM-DD"),
        startDate: moment(item.startDate).format("YYYY-MM-DD"),
        quarter: item.quarter,
        schoolId: JSON.parse(localStorage.getItem("school"))?.id,
        status: item.status,
      };
      return data;
    });
    dispatch(editQuartersMassive(a));
  };
  const onChangeStudyType = (e) => {
    dispatch(editSchool({ studyDay: Number(e.target.value) }));
    getSchool();
  };

  const setActive = (id) => {
    if (quartersStore.length > 0) {
      const a = quartersStore.map((item) => {
        if (item.quarter === id) {
          return { ...item, status: true };
        } else {
          return { ...item, status: false };
        }
      });
      dispatch(editQuartersMassive(a));
    } else {
      let a = quarters.map((item) => {
        let b;

        if (item.status === true) {
          b = { ...item, status: false };
        } else {
          b = item;
        }
        if (item.quarter == id) {
          b = { ...item, status: true };
        }
        return b;
      });
      setQuarters(a);
    }
  };

  const removeQuarter = (id) => {
    let b = quarters.filter((item) => item.quarter !== id);
    setQuarters(b);
  };
  const removeExistQuarter = (id) => {
    dispatch(deleteQuarter(id));
  };

  const { t } = useTranslation(["presetting"]);

  return (
    <SchoolPresettingLayout id={1}>
      <div className="school-presetting__body school-presetting__body-color">
        <div className="school-presetting__body-row">
          <div className="school-presetting__body-title">
            <h2>{t("presetting:title presetting school regulations")}</h2>
          </div>
          <div className="school-presetting__body-description">
            <p className="body-1">
              {t("presetting:lorem ipsum presetting school regulations")}
            </p>
          </div>
        </div>

        <div className="school-presetting__body-row">
          <div className="school-presetting__body-subtitle">
            <h3>{t("presetting:type of study presetting")}</h3>
          </div>
          {schoolsStore.school && schoolsStore.status === statuses.SUCCESS ? (
            <div className="radio-buttons">
              <label htmlFor="study-type-5">
                <input
                  type="radio"
                  name="study-type"
                  value="5"
                  id="study-type-5"
                  onChange={onChangeStudyType}
                  defaultChecked={schoolsStore.school.studyDay == 5}
                />
                {t("presetting:five day presetting school regulations")}
              </label>
              <label htmlFor="study-type-6">
                <input
                  type="radio"
                  name="study-type"
                  value="6"
                  id="study-type-6"
                  onChange={onChangeStudyType}
                  defaultChecked={schoolsStore.school.studyDay == 6}
                />
                {t("presetting:six day presetting school regulations")}
              </label>
            </div>
          ) : null}
        </div>
        <div className="school-presetting__body-row">
          <div className="school-presetting__body-subtitle">
            <h3>
              {t("presetting:study quarters presetting school regulations")}
            </h3>
          </div>

          <div className="school-presetting__study-quarters">
            <div className="school-presetting__study-quarter-titles">
              <div></div>
              <div>
                <p className="orange-text">
                  {t("presetting:start presetting school regulations")}
                </p>
              </div>
              <div>
                <p className="orange-text">
                  {t("presetting:the ending presetting school regulations")}
                </p>
              </div>
            </div>
            {quartersStore.length > 0 &&
            quartersStoreStatus === statuses.SUCCESS ? (
              quartersStore?.map((item, idx) => (
                <div className="school-presetting__study-quarter" key={idx}>
                  <div>
                    <p>{item.quarter}</p>
                  </div>
                  <div>
                    <DatePickerCalendar
                      onSelect={(e) => onChangeQuarter(e, idx + 1)}
                      placeholder="- Выбрать -"
                      defaultValue={moment(item.startDate)}
                      name="startDate"
                    />
                  </div>
                  <div>
                    <DatePickerCalendar
                      onSelect={(e) => onChangeQuarter(e, idx + 1)}
                      placeholder="- Выбрать -"
                      defaultValue={moment(item.endDate)}
                      name="endDate"
                    />
                  </div>
                  <div className="school-presetting__study-quarter-buttons">
                    <Tooltip className="tooltip" content={t("common:delete")}>
                      <button onClick={() => removeExistQuarter(item.id)}>
                        <X />
                      </button>
                    </Tooltip>
                    <Tooltip className="tooltip" content={t("common:activate")}>
                      <button
                        className={item.status ? "active" : ""}
                        onClick={() => setActive(item.quarter)}
                      >
                        <Check />
                      </button>
                    </Tooltip>
                  </div>
                </div>
              ))
            ) : quartersStoreStatus === statuses.LOADING ? (
              <NoData />
            ) : (
              quarters?.map((item, idx) => (
                <div className="school-presetting__study-quarter" key={idx}>
                  <div>
                    <p>{item.quarter}</p>
                  </div>
                  <div>
                    <DatePickerCalendar
                      onSelect={(e) => onChangeQuarter(e, idx + 1)}
                      placeholder="- Выбрать -"
                      defaultValue={item.startDate}
                      name="startDate"
                    />
                  </div>
                  <div>
                    <DatePickerCalendar
                      onSelect={(e) => onChangeQuarter(e, idx + 1)}
                      placeholder="- Выбрать -"
                      defaultValue={item.endDate}
                      name="endDate"
                    />
                  </div>
                  <div className="school-presetting__study-quarter-buttons">
                    <Tooltip className="tooltip" content={t("common:delete")}>
                      <button onClick={() => removeQuarter(item.quarter)}>
                        <X />
                      </button>
                    </Tooltip>
                    <Tooltip className="tooltip" content={t("common:activate")}>
                      <button
                        className={item.status ? "active" : ""}
                        onClick={() => setActive(item.quarter)}
                      >
                        <Check />
                      </button>
                    </Tooltip>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
        <div className="school-presetting__body-row h-center">
          <Link to="/school-presetting/2">
            <button
              onClick={onSubmit}
              className="primary large"
              // disabled={}
            >
              {t("presetting:save and continue presetting school regulations")}
            </button>
          </Link>
        </div>
      </div>
    </SchoolPresettingLayout>
  );
}
