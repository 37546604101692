import React from "react";
import Steps, { Step } from "rc-steps";
import PT from "prop-types";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "../../../node_modules/rc-steps/assets/index.css";
import "./Layout.scss";
import { SchoolPresettingFooter, SchoolPresettingHeader, GoBack } from "../";

export default function SchoolPresettingLayout(props) {
  const { children, id } = props;
  const history = useHistory();
  const { t } = useTranslation(["presetting", "common"]);
  return (
    <div className="school-presetting">
      <SchoolPresettingHeader />
      <div className="school-presetting__body school-presetting__container">
        <GoBack
          title={t(
            "presetting:return to home screen presetting school regulations"
          )}
          link="/school-presetting"
        />
        <Steps current={id - 1} labelPlacement="vertical">
          <Step
            title={t("presetting:school regulations presetting")}
            disabled={id <= 1}
            onStepClick={() => {
              history.push("/school-presetting/1");
            }}
          />
          <Step
            title={t("presetting:shifts presetting")}
            disabled={id <= 2}
            onStepClick={() => {
              history.push("/school-presetting/2");
            }}
          />
          <Step
            title={t("presetting:subjects presetting")}
            disabled={id <= 3}
            onStepClick={() => {
              history.push("/school-presetting/3");
            }}
          />
          <Step
            title={t("presetting:teachers presetting")}
            disabled={id <= 4}
            onStepClick={() => {
              history.push("/school-presetting/4");
            }}
          />
          <Step
            title={t("presetting:classes presetting")}
            disabled={id <= 5}
            onStepClick={() => {
              history.push("/school-presetting/5");
            }}
          />
        </Steps>
        {children}
      </div>
      <SchoolPresettingFooter />
    </div>
  );
}
SchoolPresettingLayout.propTypes = {
  children: PT.any,
  // style: PT.object,
  id: PT.number,
};
