/* eslint-disable indent */
import React, { useEffect, useState, useCallback } from "react";
import { debounce } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Modal from "react-modal";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Edit2, Trash, PlusSquare, X } from "react-feather";
import { toast } from "react-toastify";
import SelectSearch, { fuzzySearch } from "react-select-search";
import Tooltip from "react-simple-tooltip";

import { toastOption } from "../../config";
import {
  SchoolPresettingLayout,
  PaginationBlock,
  SearchField,
  DatePickerCalendar,
  NoData,
} from "../../components";

import {
  removePerson,
  createPerson,
  getPersonById,
  editPerson,
} from "../../store/person/actions";
import {
  getInstructors,
  getInstructorCourses,
  addCourse,
  removeCourse,
} from "../../store/instructor/actions";
import { stepUpSchoolPresetting } from "../../store/schoolPresetting/actions";
import { getCourses } from "../../store/course/actions";

export default function SchoolPresetting4() {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  // const personsStore = useSelector((store) => store.persons.persons);
  const instructorsStore = useSelector(
    (store) => store.instructors.instructors
  );
  const coursesStore = useSelector((store) => store.courses.courses);
  const instructorStore = useSelector((store) => store.persons.person);
  const instructorCoursesStore = useSelector(
    (store) => store.instructors.courses
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [currentInstructorId, setCurrentInstructorId] = useState();
  const [currentInstructor, setCurrentInstructor] = useState();
  const [selectedCourseId, setSelectedCourseId] = useState();
  const [editInstructor, setEditInstructor] = useState({});

  // const [allSubjects, setAllSubjects] = useState([]);
  const [currentData, setCurrentData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedCurrentData, setSearchedCurrentData] = useState([]);
  console.log("currentData", currentData);
  const [modal, setModal] = useState({
    step1: false,
    step2: false,
    editTeacher: false,
    editSubjects: false,
    confirm: false,
    confirm2: false,
  });

  const [newTeacher, setNewTeacher] = useState({
    lastName: "",
    firstName: "",
    middleName: "",
    phone: "",
    dateOfBirth: "1980-01-01",
    roleId: 4,
    gender: "2",
  });

  useEffect(async () => {
    await dispatch(getInstructors());
  }, []);

  const onInputChange = (e) => {
    setNewTeacher({
      ...newTeacher,
      [e.key || e.target.name]: e.value || e.target.value,
    });
  };

  const onEditInputChange = (e) => {
    setEditInstructor({ ...editInstructor, [e.target.name]: e.target.value });
  };

  const onPageChanged = (
    pageNumber,
    perPageNumber,
    data = instructorsStore
  ) => {
    const offset = (pageNumber - 1) * perPageNumber;
    const currentDataArr = data.slice(offset, offset + perPageNumber);
    return setCurrentData(currentDataArr);
  };

  const createTeacher = async () => {
    await dispatch(createPerson(newTeacher, setCurrentInstructorId));
    await dispatch(getInstructors());
    await setNewTeacher({
      lastName: "",
      firstName: "",
      middleName: "",
      phone: "",
      dateOfBirth: "1980-01-01",
      roleId: 4,
      gender: "2",
    });
    await setModal({ ...modal, step1: false, step2: true });
  };
  const editTeacher = async () => {
    await dispatch(
      editPerson({
        lastName: editInstructor.lastName,
        firstName: editInstructor.firstName,
        middleName: editInstructor.middleName,
        phone: editInstructor.phone,
        dateOfBirth: editInstructor.dateOfBirth,
        // dateOfBirth: moment(editInstructor.dateOfBirth).format("YYYY-MM-DD"),
        gender: editInstructor.gender,
        id: editInstructor.id,
      })
    );
    await dispatch(getInstructors());
    await setEditInstructor({});
    await setModal({ ...modal, editTeacher: false });
  };

  const subjectsRender = coursesStore.map((item) => ({
    name:
      String(i18n.language).toUpperCase() !== "EN" &&
      String(i18n.language).toUpperCase() !== "TR"
        ? item[`title${String(i18n.language).toUpperCase()}`]
        : item.title,
    value: item.id,
    key: "course",
  }));
  const removeSubject = async (id) => {
    await dispatch(
      removeCourse({ courseId: id, personId: currentInstructorId })
    );
    await dispatch(getInstructorCourses(currentInstructorId));
  };
  const addSubject = async (instructorId, courseId) => {
    await dispatch(addCourse(instructorId, courseId));
    await dispatch(getInstructorCourses(instructorId));
  };
  const debouncedPerPage = useCallback(
    debounce((text, instructorsStore) => {
      onPageChanged(1, Number(text), instructorsStore);
      setPerPage(Number(text));
      setCurrentPage(1);
    }, 1000),
    [instructorsStore]
  );
  const debouncedSearch = useCallback(
    debounce((text) => {
      setSearchText(String(text).toLowerCase());
      setCurrentPage(1);

      const a = instructorsStore.filter((item) => {
        let obj;
        Object.keys(item).map((key) => {
          if (key == "instructorTitle" || key == "instructorPhone") {
            const c = String(item[key]).toLowerCase();
            const d = c.includes(String(text).toLowerCase());
            if (d) {
              obj = item;
              return item;
            }
          }
        });
        return obj;
      });
      setSearchedCurrentData([...a]);
      onPageChanged(1, perPage, [...a]);
    }, 1000),
    [perPage, instructorsStore]
  );
  const onSubmit = () => {
    const a = instructorsStore.find(
      (item) =>
        item.instructorPhone === "" ||
        item.instructorCourseTitleDtoList.length < 1
    );
    if (a) {
      toast.error(
        i18n.t("toastify:add phone number and subject to instructors"),
        toastOption
      );
    } else {
      console.log(a);
      JSON.parse(localStorage.getItem("schoolPresetting"))?.preset === false &&
        dispatch(
          stepUpSchoolPresetting({
            chronicleId: JSON.parse(localStorage.getItem("chronicleActive"))
              ?.id,
            isPreset: false,
            schoolId: JSON.parse(localStorage.getItem("school"))?.id,
            stepNumber: 5,
          })
        );
      history.push(`/school-presetting/5`);
    }
  };

  const onChange = async (e) => {
    console.log(e);
    setCurrentPage(e);
    if (searchedCurrentData.length > 0) {
      onPageChanged(e, perPage, searchedCurrentData);
    } else {
      onPageChanged(e, perPage, instructorsStore);
    }
  };
  const getPerPage = (e) => {
    debouncedPerPage(e.target.value);
  };
  const searchInputHandler = (e) => {
    debouncedSearch(e.target.value);
  };

  const removeItem = async (value) => {
    await dispatch(removePerson(value.instructorId));
    await dispatch(getInstructors());
  };

  const onSelect = (value) => {
    console.log(selectedData);
    if (selectedData.find((item) => item.instructorId === value.instructorId)) {
      let a = selectedData.filter(
        (item) => item.instructorId !== value.instructorId
      );
      setSelectedData([...a]);
    } else {
      setSelectedData([...selectedData, value]);
    }
  };
  const clearSelected = () => {
    setSelectedData([]);
  };
  const removeSelected = async (data) => {
    await data.map((item) => {
      dispatch(removePerson(item.instructorId));
    });
    await setSelectedData([]);
    await dispatch(getInstructors());

    // setAllData(a);
    // onPageChanged(currentPage, perPage, a);
  };

  const tableDataRender =
    currentData.length > 0
      ? currentData.map((item, idx) => (
          <tr
            className={
              selectedData.find((i) => i.id === item.id) ? "active" : ""
            }
            key={idx}
          >
            <td className="table-select">
              <label className="check">
                <input
                  type="checkbox"
                  onChange={() => onSelect(item)}
                  checked={selectedData.find(
                    (i) => i.instructorId === item.instructorId
                  )}
                />
                <div className="box"></div>
              </label>
              <span>{idx + 1}</span>
            </td>
            <td>{item.instructorTitle}</td>
            <td>
              {item?.instructorCourseTitleDtoList?.map((item) =>
                String(i18n.language).toUpperCase() !== "EN" &&
                String(i18n.language).toUpperCase() !== "TR"
                  ? `${
                      item[`courseTitle${String(i18n.language).toUpperCase()}`]
                    } `
                  : `${item.courseTitle} `
              )}
            </td>
            <td>{item.instructorPhone}</td>
            <td>
              <div>
                <Tooltip className="tooltip" content={t("common:add course")}>
                  <button
                    onClick={() => {
                      setModal({ ...modal, editSubjects: true }),
                        setCurrentInstructorId(item.instructorId);
                    }}
                  >
                    <PlusSquare color="#F0783A" />
                  </button>
                </Tooltip>
                <Tooltip className="tooltip" content={t("common:edit")}>
                  <button
                    onClick={() => {
                      setModal({ ...modal, editTeacher: true });
                      setCurrentInstructorId(item.instructorId);
                      dispatch(
                        getPersonById(item.instructorId, setEditInstructor)
                      );
                    }}
                  >
                    <Edit2 color="#14A38B" />
                  </button>
                </Tooltip>
                {/* <Popconfirm
            title="Вы уверены?"
            onConfirm={() => removeItem(item)}
            onCancel={() => false}
            okText="Да"
            cancelText="Нет"
          > */}
                <Tooltip className="tooltip" content={t("common:delete")}>
                  <button
                    onClick={async () => {
                      await setCurrentInstructor(item);
                      await setModal({ ...modal, confirm: true });
                    }}
                  >
                    <Trash color="#FF7171" />
                  </button>
                </Tooltip>
                {/* </Popconfirm> */}
              </div>
            </td>
          </tr>
        ))
      : instructorsStore.slice([0], [10]).map((item, idx) => (
          <tr
            className={
              selectedData.find((i) => i.id === item.id) ? "active" : ""
            }
            key={idx}
          >
            <td className="table-select">
              <label className="check">
                <input
                  type="checkbox"
                  onChange={() => onSelect(item)}
                  checked={selectedData.find(
                    (i) => i.instructorId === item.instructorId
                  )}
                />
                <div className="box"></div>
              </label>
              <span>{idx + 1}</span>
            </td>
            <td>{item.instructorTitle}</td>
            <td>
              {item?.instructorCourseTitleDtoList?.map((item) =>
                String(i18n.language).toUpperCase() !== "EN" &&
                String(i18n.language).toUpperCase() !== "TR"
                  ? `${
                      item[`courseTitle${String(i18n.language).toUpperCase()}`]
                    } `
                  : `${item.courseTitle} `
              )}
            </td>
            <td>{item.instructorPhone}</td>
            <td>
              <div>
                <Tooltip className="tooltip" content={t("common:add course")}>
                  <button
                    onClick={() => {
                      setModal({ ...modal, editSubjects: true }),
                        setCurrentInstructorId(item.instructorId);
                    }}
                  >
                    <PlusSquare color="#F0783A" />
                  </button>
                </Tooltip>
                <Tooltip className="tooltip" content={t("common:edit")}>
                  <button
                    onClick={() => {
                      setModal({ ...modal, editTeacher: true });
                      setCurrentInstructorId(item.instructorId);
                      dispatch(
                        getPersonById(item.instructorId, setEditInstructor)
                      );
                    }}
                  >
                    <Edit2 color="#14A38B" />
                  </button>
                </Tooltip>
                {/* <Popconfirm
            title="Вы уверены?"
            onConfirm={() => removeItem(item)}
            onCancel={() => false}
            okText="Да"
            cancelText="Нет"
          > */}
                <Tooltip className="tooltip" content={t("common:delete")}>
                  <button
                    onClick={async () => {
                      await setCurrentInstructor(item);
                      await setModal({ ...modal, confirm: true });
                    }}
                  >
                    <Trash color="#FF7171" />
                  </button>
                </Tooltip>
                {/* </Popconfirm> */}
              </div>
            </td>
          </tr>
        ));
  return (
    <SchoolPresettingLayout id={4}>
      <div className="school-presetting__body school-presetting__body-color">
        <div className="school-presetting__body-row">
          <div className="school-presetting__body-title">
            <h2>{t("presetting:teachers presetting")}</h2>
          </div>
          <div className="school-presetting__body-description">
            <p className="body-1">
              {t("presetting:lorem ipsum teachers presetting")}
            </p>
          </div>
        </div>
        <div className="school-presetting__subjects-list">
          <div className="school-presetting__search-filter">
            <div className="school-presetting__search">
              <SearchField
                onChange={searchInputHandler}
                defaultValue={searchText}
                placeholder={`${t("common:search")}`}
              />
            </div>
            <div className="school-presetting__filter">
              <button
                className="primary small"
                onClick={() => setModal({ ...modal, step1: true })}
              >
                {t("presetting:create teachers presetting")}
              </button>
            </div>
          </div>
          {instructorsStore.length > 0 ? (
            searchText !== "" && searchedCurrentData.length == 0 ? (
              <NoData messages={[`${t("schoolAdmin:here is nothing")}`]} />
            ) : (
              <>
                <div className="school-presetting__teachers-body">
                  <div className="school-presetting__teachers-table">
                    {/* <table>{tableRender}</table> */}
                    <table>
                      <thead>
                        <tr>
                          <th>№</th>
                          <th>{t("presetting:full name presetting")}</th>
                          <th>{t("presetting:subjects presetting")}</th>
                          <th>{t("presetting:phone presetting")}</th>
                          <th>{t("presetting:action presetting")}</th>
                        </tr>
                      </thead>
                      <tbody>{tableDataRender}</tbody>
                    </table>

                    {selectedData.length > 0 && (
                      <div className="edit-table">
                        <div className="edit-table-left">
                          <div>
                            <p className="caption-1">
                              {t("presetting:choosen presetting")}:
                              <span>{selectedData.length}</span>
                            </p>
                          </div>
                          <div>
                            <button onClick={clearSelected}>
                              {t("presetting:consel presetting")}
                            </button>
                          </div>
                        </div>
                        <div className="edit-table-right">
                          <div>
                            <button
                              onClick={() =>
                                setModal({ ...modal, confirm2: true })
                              }
                            >
                              {t("presetting:delete presetting")}
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="pagination">
                  <div className="paginaiton__inner">
                    <div className="pagination__per-page">
                      <p>
                        {t("presetting:showing presetting")}{" "}
                        <input
                          type="phone"
                          defaultValue={perPage}
                          onChange={getPerPage}
                        />{" "}
                        {t("presetting:from presetting")}{" "}
                        {searchedCurrentData.length > 0
                          ? searchedCurrentData.length
                          : instructorsStore.length}{" "}
                        {t("presetting:lines presetting")}
                      </p>
                    </div>
                    <div className="pagination__page">
                      <PaginationBlock
                        total={
                          searchedCurrentData.length > 0
                            ? searchedCurrentData.length
                            : instructorsStore.length
                        }
                        current={currentPage}
                        onChange={onChange}
                        pageSize={perPage}
                      />
                    </div>
                  </div>
                </div>
              </>
            )
          ) : (
            <NoData messages={[`${t("schoolAdmin:here is nothing")}`]} />
          )}
        </div>
        <div className="school-presetting__body-row school-presetting__body-buttons">
          <Link to="/school-presetting/3">
            <button className="secondary small">{t("common:back")}</button>
          </Link>
          {/* <Link to="/school-presetting/5"> */}
          <button onClick={onSubmit} className="primary large">
            {t("presetting:save and continue presetting school regulations")}
          </button>
          {/* </Link> */}
        </div>
      </div>
      <Modal
        isOpen={modal.step1}
        onRequestClose={() => setModal({ ...modal, step1: false })}
        overlayClassName="modal-overlay"
      >
        <div className="modal">
          <div className="modal-close">
            <button onClick={() => setModal({ ...modal, step1: false })}>
              <X size={15} color="#9A9A9A" />
            </button>
          </div>
          <div className="modal-header">
            <div className="modal-header-title">
              <h2>{t("presetting:creating a teacher profile presetting")}</h2>
            </div>
            <div className="modal-header-description">
              <p className="body-2">
                {t(
                  "presetting:fill in all the fields below and proceed presetting"
                )}
              </p>
            </div>
          </div>
          <div className="modal-body">
            <div className="modal-inputs-list">
              <div className="modal-input">
                <input
                  type="text"
                  placeholder={t("presetting:surname presetting")}
                  name="lastName"
                  defaultValue={newTeacher.lastName}
                  onChange={onInputChange}
                />
              </div>
              <div className="modal-input">
                <input
                  type="text"
                  placeholder={t("presetting:name presetting")}
                  name="firstName"
                  defaultValue={newTeacher.firstName}
                  onChange={onInputChange}
                />
              </div>
              <div className="modal-input">
                <input
                  type="text"
                  placeholder={t("presetting:middlename presetting")}
                  name="middleName"
                  defaultValue={newTeacher.middleName}
                  onChange={onInputChange}
                />
              </div>
              <div className="modal-input">
                <input
                  type="text"
                  placeholder="996700123456"
                  name="phone"
                  defaultValue={newTeacher.phone}
                  onChange={onInputChange}
                />
              </div>
              <div className="modal-input">
                <DatePickerCalendar
                  placeholder={t("presetting:date of birth presetting")}
                  onSelect={onInputChange}
                  defaultValue={newTeacher.dateOfBirth}
                  name="dateOfBirth"
                />
              </div>
              {/* <div className="modal-input">
                <SelectSearch
                  options={[
                    {
                      name: `${t("presetting:director presetting")}`,
                      value: "6",
                      key: "roleId",
                    },
                    {
                      name: `${t("presetting:head teacher presetting")}`,
                      value: "7",
                      key: "roleId",
                    },
                  ]}
                  search
                  filterOptions={fuzzySearch}
                  name="roleId"
                  onChange={(_, k) => {
                    onInputChange(k);
                  }}
                  required
                  placeholder={t("presetting:role presetting")}
                />
              </div> */}
              <div className="modal-input">
                <p className="body-2">{t("presetting:gender presetting")}</p>
                <div className="gender">
                  <div>
                    <input
                      type="radio"
                      name="gender"
                      value="1"
                      checked={newTeacher.gender == 1}
                      onChange={onInputChange}
                    />
                    <label htmlFor="gender">
                      {t("presetting:male presetting")}
                    </label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      name="gender"
                      value="2"
                      checked={newTeacher.gender == 2}
                      onChange={onInputChange}
                    />
                    <label htmlFor="gender">
                      {t("presetting:female presetting")}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="large primary"
              onClick={() => {
                if (!/^[0-9]{12}$/.test(newTeacher.phone)) {
                  toast.error(
                    i18n.t("toastify:phoneNumberInvalid"),
                    toastOption
                  );
                } else {
                  createTeacher();
                }
              }}
            >
              {t("presetting:go to next step presetting")}
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modal.step2}
        onRequestClose={() => {
          setModal({ ...modal, step2: false });
          setCurrentInstructorId("");
          setSelectedCourseId("");
          dispatch(getInstructors());
        }}
        overlayClassName="modal-overlay"
        preventScroll={true}
        onAfterOpen={() => {
          dispatch(getCourses());
          dispatch(getInstructorCourses(currentInstructorId));
        }}
      >
        <div className="modal">
          <div className="modal-close">
            <button
              onClick={() => {
                setModal({ ...modal, step2: false });
                setCurrentInstructorId("");
                setSelectedCourseId("");
                dispatch(getInstructors());
              }}
            >
              <X size={15} color="#9A9A9A" />
            </button>
          </div>
          <div className="modal-header">
            <div className="modal-header-title">
              <h2>{t("presetting:adding subjects presetting")}</h2>
            </div>
            <div className="modal-header-description">
              <p className="body-2">
                {t("presetting:find in the search engine presetting")}
              </p>
            </div>
          </div>
          <div className="modal-body">
            <div className="modal-subject">
              <div className="modal-subject-select">
                <div className="modal-subject-selector" style={{ width: 300 }}>
                  <SelectSearch
                    options={subjectsRender}
                    search
                    filterOptions={fuzzySearch}
                    name="course"
                    value={selectedCourseId}
                    onChange={(_, k) => {
                      setSelectedCourseId(k.value);
                    }}
                    required
                    placeholder={t("presetting:subject")}
                  />
                </div>
                <div className="modal-subject-select-button">
                  <button
                    className="primary small"
                    disabled={!selectedCourseId}
                    onClick={() =>
                      addSubject(currentInstructorId, selectedCourseId)
                    }
                  >
                    {t("common:add")}
                  </button>
                </div>
              </div>
              <div className="modal-subject-list">
                {instructorCoursesStore.length > 0 ? (
                  instructorCoursesStore?.map((item, idx) => (
                    <div className="modal-subject-item" key={idx}>
                      <div className="modal-subject-item-title">
                        <p className="body-1">
                          {String(i18n.language).toUpperCase() !== "EN" &&
                          String(i18n.language).toUpperCase() !== "TR"
                            ? item[
                                `title${String(i18n.language).toUpperCase()}`
                              ]
                            : item.title}
                        </p>
                      </div>
                      <div className="modal-subject-item-buttons">
                        <div>
                          <button onClick={() => removeSubject(item.id)}>
                            <Trash size={24} color="#FF7171" />
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <NoData
                    messages={[`${t("presetting:no added items presetting")}`]}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="large primary"
              onClick={() => {
                setModal({ ...modal, step2: false });
                setCurrentInstructorId("");
                setSelectedCourseId("");
                dispatch(getInstructors());
              }}
            >
              {t("common:ready")}
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modal.editTeacher}
        onRequestClose={() => setModal({ ...modal, editTeacher: false })}
        onAfterOpen={async () => {
          setEditInstructor(instructorStore);
        }}
        overlayClassName="modal-overlay"
      >
        <div className="modal">
          <div className="modal-close">
            <button onClick={() => setModal({ ...modal, editTeacher: false })}>
              <X size={15} color="#9A9A9A" />
            </button>
          </div>
          <div className="modal-header">
            <div className="modal-header-title">
              <h2>{t("presetting:editing teacher profile presetting")}</h2>
            </div>
            <div className="modal-header-description">
              <p className="body-2">
                {t("presetting:edit the fields presetting")}
              </p>
            </div>
          </div>
          <div className="modal-body">
            <div className="modal-inputs-list">
              <div className="modal-input">
                <input
                  type="text"
                  placeholder={`${t("presetting:surname presetting")}`}
                  name="lastName"
                  defaultValue={editInstructor.lastName}
                  onChange={onEditInputChange}
                />
              </div>
              <div className="modal-input">
                <input
                  type="text"
                  placeholder={`${t("presetting:name presetting")}`}
                  name="firstName"
                  defaultValue={editInstructor.firstName}
                  onChange={onEditInputChange}
                />
              </div>
              <div className="modal-input">
                <input
                  type="text"
                  placeholder={`${t("presetting:middlename presetting")}`}
                  name="middleName"
                  defaultValue={editInstructor.middleName}
                  onChange={onEditInputChange}
                />
              </div>
              <div className="modal-input">
                <input
                  type="text"
                  placeholder="996700123456"
                  name="phone"
                  defaultValue={editInstructor.phone}
                  onChange={onEditInputChange}
                />
              </div>

              <div className="modal-input">
                <DatePickerCalendar
                  placeholder={t("presetting:date of birth presetting")}
                  onSelect={onEditInputChange}
                  defaultValue={moment(
                    editInstructor.dateOfBirth,
                    "YYYY-MM-DD"
                  )}
                  name="dateOfBirth"
                />
              </div>

              {/* <div className="modal-input">
                <DatePickerCalendar
                  placeholder="Дата рождения"
                  onSelect={onEditInputChange}
                  defaultValue={moment(editInstructor.dateOfBirth, "YYYY-MM-DD")}
                  name="dateOfBirth"
                />
              </div> */}
              <div className="modal-input">
                <p className="body-2">{t("presetting:gender presetting")}</p>
                <div className="gender">
                  <div>
                    <input
                      type="radio"
                      name="gender"
                      value="1"
                      checked={editInstructor.gender == 1}
                      onChange={onEditInputChange}
                    />
                    <label htmlFor="gender">
                      {t("presetting:male presetting")}
                    </label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      name="gender"
                      checked={editInstructor.gender == 2}
                      value="2"
                      onChange={onEditInputChange}
                    />
                    <label htmlFor="gender">
                      {t("presetting:female presetting")}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="large primary"
              onClick={() => {
                if (!/^[0-9]{12}$/.test(editInstructor.phone)) {
                  toast.error(
                    i18n.t("toastify:phoneNumberInvalid"),
                    toastOption
                  );
                } else {
                  editTeacher();
                }
              }}
            >
              {t("common:save")}
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modal.editSubjects}
        onRequestClose={() => {
          setModal({ ...modal, editSubjects: false });
          setCurrentInstructorId("");
          setSelectedCourseId("");
          dispatch(getInstructors());
        }}
        overlayClassName="modal-overlay"
        preventScroll={true}
        onAfterOpen={() => {
          dispatch(getCourses());
          dispatch(getInstructorCourses(currentInstructorId));
        }}
      >
        <div className="modal">
          <div className="modal-close">
            <button
              onClick={() => {
                setModal({ ...modal, editSubjects: false });
                setCurrentInstructorId("");
                setSelectedCourseId("");
                dispatch(getInstructors());
              }}
            >
              <X size={15} color="#9A9A9A" />
            </button>
          </div>
          <div className="modal-header">
            <div className="modal-header-title">
              <h2>{t("presetting:adding subjects only string presetting")}</h2>
            </div>
            <div className="modal-header-description">
              <p className="body-2">
                {t("presetting:find in the search engine presetting")}
              </p>
            </div>
          </div>
          <div className="modal-body">
            <div className="modal-subject">
              <div className="modal-subject-select">
                <div className="modal-subject-selector" style={{ width: 300 }}>
                  <SelectSearch
                    options={subjectsRender}
                    search
                    filterOptions={fuzzySearch}
                    name="course"
                    value={selectedCourseId}
                    onChange={(_, k) => {
                      setSelectedCourseId(k.value);
                    }}
                    required
                    placeholder={t("presetting:subject")}
                  />
                </div>
                <div className="modal-subject-select-button">
                  <button
                    className="primary small"
                    disabled={!selectedCourseId}
                    onClick={() =>
                      addSubject(currentInstructorId, selectedCourseId)
                    }
                  >
                    {t("common:add")}
                  </button>
                </div>
              </div>
              <div className="modal-subject-list">
                {instructorCoursesStore.length > 0 ? (
                  instructorCoursesStore?.map((item, idx) => (
                    <div className="modal-subject-item" key={idx}>
                      <div className="modal-subject-item-title">
                        <p className="body-1">
                          {String(i18n.language).toUpperCase() !== "EN" &&
                          String(i18n.language).toUpperCase() !== "TR"
                            ? item[
                                `title${String(i18n.language).toUpperCase()}`
                              ]
                            : item.title}
                        </p>
                      </div>
                      <div className="modal-subject-item-buttons">
                        <div>
                          <button onClick={() => removeSubject(item.id)}>
                            <Trash size={24} color="#FF7171" />
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <NoData
                    messages={[`${t("presetting:no added items presetting")}`]}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="large primary"
              onClick={() => {
                setModal({ ...modal, editSubjects: false });
                setCurrentInstructorId("");
                setSelectedCourseId("");
                dispatch(getInstructors());
              }}
            >
              {t("common:save")}
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modal.confirm}
        onRequestClose={() => {
          setModal({ ...modal, confirm: false });
        }}
        overlayClassName="modal-overlay"
      >
        <div className="modal">
          <div className="modal-close">
            <button
              onClick={() => {
                setModal({ ...modal, confirm: false });
              }}
            >
              <X size={15} color="#9A9A9A" />
            </button>
          </div>
          <div className="modal-header">
            <div className="modal-header-title">
              <h2>{t("presetting:are u shure presetting")}</h2>
            </div>
            <div className="modal-header-description">
              <p className="body-2">{t("schoolAdmin:delete warning")}</p>
            </div>
          </div>

          <div className="modal-footer" style={{ marginTop: "24px" }}>
            <button
              className="large secondary"
              onClick={() => setModal({ ...modal, confirm: false })}
            >
              {t("common:cancel")}
            </button>
            <button
              className="large primary"
              onClick={async () => {
                await removeItem(currentInstructor);
                await setModal({ ...modal, confirm: false });
                await setCurrentInstructor({});
              }}
            >
              {t("common:delete")}
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modal.confirm2}
        onRequestClose={() => {
          setModal({ ...modal, confirm2: false });
        }}
        overlayClassName="modal-overlay"
      >
        <div className="modal">
          <div className="modal-close">
            <button
              onClick={() => {
                setModal({ ...modal, confirm2: false });
              }}
            >
              <X size={15} color="#9A9A9A" />
            </button>
          </div>
          <div className="modal-header">
            <div className="modal-header-title">
              <h2>{t("presetting:are u shure presetting")}</h2>
            </div>
            <div className="modal-header-description">
              <p className="body-2">{t("schoolAdmin:delete warning")}</p>
            </div>
          </div>

          <div className="modal-footer" style={{ marginTop: "24px" }}>
            <button
              className="large secondary"
              onClick={() => setModal({ ...modal, confirm2: false })}
            >
              {t("common:cancel")}
            </button>
            <button
              className="large primary"
              onClick={async () => {
                await removeSelected(selectedData);
                await setModal({ ...modal, confirm2: false });
                await setCurrentInstructor({});
              }}
            >
              {t("common:delete")}
            </button>
          </div>
        </div>
      </Modal>
    </SchoolPresettingLayout>
  );
}
