/* eslint-disable indent */
import { urls, toastOption } from "../../config";
import { API } from "../../utils";
import { toast } from "react-toastify";
import i18n from "../../i18n";

import {
  GET_SHIFTS_REQUEST,
  GET_SHIFTS_SUCCESS,
  GET_SHIFTS_ERROR,
} from "../actionTypes";

export const getShifts = () => async (dispatch) => {
  dispatch(getShiftsRequest());

  await API.get(
    `${urls.SHIFT}school/${JSON.parse(localStorage.getItem("school"))?.id}`
  )
    .then((res) => {
      console.log(res.data);

      dispatch(getShiftsSuccess(res.data));
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getShiftsFailure(error.message));
      return error.message;
    });
};

export const createShift =
  (values, shiftTimes = []) =>
  async (dispatch) => {
    const data = {
      title: values.title,
      schoolId: JSON.parse(localStorage.getItem("school"))?.id,
    };

    await API.post(`${urls.SHIFT}create`, data)
      .then(async (res) => {
        console.log(res.data);

        if (shiftTimes.length > 0) {
          let data2 = shiftTimes.map((item) => ({
            ...item,
            shiftId: res.data.message,
          }));
          API.post(`${urls.SHIFT_TIME}bulk-operation`, data2)
            .then((res) => {
              toast.success(i18n.t("toastify:succsess added"), toastOption);
              console.log(res.data);
              return res.data;
            })
            .catch((error) => {
              console.log(error);
              toast.error(i18n.t("toastify:error"), toastOption);
              return error.message;
            });
        }

        dispatch(getShifts());
        return res.data;
      })
      .catch((error) => {
        console.log(error);
        toast.error(i18n.t("toastify:error"), toastOption);
        return error.message;
      });
  };
export const deleteShift = (id) => async (dispatch) => {
  dispatch(getShiftsRequest());
  await API.delete(`${urls.SHIFT}?id=${id}`)
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess deleted"), toastOption);
      dispatch(getShifts());
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getShiftsFailure(error.message));
      return error.message;
    });
};
export const editQuartersMassive = (values) => async (dispatch) => {
  dispatch(getShiftsRequest());
  const data = {
    quarterDtoList: values,
  };
  await API.post(`${urls.QUARTER}update-massive`, data)
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess updated"), toastOption);
      dispatch(getShifts());
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getShiftsFailure(error.message));
      return error.message;
    });
};

export const getShiftsRequest = () => {
  return {
    type: GET_SHIFTS_REQUEST,
  };
};

export const getShiftsSuccess = (data) => {
  return {
    type: GET_SHIFTS_SUCCESS,
    payload: data,
  };
};

export const getShiftsFailure = (error) => {
  return {
    type: GET_SHIFTS_ERROR,
    payload: error,
  };
};
