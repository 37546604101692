import {
  GET_SHIFTS_TIMES_REQUEST,
  GET_SHIFTS_TIMES_SUCCESS,
  GET_SHIFTS_TIMES_ERROR,
} from "../actionTypes";
import { statuses } from "../../config";

const initialState = {
  shiftTimes: [],
  error: "",
  status: statuses.INITIAL,
};

export const shiftTimesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SHIFTS_TIMES_REQUEST:
      return {
        ...state,
        shiftTimes: [],
        error: "",
        status: statuses.LOADING,
      };
    case GET_SHIFTS_TIMES_SUCCESS:
      return {
        ...state,
        shiftTimes: action.payload,
        error: "",
        status: statuses.SUCCESS,
      };
    case GET_SHIFTS_TIMES_ERROR:
      return {
        ...state,
        error: action.payload,
        status: statuses.ERROR,
        shiftTimes: [],
      };

    default:
      return state;
  }
};
