import { urls, toastOption } from "../../config";
import { API } from "../../utils";
import { toast } from "react-toastify";
import i18n from "../../i18n";

import {
  GET_SCHEDULES_REQUEST,
  GET_SCHEDULES_SUCCESS,
  GET_SCHEDULES_ERROR,
  GET_SCHEDULES2_REQUEST,
  GET_SCHEDULES2_SUCCESS,
  GET_SCHEDULES2_ERROR,
  GET_SCHEDULE_REQUEST,
  GET_SCHEDULE_SUCCESS,
  GET_SCHEDULE_ERROR,
} from "../actionTypes";

export const getSchedules = () => async (dispatch) => {
  dispatch(getSchedulesRequest());
  await API.get(
    `${urls.SCHEDULE}school/${JSON.parse(localStorage.getItem("school"))?.id}`
  )
    .then((res) => {
      console.log(res.data);
      dispatch(getSchedulesSuccess(res.data));
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getSchedulesFailure(error.message));
      return error.message;
    });
};
export const getSchedulesByClassId = (classId) => async (dispatch) => {
  dispatch(getSchedulesRequest());
  await API.get(`${urls.SCHEDULE}class/${classId}`)
    .then((res) => {
      console.log(res.data);
      dispatch(getSchedulesSuccess(res.data));
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getSchedulesFailure(error.message));
      return error.message;
    });
};
export const getSchedulesByClassIdCourseIdInstructorId =
  (values) => async (dispatch) => {
    dispatch(getSchedules2Request());
    await API.get(
      `${urls.SCHEDULE}month/instructor/${
        JSON.parse(localStorage.getItem("person"))?.id
      }/class/${values.classId}/course/${values.courseId}?startDate=${
        values.startDate
      }&endDate=${values.endDate}`
    )
      .then((res) => {
        console.log(res.data);
        dispatch(getSchedules2Success(res.data));
        return res.data;
      })
      .catch((error) => {
        console.log(error);
        toast.error(i18n.t("toastify:error"), toastOption);
        dispatch(getSchedules2Failure(error.message));
        return error.message;
      });
  };
export const getSchedulesByInstructorId =
  (instructorId) => async (dispatch) => {
    dispatch(getSchedulesRequest());
    await API.get(`${urls.SCHEDULE}instructor/${instructorId}`)
      .then((res) => {
        console.log(res.data);
        dispatch(getSchedulesSuccess(res.data));
        return res.data;
      })
      .catch((error) => {
        console.log(error);
        toast.error(i18n.t("toastify:error"), toastOption);
        dispatch(getSchedulesFailure(error.message));
        return error.message;
      });
  };
export const getScheduleById = (id, setSchedule) => async (dispatch) => {
  dispatch(getScheduleRequest());
  await API.get(`${urls.SCHEDULE}${id}`)
    .then((res) => {
      console.log(res.data);
      setSchedule && (setSchedule = res.data);
      dispatch(getScheduleSuccess(res.data));
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getScheduleFailure(error.message));
      return error.message;
    });
};
export const addSchedule = (value) => async (dispatch) => {
  const data = value;
  await API.post(`${urls.SCHEDULE}create`, data)
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess added"), toastOption);
      dispatch(getSchedules());
      return res.data;
    })
    .catch((error) => {
      console.log(JSON.stringify(error.response));

      toast.error("Повторите попытку!", toastOption);
      return error.message;
    });
};
export const addMassiveSchedule = (value) => async () => {
  const data = value;
  await API.post(`${urls.SCHEDULE}create-massive`, data)
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess added"), toastOption);
      return res.data;
    })
    .catch((error) => {
      console.log(JSON.stringify(error.response));

      toast.error("Повторите попытку!", toastOption);
      return error.message;
    });
};
export const updateSchedule = (value) => async (dispatch) => {
  const data = value;

  await API.post(`${urls.SCHEDULE}edit/${value.id}`, data)
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess updated"), toastOption);
      dispatch(getSchedules());
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      return error.message;
    });
};
export const deleteSchedule = (id) => async (dispatch) => {
  dispatch(getSchedulesRequest());
  await API.delete(`${urls.SCHEDULE}?id=${id}`)
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess deleted"), toastOption);
      dispatch(getSchedules());
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getSchedulesFailure(error.message));
      return error.message;
    });
};

export const deleteScheduleByShiftId = (shiftId) => async (dispatch) => {
  dispatch(getSchedulesRequest());
  await API.delete(
    `${urls.SCHEDULE}school/${
      JSON.parse(localStorage.getItem("school"))?.id
    }/shift/${shiftId}`
  )
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess deleted"), toastOption);
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getSchedulesFailure(error.message));
      return error.message;
    });
};
export const getScheduleClassLoad = () => async (dispatch) => {
  dispatch(getSchedulesRequest());
  await API.get(
    `${urls.SCHEDULE}class/load/school/${
      JSON.parse(localStorage.getItem("school"))?.id
    }`
  )
    .then((res) => {
      console.log(res.data);
      dispatch(getSchedulesSuccess(res.data));
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getSchedulesFailure(error.message));
      return error.message;
    });
};

export const createScheduleClassLoad = (value) => async (dispatch) => {
  const data = value;
  await API.post(`${urls.SCHEDULE}class/load/create`, data)
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess added"), toastOption);
      dispatch(getScheduleClassLoad());
      return res.data;
    })
    .catch((error) => {
      console.log(JSON.stringify(error.response));

      toast.error("Повторите попытку!", toastOption);
      return error.message;
    });
};
export const deleteScheduleClassLoad = (id) => async (dispatch) => {
  dispatch(getSchedulesRequest());
  await API.delete(`${urls.SCHEDULE}class/load?id=${id}`)
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess deleted"), toastOption);
      dispatch(getScheduleClassLoad());
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getSchedulesFailure(error.message));
      return error.message;
    });
};

export const editScheduleClassLoad = (value) => async (dispatch) => {
  const data = value;
  await API.post(`${urls.SCHEDULE}class/load/edit`, data)
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess updated"), toastOption);
      dispatch(getScheduleClassLoad());
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      return error.message;
    });
};

export const getSchedulesPersonConstraint = () => async (dispatch) => {
  dispatch(getSchedulesRequest());
  await API.get(
    `${urls.SCHEDULE}person/constraint/school/${
      JSON.parse(localStorage.getItem("school"))?.id
    }`
  )
    .then((res) => {
      console.log(res.data);
      dispatch(getSchedulesSuccess(res.data));
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getSchedulesFailure(error.message));
      return error.message;
    });
};
export const createSchedulesPersonConstraint = (value) => async (dispatch) => {
  const data = value;
  await API.post(`${urls.SCHEDULE}person/constraint/create`, data)
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess added"), toastOption);
      dispatch(getSchedulesPersonConstraint());
      return res.data;
    })
    .catch((error) => {
      console.log(JSON.stringify(error.response));

      toast.error("Повторите попытку!", toastOption);
      return error.message;
    });
};

export const deleteSchedulesPersonConstraint = (id) => async (dispatch) => {
  dispatch(getSchedulesRequest());
  await API.delete(`${urls.SCHEDULE}person/constraint?id=${id}`)
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess deleted"), toastOption);
      dispatch(getSchedulesPersonConstraint());
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getSchedulesFailure(error.message));
      return error.message;
    });
};

export const getSchedulesCourseConstraint = () => async (dispatch) => {
  dispatch(getSchedulesRequest());
  await API.get(
    `${urls.SCHEDULE}course/constraint/school/${
      JSON.parse(localStorage.getItem("school"))?.id
    }`
  )
    .then((res) => {
      console.log(res.data);
      dispatch(getSchedulesSuccess(res.data));
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getSchedulesFailure(error.message));
      return error.message;
    });
};
export const createSchedulesCourseConstraint = (value) => async (dispatch) => {
  const data = value;
  await API.post(`${urls.SCHEDULE}course/constraint/create`, data)
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess added"), toastOption);
      dispatch(getSchedulesCourseConstraint());
      return res.data;
    })
    .catch((error) => {
      console.log(JSON.stringify(error.response));

      toast.error("Повторите попытку!", toastOption);
      return error.message;
    });
};

export const deleteSchedulesCourseConstraint = (id) => async (dispatch) => {
  dispatch(getSchedulesRequest());
  await API.delete(`${urls.SCHEDULE}course/constraint?id=${id}`)
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess deleted"), toastOption);
      dispatch(getSchedulesCourseConstraint());
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getSchedulesFailure(error.message));
      return error.message;
    });
};

export const getScheduleAutoGenerate = (values) => async (dispatch) => {
  dispatch(getSchedulesRequest());
  await API.get(
    `https://schedule.kundoluk.kg/schedule/auto-generate?schoolId=${
      JSON.parse(localStorage.getItem("school"))?.id
    }&shiftId=${values.shiftId}`
  )
    .then((res) => {
      localStorage.setItem("autoSchedule", JSON.stringify(res.data));
      console.log(res.data);
      dispatch(getSchedulesSuccess(res.data));
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getSchedulesFailure(error.message));
      return error.message;
    });
};

export const getSchedulesRequest = () => {
  return {
    type: GET_SCHEDULES_REQUEST,
  };
};

export const getSchedulesSuccess = (data) => {
  return {
    type: GET_SCHEDULES_SUCCESS,
    payload: data,
  };
};

export const getSchedulesFailure = (error) => {
  return {
    type: GET_SCHEDULES_ERROR,
    payload: error,
  };
};

export const getSchedules2Request = () => {
  return {
    type: GET_SCHEDULES2_REQUEST,
  };
};

export const getSchedules2Success = (data) => {
  return {
    type: GET_SCHEDULES2_SUCCESS,
    payload: data,
  };
};

export const getSchedules2Failure = (error) => {
  return {
    type: GET_SCHEDULES2_ERROR,
    payload: error,
  };
};

export const getScheduleRequest = () => {
  return {
    type: GET_SCHEDULE_REQUEST,
  };
};

export const getScheduleSuccess = (data) => {
  return {
    type: GET_SCHEDULE_SUCCESS,
    payload: data,
  };
};

export const getScheduleFailure = (error) => {
  return {
    type: GET_SCHEDULE_ERROR,
    payload: error,
  };
};
