import {
  GET_PARENTS_REQUEST,
  GET_PARENTS_SUCCESS,
  GET_PARENTS_ERROR,
  GET_PARENT_REQUEST,
  GET_PARENT_SUCCESS,
  GET_PARENT_ERROR,
} from "../actionTypes";
import { statuses } from "../../config";

const initialState = {
  parents: [],
  parent: [],
  error: "",
  status: statuses.INITIAL,
};

export const parentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PARENTS_REQUEST:
      return {
        ...state,
        parents: [],
        error: "",
        status: statuses.LOADING,
      };
    case GET_PARENTS_SUCCESS:
      return {
        ...state,
        parents: action.payload,
        error: "",
        status: statuses.SUCCESS,
      };
    case GET_PARENTS_ERROR:
      return {
        ...state,
        parents: [],
        error: action.payload,
        status: statuses.ERROR,
      };
    case GET_PARENT_REQUEST:
      return {
        ...state,
        parent: [],
        error: "",
        status: statuses.LOADING,
      };
    case GET_PARENT_SUCCESS:
      return {
        ...state,
        parent: action.payload,
        error: "",
        status: statuses.SUCCESS,
      };
    case GET_PARENT_ERROR:
      return {
        ...state,
        parent: [],
        error: action.payload,
        status: statuses.ERROR,
      };

    default:
      return state;
  }
};
