/* eslint-disable no-inner-declarations */
import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectSearch, { fuzzySearch } from "react-select-search";
import PT from "prop-types";
import { ChevronRight } from "react-feather";
import { debounce } from "lodash";

import {
  DatePickerCalendar,
  GoBack,
  NoData,
  SchoolAdminLayout,
} from "../../components";

import StatisticModal from "../../components/Statistics/StatisticModal";
import TeacherStatisticDetailes from "../../components/Statistics/TeacherStatisticDetailes";
import { gradeCountAnalyticByInstructorId } from "../../store/analytic/action";

import {
  getInstructors,
  getkClassCourses,
  getkInstructorCourses,
  getkInstructorsHWMark,
  getkInstructorsMark,
  getkInstructorsTopicMark,
} from "../../store/instructor/actions";
import "./SchoolAdmin.scss";
import { useTranslation } from "react-i18next";

const ProgressBar = (props) => {
  const { completed } = props;

  const containerStyles = {
    height: "8px",
    width: "100%",
    backgroundColor: "#e0e0de",
    borderRadius: 50,
    margin: 50,
    flexDirection: "row-reverse",
  };

  const fillerStyles = {
    height: "100%",
    width: `${completed}%`,
    transition: "width 1s ease-in-out",
    background: "linear-gradient(268.73deg, #F0783A 2.73%, #FFC34F 88.79%)",
    borderRadius: "inherit",
    textAlign: "right",
  };

  const labelStyles = {
    padding: 5,
    color: "white",
    fontWeight: "bold",
  };

  return (
    <div style={containerStyles}>
      <div style={fillerStyles}>
        <span style={labelStyles}></span>
      </div>
    </div>
  );
};

function StatisticsDetails() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [modal, setModal] = useState(false);
  const [instructorId, setInstructorId] = useState(null);
  const [startDate, setStartDate] = useState(`- ${t("schoolAdmin:start")} -`);
  const [endDate, setEndDate] = useState(`- ${t("schoolAdmin:end")} -`);
  const [visible, setVisible] = useState(true);
  const [activeButton, setActiveButton] = useState("Оценки");
  const [classID, setClassID] = useState(null);

  useEffect(() => {
    dispatch(getInstructors());
    onChange(1);
  }, []);

  const instructorsStore = useSelector(
    (state) => state?.instructors?.instructors
  );

  const classStudentsCount = useSelector(
    (state) => state?.analytic?.instructorStutisticsClasses
  );

  const instructorsDataStore = useSelector(
    (state) => state?.analytic?.studentsCountAnalytics
  );
  const marksStore = useSelector(
    (state) => state?.instructors?.instructorsGradeMark
  );
  const marksTopicStore = useSelector(
    (state) => state?.instructors?.instructorsGradeTopicMark
  );
  const marksHWStore = useSelector(
    (state) => state?.instructors?.instructorsGradeHWMark
  );
  const instructors = instructorsStore?.map((item) => ({
    name: item.instructorTitle,
    value: item.instructorUserId,
    key: item.instructorUsername,

    instructorCourseTitleDtoList: item.instructorCourseTitleDtoList,
    instructorId: item.instructorId,
    instructorPhone: item.instructorPhone,
    instructorTitle: item.instructorTitle,
    instructorUserId: item.instructorUserId,
    instructorUsername: item.instructorUsername,
  }));

  function instructorStutistics() {
    instructorId &&
      dispatch(
        gradeCountAnalyticByInstructorId(
          instructorId,
          startDate !==
            `- ${t("presetting:start presetting school regulations")} -` &&
            startDate,
          endDate !==
            `- ${t("presetting:end presetting school regulations")} -` &&
            endDate
        )
      );
    setVisible(false);
  }

  const columns = useMemo(
    () => [
      {
        Header: `${t("schoolAdmin:classes")}`,
        accessor: "class",
      },
      {
        Header: `${t("schoolAdmin:number of students")}`,
        accessor: "students",
      },
      {
        Header: `${t("schoolAdmin:grade count")}`,
        accessor: "assessment",
      },
      {
        Header: `${t("schoolAdmin:passes count")}`,
        accessor: "passed_length",
      },
      {
        Header: `${t("schoolAdmin:indicator")}`,
        accessor: "indicator",
      },
    ],
    [classStudentsCount, instructorsDataStore]
  );

  function getMarks(e) {
    dispatch(getkInstructorCourses(instructorId));
    dispatch(getkInstructorsMark(instructorId, startDate, endDate, e));
    dispatch(getkInstructorsTopicMark(instructorId, e));
    dispatch(getkInstructorsHWMark(instructorId, e));
    dispatch(getkClassCourses(e));
    setClassID(e);
  }

  const data = useMemo(() => {
    let a = [];

    // console.log("instructorsDataStore", instructorsDataStore);
    // console.log("classStudentsCount", classStudentsCount);
    instructorsDataStore?.map((item) => {
      classStudentsCount?.map((data) => {
        let b = {};
        if (item.classId === data.classId) {
          let people = data.femaleCount + data.maleCount;

          function gradeCount() {
            if (item.gradeCount > people) {
              return people;
            } else {
              return item.gradeCount;
            }
          }

          let progressComplatedIntagfer = Math.round(
            (gradeCount() / people) * 100
          );

          // console.log(`${item.classTitle}`, gradeCount());
          b[`id`] = item.classId;
          b[`class`] = item.classTitle;
          b[`students`] = data.femaleCount + data.maleCount;
          b[`assessment`] = gradeCount();
          b[`passed_length`] = item.attendanceCount;
          b[`indicator`] = (
            <span>
              <p>
                <ProgressBar completed={progressComplatedIntagfer} />
              </p>
              <p className="progress-bar-persentage">
                {progressComplatedIntagfer}%
              </p>
              <p
                className="more_details"
                onClick={() => {
                  setModal(true);
                  getMarks(item.classId);
                  setPerPage(10);
                  setTopicPerPage(10);
                  // console.log("item", item);
                }}
              >
                {t("schoolAdmin:detail")}
              </p>
            </span>
          );
        }
        a.push(b);
      });
    });

    const filteredFinalYearArr = a.reduce((o, i) => {
      Object.keys(i).length > 0 && o.push(i);
      return o;
    }, []);

    return filteredFinalYearArr;
  }, [classStudentsCount, instructorsDataStore]);
  // !@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredAllData, setFilteredAllData] = useState([]);
  const [currentData, setCurrentData] = useState([]);
  const [perPage, setPerPage] = useState(10);
  // ! TOPICS
  const [currentTopicPage, setCurrentTopicPage] = useState(1);
  const [topicFilteredAllData, setTopicFilteredAllData] = useState([]);
  const [topicCurrentData, setTopicCurrentData] = useState([]);
  const [topicPerPage, setTopicPerPage] = useState(10);
  // ! HW
  const [currentHWPage, setCurrentHWPage] = useState(1);
  const [hwFilteredAllData, setHWFilteredAllData] = useState([]);
  const [hwCurrentData, setHWCurrentData] = useState([]);
  const [hwPerPage, setHWPerPage] = useState(10);
  // !@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  const [inpute, setInpute] = useState();

  const filterButtons = [
    {
      id: "Оценки",
      title: `${t("schoolAdmin:evaluations")}`,
    },
    {
      id: "Темы",
      title: `${t("schoolAdmin:themes")}`,
    },
    {
      id: "Домашнее задание",
      title: `${t("schoolAdmin:assignment")}`,
    },
    {
      id: "Итоговые оценки",
      title: `${t("schoolAdmin:final grades")}`,
    },
  ];

  const buttons = filterButtons.map((item, idx) => (
    <button
      key={idx}
      className="buttons"
      style={{
        background: `${activeButton == item.id ? "#FFF2EC" : "#fff"}`,
      }}
      onClick={() => {
        setActiveButton(item.id);
        setHWPerPage(10);
        setTopicPerPage(10);
        setPerPage(10);
      }}
    >
      {item.title}
    </button>
  ));

  const modalData = useMemo(() => {
    let a = [];
    marksStore?.map((item) => {
      let b = {};
      b[`studentTitle`] = item.studentTitle;
      b[`classTitle`] = item.classTitle;
      b[`gradeDate`] = item.gradeDate;
      b[`gradeMark`] = item.gradeMark;
      b[`courseTitleRU`] = item.courseTitleRU;
      a.push(b);
    });

    const filteredFinalYearArr = a.reduce((o, i) => {
      Object.keys(i).length > 0 && o.push(i);
      return o;
    }, []);

    return filteredFinalYearArr;
  }, [
    classStudentsCount,
    instructorsDataStore,
    buttons,
    currentData,
    marksStore,
  ]);
  const modalTopicData = useMemo(() => {
    let a = [];
    marksTopicStore?.map((item) => {
      let b = {};
      b[`classTitle`] = item.classTitle;
      b[`courseTitleRU`] = item.courseTitleRU;
      b[`courseTitleKG`] = item.courseTitleKG;
      b[`courseTitle`] = item.courseTitle;
      b[`topicDatePlan`] = item.topicDatePlan;
      b[`topicTitle`] = item.topicTitle;
      a.push(b);
    });

    const filteredFinalYearArr = a.reduce((o, i) => {
      Object.keys(i).length > 0 && o.push(i);
      return o;
    }, []);

    return filteredFinalYearArr;
  }, [
    classStudentsCount,
    instructorsDataStore,
    buttons,
    topicCurrentData,
    marksTopicStore,
  ]);
  const modalHWData = useMemo(() => {
    let a = [];
    marksHWStore?.map((item) => {
      let b = {};
      b[`classTitle`] = item.classTitle;
      b[`courseTitleRU`] = item.courseTitleRU;
      b[`courseTitleKG`] = item.courseTitleKG;
      b[`courseTitle`] = item.courseTitle;
      b[`deadlineAt`] = item.deadlineAt;
      b[`topicTitle`] = item.topicTitle;
      a.push(b);
    });

    const filteredFinalYearArr = a.reduce((o, i) => {
      Object.keys(i).length > 0 && o.push(i);
      return o;
    }, []);

    return filteredFinalYearArr;
  }, [
    classStudentsCount,
    instructorsDataStore,
    buttons,
    topicCurrentData,
    marksTopicStore,
    marksHWStore,
  ]);
  //! @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

  function onPageChanged(pageNumber, perPageNumber, data = modalData) {
    const offset = (pageNumber - 1) * perPageNumber;
    const currentDataArr = data.slice(offset, offset + perPageNumber);
    setFilteredAllData(data);
    // console.log(currentDataArr);
    return setCurrentData(currentDataArr);
  }

  const onChange = async (e) => {
    // console.log("page: ", e);
    setCurrentPage(e);
    if (modalData?.length) {
      onPageChanged(e, perPage, modalData);
    } else if (filteredAllData.length > 0) {
      onPageChanged(e, perPage, filteredAllData);
    } else {
      onPageChanged(e, perPage, modalData);
    }
  };

  const debouncedPerPage = useCallback(
    debounce((text) => {
      // console.log(text);
      if (filteredAllData.length > 0) {
        onPageChanged(1, Number(text), filteredAllData);
      } else {
        onPageChanged(1, Number(text));
      }
      setPerPage(Number(text));
      setCurrentPage(1);
    }, 1000),
    [filteredAllData, modalData, marksTopicStore]
  );

  const getPerPage = (e) => {
    debouncedPerPage(e.target.value);
  };

  // ! TOPIC
  function onTopicPageChanged(
    pageNumber,
    perPageNumber,
    data = modalTopicData
  ) {
    const offset = (pageNumber - 1) * perPageNumber;
    const currentDataArr = data.slice(offset, offset + perPageNumber);
    setTopicFilteredAllData(data);
    // console.log(currentDataArr);
    return setTopicCurrentData(currentDataArr);
  }

  const onTopicChange = async (e) => {
    // console.log("page: ", e);
    setCurrentTopicPage(e);
    if (modalTopicData?.length) {
      onTopicPageChanged(e, topicPerPage, modalTopicData);
    } else if (topicFilteredAllData.length > 0) {
      onTopicPageChanged(e, topicPerPage, topicFilteredAllData);
    } else {
      onTopicPageChanged(e, topicPerPage, modalTopicData);
    }
  };

  const debouncedTopicPerPage = useCallback(
    debounce((text) => {
      // console.log(text);
      if (topicFilteredAllData.length > 0) {
        onTopicPageChanged(1, Number(text), topicFilteredAllData);
      } else {
        onTopicPageChanged(1, Number(text));
      }
      setTopicPerPage(Number(text));
      setCurrentTopicPage(1);
    }, 1000),
    [topicFilteredAllData, modalData, marksTopicStore]
  );

  const getTopicPerPage = (e) => {
    debouncedTopicPerPage(e.target.value);
  };
  // ! HW
  // console.log("marksHWStore", marksHWStore);
  function onHWPageChanged(pageNumber, perPageNumber, data = modalHWData) {
    const offset = (pageNumber - 1) * perPageNumber;
    const currentDataArr = data.slice(offset, offset + perPageNumber);
    setHWFilteredAllData(data);
    // console.log(currentDataArr);
    return setHWCurrentData(currentDataArr);
  }

  const onHWChange = async (e) => {
    // console.log("page: ", e);
    setCurrentHWPage(e);
    if (modalHWData?.length) {
      onHWPageChanged(e, hwPerPage, modalHWData);
    } else if (hwFilteredAllData.length > 0) {
      onHWPageChanged(e, hwPerPage, hwFilteredAllData);
    } else {
      onHWPageChanged(e, hwPerPage, modalHWData);
    }
  };

  const debouncedHWPerPage = useCallback(
    debounce((text) => {
      // console.log(text);
      if (hwFilteredAllData.length > 0) {
        onHWPageChanged(1, Number(text), hwFilteredAllData);
      } else {
        onHWPageChanged(1, Number(text));
      }
      setHWPerPage(Number(text));
      setCurrentHWPage(1);
    }, 1000),
    [hwFilteredAllData, modalData, marksHWStore]
  );

  const getHWPerPage = (e) => {
    debouncedHWPerPage(e.target.value);
  };
  // console.log("inpute", inpute);
  return (
    <SchoolAdminLayout>
      <GoBack title={t("common:back")} />
      <div className="top_title_stutistic_datails">
        <div className="top_title_content">
          <span className="top_title_text">
            <p className="top_title_text_bold">
              {t("schoolAdmin:teacher statistics")}
            </p>
            {t("schoolAdmin:teacher statistics description")}
          </span>
        </div>
        <div className="filter">
          <SelectSearch
            options={instructors}
            search
            className="select-search filter drop-up"
            filterOptions={fuzzySearch}
            name="quarterId"
            value={inpute}
            onChange={(_, k) => {
              setInstructorId(k.instructorId);
              setInpute(k);
            }}
            required
            placeholder={t("schoolAdmin:choose teacher")}
          />
          <div className="dates_filter">
            <DatePickerCalendar
              onSelect={(e) => {
                setStartDate(e?.target?.value);
              }}
              onChange
              placeholder={startDate}
              name="startDate"
            />
            <DatePickerCalendar
              onSelect={(e) => {
                setEndDate(e?.target?.value);
              }}
              placeholder={endDate}
              name="endDate"
            />
            <button
              className="secondary"
              style={{
                padding: "0 1rem",
                gridGap: "1rem",
              }}
              disabled={
                endDate !==
                  `- ${t("presetting:end presetting school regulations")} -` &&
                startDate !==
                  `- ${t(
                    "presetting:start presetting school regulations"
                  )} -` &&
                instructorId !== null
                  ? false
                  : true
              }
              onClick={instructorStutistics}
            >
              {t("common:search")} <ChevronRight />
            </button>
          </div>
        </div>
        <div className="teachers-stutistic-table">
          {visible ? (
            <NoData messages={[`${t("schoolAdmin:empty")}`]} />
          ) : (
            <TeacherStatisticDetailes columns={columns} data={data} />
          )}
        </div>
      </div>

      <StatisticModal
        modal={modal}
        endDate={endDate}
        startDate={startDate}
        instructorId={instructorId}
        setModal={setModal}
        data={modalData}
        filterButtons={filterButtons}
        buttons={buttons}
        classId={classID}
        currentPage={currentPage}
        activeButton={activeButton}
        perPage={perPage}
        onChange={onChange}
        setPerPage={setPerPage}
        getPerPage={getPerPage}
        currentData={currentData}
        setCurrentData={setCurrentData}
        // ! ----TOPIC----
        currentTopicPage={currentTopicPage}
        topicPerPage={topicPerPage}
        topicCurrentData={topicCurrentData}
        setTopicPerPage={setTopicPerPage}
        getTopicPerPage={getTopicPerPage}
        onTopicChange={onTopicChange}
        setTopicCurrentData={setTopicCurrentData}
        topicDate={modalTopicData}
        // ! -----HW-----
        currentHWPage={currentHWPage}
        hwPerPage={hwPerPage}
        hwCurrentData={hwCurrentData}
        setHWPerPage={setHWPerPage}
        getHWPerPage={getHWPerPage}
        onHWChange={onHWChange}
        setHWCurrentData={setHWCurrentData}
        hwData={modalHWData}
      />
    </SchoolAdminLayout>
  );
}

ProgressBar.propTypes = {
  completed: PT.any,
};

export default StatisticsDetails;
