import { urls, toastOption } from "../../config";
import { API } from "../../utils";
import { toast } from "react-toastify";
import i18n from "../../i18n";

import {
  GET_QUARTER_GRADES_REQUEST,
  GET_QUARTER_GRADES_SUCCESS,
  GET_QUARTER_GRADES_ERROR,
} from "../actionTypes";

export const getQuarterGrades = (values) => async (dispatch) => {
  dispatch(getQuarterGradesRequest());
  const data = {
    ...values,
    personId: values.personId
      ? values.personId
      : JSON.parse(localStorage.getItem("person"))?.id,
    chronicleId: JSON.parse(localStorage.getItem("chronicleActive"))?.id,
  };
  await API.post(`${urls.QUARTER_GRADE}fetch`, data)
    .then((res) => {
      console.log(res.data);

      dispatch(getQuarterGradesSuccess(res.data));
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getQuarterGradesFailure(error.message));
      return error.message;
    });
};

export const editQuarterGrade = (values) => async () => {
  // dispatch(getQuarterGradesRequest());
  const data = values;
  await API.post(`${urls.QUARTER_GRADE}edit/${values.gradeId}`, data)
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess updated"), toastOption);
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      // dispatch(getQuarterGradesFailure(error.message));
      return error.message;
    });
};
export const deleteQuarterGrade = (values) => async () => {
  // dispatch(getQuarterGradesRequest());
  await API.delete(`${urls.QUARTER_GRADE}?id=${values.gradeId}`)
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess deleted"), toastOption);
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      // dispatch(getQuarterGradesFailure(error.message));
      return error.message;
    });
};

export const addQuarterGrade = (values) => async () => {
  const data = values;
  await API.post(`${urls.QUARTER_GRADE}create`, data)
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess added"), toastOption);
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      return error.message;
    });
};
export const getQuarterGradesRequest = () => {
  return {
    type: GET_QUARTER_GRADES_REQUEST,
  };
};

export const getQuarterGradesSuccess = (data) => {
  return {
    type: GET_QUARTER_GRADES_SUCCESS,
    payload: data,
  };
};

export const getQuarterGradesFailure = (error) => {
  return {
    type: GET_QUARTER_GRADES_ERROR,
    payload: error,
  };
};
