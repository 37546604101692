/* eslint-disable indent */
import React, { useEffect } from "react";
import { useTable } from "react-table";
import PT from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
// import Modal from "react-modal";
// import { X } from "react-feather";
import moment from "moment";
import { statuses } from "../../config";
// import { toast } from "react-toastify";

import { getShifts } from "../../store/shift/actions";
import { getShiftTimes } from "../../store/shiftTime/actions";
import { getSchoolById } from "../../store/school/actions";
import { getGradesStudentFetch } from "../../store/grade/actions";
import { getCoursesByStudentId } from "../../store/course/actions";
import { getQuarters } from "../../store/quarter/actions";

StudentProfileGrade.propTypes = {
  quarterId: PT.number.required,
  studentId: PT.number.required,
};

export default function StudentProfileGrade(props) {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  const { quarterId, studentId } = props;
  const dispatch = useDispatch();
  const shiftsStore = useSelector((store) => store.shifts.shifts);
  const shiftTimesStore = useSelector((store) =>
    store.shiftTimes.shiftTimes.sort((a, b) =>
      a.startTime < b.startTime ? -1 : a.startTime > b.startTime ? 1 : 0
    )
  );
  const quartersStore = useSelector((store) => store.quarters.quarters);
  const gradesStore = useSelector((store) => store.grades.grades);
  const gradesStatus = useSelector((store) => store.grades.status);
  const studentsStore = useSelector((store) => store.students.students);

  const coursesByStudentIdStore = useSelector(
    (store) => store.courses.coursesByStudentId.data
  );
  // const [modal, setModal] = useState({
  //   createGrade: false,
  //   editGrade: false,
  // });
  // const [count, setCount] = useState(0);
  // const [currentCell, setCurrentCell] = useState({});

  useEffect(async () => {
    await dispatch(getQuarters());
    await dispatch(getCoursesByStudentId(studentId));
    await dispatch(getShifts());
    await dispatch(getShiftTimes());
    await dispatch(
      getSchoolById(JSON.parse(localStorage.getItem("school"))?.id)
    );
  }, [studentId, quarterId]);

  useEffect(async () => {
    await dispatch(
      getGradesStudentFetch({
        startDate: quartersStore.find((item) => item.id == quarterId)
          ?.startDate,
        endDate: quartersStore.find((item) => item.id == quarterId)?.endDate,
        studentId: studentId,
        courseId: null,
      })
    );
  }, [quarterId]);

  function getAvg(data) {
    const total = data.reduce((acc, c) => acc + c, 0);
    return total / data.length;
  }

  let a =
    gradesStore.length > 0
      ? gradesStore
          ?.filter(
            (value, index, self) =>
              index === self.findIndex((t) => t.gradeDate === value.gradeDate)
            // unique
          )
          .sort(
            (a, b) => a.gradeDate?.split("-")[2] - b.gradeDate?.split("-")[2]
          )
          .sort(
            (a, b) => a.gradeDate?.split("-")[1] - b.gradeDate?.split("-")[1]
          )

          .map((grade) => {
            return {
              Header: `${moment()
                .month(grade.gradeDate?.split("-")[1] - 1)
                .format("MMMM")}`,
              // metaId: `${day.id}`,
              width: 50,
              accessor: `${grade.gradeDate?.split("-")[1]}`,
              columns: [
                {
                  Header: `${grade.gradeDate?.split("-")[2]}\n\n(${
                    shiftTimesStore.find((item) => item.id == grade.shiftTimeId)
                      ?.title
                  })`,
                  shiftTimeId: `${grade.shiftTimeId}`,
                  date: `${grade.gradeDate}`,
                  accessor: `${grade.gradeDate?.split("-")[1]}-${
                    grade.gradeDate?.split("-")[2]
                  }-${grade.shiftTimeId}`,
                },
              ],
            };
          })
      : [];
  const columns = React.useMemo(
    () => [
      {
        Header: t("schoolAdmin:subjects"),
        accessor: "courses",
        width: 300,
      },
      ...a,
      {
        Header: t("instructor:average"),
        accessor: "average",
        // width: 150,
      },
    ],
    [gradesStore, studentsStore, shiftsStore, shiftTimesStore]
  );
  const data = React.useMemo(() => {
    let a = [];
    coursesByStudentIdStore
      .sort((a, b) => a.courseTitle - b.courseTitle)
      .map((courses) => {
        if (
          gradesStore.filter(
            (grade) => grade.studentCourseId === courses.studentCourseId
          ).length > 0
        ) {
          gradesStore
            .filter(
              (grade) => grade.studentCourseId === courses.studentCourseId
            )
            .map((grade) => {
              if (
                a.find(
                  (item) => item.studentCourseId == courses.studentCourseId
                )
              ) {
                let objIndex = a.findIndex(
                  (obj) => obj.studentCourseId == courses.studentCourseId
                );
                a[objIndex][
                  `${grade.gradeDate?.split("-")[1]}-${
                    grade.gradeDate?.split("-")[2]
                  }-${grade.shiftTimeId}`
                ] =
                  grade.mark == "92"
                    ? `${t("instructor:not attendent")}`
                    : grade.mark == "95"
                    ? `${t("instructor:not attendent")}`
                    : grade.mark == "97"
                    ? gradesStore
                        .filter(
                          (item) =>
                            item.studentCourseId === courses.studentCourseId
                        )
                        .filter((item) => item.gradeMarkType === "GRADE")
                        .filter((item) => item.gradeDate == grade.gradeDate)
                        .filter(
                          (item) => item.shiftTimeId === grade.shiftTimeId
                        )?.[0]?.mark
                    : grade.mark;
                a[objIndex][
                  `${grade.gradeDate?.split("-")[1]}-${
                    grade.gradeDate?.split("-")[2]
                  }-${grade.shiftTimeId}-grade`
                ] = grade.mark;
              } else {
                a.push({
                  courses: `${
                    String(i18n.language).toUpperCase() !== "EN" &&
                    String(i18n.language).toUpperCase() !== "TR"
                      ? `${
                          courses[
                            `courseTitle${String(i18n.language).toUpperCase()}`
                          ]
                        }`
                      : `${courses.courseTitle}`
                  }\n\n${courses.instructorTitle}`,
                  studentCourseId: courses.studentCourseId,
                  [`${grade.gradeDate?.split("-")[1]}-${
                    grade.gradeDate?.split("-")[2]
                  }-${grade.shiftTimeId}`]:
                    grade.mark == "92"
                      ? `${t("instructor:not attendent")}`
                      : grade.mark == "95"
                      ? `${t("instructor:not attendent")}`
                      : grade.mark == "97"
                      ? gradesStore
                          .filter(
                            (item) =>
                              item.studentCourseId === courses.studentCourseId
                          )
                          .filter((item) => item.gradeMarkType === "GRADE")
                          // .filter((item) => item.gradeDate == grade.gradeDate)
                          .filter(
                            (item) => item.shiftTimeId === grade.shiftTimeId
                          )?.[0]?.mark
                      : grade.mark,
                  [`${grade.gradeDate?.split("-")[1]}-${
                    grade.gradeDate?.split("-")[2]
                  }-${grade.shiftTimeId}-grade`]: grade.mark,
                  average:
                    Math.round(
                      getAvg(
                        gradesStore
                          .filter(
                            (grade) =>
                              grade.studentCourseId === courses.studentCourseId
                          )
                          .filter((grade) => grade.gradeMarkType === "GRADE")
                          .map((grade) => Number(grade.mark))
                          .filter((grade) => typeof grade === "number")
                      ) * 100
                    ) / 100
                      ? Math.round(
                          getAvg(
                            gradesStore
                              .filter(
                                (grade) =>
                                  grade.studentCourseId ===
                                  courses.studentCourseId
                              )
                              .filter(
                                (grade) => grade.gradeMarkType === "GRADE"
                              )
                              .map((grade) => Number(grade.mark))
                              .filter((grade) => typeof grade === "number")
                          ) * 100
                        ) / 100
                      : " ",
                });
              }
            });
        } else {
          a.push({
            courses: `${
              String(i18n.language).toUpperCase() !== "EN" &&
              String(i18n.language).toUpperCase() !== "TR"
                ? `${
                    courses[`courseTitle${String(i18n.language).toUpperCase()}`]
                  }`
                : `${courses.courseTitle}`
            }\n\n${courses.instructorTitle}`,
            studentCourseId: courses.studentCourseId,
          });
        }
      });
    return a;
  }, [coursesByStudentIdStore, gradesStore, studentsStore, quarterId]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  return (
    <>
      {gradesStatus === statuses.SUCCESS && (
        <>
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup, idx1) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={idx1}>
                  {headerGroup.headers.map((column, idx2) => (
                    <th
                      {...column.getHeaderProps({
                        style: {
                          // whiteSpace: "pre-wrap",
                          whiteSpace: "pre-line",

                          textAlign: "center",
                          // rotate: "90deg",
                          // transform: "rotate(90deg)",

                          // background: "rgba(255, 0, 0, 0.03)",
                        },
                      })}
                      key={idx2}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {rows.map((row, idx3) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} key={idx3}>
                    {row.cells.map((cell, idx4) => {
                      return (
                        <td
                          {...row.getRowProps({
                            style: {
                              // width: `${cell.column.width}`,
                              // whiteSpace: "pre-wrap",
                              // textAlign: "center",
                              // background: "rgba(255, 0, 0, 0.03)",
                            },
                            onClick: () => {
                              console.log(cell);
                            },
                          })}
                          key={idx4}
                          meta-type={
                            cell.column.id === "courses" ? "courses" : "grade"
                          }
                          className={
                            gradesStore
                              ?.filter(
                                (item) =>
                                  item.studentCourseId ==
                                  cell.row.original.studentCourseId
                              )
                              ?.filter(
                                (item) => item.gradeDate == cell.column.date
                              )
                              .filter(
                                (item) =>
                                  item.shiftTimeId == cell.column?.shiftTimeId
                              )?.[0]?.mark == 97
                              ? "grade-orange"
                              : gradesStore
                                  .filter(
                                    (item) =>
                                      item.studentCourseId ==
                                      cell.row.original.studentCourseId
                                  )
                                  ?.filter(
                                    (item) => item.gradeDate == cell.column.date
                                  )
                                  .filter(
                                    (item) =>
                                      item.shiftTimeId ==
                                      cell.column?.shiftTimeId
                                  )[0]?.mark == 95
                              ? "grade-red"
                              : gradesStore
                                  ?.filter(
                                    (item) =>
                                      item.studentCourseId ==
                                      cell.row.original.studentCourseId
                                  )
                                  ?.filter(
                                    (item) => item.gradeDate == cell.column.date
                                  )
                                  .filter(
                                    (item) =>
                                      item.shiftTimeId ==
                                      cell.column?.shiftTimeId
                                  )?.[0]?.mark == 92 && "grade-blue"
                          }
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                      // }
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      )}

      {/* <Modal
        isOpen={modal.createGrade}
        onRequestClose={() => setModal({ ...modal, createGrade: false })}
        overlayClassName="modal-overlay"
        onAfterOpen={async () => {}}
      >
        <div className="modal">
          <div className="modal-close">
            <button onClick={() => setModal({ ...modal, createGrade: false })}>
              <X size={15} color="#9A9A9A" />
            </button>
          </div>
          <div className="modal-header">
            <div className="modal-header-title">
              <h2 className="roboto"></h2>
            </div>
            <div className="modal-header-description"></div>
          </div>
          <div className="modal-body">
            <div className="modal-inputs-list grades">
              <div className="modal-input">
                <h5>{t("instructor:late")}</h5>
                <button
                  onClick={() => {
                    // sendMark({
                    //   ...currentCell,
                    //   gradeMarkType: "ATTENDANCE",
                    //   mark: "97",
                    // });
                    setModal({ ...modal, createGrade: false });
                  }}
                >
                  &nbsp;
                </button>
              </div>
              <div className="modal-input">
                <h5>{t("instructor:absent for no valid reason")}</h5>
                <button
                  onClick={() => {
                    // sendMark({
                    //   ...currentCell,
                    //   gradeMarkType: "ATTENDANCE",
                    //   mark: "95",
                    // });
                    setModal({ ...modal, createGrade: false });
                  }}
                >
                  {t("instructor:not attendent")}
                </button>
              </div>
              <div className="modal-input">
                <h5>{t("instructor:missing for a good reason")}</h5>
                <button
                  onClick={() => {
                    // sendMark({
                    //   ...currentCell,
                    //   gradeMarkType: "ATTENDANCE",
                    //   mark: "92",
                    // });
                    setModal({ ...modal, createGrade: false });
                  }}
                >
                  {t("instructor:not attendent")}
                </button>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            {gradesStore
              .filter(
                (item) => item.studentCourseId == currentCell.studentCourseId
              )
              .filter((item) => item.gradeMarkType == "ATTENDANCE").length >
              0 && (
              <button
                className="large primary"
                onClick={async () => {
                  // sendMark({
                  //   studentCourseId: currentCell.studentCourseId,
                  //   shiftTimeId: currentCell.shiftTimeId,
                  //   gradeMarkType: "ATTENDANCE",
                  //   mark: "",
                  // });
                  setModal({ ...modal, createGrade: false });
                }}
              >
                {t("common:delete")}
              </button>
            )}
          </div>
        </div>
      </Modal> */}
    </>
  );
}
