import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PT from "prop-types";

import "./UnregisteredUserText.scss";

export default function UnregisteredUserText(props) {
  const { user } = props;
  console.log(user);
  const { t } = useTranslation(["common", "login"]);
  const history = useHistory();
  const lStoragePhoneNumber = JSON.parse(localStorage.getItem("phoneNumber"));

  const isValidUserApplicationForm = () => {
    console.log("user", Number(user));
    console.log("lStoragePhoneNumber", Number(lStoragePhoneNumber));
    if (Number(user) !== Number(lStoragePhoneNumber)) {
      localStorage.setItem("phoneNumber", user);
      localStorage.removeItem("userApplicationForm");
      history.push("/user-select-school");
    } else {
      history.push("/user-select-school");
    }
  };
  return (
    <div className="login-message ">
      <div className="login-message__title">
        <h3>{t("login:user not found")}</h3>
      </div>
      <div className="login-message__description">
        <p className="body-1">{t("login:fill user application form")}</p>
      </div>
      <div className="login-message__buttons">
        <button className="primary large" onClick={isValidUserApplicationForm}>
          {t("login:send an application")}
        </button>
        <Link to="/">{t("common:back")}</Link>
      </div>
    </div>
  );
}

UnregisteredUserText.propTypes = {
  user: PT.any,
};
