import {
  GET_COURSES_REQUEST,
  GET_COURSES_SUCCESS,
  GET_COURSES_ERROR,
  GET_COURSES_CLASS_ID_REQUEST,
  GET_COURSES_CLASS_ID_SUCCESS,
  GET_COURSES_CLASS_ID_ERROR,
  GET_COURSES_STUDENT_ID_REQUEST,
  GET_COURSES_STUDENT_ID_SUCCESS,
  GET_COURSES_STUDENT_ID_ERROR,
} from "../actionTypes";
import { statuses } from "../../config";

const initialState = {
  courses: [],
  coursesByClassId: { data: [], status: statuses.INITIAL },
  coursesByStudentId: { data: [], status: statuses.INITIAL },
  error: "",
  status: statuses.INITIAL,
};

export const coursesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COURSES_REQUEST:
      return {
        ...state,
        courses: [],
        error: "",
        status: statuses.LOADING,
      };
    case GET_COURSES_SUCCESS:
      return {
        ...state,
        courses: action.payload,
        error: "",
        status: statuses.SUCCESS,
      };
    case GET_COURSES_ERROR:
      return {
        ...state,
        error: action.payload,
        status: statuses.ERROR,
        courses: [],
      };
    case GET_COURSES_CLASS_ID_REQUEST:
      return {
        ...state,
        coursesByClassId: { data: [], status: statuses.LOADING },
        error: "",
        status: statuses.LOADING,
      };
    case GET_COURSES_CLASS_ID_SUCCESS:
      return {
        ...state,
        coursesByClassId: { data: action.payload, status: statuses.SUCCESS },
        error: "",
        status: statuses.SUCCESS,
      };
    case GET_COURSES_CLASS_ID_ERROR:
      return {
        ...state,
        error: action.payload,
        status: statuses.ERROR,
        coursesByClassId: { data: [], status: statuses.ERROR },
      };
    case GET_COURSES_STUDENT_ID_REQUEST:
      return {
        ...state,
        coursesByStudentId: { data: [], status: statuses.LOADING },
        error: "",
        status: statuses.LOADING,
      };
    case GET_COURSES_STUDENT_ID_SUCCESS:
      return {
        ...state,
        coursesByStudentId: { data: action.payload, status: statuses.SUCCESS },
        error: "",
        status: statuses.SUCCESS,
      };
    case GET_COURSES_STUDENT_ID_ERROR:
      return {
        ...state,
        error: action.payload,
        status: statuses.ERROR,
        coursesByStudentId: { data: [], status: statuses.ERROR },
      };

    default:
      return state;
  }
};
