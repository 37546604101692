/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import { useTable } from "react-table";
import PT from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { statuses } from "../../config";

import { getClassBySchoolId } from "../../store/class/actions";
import { getShifts } from "../../store/shift/actions";
import { getShiftTimes } from "../../store/shiftTime/actions";
import { getSchoolById } from "../../store/school/actions";
import { getStudentsByCourseIdClassIdInstructorId } from "../../store/student/actions";
import { getQuarters } from "../../store/quarter/actions";

import {
  getQuarterGrades,
  addQuarterGrade,
  editQuarterGrade,
  deleteQuarterGrade,
} from "../../store/quarterGrade/actions";
// import { getSchedulesByClassIdCourseIdInstructorId } from "../../store/schedule/actions";
import { getCourses } from "../../store/course/actions";

QuarterGradeTable.propTypes = {
  classId: PT.number.required,
  courseId: PT.number.required,
};

export default function QuarterGradeTable(props) {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  const { classId, courseId } = props;
  const dispatch = useDispatch();
  // const shiftsStore = useSelector((store) => store.shifts.shifts);
  // const shiftTimesStore = useSelector((store) =>
  //   store.shiftTimes.shiftTimes.sort((a, b) =>
  //     a.startTime < b.startTime ? -1 : a.startTime > b.startTime ? 1 : 0
  //   )
  // );
  const classStore = useSelector((store) => store.class.class);
  const quartersStore = useSelector((store) =>
    store.quarters.quarters.sort(
      (a, b) => Number(a.quarter) - Number(b.quarter)
    )
  );

  const quarterGradesStore = useSelector(
    (store) => store.quarterGrades.quarterGrades
  );

  const quarterGradesStatus = useSelector(
    (store) => store.quarterGrades.status
  );
  const studentsStore = useSelector((store) => store.students.students);
  const [quarterId] = useState();

  const [count, setCount] = useState(0);

  useEffect(async () => {
    await dispatch(getQuarters());
    await dispatch(getCourses());
    await dispatch(getQuarters());

    await dispatch(
      getStudentsByCourseIdClassIdInstructorId({
        classId: classId,
        courseId: courseId,
      })
    );
    await dispatch(getClassBySchoolId());
    await dispatch(getShifts());
    await dispatch(getShiftTimes());
    await dispatch(
      getSchoolById(JSON.parse(localStorage.getItem("school"))?.id)
    );
    console.log("aa", {
      classId: classId,
      courseId: courseId,
      quarterId: quarterId,
      startDate: quartersStore.find((item) => item.id == quarterId)?.startDate,
      endDate: quartersStore.find((item) => item.id == quarterId)?.endDate,
    });
  }, [classId, courseId]);

  useEffect(async () => {
    await dispatch(
      getQuarterGrades({
        classId: classId,
        courseId: courseId,
        startDate: quartersStore.find((item) => item.id == quarterId)
          ?.startDate,
        endDate: quartersStore.find((item) => item.id == quarterId)?.endDate,
      })
    );
  }, [count, classId, courseId]);

  const sendMark = async (values) => {
    let gradeExist = await quarterGradesStore
      .filter((item) => item.studentCourseId == values.studentCourseId)
      .filter((item) => item.quarterId == values.quarterId)
      .filter((item) => item.gradeMarkType == values.gradeMarkType);
    if (gradeExist.length > 0) {
      await dispatch(
        editQuarterGrade({
          gradeId: gradeExist?.[0]?.gradeId,
          gradeMarkType: values.gradeMarkType,
          mark: values.mark,
          studentCourseId: values.studentCourseId,
        })
      );
    } else {
      await dispatch(
        addQuarterGrade({
          classId: classId,
          gradeMarkType: values.gradeMarkType,
          mark: `${values.mark}`,
          personId: JSON.parse(localStorage.getItem("person"))?.id,
          quarterId: values.quarterId,
          studentCourseId: values.studentCourseId,
        })
      );
    }
    await setCount(count + 1);
  };

  const deleteMark = async (values) => {
    let gradeExist = quarterGradesStore
      .filter((item) => item.studentCourseId == values.studentCourseId)
      .filter((item) => item.quarterId == values.quarterId)
      .filter((item) => item.gradeMarkType == values.gradeMarkType);

    if (gradeExist.length > 0) {
      await dispatch(
        deleteQuarterGrade({
          gradeId: gradeExist?.[0]?.gradeId,
        })
      );
    }
    await setCount(count + 1);
  };

  let a =
    quartersStore.length > 0
      ? quartersStore?.map((quarter) => {
          return {
            Header: `${quarter.quarter} ${t("instructor:quarter")}`,
            // metaId: `${day.id}`,
            quarterId: `${quarter.id}`,
            accessor: `${quarter.id}-QUARTER_GRADE`,
            gradeMarkType: "QUARTER_GRADE",
          };
        })
      : [];
  const columns = React.useMemo(
    () =>
      [
        {
          Header: "№",
          accessor: "number",
          // width: 150,
        },
        {
          Header: t("validation:full name"),
          accessor: "students",
          // width: 150,
        },
        ...a,
        {
          Header: `${t("instructor:half a year")}`,
          // metaId: `${day.id}`,
          quarterId:
            quartersStore.length % 2 === 0
              ? `${quartersStore[quartersStore.length / 2 - 1]?.id}`
              : `${quartersStore[0]?.id}`,
          accessor:
            quartersStore.length % 2 === 0
              ? `${
                  quartersStore[quartersStore.length / 2 - 1]?.id
                }-HALF_YEAR_GRADE`
              : `${quartersStore[0]?.id}-HALF_YEAR_GRADE`,
          gradeMarkType: "HALF_YEAR_GRADE",
        },
        {
          Header: `${t("instructor:year")}`,
          // metaId: `${day.id}`,
          quarterId: `${quartersStore[quartersStore.length - 1]?.id}`,
          accessor: `${
            quartersStore[quartersStore.length - 1]?.id
          }-FULL_YEAR_GRADE`,
          gradeMarkType: "FULL_YEAR_GRADE",
        },
        classStore.find((item) => item.id == classId)?.classLevel == 9 ||
        classStore.find((item) => item.id == classId)?.classLevel == 11
          ? {
              Header: `${t("instructor:gos")}`,
              // metaId: `${day.id}`,
              quarterId: `${quartersStore[quartersStore.length - 1]?.id}`,
              accessor: `${
                quartersStore[quartersStore.length - 1]?.id
              }-STATE_EXAM_GRADE`,
              gradeMarkType: "STATE_EXAM_GRADE",
            }
          : { accessor: "001" },
        {
          Header: `${t("instructor:final")}`,
          // metaId: `${day.id}`,
          quarterId: `${quartersStore[quartersStore.length - 1]?.id}`,
          accessor: `${
            quartersStore[quartersStore.length - 1]?.id
          }-FINAL_GRADE`,
          gradeMarkType: "FINAL_GRADE",
        },
      ].filter((item) => item.Header),
    [quartersStore, studentsStore]
  );

  const data = React.useMemo(() => {
    let a = [];
    studentsStore
      .sort((a, b) => a.studentTitle - b.studentTitle)
      .map((student, idx) => {
        if (
          quarterGradesStore.filter(
            (grade) => grade.studentCourseId === student.studentCourseId
          ).length > 0
        ) {
          let b = {
            number: idx + 1,
            students: student.studentTitle,
            studentCourseId: student.studentCourseId,
          };
          quarterGradesStore
            .filter(
              (grade) => grade.studentCourseId === student.studentCourseId
            )
            .map((grade) => {
              b[`${grade.quarterId}-${grade.gradeMarkType}`] = grade.mark;
            });
          a.push(b);
        } else {
          a.push({
            number: idx + 1,
            students: student.studentTitle,
            studentCourseId: student.studentCourseId,
          });
        }
      });
    return a;
  }, [quarterGradesStore, studentsStore]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  return (
    <>
      {quarterGradesStatus === statuses.SUCCESS && (
        <>
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup, idx1) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={idx1}>
                  {headerGroup.headers.map((column, idx2) => (
                    <th
                      {...column.getHeaderProps()}
                      key={idx2}
                      style={{
                        writingMode: "vertical-lr",
                        transform: "rotate(180deg)",
                      }}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {rows.map((row, idx3) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} key={idx3}>
                    {row.cells.map((cell, idx4) => {
                      return (
                        <td
                          {...row.getRowProps({
                            style: {
                              width: `${cell.column.width}`,
                              cursor: "pointer",
                            },
                            onClick: () => {
                              console.log("cell", cell);
                              // setCurrentCell({
                              //   studentCourseId:
                              //     cell.row.original.studentCourseId,
                              //   shiftTimeId:
                              //     cell.column.parent?.columns?.[0]?.id?.split(
                              //       "-"
                              //     )[2],
                              // });
                            },
                            onKeyDown: async (e) => {
                              if (
                                (e.which >= 50 && e.which <= 53) ||
                                (e.which >= 98 && e.which <= 101)
                              ) {
                                // console.log("cell", cell);

                                e.target.textContent = "";
                                await sendMark({
                                  studentCourseId:
                                    cell.row.original.studentCourseId,
                                  shiftTimeId:
                                    cell.column.parent?.columns?.[0]?.id?.split(
                                      "-"
                                    )[2],
                                  quarterId: cell.column.quarterId,
                                  gradeMarkType: cell.column.gradeMarkType,
                                  mark: e.key,
                                });
                              } else if (
                                (e.which >= 37 && e.which <= 40) ||
                                e.which == 27
                              ) {
                                console.log("ss");
                              }
                            },
                            onKeyUp: async (e) => {
                              if (
                                (e.which >= 50 && e.which <= 53) ||
                                (e.which >= 98 && e.which <= 101)
                              ) {
                                console.log("aa");
                              } else if (e.keyCode == 8) {
                                console.log("8");
                                if (
                                  cell.value == "2" ||
                                  cell.value == "3" ||
                                  cell.value == "4" ||
                                  cell.value == "5"
                                ) {
                                  await deleteMark({
                                    studentCourseId:
                                      cell.row.original.studentCourseId,
                                    shiftTimeId:
                                      cell.column.parent?.columns?.[0]?.id?.split(
                                        "-"
                                      )[2],
                                    quarterId: cell.column.quarterId,
                                    gradeMarkType: cell.column.gradeMarkType,
                                    mark: e.key,
                                  });
                                }
                              } else if (
                                (e.which >= 37 && e.which <= 40) ||
                                e.which == 27
                              ) {
                                console.log("ss");
                              } else {
                                e.target.textContent = "";
                              }
                            },
                          })}
                          contentEditable={true}
                          key={idx4}
                          id={`${cell.column.parent?.columns?.[0]?.id}-${cell.row.original.studentCourseId}`}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      )}
    </>
  );
}
