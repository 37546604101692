import { urls, toastOption } from "../../config";
import { API } from "../../utils";
import { toast } from "react-toastify";
import i18n from "../../i18n";

import {
  GET_PERSONS_REQUEST,
  GET_PERSONS_SUCCESS,
  GET_PERSONS_ERROR,
  GET_PERSON_REQUEST,
  GET_PERSON_SUCCESS,
  GET_PERSON_ERROR,
} from "../actionTypes";

export const getPersons = (roleCode) => async (dispatch) => {
  dispatch(getPersonsRequest());

  await API.get(
    `${urls.PERSON}school/${
      JSON.parse(localStorage.getItem("school"))?.id
    }/role/${roleCode}`
  )
    .then((res) => {
      console.log(res.data);
      dispatch(getPersonsSuccess(res.data));
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getPersonsFailure(error.message));
      return error.message;
    });
};
export const getPersonById = (id, setPerson) => async (dispatch) => {
  dispatch(getPersonRequest());
  await API.get(`${urls.PERSON}${id}`)
    .then(async (res) => {
      console.log(res.data);
      setPerson && (await setPerson(res.data));
      console.log("person", res.data);
      dispatch(getPersonSuccess(res.data));
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getPersonFailure(error.message));
      return error.message;
    });
};
export const getPersonByUserId = (id) => async (dispatch) => {
  dispatch(getPersonRequest());
  await API.get(`${urls.PERSON}user/${id}`)
    .then(async (res) => {
      localStorage.setItem("person", JSON.stringify(res.data));
      console.log(res.data);
      dispatch(getPersonSuccess(res.data));
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getPersonFailure(error.message));
      return error.message;
    });
};
export const removePerson = (id) => async (dispatch) => {
  dispatch(getPersonsRequest());
  await API.delete(
    `${urls.PERSON}?id=${id}&schoolId=${
      JSON.parse(localStorage.getItem("school"))?.id
    }`
  )
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess deleted"), toastOption);
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getPersonsFailure(error.message));
      return error.message;
    });
};
export const editPerson = (values) => async (dispatch) => {
  dispatch(getPersonsRequest());
  const data = values;

  await API.post(`${urls.PERSON}edit/${values.id}`, data)
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess updated"), toastOption);
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getPersonsFailure(error.message));
      return error.message;
    });
};

export const activatePerson = (personId) => async (dispatch) => {
  dispatch(getPersonsRequest());
  const data = personId;

  await API.post(`${urls.PERSON}activate/${personId}`, data)
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:success"), toastOption);
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getPersonsFailure(error.message));
      return error.message;
    });
};
export const switchRole = (values) => async (dispatch) => {
  const data = {
    ...values,
    schoolId: JSON.parse(localStorage.getItem("school"))?.id,
  };

  await API.post(`${urls.USER}switch/role`, data)
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:success"), toastOption);
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getPersonsFailure(error.message));
      return error.message;
    });
};

export const createPerson = (values, setPersonId) => async () => {
  const data = {
    ...values,
    schoolId: JSON.parse(localStorage.getItem("school"))?.id,
  };
  await API.post(`${urls.PERSON}create`, data)
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess added"), toastOption);
      setPersonId && setPersonId(res.data.message);
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      return error.message;
    });
};
export const getPersonsRequest = () => {
  return {
    type: GET_PERSONS_REQUEST,
  };
};

export const getPersonsSuccess = (data) => {
  return {
    type: GET_PERSONS_SUCCESS,
    payload: data,
  };
};

export const getPersonsFailure = (error) => {
  return {
    type: GET_PERSONS_ERROR,
    payload: error,
  };
};

export const getPersonRequest = () => {
  return {
    type: GET_PERSON_REQUEST,
  };
};

export const getPersonSuccess = (data) => {
  return {
    type: GET_PERSON_SUCCESS,
    payload: data,
  };
};

export const getPersonFailure = (error) => {
  return {
    type: GET_PERSON_ERROR,
    payload: error,
  };
};
