import React, { useEffect } from "react";
import moment from "moment";
import PT from "prop-types";

export default function FullCurrentDate(props) {
  const { date } = props;
  useEffect(() => {}, [date]);
  return <div>{moment(date).format("dddd, D MMMM")}</div>;
}
FullCurrentDate.propTypes = {
  date: PT.string,
};
