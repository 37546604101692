/* eslint-disable indent */
import React, { useEffect } from "react";
import PT from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { Line } from "react-chartjs-2";

import { studentsAttendance as studentAttendanceReport } from "../../store/report/actions";
import { statuses } from "../../config";

export default function ClassStatisticsAttendance(props) {
  const { classId } = props;
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  // const studentsStore = useSelector((store) => store.students.students);
  const studentsAttendanceAnalytics = useSelector(
    (store) => store.report.studentsAttendance.data
  );
  const studentsAttendanceAnalyticsStatus = useSelector(
    (store) => store.report.studentsAttendance.status
  );
  const studentsStore = useSelector((store) => store.students.students);

  useEffect(async () => {
    await dispatch(
      studentAttendanceReport(
        classId,
        `${
          JSON.parse(localStorage.getItem("chronicleActive"))?.startYear
        }-09-01`,
        moment().format("YYYY-MM-DD")
      )
    );
  }, [classId]);

  let labels = [];
  if (Number(moment().format("M")) > 8) {
    for (let i = 8; i < Number(moment().format("M")); i++) {
      labels.push({
        monthName: moment().month(i).format("MMMM"),
        monthNameEn: moment().locale("en").month(i).format("MMMM"),
      });
    }
  } else {
    for (let i = 8; i < 12; i++) {
      labels.push({
        monthName: moment().month(i).format("MMMM"),
        monthNameEn: moment().locale("en").month(i).format("MMMM"),
      });
    }
    for (let i = 0; i < Number(moment().format("M")); i++) {
      labels.push({
        monthName: moment().month(i).format("MMMM"),
        monthNameEn: moment().locale("en").month(i).format("MMMM"),
      });
    }
  }
  console.log(
    "aaaa",
    labels.map((month) =>
      studentsAttendanceAnalytics
        .filter((item) => item.monthName.trim() === month.monthNameEn)

        .filter((item) => item.count92Day > 0)
        .filter(
          (value, index, self) =>
            index ===
            self.findIndex(
              (t) =>
                t.studentId === value.studentId &&
                t.monthName.trim() === value.monthName.trim()
            )
        )

        .reduce((acc) => {
          return acc + 1;
        }, 0)
    )
  );

  const data = {
    labels: labels.map((item) => item.monthName),
    datasets: [
      {
        // label: "Dataset 1",
        data: labels.map((month) =>
          Math.round(
            (studentsAttendanceAnalytics
              .filter((item) => item.monthName.trim() === month.monthNameEn)
              .filter((item) => item.count92Day > 0)
              .filter(
                (value, index, self) =>
                  index ===
                  self.findIndex((t) => t.studentId == value.studentId)
              )
              .reduce((acc) => {
                return acc + 1;
              }, 0) /
              studentsStore.length) *
              100
          )
        ),
        borderColor: "#F0783A",
        backgroundColor: "#F0783A",
      },
    ],
  };
  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            min: 0,
            max: 100,
          },
        },
      ],
    },
  };

  return (
    <div className="school-admin__schedule-group school-admin__schedule school-presetting__body school-presetting__body-color class-statistics_grade">
      <div className="school-presetting__body-row">
        <div className="school-presetting__body-title">
          <h2>{t("instructor:percentage of attendance")}</h2>
        </div>
      </div>
      <div className="school-presetting__body-row">
        <div className="school-presetting__body-description">
          <p>
            {moment().format("MMMM")}:{" "}
            {studentsAttendanceAnalytics
              .filter(
                (item) => item.monthName.trim() === labels?.[-1]?.monthNameEn
              )
              .reduce((acc, current) => {
                return acc + current.count92Day;
              }, 0)}
          </p>
        </div>
      </div>
      <div className="class-statistics_grade-body">
        <Line data={data} options={options} />
        <div className="class-statistics_grade-data"></div>
      </div>

      <div className="school-presetting__body-row">
        <div className="school-presetting__body-title">
          <h2>{t("instructor:number of passes this month")}</h2>
        </div>
      </div>
      <div className="school-presetting__body-row">
        <div className="school-presetting__body-description">
          <p>{moment().format("MMMM")}</p>
        </div>
      </div>
      <div className="class-statistics_grade-body">
        <div className="class-statistics_attendance-types">
          <div className="class-statistics_attendance-type">
            <div className="class-statistics_attendance-type-count roboto">
              {studentsAttendanceAnalyticsStatus === statuses.SUCCESS
                ? studentsAttendanceAnalytics
                    ?.filter(
                      (item) =>
                        item.monthName.trim() ==
                        moment().locale("en").format("MMMM")
                    )
                    .reduce((accumulator, current) => {
                      return accumulator + current?.count92Day;
                    }, 0)
                : 0}
            </div>
            <div className="class-statistics_attendance-type-text">
              {t("instructor:absent for good reason")}
            </div>
          </div>
          <div className="class-statistics_attendance-type">
            <div className="class-statistics_attendance-type-count roboto">
              {/* {studentsAttendanceAnalyticsStatus === statuses.SUCCESS */}
              {studentsAttendanceAnalytics
                .filter(
                  (item) =>
                    item.monthName.trim() ==
                    moment().locale("en").format("MMMM")
                )
                .reduce((accumulator, current) => {
                  return accumulator + current.count95Day;
                }, 0)}
            </div>
            <div className="class-statistics_attendance-type-text">
              {t("instructor:absent for no valid reason")}
            </div>
          </div>
        </div>

        <div className="class-statistics_grade-data"></div>
      </div>
    </div>
  );
}

ClassStatisticsAttendance.propTypes = {
  classId: PT.number,
};
