import {
  GET_PERSONS_REQUEST,
  GET_PERSONS_SUCCESS,
  GET_PERSONS_ERROR,
  GET_PERSON_REQUEST,
  GET_PERSON_SUCCESS,
  GET_PERSON_ERROR,
} from "../actionTypes";
import { statuses } from "../../config";

const initialState = {
  persons: [],
  person: [],
  error: "",
  status: statuses.INITIAL,
};

export const personsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PERSONS_REQUEST:
      return {
        ...state,
        persons: [],
        error: "",
        status: statuses.LOADING,
      };
    case GET_PERSONS_SUCCESS:
      return {
        ...state,
        persons: action.payload,
        error: "",
        status: statuses.SUCCESS,
      };
    case GET_PERSONS_ERROR:
      return {
        ...state,
        persons: [],
        error: action.payload,
        status: statuses.ERROR,
      };
    case GET_PERSON_REQUEST:
      return {
        ...state,
        person: [],
        error: "",
        status: statuses.LOADING,
      };
    case GET_PERSON_SUCCESS:
      return {
        ...state,
        person: action.payload,
        error: "",
        status: statuses.SUCCESS,
      };
    case GET_PERSON_ERROR:
      return {
        ...state,
        person: [],
        error: action.payload,
        status: statuses.ERROR,
      };

    default:
      return state;
  }
};
