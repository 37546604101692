import React from "react";
import { Logo } from "../icons";
import { LanguageDropdown } from "../";
import { LogOut } from "react-feather";
import { useTranslation } from "react-i18next";

export default function SchoolPresettingHeader() {
  const { t } = useTranslation(["presetting"]);
  return (
    <div className="school-presetting__header school-presetting__container">
      <div className="school-presetting__header-logo">
        <Logo />
      </div>
      <div className="school-presetting__header-laguage">
        <LanguageDropdown />
        <div className="logout">
          <button
            onClick={() => {
              localStorage.clear();
              window.location.href = "/";
            }}
          >
            <span>{t("common:exit")}</span>
            <LogOut size={12} color="#212121" />
          </button>
        </div>
      </div>
    </div>
  );
}
