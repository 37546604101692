import React, { useState } from "react";
import Calendar from "rc-calendar";
import "rc-calendar/assets/index.css";
import DatePicker from "rc-calendar/lib/Picker";
import PT from "prop-types";

import moment from "moment";
import "moment/locale/tr";
import "moment/locale/en-au";
import "moment/locale/ky";
import "moment/locale/ru";

const format = "YYYY-MM-DD";

function getFormat(time) {
  return time ? format : "YYYY-MM-DD";
}

export default function DatePickerCalendar(props) {
  // const { t, i18n } = useTranslation();

  const { defaultValue, placeholder, onSelect, name } = props;
  const calendarContainerRef = React.createRef();
  const getCalendarContainer = () => calendarContainerRef.current;
  console.log(defaultValue);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(defaultValue);

  const onChange = (value1, name) => {
    console.log(value);
    setValue(value1);
    const data = {
      target: {
        name: name,
        value: value1?.format(getFormat(true)),
      },
    };
    onSelect(data);
  };

  return (
    <div>
      <DatePicker
        animation="slide-up"
        calendar={
          <Calendar
            style={{ zIndex: 1001 }}
            dateInputPlaceholder={placeholder ? placeholder : "- Выбрать -"}
            format={getFormat(value)}
            name={name}
            focusablePanel={false}
          />
        }
        value={value ? moment(value) : ""}
        onChange={(e) => onChange(e, name)}
        name={name}
        getCalendarContainer={getCalendarContainer}
        onOpenChange={setOpen}
        open={open}
        style={{ zIndex: 1001 }}
      >
        {({ value }) => {
          return (
            <span tabIndex="0">
              <input
                placeholder={placeholder ? placeholder : "- Выбрать -"}
                style={{ width: 250 }}
                readOnly
                name={name}
                tabIndex="-1"
                className="ant-calendar-picker-input ant-input date-picker-calendar-input"
                value={value ? moment(value)?.format(getFormat(true)) : ""}
              />
              <div ref={calendarContainerRef} />
            </span>
          );
        }}
      </DatePicker>
    </div>
  );
}

DatePickerCalendar.propTypes = {
  defaultValue: PT.object,
  defaultCalendarValue: PT.object,
  placeholder: PT.string,
  name: PT.string,
  onSelect: PT.func,
};
