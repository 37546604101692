import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GoBack, SchoolAdminLayout } from "..";
import { useTable } from "react-table";
import PT from "prop-types";

import "./FinalClassGradesReport.scss";
import NoData from "../NoData/NoData";
import SelectSearch, { fuzzySearch } from "react-select-search";
import { useDispatch, useSelector } from "react-redux";
import { getClassBySchoolId } from "../../store/class/actions";
import { getChronicleYears } from "../../store/chronicleYear/actions";
import { getQuarterMark, getQuarterTitles } from "../../store/report/actions";

function SummaryStudentsReport() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const [start, setStart] = useState(false);
  const [classValue, setClassValue] = useState();
  const [classData, setClassData] = useState();
  const [yearValue, setYearValue] = useState();
  const [year, setYear] = useState();

  const classesStore = useSelector((state) => state.class.class);
  const quartersStore = useSelector((state) => state.report.quarter);

  const classes = classesStore?.map((item) => ({
    name: `${item?.classLevel} ${item?.classLabel}`,
    value: item.id,
    key: item.id,
  }));

  quartersStore.sort((a, b) => {
    return a.quarter - b.quarter;
  });

  const chronicleYears = useSelector(
    (state) => state.chronicleYears.chronicleYears
  );

  const classStore = useSelector((state) => state.report.studentsRecord);

  useEffect(() => {
    dispatch(getClassBySchoolId());
    dispatch(getChronicleYears());
  }, []);

  function yearFunction() {
    let numbersCopy = [...chronicleYears];
    numbersCopy = numbersCopy?.map((item) => ({
      name: `${item.selectorTitle} ${t("schoolAdmin:year")}`,
      value: item.id,
      key: item.id,
    }));
    return numbersCopy;
  }

  const yearState = yearFunction();

  // !
  function tableToExcel(table) {
    var uri = "data:application/vnd.ms-excel;base64,",
      template =
        '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
      base64 = function (s) {
        return window.btoa(unescape(encodeURIComponent(s)));
      },
      format = function (s, c) {
        return s.replace(/{(\w+)}/g, function (m, p) {
          return c[p];
        });
      };
    if (!table.nodeType) table = document.getElementById(table);
    var ctx = { worksheet: year.name || "Worksheet", table: table.innerHTML };
    var a = document.createElement("a");
    a.href = uri + base64(format(template, ctx));
    a.download =
      `${t("schoolAdmin:summary of student progress records")}` +
      +" " +
      year.name +
      " " +
      classData.name +
      ".xls";
    a.click();
  }
  // !

  const quarterMarkFunction = (k) => {
    dispatch(getQuarterMark(classData?.key, undefined, k.key));
    dispatch(
      getQuarterTitles(JSON.parse(localStorage.getItem("school")).id, k.key)
    );
    setStart(true);
  };

  let columns = [
    {
      Header: `${t("presetting:full name presetting")}`,
      accessor: "studentTitle",
    },
    { Header: "", accessor: "type" },
  ];

  const ids = classStore?.map((o) => o.courseId);
  const courses = classStore?.filter(
    ({ courseId }, index) => !ids.includes(courseId, index + 1)
  );

  const studentsTitle = [
    ...new Set(classStore.map((item) => item.studentTitle)),
  ];

  courses?.map((item) => {
    let a = {};
    a[`Header`] =
      String(i18n.language).toUpperCase() !== "EN" &&
      String(i18n.language).toUpperCase() !== "TR"
        ? `${item[`courseTitle${String(i18n.language).toUpperCase()}`]}`
        : `${item.courseTitle} `;
    a[`accessor`] = item.courseTitleRU;
    columns.push(a);
  });

  columns.push({
    Header: `${t(
      "schoolAdmin:decision of the pedagogical council (date and number)"
    )}`,
    accessor: `${t(
      "schoolAdmin:decision of the pedagogical council (date and number)"
    )}`,
  });

  let mockData = [];
  studentsTitle?.map((names) => {
    quartersStore.map((quarter, idx) => {
      let a = {};
      a[`type`] = `${" "} ${" "} ${idx + 1} ${" "} ${t("instructor:quarter")}`;
      classStore?.map((item) => {
        if (names == item.studentTitle) {
          a[`studentTitle`] = item.studentTitle;
          columns?.map((lesson) => {
            if (quarter.id == item.quarterId)
              if (item.courseTitleRU == lesson.accessor) {
                a[item.courseTitleRU] = item.quarterMark;
              }
          });
        }
      });
      mockData.push(a);
    });
  });

  return (
    <SchoolAdminLayout>
      <GoBack title={t("common:back")} />
      <div className="final_class_grades_report_box">
        <span
          className="final_class_grades_report_topic"
          style={{ cursor: "pointer" }}
        >
          {t("schoolAdmin:summary of student progress records")}
        </span>
        <p className="final_class_grades_report_title">
          {t("schoolAdmin:summary of student progress records text")}
        </p>

        <div className="final_class_grades_report_buttons">
          <div className="final_class_grades_report_select_search">
            <SelectSearch
              options={classes}
              // search
              filterOptions={fuzzySearch}
              value={classValue}
              name="quarterId"
              onChange={(_, k) => {
                setClassData(k);
                setClassValue(k.value);
              }}
              required
              placeholder={`- ${t("schoolAdmin:class")} -`}
            />
            <SelectSearch
              options={yearState}
              // search
              filterOptions={fuzzySearch}
              value={yearValue}
              name="classId"
              onChange={async (_, k) => {
                setYearValue(k.value);
                quarterMarkFunction(k);
                setYear(k);
              }}
              required
              placeholder={`- ${t("schoolAdmin:year")} -`}
            />
          </div>
          <button
            onClick={() => {
              tableToExcel("summaryStudentsReport_table");
            }}
            className="export_button_excel"
          >
            {t("common:export")}
          </button>
        </div>
        {classStore[0] ? (
          <div className="summaryStudentsReport_table_box">
            <Table
              id="summaryStudentsReport_table"
              columns={columns}
              data={mockData}
              start={start}
              setStart={setStart}
            />
          </div>
        ) : (
          <NoData
            messages={[`${t("schoolAdmin:select class and year of study")}`]}
          />
        )}
      </div>
    </SchoolAdminLayout>
  );
}

Table.propTypes = {
  id: PT.string,
  data: PT.array,
  columns: PT.array,
  start: PT.bool,
  setStart: PT.func,
};

export default SummaryStudentsReport;

export function Table({
  id = "summaryStudentsReport_table",
  columns,
  data,
  start,
  setStart,
}) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  useEffect(() => {
    if (start) {
      let rowLength = document.querySelector("#tbody").children.length;
      document.querySelector("#tbody_td_0_0").setAttribute("rowspan", 4);

      document.querySelector("#summaryStudentsReport_table").border = 1;
      var els = document.querySelectorAll("td[meta-type=grade]");

      for (var i = 0; i < els.length; i++) {
        els[i].style.textAlign = "center";
      }

      for (let i = 0; i < rowLength; ) {
        document.querySelector(`#tbody_td_${i}_0`).setAttribute("rowspan", 4);
        let rowLength = document.getElementById(`tbody_tr_${i}`).children
          .length;
        for (let z = 0; z < rowLength; z++) {
          document
            .querySelector(`#tbody_td_${i + 3}_${z}`)
            .setAttribute("class", "borderBottom");
        }
        document
          .querySelector(`#tbody_td_${i}_0`)
          .setAttribute("class", "first");

        document
          .querySelector(`#tbody_td_${i}_1`)
          .setAttribute("class", "second");
        document
          .querySelector(`#tbody_td_${i + 1}_1`)
          .setAttribute("class", "second");
        document
          .querySelector(`#tbody_td_${i + 2}_1`)
          .setAttribute("class", "second");
        document
          .querySelector(`#tbody_td_${i + 3}_1`)
          .setAttribute("class", "second1");
        document.getElementById(`tbody_tr_${i + 1}`).deleteCell(0);
        document.getElementById(`tbody_tr_${i + 2}`).deleteCell(0);
        document.getElementById(`tbody_tr_${i + 3}`).deleteCell(0);
        document.querySelector(`#tbody_td_${i}_1`).align = "center";
        i = i + 4;
      }
      setStart(false);
    }
  }, [start]);

  return (
    <table
      id={id}
      style={{ border: "none" }}
      className="grade_summary_information_table_class"
      {...getTableProps()}
    >
      <thead id="thead">
        {headerGroups.map((headerGroup, idx) => (
          <tr id="thead_tr" key={idx} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, idx) => (
              <th
                textOrientation={90}
                rotate={90}
                id={`thead_th_${idx}`}
                align="center"
                hAlign="center"
                vAlign="center"
                key={idx}
                text-orientation={90}
                textorientation={90}
                {...column.getHeaderProps()}
              >
                <span>{column.render("Header")}</span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody id="tbody" {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr id={`tbody_tr_${i}`} key={i} {...row.getRowProps()}>
              {row.cells.map((cell, idx) => {
                return (
                  <td
                    id={`tbody_td_${i}_${idx}`}
                    align="center"
                    vAlign="center"
                    hAlign="center"
                    key={idx}
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
