/* eslint-disable indent */
import React, { useEffect, useState, useCallback } from "react";
import { debounce } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import { X, Eye, PlusCircle, RefreshCcw } from "react-feather";
import { Link, useParams } from "react-router-dom";
import SelectSearch, { fuzzySearch } from "react-select-search";

import {
  SchoolAdminLayout,
  PaginationBlock,
  SearchField,
  DatePickerCalendar,
  NoData,
} from "../../components";

import {
  getAllStudentsBySchool,
  addStudent,
  removeStudent,
  updateStudent,
} from "../../store/student/actions";

import { addParent } from "../../store/parent/actions";
import {
  getClassBySchoolId,
  editStudentsClass,
} from "../../store/class/actions";
import { statuses } from "../../config";

export default function Students() {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  const { class_id } = useParams();

  const dispatch = useDispatch();
  // const personsStore = useSelector((store) => store.persons.persons);
  const studentsStore = useSelector((store) => store.students.students);
  const studentsStatus = useSelector((store) => store.students.status);
  const classStore = useSelector((store) => store.class.class);

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [filterTypeNumber, setFilterTypeNumber] = useState(1);
  const [filterClassTitle, setFilterClassTitle] = useState("");
  const [filteredAllData, setFilteredAllData] = useState([]);
  const [currentStudentId, setCurrentStudentId] = useState();
  const [currentData, setCurrentData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedCurrentData, setSearchedCurrentData] = useState([]);
  const [editClassId, setEditClassId] = useState();

  const [modal, setModal] = useState({
    step1: false,
    step2: false,
    editClass: false,
    confirm2: false,
  });

  const [newStudent, setNewStudent] = useState({
    lastName: "",
    firstName: "",
    middleName: "",
    phone: "",
    dateOfBirth: "2010-01-01",
    nationality: "",
    gender: 1,
    classId: "",
  });
  const [newParent, setNewParent] = useState({
    lastName: "",
    firstName: "",
    middleName: "",
    phone: "",
    dateOfBirth: "1980-01-01",
    job: "",
    jobPlace: "",
    roleId: 5,
    parentalType: "",
  });

  useEffect(async () => {
    await dispatch(getAllStudentsBySchool());
    await dispatch(getClassBySchoolId());
    if (class_id) {
      await studentsByParam();
    }
  }, []);

  const classOptions = classStore.map((item) => ({
    name: `${item.classLevel}${item.classLabel}`,
    value: item.id,
    key: "classId",
  }));
  const classTitleOptions = classStore.map((item) => ({
    name: `${item.classLevel}${item.classLabel}`,
    value: `${item.classLevel}${item.classLabel}`,
    key: "filterClassTitle",
    id: item.id,
  }));
  const onInputChange = (e) => {
    setNewStudent({
      ...newStudent,
      [e.key || e.target.name]: e.value || e.target.value,
    });
  };
  const onParentInputChange = (e) => {
    setNewParent({
      ...newParent,
      [e.key || e.target.name]: e.value || e.target.value,
    });
  };

  const onPageChanged = (
    pageNumber,
    perPageNumber,
    data = studentsStore,
    filter = filterClassTitle
  ) => {
    const offset = (pageNumber - 1) * perPageNumber;
    let currentDataArr = [];
    if (filter) {
      let a = data.filter((item) => `${item.classTitle}` === filter);
      currentDataArr = a.slice(offset, offset + perPageNumber);
      setFilteredAllData(a);
    } else {
      currentDataArr = data.slice(offset, offset + perPageNumber);
      setFilteredAllData([]);
    }
    return setCurrentData(currentDataArr);
  };

  const onFilterHandler = (classTitle) => {
    // console.log(classTitle);
    setCurrentPage(1);
    setFilterClassTitle(classTitle);
    onPageChanged(1, perPage, studentsStore, classTitle);
  };
  const onFilterTypeHandler = async (typeNumber) => {
    await setSearchText("");
    await setCurrentData([]);
    await setFilteredAllData([]);
    await setSearchedCurrentData([]);
    await setCurrentPage(1);
    await setFilterTypeNumber(typeNumber);
    await setFilterClassTitle("");
  };
  const studentsByParam = () => {
    const classById = classStore?.find((item) => item.id == class_id);
    // console.log("classById", classById?.classLevel);
    onFilterHandler(`${classById?.classLevel}${classById?.classLabel}`);
  };
  const createStudent = async () => {
    await dispatch(addStudent(newStudent, setCurrentStudentId, setModal));
    await dispatch(getAllStudentsBySchool());
    await setNewStudent({
      lastName: "",
      firstName: "",
      middleName: "",
      phone: "",
      dateOfBirth: "2010-01-01",
      nationality: "",
      classId: "",
      gender: "1",
    });
  };

  const createParent = async () => {
    // console.log({
    //   parentalType: newParent.parentalType,
    //   personDto: {
    //     ...newParent,
    //     gender: newParent.parentalType == 1 ? 1 : 2,
    //   },
    //   studentId: currentStudentId,
    // });
    await dispatch(
      addParent({
        parentalType: newParent.parentalType,
        personDto: {
          ...newParent,
          gender: newParent.parentalType == 1 ? 1 : 2,
        },
        studentId: currentStudentId,
      })
    );
    await dispatch(getAllStudentsBySchool());
    // setCurrentStudentId("");
    await setNewParent({
      lastName: "",
      firstName: "",
      middleName: "",
      phone: "",
      dateOfBirth: "1980-01-01",
      job: "",
      jobPlace: "",
      roleId: 5,
      parentalType: "",
    });
    await setModal({ ...modal, step2: false });
  };

  const debouncedPerPage = useCallback(
    debounce((text, studentsStore) => {
      if (filteredAllData.length > 0) {
        onPageChanged(1, Number(text), filteredAllData);
      } else {
        onPageChanged(1, Number(text), studentsStore);
      }
      setPerPage(Number(text));
      setCurrentPage(1);
    }, 1000),
    [studentsStore, filteredAllData]
  );
  const debouncedSearch = useCallback(
    debounce((text) => {
      if (
        studentsStore.length > 0 &&
        perPage &&
        String(text).toLowerCase() !== ""
      ) {
        setSearchText(String(text).toLowerCase());
        setCurrentPage(1);
        setFilterClassTitle("");

        const a = studentsStore.filter((item) => {
          let obj;
          Object.keys(item).map((key) => {
            if (
              key == "firstName" ||
              key == "lastName" ||
              key == "middleName" ||
              key == "phone"
            ) {
              const c = String(item[key]).toLowerCase();
              // if (c != "undefined" && c !== "NULL" && c !== null) {
              const d = c.includes(String(text).toLowerCase());
              if (d) {
                obj = item;
                return item;
              }
              // }
            }
          });
          return obj;
        });
        setSearchedCurrentData([...a]);
        onPageChanged(1, perPage, [...a]);
      } else {
        setSearchText(String(text).toLowerCase());
        setCurrentPage(1);
        setFilterClassTitle("");
        setSearchedCurrentData([]);
        onPageChanged(1, perPage);
      }
    }, 1000),
    [perPage, studentsStore]
  );

  const onChange = async (e) => {
    console.log("sss", e);
    setCurrentPage(e);
    if (searchedCurrentData.length > 0) {
      onPageChanged(e, perPage, searchedCurrentData);
    } else if (filteredAllData.length > 0) {
      onPageChanged(e, perPage, filteredAllData);
    } else {
      onPageChanged(e, perPage, studentsStore);
    }
  };
  const getPerPage = (e) => {
    debouncedPerPage(e.target.value);
  };
  const searchInputHandler = async (e) => {
    await debouncedSearch(e.target.value);
  };

  const onSelect = (value) => {
    console.log(selectedData);
    if (selectedData.find((item) => item.id === value.id)) {
      let a = selectedData.filter((item) => item.id !== value.id);
      setSelectedData([...a]);
    } else {
      setSelectedData([...selectedData, value]);
    }
  };
  const clearSelected = () => {
    setSelectedData([]);
  };
  const removeSelected = async (selected) => {
    await selected.map((item) => dispatch(removeStudent(item.id)));
    await setSelectedData([]);
    await dispatch(getAllStudentsBySchool());
  };
  const restoreItem = async (value) => {
    await dispatch(updateStudent(value));
    await dispatch(getAllStudentsBySchool(true));
  };

  const tableDataRender =
    currentData.length > 0
      ? currentData.map((item, idx) => (
          <tr
            className={
              selectedData.find((i) => i.id === item.id) ? "active" : ""
            }
            key={idx}
          >
            <td className="table-select">
              <label className="check">
                <input
                  type="checkbox"
                  onChange={() => onSelect(item)}
                  checked={selectedData.find((i) => i.id === item.id)}
                />
                <div className="box"></div>
              </label>
              <span>{idx + 1}</span>
            </td>
            <td>
              {item.lastName} {item.firstName} {item.middleName}
            </td>
            <td>{item.phone}</td>
            <td>{item.classTitle}</td>
            <td>
              <div className="school-admin__profile-link">
                {filterTypeNumber == 1 ? (
                  <Link to={`/school-admin/student-profile/${item.id}`}>
                    <button>
                      <span>{t("schoolAdmin:view profile")}</span>
                      <Eye color="#7D7D7D" />
                    </button>
                  </Link>
                ) : (
                  <div className="table-restore">
                    <button
                      onClick={() =>
                        restoreItem({ id: item.id, archived: false })
                      }
                    >
                      <span>{t("schoolAdmin:restore")}</span>
                      <RefreshCcw color="#7D7D7D" />
                    </button>
                  </div>
                )}
              </div>
            </td>
          </tr>
        ))
      : filteredAllData.length > 0
      ? filteredAllData.map((item, idx) => (
          <tr
            className={
              selectedData.find((i) => i.id === item.id) ? "active" : ""
            }
            key={idx}
          >
            <td className="table-select">
              <label className="check">
                <input
                  type="checkbox"
                  onChange={() => onSelect(item)}
                  checked={selectedData.find((i) => i.id === item.id)}
                />
                <div className="box"></div>
              </label>
              <span>{idx + 1}</span>
            </td>
            <td>
              {item.lastName} {item.firstName} {item.middleName}
            </td>
            <td>{item.phone}</td>
            <td>{item.classTitle}</td>
            <td>
              <div className="school-admin__profile-link">
                {filterTypeNumber == 1 ? (
                  <Link to={`/school-admin/student-profile/${item.id}`}>
                    <button>
                      <span>{t("schoolAdmin:view profile")}</span>
                      <Eye color="#7D7D7D" />
                    </button>
                  </Link>
                ) : (
                  <div className="table-restore">
                    <button
                      onClick={() =>
                        restoreItem({ id: item.id, archived: false })
                      }
                    >
                      <span>{t("schoolAdmin:restore")}</span>
                      <RefreshCcw color="#7D7D7D" />
                    </button>
                  </div>
                )}
              </div>
            </td>
          </tr>
        ))
      : studentsStore.slice([0], [10]).map((item, idx) => (
          <tr
            className={
              selectedData.find((i) => i.id === item.id) ? "active" : ""
            }
            key={idx}
          >
            <td className="table-select">
              <label className="check">
                <input
                  type="checkbox"
                  onChange={() => onSelect(item)}
                  checked={selectedData.find((i) => i.id === item.id)}
                />
                <div className="box"></div>
              </label>
              <span>{idx + 1}</span>
            </td>
            <td>
              {item.lastName} {item.firstName} {item.middleName}
            </td>
            <td>{item.phone}</td>
            <td>{item.classTitle}</td>
            <td>
              <div className="school-admin__profile-link">
                {filterTypeNumber == 1 ? (
                  <Link to={`/school-admin/student-profile/${item.id}`}>
                    <button>
                      <span>{t("schoolAdmin:view profile")}</span>
                      <Eye color="#7D7D7D" />
                    </button>
                  </Link>
                ) : (
                  <div className="table-restore">
                    <button
                      onClick={() =>
                        restoreItem({ id: item.id, archived: false })
                      }
                    >
                      <span>{t("schoolAdmin:restore")}</span>
                      <RefreshCcw color="#7D7D7D" />
                    </button>
                  </div>
                )}
              </div>
            </td>
          </tr>
        ));
  return (
    <SchoolAdminLayout>
      <div className="school-presetting__body school-presetting__body-color">
        <div className="school-presetting__body-row">
          <div className="school-presetting__body-title">
            <h2>{t("schoolAdmin:students")}</h2>
          </div>
          <div className="school-presetting__body-description">
            <p className="body-1">{t("schoolAdmin:lorem ipsum students")}</p>
          </div>
        </div>

        <div className="school-presetting__subjects-list">
          <div className="school-presetting__search-filter">
            <div className="school-presetting__search">
              {studentsStatus === statuses.SUCCESS && studentsStore.length > 0 && (
                <>
                  <SearchField
                    onChange={searchInputHandler}
                    defaultValue={searchText}
                    placeholder={`${t("common:search")}`}
                  />
                  <SelectSearch
                    options={[
                      {
                        name: `${t("schoolAdmin:all")}`,
                        value: "",
                        key: "filterClassTitle",
                      },
                      ...classTitleOptions,
                    ]}
                    search
                    className="select-search filter"
                    filterOptions={fuzzySearch}
                    name="filterClassTitle"
                    onChange={(_, k) => {
                      onFilterHandler(k.value);
                    }}
                    required
                    placeholder={`${t("schoolAdmin:class")}`}
                  />
                </>
              )}
            </div>

            <div className="school-presetting__filter">
              <button
                id="create_student"
                className="primary small"
                onClick={() => setModal({ ...modal, step1: true })}
              >
                {t("schoolAdmin:create a student")}
              </button>
            </div>
          </div>
          <div className="school-presetting__tabs">
            <div className="school-presetting__tabs-item">
              <button
                onClick={async () => {
                  await dispatch(getAllStudentsBySchool());
                  await onFilterTypeHandler(1);
                }}
                className={filterTypeNumber === 1 ? "active" : ""}
              >
                {t("schoolAdmin:active")}
              </button>
            </div>
            <div className="school-presetting__tabs-item">
              <button
                onClick={async () => {
                  await dispatch(getAllStudentsBySchool(true));
                  await onFilterTypeHandler(2);
                }}
                className={filterTypeNumber === 2 ? "active" : ""}
              >
                {t("schoolAdmin:archived")}
              </button>
            </div>
          </div>
          {filteredAllData.length == 0 &&
          (filterClassTitle !== "" || class_id) ? (
            <NoData messages={[`${t("schoolAdmin:empty")}`]} />
          ) : studentsStore.length > 0 ? (
            <>
              <div className="school-presetting__teachers-body">
                <div className="school-presetting__teachers-table">
                  <table>
                    <thead>
                      <tr>
                        <th>№</th>
                        <th>{t("presetting:full name presetting")}</th>
                        <th>{t("presetting:phone presetting")}</th>
                        <th>{t("presetting:classes presetting")}</th>
                        <th>{t("presetting:action presetting")}</th>
                      </tr>
                    </thead>
                    <tbody>{tableDataRender}</tbody>
                  </table>

                  {selectedData.length > 0 && (
                    <div className="edit-table">
                      <div className="edit-table-left">
                        <div>
                          <p className="caption-1">
                            {t("presetting:choosen presetting")}:
                            <span>{selectedData.length}</span>
                          </p>
                        </div>
                        <div>
                          <button onClick={clearSelected}>
                            {t("presetting:consel presetting")}
                          </button>
                        </div>
                      </div>
                      <div className="edit-table-right">
                        {filterTypeNumber === 1 && (
                          <>
                            <div>
                              <button
                                onClick={() => {
                                  setModal({ ...modal, editClass: true });
                                }}
                              >
                                Перевести
                              </button>
                            </div>
                            <div>
                              <button
                                onClick={() =>
                                  setModal({ ...modal, confirm2: true })
                                }
                              >
                                Удалить
                              </button>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="pagination">
                <div className="paginaiton__inner">
                  <div className="pagination__per-page">
                    <p>
                      {t("presetting:showing presetting")}{" "}
                      <input
                        type="phone"
                        defaultValue={perPage}
                        onChange={getPerPage}
                      />{" "}
                      {t("presetting:from presetting")}{" "}
                      {searchedCurrentData.length > 0
                        ? searchedCurrentData.length
                        : filteredAllData.length > 0
                        ? filteredAllData.length
                        : studentsStore.length}{" "}
                      {t("presetting:lines presetting")}
                    </p>
                  </div>
                  <div className="pagination__page">
                    <PaginationBlock
                      total={
                        searchedCurrentData.length > 0
                          ? searchedCurrentData.length
                          : filteredAllData.length > 0
                          ? filteredAllData.length
                          : studentsStore.length
                      }
                      current={currentPage}
                      onChange={onChange}
                      pageSize={perPage}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <NoData messages={[`${t("schoolAdmin:here is nothing")}`]} />
          )}
        </div>
      </div>
      <Modal
        isOpen={modal.step1}
        onRequestClose={() => setModal({ ...modal, step1: false })}
        overlayClassName="modal-overlay"
        onAfterOpen={() => dispatch(getClassBySchoolId())}
      >
        <div className="modal">
          <div className="modal-close">
            <button onClick={() => setModal({ ...modal, step1: false })}>
              <X size={15} color="#9A9A9A" />
            </button>
          </div>
          <div className="modal-header">
            <div className="modal-header-title">
              <h2>{t("schoolAdmin:create a student step 1")}</h2>
            </div>
            <div className="modal-header-description">
              <p className="body-2">
                {t(
                  "presetting:fill in all the fields below and proceed presetting"
                )}
              </p>
            </div>
          </div>
          <div className="modal-body">
            <div className="modal-inputs-list">
              <div className="modal-input">
                <input
                  type="text"
                  placeholder={`${t("presetting:surname presetting")}`}
                  name="lastName"
                  defaultValue={newStudent.lastName}
                  onChange={onInputChange}
                />
              </div>
              <div className="modal-input">
                <input
                  type="text"
                  placeholder={`${t("presetting:name presetting")}`}
                  name="firstName"
                  defaultValue={newStudent.firstName}
                  onChange={onInputChange}
                />
              </div>
              <div className="modal-input">
                <input
                  type="text"
                  placeholder={`${t("presetting:middlename presetting")}`}
                  name="middleName"
                  defaultValue={newStudent.middleName}
                  onChange={onInputChange}
                />
              </div>
              <div className="modal-input">
                <input
                  type="text"
                  placeholder="996700112233"
                  name="phone"
                  defaultValue={newStudent.phone}
                  onChange={onInputChange}
                />
              </div>
              <div className="modal-input">
                <DatePickerCalendar
                  placeholder={`${t("presetting:date of birth presetting")}`}
                  onSelect={onInputChange}
                  defaultValue={newStudent.dateOfBirth}
                  name="dateOfBirth"
                  required
                />
              </div>
              <div className="modal-input">
                <SelectSearch
                  options={classOptions}
                  search
                  className="select-search drop-up"
                  filterOptions={fuzzySearch}
                  name="classId"
                  value={newStudent?.classId}
                  onChange={(_, k) => {
                    onInputChange(k);
                  }}
                  required
                  placeholder={`${t("schoolAdmin:class")}`}
                />
              </div>
              <div className="modal-input">
                <input
                  type="text"
                  placeholder={`${t("schoolAdmin:nationality")}`}
                  name="nationality"
                  defaultValue={newStudent.nationality}
                  onChange={onInputChange}
                />
              </div>
              <div className="modal-input">
                <p className="body-2">{t("presetting:gender presetting")}</p>
                <div className="gender">
                  <div>
                    <input
                      type="radio"
                      name="gender"
                      value="1"
                      checked={newStudent.gender == 1}
                      onChange={onInputChange}
                    />
                    <label htmlFor="gender">
                      {t("presetting:male presetting")}
                    </label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      name="gender"
                      value="2"
                      checked={newStudent.gender == 2}
                      onChange={onInputChange}
                    />
                    <label htmlFor="gender">
                      {t("presetting:female presetting")}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              id="create_student_step1"
              className="large primary"
              onClick={() => createStudent()}
            >
              {t("presetting:go to next step presetting")}
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modal.step2}
        onRequestClose={() => setModal({ ...modal, step2: false })}
        overlayClassName="modal-overlay"
        preventScroll={true}
        onAfterOpen={() => {
          // dispatch(getCourses());
          // dispatch(getInstructorCourses(currentStudentId));
        }}
      >
        <div className="modal">
          <div className="modal-close">
            <button onClick={() => setModal({ ...modal, step2: false })}>
              <X size={15} color="#9A9A9A" />
            </button>
          </div>
          <div className="modal-header">
            <div className="modal-header-title">
              <h2>{t("schoolAdmin:info about students perents step 2")}</h2>
            </div>
            <div className="modal-header-description">
              <p className="body-2">
                {t("schoolAdmin:fill info abount this students  parants")}
              </p>
            </div>
          </div>
          <div className="modal-body">
            <div className="modal-inputs-list">
              <div className="modal-input">
                <input
                  type="text"
                  placeholder={`${t("presetting:surname presetting")}`}
                  name="lastName"
                  defaultValue={newParent.lastName}
                  onChange={onParentInputChange}
                />
              </div>
              <div className="modal-input">
                <input
                  type="text"
                  placeholder={`${t("presetting:name presetting")}`}
                  name="firstName"
                  defaultValue={newParent.firstName}
                  onChange={onParentInputChange}
                />
              </div>
              <div className="modal-input">
                <input
                  type="text"
                  placeholder={`${t("presetting:middlename presetting")}`}
                  name="middleName"
                  defaultValue={newParent.middleName}
                  onChange={onParentInputChange}
                />
              </div>
              <div className="modal-input">
                <input
                  type="text"
                  placeholder="996700112233"
                  name="phone"
                  defaultValue={newParent.phone}
                  onChange={onParentInputChange}
                />
              </div>
              <div className="modal-input">
                <DatePickerCalendar
                  placeholder={`${t("presetting:date of birth presetting")}`}
                  onSelect={onParentInputChange}
                  defaultValue={newParent.dateOfBirth}
                  name="dateOfBirth"
                />
              </div>
              <div className="modal-input">
                <SelectSearch
                  options={[
                    {
                      name: `${t("schoolAdmin:relationship father")}`,
                      value: 1,
                      key: "parentalType",
                    },
                    {
                      name: `${t("schoolAdmin:relationship mather")}`,
                      value: 2,
                      key: "parentalType",
                    },
                    {
                      name: `${t("schoolAdmin:relationship other")}`,
                      value: 3,
                      key: "parentalType",
                    },
                  ]}
                  search
                  // className="select-search drop-up"
                  filterOptions={fuzzySearch}
                  name="parentalType"
                  value={newParent?.parentalType}
                  onChange={(_, k) => {
                    onParentInputChange(k);
                  }}
                  required
                  placeholder={`${t("schoolAdmin:relationship")}`}
                />
              </div>
              <div className="modal-input">
                <input
                  type="text"
                  placeholder={`${t("schoolAdmin:profession")}`}
                  name="job"
                  defaultValue={newParent.job}
                  onChange={onParentInputChange}
                />
              </div>
              <div className="modal-input">
                <input
                  type="text"
                  placeholder={`${t("schoolAdmin:work address")}`}
                  name="jobPlace"
                  defaultValue={newParent.jobPlace}
                  onChange={onParentInputChange}
                />
              </div>
              <div className="modal-input">
                <div className="parents__new-parent-button">
                  <button
                    onClick={async () => {
                      await createParent();
                      await setModal({ ...modal, step2: true });
                    }}
                  >
                    <PlusCircle color="#F0783A" />
                    <span>{t("schoolAdmin:add next parent")}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              id="create_student_step2"
              className="large primary"
              onClick={() => createParent()}
            >
              {t("common:ready")}
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modal.editClass}
        onRequestClose={() => {
          setModal({ ...modal, editClass: false });
          setEditClassId();
        }}
        overlayClassName="modal-overlay"
        onAfterOpen={() => dispatch(getClassBySchoolId())}
      >
        <div className="modal">
          <div className="modal-close">
            <button
              onClick={() => {
                setModal({ ...modal, editClass: false });
                setEditClassId();
              }}
            >
              <X size={15} color="#9A9A9A" />
            </button>
          </div>
          <div className="modal-header">
            <div className="modal-header-title">
              <h2>{t("schoolAdmin:create a student step 1")}</h2>
            </div>
            <div className="modal-header-description">
              <p className="body-2">
                {t(
                  "presetting:fill in all the fields below and proceed presetting"
                )}
              </p>
            </div>
          </div>
          <div className="modal-body">
            <div className="modal-inputs-list">
              <div className="modal-input">
                <SelectSearch
                  options={classOptions}
                  search
                  className="select-search drop-up"
                  filterOptions={fuzzySearch}
                  name="classId"
                  onChange={(_, k) => {
                    setEditClassId(k.value);
                  }}
                  required
                  placeholder={`${t("schoolAdmin:class")}`}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="large primary"
              onClick={async () => {
                await dispatch(
                  editStudentsClass({
                    classId: editClassId,
                    studentList: selectedData.map((item) => item.id),
                  })
                );
                await setSelectedData([]);

                await dispatch(getAllStudentsBySchool());
                await setModal({ ...modal, editClass: false });
                await setEditClassId();
              }}
              disabled={!editClassId}
            >
              {t("common:save")}
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modal.confirm2}
        onRequestClose={() => {
          setModal({ ...modal, confirm2: false });
        }}
        overlayClassName="modal-overlay"
      >
        <div className="modal">
          <div className="modal-close">
            <button
              onClick={() => {
                setModal({ ...modal, confirm2: false });
              }}
            >
              <X size={15} color="#9A9A9A" />
            </button>
          </div>
          <div className="modal-header">
            <div className="modal-header-title">
              <h2>{t("presetting:are u shure presetting")}</h2>
            </div>
            <div className="modal-header-description">
              <p className="body-2">{t("schoolAdmin:delete warning")}</p>
            </div>
          </div>

          <div className="modal-footer" style={{ marginTop: "24px" }}>
            <button
              className="large secondary"
              onClick={() => setModal({ ...modal, confirm2: false })}
            >
              {t("common:cancel")}
            </button>
            <button
              className="large primary"
              onClick={async () => {
                await removeSelected(selectedData);
                await setModal({ ...modal, confirm2: false });
              }}
            >
              {t("common:delete")}
            </button>
          </div>
        </div>
      </Modal>
    </SchoolAdminLayout>
  );
}
