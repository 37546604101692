export function getCount(array) {
  let arr = [];
  let allClassTotal = {
    initialCount: 0,
    initialMaleCount: 0,
    initialFemaleCount: 0,
    finalMaleCount: 0,
    finalFemaleCount: 0,
    depCountryCount: 0,
    depRegionCount: 0,
    depRayonCount: 0,
    arrCountryCount: 0,
    arrRayonCount: 0,
    arrRegionCount: 0,
    finalCount: 0,
    quarterId: 0,
    arrAll: 0,
    depAll: 0,
  }; 
  array.map((tag) => {
    let total = {
      initialCount: 0,
      initialMaleCount: 0,
      initialFemaleCount: 0,
      finalMaleCount: 0,
      finalFemaleCount: 0,
      depCountryCount: 0,
      depRegionCount: 0,
      depRayonCount: 0,
      arrCountryCount: 0,
      arrRayonCount: 0,
      arrRegionCount: 0,
      finalCount: 0,
      quarterId: 0,
      arrAll: 0,
      depAll: 0,
    }; 
    // let total = Object.assign({}, allClassTotal);
    // console.log(total, "total");
    tag.forEach((el) => {
      total.initialCount += el.initialCount;
      total.initialMaleCount += el.initialMaleCount;
      total.finalMaleCount += el.finalMaleCount;
      total.initialFemaleCount += el.initialFemaleCount;
      total.finalFemaleCount += el.finalFemaleCount;
      total.depCountryCount += el.depCountryCount;
      total.depRegionCount += el.depRegionCount;
      total.depRayonCount += el.depRayonCount;
      total.arrCountryCount += el.arrCountryCount;
      total.arrRayonCount += el.arrRayonCount;
      total.arrRegionCount += el.arrRegionCount;
      total.finalCount += el.finalCount;
      total.quarterId = el.quarterId;
      total.depAll += el.depAll;
      total.arrAll += el.arrAll;

      allClassTotal.initialCount += el.initialCount;
      allClassTotal.initialMaleCount += el.initialMaleCount;
      allClassTotal.finalMaleCount += el.finalMaleCount;
      allClassTotal.initialFemaleCount += el.initialFemaleCount;
      allClassTotal.finalFemaleCount += el.finalFemaleCount;
      allClassTotal.depCountryCount += el.depCountryCount;
      allClassTotal.depRegionCount += el.depRegionCount;
      allClassTotal.depRayonCount += el.depRayonCount;
      allClassTotal.arrCountryCount += el.arrCountryCount;
      allClassTotal.arrRayonCount += el.arrRayonCount;
      allClassTotal.arrRegionCount += el.arrRegionCount;
      allClassTotal.finalCount += el.finalCount;
      allClassTotal.depAll += el.depAll;
      allClassTotal.arrAll += el.arrAll;
    });
    tag.push(total);
    arr = arr.concat(tag);
  });
  arr.push(allClassTotal);
  return arr;
}