import React, { useEffect, useState } from "react";
import "./UserApplicationForm.scss";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { ApplicationFormLayout } from "../../components";
import { createUserApplicationForm } from "../../store/userApplicationForm/actions";

export default function Step3() {
  const { t } = useTranslation(["common", "userApplicationForm"]);
  const lStorage = JSON.parse(localStorage.getItem("userApplicationForm"));
  const dispatch = useDispatch();
  const history = useHistory();
  const [message, setMessage] = useState(lStorage?.message);

  useEffect(() => {
    localStorage.setItem(
      "userApplicationForm",
      JSON.stringify({ ...lStorage, activeStep: 3 })
    );
  }, []);

  const setLocalStorage = () => {
    localStorage.setItem(
      "userApplicationForm",
      JSON.stringify({ ...lStorage, message })
    );
  };
  const onSubmit = () => {
    setLocalStorage();
    dispatch(
      createUserApplicationForm(
        {
          ...lStorage,
          phoneNumber: localStorage.getItem("phoneNumber") || "",
        },
        history
      )
    );
  };

  return (
    <div className="step">
      <ApplicationFormLayout style={{ minHeight: 600 }} id={3}>
        <div className="step__title">
          <p className="body-1">
            {t("userApplicationForm:write about your problem")}
          </p>
        </div>
        <div className="step__body">
          <div className="step__body-input ">
            <textarea
              // className="large"
              name="message"
              cols="50"
              rows="10"
              onChange={(e) => setMessage(e.target.value)}
              value={message}
              placeholder={t("userApplicationForm:comments")}
            ></textarea>
          </div>

          <div className="step__body-submit">
            <Link to="/user-application-form/2">
              <button className="secondary small">{t("common:back")}</button>
            </Link>
            {/* <Link to="/user-application-form/final"> */}
            <button
              className="primary small"
              disabled={!message}
              onClick={onSubmit}
            >
              {t("common:send")}
            </button>
            {/* </Link> */}
          </div>
        </div>
      </ApplicationFormLayout>
    </div>
  );
}
