import React, { useEffect, useState } from "react";
import ReactCodeInput from "react-verification-code-input";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginByPhone, createCode } from "../../store/auth/actions.js";

import "./SMSVerificationValidator.scss";

export default function SMSVerificationValidator() {
  const countdown = 10;
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [showTimer] = useState(true);
  const [counter, setCounter] = useState(countdown);
  const dispatch = useDispatch();
  const history = useHistory();
  const { phone_number } = useParams();
  useEffect(() => {
    startTimer();
  }, []);

  const onComplete = (e) => {
    console.log(e);
    setIsLoading(true);
    dispatch(
      loginByPhone(
        { smsCode: e, phoneNumber: phone_number },
        setIsDisabled,
        setIsLoading
      )
    );
  };

  const startTimer = () => {
    let timer = countdown;

    let interval = setInterval(() => {
      console.log("timer", timer);

      if (timer == 0) {
        console.log("finish timer");
        stopInt();
      } else {
        setCounter((c) => c - 1);
        timer = timer - 1;
      }
    }, 1000);
    const stopInt = () => {
      clearInterval(interval);
      return null;
    };
  };

  const resendCode = () => {
    setCounter(countdown);
    dispatch(createCode(phone_number));
    startTimer();
  };
  const padTime = (time) => {
    return String(time).length === 1 ? `0${time}` : `${time}`;
  };

  const format = (time) => {
    // Convert seconds into minutes and take the whole part
    const minutes = Math.floor(time / 60);

    // Get the seconds left after converting minutes
    const seconds = time % 60;

    //Return combined values as string in format mm:ss
    return `${minutes}:${padTime(seconds)}`;
  };

  const timerRender = showTimer ? (
    counter !== 0 ? (
      <div className="resend-code">
        <p className="caption-2">
          Отправить код повторно через {format(counter)}
        </p>
      </div>
    ) : (
      <div className="resend-code">
        <p className="orange-text caption-2 link" onClick={() => resendCode()}>
          Отправить код повторно
        </p>
      </div>
    )
  ) : null;

  return (
    <div className="sms-verification__validator">
      <ReactCodeInput
        fields={6}
        autoFocus={true}
        fieldWidth={48}
        fieldHeight={48}
        loading={isLoading}
        onChange={() => {
          setIsDisabled(true);
        }}
        onComplete={(e) => {
          onComplete(e);
        }}
      />
      {timerRender}

      <button
        className="primary"
        disabled={isDisabled}
        onClick={() => (history.push("/"), window.location.reload())}
      >
        Далее
      </button>
    </div>
  );
}
