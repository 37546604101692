/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import {
  SchoolAdminLayout,
  ManualScheduleClass,
  NoData,
} from "../../components/";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { X } from "react-feather";
import SelectSearch, { fuzzySearch } from "react-select-search";

import { getShifts } from "../../store/shift/actions";
import { getSchedules, getSchedulesByClassId, getSchedulesByInstructorId } from "../../store/schedule/actions";
import { getClassBySchoolId } from "../../store/class/actions";
import { getInstructors } from "../../store/instructor/actions";
import ScheduleClass from "../../components/Schedule/ScheduleClass";
// import { statuses } from "../../config";

export default function Schedule() {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();

  const history = useHistory();
  const dispatch = useDispatch();
  const shiftsStore = useSelector((store) => store.shifts.shifts);
  // const shiftsStatus = useSelector((store) => store.shifts.status);
  // const schedulesStore = useSelector((store) => store.schedules.schedules);
  const instructors = useSelector((state) => state.instructors.instructors);
  const [teacher, setTeacher] = useState();
  const [scheduleClass, setScheduleClass] = useState(false);
  const [classTitle, setClassTitle] = useState(null);
  // const [classData, setClassData] = useState(null);
  const classesStore = useSelector((state) => state.class.class);
  // const [teacherFullName, setTeacherFullName] = useState(null);

  const [shiftId, setShiftId] = useState();
  // const [isScheduleShown, setIsScheduleShown] = useState(true);
  // const [scheduleView, setScheduleView] = useState();
  // console.log(isScheduleShown);
  const [i, setI] = useState(false);
  const [modal, setModal] = useState({
    addSchedule: false,
  });

  useEffect(async () => {
    await dispatch(getSchedules());
    await dispatch(getShifts());
    await  dispatch(getInstructors());
    await dispatch(getClassBySchoolId());
    const tableLayout = document.querySelector("#table-layout");
    tableLayout.scrollTop = JSON.parse(
      localStorage.getItem("scheduleScrollTop")
    );
  }, []);

  function instructorsLessonsOptions() {
    let a = [];
    instructors.sort().map((teacher) => {
        a.push({
          name: `${teacher.instructorTitle}`,
          value: `${teacher.instructorId}`,
          instructorId: teacher.instructorId,
        });
  });
    return a;
  }

  function handleInstructorsData(k) {
    setTeacher(k.value);
    dispatch(getSchedulesByInstructorId(k.instructorId));
  }

  const shiftsOptions = shiftsStore.map((item) =>
    // <option
    //   value={item.id}
    //   key={idx}
    //   selected={shiftsStore?.[0]?.id === item.id}
    // >
    //   {item.title} {t("presetting:shift presetting")}
    // </option>
    ({
      name: `${item.title} ${t("presetting:shift presetting")}`,
      value: item.id,
      key: "shiftId",
    })
  );

  const classes = classesStore?.map((item) => ({
    name: `${item?.classLevel} ${item?.classLabel}`,
    value: item.id,
    key: item.id,
  }));

  function schedulesByClassId(k) {
    dispatch(getSchedulesByClassId(k.key));
  }

  // function classSchedule(){
  //   classTitle ? ch
  // };

  function exportTableToExcel(tableID, filename = "") {
    let downloadLink;
    let dataType = "application/vnd.ms-excel";
    let tableSelect = document.querySelector(tableID);
    tableSelect.border = 1;
    let tableHTML = tableSelect.outerHTML.replace(/ /g, "%20");

    // Specify file name
    filename = filename ? filename + ".xls" : "excel_data.xls";

    // Create download link element
    downloadLink = document.createElement("a");

    document.body.appendChild(downloadLink);

    if (navigator.msSaveOrOpenBlob) {
      let blob = new Blob(["\ufeff", tableHTML], {
        type: dataType,
      });
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      // Create a link to the file
      downloadLink.href = "data:" + dataType + ", " + tableHTML;

      // Setting the file name
      downloadLink.download = filename;

      //triggering the function
      downloadLink.click();
    }
  }
  return (
    <SchoolAdminLayout>
      <div className="school-admin__schedule school-presetting__body school-presetting__body-color">
        <div className="school-presetting__body-row">
          <div className="school-presetting__body-title">
            <h2>{t("schoolAdmin:schedule")}</h2>
          </div>
          <div className="school-presetting__body-description">
            <p className="body-1">{t("schoolAdmin:lorem ipsum schedule")}</p>
          </div>
        </div>
        <div className="school-presetting__search-filter">
          <div className="school-presetting__search">
            {t("schoolAdmin:schedule of courses to")}{" "}
            {JSON.parse(localStorage.getItem("chronicleActive"))?.selectorTitle}{" "}
            {t("schoolAdmin:year")}
          </div>
          <div className="school-presetting__filter">
            <Link to="/school-admin/schedule/group">
              <button className="secondary small">
                {t("schoolAdmin:group")}
              </button>
            </Link>
            <button
              className="primary small"
              onClick={() => setModal({ ...modal, addSchedule: true })}
            >
              {t("schoolAdmin:add schedule")}
            </button>
          </div>
        </div>
        <div className="school-presetting__search-filter">
          <div className="school-presetting__search">
            {shiftsStore.length > 0 && (
              <>
                <SelectSearch
                  options={shiftsOptions}
                  search
                  className="select-search filter drop-up"
                  filterOptions={fuzzySearch}
                  name="shiftId"
                  value={shiftsStore?.[0].id}
                  onChange={(_, k) => {
                    setShiftId(k.value);
                  }}
                  required
                  placeholder={`${t("presetting:shift presetting")}`}
                />
                <button
                  className="secondary small"
                  onClick={() => {
                    exportTableToExcel(
                      "#table-layout table",
                      `${
                        JSON.parse(localStorage.getItem("school"))?.name
                      } - ${t("schoolAdmin:schedule")} - ${
                        shiftsStore.find(
                          (item) => item.id == (shiftId || shiftsStore?.[0]?.id)
                        )?.title
                      } ${t("schoolAdmin:shift")}`
                    );
                  }}
                >
                  {"  "}
                  {t("schoolAdmin:export")}
                  {"  "}
                </button>
              </>
            )}
          </div>
          <div className="school-presetting__filter">
            <SelectSearch
              options={instructorsLessonsOptions()}
              filterOptions={fuzzySearch}
              value={teacher}
              name="instructorId"
              onChange={(_, k) => {
                setScheduleClass(false);
                dispatch(getSchedulesByInstructorId(k.instructorId));
                handleInstructorsData(k);
                setClassTitle(null);
                setI(true);
              }}
              required
              placeholder={`-${t("login:role instructor")}-`}
              />
            <SelectSearch
              options={classes}
              filterOptions={fuzzySearch}
              value={classTitle}
              name="classId"
              onChange={(_, k) => {
                setTeacher(null);
                schedulesByClassId(k);
                setClassTitle(k.value);
                setScheduleClass(true);
                setI(false);
              }}
              required
              placeholder={`- ${t("schoolAdmin:class")} -`}
            />
          </div>
        </div>
        {shiftId !== "" ? (
          <div
            id="table-layout"
            className="table-layout"
            style={{ width: "100%", height: 600, overflow: "scroll" }}
            onScroll={(e) => {
              localStorage.setItem("scheduleScrollTop", e.target.scrollTop);
            }}
          >
            {scheduleClass ? <ScheduleClass shiftId={shiftId || shiftsStore?.[0]?.id} classTitle={classTitle}/> 
            :<ManualScheduleClass shiftId={shiftId || shiftsStore?.[0]?.id} instructor={i}/> }
          </div>
        ) : (
          <NoData
            messages={[`${t("schoolAdmin:choose shedule and type of render")}`]}
          />
        )}
      </div>
      <Modal
        isOpen={modal.addSchedule}
        onRequestClose={() => setModal({ ...modal, addSchedule: false })}
        overlayClassName="modal-overlay"
        onAfterOpen={async () => {}}
        className="middle"
      >
        <div className="modal">
          <div className="modal-close">
            <button onClick={() => setModal({ ...modal, addSchedule: false })}>
              <X size={15} color="#9A9A9A" />
            </button>
          </div>
          <div className="modal-header">
            <div className="modal-header-title">
              <h2 className="roboto">{t("schoolAdmin:new schedule")}</h2>
            </div>
          </div>
          <div className="modal-body">
            <div className="modal__add-schedule-list">
              {/* <button
                onClick={() => {
                  setModal({ ...modal, addSchedule: false });
                }}
              >
                <div className="modal__add-schedule-item">
                  <div className="modal__add-schedule-title">
                    <p>{t("schoolAdmin:standard schedule")}</p>
                  </div>
                  <div className="modal__add-schedule-description">
                    <p>
                      {t(
                        "schoolAdmin:schedule type in which you need to configure everything manually"
                      )}
                    </p>
                  </div>
                </div>
              </button>
              <div className="modal__add-schedule-divider"></div> */}
              <button
                onClick={() => {
                  setModal({ ...modal, addSchedule: false });
                  history.push("/school-admin/schedule/auto/1");
                }}
              >
                <div className="modal__add-schedule-item">
                  <div className="modal__add-schedule-title">
                    <p>{t("schoolAdmin:auto schedule")}</p>
                  </div>
                  <div className="modal__add-schedule-description">
                    <p>
                      {t(
                        "schoolAdmin:set the desired settings and the schedule is automatically generated"
                      )}
                    </p>
                  </div>
                </div>
              </button>
              {/* <div className="modal__add-schedule-divider"></div> */}
              {/* <button
                onClick={() => {
                  // setModal({ ...modal, addSchedule: false });
                  exportTableToExcel(
                    "#table-layout table",
                    `${JSON.parse(localStorage.getItem("school"))?.name} - ${t(
                      "schoolAdmin:schedule"
                    )} - ${shiftsStore.find(
                      (item) => item.id == (shiftId || shiftsStore?.[0]?.id)
                    )} ${t("schoolAdmin:shift")}`
                  );
                }}
              >
                <div className="modal__add-schedule-item">
                  <div className="modal__add-schedule-title">
                    <p>{t("schoolAdmin:export")}</p>
                  </div>
                  <div className="modal__add-schedule-description">
                    <p>{t("schoolAdmin:schedule export")}</p>
                  </div>
                </div>
              </button> */}
            </div>
          </div>
        </div>
      </Modal>
    </SchoolAdminLayout>
  );
}
