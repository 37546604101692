import React, { useState } from "react";

import "./Login.scss";
import appstore from "../../assets/images/appstore.png";
import googlePlay from "../../assets/images/google-play.png";
import loginImage from "../../assets/images/login-image.png";
import { Logo } from "../../components/icons";
import { LoginByLogin, LoginByPhone, LanguageDropdown } from "../../components";
import { constants } from "../../config";
import { useTranslation } from "react-i18next";

export default function Login() {
  const { t } = useTranslation("login");
  const [isLogin, setIsLogin] = useState(true);
  return (
    <div className="login-page">
      <div className="login-left">
        <div className="login-left__inner v-center">
          <div className="login-left__header">
            <div className="login-left__header-title">
              <h1 className="title">Kundoluk</h1>
            </div>
            <div className="login-left__header-description">
              <h3>{t("login:kundoluk description")}</h3>
            </div>
          </div>
          <div className="login-left__body">
            <div className="login-left__body-image h-center">
              <img src={loginImage} alt="login image" />
            </div>
          </div>
          <div className="login-left__footer">
            <div className="login-left__footer-text">
              <p className="body-1">{t("kundoluk download app")}</p>
            </div>
            <div className="login-left__footer-image">
              <div className="login-left__footer-image-item">
                <a
                  href={constants.googlePlay}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={googlePlay} loading="lazy" alt="google play" />
                </a>
              </div>
              <div className="login-left__footer-image-item">
                <a
                  href={constants.appStore}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={appstore} loading="lazy" alt="appstore" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="login-right">
        <div className="login-right__inner">
          <div className="login-right__header">
            <div className="login-logo">
              <Logo />
            </div>
            <div className="language-switcher">
              <LanguageDropdown />
            </div>
          </div>
          <div className="v-center h-center login-right__body">
            {isLogin ? (
              <LoginByLogin setIsLogin={setIsLogin} />
            ) : (
              <LoginByPhone setIsLogin={setIsLogin} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
