/* eslint-disable prettier/prettier */

import React, { useEffect } from "react";
import PT from "prop-types";
import Select, { components } from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { getClassBySchoolId } from "../../store/class/actions";
import { statuses } from "../../config";
import { useTranslation } from "react-i18next";

const customStyles = {
  option: (styles) => ({
    ...styles,
    padding: "24px 24px",
    // borderRadius: 8,
  }),
  control: () => ({
    width: 520,
    display: "flex",
    alignItems: "center",
    backgroundColor: "#fff",
    borderRadius: 8,
    border: "1px solid #E2E8F0",
    // boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
    fontSize: 14,
    // lineHeight: 23,
  }),
  singleValue: (styles) => ({
    ...styles,
    transition: "opacity 300ms",
  }),
  input: (styles) => ({
    ...styles,
    // color: "transparent",
    // ":active": {
    //   border: "1px solid red",
    // },
  }),
  // placeholder: (styles) => ({ ...styles, backgroundColor: "#F3F3F3" }),
  menuList: (style) => ({
    ...style,
    paddingTop: 0,
    paddingBottom: 0,
    maxHeight: 180,
  }),
};

const { Option } = components;

const IconOption = (props) => (
  <Option {...props}>
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      {/* <span style={{ fontSize: 24 }}>{props.data.icon}</span> */}
      <span style={{ fontSize: 16 }}>{props.data.label}</span>
    </div>
  </Option>
);
IconOption.propTypes = {
  data: PT.any,
};

export default function SelectGradeDropdown(props) {
  const { t } = useTranslation(["userApplicationForm"]);

  const { onSelectGrade, gradeId } = props;
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  useEffect(() => {
    const schoolId = JSON.parse(
      localStorage.getItem("userApplicationForm")
    )?.schoolId;
    dispatch(getClassBySchoolId(schoolId));
  }, []);
  const options = store.class.class.map((item) => ({
    label: item.selectorTitle,
    value: item.id,
  }));
  return (
    <Select
      className="select-school-form__dropdown"
      isLoading={store.class?.status === statuses.LOADING}
      defaultValue={
        store.class.status === statuses.SUCCESS
          ? options.filter((item) => item.value === gradeId)
          : []
      }
      options={store.class.status === statuses.SUCCESS ? options : []}
      styles={customStyles}
      placeholder={t("userApplicationForm:class")}
      components={{ Option: IconOption, IndicatorSeparator: () => null }}
      onChange={(e) => onSelectGrade(e.value)}
      theme={(theme) => ({
        ...theme,
        borderRadius: 8,
        backgroundColor: "#F3F3F3",
        // height: "auto",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.12)",

        colors: {
          ...theme.colors,
          text: "red",
          primary25: "rgba(240, 119, 58, 0.166)",
          primary50: "rgba(240, 119, 58, 0.166)",
          primary75: "rgba(240, 119, 58, 0.166)",
          primary: "#F0783A",
        },
      })}
    />
  );
}

SelectGradeDropdown.propTypes = {
  onSelectGrade: PT.func,
  gradeId: PT.any,
};
