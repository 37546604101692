import axios from "axios";
// import { urls } from "../config";

// const API = (token) =>
//   axios.create({
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${token}`,
//     },
//   });
// export default API;

const API = axios.create({
  // timeout: 5000,
});

API.interceptors.request.use((config) => {
  const user = JSON.parse(localStorage.getItem("user"));

  if (user) {
    config.headers["Authorization"] = `Bearer_${user.token}`;
  }
  config.headers["Content-Type"] = "application/json";
  return config;
});

API.interceptors.response.use(
  (response) => response,
  (error) => {
    // const originalRequest = error.config;

    // if (
    //   error.response.status === 401 &&
    //   originalRequest.url === urls.AUTH + "refresh-token"
    // ) {

    // }

    if (
      error.response.data.status === 403 &&
      error.response.data.error === "Forbidden"
    ) {
      localStorage.clear();
      window.location.href = "/";
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

export default API;
