import React, { useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import FormikControl from "../../components/formik/FormikControl";
import { ApplicationFormLayout } from "../../components";
import "./UserApplicationForm.scss";
import student from "../../assets/images/student.png";
import teacher from "../../assets/images/teacher.png";
import parent from "../../assets/images/parent.png";

export default function Step1() {
  const { t } = useTranslation(["common", "userApplicationForm", "validation"]);

  const lStorage = JSON.parse(localStorage.getItem("userApplicationForm"));

  const history = useHistory();
  useEffect(() => {
    localStorage.setItem(
      "userApplicationForm",
      JSON.stringify({ ...lStorage, activeStep: 1 })
    );
  }, []);

  const initialValues = {
    role: lStorage?.role || "",
    firstName: lStorage?.firstName || "",
    lastName: lStorage?.lastName || "",
    middleName: lStorage?.middleName || "",
  };
  const validationSchema = Yup.object({
    role: Yup.string().required(t("validation:required")),
    firstName: Yup.string().required(t("validation:required")),
    lastName: Yup.string().required(t("validation:required")),
    middleName: Yup.string(),
  });

  const onSubmit = async (values) => {
    console.log({ ...values });
    const lStorage = JSON.parse(localStorage.getItem("userApplicationForm"));

    localStorage.setItem(
      "userApplicationForm",
      JSON.stringify({ ...lStorage, ...values })
    );
    history.push("/user-application-form/2");
    // let data = [];
    // data.push(values);
    // console.log(("data", data));
    // await this.props.createEmployee(data);
    // this.props.history.push("/staff");
  };
  return (
    <div className="step">
      <ApplicationFormLayout style={{ minHeight: 600 }} id={1}>
        <div className="step__title">
          <p className="body-1">{t("userApplicationForm:fill fields")}</p>
        </div>
        <div className="step__body">
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {(formik) => {
              return (
                <Form>
                  <div className="step__body-radio">
                    <div className="step__body-radio-item">
                      <label
                        className={`step__body-radio-container${
                          formik.values.role === "3" ? " radio-active" : ""
                        }`}
                      >
                        <img src={student} alt="student" />
                        <input
                          type="radio"
                          name="role"
                          value={3}
                          checked={formik.values.role === "3"}
                          onChange={(e) =>
                            // handleChangeRole(e),
                            formik.setFieldValue(
                              e.target.name,
                              `${e.target.value}`
                            )
                          }
                        />
                      </label>
                      <p className="body-2">
                        {t("userApplicationForm:im student")}
                      </p>
                    </div>
                    <div className="step__body-radio-item">
                      <label
                        className={`step__body-radio-container${
                          formik.values.role === "5" ? " radio-active" : ""
                        }`}
                      >
                        <img src={teacher} alt="teacher" />
                        <input
                          type="radio"
                          name="role"
                          value={5}
                          onChange={(e) =>
                            // handleChangeRole(e),
                            formik.setFieldValue(
                              e.target.name,
                              `${e.target.value}`
                            )
                          }
                          checked={formik.values.role === "5"}
                        />
                      </label>
                      <p className="body-2">
                        {" "}
                        {t("userApplicationForm:im instructor")}
                      </p>
                    </div>
                    <div className="step__body-radio-item">
                      <label
                        className={`step__body-radio-container${
                          formik.values.role === "4" ? " radio-active" : ""
                        }`}
                      >
                        <img src={parent} alt="parent" />
                        <input
                          type="radio"
                          name="role"
                          value={4}
                          onChange={(e) =>
                            // handleChangeRole(e),
                            formik.setFieldValue(
                              e.target.name,
                              `${e.target.value}`
                            )
                          }
                          checked={formik.values.role === "4"}
                        />
                      </label>
                      <p className="body-2">
                        {" "}
                        {t("userApplicationForm:im parent")}
                      </p>
                    </div>
                  </div>
                  <div className="step__body-input">
                    <FormikControl
                      control="input"
                      type="text"
                      placeholder={t("validation:first name")}
                      name="firstName"
                      value={formik.values.firstName}
                      onBlur={formik.handleBlur}
                      changed={formik.touched.firstName}
                      isValid={formik.errors.firstName}
                      onChange={(e) => (
                        formik.setFieldTouched(e.target.name, true, true),
                        formik.handleChange(e)
                      )}
                    />
                    <FormikControl
                      control="input"
                      type="text"
                      placeholder={t("validation:last name")}
                      name="lastName"
                      value={formik.values.lastName}
                      onBlur={formik.handleBlur}
                      changed={formik.touched.lastName}
                      isValid={formik.errors.lastName}
                      onChange={(e) => (
                        formik.setFieldTouched(e.target.name, true, true),
                        formik.handleChange(e)
                      )}
                    />
                    <FormikControl
                      control="input"
                      type="text"
                      placeholder={t("validation:middle name")}
                      name="middleName"
                      value={formik.values.middleName}
                      onBlur={formik.handleBlur}
                      changed={formik.touched.middleName}
                      isValid={formik.errors.middleName}
                      onChange={(e) => (
                        formik.setFieldTouched(e.target.name, true, true),
                        formik.handleChange(e)
                      )}
                    />
                  </div>
                  <div
                    className="step__body-submit"
                    style={{ justifyContent: "initial" }}
                  >
                    <button
                      className="primary large"
                      type="submit"
                      disabled={!formik.isValid}
                    >
                      {t("common:continue")}
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
          <div className="step__body-info">
            <p className="caption-1">
              {t("userApplicationForm:user agreement")}
            </p>
          </div>
        </div>
      </ApplicationFormLayout>
    </div>
  );
}
