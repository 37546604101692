/* eslint-disable indent */
import React, { useEffect, useState, useRef } from "react";
import {
  InstractorLayout,
  NoData,
  GoBack,
  PaginationBlock,
  DatePickerCalendar,
} from "../../components";
import { useParams } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Edit2, Trash, X, File, FilePlus } from "react-feather";
import Modal from "react-modal";
import SelectSearch, { fuzzySearch } from "react-select-search";
import Tooltip from "react-simple-tooltip";

import {
  getAssignmentsByClassIdCourseId,
  removeAssignment,
  addAssignment,
  editAssignment,
  removeFiles,
  addAssignmentTopicless,
} from "../../store/assignment/actions";
import { getClassById } from "../../store/class/actions";
import { getCourses } from "../../store/course/actions";
import { getCalendarTopicByClassIdAndCourseId } from "../../store/calendarTopic/actions";
import { getQuarters } from "../../store/quarter/actions";

export default function AssignmentList() {
  const { classId_courseId } = useParams();
  const classId = classId_courseId.split("-")[0];
  const courseId = classId_courseId.split("-")[1];

  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation([
    "presetting",
    "schoolAdmin",
    "instructor",
  ]);
  const assignmentsStore = useSelector(
    (store) => store.assignments.assignments
  );
  const coursesStore = useSelector((store) => store.courses.courses);
  const classOneStore = useSelector((store) => store.class.classOne);
  const calendarTopicsStore = useSelector(
    (store) => store.calendarTopics.calendarTopics
  );
  const quartersStore = useSelector((store) => store.quarters.quarters);

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [selectedData, setSelectedData] = useState([]);
  const [searchedCurrentData, setSearchedCurrentData] = useState([]);
  const [editCurrentAssignment, setEditAssignment] = useState({
    title: "",
  });
  const [modal, setModal] = useState({
    createAssignment: false,
    editCurrentAssignment: false,
  });
  const [newAssignment, setNewAssignment] = useState({});

  const hiddenFileInput = useRef(null);
  const hiddenFileInputEdit = useRef(null);

  const openUploadFile = () => {
    hiddenFileInput.current.click();
  };
  const openEditUploadFile = () => {
    hiddenFileInputEdit.current.click();
  };

  useEffect(async () => {
    await dispatch(
      getAssignmentsByClassIdCourseId({
        classId,
        courseId,
        pageNumber: currentPage,
      })
    );
    await dispatch(getCalendarTopicByClassIdAndCourseId({ classId, courseId }));
    await dispatch(getClassById(classId));
    await dispatch(getCourses(classId));
  }, []);
  const createAssignment = async () => {
    newAssignment?.isHasTopic
      ? await dispatch(
          addAssignmentTopicless({ ...newAssignment, courseId, classId })
        )
      : await dispatch(addAssignment(newAssignment));
    await dispatch(
      getAssignmentsByClassIdCourseId({ classId, courseId, pageNumber: 1 })
    );

    setModal({ ...modal, createAssignment: false });
    setNewAssignment({});
    await setSearchedCurrentData([]);
    await setSelectedData([]);
    await setSearchText("");
  };
  const updateAssignment = async () => {
    await dispatch(
      editAssignment({
        calendarTopicId: editCurrentAssignment?.calendarTopicId,
        content: editCurrentAssignment?.content,
        deadlineAt: editCurrentAssignment?.deadlineAt,
        id: editCurrentAssignment?.id,
        files: editCurrentAssignment?.files,
      })
    );
    editCurrentAssignment?.attachmentsToDelete?.length > 0 &&
      dispatch(removeFiles(editCurrentAssignment?.attachmentsToDelete));
    await dispatch(
      getAssignmentsByClassIdCourseId({
        classId,
        courseId,
        pageNumber: currentPage,
      })
    );
    await setModal({ ...modal, editCurrentAssignment: false });
    await setEditAssignment({});
  };

  const onInputChange = (e) => {
    if ((e.key || e.target.name) == "files") {
      const fileList = Object.values(e.target.files);
      console.log("files", fileList);
      setNewAssignment({
        ...newAssignment,
        files: fileList,
      });
    } else if ((e.key || e.target.name) == "isHasTopic") {
      setNewAssignment({
        ...newAssignment,
        [e.key || e.target.name]: e.checked || e.target.checked,
      });
    } else {
      setNewAssignment({
        ...newAssignment,
        [e.key || e.target.name]: e.value || e.target.value,
      });
    }
  };
  const onEditInputChange = (e) => {
    if ((e.key || e.target.name) == "files") {
      const fileList = Object.values(e.target.files);
      console.log("files", fileList);
      setEditAssignment({
        ...editCurrentAssignment,
        files: fileList,
      });
    } else {
      setEditAssignment({
        ...editCurrentAssignment,
        [e.key || e.target.name]: e.value || e.target.value,
      });
    }
  };
  const onChange = async (e) => {
    await dispatch(
      getAssignmentsByClassIdCourseId({
        classId,
        courseId,
        pageNumber: e,
      })
    );
    setCurrentPage(e);
  };

  const removeItem = async (value) => {
    await dispatch(removeAssignment(value.id));
    await dispatch(
      getAssignmentsByClassIdCourseId({
        classId,
        courseId,
        pageNumber: currentPage,
      })
    );
  };

  const onSelect = (value) => {
    if (selectedData.find((item) => item.id === value.id)) {
      let a = selectedData.filter((item) => item.id !== value.id);
      setSelectedData([...a]);
    } else {
      setSelectedData([...selectedData, value]);
    }
  };
  const clearSelected = () => {
    setSelectedData([]);
  };
  const removeSelected = async (data) => {
    // let a = assignmentsStore;

    await data.map((item) => {
      dispatch(removeAssignment(item.id));
    });
    await setSelectedData([]);

    await dispatch(
      getAssignmentsByClassIdCourseId({
        classId,
        courseId,
        pageNumber: currentPage,
      })
    );
  };

  function formatBytes(bytes) {
    var marker = 1024;
    var decimal = 2;
    var kiloBytes = marker;
    var megaBytes = marker * marker;
    var gigaBytes = marker * marker * marker;

    if (bytes < kiloBytes) return bytes + " Bytes";
    else if (bytes < megaBytes)
      return (bytes / kiloBytes).toFixed(decimal) + " KB";
    else if (bytes < gigaBytes)
      return (bytes / megaBytes).toFixed(decimal) + " MB";
    else return (bytes / gigaBytes).toFixed(decimal) + " GB";
  }

  const calendarTopicsRender = calendarTopicsStore.map((item) => ({
    name: `${item.title}`,
    value: `${item.id}`,
    key: "calendarTopicId",
  }));

  const quartersOptions = quartersStore.map((item) => ({
    name: `${item.quarter} ${t("instructor:quarter")}`,
    value: item.id,
    key: "quarterId",
  }));
  const tableDataRender = assignmentsStore?.content?.map((item, idx) => (
    <tr
      className={selectedData.find((i) => i.id === item.id) ? "active" : ""}
      key={idx}
    >
      <td className="table-select">
        <label className="check">
          <input
            type="checkbox"
            onChange={() => onSelect(item)}
            checked={selectedData.find((i) => i.id === item.id)}
          />
          <div className="box"></div>
        </label>
        <span>{idx + 1}</span>
      </td>
      <td>{item.calendarTopicTitle}</td>
      <td>{item.content}</td>
      <td>{item.deadlineAt}</td>
      <td>
        <div>
          <Tooltip className="tooltip" content={t("common:edit")}>
            <button
              onClick={async () => {
                await setEditAssignment(item);
                await setModal({ ...modal, editCurrentAssignment: true });
              }}
            >
              <Edit2 color="#14A38B" />
            </button>
          </Tooltip>
          <Tooltip className="tooltip" content={t("common:delete")}>
            <button onClick={() => removeItem(item)}>
              <Trash color="#FF7171" />
            </button>
          </Tooltip>
        </div>
      </td>
    </tr>
  ));
  return (
    <div>
      <InstractorLayout>
        <GoBack title={`${t("schoolAdmin:back to list")}`} />
        <div className="school-presetting__body school-presetting__body-color">
          <div className="school-presetting__body-row">
            <div className="school-presetting__body-title">
              <h2 className="roboto">
                {classOneStore?.classLevel}
                {classOneStore?.classLabel} -{" "}
                {
                  coursesStore?.find((item) => item.id == courseId)?.[
                    String(i18n.language).toLowerCase() === "en" ||
                    String(i18n.language).toLowerCase() === "tr"
                      ? `title`
                      : `title${String(i18n.language).toUpperCase()}`
                  ]
                }
              </h2>
            </div>
            <div className="school-presetting__body-description">
              <p className="body-1">
                {t("schoolAdmin:assignment description")}
              </p>
            </div>
          </div>
          <div className="school-presetting__subjects-list">
            <div className="school-presetting__search-filter">
              <div className="school-presetting__search"></div>
              <div className="school-presetting__filter">
                <button
                  className="primary small"
                  onClick={() => setModal({ ...modal, createAssignment: true })}
                >
                  {t("common:create")}
                </button>
              </div>
            </div>
            <div className="school-presetting__teachers-body">
              <div className="school-presetting__teachers-table">
                {assignmentsStore?.content?.length > 0 ? (
                  searchText !== "" && searchedCurrentData.length == 0 ? (
                    <NoData
                      messages={[`${t("schoolAdmin:here is nothing")}`]}
                    />
                  ) : (
                    <>
                      <table>
                        <thead>
                          <tr>
                            <th>№</th>
                            <th>{t("instructor:topic")}</th>
                            <th>{t("schoolAdmin:description")}</th>
                            <th>{t("schoolAdmin:deadline")}</th>
                            <th>{t("presetting:action presetting")}</th>
                          </tr>
                        </thead>
                        <tbody>{tableDataRender}</tbody>
                      </table>
                      {selectedData.length > 0 && (
                        <div className="edit-table">
                          <div className="edit-table-left">
                            <div>
                              <p className="caption-1">
                                {t("presetting:choosen presetting")}:
                                <span>{selectedData.length}</span>
                              </p>
                            </div>
                            <div>
                              <button onClick={clearSelected}>
                                {t("presetting:consel presetting")}
                              </button>
                            </div>
                          </div>
                          <div className="edit-table-right">
                            <div>
                              <button
                                onClick={() => {
                                  removeSelected(selectedData);
                                }}
                              >
                                {t("presetting:delete presetting")}
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )
                ) : (
                  <NoData messages={[`${t("schoolAdmin:here is nothing")}`]} />
                )}
              </div>
            </div>
            <div className="pagination">
              <div className="paginaiton__inner">
                <div className="pagination__per-page">
                  <p>
                    {t("presetting:showing presetting")} {perPage}{" "}
                    {t("presetting:from presetting")}{" "}
                    {assignmentsStore?.page?.totalElements}{" "}
                    {t("presetting:lines presetting")}
                  </p>
                </div>
                <div className="pagination__page">
                  <PaginationBlock
                    total={assignmentsStore?.page?.totalElements}
                    current={currentPage}
                    onChange={onChange}
                    pageSize={perPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          isOpen={modal.createAssignment}
          onRequestClose={() => {
            setModal({ ...modal, createAssignment: false });
          }}
          overlayClassName="modal-overlay"
          onAfterOpen={async () => {
            await dispatch(
              getCalendarTopicByClassIdAndCourseId({ classId, courseId })
            );
            await dispatch(getQuarters());
          }}
        >
          <div className="modal">
            <div className="modal-close">
              <button
                onClick={() => {
                  setModal({ ...modal, createAssignment: false });
                  setNewAssignment({});
                }}
              >
                <X size={15} color="#9A9A9A" />
              </button>
            </div>
            <div className="modal-header">
              <div className="modal-header-title">
                <h2>{t("schoolAdmin:assignment")}</h2>
              </div>
            </div>
            <div className="modal-body">
              <div className="modal-inputs-list">
                <div className="modal-input">
                  {newAssignment?.isHasTopic ? (
                    <SelectSearch
                      options={quartersOptions}
                      search
                      filterOptions={fuzzySearch}
                      className="select-search small"
                      name="quarterId"
                      value={newAssignment?.quarterId}
                      onChange={(_, k) => {
                        onInputChange(k);
                      }}
                      required
                      placeholder={`${t("instructor:quarter")}`}
                    />
                  ) : (
                    <SelectSearch
                      options={calendarTopicsRender}
                      search
                      filterOptions={fuzzySearch}
                      className="select-search small"
                      name="calendarTopicId"
                      value={newAssignment?.calendarTopicId}
                      onChange={(_, k) => {
                        onInputChange(k);
                      }}
                      required
                      placeholder={`${t("instructor:topic")}`}
                    />
                  )}
                </div>
                <div className="modal-input">
                  <div className="modal-input-checkbox">
                    <input
                      type="checkbox"
                      name="isHasTopic"
                      id="isHasTopic"
                      checked={newAssignment?.isHasTopic}
                      onChange={onInputChange}
                    />
                    <label htmlFor="isHasTopic">
                      {t("schoolAdmin:dont have topic")}
                    </label>
                  </div>
                </div>
                <div className="modal-input">
                  <input
                    type="text"
                    placeholder={t("schoolAdmin:title")}
                    name="title"
                    value={newAssignment?.title}
                    onChange={onInputChange}
                    required
                  />
                </div>
                <div className="modal-input">
                  <DatePickerCalendar
                    placeholder={`${t("schoolAdmin:deadline")}`}
                    onSelect={onInputChange}
                    defaultValue={newAssignment?.deadlineAt}
                    name="deadlineAt"
                  />
                </div>
                <div className="modal-input">
                  <textarea
                    type="text"
                    placeholder={t("schoolAdmin:description")}
                    name="content"
                    value={newAssignment.content}
                    onChange={onInputChange}
                    required
                  />
                </div>
                <div className="modal-input">
                  <button
                    onClick={openUploadFile}
                    className="file-upload-btn large"
                  >
                    <FilePlus /> <span>{t("schoolAdmin:attach file")}</span>
                  </button>

                  <input
                    type="file"
                    id="files"
                    name="files"
                    ref={hiddenFileInput}
                    style={{ display: "none" }}
                    onChange={onInputChange}
                    multiple
                  />
                </div>

                <div className="uploaded-files-list  roboto">
                  {newAssignment?.files?.length > 0 && (
                    <p>
                      {t("reports:total")}: {newAssignment?.files?.length}
                    </p>
                  )}
                  {newAssignment?.files?.map((item, idx) => (
                    <div className="uploaded-file" key={idx}>
                      <div className="uploaded-file_left">
                        {" "}
                        <File color="#718096" size={16} />
                        <p>
                          {item.name
                            .split(".")
                            .slice(0, -1)
                            .join(".")
                            .substring(0, 15) + "..."}
                        </p>
                      </div>
                      <div className="uploaded-file_right">
                        <p className="tag">{item.name.split(".").pop()}</p>
                        <p className="tag">{formatBytes(item.size)}</p>
                        <button
                          className="pure"
                          onClick={() => {
                            setNewAssignment({
                              ...newAssignment,
                              files: newAssignment.files.filter(
                                (file) => file !== item
                              ),
                            });
                          }}
                        >
                          <X color="#C4C4C4" size={16} />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                id="create_class_modal"
                className="large primary"
                onClick={() => createAssignment()}
                disabled={
                  newAssignment?.deadlineAt === "" ||
                  newAssignment?.deadlineAt === undefined ||
                  newAssignment?.deadlineAt === null ||
                  newAssignment?.content === "" ||
                  newAssignment?.content === undefined ||
                  newAssignment?.content === null ||
                  newAssignment?.title === "" ||
                  newAssignment?.title === undefined ||
                  newAssignment?.title === null
                }
              >
                {t("common:create")}
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={modal.editCurrentAssignment}
          onRequestClose={() => {
            setModal({ ...modal, editCurrentAssignment: false });
          }}
          overlayClassName="modal-overlay"
          onAfterOpen={async () => {
            await dispatch(
              getCalendarTopicByClassIdAndCourseId({ classId, courseId })
            );
          }}
        >
          <div className="modal">
            <div className="modal-close">
              <button
                onClick={() => {
                  setModal({ ...modal, editCurrentAssignment: false });
                  setEditAssignment({});
                }}
              >
                <X size={15} color="#9A9A9A" />
              </button>
            </div>
            <div className="modal-header">
              <div className="modal-header-title">
                <h2>{t("schoolAdmin:assignment")}</h2>
              </div>
            </div>
            <div className="modal-body">
              <div className="modal-inputs-list">
                <div className="modal-input">
                  <SelectSearch
                    options={calendarTopicsRender}
                    search
                    filterOptions={fuzzySearch}
                    className="select-search small"
                    name="calendarTopicId"
                    value={editCurrentAssignment?.calendarTopicId}
                    onChange={(_, k) => {
                      onEditInputChange(k);
                    }}
                    required
                    placeholder={`${t("instructor:topic")}`}
                  />
                </div>
                <div className="modal-input">
                  <DatePickerCalendar
                    placeholder={`${t("schoolAdmin:deadline")}`}
                    onSelect={onEditInputChange}
                    defaultValue={editCurrentAssignment?.deadlineAt}
                    name="deadlineAt"
                  />
                </div>
                <div className="modal-input">
                  <textarea
                    type="text"
                    placeholder={t("schoolAdmin:description")}
                    name="content"
                    value={editCurrentAssignment?.content}
                    onChange={onEditInputChange}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="modal-input">
              <button
                onClick={openEditUploadFile}
                className="file-upload-btn large"
              >
                <FilePlus /> <span>{t("schoolAdmin:attach file")}</span>
              </button>

              <input
                type="file"
                name="files"
                ref={hiddenFileInputEdit}
                style={{ display: "none" }}
                onChange={onEditInputChange}
                multiple
              />
            </div>

            <div
              className="uploaded-files-list  roboto"
              style={{ margin: "25px 0" }}
            >
              {(editCurrentAssignment?.attachments?.length > 0 ||
                editCurrentAssignment?.files?.length > 0) && (
                <p>
                  {t("reports:total")}:{" "}
                  {(editCurrentAssignment?.attachments?.length
                    ? editCurrentAssignment?.attachments?.length
                    : 0) +
                    (editCurrentAssignment?.files?.length
                      ? editCurrentAssignment?.files?.length
                      : 0)}
                </p>
              )}
              {editCurrentAssignment?.files?.reverse()?.map((item, idx) => (
                <div className="uploaded-file" key={idx}>
                  <div className="uploaded-file_left">
                    <File color="#718096" size={16} />
                    <p>
                      {item.name
                        .split(".")
                        .slice(0, -1)
                        .join(".")
                        .substring(0, 15) + "..."}
                    </p>
                  </div>
                  <div className="uploaded-file_right">
                    <p className="tag">{item.name.split(".").pop()}</p>
                    <button
                      className="pure"
                      onClick={() => {
                        setEditAssignment({
                          ...editCurrentAssignment,
                          files: editCurrentAssignment.files.filter(
                            (file) => file !== item
                          ),
                        });
                      }}
                    >
                      <X color="#C4C4C4" size={16} />
                    </button>
                  </div>
                </div>
              ))}
              {editCurrentAssignment?.attachments?.map((item, idx) => (
                <div className="uploaded-file" key={idx}>
                  <div className="uploaded-file_left">
                    <File color="#718096" size={16} />
                    <a
                      href={item.fileUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "#272727" }}
                    >
                      <p>
                        {item.originalTitle
                          .split(".")
                          .slice(0, -1)
                          .join(".")
                          .substring(0, 15) + "..."}
                      </p>
                    </a>
                  </div>
                  <div className="uploaded-file_right">
                    <p className="tag">{item.originalTitle.split(".").pop()}</p>
                    <button
                      className="pure"
                      onClick={() => {
                        setEditAssignment({
                          ...editCurrentAssignment,
                          attachmentsToDelete:
                            editCurrentAssignment?.attachmentsToDelete?.length >
                            0
                              ? [
                                  ...editCurrentAssignment?.attachmentsToDelete,
                                  item.id,
                                ]
                              : [item.id],
                          attachments: editCurrentAssignment.attachments.filter(
                            (file) => file !== item
                          ),
                        });
                      }}
                    >
                      <X color="#C4C4C4" size={16} />
                    </button>
                  </div>
                </div>
              ))}
            </div>
            <div className="modal-footer">
              <button
                className="large primary"
                onClick={async () => {
                  await updateAssignment();
                }}
              >
                {t("common:save")}
              </button>
            </div>
          </div>
        </Modal>
      </InstractorLayout>
    </div>
  );
}
