/* eslint-disable indent */
import { urls, toastOption } from "../../config";
import { API } from "../../utils";
import { toast } from "react-toastify";
import i18n from "../../i18n";

import {
  GET_RAYONS_REQUEST,
  GET_RAYONS_SUCCESS,
  GET_RAYONS_ERROR,
} from "../actionTypes";

export const getRayons = () => async (dispatch) => {
  dispatch(getRayonsRequest());

  await API.get(`${urls.RAYON}`)
    .then((res) => {
      console.log(res.data);

      dispatch(getRayonsSuccess(res.data));
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getRayonsFailure(error.message));
      return error.message;
    });
};

export const getRayonsRequest = () => {
  return {
    type: GET_RAYONS_REQUEST,
  };
};

export const getRayonsSuccess = (data) => {
  return {
    type: GET_RAYONS_SUCCESS,
    payload: data,
  };
};

export const getRayonsFailure = (error) => {
  return {
    type: GET_RAYONS_ERROR,
    payload: error,
  };
};
