import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
// import { Link } from "react-router-dom";
// import {
// ChevronRight,
// Users,
// BarChart,
// BookOpen,
// FileText,
// CheckCircle,
// } from "react-feather";

import { getClassByInstructorId } from "../../store/class/actions";
import {
  InstractorLayout,
  ClassStatisticsGrade,
  ClassStatisticsAttendance,
} from "../../components";
import { getQuarters } from "../../store/quarter/actions";

import { statuses } from "../../config";

export default function ClassStatistics() {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const classStore = useSelector((store) =>
    store.class.class.filter(
      (item) => item.schoolId === JSON.parse(localStorage.getItem("school"))?.id
    )
  );
  const classStoreStatus = useSelector((store) => store.class.status);

  const [currenClassId, setCurrenClassId] = useState(classStore?.[0]?.id);

  useEffect(async () => {
    await dispatch(
      getClassByInstructorId(JSON.parse(localStorage.getItem("person"))?.id)
    );
    await dispatch(getQuarters());
  }, []);

  return (
    <InstractorLayout>
      <div className="my-class class-statistics">
        <div className="school-admin__schedule-group school-admin__schedule school-presetting__body school-presetting__body-color">
          <div className="school-presetting__body-row my-class__header">
            <div className="my-class__header-left">
              <div className="school-presetting__body-title">
                <h2>{t("instructor:statistics")}</h2>
              </div>
              <div className="school-presetting__body-description">
                <p className="body-1">
                  {t("instructor:class statistics description")}
                </p>
              </div>
            </div>
            <div className="my-class__header-right roboto">
              {classStore.map((item, idx) => (
                <button
                  key={idx}
                  onClick={() => setCurrenClassId(item.id)}
                  className={item.id == currenClassId ? "active" : ""}
                >
                  {item.classLevel}
                  {item.classLabel}
                </button>
              ))}
            </div>
          </div>
        </div>
        <div className="my-class__body">
          {classStore.length > 0 && classStoreStatus === statuses.SUCCESS && (
            <ClassStatisticsGrade
              classId={currenClassId || classStore?.[0]?.id}
            />
          )}
          {classStore.length > 0 && classStoreStatus === statuses.SUCCESS && (
            <ClassStatisticsAttendance
              classId={currenClassId || classStore?.[0]?.id}
            />
          )}
        </div>
      </div>
      {/* </div> */}
    </InstractorLayout>
  );
}
