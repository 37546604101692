import { urls, toastOption } from "../../config";
import { API } from "../../utils";
import { toast } from "react-toastify";
import i18n from "../../i18n";

import {
  GET_INSTRUCTOR_ANALYTIC_REQUEST,
  GET_INSTRUCTOR_ANALYTIC_SUCCESS,
  GET_INSTRUCTOR_ANALYTIC_ERROR,
  GET_STUDENTS_ANALYTIC_REQUEST,
  GET_STUDENTS_ANALYTIC_SUCCESS,
  GET_STUDENTS_ANALYTIC_ERROR,
  GET_PARENTS_ANALYTIC_REQUEST,
  GET_PARENTS_ANALYTIC_SUCCESS,
  GET_PARENTS_ANALYTIC_ERROR,
  GET_ASSESSMENTS_ANALYTIC_REQUEST,
  GET_ASSESSMENTS_ANALYTIC_SUCCESS,
  GET_ASSESSMENTS_ANALYTIC_ERROR,
  GET_ASSESSMENTS_TOPIC_REQUEST,
  GET_ASSESSMENTS_TOPIC_SUCCESS,
  GET_ASSESSMENTS_TOPIC_ERROR,
  GET_ASSESSMENTS_HW_REQUEST,
  GET_ASSESSMENTS_HW_SUCCESS,
  GET_ASSESSMENTS_HW_ERROR,
  GET_INSTRUCTOR_STUTISTICS_REQUEST,
  GET_INSTRUCTOR_STUTISTICS_SUCCESS,
  GET_STUDENTS_COUNT_SUCCESS,
  GET_INSTRUCTOR_STUTISTICS_ERROR,
  GET_STUDENT_GRADE_ANALYTICS_REQUEST,
  GET_STUDENT_GRADE_ANALYTICS_SUCCESS,
  GET_STUDENT_GRADE_ANALYTICS_ERROR,
  GET_STUDENT_ATTENDANCE_ANALYTICS_REQUEST,
  GET_STUDENT_ATTENDANCE_ANALYTICS_SUCCESS,
  GET_STUDENT_ATTENDANCE_ANALYTICS_ERROR,
} from "../actionTypes";

// ! INSTRUCTOR GENDER
export const instructorGenderAnalytic =
  (rayonId, school) => async (dispatch) => {
    dispatch(getInstructorGenderAnalytic());

    await API.get(
      `${urls.INSTRUCTOR_ANALYTIC}rayonId=${rayonId}&schoolId=${school}`
    )
      .then((res) => {
        dispatch(getInstructorGenderAnalyticSuccess(res.data));
        return res.data;
      })
      .catch((error) => {
        console.log(error);
        toast.error(i18n.t("toastify:error"), toastOption);
        dispatch(getInstructorGenderAnalyticFailure(error.message));
        return error.message;
      });
  };

// ! STUDENT GENDER
export const studentsGenderAnalytic = (rayonId, school) => async (dispatch) => {
  dispatch(getStudentsGenderAnalytic());

  await API.get(
    `${urls.STUDENTS_ANALYTIC}rayonId=${rayonId}&schoolId=${school}`
  )
    .then((res) => {
      dispatch(getStudentsGenderAnalyticSuccess(res.data));
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getStudentsGenderAnalyticFailure(error.message));
      return error.message;
    });
};

// ! PARENT GENDER
export const parentGenderAnalytic = (school) => async (dispatch) => {
  dispatch(getParentGenderAnalytic());

  await API.get(`${urls.PARENTS_ANALYTIC}&schoolId=${school}`)
    .then((res) => {
      dispatch(getParentSGenderAnalyticSuccess(res.data));
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getParentsGenderAnalyticFailure(error.message));
      return error.message;
    });
};

// ! ASSIGNMENTS COUNT BY SCOOL
export const assignmentCountAnalyticBySchool =
  (school, startDate, endDate) => async (dispatch) => {
    dispatch(getTeachersAssessments());
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var beforM = String(today.getMonth()).padStart(2, "0"); //January is -1!
    var yyyy = today.getFullYear();
    var beforY = today.getFullYear() - 2;
    today = yyyy + "-" + mm + "-" + dd;
    await API.get(
      `${urls.ASSESSMENTS_ANALYTIC}/${school}?endDate=${
        endDate ? endDate : today
      }&startDate=${startDate ? startDate : beforY + "-" + beforM + "-" + dd}`
    )
      .then((res) => {
        dispatch(getTeachersAssessmentsSuccess(res.data));
        return res.data;
      })
      .catch((error) => {
        console.log(error);
        toast.error(i18n.t("toastify:error"), toastOption);
        dispatch(getTeachersAssessmentsFailure(error.message));
        return error.message;
      });
  };

// ! TOPIC
export const topicAnalyticBySchool =
  (school, startDate, endDate) => async (dispatch) => {
    dispatch(getTeachersTopic());
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var beforM = String(today.getMonth()).padStart(2, "0"); //January is -1!
    var yyyy = today.getFullYear();
    var beforY = today.getFullYear() - 2;
    today = yyyy + "-" + mm + "-" + dd;
    await API.get(
      `${urls.TOPIC_ANALYTIC}/${school}?endDate=${
        endDate ? endDate : today
      }&startDate=${startDate ? startDate : beforY + "-" + beforM + "-" + dd}`
    )
      .then((res) => {
        dispatch(getTeachersTopicSuccess(res.data));
        return res.data;
      })
      .catch((error) => {
        console.log(error);
        toast.error(i18n.t("toastify:error"), toastOption);
        dispatch(getTeachersTopicFailure(error.message));
        return error.message;
      });
  };

// ! HOME WORK
export const homeWorkAnalyticBySchool =
  (school, startDate, endDate) => async (dispatch) => {
    dispatch(getTeachersHW());
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var beforM = String(today.getMonth()).padStart(2, "0"); //January is -1!
    var yyyy = today.getFullYear();
    var beforY = today.getFullYear() - 2;
    today = yyyy + "-" + mm + "-" + dd;
    await API.get(
      `${urls.HW_ANALYTIC}/${school}?endDate=${
        endDate ? endDate : today
      }&startDate=${startDate ? startDate : beforY + "-" + beforM + "-" + dd}`
    )
      .then((res) => {
        dispatch(getTeachersHWSuccess(res.data));
        return res.data;
      })
      .catch((error) => {
        console.log(error);
        toast.error(i18n.t("toastify:error"), toastOption);
        dispatch(getTeachersHWFailure(error.message));
        return error.message;
      });
  };

// ! GRADE COUNT BY INSTRUCTOR
export const gradeCountAnalyticByInstructorId =
  (instructorId, startDate, endDate) => async (dispatch) => {
    dispatch(getTeachersStutistics());
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var beforM = String(today.getMonth()).padStart(2, "0"); //January is -1!
    var yyyy = today.getFullYear();
    var beforY = today.getFullYear() - 2;
    today = yyyy + "-" + mm + "-" + dd;
    await API.get(
      `${urls.TEACHER_ANALYTIC}/${instructorId}?endDate=${
        endDate ? endDate : today
      }&startDate=${startDate ? startDate : beforY + "-" + beforM + "-" + dd}`
    )
      .then((res) => {
        API.get(
          `${urls.STUDENTS_COUNT_ANALYTIC}/${
            JSON.parse(localStorage.getItem("school")).id
          }`
        ).then((res) => {
          dispatch(getStudentsCountSuccess(res.data));
          return res.data;
        });

        dispatch(getTeachersStutisticsSuccess(res.data));
        console.log(res.data);
        return res.data;
      })
      .catch((error) => {
        console.log(error);
        toast.error(i18n.t("toastify:error"), toastOption);
        dispatch(getTeachersStutisticsFailure(error.message));
        return error.message;
      });
  };

// studentsCountAnalytics

export const getStudentsCountAnalytics = () => async (dispatch) => {
  dispatch(getTeachersStutistics());
  
  await API.get(
    `${urls.STUDENTS_COUNT_ANALYTIC}/${
      JSON.parse(localStorage.getItem("school")).id
    }`
  ).then((res) => {
    console.log(res);
    dispatch(getStudentsCountSuccess(res.data));
    return res.data;
  }).catch((error) => {
    console.log(error);
    toast.error(i18n.t("toastify:error"), toastOption);
    dispatch(getTeachersStutisticsFailure(error.message));
    return error.message;
  });
};



export const getStudentGradeAnalytics = (values) => async (dispatch) => {
  dispatch(getStudentGradeAnalytic());
  const { studentId, endDate, startDate } = values;
  await API.get(
    `${urls.GRADE_ANALYTIC}student/${studentId}?endDate=${endDate}&startDate=${startDate}`
  )
    .then((res) => {
      dispatch(getStudentGradeAnalyticSuccess(res.data));
      console.log(res.data);
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getStudentGradeAnalyticFailure(error.message));
      return error.message;
    });
};
export const getStudentAttendanceAnalytics = (values) => async (dispatch) => {
  dispatch(getStudentAttendanceAnalytic());
  const { studentId, endDate, startDate } = values;
  await API.get(
    `${urls.ATTENDANCE_ANALYTIC}student/${studentId}?endDate=${endDate}&startDate=${startDate}`
  )
    .then((res) => {
      dispatch(getStudentAttendanceAnalyticSuccess(res.data));
      console.log(res.data);
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getStudentAttendanceAnalyticFailure(error.message));
      return error.message;
    });
};
// ! INSTRUCROT
export const getInstructorGenderAnalytic = () => {
  return {
    type: GET_INSTRUCTOR_ANALYTIC_REQUEST,
  };
};

export const getInstructorGenderAnalyticSuccess = (data) => {
  return {
    type: GET_INSTRUCTOR_ANALYTIC_SUCCESS,
    payload: data,
  };
};

export const getInstructorGenderAnalyticFailure = (error) => {
  return {
    type: GET_INSTRUCTOR_ANALYTIC_ERROR,
    payload: error,
  };
};
// ! INSTRUCTOR STUTISTICS TOPIC
export const getTeachersStutistics = () => {
  return {
    type: GET_INSTRUCTOR_STUTISTICS_REQUEST,
  };
};

export const getTeachersStutisticsSuccess = (data) => {
  return {
    type: GET_INSTRUCTOR_STUTISTICS_SUCCESS,
    payload: data,
  };
};

export const getTeachersStutisticsFailure = (error) => {
  return {
    type: GET_INSTRUCTOR_STUTISTICS_ERROR,
    payload: error,
  };
};
// ! STUDENT COUNT TOPIC
export const getStudentsCountSuccess = (data) => {
  return {
    type: GET_STUDENTS_COUNT_SUCCESS,
    payload: data,
  };
};

// ! INSTRUCTOR STUTISTICS HOME WORK
export const getTeachersHW = () => {
  return {
    type: GET_ASSESSMENTS_HW_REQUEST,
  };
};

export const getTeachersHWSuccess = (data) => {
  return {
    type: GET_ASSESSMENTS_HW_SUCCESS,
    payload: data,
  };
};

export const getTeachersHWFailure = (error) => {
  return {
    type: GET_ASSESSMENTS_HW_ERROR,
    payload: error,
  };
};

// ! INSTRUCTOR STUTISTICS TOPIC ASSESSMENTS
export const getTeachersTopic = () => {
  return {
    type: GET_ASSESSMENTS_TOPIC_REQUEST,
  };
};

export const getTeachersTopicSuccess = (data) => {
  return {
    type: GET_ASSESSMENTS_TOPIC_SUCCESS,
    payload: data,
  };
};

export const getTeachersTopicFailure = (error) => {
  return {
    type: GET_ASSESSMENTS_TOPIC_ERROR,
    payload: error,
  };
};

// ! INSTRUCTOR STUTISTICS ASSESSMENTS
export const getTeachersAssessments = () => {
  return {
    type: GET_ASSESSMENTS_ANALYTIC_REQUEST,
  };
};

export const getTeachersAssessmentsSuccess = (data) => {
  return {
    type: GET_ASSESSMENTS_ANALYTIC_SUCCESS,
    payload: data,
  };
};

export const getTeachersAssessmentsFailure = (error) => {
  return {
    type: GET_ASSESSMENTS_ANALYTIC_ERROR,
    payload: error,
  };
};

// ! PARENT GENDER ANALYTIC
export const getParentGenderAnalytic = () => {
  return {
    type: GET_PARENTS_ANALYTIC_REQUEST,
  };
};

export const getParentSGenderAnalyticSuccess = (data) => {
  return {
    type: GET_PARENTS_ANALYTIC_SUCCESS,
    payload: data,
  };
};

export const getParentsGenderAnalyticFailure = (error) => {
  return {
    type: GET_PARENTS_ANALYTIC_ERROR,
    payload: error,
  };
};

// ! STUDENT GENDER ANALYTIC
export const getStudentsGenderAnalytic = () => {
  return {
    type: GET_STUDENTS_ANALYTIC_REQUEST,
  };
};

export const getStudentsGenderAnalyticSuccess = (data) => {
  return {
    type: GET_STUDENTS_ANALYTIC_SUCCESS,
    payload: data,
  };
};

export const getStudentsGenderAnalyticFailure = (error) => {
  return {
    type: GET_STUDENTS_ANALYTIC_ERROR,
    payload: error,
  };
};

export const getStudentGradeAnalytic = () => {
  return {
    type: GET_STUDENT_GRADE_ANALYTICS_REQUEST,
  };
};

export const getStudentGradeAnalyticSuccess = (data) => {
  return {
    type: GET_STUDENT_GRADE_ANALYTICS_SUCCESS,
    payload: data,
  };
};

export const getStudentGradeAnalyticFailure = (error) => {
  return {
    type: GET_STUDENT_GRADE_ANALYTICS_ERROR,
    payload: error,
  };
};

export const getStudentAttendanceAnalytic = () => {
  return {
    type: GET_STUDENT_ATTENDANCE_ANALYTICS_REQUEST,
  };
};

export const getStudentAttendanceAnalyticSuccess = (data) => {
  return {
    type: GET_STUDENT_ATTENDANCE_ANALYTICS_SUCCESS,
    payload: data,
  };
};

export const getStudentAttendanceAnalyticFailure = (error) => {
  return {
    type: GET_STUDENT_ATTENDANCE_ANALYTICS_ERROR,
    payload: error,
  };
};
