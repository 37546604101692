/* eslint-disable indent */
import React, { useEffect } from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { X, Check, PlusCircle } from "react-feather";
import { useTranslation } from "react-i18next";
import Tooltip from "react-simple-tooltip";

import {
  SchoolAdminLayout,
  DatePickerCalendar,
  NoData,
} from "../../components";
import {
  editQuartersMassive,
  getQuarters,
  deleteQuarter,
  createQuarter,
} from "../../store/quarter/actions";
import { statuses } from "../../config";

export default function Quarters() {
  const dispatch = useDispatch();
  const quartersStore = useSelector((store) => store.quarters.quarters);
  const quartersStoreStatus = useSelector((store) => store.quarters.status);

  const { t } = useTranslation(["presetting", "schoolAdmin"]);

  useEffect(async () => {
    getAllQuarter();
  }, [dispatch]);

  const getAllQuarter = () => {
    dispatch(getQuarters());
  };

  const onChangeQuarter = (e, id) => {
    console.log("ss", moment(e.target.value).format("YYYY-MM-DD"));
    const a = quartersStore.map((item) => {
      if (item.quarter == id) {
        return {
          ...item,
          [e.target.name]: moment(e.target.value).format("YYYY-MM-DD"),
        };
      } else {
        return item;
      }
    });
    dispatch(editQuartersMassive(a));
  };

  const setActive = (id) => {
    const a = quartersStore.map((item) => {
      if (item.quarter === id) {
        return { ...item, status: true };
      } else {
        return { ...item, status: false };
      }
    });
    dispatch(editQuartersMassive(a));
  };

  const removeExistQuarter = (id) => {
    dispatch(deleteQuarter(id));
  };

  return (
    <SchoolAdminLayout>
      <div className="school-presetting__body school-presetting__body-color">
        <div className="school-presetting__body-row">
          <div className="school-presetting__body-title">
            <h2>{t("schoolAdmin:study period")}</h2>
          </div>
          <div className="school-presetting__body-description">
            <p className="body-1">
              {t("schoolAdmin:lorem ipsum study period")}
            </p>
          </div>
        </div>

        <div className="school-presetting__body-row">
          <div className="school-presetting__body-subtitle">
            <h3>
              {t("presetting:study quarters presetting school regulations")}
            </h3>
          </div>

          <div className="school-presetting__study-quarters">
            <div className="school-presetting__study-quarter-titles">
              <div></div>
              <div>
                <p className="orange-text">
                  {t("presetting:start presetting school regulations")}
                </p>
              </div>
              <div>
                <p className="orange-text">
                  {t("presetting:the ending presetting school regulations")}
                </p>
              </div>
            </div>
            {quartersStore.length > 0 &&
            quartersStoreStatus === statuses.SUCCESS
              ? quartersStore?.map((item, idx) => (
                  <div className="school-presetting__study-quarter" key={idx}>
                    <div>
                      <p>{item.quarter}</p>
                    </div>
                    <div>
                      <DatePickerCalendar
                        onSelect={(e) => onChangeQuarter(e, idx + 1)}
                        placeholder={`- ${t("schoolAdmin:choose")} -`}
                        defaultValue={moment(item.startDate)}
                        name="startDate"
                      />
                    </div>
                    <div>
                      <DatePickerCalendar
                        onSelect={(e) => onChangeQuarter(e, idx + 1)}
                        placeholder={`- ${t("schoolAdmin:choose")} -`}
                        defaultValue={moment(item.endDate)}
                        name="endDate"
                      />
                    </div>
                    <div className="school-presetting__study-quarter-buttons">
                      <Tooltip className="tooltip" content={t("common:delete")}>
                        <button onClick={() => removeExistQuarter(item.id)}>
                          <X />
                        </button>
                      </Tooltip>
                      <Tooltip
                        className="tooltip"
                        content={t("common:activate")}
                      >
                        <button
                          className={item.status ? "active" : ""}
                          onClick={() => setActive(item.quarter)}
                        >
                          <Check />
                        </button>
                      </Tooltip>
                    </div>
                  </div>
                ))
              : quartersStoreStatus === statuses.LOADING && <NoData />}
            {quartersStore.length > 0 && quartersStore.length < 4 ? (
              <Tooltip className="tooltip" content={t("common:add")}>
                <button
                  className="add-quarter-button"
                  onClick={async () => {
                    await dispatch(
                      createQuarter({
                        endDate: `${
                          JSON.parse(localStorage.getItem("chronicleActive"))
                            ?.endYear
                        }-01-01`,
                        quarter: `${quartersStore.length + 1}`,
                        startDate: `${
                          JSON.parse(localStorage.getItem("chronicleActive"))
                            ?.endYear
                        }-02-25`,
                        status: false,
                      })
                    );
                    await getAllQuarter();
                  }}
                >
                  <PlusCircle size={24} color="#F0783A" />
                  <span>{t("schoolAdmin:add new quater")}</span>
                </button>
              </Tooltip>
            ) : null}
          </div>
        </div>
      </div>
    </SchoolAdminLayout>
  );
}
