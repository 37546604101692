import { urls, toastOption } from "../../config";
import { API } from "../../utils";
import { toast } from "react-toastify";
import i18n from "../../i18n";

import {
  GET_GRADE_SHEET_REQUEST,
  GET_GRADE_SHEET_SUCCESS,
  GET_GRADE_SHEET_ERROR,
  GET_GRADE_SHEET_COURSES_SUCCESS,
  GET_GRADE_SHEET_PEOPLE_SUCCESS,
  GET_QUARTER_GRADE_ANALYTIC_CLASS_PERFORMANCE_REQUEST,
  GET_QUARTER_GRADE_ANALYTIC_CLASS_PERFORMANCE_SUCCESS,
  GET_QUARTER_GRADE_ANALYTIC_CLASS_PERFORMANCE_ERROR,
  GET_QUARTER_GRADE_ANALYTIC_LIST_EXCELLENCE_REQUEST,
  GET_QUARTER_GRADE_ANALYTIC_LIST_EXCELLENCE_SUCCESS,
  GET_QUARTER_GRADE_ANALYTIC_LIST_EXCELLENCE_ERROR,
  GET_QUARTER_GRADE_ANALYTIC_COUNT_REQUEST,
  GET_QUARTER_GRADE_ANALYTIC_COUNT_SUCCESS,
  GET_QUARTER_GRADE_ANALYTIC_COUNT_ERROR,
  GET_STUDENTS_REPORT_ATTENDANCE,
  GET_STUDENTS_REPORT_ATTENDANCE_SUCCESS,
  GET_STUDENTS_REPORT_ATTENDANCE_ERROR,
  QUARTER_TITLE_REQUEST,
  GET_QUARTER_TITLE_REQUEST_SUCCESS,
  GET_QUARTER_TITLE_REQUEST_ERROR,
  GET_STUDENT_ANALYTIC_REPORTS,
  GET_STUDENT_ANALYTIC_REPORTS_SUCCESS,
  GET_STUDENT_ANALYTIC_REPORTS_ERROR,
} from "../actionTypes";

export const getQuarterTitles = (schoolId, chronicleId) => async (dispatch) => {
  dispatch(getQuarterTitlesRequest());

  await API.get(`${urls.QUATERS_TITLE}${schoolId}/chronicle/${chronicleId}`)
    .then((res) => {
      dispatch(getQuarterTitlesSuccess(res.data));
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getQuarterTitlesFailure(error.message));
      return error.message;
    });
};
function getQuarterTitlesRequest() {
  return {
    type: QUARTER_TITLE_REQUEST,
  };
}
function getQuarterTitlesSuccess(data) {
  return {
    type: GET_QUARTER_TITLE_REQUEST_SUCCESS,
    payload: data,
  };
}
function getQuarterTitlesFailure() {
  return {
    type: GET_QUARTER_TITLE_REQUEST_ERROR,
  };
}

export const getQuarterMark =
  (classId, quarterId, chronicleId) => async (dispatch) => {
    dispatch(getGradeSheetRequest());

    await API.get(
      `${
        urls.QUARTER_GRADE_ANALYTIC
      }grade-sheet?chronicleId=${chronicleId}&classId=${classId}${`${
        quarterId !== undefined
          ? "&gradeType=QUARTER_GRADE&quarterId=" + quarterId
          : ""
      }`}`
    )
      .then((res) => {
        let person = res.data;
        dispatch(getGradeSheetSuccess(res.data));
        const people = [
          ...new Map(
            person.map((item) => [
              JSON.stringify(item.studentTitle),
              {
                studentTitle: item.studentTitle,
                studentId: item.studentId,
              },
            ])
          ).values(),
        ];
        dispatch(getGradeSheetPersonSuccess(people));

        const obj = [
          ...new Map(
            person.map((item) => [
              JSON.stringify(item.courseTitleRU),
              {
                courseId: item.courseId,
                courseTitleRU: item.courseTitleRU,
                courseTitleKG: item.courseTitleKG,
                courseTitle: item.courseTitle,
              },
            ])
          ).values(),
        ];
        dispatch(getGradeSheetCoursesSuccess(obj));

        return res.data;
      })
      .catch((error) => {
        console.log(error);
        toast.error(i18n.t("toastify:error"), toastOption);
        dispatch(getGradeSheetFailure(error.message));
        return error.message;
      });
  };

export const getQuarterGradeAnalyticClassPerformance =
  (classId, quarterId, chronicleId) => async (dispatch) => {
    dispatch(getQuarterGradeAnalyticClassPerformanceRequest());

    await API.get(
      `${urls.QUARTER_GRADE_ANALYTIC}class-performance?chronicleId=${chronicleId}&classId=${classId}&gradeType=QUARTER_GRADE&quarterId=${quarterId}`
    )
      .then((res) => {
        dispatch(getQuarterGradeAnalyticClassPerformanceSuccess(res.data));
        return res.data;
      })
      .catch((error) => {
        console.log(error);
        toast.error(i18n.t("toastify:error"), toastOption);
        dispatch(getQuarterGradeAnalyticClassPerformanceFailure(error.message));
        return error.message;
      });
  };

export const getQuarterGradeAnalyticListExcellence =
  (classId, quarterId) => async (dispatch) => {
    dispatch(getQuarterGradeAnalyticListExcellenceRequest());

    await API.get(
      `${urls.QUARTER_GRADE_ANALYTIC}list-excellence?schoolId=${
        JSON.parse(localStorage.getItem("school"))?.id
      }&classId=${classId}&quarterId=${quarterId}`
    )
      .then((res) => {
        dispatch(getQuarterGradeAnalyticListExcellenceSuccess(res.data));
        return res.data;
      })
      .catch((error) => {
        console.log(error);
        toast.error(i18n.t("toastify:error"), toastOption);
        dispatch(getQuarterGradeAnalyticListExcellenceFailure(error.message));
        return error.message;
      });
  };
export const getQuarterGradeAnalyticCount =
  (classId, quarterId) => async (dispatch) => {
    dispatch(getQuarterGradeAnalyticCountRequest());

    await API.get(
      `${urls.QUARTER_GRADE_ANALYTIC}count?schoolId=${
        JSON.parse(localStorage.getItem("school"))?.id
      }&classId=${classId}&quarterId=${quarterId}`
    )
      .then((res) => {
        dispatch(getQuarterGradeAnalyticCountSuccess(res.data));
        return res.data;
      })
      .catch((error) => {
        console.log(error);
        toast.error(i18n.t("toastify:error"), toastOption);
        dispatch(getQuarterGradeAnalyticCountFailure(error.message));
        return error.message;
      });
  };

// !-------------------------------------------------------------------------
export const studentsAttendance =
  (classId, startDate, endDate) => async (dispatch) => {
    dispatch(getStudentsAttendanceLoading());

    await API.get(
      `${urls.STUDENTS_ATTENDACE}class/${classId}?endDate=${endDate}&startDate=${startDate}`
    )
      .then((res) => {
        dispatch(getStudentsAttendanceSuccess(res.data));
        return res.data;
      })
      .catch((error) => {
        console.log(error);
        toast.error(i18n.t("toastify:error"), toastOption);
        dispatch(getStudentsAttendanceErrorr(error.message));
        return error.message;
      });
  };
// --------------------------------------------------------------
export const analyticStudents = (quarterId) => async (dispatch) => {
  dispatch(getAnalyticStudentReports());

  await API.get(`${urls.REPORT_PUPILS}${quarterId}`)
    .then((res) => {
      dispatch(getStudentAnalyticReportsSuccess(res.data));
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getStudentAnalyticReportsError(error.message));
      return error.message;
    });
};
// // // // // // // // // // // // // // // // // // // // // // // // // //
export const getAnalyticStudentReports = () => {
  return {
    type: GET_STUDENT_ANALYTIC_REPORTS,
  };
};
export const getStudentAnalyticReportsSuccess = (data) => {
  return {
    type: GET_STUDENT_ANALYTIC_REPORTS_SUCCESS,
    payload: data
  };
};

export const getStudentAnalyticReportsError = (error) => {
  return {
    type: GET_STUDENT_ANALYTIC_REPORTS_ERROR,
    payload: error
  };
};

// -------------------------------
export const getStudentsAttendanceLoading = () => {
  return {
    type: GET_STUDENTS_REPORT_ATTENDANCE,
  };
};
export const getStudentsAttendanceSuccess = (data) => {
  return {
    type: GET_STUDENTS_REPORT_ATTENDANCE_SUCCESS,
    payload: data,
  };
};

export const getStudentsAttendanceErrorr = (error) => {
  return {
    type: GET_STUDENTS_REPORT_ATTENDANCE_ERROR,
    payload: error,
  };
};

// !-------------------------------------------------------------------------

export const getGradeSheetRequest = () => {
  return {
    type: GET_GRADE_SHEET_REQUEST,
  };
};

export const getGradeSheetCoursesSuccess = (data) => {
  return {
    type: GET_GRADE_SHEET_COURSES_SUCCESS,
    payload: data,
  };
};
export const getGradeSheetPersonSuccess = (data) => {
  return {
    type: GET_GRADE_SHEET_PEOPLE_SUCCESS,
    payload: data,
  };
};

export const getGradeSheetSuccess = (data) => {
  return {
    type: GET_GRADE_SHEET_SUCCESS,
    payload: data,
  };
};

export const getGradeSheetFailure = (error) => {
  return {
    type: GET_GRADE_SHEET_ERROR,
    payload: error,
  };
};

export const getQuarterGradeAnalyticClassPerformanceRequest = () => {
  return {
    type: GET_QUARTER_GRADE_ANALYTIC_CLASS_PERFORMANCE_REQUEST,
  };
};

export const getQuarterGradeAnalyticClassPerformanceSuccess = (data) => {
  return {
    type: GET_QUARTER_GRADE_ANALYTIC_CLASS_PERFORMANCE_SUCCESS,
    payload: data,
  };
};

export const getQuarterGradeAnalyticClassPerformanceFailure = (error) => {
  return {
    type: GET_QUARTER_GRADE_ANALYTIC_CLASS_PERFORMANCE_ERROR,
    payload: error,
  };
};

export const getQuarterGradeAnalyticListExcellenceRequest = () => {
  return {
    type: GET_QUARTER_GRADE_ANALYTIC_LIST_EXCELLENCE_REQUEST,
  };
};

export const getQuarterGradeAnalyticListExcellenceSuccess = (data) => {
  return {
    type: GET_QUARTER_GRADE_ANALYTIC_LIST_EXCELLENCE_SUCCESS,
    payload: data,
  };
};

export const getQuarterGradeAnalyticListExcellenceFailure = (error) => {
  return {
    type: GET_QUARTER_GRADE_ANALYTIC_LIST_EXCELLENCE_ERROR,
    payload: error,
  };
};

export const getQuarterGradeAnalyticCountRequest = () => {
  return {
    type: GET_QUARTER_GRADE_ANALYTIC_COUNT_REQUEST,
  };
};

export const getQuarterGradeAnalyticCountSuccess = (data) => {
  return {
    type: GET_QUARTER_GRADE_ANALYTIC_COUNT_SUCCESS,
    payload: data,
  };
};

export const getQuarterGradeAnalyticCountFailure = (error) => {
  return {
    type: GET_QUARTER_GRADE_ANALYTIC_COUNT_ERROR,
    payload: error,
  };
};