import {
  GET_CALENDAR_PLANS_REQUEST,
  GET_CALENDAR_PLANS_SUCCESS,
  GET_CALENDAR_PLANS_ERROR,
  GET_CALENDAR_PLAN_REQUEST,
  GET_CALENDAR_PLAN_SUCCESS,
  GET_CALENDAR_PLAN_ERROR,
} from "../actionTypes";
import { statuses } from "../../config";

const initialState = {
  calendarPlans: [],
  calendarPlan: [],
  error: "",
  status: statuses.INITIAL,
};

export const calendarPlanReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CALENDAR_PLANS_REQUEST:
      return {
        ...state,
        calendarPlans: [],
        error: "",
        status: statuses.LOADING,
      };
    case GET_CALENDAR_PLANS_SUCCESS:
      return {
        ...state,
        calendarPlans: action.payload,
        error: "",
        status: statuses.SUCCESS,
      };
    case GET_CALENDAR_PLANS_ERROR:
      return {
        ...state,
        error: action.payload,
        status: statuses.ERROR,
        calendarPlans: [],
      };
    case GET_CALENDAR_PLAN_REQUEST:
      return {
        ...state,
        calendarPlan: [],
        error: "",
        status: statuses.LOADING,
      };
    case GET_CALENDAR_PLAN_SUCCESS:
      return {
        ...state,
        calendarPlan: action.payload,
        error: "",
        status: statuses.SUCCESS,
      };
    case GET_CALENDAR_PLAN_ERROR:
      return {
        ...state,
        error: action.payload,
        status: statuses.ERROR,
        calendarPlan: [],
      };

    default:
      return state;
  }
};
