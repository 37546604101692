import { urls, toastOption } from "../../config";
import { API } from "../../utils";
import { toast } from "react-toastify";
import i18n from "../../i18n";

import {
  GET_SCHOOLS_REQUEST,
  GET_SCHOOLS_SUCCESS,
  GET_SCHOOLS_ERROR,
  GET_SCHOOL_REQUEST,
  GET_SCHOOL_SUCCESS,
  GET_SCHOOL_ERROR,
} from "../actionTypes";

export const getSchools = () => async (dispatch) => {
  dispatch(getSchoolsRequest());

  await API.get(`${urls.SCHOOLS}list-short-detail`)
    .then((res) => {
      console.log(res.data);
      dispatch(getSchoolsSuccess(res.data));
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getSchoolsFailure(error.message));
      return error.message;
    });
};

export const getSchoolById = (id, setSchool) => async (dispatch) => {
  dispatch(getSchoolRequest());

  await API.get(
    `${urls.SCHOOLS}${id ? id : JSON.parse(localStorage.getItem("school"))?.id}`
  )
    .then((res) => {
      console.log(res.data);
      dispatch(getSchoolSuccess(res.data));
      setSchool && setSchool(res.data);
      localStorage.setItem("school", JSON.stringify(res.data));
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getSchoolFailure(error.message));
      return error.message;
    });
};

export const editSchool = (values) => async (dispatch) => {
  dispatch(getSchoolRequest());
  const data = values;
  console.log(data);
  await API.post(
    `${urls.SCHOOLS}edit/${JSON.parse(localStorage.getItem("school"))?.id}`,
    data
  )
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess updated"), toastOption);
      dispatch(getSchoolSuccess(res.data));
      dispatch(getSchoolById(JSON.parse(localStorage.getItem("school"))?.id));
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getSchoolFailure(error.message));
      return error.message;
    });
};

export const getSchoolsRequest = () => {
  return {
    type: GET_SCHOOLS_REQUEST,
  };
};

export const getSchoolsSuccess = (data) => {
  return {
    type: GET_SCHOOLS_SUCCESS,
    payload: data,
  };
};

export const getSchoolsFailure = (error) => {
  return {
    type: GET_SCHOOLS_ERROR,
    payload: error,
  };
};

export const getSchoolRequest = () => {
  return {
    type: GET_SCHOOL_REQUEST,
  };
};

export const getSchoolSuccess = (data) => {
  return {
    type: GET_SCHOOL_SUCCESS,
    payload: data,
  };
};

export const getSchoolFailure = (error) => {
  return {
    type: GET_SCHOOL_ERROR,
    payload: error,
  };
};
