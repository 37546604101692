/* eslint-disable indent */
import React, { useEffect, useState, useCallback } from "react";
import { debounce } from "lodash";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Edit2, Trash, X, Grid, Download } from "react-feather";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import ReactExport from "react-export-excel";
import SelectSearch, { fuzzySearch } from "react-select-search";
import Tooltip from "react-simple-tooltip";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

import {
  InstractorLayout,
  PaginationBlock,
  SearchField,
  NoData,
} from "../../components";
import { getCalendarTopicsByPlanId } from "../../store/calendarTopic/actions";
import {
  getCalendarPlans,
  removeCalendarPlan,
  addCalendarPlan,
  editCalendarPlan as editCalendarPlanAction,
  getCalendarPlanById,
} from "../../store/calendarPlan/actions";
import { getClassBySchoolId } from "../../store/class/actions";
import { getSchedulesByInstructorId } from "../../store/schedule/actions";

export default function CTP() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation(["presetting", "schoolAdmin", "common"]);
  const calendarPlansStore = useSelector(
    (store) => store.calendarPlans.calendarPlans
  );
  const calendarTopicsStore = useSelector(
    (store) => store.calendarTopics.calendarTopics
  );
  const schedulesStore = useSelector((store) => store.schedules.schedules);

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [currentData, setCurrentData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [selectedData, setSelectedData] = useState([]);
  const [searchedCurrentData, setSearchedCurrentData] = useState([]);
  const [filteredAllData, setFilteredAllData] = useState([]);
  const [currentCalendarPlanId, setCurrentCalendarPlanId] = useState();
  const [editCalendarPlan, setEditCalendarPlan] = useState({
    classId: "",
    courseId: "",
    title: "",
  });

  const [modal, setModal] = useState({
    createCalendarPlan: false,
    editCalendarPlan: false,
    export: false,
  });
  const [newCalendarPlan, setNewCalendarPlan] = useState({
    classId: "",
    courseId: "",
    title: "",
  });

  useEffect(async () => {
    await dispatch(getCalendarPlans());
  }, []);
  const onPageChanged = (
    pageNumber,
    perPageNumber,
    data = calendarPlansStore
  ) => {
    const offset = (pageNumber - 1) * perPageNumber;
    const currentDataArr = data.slice(offset, offset + perPageNumber);
    setFilteredAllData(data);
    return setCurrentData(currentDataArr);
  };
  const createCalendarPlan = () => {
    dispatch(addCalendarPlan(newCalendarPlan));
    setModal({ ...modal, createCalendarPlan: false });
    setNewCalendarPlan({
      title: "",
      courseId: "",
      classId: "",
    });
  };
  const updateCalendarPlan = async () => {
    await dispatch(
      editCalendarPlanAction({
        id: editCalendarPlan.id,
        classId: editCalendarPlan.classId,
        courseId: editCalendarPlan.courseId,
        title: editCalendarPlan.title,
      })
    );

    await setModal({ ...modal, editCalendarPlan: false });
    await setEditCalendarPlan({
      classId: "",
      courseId: "",
      title: "",
    });

    await setFilteredAllData([]);
    await setSearchedCurrentData([]);
    await setCurrentData([]);
    await setSelectedData([]);
    // await onPageChanged(1, perPage, calendarPlansStore, filterTypeNumber);
  };

  const onInputChange = (e) => {
    if ((e.key || e.target.name) === "classId") {
      console.log("e.value", e.value);

      setNewCalendarPlan({
        ...newCalendarPlan,
        classId: e.value.shift(),
        courseId: e.value.pop(),
      });
      // }
    } else {
      setNewCalendarPlan({
        ...newCalendarPlan,
        [e.key || e.target.name]: e.value || e.target.value,
      });
    }
  };
  const onEditInputChange = (e) => {
    if ((e.key || e.target.name) === "classId") {
      setEditCalendarPlan({
        ...editCalendarPlan,
        classId: e.value.shift(),
        courseId: e.value.pop(),
      });
      // }
    } else {
      setEditCalendarPlan({
        ...editCalendarPlan,
        [e.key || e.target.name]: e.value || e.target.value,
      });
    }
  };

  const debouncedPerPage = useCallback(
    debounce((text, calendarPlansStore) => {
      if (filteredAllData.length > 0) {
        onPageChanged(1, Number(text), filteredAllData);
      } else {
        onPageChanged(1, Number(text), calendarPlansStore);
      }
      setPerPage(Number(text));
      setCurrentPage(1);
    }, 1000),
    [calendarPlansStore, filteredAllData]
  );

  const debouncedSearch = useCallback(
    debounce((text) => {
      setSearchText(text.toLowerCase());
      setCurrentPage(1);

      const a = calendarPlansStore.filter((item) => {
        const a = String(`${item.title}`).toLowerCase();
        const c = a.includes(text.toLowerCase());

        if (c) {
          return c;
        }
      });
      setSearchedCurrentData([...a]);
      onPageChanged(1, perPage, [...a]);
    }, 1000),
    [calendarPlansStore, perPage]
  );
  const onChange = async (e) => {
    console.log(e);
    setCurrentPage(e);
    if (searchedCurrentData.length > 0) {
      onPageChanged(e, perPage, searchedCurrentData);
    } else if (filteredAllData.length > 0) {
      onPageChanged(e, perPage, filteredAllData);
    } else {
      onPageChanged(e, perPage, calendarPlansStore);
    }
  };
  const getPerPage = (e) => {
    debouncedPerPage(e.target.value);
  };
  const searchInputHandler = (e) => {
    debouncedSearch(e.target.value);
  };

  const removeItem = async (value) => {
    await dispatch(removeCalendarPlan(value.id));
    await dispatch(getCalendarPlans());
  };

  const onSelect = (value) => {
    if (selectedData.find((item) => item.id === value.id)) {
      let a = selectedData.filter((item) => item.id !== value.id);
      setSelectedData([...a]);
    } else {
      setSelectedData([...selectedData, value]);
    }
  };
  const clearSelected = () => {
    setSelectedData([]);
  };
  const removeSelected = async (data) => {
    // let a = calendarPlansStore;
    await data.map((item) => {
      dispatch(removeCalendarPlan(item.id));
    });
    await dispatch(getCalendarPlans());
    setSelectedData([]);
    // setAllData(a);
    // onPageChanged(currentPage, perPage);
  };
  const classAndCourseRender = schedulesStore
    .filter(
      (thing, index, self) =>
        index ===
        self.findIndex(
          (t) => t.courseId === thing.courseId && t.classId === thing.classId
        )
    )
    .sort(
      (a, b) =>
        a.classTitle.match(/\d/g).join("") - b.classTitle.match(/\d/g).join("")
    )
    .map((item) => ({
      name: `${item.classTitle} - ${
        String(i18n.language).toUpperCase() !== "EN" &&
        String(i18n.language).toUpperCase() !== "TR"
          ? `${item[`courseTitle${String(i18n.language).toUpperCase()}`]} `
          : `${item.courseTitle} `
      }`,
      value: [item.classId, item.courseId],
      key: "classId",
    }));
  const tableDataRender =
    searchedCurrentData.length > 0
      ? searchedCurrentData.map((item, idx) => (
          <tr
            className={
              selectedData.find((i) => i.id === item.id) ? "active" : ""
            }
            key={idx}
          >
            <td className="table-select">
              <label className="check">
                <input
                  type="checkbox"
                  onChange={() => onSelect(item)}
                  checked={selectedData.find((i) => i.id === item.id)}
                />
                <div className="box"></div>
              </label>
              <span>{idx + 1}</span>
            </td>
            <td>{item.title}</td>
            <td>
              {item.classTitle}{" "}
              {String(i18n.language).toUpperCase() !== "EN" &&
              String(i18n.language).toUpperCase() !== "TR"
                ? `${
                    item[`courseTitle${String(i18n.language).toUpperCase()}`]
                  } `
                : `${item.courseTitle} `}
            </td>
            <td>{item.topicTotalCount}</td>
            <td>{item.topicTotalHour}</td>
            <td>
              <div>
                <Tooltip className="tooltip" content={t("common:open")}>
                  <Link to={`/instructor/ctp/${item.id}`}>
                    <button>
                      <Grid color="#F0783A" />
                    </button>
                  </Link>
                </Tooltip>
                <Tooltip className="tooltip" content={t("common:export")}>
                  <button
                    onClick={() => {
                      setCurrentCalendarPlanId(item.id);
                      setModal({ ...modal, export: true });
                    }}
                  >
                    <Download color="#0066ff" />
                  </button>
                </Tooltip>
                <Tooltip className="tooltip" content={t("common:edit")}>
                  <button
                    onClick={async () => {
                      await setCurrentCalendarPlanId(item.id);
                      await setModal({ ...modal, editCalendarPlan: true });
                    }}
                  >
                    <Edit2 color="#14A38B" />
                  </button>
                </Tooltip>
                <Tooltip className="tooltip" content={t("common:delete")}>
                  <button onClick={() => removeItem(item)}>
                    <Trash color="#FF7171" />
                  </button>
                </Tooltip>
              </div>
            </td>
          </tr>
        ))
      : currentData.length > 0
      ? currentData.map((item, idx) => (
          <tr
            className={
              selectedData.find((i) => i.id === item.id) ? "active" : ""
            }
            key={idx}
          >
            <td className="table-select">
              <label className="check">
                <input
                  type="checkbox"
                  onChange={() => onSelect(item)}
                  checked={selectedData.find((i) => i.id === item.id)}
                />
                <div className="box"></div>
              </label>
              <span>{idx + 1}</span>
            </td>
            <td>{item.title}</td>
            <td>
              {item.classTitle}{" "}
              {String(i18n.language).toUpperCase() !== "EN" &&
              String(i18n.language).toUpperCase() !== "TR"
                ? `${
                    item[`courseTitle${String(i18n.language).toUpperCase()}`]
                  } `
                : `${item.courseTitle} `}
            </td>
            <td>{item.topicTotalCount}</td>
            <td>{item.topicTotalHour}</td>
            <td>
              <div>
                <Tooltip className="tooltip" content={t("common:open")}>
                  <Link to={`/instructor/ctp/${item.id}`}>
                    <button>
                      <Grid color="#F0783A" />
                    </button>
                  </Link>
                </Tooltip>
                <Tooltip className="tooltip" content={t("common:export")}>
                  <button
                    onClick={() => {
                      setCurrentCalendarPlanId(item.id);
                      setModal({ ...modal, export: true });
                    }}
                  >
                    <Download color="#0066ff" />
                  </button>
                </Tooltip>
                <Tooltip className="tooltip" content={t("common:edit")}>
                  <button
                    onClick={async () => {
                      await setCurrentCalendarPlanId(item.id);
                      await setModal({ ...modal, editCalendarPlan: true });
                    }}
                  >
                    <Edit2 color="#14A38B" />
                  </button>
                </Tooltip>
                <Tooltip className="tooltip" content={t("common:delete")}>
                  <button onClick={() => removeItem(item)}>
                    <Trash color="#FF7171" />
                  </button>
                </Tooltip>
              </div>
            </td>
          </tr>
        ))
      : calendarPlansStore.slice([0], [10]).map((item, idx) => (
          <tr
            className={
              selectedData.find((i) => i.id === item.id) ? "active" : ""
            }
            key={idx}
          >
            <td className="table-select">
              <label className="check">
                <input
                  type="checkbox"
                  onChange={() => onSelect(item)}
                  checked={selectedData.find((i) => i.id === item.id)}
                />
                <div className="box"></div>
              </label>
              <span>{idx + 1}</span>
            </td>
            <td>{item.title}</td>
            <td>
              {item.classTitle}{" "}
              {String(i18n.language).toUpperCase() !== "EN" &&
              String(i18n.language).toUpperCase() !== "TR"
                ? `${
                    item[`courseTitle${String(i18n.language).toUpperCase()}`]
                  } `
                : `${item.courseTitle} `}
            </td>
            <td>{item.topicTotalCount}</td>
            <td>{item.topicTotalHour}</td>
            <td>
              <div>
                <Tooltip className="tooltip" content={t("common:open")}>
                  <Link to={`/instructor/ctp/${item.id}`}>
                    <button>
                      <Grid color="#F0783A" />
                    </button>
                  </Link>
                </Tooltip>
                <Tooltip className="tooltip" content={t("common:export")}>
                  <button
                    onClick={() => {
                      setCurrentCalendarPlanId(item.id);
                      setModal({ ...modal, export: true });
                    }}
                  >
                    <Download color="#0066ff" />
                  </button>
                </Tooltip>
                <Tooltip className="tooltip" content={t("common:edit")}>
                  <button
                    onClick={async () => {
                      await setCurrentCalendarPlanId(item.id);
                      await setModal({ ...modal, editCalendarPlan: true });
                    }}
                  >
                    <Edit2 color="#14A38B" />
                  </button>
                </Tooltip>
                <Tooltip className="tooltip" content={t("common:delete")}>
                  <button onClick={() => removeItem(item)}>
                    <Trash color="#FF7171" />
                  </button>
                </Tooltip>
              </div>
            </td>
          </tr>
        ));
  return (
    <div>
      <InstractorLayout>
        <div className="school-presetting__body school-presetting__body-color">
          <div className="school-presetting__body-row">
            <div className="school-presetting__body-title">
              <h2>{t("instructor:calendar-thematic plan")}</h2>
            </div>
            <div className="school-presetting__body-description">
              <p className="body-1">{t("instructor:CTP loran")}</p>
            </div>
          </div>
          <div className="school-presetting__subjects-list">
            <div className="school-presetting__search-filter">
              <div className="school-presetting__search">
                <SearchField
                  onChange={searchInputHandler}
                  defaultValue={searchText}
                  placeholder={t("common:search")}
                />
              </div>
              <div className="school-presetting__filter">
                {/* <button
                  className="secondary small"
                  onClick={() => setModal({ ...modal, export: true })}
                >
                  {t("schoolAdmin:export")}
                </button> */}
                <button
                  id="create_class"
                  className="primary small"
                  onClick={() =>
                    setModal({ ...modal, createCalendarPlan: true })
                  }
                >
                  {t("common:create")}
                </button>
              </div>
            </div>
            <div className="school-presetting__teachers-body">
              <div className="school-presetting__teachers-table">
                {calendarPlansStore.length > 0 ? (
                  searchText !== "" && searchedCurrentData.length == 0 ? (
                    <NoData
                      messages={[`${t("schoolAdmin:here is nothing")}`]}
                    />
                  ) : (
                    <>
                      <table>
                        <thead>
                          <tr>
                            <th>№</th>
                            <th>{t("instructor:name ctp")}</th>
                            <th>{t("schoolAdmin:class")}</th>
                            <th>{t("instructor:topic lenth")}</th>
                            <th>{t("instructor:number of hours")}</th>
                            <th>{t("presetting:action presetting")}</th>
                          </tr>
                        </thead>
                        <tbody>{tableDataRender}</tbody>
                      </table>
                      {selectedData.length > 0 && (
                        <div className="edit-table">
                          <div className="edit-table-left">
                            <div>
                              <p className="caption-1">
                                {t("presetting:choosen presetting")}:
                                <span>{selectedData.length}</span>
                              </p>
                            </div>
                            <div>
                              <button onClick={clearSelected}>
                                {t("presetting:consel presetting")}
                              </button>
                            </div>
                          </div>
                          <div className="edit-table-right">
                            <div>
                              <button
                                onClick={() => {
                                  removeSelected(selectedData);
                                }}
                              >
                                {t("presetting:delete presetting")}
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )
                ) : (
                  <NoData messages={[`${t("schoolAdmin:here is nothing")}`]} />
                )}
              </div>
            </div>
            <div className="pagination">
              <div className="paginaiton__inner">
                <div className="pagination__per-page">
                  <p>
                    {t("presetting:showing presetting")}{" "}
                    <input
                      type="phone"
                      defaultValue={perPage}
                      onChange={getPerPage}
                    />{" "}
                    {t("presetting:from presetting")}{" "}
                    {searchedCurrentData.length > 0
                      ? searchedCurrentData.length
                      : filteredAllData.length > 0
                      ? filteredAllData.length
                      : calendarPlansStore.length}{" "}
                    {t("presetting:lines presetting")}
                  </p>
                </div>
                <div className="pagination__page">
                  <PaginationBlock
                    total={
                      searchedCurrentData.length > 0
                        ? searchedCurrentData.length
                        : filteredAllData.length > 0
                        ? filteredAllData.length
                        : calendarPlansStore.length
                    }
                    current={currentPage}
                    onChange={onChange}
                    pageSize={perPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          isOpen={modal.createCalendarPlan}
          onRequestClose={() => {
            setModal({ ...modal, createCalendarPlan: false });
            setNewCalendarPlan({
              classId: "",
              courseId: "",
              title: "",
            });
          }}
          overlayClassName="modal-overlay"
          onAfterOpen={async () => {
            await dispatch(
              getSchedulesByInstructorId(
                JSON.parse(localStorage.getItem("person"))?.id
              )
            );
            await dispatch(getClassBySchoolId());
          }}
        >
          <div className="modal">
            <div className="modal-close">
              <button
                onClick={() => {
                  setModal({ ...modal, createCalendarPlan: false });
                  setNewCalendarPlan({
                    classId: "",
                    courseId: "",
                    title: "",
                  });
                }}
              >
                <X size={15} color="#9A9A9A" />
              </button>
            </div>
            <div className="modal-header">
              <div className="modal-header-title">
                <h2>{t("instructor:new CTP")}</h2>
              </div>
            </div>
            <div className="modal-body">
              <div className="modal-inputs-list">
                <div className="modal-input">
                  <input
                    type="text"
                    placeholder={`${t("instructor:submit name of CTP")}`}
                    name="title"
                    defaultValue={newCalendarPlan?.title}
                    onChange={onInputChange}
                    required
                  />
                </div>
                <div className="modal-input">
                  <SelectSearch
                    options={classAndCourseRender}
                    search
                    filterOptions={fuzzySearch}
                    className="select-search drop-up small"
                    name="classId"
                    value={`${[
                      newCalendarPlan.classId,
                      newCalendarPlan.courseId,
                    ]}`}
                    onChange={(_, k) => {
                      onInputChange(k);
                    }}
                    required
                    placeholder={`${t("instructor:choose class")}`}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                id="create_class_modal"
                className="large primary"
                onClick={() => createCalendarPlan()}
                disabled={
                  newCalendarPlan?.title === "" ||
                  newCalendarPlan?.courseId === "" ||
                  newCalendarPlan?.classId === ""
                }
              >
                {t("common:create")}
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={modal.editCalendarPlan}
          onRequestClose={() => {
            setModal({ ...modal, editCalendarPlan: false });
            setEditCalendarPlan({
              classId: "",
              courseId: "",
              title: "",
            });
          }}
          overlayClassName="modal-overlay"
          onAfterOpen={async () => {
            await dispatch(
              getSchedulesByInstructorId(
                JSON.parse(localStorage.getItem("person"))?.id
              )
            );
            await dispatch(getClassBySchoolId());
            await dispatch(
              getCalendarPlanById(currentCalendarPlanId, setEditCalendarPlan)
            );
          }}
        >
          <div className="modal">
            <div className="modal-close">
              <button
                onClick={() => {
                  setModal({ ...modal, editCalendarPlan: false });
                  setEditCalendarPlan({
                    classId: "",
                    courseId: "",
                    title: "",
                  });
                }}
              >
                <X size={15} color="#9A9A9A" />
              </button>
            </div>
            <div className="modal-header">
              <div className="modal-header-title">
                <h2 className="roboto">
                  {editCalendarPlan.classLevel}
                  {editCalendarPlan.classLabel}{" "}
                  {t("schoolAdmin:lower case class")}
                </h2>
              </div>
            </div>
            <div className="modal-body">
              <div className="modal-inputs-list">
                <div className="modal-input">
                  <input
                    type="text"
                    placeholder={`${t("instructor:submit name of CTP")}`}
                    name="title"
                    defaultValue={editCalendarPlan.title}
                    onChange={onEditInputChange}
                  />
                </div>
                <div className="modal-input">
                  {editCalendarPlan.classId !== "" &&
                    editCalendarPlan.courseId !== "" && (
                      <SelectSearch
                        options={classAndCourseRender}
                        search
                        filterOptions={fuzzySearch}
                        className="select-search drop-up small"
                        name="classId"
                        value={`${[
                          editCalendarPlan.classId,
                          editCalendarPlan.courseId,
                        ]}`}
                        onChange={(_, k) => {
                          onEditInputChange(k);
                        }}
                        required
                        placeholder={`${t("instructor:choose class")}`}
                      />
                    )}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                className="large primary"
                onClick={async () => {
                  await updateCalendarPlan();
                }}
              >
                {t("common:save")}
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={modal.export}
          onRequestClose={() => {
            setModal({ ...modal, export: false });
          }}
          overlayClassName="modal-overlay"
          onAfterOpen={async () => {
            await dispatch(getCalendarTopicsByPlanId(currentCalendarPlanId));
          }}
        >
          <div className="modal">
            <div className="modal-close">
              <button
                onClick={() => {
                  setModal({ ...modal, export: false });
                }}
              >
                <X size={15} color="#9A9A9A" />
              </button>
            </div>
            <div className="modal-header">
              <div className="modal-header-title">
                <h2>{t("schoolAdmin:export")}</h2>
              </div>
            </div>

            <div className="modal-footer" style={{ marginTop: "24px" }}>
              <ExcelFile
                filename={`${
                  JSON.parse(localStorage.getItem("school"))?.name
                } - ${t("instructor:ctp")} - ${
                  calendarPlansStore.find(
                    (item) => item.id == currentCalendarPlanId
                  )?.classTitle
                } ${
                  String(i18n.language).toUpperCase() !== "EN" &&
                  String(i18n.language).toUpperCase() !== "TR"
                    ? `${
                        calendarPlansStore.find(
                          (item) => item.id == currentCalendarPlanId
                        )?.[`courseTitle${String(i18n.language).toUpperCase()}`]
                      } `
                    : `${
                        calendarPlansStore.find(
                          (item) => item.id == currentCalendarPlanId
                        )?.courseTitle
                      } `
                } - ${
                  calendarPlansStore.find(
                    (item) => item.id == currentCalendarPlanId
                  )?.title
                }`}
                element={
                  <button className="large primary">
                    {t("schoolAdmin:export")}
                  </button>
                }
              >
                <ExcelSheet
                  data={calendarTopicsStore}
                  name={t("instructor:calendar-thematic plan")}
                >
                  <ExcelColumn label={t("instructor:topic")} value="title" />
                  <ExcelColumn
                    label={t("instructor:number of hours")}
                    value="topicHour"
                  />
                  <ExcelColumn
                    label={t("instructor:date according to plan")}
                    value="topicDate"
                  />
                  <ExcelColumn
                    label={t("instructor:quarter")}
                    value="quarterSemester"
                  />
                </ExcelSheet>
              </ExcelFile>
            </div>
          </div>
        </Modal>
      </InstractorLayout>
    </div>
  );
}
