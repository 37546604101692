import React from "react";
import { Phone, Eye, EyeOff, User, Lock } from "react-feather";
import { Link, useHistory } from "react-router-dom";
import { Formik, Form } from "formik";
import { useTranslation, Trans } from "react-i18next";
import * as Yup from "yup";
import PT from "prop-types";
import { useDispatch } from "react-redux";

import FormikControl from "../../components/formik/FormikControl";
import { loginByLogin } from "../../store/auth/actions";
import "./LoginByLogin.scss";
import { constants } from "../../config";

LoginByLogin.propTypes = {
  setIsLogin: PT.func,
};

export default function LoginByLogin(props) {
  const { setIsLogin } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation(["common", "validation", "login"]);

  const initialValues = {
    username: "",
    password: "",
  };
  const validationSchema = Yup.object({
    password: Yup.string()
      .min(
        4,
        <Trans i18nKey="validation:min" count={"4"}>
          Не должно быть меньше 4 знаков.
        </Trans>
      )
      .required(`${t("validation:required")}`),

    username: Yup.string().required(`${t("validation:required")}`),
  });

  const onSubmit = async (values) => {
    console.log(values);
    dispatch(loginByLogin(values, history));
  };
  return (
    <div className="login">
      <div className="login__header">
        <div className="login__header-title">
          <h2>{t("login:login")}</h2>
        </div>
        <div className="login__header-description">
          <p className="body-2">{t("login:welcome")}</p>
          <p className="body-2">{t("login:enter login and password")}</p>
        </div>
      </div>
      <div className="login__body login_form large">
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(formik) => {
            return (
              <Form>
                <FormikControl
                  control="input"
                  // control="phoneinput"
                  type="text"
                  // label="Телефон*"
                  placeholder={`${t("validation:login")}`}
                  name="username"
                  value={formik.values.username}
                  onBlur={formik.handleBlur}
                  changed={formik.touched.username}
                  isValid={formik.errors.username}
                  icon={<User color="#718096" size={16} />}
                  onChange={(e) => (
                    formik.setFieldTouched(e.target.name, true, true),
                    formik.handleChange(e)
                  )}
                />
                <FormikControl
                  control="input"
                  // type="text"
                  // label="Пароль*"
                  placeholder={t("validation:password")}
                  showIcon={<Eye color="#718096" size={16} />}
                  hideIcon={<EyeOff color="#718096" size={16} />}
                  name="password"
                  value={formik.values.password}
                  onBlur={formik.handleBlur}
                  changed={formik.touched.password}
                  isValid={formik.errors.password}
                  icon={<Lock color="#718096" size={16} />}
                  onChange={(e) => (
                    formik.setFieldTouched(e.target.name, true, true),
                    formik.handleChange(e)
                  )}
                />
                <button
                  className="primary large"
                  type="submit"
                  disabled={!formik.touched.password && !formik.isValid}
                >
                  {t("common:login button")}
                </button>
              </Form>
            );
          }}
        </Formik>
      </div>
      <div className="login__footer large">
        <div className="login__footer-text">
          <p className="body-2">
            <Trans
              i18nKey="login:have questions"
              t={t}
              components={[<span className="orange-text" key="0"></span>]}
            ></Trans>
          </p>
        </div>
        <div className="numbers-list">
          {constants.phone_numbers_list.map((item, idx) => (
            <a href={`tel:${item}`} key={idx}>
              {item}
            </a>
          ))}
        </div>
      </div>
      <div id="phone-login__page-button">
        <Link to="/" onClick={() => setIsLogin(false)}>
          <button className="large">
            <Phone size={14} color="#14A38B" />
            <span>{t("common:login by phone number button")}</span>
          </button>
        </Link>
      </div>
    </div>
  );
}
