import { urls, toastOption } from "../../config";
import { API } from "../../utils";
import { toast } from "react-toastify";
import i18n from "../../i18n";

import {
  GET_CALENDAR_PLANS_REQUEST,
  GET_CALENDAR_PLANS_SUCCESS,
  GET_CALENDAR_PLANS_ERROR,
  GET_CALENDAR_PLAN_REQUEST,
  GET_CALENDAR_PLAN_SUCCESS,
  GET_CALENDAR_PLAN_ERROR,
} from "../actionTypes";

export const getCalendarPlans = () => async (dispatch) => {
  dispatch(getCalendarPlansRequest());

  await API.get(
    `${urls.CALENDAR_PLAN}instructor/${
      JSON.parse(localStorage.getItem("person"))?.id
    }/chronicle/${JSON.parse(localStorage.getItem("chronicleActive"))?.id}`
  )
    .then((res) => {
      console.log(res.data);
      dispatch(getCalendarPlansSuccess(res.data));
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getCalendarPlansFailure(error.message));
      return error.message;
    });
};
export const getCalendarPlanById =
  (id, setEditCalendarPlan) => async (dispatch) => {
    dispatch(getCalendarPlanRequest());

    await API.get(`${urls.CALENDAR_PLAN}${id}`)
      .then((res) => {
        console.log(res.data);
        setEditCalendarPlan && setEditCalendarPlan(res.data);
        dispatch(getCalendarPlanSuccess(res.data));
        return res.data;
      })
      .catch((error) => {
        console.log(error);
        toast.error(i18n.t("toastify:error"), toastOption);
        dispatch(getCalendarPlanFailure(error.message));
        return error.message;
      });
  };
export const removeCalendarPlan = (id) => async (dispatch) => {
  dispatch(getCalendarPlansRequest());
  await API.delete(`${urls.CALENDAR_PLAN}?id=${id}`)
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess deleted"), toastOption);
      dispatch(getCalendarPlans());
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      if (error.response.status === 409) {
        toast.error(i18n.t("toastify:delete grade exist"), toastOption);
      } else {
        toast.error(i18n.t("toastify:error"), toastOption);
      }
      // dispatch(getCalendarPlansFailure(error.message));
      return error.message;
    });
};
export const editCalendarPlan = (values) => async (dispatch) => {
  dispatch(getCalendarPlansRequest());
  const data = values;
  await API.post(`${urls.CALENDAR_PLAN}edit-all/${values.id}`, data)
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess updated"), toastOption);
      dispatch(getCalendarPlans());
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getCalendarPlansFailure(error.message));
      return error.message;
    });
};

export const addCalendarPlan = (values) => async (dispatch) => {
  const data = {
    ...values,
    chronicleId: JSON.parse(localStorage.getItem("chronicleActive"))?.id,
    personId: JSON.parse(localStorage.getItem("person"))?.id,
  };
  await API.post(`${urls.CALENDAR_PLAN}create`, data)
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess added"), toastOption);
      dispatch(getCalendarPlans());
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      return error.message;
    });
};
export const getCalendarPlansRequest = () => {
  return {
    type: GET_CALENDAR_PLANS_REQUEST,
  };
};

export const getCalendarPlansSuccess = (data) => {
  return {
    type: GET_CALENDAR_PLANS_SUCCESS,
    payload: data,
  };
};

export const getCalendarPlansFailure = (error) => {
  return {
    type: GET_CALENDAR_PLANS_ERROR,
    payload: error,
  };
};
export const getCalendarPlanRequest = () => {
  return {
    type: GET_CALENDAR_PLAN_REQUEST,
  };
};

export const getCalendarPlanSuccess = (data) => {
  return {
    type: GET_CALENDAR_PLAN_SUCCESS,
    payload: data,
  };
};

export const getCalendarPlanFailure = (error) => {
  return {
    type: GET_CALENDAR_PLAN_ERROR,
    payload: error,
  };
};
