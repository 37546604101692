import { urls, toastOption } from "../../config";
import { API } from "../../utils";
import { toast } from "react-toastify";
import i18n from "../../i18n";

import {
  GET_COURSES_REQUEST,
  GET_COURSES_SUCCESS,
  GET_COURSES_ERROR,
  GET_COURSES_CLASS_ID_REQUEST,
  GET_COURSES_CLASS_ID_SUCCESS,
  GET_COURSES_CLASS_ID_ERROR,
  GET_COURSES_STUDENT_ID_REQUEST,
  GET_COURSES_STUDENT_ID_SUCCESS,
  GET_COURSES_STUDENT_ID_ERROR,
} from "../actionTypes";

export const getCourses = () => async (dispatch) => {
  dispatch(getCoursesRequest());

  await API.get(
    `${urls.COURSE}school/${JSON.parse(localStorage.getItem("school"))?.id}`
  )
    .then((res) => {
      console.log(res.data);

      dispatch(getCoursesSuccess(res.data));
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getCoursesFailure(error.message));
      return error.message;
    });
};
export const getCoursesByClassId = (classId) => async (dispatch) => {
  dispatch(getCoursesByClassIdRequest());

  await API.get(
    `${urls.STUDENT}course/class/${classId}/chronicle/${
      JSON.parse(localStorage.getItem("chronicleActive"))?.id
    }`
  )
    .then((res) => {
      console.log(res.data);

      dispatch(getCoursesByClassIdSuccess(res.data));
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getCoursesByClassIdFailure(error.message));
      return error.message;
    });
};
export const getCoursesByStudentId = (studentId) => async (dispatch) => {
  dispatch(getCoursesByStudentIdRequest());

  await API.get(
    `${urls.STUDENT_MOBILE}course/student/${studentId}/chronicle/${
      JSON.parse(localStorage.getItem("chronicleActive"))?.id
    }`
  )
    .then((res) => {
      console.log(res.data);

      dispatch(getCoursesByStudentIdSuccess(res.data));
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getCoursesByStudentIdFailure(error.message));
      return error.message;
    });
};
export const removeCourese = (id) => async (dispatch) => {
  dispatch(getCoursesRequest());
  await API.delete(`${urls.COURSE}?id=${id}`)
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess deleted"), toastOption);
      dispatch(getCourses());
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getCoursesFailure(error.message));
      return error.message;
    });
};
export const editQuartersMassive = (values) => async (dispatch) => {
  dispatch(getCoursesRequest());
  const data = {
    quarterDtoList: values,
  };
  await API.post(`${urls.QUARTER}update-massive`, data)
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess updated"), toastOption);
      dispatch(getCourses());
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      dispatch(getCoursesFailure(error.message));
      return error.message;
    });
};

export const addCourse = (id) => async () => {
  const data = {
    courseId: id,
    schoolId: JSON.parse(localStorage.getItem("school"))?.id,
  };
  await API.post(`${urls.COURSE}create`, data)
    .then((res) => {
      console.log(res.data);
      toast.success(i18n.t("toastify:succsess added"), toastOption);
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      toast.error(i18n.t("toastify:error"), toastOption);
      return error.message;
    });
};
export const getCoursesRequest = () => {
  return {
    type: GET_COURSES_REQUEST,
  };
};

export const getCoursesSuccess = (data) => {
  return {
    type: GET_COURSES_SUCCESS,
    payload: data,
  };
};

export const getCoursesFailure = (error) => {
  return {
    type: GET_COURSES_ERROR,
    payload: error,
  };
};

export const getCoursesByClassIdRequest = () => {
  return {
    type: GET_COURSES_CLASS_ID_REQUEST,
  };
};

export const getCoursesByClassIdSuccess = (data) => {
  return {
    type: GET_COURSES_CLASS_ID_SUCCESS,
    payload: data,
  };
};

export const getCoursesByClassIdFailure = (error) => {
  return {
    type: GET_COURSES_CLASS_ID_ERROR,
    payload: error,
  };
};

export const getCoursesByStudentIdRequest = () => {
  return {
    type: GET_COURSES_STUDENT_ID_REQUEST,
  };
};

export const getCoursesByStudentIdSuccess = (data) => {
  return {
    type: GET_COURSES_STUDENT_ID_SUCCESS,
    payload: data,
  };
};

export const getCoursesByStudentIdFailure = (error) => {
  return {
    type: GET_COURSES_STUDENT_ID_ERROR,
    payload: error,
  };
};
