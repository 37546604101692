import React, { PureComponent } from "react";

export default class PaginationLeftIcon extends PureComponent {
  render() {
    return (
      <svg
        width="6"
        height="8"
        viewBox="0 0 6 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.84"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.53553 0.465745L0 4.00128L3.53553 7.53681C3.92606 7.92734 4.55922 7.92734 4.94975 7.53681C5.34027 7.14629 5.34027 6.51312 4.94975 6.1226L2.82843 4.00128L4.94975 1.87996C5.34027 1.48943 5.34027 0.856269 4.94975 0.465745C4.55922 0.0752205 3.92606 0.0752205 3.53553 0.465745Z"
          fill="#272727"
        />
      </svg>
    );
  }
}
